import {AssetCouponUsageDomain} from "../../domain/asset/AssetCouponUsageDomain"
import {AssetCouponId} from "../../id/asset/AssetCouponId"
import {AssetCouponUsageId} from "../../id/asset/AssetCouponUsageId"
import {AssetLunarUsageId} from "../../id/asset/AssetLunarUsageId"
import {AssetSolarUsageId} from "../../id/asset/AssetSolarUsageId"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 쿠폰 사용
 */
export class AssetCouponUsageValueObject implements ValueObject, AssetCouponUsageDomain {
    /**
     * 기본키
     */
    id: AssetCouponUsageId
    /**
     * 쿠폰 ID
     */
    assetCouponId: AssetCouponId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 솔라 지급 ID
     */
    assetSolarUsageId?: AssetSolarUsageId
    /**
     * 루나 지급 ID
     */
    assetLunarUsageId?: AssetLunarUsageId
    /**
     * 스타 지급 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetCouponUsageDomain, any>) {
        this.id = BigInt(o.id)
        this.assetCouponId = BigInt(o.assetCouponId)
        this.userId = BigInt(o.userId)
        if (o.assetSolarUsageId !== undefined && o.assetSolarUsageId !== null) {
            this.assetSolarUsageId = BigInt(o.assetSolarUsageId)
        }
        if (o.assetLunarUsageId !== undefined && o.assetLunarUsageId !== null) {
            this.assetLunarUsageId = BigInt(o.assetLunarUsageId)
        }
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        this.createdAt = new Date(o.createdAt)
    }
}
