import {UserNotificationPostDataTransferObject} from "../../data-transfer-object/user/UserNotificationPostDataTransferObject"

/**
 * 사용자 알림 속성 설명
 */
export const UserNotificationPostDataTransferObjectDescription: Record<keyof UserNotificationPostDataTransferObject, string> = {
    userId: '사용자 ID',
    title: '제목',
    body: '내용',
    subject: '광고 주제',
    deepLink: '딥링크',
    push: '푸시 발송'
}
