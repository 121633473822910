import {AssetSolarUsageType} from "../../constant/asset/AssetSolarUsageType"
import {AssetSolarUsagePostDataTransferObject} from "../../data-transfer-object/asset/AssetSolarUsagePostDataTransferObject"
import {AssetSolarUsageValueObject} from "../../value-object/asset/AssetSolarUsageValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetSolarUsagePostState = {
    userId: StatePair<bigint>
    type: StatePair<AssetSolarUsageType>
    amount: StatePair<number>
    toDataTransferObject(): AssetSolarUsagePostDataTransferObject
    fromValueObject(vo: AssetSolarUsageValueObject): void
    values(): [bigint, AssetSolarUsageType, number]
}

export function useAssetSolarUsagePostState(): AssetSolarUsagePostState {
    return {
        userId: useStatePair<bigint>(0n),
        type: useStatePair<AssetSolarUsageType>(AssetSolarUsageType.Admin),
        amount: useStatePair<number>(0),
        toDataTransferObject(): AssetSolarUsagePostDataTransferObject {
            return {
                userId: this.userId.value,
                type: this.type.value,
                amount: this.amount.value,
            }
        },
        fromValueObject(vo: AssetSolarUsageValueObject) {
            this.userId.setter(vo.userId)
            this.type.setter(vo.type)
            this.amount.setter(vo.amount)
        },
        values(): [bigint, AssetSolarUsageType, number] {
            return [
                this.userId.value,
                this.type.value,
                this.amount.value,
            ]
        }
    }
}
