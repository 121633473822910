import {AssetSolarWithdrawalOptionDomain} from "../../domain/asset/AssetSolarWithdrawalOptionDomain"
import {AssetSolarWithdrawalOptionId} from "../../id/asset/AssetSolarWithdrawalOptionId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 솔라 인출 옵션
 */
export class AssetSolarWithdrawalOptionValueObject implements ValueObject, AssetSolarWithdrawalOptionDomain {
    /**
     * 기본키
     */
    id: AssetSolarWithdrawalOptionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 차감 솔라
     */
    solarAmount: number
    /**
     * 지급 금액
     */
    cashAmount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date

    constructor(o: Record<keyof AssetSolarWithdrawalOptionDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.solarAmount = Number(o.solarAmount)
        this.cashAmount = Number(o.cashAmount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
    }
}
