import {ContentCommentLikeDomain} from "../../domain/content/ContentCommentLikeDomain"

/**
 * 댓글 좋아요 속성 설명
 */
export const ContentCommentLikeDescription: Record<keyof ContentCommentLikeDomain, string> = {
    id: '기본키',
    contentCommentId: '댓글 ID',
    userId: '사용자 ID',
    createdAt: '생성일'
}
