import {ContentPolicyDescriptor} from "../../../data/descriptor/content/ContentPolicyDescriptor";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentPolicyValueObject} from "../../../data/value-object/content/ContentPolicyValueObject";
import {ContentPolicySearchOption} from "../../../data/search-option/content/ContentPolicySearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {ContentPolicyIO} from "../../../io/ContentIO";
import {ContentPolicyDescription} from "../../../data/description/content/ContentPolicyDescription";
import ListOption from "../../ListOption";
import {
    ContentPolicySearchOptionDescription
} from "../../../data/description/content/ContentPolicySearchOptionDescription";
import {
    applyContentPolicySearchOption,
    ContentPolicySearchOptionField
} from "../../search-option-field/content/ContentPolicySearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentPolicyValueObject[]>([])
    const option = useStatePair<ContentPolicySearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentPolicyIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentPolicyDescription}
                descriptor={ContentPolicyDescriptor}
                showPost
                onPostClick={() => document.location = '/contents/policies/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/policies/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentPolicySearchOptionDescription}
                fields={ContentPolicySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentPolicySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentPolicyDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}