import {UserBlockDomain} from "../../domain/user/UserBlockDomain"

export const UserBlockDescriptor: Record<keyof UserBlockDomain, (o: UserBlockDomain) => string> = {
    id(o: UserBlockDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserBlockDomain): string {
        return o.creatorId.toString()
    },
    userId(o: UserBlockDomain): string {
        return o.userId.toString()
    },
    userBlockReasonId(o: UserBlockDomain): string {
        return o.userBlockReasonId.toString()
    },
    blockedAt(o: UserBlockDomain): string {
        return o.blockedAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    blockedUntil(o: UserBlockDomain): string {
        return o.blockedUntil?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    createdAt(o: UserBlockDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserBlockDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
