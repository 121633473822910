import {ContentUserProductionRewardDomain} from "../../domain/content/ContentUserProductionRewardDomain"

/**
 * 와글와글 보상 프리셋 속성 설명
 */
export const ContentUserProductionRewardDescription: Record<keyof ContentUserProductionRewardDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    value: '지급 솔라',
    displayOrder: '표시 순서',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
