import {UiPolicySearchOptionDescription} from "../../../data/description/ui/UiPolicySearchOptionDescription"
import {UiPolicySearchOption} from "../../../data/search-option/ui/UiPolicySearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function UiPolicySearchOptionField(props: ListOptionFieldProps<UiPolicySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentSuggestionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentSuggestionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentSuggestionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentSuggestionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentSuggestionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentSuggestionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentSuggestionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentSuggestionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentUserProductionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentUserProductionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentUserProductionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentUserProductionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentUserProductionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentUserProductionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeContentUserProductionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeContentUserProductionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeNoticePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeNoticePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeNoticePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeNoticePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeNoticePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeNoticePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiHomeNoticePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiHomeNoticePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiOnboardingPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiOnboardingPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiOnboardingPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiOnboardingPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiOnboardingPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiOnboardingPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiOnboardingPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiOnboardingPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiPolicyPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiPolicyPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiPolicyPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiPolicyPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiPolicyPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiPolicyPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "uiPolicyPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.uiPolicyPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UiPolicySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUiPolicySearchOption(key: keyof UiPolicySearchOption, value: string, into: StatePair<UiPolicySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "uiHomeContentSuggestionPageSizeEq":
            into.setter({
                ...into.value,
                uiHomeContentSuggestionPageSizeEq: Number(value)
            })
            break
        case "uiHomeContentSuggestionPageSizeNeq":
            into.setter({
                ...into.value,
                uiHomeContentSuggestionPageSizeNeq: Number(value)
            })
            break
        case "uiHomeContentSuggestionPageSizeMin":
            into.setter({
                ...into.value,
                uiHomeContentSuggestionPageSizeMin: Number(value)
            })
            break
        case "uiHomeContentSuggestionPageSizeMax":
            into.setter({
                ...into.value,
                uiHomeContentSuggestionPageSizeMax: Number(value)
            })
            break
        case "uiHomeContentUserProductionPageSizeEq":
            into.setter({
                ...into.value,
                uiHomeContentUserProductionPageSizeEq: Number(value)
            })
            break
        case "uiHomeContentUserProductionPageSizeNeq":
            into.setter({
                ...into.value,
                uiHomeContentUserProductionPageSizeNeq: Number(value)
            })
            break
        case "uiHomeContentUserProductionPageSizeMin":
            into.setter({
                ...into.value,
                uiHomeContentUserProductionPageSizeMin: Number(value)
            })
            break
        case "uiHomeContentUserProductionPageSizeMax":
            into.setter({
                ...into.value,
                uiHomeContentUserProductionPageSizeMax: Number(value)
            })
            break
        case "uiHomeNoticePageSizeEq":
            into.setter({
                ...into.value,
                uiHomeNoticePageSizeEq: Number(value)
            })
            break
        case "uiHomeNoticePageSizeNeq":
            into.setter({
                ...into.value,
                uiHomeNoticePageSizeNeq: Number(value)
            })
            break
        case "uiHomeNoticePageSizeMin":
            into.setter({
                ...into.value,
                uiHomeNoticePageSizeMin: Number(value)
            })
            break
        case "uiHomeNoticePageSizeMax":
            into.setter({
                ...into.value,
                uiHomeNoticePageSizeMax: Number(value)
            })
            break
        case "uiOnboardingPageSizeEq":
            into.setter({
                ...into.value,
                uiOnboardingPageSizeEq: Number(value)
            })
            break
        case "uiOnboardingPageSizeNeq":
            into.setter({
                ...into.value,
                uiOnboardingPageSizeNeq: Number(value)
            })
            break
        case "uiOnboardingPageSizeMin":
            into.setter({
                ...into.value,
                uiOnboardingPageSizeMin: Number(value)
            })
            break
        case "uiOnboardingPageSizeMax":
            into.setter({
                ...into.value,
                uiOnboardingPageSizeMax: Number(value)
            })
            break
        case "uiPolicyPageSizeEq":
            into.setter({
                ...into.value,
                uiPolicyPageSizeEq: Number(value)
            })
            break
        case "uiPolicyPageSizeNeq":
            into.setter({
                ...into.value,
                uiPolicyPageSizeNeq: Number(value)
            })
            break
        case "uiPolicyPageSizeMin":
            into.setter({
                ...into.value,
                uiPolicyPageSizeMin: Number(value)
            })
            break
        case "uiPolicyPageSizeMax":
            into.setter({
                ...into.value,
                uiPolicyPageSizeMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
