import {MediaFile} from "../ui/Media";
import {GetObjectByteArrayCommandOutput} from "../io/StorageIO";
import {randomString} from "./StringUtils";
export function createMediaFileFromStorageObject(o: GetObjectByteArrayCommandOutput, name: string = randomString({ length: 10 })) {
    const mediaFile: MediaFile = {
        file: new File([o.Body.buffer], name, { type: o.ContentType }),
        arrayBuffer: o.Body.buffer,
        text: new TextDecoder().decode(o.Body.buffer)
    }

    return mediaFile
}