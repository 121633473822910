import {RouterProps} from "../../../RouterProps";
import {Route, Routes} from "react-router-dom";
import SuggestionRouter from "./suggestion/SuggestionRouter";
import UserProductionRouter from "./userProduction/UserProductionRouter";
import {RouterNavigationButton} from "../../../RouterViews";

export default function ContentRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/suggestions/*'} element={<SuggestionRouter {...props} />} />
            <Route path={'/userProductions/*'} element={<UserProductionRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <RouterNavigationButton
                {...props}
                href={'/ui/home/contents/suggestions'}>
                홈 추천 콘텐츠
            </RouterNavigationButton>
            <RouterNavigationButton
                {...props}
                href={'/ui/home/contents/userProductions'}>
                홈 와글와글 콘텐츠
            </RouterNavigationButton>
        </div>
    </>
}