import {ContentCommentReportReasonModificationDomain} from "../../domain/content/ContentCommentReportReasonModificationDomain"

export const ContentCommentReportReasonModificationDescriptor: Record<keyof ContentCommentReportReasonModificationDomain, (o: ContentCommentReportReasonModificationDomain) => string> = {
    id(o: ContentCommentReportReasonModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentCommentReportReasonModificationDomain): string {
        return o.creatorId.toString()
    },
    contentCommentReportReasonId(o: ContentCommentReportReasonModificationDomain): string {
        return o.contentCommentReportReasonId.toString()
    },
    body(o: ContentCommentReportReasonModificationDomain): string {
        return o.body
    },
    displayOrder(o: ContentCommentReportReasonModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: ContentCommentReportReasonModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
