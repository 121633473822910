import {Body1, Spinner} from "@fluentui/react-components";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {ContentReportValueObject} from "../../../data/value-object/content/ContentReportValueObject";
import {useEffect} from "react";
import {ContentReportIO} from "../../../io/ContentIO";
import {ContentReportDescription} from "../../../data/description/content/ContentReportDescription";
import {ContentReportDomain} from "../../../data/domain/content/ContentReportDomain";
import {ContentReportDescriptor} from "../../../data/descriptor/content/ContentReportDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentReportId: string }>()
    const item = useStatePair<ContentReportValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentReportId !== undefined) {
            ContentReportIO
                .getById(BigInt(params.contentReportId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentReportValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentReportIO}
                description={ContentReportDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentReportDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{ContentReportDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentReportDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentId":
            return <a href={`/contents/${props.item.contentId}`}><Body1>{ContentReportDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentReportReasonId":
            return <a href={`/contents/reports/reasons/${props.item.contentReportReasonId}`}><Body1>{ContentReportDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}