import {ContentPolicyPutDataTransferObject} from "../../data-transfer-object/content/ContentPolicyPutDataTransferObject"

/**
 * 콘텐츠 정책 속성 설명
 */
export const ContentPolicyPutDataTransferObjectDescription: Record<keyof ContentPolicyPutDataTransferObject, string> = {
    contentPageSize: '콘텐츠 페이지 크기',
    contentReportAutoHiddenCount: '자동 숨김을 위한 신고 수',
    contentCommentPageSize: '콘텐츠 댓글 페이지 크기',
    contentCommentWithAudioSolarAmount: '콘텐츠 음성 댓글 소모 솔라',
    contentCommentReportAutoHiddenCount: '콘텐츠 댓글 자동 숨김 신고 수',
    contentCommentThresholdMinutes: '콘텐츠 댓글 작성 제한 단위 시간',
    contentCommentThresholdCount: '콘텐츠 댓글 작성 제한 횟수',
    contentCommentLikePageSize: '콘텐츠 댓글 좋아요 페이지 크기',
    contentCommentReportPageSize: '콘텐츠 댓글 신고 페이지 크기',
    contentCommentReportReasonPageSize: '콘텐츠 댓글 신고 사유 페이지 크기',
    contentParticipationPageSize: '콘텐츠 참여 페이지 크기',
    contentDailyParticipationExp: '콘텐츠 참여 EXP(오늘의 단어)',
    contentDailyParticipationPassExp: '콘텐츠 합격 EXP(오늘의 단어)',
    contentRepeatParticipationThresholdMinutes: '콘텐츠 참여 제한 단위 시간(따라말하기)',
    contentRepeatParticipationThresholdCount: '콘텐츠 참여 제한 횟수(따라말하기)',
    contentRepeatParticipationExp: '콘텐츠 참여 EXP(따라말하기)',
    contentRepeatParticipationPassExp: '콘텐츠 합격 EXP(따라말하기)',
    contentM10nParticipationThresholdMinutes: '콘텐츠 참여 제한 단위 시간(암기하기)',
    contentM10nParticipationThresholdCount: '콘텐츠 참여 제한 횟수(암기하기)',
    contentM10nParticipationExp: '콘텐츠 참여 EXP(암기하기)',
    contentM10nParticipationPassExp: '콘텐츠 합격 EXP(암기하기)',
    contentUserProductionParticipationThresholdMinutes: '콘텐츠 참여 제한 단위 시간(와글와글)',
    contentUserProductionParticipationThresholdCount: '콘텐츠 참여 제한 횟수(와글와글)',
    contentUserProductionParticipationExp: '콘텐츠 참여 EXP(와글와글)',
    contentUserProductionParticipationPassExp: '콘텐츠 합격 EXP(와글와글)',
    contentUserProductionPresetPageSize: '콘텐츠 프리셋 페이지 크기(와글와글)',
    contentUserProductionMinimumAssetLunarAmount: '콘텐츠 제작 최소 루나',
    contentUserProductionMinimumUserLevelId: '콘텐츠 제작 최소 레벨',
    contentUserProductionMinimumAccuracy: '콘텐츠 제작 최소 일치율',
    contentUserProductionPassAccuracy: '콘텐츠 일치율(와글와글)',
    contentUserProductionPassHighAccuracy: '콘텐츠 높은 일치율(와글와글)',
    contentUserProductionPassSolarAmountHighAccuracy: '콘텐츠 높은 일치율 지급 솔라(와글와글)',
    contentUserProductionStarAmount: '콘텐츠 소모 스타(와글와글)',
    contentUserProductionDefaultDetailUrl: '콘텐츠 더 알아보기 기본 URL',
    contentUserProductionDefaultDetailUrlBrowser: '콘텐츠 더 알아보기 기본 브라우저',
    contentUserProductionDefaultResultUrl: '콘텐츠 참여 결과 기본 URL',
    contentUserProductionDefaultResultUrlBrowser: '콘텐츠 참여 결과 기본 브라우저',
    contentUserProductionScriptPageSize: '콘텐츠 스크립트 페이지 크기(와글와글)',
    contentLikePageSize: '콘텐츠 좋아요 페이지 크기',
    contentPolicyPageSize: '콘텐츠 정책 페이지 크기',
    contentQueryPageSize: '콘텐츠 검색 페이지 크기',
    contentQueryPopularDurationMinutes: '콘텐츠 인기 검색어 갱신 주기(분)',
    contentQueryPopularPageSize: '콘텐츠 인기 검색어 페이지 크기',
    contentReportReasonPageSize: '콘텐츠 신고 사유 페이지 크기',
    contentReportPageSize: '콘텐츠 신고 페이지 크기',
    contentReportDailyThreshold: '콘텐츠 일일 신고 가능 횟수',
    contentDetailUrlPageSize: '콘텐츠 더 알아보기 URL 페이지 크기',
    contentDetailUrlClickPageSize: '콘텐츠 더 알아보기 URL 클릭 페이지 크기',
    contentDetailUrlClickExp: '콘텐츠 더 알아보기 URL 클릭 경험치',
    contentResultUrlPageSize: '콘텐츠 참여 결과 URL 페이지 크기',
    contentResultUrlClickPageSize: '콘텐츠 참여 결과 URL 클릭 페이지 크기',
    contentResultUrlClickExp: '콘텐츠 참여 결과 URL 클릭 경험치',
    contentReviewPageSize: '콘텐츠 평가 페이지 크기(와글와글)',
    contentTargetPageSize: '콘텐츠 대상 페이지 크기'
}
