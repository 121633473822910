import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import React, {useCallback} from "react";
import {AssetSolarUsageIO} from "../../../../io/AssetIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFinishDialog} from "../../../PostField";
import {useAssetSolarUsagePostState} from "../../../../data/data-transfer-object-state/asset/AssetSolarUsagePostState";
import {AssetSolarUsagePostFields} from "../../../data-transfer-object-field/asset/AssetSolarUsagePostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useAssetSolarUsagePostState()

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        AssetSolarUsageIO
            .post(state.toDataTransferObject())
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <AssetSolarUsagePostFields
                    {...props}
                    state={state} />
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/assets/solars/usages'}/>
    </>
}