import {UserNotificationSubject} from "../../constant/user/UserNotificationSubject"
import {UserNotificationPostDataTransferObject} from "../../data-transfer-object/user/UserNotificationPostDataTransferObject"
import {UserNotificationValueObject} from "../../value-object/user/UserNotificationValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type UserNotificationPostState = {
    userId: StatePair<bigint>
    title: StatePair<string>
    body: StatePair<string>
    subject: StatePair<UserNotificationSubject>
    deepLink: StatePair<string | undefined>
    push: StatePair<boolean>
    toDataTransferObject(): UserNotificationPostDataTransferObject
    fromValueObject(vo: UserNotificationValueObject): void
    values(): [bigint, string, string, UserNotificationSubject, string | undefined, boolean]
}

export function useUserNotificationPostState(): UserNotificationPostState {
    return {
        userId: useStatePair<bigint>(0n),
        title: useStatePair<string>(''),
        body: useStatePair<string>(''),
        subject: useStatePair<UserNotificationSubject>(UserNotificationSubject.Notice),
        deepLink: useStatePair<string | undefined>(undefined),
        push: useStatePair<boolean>(false),
        toDataTransferObject(): UserNotificationPostDataTransferObject {
            return {
                userId: this.userId.value,
                title: this.title.value,
                body: this.body.value,
                subject: this.subject.value,
                deepLink: this.deepLink.value,
                push: this.push.value,
            }
        },
        fromValueObject(vo: UserNotificationValueObject) {
            this.userId.setter(vo.userId)
            this.title.setter(vo.title)
            this.body.setter(vo.body)
            this.subject.setter(vo.subject)
            this.deepLink.setter(vo.deepLink)
        },
        values(): [bigint, string, string, UserNotificationSubject, string | undefined, boolean] {
            return [
                this.userId.value,
                this.title.value,
                this.body.value,
                this.subject.value,
                this.deepLink.value,
                this.push.value,
            ]
        }
    }
}
