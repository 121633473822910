import {UserBlockReasonModificationDomain} from "../../domain/user/UserBlockReasonModificationDomain"
import {UserBlockReasonId} from "../../id/user/UserBlockReasonId"
import {UserBlockReasonModificationId} from "../../id/user/UserBlockReasonModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 정지 사유 수정 이력
 */
export class UserBlockReasonModificationValueObject implements ValueObject, UserBlockReasonModificationDomain {
    /**
     * 기본키
     */
    id: UserBlockReasonModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReasonId: UserBlockReasonId
    /**
     * 사유
     */
    body: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UserBlockReasonModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userBlockReasonId = BigInt(o.userBlockReasonId)
        this.body = String(o.body)
        this.createdAt = new Date(o.createdAt)
    }
}
