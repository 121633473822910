import {ContentReportDomain} from "../../domain/content/ContentReportDomain"

/**
 * 콘텐츠 신고 속성 설명
 */
export const ContentReportDescription: Record<keyof ContentReportDomain, string> = {
    id: '기본키',
    contentId: '콘텐츠 ID',
    contentReportReasonId: '콘텐츠 신고 사유 ID',
    userId: '사용자 ID',
    createdAt: '생성일'
}
