import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {EnvNoticeValueObject} from "../../../data/value-object/env/EnvNoticeValueObject";
import {EnvNoticeSearchOption} from "../../../data/search-option/env/EnvNoticeSearchOption";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvNoticeIO} from "../../../io/EnvIO";
import {EnvNoticeDescription} from "../../../data/description/env/EnvNoticeDescription";
import {EnvNoticeDescriptor} from "../../../data/descriptor/env/EnvNoticeDescriptor";
import ListOption from "../../ListOption";
import {EnvNoticeSearchOptionDescription} from "../../../data/description/env/EnvNoticeSearchOptionDescription";
import {
    applyEnvNoticeSearchOption,
    EnvNoticeSearchOptionField
} from "../../search-option-field/env/EnvNoticeSearchOptionField";
import ListOrder from "../../ListOrder";
import {SearchIndex} from "../../../io/HttpClient";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<EnvNoticeValueObject[]>([])
    const option = useStatePair<EnvNoticeSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={EnvNoticeIO}
                items={items}
                option={option.value}
                index={index}
                description={EnvNoticeDescription}
                descriptor={EnvNoticeDescriptor}
                showPost
                onPostClick={() => document.location = '/env/notices/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/env/notices/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={EnvNoticeSearchOptionDescription}
                fields={EnvNoticeSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyEnvNoticeSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvNoticeDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}