import {EnvFaqVerboseDomain} from "../../domain/env/EnvFaqVerboseDomain"
import {EnvFaqId} from "../../id/env/EnvFaqId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 자주 묻는 질문 상세
 */
export class EnvFaqVerboseValueObject implements VerboseValueObject, EnvFaqVerboseDomain {
    /**
     * 기본키
     */
    id: EnvFaqId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 제목
     */
    title: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 하위 항목 수
     */
    envFaqItemCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof EnvFaqVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.title = String(o.title)
        this.displayOrder = Number(o.displayOrder)
        this.envFaqItemCount = Number(o.envFaqItemCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
