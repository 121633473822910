import {AssetStarChargeDomain} from "../../domain/asset/AssetStarChargeDomain"
import {AssetStarChargeId} from "../../id/asset/AssetStarChargeId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 스타 충전
 */
export class AssetStarChargeValueObject implements ValueObject, AssetStarChargeDomain {
    /**
     * 기본키
     */
    id: AssetStarChargeId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 충전 예정 시각
     */
    scheduledAt: Date
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetStarChargeDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.scheduledAt = new Date(o.scheduledAt)
        this.createdAt = new Date(o.createdAt)
    }
}
