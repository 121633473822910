import {ContentSearchOption} from "../../search-option/content/ContentSearchOption"

/**
 * 참여 가능 항목 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentSearchOptionDescription: Record<keyof ContentSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    advertiserIdEq: '광고주 ID 일치',
    advertiserIdNeq: '광고주 ID 불일치',
    advertiserIdMin: '광고주 ID 최소',
    advertiserIdMax: '광고주 ID 최대',
    advertiserIdIsNull: '광고주 ID 값 존재 여부',
    typeEq: '유형 일치',
    typeNeq: '유형 불일치',
    typeMin: '유형 최소',
    typeMax: '유형 최대',
    typeIn: '유형 포함',
    participationMethodEq: '참여 방법 일치',
    participationMethodNeq: '참여 방법 불일치',
    participationMethodMin: '참여 방법 최소',
    participationMethodMax: '참여 방법 최대',
    participationMethodIn: '참여 방법 포함',
    titleEq: '제목 일치',
    titleNeq: '제목 불일치',
    titleMin: '제목 최소',
    titleMax: '제목 최대',
    titleLike: '제목 포함',
    titleNotLike: '제목 제외',
    titleHighlightEq: '제목 강조 일치',
    titleHighlightNeq: '제목 강조 불일치',
    titleHighlightMin: '제목 강조 최소',
    titleHighlightMax: '제목 강조 최대',
    titleHighlightLike: '제목 강조 포함',
    titleHighlightNotLike: '제목 강조 제외',
    titleHighlightIsNull: '제목 강조 값 존재 여부',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    scriptEq: '스크립트 일치',
    scriptNeq: '스크립트 불일치',
    scriptMin: '스크립트 최소',
    scriptMax: '스크립트 최대',
    scriptLike: '스크립트 포함',
    scriptNotLike: '스크립트 제외',
    scriptVisibilityEq: '스크립트 표시 유형 일치',
    scriptVisibilityNeq: '스크립트 표시 유형 불일치',
    scriptVisibilityMin: '스크립트 표시 유형 최소',
    scriptVisibilityMax: '스크립트 표시 유형 최대',
    scriptVisibilityIn: '스크립트 표시 유형 포함',
    contentDetailUrlIdNeq: '콘텐츠 더 알아보기 URL ID 불일치',
    contentDetailUrlIdMin: '콘텐츠 더 알아보기 URL ID 최소',
    contentDetailUrlIdMax: '콘텐츠 더 알아보기 URL ID 최대',
    contentResultUrlIdNeq: '콘텐츠 참여 결과 URL ID 불일치',
    contentResultUrlIdMin: '콘텐츠 참여 결과 URL ID 최소',
    contentResultUrlIdMax: '콘텐츠 참여 결과 URL ID 최대',
    gradingMessageEq: '채점 중 텍스트 일치',
    gradingMessageNeq: '채점 중 텍스트 불일치',
    gradingMessageMin: '채점 중 텍스트 최소',
    gradingMessageMax: '채점 중 텍스트 최대',
    gradingMessageLike: '채점 중 텍스트 포함',
    gradingMessageNotLike: '채점 중 텍스트 제외',
    gradingMessageIsNull: '채점 중 텍스트 값 존재 여부',
    amountEq: '보상 지급 수량 일치',
    amountNeq: '보상 지급 수량 불일치',
    amountMin: '보상 지급 수량 최소',
    amountMax: '보상 지급 수량 최대',
    passAccuracyEq: '합격 기준(%) 일치',
    passAccuracyNeq: '합격 기준(%) 불일치',
    passAccuracyMin: '합격 기준(%) 최소',
    passAccuracyMax: '합격 기준(%) 최대',
    passAccuracyHighEq: '높은 일치율 기준(%) 일치',
    passAccuracyHighNeq: '높은 일치율 기준(%) 불일치',
    passAccuracyHighMin: '높은 일치율 기준(%) 최소',
    passAccuracyHighMax: '높은 일치율 기준(%) 최대',
    passAccuracyHighIsNull: '높은 일치율 기준(%) 값 존재 여부',
    passSolarAmountEq: '합격 지급 솔라 일치',
    passSolarAmountNeq: '합격 지급 솔라 불일치',
    passSolarAmountMin: '합격 지급 솔라 최소',
    passSolarAmountMax: '합격 지급 솔라 최대',
    passSolarAmountHighAccuracyEq: '높은 일치율 지급 솔라 일치',
    passSolarAmountHighAccuracyNeq: '높은 일치율 지급 솔라 불일치',
    passSolarAmountHighAccuracyMin: '높은 일치율 지급 솔라 최소',
    passSolarAmountHighAccuracyMax: '높은 일치율 지급 솔라 최대',
    starAmountEq: '소모 스타 일치',
    starAmountNeq: '소모 스타 불일치',
    starAmountMin: '소모 스타 최소',
    starAmountMax: '소모 스타 최대',
    difficultyEq: '난이도 일치',
    difficultyNeq: '난이도 불일치',
    difficultyMin: '난이도 최소',
    difficultyMax: '난이도 최대',
    difficultyIn: '난이도 포함',
    headerImageCountEq: '상단 이미지 수 일치',
    headerImageCountNeq: '상단 이미지 수 불일치',
    headerImageCountMin: '상단 이미지 수 최소',
    headerImageCountMax: '상단 이미지 수 최대',
    descriptionImageCountEq: '설명 이미지 수 일치',
    descriptionImageCountNeq: '설명 이미지 수 불일치',
    descriptionImageCountMin: '설명 이미지 수 최소',
    descriptionImageCountMax: '설명 이미지 수 최대',
    exposedAtEq: '노출 시작일 일치',
    exposedAtNeq: '노출 시작일 불일치',
    exposedAtMin: '노출 시작일 최소',
    exposedAtMax: '노출 시작일 최대',
    startAtEq: '참여 시작일 일치',
    startAtNeq: '참여 시작일 불일치',
    startAtMin: '참여 시작일 최소',
    startAtMax: '참여 시작일 최대',
    endAtEq: '참여 종료일 일치',
    endAtNeq: '참여 종료일 불일치',
    endAtMin: '참여 종료일 최소',
    endAtMax: '참여 종료일 최대',
    statusFlagsEq: '상태 플래그 일치',
    statusFlagsNeq: '상태 플래그 불일치',
    statusFlagsAny: '상태 플래그 일부 해당',
    statusFlagsAll: '상태 플래그 전체 해당',
    statusFlagsNone: '상태 플래그 제외',
    contentTargetCountEq: '대상 수 일치',
    contentTargetCountNeq: '대상 수 불일치',
    contentTargetCountMin: '대상 수 최소',
    contentTargetCountMax: '대상 수 최대',
    participationCountEq: '참여 수 일치',
    participationCountNeq: '참여 수 불일치',
    participationCountMin: '참여 수 최소',
    participationCountMax: '참여 수 최대',
    participationPassCountEq: '합격 수 일치',
    participationPassCountNeq: '합격 수 불일치',
    participationPassCountMin: '합격 수 최소',
    participationPassCountMax: '합격 수 최대',
    participationHighAccuracyCountEq: '높은 일치율 수 일치',
    participationHighAccuracyCountNeq: '높은 일치율 수 불일치',
    participationHighAccuracyCountMin: '높은 일치율 수 최소',
    participationHighAccuracyCountMax: '높은 일치율 수 최대',
    commentCountEq: '댓글 수 일치',
    commentCountNeq: '댓글 수 불일치',
    commentCountMin: '댓글 수 최소',
    commentCountMax: '댓글 수 최대',
    reportCountEq: '(와글와글) 신고 수 일치',
    reportCountNeq: '(와글와글) 신고 수 불일치',
    reportCountMin: '(와글와글) 신고 수 최소',
    reportCountMax: '(와글와글) 신고 수 최대',
    reviewPositiveCountEq: '(와글와글) 긍정 평가 수 일치',
    reviewPositiveCountNeq: '(와글와글) 긍정 평가 수 불일치',
    reviewPositiveCountMin: '(와글와글) 긍정 평가 수 최소',
    reviewPositiveCountMax: '(와글와글) 긍정 평가 수 최대',
    reviewNeutralCountEq: '(와글와글) 중립 평가 수 일치',
    reviewNeutralCountNeq: '(와글와글) 중립 평가 수 불일치',
    reviewNeutralCountMin: '(와글와글) 중립 평가 수 최소',
    reviewNeutralCountMax: '(와글와글) 중립 평가 수 최대',
    reviewNegativeCountEq: '(와글와글) 부정 평가 수 일치',
    reviewNegativeCountNeq: '(와글와글) 부정 평가 수 불일치',
    reviewNegativeCountMin: '(와글와글) 부정 평가 수 최소',
    reviewNegativeCountMax: '(와글와글) 부정 평가 수 최대',
    likeCountEq: '좋아요 수 일치',
    likeCountNeq: '좋아요 수 불일치',
    likeCountMin: '좋아요 수 최소',
    likeCountMax: '좋아요 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
