import {EnvConstructionModificationDomain} from "../../domain/env/EnvConstructionModificationDomain"

/**
 * 작업 수정 이력 속성 설명
 */
export const EnvConstructionModificationDescription: Record<keyof EnvConstructionModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envConstructionId: '작업 ID',
    title: '제목',
    body: '내용',
    startAt: '시작일',
    endAt: '종료일',
    createdAt: '수정일'
}
