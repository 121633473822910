import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {SortOrder} from "ts-protocol-extension";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {
    AssetLunarPurchaseOptionValueObject
} from "../../../../data/value-object/asset/AssetLunarPurchaseOptionValueObject";
import {
    AssetLunarPurchaseOptionSearchOption
} from "../../../../data/search-option/asset/AssetLunarPurchaseOptionSearchOption";
import {AssetLunarPurchaseOptionIO} from "../../../../io/AssetIO";
import {
    AssetLunarPurchaseOptionDescription
} from "../../../../data/description/asset/AssetLunarPurchaseOptionDescription";
import {AssetLunarPurchaseOptionDescriptor} from "../../../../data/descriptor/asset/AssetLunarPurchaseOptionDescriptor";
import {
    AssetLunarPurchaseOptionSearchOptionDescription
} from "../../../../data/description/asset/AssetLunarPurchaseOptionSearchOptionDescription";
import {
    applyAssetLunarPurchaseOptionSearchOption,
    AssetLunarPurchaseOptionSearchOptionField
} from "../../../search-option-field/asset/AssetLunarPurchaseOptionSearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetLunarPurchaseOptionValueObject[]>([])
    const option = useStatePair<AssetLunarPurchaseOptionSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetLunarPurchaseOptionIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetLunarPurchaseOptionDescription}
                descriptor={AssetLunarPurchaseOptionDescriptor}
                showPost
                onPostClick={() => document.location = '/assets/lunars/purchaseOptions/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/lunars/purchaseOptions/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetLunarPurchaseOptionSearchOptionDescription}
                fields={AssetLunarPurchaseOptionSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetLunarPurchaseOptionSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetLunarPurchaseOptionDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}