import {ContentTargetSearchOption} from "../../search-option/content/ContentTargetSearchOption"

/**
 * 콘텐츠 노출 대상 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentTargetSearchOptionDescription: Record<keyof ContentTargetSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    contentIdEq: '콘텐츠 ID 일치',
    contentIdNeq: '콘텐츠 ID 불일치',
    contentIdMin: '콘텐츠 ID 최소',
    contentIdMax: '콘텐츠 ID 최대',
    envCountryIdEq: '국가 ID 일치',
    envCountryIdNeq: '국가 ID 불일치',
    envCountryIdMin: '국가 ID 최소',
    envCountryIdMax: '국가 ID 최대',
    envCountryIdIsNull: '국가 ID 값 존재 여부',
    userGenderEq: '성별 일치',
    userGenderNeq: '성별 불일치',
    userGenderMin: '성별 최소',
    userGenderMax: '성별 최대',
    userGenderIn: '성별 포함',
    userGenderIsNull: '성별 값 존재 여부',
    userBirthYearMinEq: '출생년도 최소 일치',
    userBirthYearMinNeq: '출생년도 최소 불일치',
    userBirthYearMinMin: '출생년도 최소 최소',
    userBirthYearMinMax: '출생년도 최소 최대',
    userBirthYearMinIsNull: '출생년도 최소 값 존재 여부',
    userBirthYearMaxEq: '출생년도 최대 일치',
    userBirthYearMaxNeq: '출생년도 최대 불일치',
    userBirthYearMaxMin: '출생년도 최대 최소',
    userBirthYearMaxMax: '출생년도 최대 최대',
    userBirthYearMaxIsNull: '출생년도 최대 값 존재 여부',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
