import {UserLevelDomain} from "../../domain/user/UserLevelDomain"
import {UserId} from "../../id/user/UserId"
import {UserLevelId} from "../../id/user/UserLevelId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 레벨
 */
export class UserLevelValueObject implements ValueObject, UserLevelDomain {
    /**
     * 기본키
     */
    id: UserLevelId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 이름
     */
    name: string
    /**
     * 필요 경험치
     */
    exp: number
    /**
     * 보상 루나
     */
    rewardAssetLunarAmount: number
    /**
     * 보상 솔라
     */
    rewardAssetSolarAmount: number
    /**
     * 보상 스타
     */
    rewardAssetStarAmount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof UserLevelDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.name = String(o.name)
        this.exp = Number(o.exp)
        this.rewardAssetLunarAmount = Number(o.rewardAssetLunarAmount)
        this.rewardAssetSolarAmount = Number(o.rewardAssetSolarAmount)
        this.rewardAssetStarAmount = Number(o.rewardAssetStarAmount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
