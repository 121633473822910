import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryPopularDomain} from "../../domain/content/ContentQueryPopularDomain"
import {ContentQueryPopularId} from "../../id/content/ContentQueryPopularId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 인기 검색어
 */
export class ContentQueryPopularValueObject implements ValueObject, ContentQueryPopularDomain {
    /**
     * 기본키
     */
    id: ContentQueryPopularId
    /**
     * 콘텐츠 유형
     */
    contentType: ContentType
    /**
     * 순위
     */
    displayOrder: number
    /**
     * 순위 변동
     */
    displayOrderDiff?: number
    /**
     * 질의어
     */
    body: string
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentQueryPopularDomain, any>) {
        this.id = BigInt(o.id)
        this.contentType = o.contentType as ContentType
        this.displayOrder = Number(o.displayOrder)
        if (o.displayOrderDiff !== undefined && o.displayOrderDiff !== null) {
            this.displayOrderDiff = Number(o.displayOrderDiff)
        }
        this.body = String(o.body)
        this.createdAt = new Date(o.createdAt)
    }
}
