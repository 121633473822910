import {AssetPolicyDomain} from "../../domain/asset/AssetPolicyDomain"

export const AssetPolicyDescriptor: Record<keyof AssetPolicyDomain, (o: AssetPolicyDomain) => string> = {
    id(o: AssetPolicyDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetPolicyDomain): string {
        return o.creatorId.toString()
    },
    assetCouponPageSize(o: AssetPolicyDomain): string {
        return o.assetCouponPageSize.toString()
    },
    assetCouponUsagePageSize(o: AssetPolicyDomain): string {
        return o.assetCouponUsagePageSize.toString()
    },
    assetLunarUsagePageSize(o: AssetPolicyDomain): string {
        return o.assetLunarUsagePageSize.toString()
    },
    assetLunarExchangeOptionPageSize(o: AssetPolicyDomain): string {
        return o.assetLunarExchangeOptionPageSize.toString()
    },
    assetLunarExchangeExp(o: AssetPolicyDomain): string {
        return o.assetLunarExchangeExp.toString()
    },
    assetLunarPurchaseOptionPageSize(o: AssetPolicyDomain): string {
        return o.assetLunarPurchaseOptionPageSize.toString()
    },
    assetLunarPurchaseExp(o: AssetPolicyDomain): string {
        return o.assetLunarPurchaseExp.toString()
    },
    assetPolicyPageSize(o: AssetPolicyDomain): string {
        return o.assetPolicyPageSize.toString()
    },
    assetSolarUsagePageSize(o: AssetPolicyDomain): string {
        return o.assetSolarUsagePageSize.toString()
    },
    assetSolarWithdrawalPageSize(o: AssetPolicyDomain): string {
        return o.assetSolarWithdrawalPageSize.toString()
    },
    assetSolarWithdrawalOptionPageSize(o: AssetPolicyDomain): string {
        return o.assetSolarWithdrawalOptionPageSize.toString()
    },
    assetSolarWithdrawalExp(o: AssetPolicyDomain): string {
        return o.assetSolarWithdrawalExp.toString()
    },
    assetStarChargePageSize(o: AssetPolicyDomain): string {
        return o.assetStarChargePageSize.toString()
    },
    assetStarUsagePageSize(o: AssetPolicyDomain): string {
        return o.assetStarUsagePageSize.toString()
    },
    assetStarUsageExp(o: AssetPolicyDomain): string {
        return o.assetStarUsageExp.toString()
    },
    assetStarExchangeOptionPageSize(o: AssetPolicyDomain): string {
        return o.assetStarExchangeOptionPageSize.toString()
    },
    assetStarExchangeExp(o: AssetPolicyDomain): string {
        return o.assetStarExchangeExp.toString()
    },
    assetStarChargeMinutes(o: AssetPolicyDomain): string {
        return o.assetStarChargeMinutes.toString()
    },
    assetStarChargeMax(o: AssetPolicyDomain): string {
        return o.assetStarChargeMax.toString()
    },
    createdAt(o: AssetPolicyDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: AssetPolicyDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
