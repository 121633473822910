import {useParams} from "react-router-dom";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    ContentCommentReportValueObject
} from "../../../../data/value-object/content/ContentCommentReportValueObject";
import {useEffect} from "react";
import {ContentCommentReportIO} from "../../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {ContentCommentReportDescription} from "../../../../data/description/content/ContentCommentReportDescription";
import {ContentCommentReportDomain} from "../../../../data/domain/content/ContentCommentReportDomain";
import {ContentCommentReportDescriptor} from "../../../../data/descriptor/content/ContentCommentReportDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentCommentReportId: string }>()
    const item = useStatePair<ContentCommentReportValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentCommentReportId !== undefined) {
            ContentCommentReportIO
                .getById(BigInt(params.contentCommentReportId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentCommentReportValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentCommentReportIO}
                description={ContentCommentReportDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentCommentReportDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{ContentCommentReportDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentCommentReportDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentCommentId":
            return <a href={`/contents/comments/${props.item.contentCommentId}`}><Body1>{ContentCommentReportDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentCommentReportReasonId":
            return <a href={`/contents/comments/reports/reasons/${props.item.contentCommentReportReasonId}`}><Body1>{ContentCommentReportDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}