import {EnvAgreementSearchOption} from "../../search-option/env/EnvAgreementSearchOption"

/**
 * 이용 약관 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvAgreementSearchOptionDescription: Record<keyof EnvAgreementSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    typeEq: '유형 일치',
    typeNeq: '유형 불일치',
    typeMin: '유형 최소',
    typeMax: '유형 최대',
    typeIn: '유형 포함',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    userAgreementCountEq: '약관에 동의한 사용자 수 일치',
    userAgreementCountNeq: '약관에 동의한 사용자 수 불일치',
    userAgreementCountMin: '약관에 동의한 사용자 수 최소',
    userAgreementCountMax: '약관에 동의한 사용자 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
