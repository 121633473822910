import {ContentReportReasonVerboseDomain} from "../../domain/content/ContentReportReasonVerboseDomain"
import {ContentReportReasonId} from "../../id/content/ContentReportReasonId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 신고 사유 상세
 */
export class ContentReportReasonVerboseValueObject implements VerboseValueObject, ContentReportReasonVerboseDomain {
    /**
     * 기본키
     */
    id: ContentReportReasonId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 내용
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 신고 수
     */
    reportCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof ContentReportReasonVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.reportCount = Number(o.reportCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
