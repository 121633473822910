export enum AssetSolarUsageType {
    /**
     * 관리자 지급
     */
    Admin = 'Admin',

    /**
     * 콘텐츠 참여
     */
    ContentParticipation = 'ContentParticipation',

    /**
     * 솔라를 사용하여 루나 교환
     */
    LunarExchange = 'LunarExchange',

    /**
     * 솔라를 사용하여 스타 교환
     */
    StarExchange = 'StarExchange',

    /**
     * 인출
     */
    Withdrawal = 'Withdrawal',

    /**
     * 회원가입
     */
    SignUp = 'SignUp',

    /**
     * 추천인
     */
    UserRecommender = 'UserRecommender',

    /**
     * 피추천인
     */
    UserRecommendee = 'UserRecommendee',

    /**
     * 레벨업 보상
     */
    UserLevelReward = 'UserLevelReward',

    /**
     * 랭크 보상
     */
    UserRankReward = 'UserRankReward',

    /**
     * 쿠폰
     */
    Coupon = 'Coupon'
}

export namespace AssetSolarUsageType {
    export function description(o: AssetSolarUsageType) {
        switch (o) {
            case AssetSolarUsageType.Admin: return '관리자 지급'
            case AssetSolarUsageType.ContentParticipation: return '콘텐츠 참여'
            case AssetSolarUsageType.LunarExchange: return '솔라를 사용하여 루나 교환'
            case AssetSolarUsageType.StarExchange: return '솔라를 사용하여 스타 교환'
            case AssetSolarUsageType.Withdrawal: return '인출'
            case AssetSolarUsageType.SignUp: return '회원가입'
            case AssetSolarUsageType.UserRecommender: return '추천인'
            case AssetSolarUsageType.UserRecommendee: return '피추천인'
            case AssetSolarUsageType.UserLevelReward: return '레벨업 보상'
            case AssetSolarUsageType.UserRankReward: return '랭크 보상'
            case AssetSolarUsageType.Coupon: return '쿠폰'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: AssetSolarUsageType.Admin, label: description(AssetSolarUsageType.Admin) },
        { value: AssetSolarUsageType.ContentParticipation, label: description(AssetSolarUsageType.ContentParticipation) },
        { value: AssetSolarUsageType.LunarExchange, label: description(AssetSolarUsageType.LunarExchange) },
        { value: AssetSolarUsageType.StarExchange, label: description(AssetSolarUsageType.StarExchange) },
        { value: AssetSolarUsageType.Withdrawal, label: description(AssetSolarUsageType.Withdrawal) },
        { value: AssetSolarUsageType.SignUp, label: description(AssetSolarUsageType.SignUp) },
        { value: AssetSolarUsageType.UserRecommender, label: description(AssetSolarUsageType.UserRecommender) },
        { value: AssetSolarUsageType.UserRecommendee, label: description(AssetSolarUsageType.UserRecommendee) },
        { value: AssetSolarUsageType.UserLevelReward, label: description(AssetSolarUsageType.UserLevelReward) },
        { value: AssetSolarUsageType.UserRankReward, label: description(AssetSolarUsageType.UserRankReward) },
        { value: AssetSolarUsageType.Coupon, label: description(AssetSolarUsageType.Coupon) }
    ]
}
