import {UserRankDuration} from "../../constant/user/UserRankDuration"
import {EnvPolicyDomain} from "../../domain/env/EnvPolicyDomain"

export const EnvPolicyDescriptor: Record<keyof EnvPolicyDomain, (o: EnvPolicyDomain) => string> = {
    id(o: EnvPolicyDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvPolicyDomain): string {
        return o.creatorId.toString()
    },
    envAdminPageSize(o: EnvPolicyDomain): string {
        return o.envAdminPageSize.toString()
    },
    envAgreementPageSize(o: EnvPolicyDomain): string {
        return o.envAgreementPageSize.toString()
    },
    envBankPageSize(o: EnvPolicyDomain): string {
        return o.envBankPageSize.toString()
    },
    envClientPageSize(o: EnvPolicyDomain): string {
        return o.envClientPageSize.toString()
    },
    envConstructionPageSize(o: EnvPolicyDomain): string {
        return o.envConstructionPageSize.toString()
    },
    envCountryPageSize(o: EnvPolicyDomain): string {
        return o.envCountryPageSize.toString()
    },
    envCountryRankDuration(o: EnvPolicyDomain): string {
        return UserRankDuration.description(o.envCountryRankDuration)
    },
    envEventPageSize(o: EnvPolicyDomain): string {
        return o.envEventPageSize.toString()
    },
    envFaqPageSize(o: EnvPolicyDomain): string {
        return o.envFaqPageSize.toString()
    },
    envFaqItemPageSize(o: EnvPolicyDomain): string {
        return o.envFaqItemPageSize.toString()
    },
    envNoticePageSize(o: EnvPolicyDomain): string {
        return o.envNoticePageSize.toString()
    },
    envPhoneBlockPageSize(o: EnvPolicyDomain): string {
        return o.envPhoneBlockPageSize.toString()
    },
    envPolicyPageSize(o: EnvPolicyDomain): string {
        return o.envPolicyPageSize.toString()
    },
    envStorageKeyPageSize(o: EnvPolicyDomain): string {
        return o.envStorageKeyPageSize.toString()
    },
    createdAt(o: EnvPolicyDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvPolicyDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
