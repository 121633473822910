import {RouterProps} from "../../../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {
    ContentCommentReportReasonValueObject
} from "../../../../../data/value-object/content/ContentCommentReportReasonValueObject";
import {JSX, useEffect} from "react";
import {
    ContentCommentLikeIO,
    ContentCommentReportIO,
    ContentCommentReportReasonIO,
    ContentCommentReportReasonModificationIO
} from "../../../../../io/ContentIO";
import {Body1, Button, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {mapByKey} from "../../../../../util/ObjectUtil";
import {ContentCommentReportReasonDescription} from "../../../../../data/description/content/ContentCommentReportReasonDescription";
import {ContentCommentReportReasonDomain} from "../../../../../data/domain/content/ContentCommentReportReasonDomain";
import {ContentCommentReportReasonDescriptor} from "../../../../../data/descriptor/content/ContentCommentReportReasonDescriptor";
import {DeleteDialog, DetailDefaultPanel, DetailDefaultTab, DetailTab} from "../../../../DetailViews";
import {searchIndex, SearchIndex} from "../../../../../io/HttpClient";
import ListPageWrapper from "../../../../ListPageWrapper";
import {
    ContentCommentReportReasonModificationValueObject
} from "../../../../../data/value-object/content/ContentCommentReportReasonModificationValueObject";
import {
    ContentCommentReportReasonModificationSearchOption
} from "../../../../../data/search-option/content/ContentCommentReportReasonModificationSearchOption";
import {
    ContentCommentReportReasonModificationDescription
} from "../../../../../data/description/content/ContentCommentReportReasonModificationDescription";
import {
    ContentCommentReportReasonModificationDescriptor
} from "../../../../../data/descriptor/content/ContentCommentReportReasonModificationDescriptor";
import {ContentCommentReportDescriptor} from "../../../../../data/descriptor/content/ContentCommentReportDescriptor";
import {
    ContentCommentReportValueObject
} from "../../../../../data/value-object/content/ContentCommentReportValueObject";
import {
    ContentCommentReportSearchOption
} from "../../../../../data/search-option/content/ContentCommentReportSearchOption";
import {ContentCommentReportDescription} from "../../../../../data/description/content/ContentCommentReportDescription";
import {ContentCommentLikeDescription} from "../../../../../data/description/content/ContentCommentLikeDescription";

enum Panel {
    Default,
    ContentCommentReportReasonModificationList,
    ContentCommentReportList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentCommentReportReasonId: string }>()
    const item = useStatePair<ContentCommentReportReasonValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentCommentReportReasonId !== undefined) {
            ContentCommentReportReasonIO
                .getById(BigInt(params.contentCommentReportReasonId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentCommentReportReasonValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.ContentCommentReportReasonModificationList}>수정 이력</Tab>
                <Tab value={Panel.ContentCommentReportList}>신고 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={ContentCommentReportReasonIO}
                description={ContentCommentReportReasonDescription}
                properties={props => <PropertyBody {...props} />}/>
            <ContentCommentReportReasonModificationListPanel
                {...props}
                panel={panel.value}/>
            <ContentCommentReportListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentCommentReportReasonDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "body":
        case "displayOrder":
        case "reportCount":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{ContentCommentReportReasonDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentCommentReportReasonDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(ContentCommentReportReasonDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{ContentCommentReportReasonDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}

function ContentCommentReportReasonModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentCommentReportReasonModificationValueObject[]>([])
    const modificationSearchOption: ContentCommentReportReasonModificationSearchOption = { contentCommentReportReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentCommentReportReasonModificationList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentReportReasonModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentCommentReportReasonModificationDescription}
                descriptor={ContentCommentReportReasonModificationDescriptor}
                onItemClick={item => `/contents/comments/reports/reasons/${item.id}`} />
        </>}
    </>
}

function ContentCommentReportListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentCommentReportValueObject[]>([])
    const modificationSearchOption: ContentCommentReportSearchOption = { contentCommentReportReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentCommentReportList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentReportIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentCommentReportDescription}
                descriptor={ContentCommentReportDescriptor}
                onItemClick={item => `/contents/comments/reports/${item.id}`} />
        </>}
    </>
}