import {UiHomeContentSuggestionDomain} from "../../domain/ui/UiHomeContentSuggestionDomain"

export const UiHomeContentSuggestionDescriptor: Record<keyof UiHomeContentSuggestionDomain, (o: UiHomeContentSuggestionDomain) => string> = {
    id(o: UiHomeContentSuggestionDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiHomeContentSuggestionDomain): string {
        return o.creatorId.toString()
    },
    displayOrder(o: UiHomeContentSuggestionDomain): string {
        return o.displayOrder.toString()
    },
    url(o: UiHomeContentSuggestionDomain): string {
        return o.url
    },
    createdAt(o: UiHomeContentSuggestionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UiHomeContentSuggestionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
