import {RouterProps} from "../../RouterProps";
import {StatePair, useStatePair} from "react-type-extension";
import React, {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {PostFinishDialog} from "../../PostField";
import {UserId} from "../../../data/id/user/UserId";
import {UserIO, UserNotificationIO} from "../../../io/UserIO";
import {UserDomain} from "../../../data/domain/user/UserDomain";
import {UserSearchOption} from "../../../data/search-option/user/UserSearchOption";
import {searchIndex} from "../../../io/HttpClient";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import ListPageWrapper from "../../ListPageWrapper";
import {UserDescription} from "../../../data/description/user/UserDescription";
import {UserDescriptor} from "../../../data/descriptor/user/UserDescriptor";
import ListOption from "../../ListOption";
import {UserSearchOptionDescription} from "../../../data/description/user/UserSearchOptionDescription";
import {applyUserSearchOption, UserSearchOptionField} from "../../search-option-field/user/UserSearchOptionField";
import ListOrder from "../../ListOrder";
import {useUserNotificationPostState} from "../../../data/data-transfer-object-state/user/UserNotificationPostState";
import {UserNotificationPostFields} from "../../data-transfer-object-field/user/UserNotificationPostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
    UserList,
    UserOption,
    UserOrder,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useUserNotificationPostState()

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        UserNotificationIO
            .post(state.toDataTransferObject())
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <UserNotificationPostFields
                    {...props}
                    state={state} />
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        {/*<UserSearch*/}
        {/*    {...props}*/}
        {/*    phase={phase}*/}
        {/*    userId={userId} />*/}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/users/notifications'} />
    </>
}

type UserSearchProps = RouterProps & {
    phase: StatePair<Phase>
    userId: StatePair<UserId>
}

function UserSearch(props: UserSearchProps) {
    const items = useStatePair<UserDomain[]>([])
    const option = useStatePair<UserSearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.UserList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>사용자 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={UserIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={UserDescription}
                    descriptor={UserDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.UserOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.UserOrder)}
                    onItemClick={item => {
                        props.userId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.UserOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)} />
                    <Title3>사용자 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={UserSearchOptionDescription}
                    fields={UserSearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.UserList)}
                    onApplyClick={(key, value) => {
                        applyUserSearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.UserOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={UserDescription}
                onBackClick={() => props.phase.setter(Phase.UserList)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}