import {AssetCouponUsageDomain} from "../../domain/asset/AssetCouponUsageDomain"

/**
 * 쿠폰 사용 속성 설명
 */
export const AssetCouponUsageDescription: Record<keyof AssetCouponUsageDomain, string> = {
    id: '기본키',
    assetCouponId: '쿠폰 ID',
    userId: '사용자 ID',
    assetSolarUsageId: '솔라 지급 ID',
    assetLunarUsageId: '루나 지급 ID',
    assetStarUsageId: '스타 지급 ID',
    createdAt: '생성일'
}
