import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentParticipationValueObject
} from "../../../data/value-object/content/ContentParticipationValueObject";
import {useEffect} from "react";
import {ContentParticipationIO} from "../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {ContentParticipationDescription} from "../../../data/description/content/ContentParticipationDescription";
import {ContentParticipationDomain} from "../../../data/domain/content/ContentParticipationDomain";
import {ContentParticipationDescriptor} from "../../../data/descriptor/content/ContentParticipationDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentParticipationId: string }>()
    const item = useStatePair<ContentParticipationValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentParticipationId !== undefined) {
            ContentParticipationIO
                .getById(BigInt(params.contentParticipationId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentParticipationValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentParticipationIO}
                description={ContentParticipationDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentParticipationDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "audioId":
        case "transcription":
        case "accuracy":
        case "isPassed":
        case "isHighAccuracy":
        case "createdAt":
            return <Body1>{ContentParticipationDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentParticipationDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentId":
            return <a href={`/contents/${props.item.contentId}`}><Body1>{ContentParticipationDescriptor[props.itemKey](props.item)}</Body1></a>
        case "assetSolarUsageId":
            return (props.item.assetSolarUsageId !== undefined)
                ? <a href={`/assets/solars/usages/${props.item.assetSolarUsageId}`}><Body1>{ContentParticipationDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
        case "assetStarUsageId":
            return (props.item.assetStarUsageId !== undefined)
                ? <a href={`/assets/stars/usages/${props.item.assetStarUsageId}`}><Body1>{ContentParticipationDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
    }
}