import {RouterProps} from "./RouterProps";
import {
    Button,
    Caption1, Caption2,
    Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogProps, DialogSurface, DialogTitle,
    Field,
    Input,
    InputProps,
    makeStyles,
    Radio,
    RadioGroup, Textarea
} from "@fluentui/react-components";
import React, {ChangeEvent, Dispatch, InputHTMLAttributes, JSX, useRef} from "react";
import {MediaFile, Media, MutableMediaList} from "./Media";
import {insertDatePunctuation} from "../util/DateUtils";

export type PostFieldWrapperProps = RouterProps & {
    title: string
    subtitle?: string | JSX.Element | JSX.Element[]
    children?: JSX.Element | JSX.Element[]
    showSearch?: boolean
    onSearchClick?: () => void
    showUnusedCheckbox?: boolean
    isUnusedCheckboxChecked?: boolean
    onUnusedCheckboxChange?: (checked: boolean) => void
}

export function PostFieldWrapper(props: PostFieldWrapperProps) {
    return <>
        <div className={props.styles.column4}>
            <Caption1>{props.title}</Caption1>
            {props.subtitle !== undefined && typeof props.subtitle === 'string' && <Caption2>{props.subtitle}</Caption2>}
            {props.subtitle !== undefined && typeof props.subtitle !== 'string' && props.subtitle}
            <div className={props.styles.row8}>
                {props.children}
                <div className={props.styles.column}>
                    {props.showSearch === true && <>
                        <Button
                            disabled={props.isUnusedCheckboxChecked === true}
                            onClick={props.onSearchClick}>
                            검색
                        </Button>
                    </>}
                </div>
                <div className={props.styles.column}>
                    {props.showUnusedCheckbox === true && (
                        <Checkbox
                            label={'사용 안 함'}
                            onChange={(_, data) => props.onUnusedCheckboxChange?.(data.checked === true)}
                            checked={props.isUnusedCheckboxChecked === true} />
                    )}
                </div>
            </div>
        </div>
    </>
}

const useStyles = makeStyles({
    field: {
        minWidth: '360px'
    },
    textarea: {
        maxHeight: '480px',
        height: '480px'
    }
})

export type PostFieldProps<T, Attr = Pick<InputProps, 'max' | 'min' | 'maxLength' | 'minLength' | 'type' | 'placeholder'>> = RouterProps & Attr & {
    disabled?: boolean
    value: T | undefined
    setter: Dispatch<T>
}

export function PostInputField(props: PostFieldProps<string>) {
    const styles = useStyles()
    return <>
        <Field className={styles.field}>
            <Input
                {...props}
                disabled={props.disabled}
                value={props.value ?? ''}
                onChange={(_, data) => props.setter(data.value)}
                type={'text'} />
        </Field>
    </>
}

export function PostDateField(props: PostFieldProps<string>) {
    const styles = useStyles()
    return <>
        <Field className={styles.field}>
            <Input
                {...props}
                disabled={props.disabled}
                value={props.value ?? ''}
                onChange={(_, data) => props.setter(insertDatePunctuation(data.value))}
                type={'text'} />
        </Field>
    </>
}

export function PostTextareaField(props: PostFieldProps<string>) {
    const styles = useStyles()
    return <>
        <Field className={styles.field}>
            <Textarea
                {...props}
                className={styles.textarea}
                root={{
                    className: styles.textarea,
                }}
                textarea={{
                    className: styles.textarea
                }}
                disabled={props.disabled}
                value={props.value ?? ''}
                onChange={(_, data) => props.setter(data.value)} />
        </Field>
    </>
}

export function PostBigIntField(props: PostFieldProps<bigint>) {
    const styles = useStyles()
    return <>
        <Field className={styles.field}>
            <Input
                {...props}
                disabled={props.disabled === true}
                value={(props.value === undefined) ? '' : props.value.toString()}
                onChange={(_, data) => props.setter(BigInt(data.value))}
                type={'number'} />
        </Field>
    </>
}

export function PostNumberField(props: PostFieldProps<number>) {
    const styles = useStyles()
    return <>
        <Field className={styles.field}>
            <Input
                {...props}
                disabled={props.disabled === true}
                value={(props.value === undefined) ? '' : props.value.toString()}
                onChange={(_, data) => props.setter(Number(data.value))}
                type={'number'} />
        </Field>
    </>
}

export function PostBooleanField(props: PostFieldProps<boolean>) {
    const styles = useStyles()
    return <>
        <Field className={styles.field}>
            <Checkbox
                checked={props.value}
                onChange={(_ev, data) => props.setter(!!data.checked)} />
        </Field>
    </>
}

export function PostFileField(props: PostFieldProps<MediaFile | undefined, Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>>) {
    const inputRef = useRef<HTMLInputElement>(null)
    const { value, setter, ...otherProps } = props

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files === null) {
            return
        }
        MediaFile
            .createFromFileList(files)
            .then(mediaFiles => setter(mediaFiles[0]))
            .catch(reason => {
                console.error(reason)
                alert('파일을 불러올 수 없습니다.')
            })
    }

    return <>
        <div className={props.styles.column4}>
            <div className={props.styles.row}>
                <Button onClick={() => inputRef.current?.click()}>선택</Button>
            </div>
            <input
                {...otherProps}
                ref={inputRef}
                style={{display: 'none'}}
                type={'file'}
                onChange={onChange} />
            {value !== undefined && <>
                <Media
                    {...props}
                    key={`media-item`}
                    index={0}
                    source={value}
                    showDismiss
                    onDismissClick={() => setter(undefined)} />
            </>}
        </div>
    </>
}

export function PostFilesField(props: PostFieldProps<MediaFile[], Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>>) {
    const inputRef = useRef<HTMLInputElement>(null)
    const { value, setter, ...otherProps } = props

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files === null) {
            return
        }
        MediaFile
            .createFromFileList(files)
            .then(mediaFiles => setter([...(value ?? []), ...mediaFiles]))
            .catch(reason => {
                console.error(reason)
                alert('파일을 불러올 수 없습니다.')
            })
    }

    return <>
        <div className={props.styles.column4}>
            <div className={props.styles.row}>
                <Button onClick={() => inputRef.current?.click()}>선택</Button>
            </div>
            <input
                {...otherProps}
                multiple
                ref={inputRef}
                style={{display: 'none'}}
                type={'file'}
                onChange={onChange}/>
            <MutableMediaList
                {...props}
                value={value}
                setter={setter} />
        </div>
    </>
}

export type PostEnumField<T> = PostFieldProps<T> & {
    selections: { value: string, label: string }[]
}

export function PostEnumField<T extends string>(props: PostEnumField<T>) {
    return <>
        <RadioGroup
            disabled={props.disabled}
            value={props.value}
            onChange={(_, data) => props.setter(data.value as T)}>
            {props.selections.map(selection => <>
                <Radio
                    key={`post-enum-field-${selection.value}`}
                    label={selection.label}
                    value={selection.value} />
            </>)}
        </RadioGroup>
    </>
}

export type PostBitMaskField = PostFieldProps<number> & {
    selections: { value: string, label: string }[]
}

export function PostBitMaskField(props: PostBitMaskField) {
    return <>
        <div className={props.styles.column}>
            {props.selections.map(selection => <>
                <Checkbox
                    key={`post-bitmask-field-${selection.value}`}
                    label={selection.label}
                    checked={((props.value ?? 0) & Number(selection.value)) !== 0}
                    onChange={(_, data) => {
                        if (data.checked) {
                            props.setter((props.value ?? 0) | Number(selection.value))
                        } else {
                            props.setter((props.value ?? 0) ^ Number(selection.value))
                        }
                    }}></Checkbox>
            </>)}
        </div>
    </>
}

export type PostFinishDialogProps = Omit<DialogProps, 'children'> & {
    negativeHref: string
}

export function PostFinishDialog(props: PostFinishDialogProps) {
    return <>
        <Dialog {...props}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>완료</DialogTitle>
                    <DialogContent>새 항목이 추가되었습니다. 계속 추가하시겠습니까?</DialogContent>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => document.location.reload()}>예</Button>
                    <Button onClick={() => document.location = props.negativeHref}>아니오</Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    </>
}