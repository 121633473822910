import {AdvertiserDomain} from "../../domain/advertiser/AdvertiserDomain"
import {AdvertiserId} from "../../id/advertiser/AdvertiserId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 광고주
 */
export class AdvertiserValueObject implements ValueObject, AdvertiserDomain {
    /**
     * 기본키
     */
    id: AdvertiserId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 사업자등록번호
     */
    registration: string
    /**
     * 상호명
     */
    name: string
    /**
     * 콘텐츠 수
     */
    contentCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof AdvertiserDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.registration = String(o.registration)
        this.name = String(o.name)
        this.contentCount = Number(o.contentCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
