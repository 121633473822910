import {ContentCommentReportReasonPutDataTransferObject} from "../../data-transfer-object/content/ContentCommentReportReasonPutDataTransferObject"
import {ContentCommentReportReasonValueObject} from "../../value-object/content/ContentCommentReportReasonValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type ContentCommentReportReasonPutState = {
    body: StatePair<string>
    displayOrder: StatePair<number>
    toDataTransferObject(): ContentCommentReportReasonPutDataTransferObject
    fromValueObject(vo: ContentCommentReportReasonValueObject): void
    values(): [string, number]
}

export function useContentCommentReportReasonPutState(): ContentCommentReportReasonPutState {
    return {
        body: useStatePair<string>(''),
        displayOrder: useStatePair<number>(0),
        toDataTransferObject(): ContentCommentReportReasonPutDataTransferObject {
            return {
                body: this.body.value,
                displayOrder: this.displayOrder.value,
            }
        },
        fromValueObject(vo: ContentCommentReportReasonValueObject) {
            this.body.setter(vo.body)
            this.displayOrder.setter(vo.displayOrder)
        },
        values(): [string, number] {
            return [
                this.body.value,
                this.displayOrder.value,
            ]
        }
    }
}
