import {UserInquirySearchOption} from "../../search-option/user/UserInquirySearchOption"

/**
 * 사용자 문의 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserInquirySearchOptionDescription: Record<keyof UserInquirySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    userIdIsNull: '사용자 ID 값 존재 여부',
    envClientIdEq: '클라이언트 ID 일치',
    envClientIdNeq: '클라이언트 ID 불일치',
    envClientIdMin: '클라이언트 ID 최소',
    envClientIdMax: '클라이언트 ID 최대',
    envCountryIdEq: '국가 ID 일치',
    envCountryIdNeq: '국가 ID 불일치',
    envCountryIdMin: '국가 ID 최소',
    envCountryIdMax: '국가 ID 최대',
    emailEq: '회신 이메일 일치',
    emailNeq: '회신 이메일 불일치',
    emailMin: '회신 이메일 최소',
    emailMax: '회신 이메일 최대',
    emailLike: '회신 이메일 포함',
    emailNotLike: '회신 이메일 제외',
    remoteAddressEq: '요청자 주소 일치',
    remoteAddressNeq: '요청자 주소 불일치',
    remoteAddressMin: '요청자 주소 최소',
    remoteAddressMax: '요청자 주소 최대',
    remoteAddressLike: '요청자 주소 포함',
    remoteAddressNotLike: '요청자 주소 제외',
    deviceNameEq: '장치 이름 일치',
    deviceNameNeq: '장치 이름 불일치',
    deviceNameMin: '장치 이름 최소',
    deviceNameMax: '장치 이름 최대',
    deviceNameLike: '장치 이름 포함',
    deviceNameNotLike: '장치 이름 제외',
    deviceVersionEq: '장치 버전 일치',
    deviceVersionNeq: '장치 버전 불일치',
    deviceVersionMin: '장치 버전 최소',
    deviceVersionMax: '장치 버전 최대',
    deviceVersionLike: '장치 버전 포함',
    deviceVersionNotLike: '장치 버전 제외',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
