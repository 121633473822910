import {ContentUserProductionScriptDomain} from "../../domain/content/ContentUserProductionScriptDomain"

/**
 * 와글와글 스크립트 속성 설명
 */
export const ContentUserProductionScriptDescription: Record<keyof ContentUserProductionScriptDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    audioId: '오디오 ID',
    script: '본문',
    transcription: '인식된 텍스트',
    accuracy: '일치율(%)',
    createdAt: '생성일'
}
