import {ContentReportReasonModificationDomain} from "../../domain/content/ContentReportReasonModificationDomain"
import {ContentReportReasonId} from "../../id/content/ContentReportReasonId"
import {ContentReportReasonModificationId} from "../../id/content/ContentReportReasonModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 신고 사유 수정 이력
 */
export class ContentReportReasonModificationValueObject implements ValueObject, ContentReportReasonModificationDomain {
    /**
     * 기본키
     */
    id: ContentReportReasonModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 콘텐츠 신고 사유 ID
     */
    contentReportReasonId: ContentReportReasonId
    /**
     * 내용
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentReportReasonModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentReportReasonId = BigInt(o.contentReportReasonId)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
