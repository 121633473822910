import {UserRankDuration} from "../../constant/user/UserRankDuration"
import {UserPolicyPostDataTransferObject} from "../../data-transfer-object/user/UserPolicyPostDataTransferObject"
import {UserPolicyValueObject} from "../../value-object/user/UserPolicyValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type UserPolicyPostState = {
    signUpSolarAmount: StatePair<number>
    signUpLunarAmount: StatePair<number>
    signUpStarAmount: StatePair<number>
    recommenderSolarAmount: StatePair<number>
    recommenderLunarAmount: StatePair<number>
    recommenderStarAmount: StatePair<number>
    recommenderRewardThreshold: StatePair<number>
    recommendeeSolarAmount: StatePair<number>
    recommendeeLunarAmount: StatePair<number>
    recommendeeStarAmount: StatePair<number>
    userPageSize: StatePair<number>
    userAgreementPageSize: StatePair<number>
    userAuthenticationCodePageSize: StatePair<number>
    userAuthenticationCodeExpireSeconds: StatePair<number>
    userAuthenticationCodeThresholdMinutes: StatePair<number>
    userAuthenticationCodeThresholdCount: StatePair<number>
    userBlockPageSize: StatePair<number>
    userBlockReasonPageSize: StatePair<number>
    userExpPageSize: StatePair<number>
    userInquiryPageSize: StatePair<number>
    userLevelPageSize: StatePair<number>
    userLevelHistoryPageSize: StatePair<number>
    userNotificationPageSize: StatePair<number>
    userPolicyPageSize: StatePair<number>
    userRankPageSize: StatePair<number>
    userRankCacheSchedulerDurationMinutes: StatePair<number>
    userRankCacheRefreshDurationMinutes: StatePair<number>
    userRankDuration: StatePair<UserRankDuration>
    userRankHistoryPageSize: StatePair<number>
    userSessionPageSize: StatePair<number>
    userSessionMaxAgeSeconds: StatePair<number>
    userSignInPageSize: StatePair<number>
    userWithdrawalPageSize: StatePair<number>
    userWithdrawalReasonPageSize: StatePair<number>
    toDataTransferObject(): UserPolicyPostDataTransferObject
    fromValueObject(vo: UserPolicyValueObject): void
    values(): [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, UserRankDuration, number, number, number, number, number, number]
}

export function useUserPolicyPostState(): UserPolicyPostState {
    return {
        signUpSolarAmount: useStatePair<number>(0),
        signUpLunarAmount: useStatePair<number>(0),
        signUpStarAmount: useStatePair<number>(0),
        recommenderSolarAmount: useStatePair<number>(0),
        recommenderLunarAmount: useStatePair<number>(0),
        recommenderStarAmount: useStatePair<number>(0),
        recommenderRewardThreshold: useStatePair<number>(0),
        recommendeeSolarAmount: useStatePair<number>(0),
        recommendeeLunarAmount: useStatePair<number>(0),
        recommendeeStarAmount: useStatePair<number>(0),
        userPageSize: useStatePair<number>(0),
        userAgreementPageSize: useStatePair<number>(0),
        userAuthenticationCodePageSize: useStatePair<number>(0),
        userAuthenticationCodeExpireSeconds: useStatePair<number>(0),
        userAuthenticationCodeThresholdMinutes: useStatePair<number>(0),
        userAuthenticationCodeThresholdCount: useStatePair<number>(0),
        userBlockPageSize: useStatePair<number>(0),
        userBlockReasonPageSize: useStatePair<number>(0),
        userExpPageSize: useStatePair<number>(0),
        userInquiryPageSize: useStatePair<number>(0),
        userLevelPageSize: useStatePair<number>(0),
        userLevelHistoryPageSize: useStatePair<number>(0),
        userNotificationPageSize: useStatePair<number>(0),
        userPolicyPageSize: useStatePair<number>(0),
        userRankPageSize: useStatePair<number>(0),
        userRankCacheSchedulerDurationMinutes: useStatePair<number>(0),
        userRankCacheRefreshDurationMinutes: useStatePair<number>(0),
        userRankDuration: useStatePair<UserRankDuration>(UserRankDuration.Day),
        userRankHistoryPageSize: useStatePair<number>(0),
        userSessionPageSize: useStatePair<number>(0),
        userSessionMaxAgeSeconds: useStatePair<number>(0),
        userSignInPageSize: useStatePair<number>(0),
        userWithdrawalPageSize: useStatePair<number>(0),
        userWithdrawalReasonPageSize: useStatePair<number>(0),
        toDataTransferObject(): UserPolicyPostDataTransferObject {
            return {
                signUpSolarAmount: this.signUpSolarAmount.value,
                signUpLunarAmount: this.signUpLunarAmount.value,
                signUpStarAmount: this.signUpStarAmount.value,
                recommenderSolarAmount: this.recommenderSolarAmount.value,
                recommenderLunarAmount: this.recommenderLunarAmount.value,
                recommenderStarAmount: this.recommenderStarAmount.value,
                recommenderRewardThreshold: this.recommenderRewardThreshold.value,
                recommendeeSolarAmount: this.recommendeeSolarAmount.value,
                recommendeeLunarAmount: this.recommendeeLunarAmount.value,
                recommendeeStarAmount: this.recommendeeStarAmount.value,
                userPageSize: this.userPageSize.value,
                userAgreementPageSize: this.userAgreementPageSize.value,
                userAuthenticationCodePageSize: this.userAuthenticationCodePageSize.value,
                userAuthenticationCodeExpireSeconds: this.userAuthenticationCodeExpireSeconds.value,
                userAuthenticationCodeThresholdMinutes: this.userAuthenticationCodeThresholdMinutes.value,
                userAuthenticationCodeThresholdCount: this.userAuthenticationCodeThresholdCount.value,
                userBlockPageSize: this.userBlockPageSize.value,
                userBlockReasonPageSize: this.userBlockReasonPageSize.value,
                userExpPageSize: this.userExpPageSize.value,
                userInquiryPageSize: this.userInquiryPageSize.value,
                userLevelPageSize: this.userLevelPageSize.value,
                userLevelHistoryPageSize: this.userLevelHistoryPageSize.value,
                userNotificationPageSize: this.userNotificationPageSize.value,
                userPolicyPageSize: this.userPolicyPageSize.value,
                userRankPageSize: this.userRankPageSize.value,
                userRankCacheSchedulerDurationMinutes: this.userRankCacheSchedulerDurationMinutes.value,
                userRankCacheRefreshDurationMinutes: this.userRankCacheRefreshDurationMinutes.value,
                userRankDuration: this.userRankDuration.value,
                userRankHistoryPageSize: this.userRankHistoryPageSize.value,
                userSessionPageSize: this.userSessionPageSize.value,
                userSessionMaxAgeSeconds: this.userSessionMaxAgeSeconds.value,
                userSignInPageSize: this.userSignInPageSize.value,
                userWithdrawalPageSize: this.userWithdrawalPageSize.value,
                userWithdrawalReasonPageSize: this.userWithdrawalReasonPageSize.value,
            }
        },
        fromValueObject(vo: UserPolicyValueObject) {
            this.signUpSolarAmount.setter(vo.signUpSolarAmount)
            this.signUpLunarAmount.setter(vo.signUpLunarAmount)
            this.signUpStarAmount.setter(vo.signUpStarAmount)
            this.recommenderSolarAmount.setter(vo.recommenderSolarAmount)
            this.recommenderLunarAmount.setter(vo.recommenderLunarAmount)
            this.recommenderStarAmount.setter(vo.recommenderStarAmount)
            this.recommenderRewardThreshold.setter(vo.recommenderRewardThreshold)
            this.recommendeeSolarAmount.setter(vo.recommendeeSolarAmount)
            this.recommendeeLunarAmount.setter(vo.recommendeeLunarAmount)
            this.recommendeeStarAmount.setter(vo.recommendeeStarAmount)
            this.userPageSize.setter(vo.userPageSize)
            this.userAgreementPageSize.setter(vo.userAgreementPageSize)
            this.userAuthenticationCodePageSize.setter(vo.userAuthenticationCodePageSize)
            this.userAuthenticationCodeExpireSeconds.setter(vo.userAuthenticationCodeExpireSeconds)
            this.userAuthenticationCodeThresholdMinutes.setter(vo.userAuthenticationCodeThresholdMinutes)
            this.userAuthenticationCodeThresholdCount.setter(vo.userAuthenticationCodeThresholdCount)
            this.userBlockPageSize.setter(vo.userBlockPageSize)
            this.userBlockReasonPageSize.setter(vo.userBlockReasonPageSize)
            this.userExpPageSize.setter(vo.userExpPageSize)
            this.userInquiryPageSize.setter(vo.userInquiryPageSize)
            this.userLevelPageSize.setter(vo.userLevelPageSize)
            this.userLevelHistoryPageSize.setter(vo.userLevelHistoryPageSize)
            this.userNotificationPageSize.setter(vo.userNotificationPageSize)
            this.userPolicyPageSize.setter(vo.userPolicyPageSize)
            this.userRankPageSize.setter(vo.userRankPageSize)
            this.userRankCacheSchedulerDurationMinutes.setter(vo.userRankCacheSchedulerDurationMinutes)
            this.userRankCacheRefreshDurationMinutes.setter(vo.userRankCacheRefreshDurationMinutes)
            this.userRankDuration.setter(vo.userRankDuration)
            this.userRankHistoryPageSize.setter(vo.userRankHistoryPageSize)
            this.userSessionPageSize.setter(vo.userSessionPageSize)
            this.userSessionMaxAgeSeconds.setter(vo.userSessionMaxAgeSeconds)
            this.userSignInPageSize.setter(vo.userSignInPageSize)
            this.userWithdrawalPageSize.setter(vo.userWithdrawalPageSize)
            this.userWithdrawalReasonPageSize.setter(vo.userWithdrawalReasonPageSize)
        },
        values(): [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, UserRankDuration, number, number, number, number, number, number] {
            return [
                this.signUpSolarAmount.value,
                this.signUpLunarAmount.value,
                this.signUpStarAmount.value,
                this.recommenderSolarAmount.value,
                this.recommenderLunarAmount.value,
                this.recommenderStarAmount.value,
                this.recommenderRewardThreshold.value,
                this.recommendeeSolarAmount.value,
                this.recommendeeLunarAmount.value,
                this.recommendeeStarAmount.value,
                this.userPageSize.value,
                this.userAgreementPageSize.value,
                this.userAuthenticationCodePageSize.value,
                this.userAuthenticationCodeExpireSeconds.value,
                this.userAuthenticationCodeThresholdMinutes.value,
                this.userAuthenticationCodeThresholdCount.value,
                this.userBlockPageSize.value,
                this.userBlockReasonPageSize.value,
                this.userExpPageSize.value,
                this.userInquiryPageSize.value,
                this.userLevelPageSize.value,
                this.userLevelHistoryPageSize.value,
                this.userNotificationPageSize.value,
                this.userPolicyPageSize.value,
                this.userRankPageSize.value,
                this.userRankCacheSchedulerDurationMinutes.value,
                this.userRankCacheRefreshDurationMinutes.value,
                this.userRankDuration.value,
                this.userRankHistoryPageSize.value,
                this.userSessionPageSize.value,
                this.userSessionMaxAgeSeconds.value,
                this.userSignInPageSize.value,
                this.userWithdrawalPageSize.value,
                this.userWithdrawalReasonPageSize.value,
            ]
        }
    }
}
