import {AssetLunarPurchaseOptionVerboseDomain} from "../../domain/asset/AssetLunarPurchaseOptionVerboseDomain"
import {AssetLunarPurchaseOptionId} from "../../id/asset/AssetLunarPurchaseOptionId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 루나 구매 옵션 상세
 */
export class AssetLunarPurchaseOptionVerboseValueObject implements VerboseValueObject, AssetLunarPurchaseOptionVerboseDomain {
    /**
     * 기본키
     */
    id: AssetLunarPurchaseOptionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 지급 루나
     */
    lunarAmount: number
    /**
     * 지불 금액
     */
    cashAmount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof AssetLunarPurchaseOptionVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.lunarAmount = Number(o.lunarAmount)
        this.cashAmount = Number(o.cashAmount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
