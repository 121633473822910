import {UiOnboardingIO, UiOnboardingModificationIO} from "../../../io/UiIO";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    UiOnboardingValueObject
} from "../../../data/value-object/ui/UiOnboardingValueObject";
import {useEffect} from "react";
import {Body1, Caption1Strong, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {
    UiOnboardingDescription
} from "../../../data/description/ui/UiOnboardingDescription";
import {UiOnboardingDomain} from "../../../data/domain/ui/UiOnboardingDomain";
import {UiOnboardingDescriptor} from "../../../data/descriptor/ui/UiOnboardingDescriptor";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {
    UiOnboardingModificationValueObject
} from "../../../data/value-object/ui/UiOnboardingModificationValueObject";
import {
    UiOnboardingModificationSearchOption
} from "../../../data/search-option/ui/UiOnboardingModificationSearchOption";
import {
    UiOnboardingModificationDescription
} from "../../../data/description/ui/UiOnboardingModificationDescription";
import {
    UiOnboardingModificationDescriptor
} from "../../../data/descriptor/ui/UiOnboardingModificationDescriptor";
import {StorageKey} from "../../../io/StorageIO";
import {Media, MediaFile} from "../../Media";

enum Panel {
    Default,
    UiOnboardingModificationList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ uiOnboardingId: string }>()
    const item = useStatePair<UiOnboardingValueObject | undefined>(undefined)
    const image = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.uiOnboardingId !== undefined) {
            const id = BigInt(params.uiOnboardingId)
            UiOnboardingIO
                .getById(id)
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
            MediaFile
                .createFromStorageKey(StorageKey.Ui.onboarding(id))
                .then(image.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('이미지를 찾을 수 없습니다. 이미지를 먼저 수정해주세요.')
                    document.location = document.location.pathname + '/edit'
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && image.value !== undefined && <Page {...props} item={item.value} image={image.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UiOnboardingValueObject
    image: MediaFile
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UiOnboardingModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UiOnboardingIO}
                description={UiOnboardingDescription}
                properties={props => <PropertyBody {...props} />}
                panelChildren={<>
                    <Caption1Strong>이미지</Caption1Strong>
                    <Media
                        {...props}
                        source={props.image} />
                </>}
                storageKeys={() => StorageKey.Ui.onboarding(props.item.id)} />
            <UiOnboardingModificationListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = RouterProps & {
    item: UiOnboardingDomain
    itemKey: keyof UiOnboardingDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "displayOrder":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{UiOnboardingDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UiOnboardingDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function UiOnboardingModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<UiOnboardingModificationValueObject[]>([])
    const modificationSearchOption: UiOnboardingModificationSearchOption = { uiOnboardingIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.UiOnboardingModificationList && <>
            <ListPageWrapper
                {...props}
                client={UiOnboardingModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UiOnboardingModificationDescription}
                descriptor={UiOnboardingModificationDescriptor} />
        </>}
    </>
}