import {UiOnboardingDomain} from "../../domain/ui/UiOnboardingDomain"

/**
 * 온보딩 이미지 속성 설명
 */
export const UiOnboardingDescription: Record<keyof UiOnboardingDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    displayOrder: '표시 우선 순위',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
