import {UserLevelSearchOption} from "../../search-option/user/UserLevelSearchOption"

/**
 * 사용자 레벨 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserLevelSearchOptionDescription: Record<keyof UserLevelSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    nameEq: '이름 일치',
    nameNeq: '이름 불일치',
    nameMin: '이름 최소',
    nameMax: '이름 최대',
    nameLike: '이름 포함',
    nameNotLike: '이름 제외',
    expEq: '필요 경험치 일치',
    expNeq: '필요 경험치 불일치',
    expMin: '필요 경험치 최소',
    expMax: '필요 경험치 최대',
    rewardAssetLunarAmountEq: '보상 루나 일치',
    rewardAssetLunarAmountNeq: '보상 루나 불일치',
    rewardAssetLunarAmountMin: '보상 루나 최소',
    rewardAssetLunarAmountMax: '보상 루나 최대',
    rewardAssetSolarAmountEq: '보상 솔라 일치',
    rewardAssetSolarAmountNeq: '보상 솔라 불일치',
    rewardAssetSolarAmountMin: '보상 솔라 최소',
    rewardAssetSolarAmountMax: '보상 솔라 최대',
    rewardAssetStarAmountEq: '보상 스타 일치',
    rewardAssetStarAmountNeq: '보상 스타 불일치',
    rewardAssetStarAmountMin: '보상 스타 최소',
    rewardAssetStarAmountMax: '보상 스타 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
