import {ContentReportReasonDomain} from "../../domain/content/ContentReportReasonDomain"

export const ContentReportReasonDescriptor: Record<keyof ContentReportReasonDomain, (o: ContentReportReasonDomain) => string> = {
    id(o: ContentReportReasonDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentReportReasonDomain): string {
        return o.creatorId.toString()
    },
    body(o: ContentReportReasonDomain): string {
        return o.body
    },
    displayOrder(o: ContentReportReasonDomain): string {
        return o.displayOrder.toString()
    },
    reportCount(o: ContentReportReasonDomain): string {
        return o.reportCount.toString()
    },
    createdAt(o: ContentReportReasonDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentReportReasonDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: ContentReportReasonDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
