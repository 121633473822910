import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {EnvPolicyValueObject} from "../../../data/value-object/env/EnvPolicyValueObject";
import {EnvPolicySearchOption} from "../../../data/search-option/env/EnvPolicySearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvPolicyIO} from "../../../io/EnvIO";
import {EnvPolicyDescription} from "../../../data/description/env/EnvPolicyDescription";
import {EnvPolicyDescriptor} from "../../../data/descriptor/env/EnvPolicyDescriptor";
import ListOption from "../../ListOption";
import {EnvPolicySearchOptionDescription} from "../../../data/description/env/EnvPolicySearchOptionDescription";
import {
    applyEnvPolicySearchOption,
    EnvPolicySearchOptionField
} from "../../search-option-field/env/EnvPolicySearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<EnvPolicyValueObject[]>([])
    const option = useStatePair<EnvPolicySearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={EnvPolicyIO}
                items={items}
                option={option.value}
                index={index}
                description={EnvPolicyDescription}
                descriptor={EnvPolicyDescriptor}
                showPost
                onPostClick={() => document.location = '/env/policies/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/env/policies/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={EnvPolicySearchOptionDescription}
                fields={EnvPolicySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyEnvPolicySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvPolicyDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}