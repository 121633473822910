import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserInquiryValueObject} from "../../../data/value-object/user/UserInquiryValueObject";
import {useEffect} from "react";
import {UserInquiryIO} from "../../../io/UserIO";
import {Body1, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {UserInquiryDomain} from "../../../data/domain/user/UserInquiryDomain";
import {UserInquiryDescriptor} from "../../../data/descriptor/user/UserInquiryDescriptor";
import {mapByKey} from "../../../util/ObjectUtil";
import {UserInquiryDescription} from "../../../data/description/user/UserInquiryDescription";

enum Panel {
    Default,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userInquiryId: string }>()
    const item = useStatePair<UserInquiryValueObject | undefined>(undefined)

    useEffect(() => {
        if (params.userInquiryId !== undefined) {
            UserInquiryIO
                .getById(BigInt(params.userInquiryId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserInquiryValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
            </TabList>
            <DefaultPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserInquiryDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "email":
        case "remoteAddress":
        case "deviceName":
        case "deviceVersion":
        case "body":
        case "createdAt":
            return <Body1>{UserInquiryDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{UserInquiryDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envClientId":
            return <a href={`/env/clients/${props.item.envClientId}`}><Body1>{UserInquiryDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envCountryId":
            return <a href={`/env/countries/${props.item.envCountryId}`}><Body1>{UserInquiryDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(UserInquiryDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{UserInquiryDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}