import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryPopularPreemptionVerboseDomain} from "../../domain/content/ContentQueryPopularPreemptionVerboseDomain"
import {ContentQueryPopularPreemptionId} from "../../id/content/ContentQueryPopularPreemptionId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 인기 검색어 예약 상세
 */
export class ContentQueryPopularPreemptionVerboseValueObject implements VerboseValueObject, ContentQueryPopularPreemptionVerboseDomain {
    /**
     * 기본키
     */
    id: ContentQueryPopularPreemptionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 콘텐츠 유형
     */
    contentType: ContentType
    /**
     * 질의어
     */
    query: string
    /**
     * 노출 시작일
     */
    exposedAt: Date
    /**
     * 노출 종료일
     */
    exposedUntil: Date
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof ContentQueryPopularPreemptionVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentType = o.contentType as ContentType
        this.query = String(o.query)
        this.exposedAt = new Date(o.exposedAt)
        this.exposedUntil = new Date(o.exposedUntil)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
