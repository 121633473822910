import {AssetPolicyPutDataTransferObject} from "../../data-transfer-object/asset/AssetPolicyPutDataTransferObject"

/**
 * 재화 정책 속성 설명
 */
export const AssetPolicyPutDataTransferObjectDescription: Record<keyof AssetPolicyPutDataTransferObject, string> = {
    assetCouponPageSize: '쿠폰 페이지 크기',
    assetCouponUsagePageSize: '쿠폰 사용 페이지 크기',
    assetLunarUsagePageSize: '루나 페이지 크기',
    assetLunarExchangeOptionPageSize: '루나 교환 옵션 페이지 크기',
    assetLunarExchangeExp: '루나 교환 경험치',
    assetLunarPurchaseOptionPageSize: '루나 구매 옵션 페이지 크기',
    assetLunarPurchaseExp: '루나 구매 경험치',
    assetPolicyPageSize: '재화 정책 페이지 크기',
    assetSolarUsagePageSize: '솔라 페이지 크기',
    assetSolarWithdrawalPageSize: '솔라 인출 페이지 크기',
    assetSolarWithdrawalOptionPageSize: '솔라 인출 옵션 페이지 크기',
    assetSolarWithdrawalExp: '솔라 인출 경험치',
    assetStarChargePageSize: '스타 충전 페이지 크기',
    assetStarUsagePageSize: '스타 페이지 크기',
    assetStarUsageExp: '스타 소모 경험치',
    assetStarExchangeOptionPageSize: '스타 교환 옵션 페이지 크기',
    assetStarExchangeExp: '스타 교환 경험치',
    assetStarChargeMinutes: '스타 충전 소요 시간',
    assetStarChargeMax: '스타 최대 충전'
}
