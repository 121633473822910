import {EnvCountrySearchOptionDescription} from "../../../data/description/env/EnvCountrySearchOptionDescription"
import {EnvCountrySearchOption} from "../../../data/search-option/env/EnvCountrySearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function EnvCountrySearchOptionField(props: ListOptionFieldProps<EnvCountrySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.nameEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.nameNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.nameMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.nameMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.nameLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.nameNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "isoCodeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.isoCodeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "isoCodeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.isoCodeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "isoCodeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.isoCodeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "isoCodeLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.isoCodeLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "isoCodeNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.isoCodeNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCodeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.phoneCodeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCodeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.phoneCodeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCodeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.phoneCodeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCodeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.phoneCodeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emojiEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.emojiEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emojiNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.emojiNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emojiMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.emojiMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emojiMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.emojiMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emojiLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.emojiLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emojiNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.emojiNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userBirthCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userBirthCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userBirthCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userBirthCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userResidenceCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userResidenceCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userResidenceCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userResidenceCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userResidenceCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userResidenceCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userResidenceCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userResidenceCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPhoneCodeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userPhoneCodeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPhoneCodeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userPhoneCodeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPhoneCodeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userPhoneCodeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPhoneCodeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.userPhoneCodeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvCountrySearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyEnvCountrySearchOption(key: keyof EnvCountrySearchOption, value: string, into: StatePair<EnvCountrySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "nameEq":
            into.setter({
                ...into.value,
                nameEq: String(value)
            })
            break
        case "nameNeq":
            into.setter({
                ...into.value,
                nameNeq: String(value)
            })
            break
        case "nameMin":
            into.setter({
                ...into.value,
                nameMin: String(value)
            })
            break
        case "nameMax":
            into.setter({
                ...into.value,
                nameMax: String(value)
            })
            break
        case "nameLike":
            into.setter({
                ...into.value,
                nameLike: String(value)
            })
            break
        case "nameNotLike":
            into.setter({
                ...into.value,
                nameNotLike: String(value)
            })
            break
        case "isoCodeNeq":
            into.setter({
                ...into.value,
                isoCodeNeq: String(value)
            })
            break
        case "isoCodeMin":
            into.setter({
                ...into.value,
                isoCodeMin: String(value)
            })
            break
        case "isoCodeMax":
            into.setter({
                ...into.value,
                isoCodeMax: String(value)
            })
            break
        case "isoCodeLike":
            into.setter({
                ...into.value,
                isoCodeLike: String(value)
            })
            break
        case "isoCodeNotLike":
            into.setter({
                ...into.value,
                isoCodeNotLike: String(value)
            })
            break
        case "phoneCodeEq":
            into.setter({
                ...into.value,
                phoneCodeEq: Number(value)
            })
            break
        case "phoneCodeNeq":
            into.setter({
                ...into.value,
                phoneCodeNeq: Number(value)
            })
            break
        case "phoneCodeMin":
            into.setter({
                ...into.value,
                phoneCodeMin: Number(value)
            })
            break
        case "phoneCodeMax":
            into.setter({
                ...into.value,
                phoneCodeMax: Number(value)
            })
            break
        case "emojiEq":
            into.setter({
                ...into.value,
                emojiEq: String(value)
            })
            break
        case "emojiNeq":
            into.setter({
                ...into.value,
                emojiNeq: String(value)
            })
            break
        case "emojiMin":
            into.setter({
                ...into.value,
                emojiMin: String(value)
            })
            break
        case "emojiMax":
            into.setter({
                ...into.value,
                emojiMax: String(value)
            })
            break
        case "emojiLike":
            into.setter({
                ...into.value,
                emojiLike: String(value)
            })
            break
        case "emojiNotLike":
            into.setter({
                ...into.value,
                emojiNotLike: String(value)
            })
            break
        case "userBirthCountEq":
            into.setter({
                ...into.value,
                userBirthCountEq: Number(value)
            })
            break
        case "userBirthCountNeq":
            into.setter({
                ...into.value,
                userBirthCountNeq: Number(value)
            })
            break
        case "userBirthCountMin":
            into.setter({
                ...into.value,
                userBirthCountMin: Number(value)
            })
            break
        case "userBirthCountMax":
            into.setter({
                ...into.value,
                userBirthCountMax: Number(value)
            })
            break
        case "userResidenceCountEq":
            into.setter({
                ...into.value,
                userResidenceCountEq: Number(value)
            })
            break
        case "userResidenceCountNeq":
            into.setter({
                ...into.value,
                userResidenceCountNeq: Number(value)
            })
            break
        case "userResidenceCountMin":
            into.setter({
                ...into.value,
                userResidenceCountMin: Number(value)
            })
            break
        case "userResidenceCountMax":
            into.setter({
                ...into.value,
                userResidenceCountMax: Number(value)
            })
            break
        case "userPhoneCodeCountEq":
            into.setter({
                ...into.value,
                userPhoneCodeCountEq: Number(value)
            })
            break
        case "userPhoneCodeCountNeq":
            into.setter({
                ...into.value,
                userPhoneCodeCountNeq: Number(value)
            })
            break
        case "userPhoneCodeCountMin":
            into.setter({
                ...into.value,
                userPhoneCodeCountMin: Number(value)
            })
            break
        case "userPhoneCodeCountMax":
            into.setter({
                ...into.value,
                userPhoneCodeCountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
