import {UserNotificationSubject} from "../../constant/user/UserNotificationSubject"
import {UserNotificationDomain} from "../../domain/user/UserNotificationDomain"
import {UserId} from "../../id/user/UserId"
import {UserNotificationId} from "../../id/user/UserNotificationId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 알림
 */
export class UserNotificationValueObject implements ValueObject, UserNotificationDomain {
    /**
     * 기본키
     */
    id: UserNotificationId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 제목
     */
    title: string
    /**
     * 내용
     */
    body: string
    /**
     * 광고 주제
     */
    subject: UserNotificationSubject
    /**
     * 딥링크
     */
    deepLink?: string
    /**
     * 상태
     */
    statusFlags: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date

    constructor(o: Record<keyof UserNotificationDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.creatorId = BigInt(o.creatorId)
        this.title = String(o.title)
        this.body = String(o.body)
        this.subject = o.subject as UserNotificationSubject
        if (o.deepLink !== undefined && o.deepLink !== null) {
            this.deepLink = String(o.deepLink)
        }
        this.statusFlags = Number(o.statusFlags)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
    }
}
