import {ContentType} from "../../../data/constant/content/ContentType"
import {ContentQueryPopularPreemptionSearchOptionDescription} from "../../../data/description/content/ContentQueryPopularPreemptionSearchOptionDescription"
import {ContentQueryPopularPreemptionSearchOption} from "../../../data/search-option/content/ContentQueryPopularPreemptionSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentQueryPopularPreemptionSearchOptionField(props: ListOptionFieldProps<ContentQueryPopularPreemptionSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTypeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.contentTypeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.contentTypeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.contentTypeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.contentTypeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.contentTypeIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "queryEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.queryEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "queryNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.queryNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "queryMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.queryMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "queryMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.queryMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "queryLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.queryLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "queryNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.queryNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedUntilEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedUntilEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedUntilNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedUntilNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedUntilMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedUntilMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedUntilMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.exposedUntilMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.displayOrderEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.displayOrderNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.displayOrderMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.displayOrderMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentQueryPopularPreemptionSearchOption(key: keyof ContentQueryPopularPreemptionSearchOption, value: string, into: StatePair<ContentQueryPopularPreemptionSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "contentTypeEq":
            into.setter({
                ...into.value,
                contentTypeEq: value as ContentType
            })
            break
        case "contentTypeNeq":
            into.setter({
                ...into.value,
                contentTypeNeq: value as ContentType
            })
            break
        case "contentTypeMin":
            into.setter({
                ...into.value,
                contentTypeMin: value as ContentType
            })
            break
        case "contentTypeMax":
            into.setter({
                ...into.value,
                contentTypeMax: value as ContentType
            })
            break
        case "contentTypeIn":
            into.setter({
                ...into.value,
                contentTypeIn: value
                    .split('|')
                    .map(e => e as ContentType)
            })
            break
        case "queryEq":
            into.setter({
                ...into.value,
                queryEq: String(value)
            })
            break
        case "queryNeq":
            into.setter({
                ...into.value,
                queryNeq: String(value)
            })
            break
        case "queryMin":
            into.setter({
                ...into.value,
                queryMin: String(value)
            })
            break
        case "queryMax":
            into.setter({
                ...into.value,
                queryMax: String(value)
            })
            break
        case "queryLike":
            into.setter({
                ...into.value,
                queryLike: String(value)
            })
            break
        case "queryNotLike":
            into.setter({
                ...into.value,
                queryNotLike: String(value)
            })
            break
        case "exposedAtEq":
            into.setter({
                ...into.value,
                exposedAtEq: new Date(value)
            })
            break
        case "exposedAtNeq":
            into.setter({
                ...into.value,
                exposedAtNeq: new Date(value)
            })
            break
        case "exposedAtMin":
            into.setter({
                ...into.value,
                exposedAtMin: new Date(value)
            })
            break
        case "exposedAtMax":
            into.setter({
                ...into.value,
                exposedAtMax: new Date(value)
            })
            break
        case "exposedUntilEq":
            into.setter({
                ...into.value,
                exposedUntilEq: new Date(value)
            })
            break
        case "exposedUntilNeq":
            into.setter({
                ...into.value,
                exposedUntilNeq: new Date(value)
            })
            break
        case "exposedUntilMin":
            into.setter({
                ...into.value,
                exposedUntilMin: new Date(value)
            })
            break
        case "exposedUntilMax":
            into.setter({
                ...into.value,
                exposedUntilMax: new Date(value)
            })
            break
        case "displayOrderEq":
            into.setter({
                ...into.value,
                displayOrderEq: Number(value)
            })
            break
        case "displayOrderNeq":
            into.setter({
                ...into.value,
                displayOrderNeq: Number(value)
            })
            break
        case "displayOrderMin":
            into.setter({
                ...into.value,
                displayOrderMin: Number(value)
            })
            break
        case "displayOrderMax":
            into.setter({
                ...into.value,
                displayOrderMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
