import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    AssetStarChargeValueObject
} from "../../../../data/value-object/asset/AssetStarChargeValueObject";
import {
    AssetStarChargeSearchOption
} from "../../../../data/search-option/asset/AssetStarChargeSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {AssetStarChargeIO} from "../../../../io/AssetIO";
import {
    AssetStarChargeDescription
} from "../../../../data/description/asset/AssetStarChargeDescription";
import {AssetStarChargeDescriptor} from "../../../../data/descriptor/asset/AssetStarChargeDescriptor";
import ListOption from "../../../ListOption";
import {
    AssetStarChargeSearchOptionDescription
} from "../../../../data/description/asset/AssetStarChargeSearchOptionDescription";
import {
    applyAssetStarChargeSearchOption,
    AssetStarChargeSearchOptionField
} from "../../../search-option-field/asset/AssetStarChargeSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetStarChargeValueObject[]>([])
    const option = useStatePair<AssetStarChargeSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetStarChargeIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetStarChargeDescription}
                descriptor={AssetStarChargeDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/stars/charges/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetStarChargeSearchOptionDescription}
                fields={AssetStarChargeSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetStarChargeSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetStarChargeDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}