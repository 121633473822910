import {ContentDifficulty} from "../../../data/constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../../data/constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../../data/constant/content/ContentScriptVisibility"
import {ContentStatus} from "../../../data/constant/content/ContentStatus"
import {ContentType} from "../../../data/constant/content/ContentType"
import {EnvBrowser} from "../../../data/constant/env/EnvBrowser"
import {ContentPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/content/ContentPostDataTransferObjectDescription"
import {ContentPostState} from "../../../data/data-transfer-object-state/content/ContentPostState"
import {ContentPostDataTransferObject} from "../../../data/data-transfer-object/content/ContentPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: ContentPostState
}

export const ContentPostField: Record<Capitalize<keyof ContentPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    AdvertiserId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.advertiserId}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.advertiserId.value === undefined}
                onUnusedCheckboxChange={checked => props.state.advertiserId.setter(checked ? undefined : 0n)}>
                <PostBigIntField
                    {...props}
                    value={props.state.advertiserId.value}
                    setter={props.state.advertiserId.setter}
                    disabled={props.state.advertiserId.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Type(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.type}>
                <PostEnumField
                    {...props}
                    value={props.state.type.value}
                    setter={props.state.type.setter}
                    selections={ContentType.selections} />
            </PostFieldWrapper>
        )
    },
    ParticipationMethod(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.participationMethod}>
                <PostEnumField
                    {...props}
                    value={props.state.participationMethod.value}
                    setter={props.state.participationMethod.setter}
                    selections={ContentParticipationMethod.selections} />
            </PostFieldWrapper>
        )
    },
    Title(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.title}>
                <PostTextareaField
                    {...props}
                    value={props.state.title.value}
                    setter={props.state.title.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    TitleHighlight(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.titleHighlight}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.titleHighlight.value === undefined}
                onUnusedCheckboxChange={checked => props.state.titleHighlight.setter(checked ? undefined : '')}>
                <PostInputField
                    {...props}
                    value={props.state.titleHighlight.value}
                    setter={props.state.titleHighlight.setter}
                    maxLength={20}
                    disabled={props.state.titleHighlight.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Body(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.body}>
                <PostTextareaField
                    {...props}
                    value={props.state.body.value}
                    setter={props.state.body.setter}
                    maxLength={2000} />
            </PostFieldWrapper>
        )
    },
    Script(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.script}>
                <PostTextareaField
                    {...props}
                    value={props.state.script.value}
                    setter={props.state.script.setter}
                    maxLength={1000} />
            </PostFieldWrapper>
        )
    },
    ScriptVisibility(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.scriptVisibility}>
                <PostEnumField
                    {...props}
                    value={props.state.scriptVisibility.value}
                    setter={props.state.scriptVisibility.setter}
                    selections={ContentScriptVisibility.selections} />
            </PostFieldWrapper>
        )
    },
    GradingMessage(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.gradingMessage}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.gradingMessage.value === undefined}
                onUnusedCheckboxChange={checked => props.state.gradingMessage.setter(checked ? undefined : '')}>
                <PostTextareaField
                    {...props}
                    value={props.state.gradingMessage.value}
                    setter={props.state.gradingMessage.setter}
                    maxLength={400}
                    disabled={props.state.gradingMessage.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
    PassAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.passAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.passAccuracy.value}
                    setter={props.state.passAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    PassAccuracyHigh(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.passAccuracyHigh}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.passAccuracyHigh.value === undefined}
                onUnusedCheckboxChange={checked => props.state.passAccuracyHigh.setter(checked ? undefined : 0)}>
                <PostNumberField
                    {...props}
                    value={props.state.passAccuracyHigh.value}
                    setter={props.state.passAccuracyHigh.setter}
                    disabled={props.state.passAccuracyHigh.value === undefined} />
            </PostFieldWrapper>
        )
    },
    PassSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.passSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.passSolarAmount.value}
                    setter={props.state.passSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    PassSolarAmountHighAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.passSolarAmountHighAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.passSolarAmountHighAccuracy.value}
                    setter={props.state.passSolarAmountHighAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    StarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.starAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.starAmount.value}
                    setter={props.state.starAmount.setter} />
            </PostFieldWrapper>
        )
    },
    Difficulty(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.difficulty}>
                <PostEnumField
                    {...props}
                    value={props.state.difficulty.value}
                    setter={props.state.difficulty.setter}
                    selections={ContentDifficulty.selections} />
            </PostFieldWrapper>
        )
    },
    HeaderImageCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.headerImageCount}>
                <PostNumberField
                    {...props}
                    value={props.state.headerImageCount.value}
                    setter={props.state.headerImageCount.setter} />
            </PostFieldWrapper>
        )
    },
    DescriptionImageCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.descriptionImageCount}>
                <PostNumberField
                    {...props}
                    value={props.state.descriptionImageCount.value}
                    setter={props.state.descriptionImageCount.setter} />
            </PostFieldWrapper>
        )
    },
    ExposedAt(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.exposedAt}>
                <PostDateField
                    {...props}
                    value={props.state.exposedAt.value}
                    setter={props.state.exposedAt.setter} />
            </PostFieldWrapper>
        )
    },
    StartAt(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.startAt}>
                <PostDateField
                    {...props}
                    value={props.state.startAt.value}
                    setter={props.state.startAt.setter} />
            </PostFieldWrapper>
        )
    },
    EndAt(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.endAt}>
                <PostDateField
                    {...props}
                    value={props.state.endAt.value}
                    setter={props.state.endAt.setter} />
            </PostFieldWrapper>
        )
    },
    StatusFlags(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.statusFlags}>
                <PostBitMaskField
                    {...props}
                    value={props.state.statusFlags.value}
                    setter={props.state.statusFlags.setter}
                    selections={ContentStatus.selections} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.contentDetailUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentDetailUrl.value}
                    setter={props.state.contentDetailUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.contentDetailUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentDetailUrlBrowser.value}
                    setter={props.state.contentDetailUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.contentResultUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentResultUrl.value}
                    setter={props.state.contentResultUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.contentResultUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentResultUrlBrowser.value}
                    setter={props.state.contentResultUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    AudioId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.audioId}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.audioId.value === undefined}
                onUnusedCheckboxChange={checked => props.state.audioId.setter(checked ? undefined : 0n)}>
                <PostBigIntField
                    {...props}
                    value={props.state.audioId.value}
                    setter={props.state.audioId.setter}
                    disabled={props.state.audioId.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Transcription(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPostDataTransferObjectDescription.transcription}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.transcription.value === undefined}
                onUnusedCheckboxChange={checked => props.state.transcription.setter(checked ? undefined : '')}>
                <PostTextareaField
                    {...props}
                    value={props.state.transcription.value}
                    setter={props.state.transcription.setter}
                    maxLength={1200}
                    disabled={props.state.transcription.value === undefined} />
            </PostFieldWrapper>
        )
    },
}

export function ContentPostFields(props: PostFieldProps) {
    return <>
        <ContentPostField.AdvertiserId
            {...props}
            state={props.state} />
        <ContentPostField.Type
            {...props}
            state={props.state} />
        <ContentPostField.ParticipationMethod
            {...props}
            state={props.state} />
        <ContentPostField.Title
            {...props}
            state={props.state} />
        <ContentPostField.TitleHighlight
            {...props}
            state={props.state} />
        <ContentPostField.Body
            {...props}
            state={props.state} />
        <ContentPostField.Script
            {...props}
            state={props.state} />
        <ContentPostField.ScriptVisibility
            {...props}
            state={props.state} />
        <ContentPostField.GradingMessage
            {...props}
            state={props.state} />
        <ContentPostField.Amount
            {...props}
            state={props.state} />
        <ContentPostField.PassAccuracy
            {...props}
            state={props.state} />
        <ContentPostField.PassAccuracyHigh
            {...props}
            state={props.state} />
        <ContentPostField.PassSolarAmount
            {...props}
            state={props.state} />
        <ContentPostField.PassSolarAmountHighAccuracy
            {...props}
            state={props.state} />
        <ContentPostField.StarAmount
            {...props}
            state={props.state} />
        <ContentPostField.Difficulty
            {...props}
            state={props.state} />
        <ContentPostField.HeaderImageCount
            {...props}
            state={props.state} />
        <ContentPostField.DescriptionImageCount
            {...props}
            state={props.state} />
        <ContentPostField.ExposedAt
            {...props}
            state={props.state} />
        <ContentPostField.StartAt
            {...props}
            state={props.state} />
        <ContentPostField.EndAt
            {...props}
            state={props.state} />
        <ContentPostField.StatusFlags
            {...props}
            state={props.state} />
        <ContentPostField.ContentDetailUrl
            {...props}
            state={props.state} />
        <ContentPostField.ContentDetailUrlBrowser
            {...props}
            state={props.state} />
        <ContentPostField.ContentResultUrl
            {...props}
            state={props.state} />
        <ContentPostField.ContentResultUrlBrowser
            {...props}
            state={props.state} />
        <ContentPostField.AudioId
            {...props}
            state={props.state} />
        <ContentPostField.Transcription
            {...props}
            state={props.state} />
    </>
}
