import {UserModificationDomain} from "../../domain/user/UserModificationDomain"

/**
 * 사용자 수정 이력 속성 설명
 */
export const UserModificationDescription: Record<keyof UserModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    userId: '사용자 ID',
    role: '역할',
    adminPermission: '관리자 권한',
    email: '이메일',
    cloudMessagingToken: 'Firebase Cloud Messaging Token',
    nickname: '닉네임',
    residenceCountryId: '거주 국가 ID',
    birth: '생년월일',
    gender: '성별',
    phoneCountryId: '전화번호 국가 ID',
    phone: '전화번호',
    statusFlags: '상태 플래그',
    createdAt: '수정일'
}
