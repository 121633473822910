import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentUserProductionDurationValueObject} from "../../../../data/value-object/content/ContentUserProductionDurationValueObject";
import {ContentUserProductionDurationSearchOption} from "../../../../data/search-option/content/ContentUserProductionDurationSearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {ContentUserProductionDurationIO} from "../../../../io/ContentIO";
import {ContentUserProductionDurationDescription} from "../../../../data/description/content/ContentUserProductionDurationDescription";
import {ContentUserProductionDurationDescriptor} from "../../../../data/descriptor/content/ContentUserProductionDurationDescriptor";
import ListOption from "../../../ListOption";
import {
    ContentUserProductionDurationSearchOptionDescription
} from "../../../../data/description/content/ContentUserProductionDurationSearchOptionDescription";
import {
    applyContentUserProductionDurationSearchOption,
    ContentUserProductionDurationSearchOptionField
} from "../../../search-option-field/content/ContentUserProductionDurationSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentUserProductionDurationValueObject[]>([])
    const option = useStatePair<ContentUserProductionDurationSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentUserProductionDurationIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentUserProductionDurationDescription}
                descriptor={ContentUserProductionDurationDescriptor}
                showPost
                onPostClick={() => document.location = '/contents/userProduction/durations/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/userProduction/durations/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentUserProductionDurationSearchOptionDescription}
                fields={ContentUserProductionDurationSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentUserProductionDurationSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentUserProductionDurationDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}