import {AssetSolarWithdrawalDomain} from "../../domain/asset/AssetSolarWithdrawalDomain"
import {AssetSolarUsageId} from "../../id/asset/AssetSolarUsageId"
import {AssetSolarWithdrawalId} from "../../id/asset/AssetSolarWithdrawalId"
import {EnvBankId} from "../../id/env/EnvBankId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 솔라 인출
 */
export class AssetSolarWithdrawalValueObject implements ValueObject, AssetSolarWithdrawalDomain {
    /**
     * 기본키
     */
    id: AssetSolarWithdrawalId
    /**
     * 솔라 사용 ID
     */
    assetSolarUsageId: AssetSolarUsageId
    /**
     * 은행 ID
     */
    envBankId: EnvBankId
    /**
     * 계좌번호
     */
    account: string
    /**
     * 요청 금액(원)
     */
    cashAmount: number
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetSolarWithdrawalDomain, any>) {
        this.id = BigInt(o.id)
        this.assetSolarUsageId = BigInt(o.assetSolarUsageId)
        this.envBankId = BigInt(o.envBankId)
        this.account = String(o.account)
        this.cashAmount = Number(o.cashAmount)
        this.createdAt = new Date(o.createdAt)
    }
}
