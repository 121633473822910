import {UserPolicySearchOption} from "../../search-option/user/UserPolicySearchOption"

/**
 * 사용자 정책 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserPolicySearchOptionDescription: Record<keyof UserPolicySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    signUpSolarAmountEq: '회원가입 지급 솔라 일치',
    signUpSolarAmountNeq: '회원가입 지급 솔라 불일치',
    signUpSolarAmountMin: '회원가입 지급 솔라 최소',
    signUpSolarAmountMax: '회원가입 지급 솔라 최대',
    signUpLunarAmountEq: '회원가입 지급 루나 일치',
    signUpLunarAmountNeq: '회원가입 지급 루나 불일치',
    signUpLunarAmountMin: '회원가입 지급 루나 최소',
    signUpLunarAmountMax: '회원가입 지급 루나 최대',
    signUpStarAmountEq: '회원가입 지급 스타 일치',
    signUpStarAmountNeq: '회원가입 지급 스타 불일치',
    signUpStarAmountMin: '회원가입 지급 스타 최소',
    signUpStarAmountMax: '회원가입 지급 스타 최대',
    recommenderSolarAmountEq: '추천인 지급 솔라 일치',
    recommenderSolarAmountNeq: '추천인 지급 솔라 불일치',
    recommenderSolarAmountMin: '추천인 지급 솔라 최소',
    recommenderSolarAmountMax: '추천인 지급 솔라 최대',
    recommenderLunarAmountEq: '추천인 지급 루나 일치',
    recommenderLunarAmountNeq: '추천인 지급 루나 불일치',
    recommenderLunarAmountMin: '추천인 지급 루나 최소',
    recommenderLunarAmountMax: '추천인 지급 루나 최대',
    recommenderStarAmountEq: '추천인 지급 스타 일치',
    recommenderStarAmountNeq: '추천인 지급 스타 불일치',
    recommenderStarAmountMin: '추천인 지급 스타 최소',
    recommenderStarAmountMax: '추천인 지급 스타 최대',
    recommenderRewardThresholdEq: '추천인 보상 기준 일치',
    recommenderRewardThresholdNeq: '추천인 보상 기준 불일치',
    recommenderRewardThresholdMin: '추천인 보상 기준 최소',
    recommenderRewardThresholdMax: '추천인 보상 기준 최대',
    recommendeeSolarAmountEq: '피추천인 지급 솔라 일치',
    recommendeeSolarAmountNeq: '피추천인 지급 솔라 불일치',
    recommendeeSolarAmountMin: '피추천인 지급 솔라 최소',
    recommendeeSolarAmountMax: '피추천인 지급 솔라 최대',
    recommendeeLunarAmountEq: '피추천인 지급 루나 일치',
    recommendeeLunarAmountNeq: '피추천인 지급 루나 불일치',
    recommendeeLunarAmountMin: '피추천인 지급 루나 최소',
    recommendeeLunarAmountMax: '피추천인 지급 루나 최대',
    recommendeeStarAmountEq: '피추천인 지급 스타 일치',
    recommendeeStarAmountNeq: '피추천인 지급 스타 불일치',
    recommendeeStarAmountMin: '피추천인 지급 스타 최소',
    recommendeeStarAmountMax: '피추천인 지급 스타 최대',
    userPageSizeEq: '사용자 페이지 크기 일치',
    userPageSizeNeq: '사용자 페이지 크기 불일치',
    userPageSizeMin: '사용자 페이지 크기 최소',
    userPageSizeMax: '사용자 페이지 크기 최대',
    userAgreementPageSizeEq: '사용자 이용 약관 동의 페이지 크기 일치',
    userAgreementPageSizeNeq: '사용자 이용 약관 동의 페이지 크기 불일치',
    userAgreementPageSizeMin: '사용자 이용 약관 동의 페이지 크기 최소',
    userAgreementPageSizeMax: '사용자 이용 약관 동의 페이지 크기 최대',
    userAuthenticationCodePageSizeEq: '사용자 인증 번호 페이지 크기 일치',
    userAuthenticationCodePageSizeNeq: '사용자 인증 번호 페이지 크기 불일치',
    userAuthenticationCodePageSizeMin: '사용자 인증 번호 페이지 크기 최소',
    userAuthenticationCodePageSizeMax: '사용자 인증 번호 페이지 크기 최대',
    userAuthenticationCodeExpireSecondsEq: '사용자 인증 번호 만료 시간(초) 일치',
    userAuthenticationCodeExpireSecondsNeq: '사용자 인증 번호 만료 시간(초) 불일치',
    userAuthenticationCodeExpireSecondsMin: '사용자 인증 번호 만료 시간(초) 최소',
    userAuthenticationCodeExpireSecondsMax: '사용자 인증 번호 만료 시간(초) 최대',
    userAuthenticationCodeThresholdMinutesEq: '사용자 인증 번호 제한 단위 시간(분) 일치',
    userAuthenticationCodeThresholdMinutesNeq: '사용자 인증 번호 제한 단위 시간(분) 불일치',
    userAuthenticationCodeThresholdMinutesMin: '사용자 인증 번호 제한 단위 시간(분) 최소',
    userAuthenticationCodeThresholdMinutesMax: '사용자 인증 번호 제한 단위 시간(분) 최대',
    userAuthenticationCodeThresholdCountEq: '사용자 인증 번호 제한 단위 횟수 일치',
    userAuthenticationCodeThresholdCountNeq: '사용자 인증 번호 제한 단위 횟수 불일치',
    userAuthenticationCodeThresholdCountMin: '사용자 인증 번호 제한 단위 횟수 최소',
    userAuthenticationCodeThresholdCountMax: '사용자 인증 번호 제한 단위 횟수 최대',
    userBlockPageSizeEq: '사용자 이용 정지 페이지 크기 일치',
    userBlockPageSizeNeq: '사용자 이용 정지 페이지 크기 불일치',
    userBlockPageSizeMin: '사용자 이용 정지 페이지 크기 최소',
    userBlockPageSizeMax: '사용자 이용 정지 페이지 크기 최대',
    userBlockReasonPageSizeEq: '사용자 이용 정지 사유 페이지 크기 일치',
    userBlockReasonPageSizeNeq: '사용자 이용 정지 사유 페이지 크기 불일치',
    userBlockReasonPageSizeMin: '사용자 이용 정지 사유 페이지 크기 최소',
    userBlockReasonPageSizeMax: '사용자 이용 정지 사유 페이지 크기 최대',
    userExpPageSizeEq: '사용자 경험치 페이지 크기 일치',
    userExpPageSizeNeq: '사용자 경험치 페이지 크기 불일치',
    userExpPageSizeMin: '사용자 경험치 페이지 크기 최소',
    userExpPageSizeMax: '사용자 경험치 페이지 크기 최대',
    userInquiryPageSizeEq: '사용자 문의 페이지 크기 일치',
    userInquiryPageSizeNeq: '사용자 문의 페이지 크기 불일치',
    userInquiryPageSizeMin: '사용자 문의 페이지 크기 최소',
    userInquiryPageSizeMax: '사용자 문의 페이지 크기 최대',
    userLevelPageSizeEq: '사용자 레벨 페이지 크기 일치',
    userLevelPageSizeNeq: '사용자 레벨 페이지 크기 불일치',
    userLevelPageSizeMin: '사용자 레벨 페이지 크기 최소',
    userLevelPageSizeMax: '사용자 레벨 페이지 크기 최대',
    userLevelHistoryPageSizeEq: '사용자 레벨 기록 페이지 크기 일치',
    userLevelHistoryPageSizeNeq: '사용자 레벨 기록 페이지 크기 불일치',
    userLevelHistoryPageSizeMin: '사용자 레벨 기록 페이지 크기 최소',
    userLevelHistoryPageSizeMax: '사용자 레벨 기록 페이지 크기 최대',
    userNotificationPageSizeEq: '사용자 알림 페이지 크기 일치',
    userNotificationPageSizeNeq: '사용자 알림 페이지 크기 불일치',
    userNotificationPageSizeMin: '사용자 알림 페이지 크기 최소',
    userNotificationPageSizeMax: '사용자 알림 페이지 크기 최대',
    userPolicyPageSizeEq: '사용자 정책 페이지 크기 일치',
    userPolicyPageSizeNeq: '사용자 정책 페이지 크기 불일치',
    userPolicyPageSizeMin: '사용자 정책 페이지 크기 최소',
    userPolicyPageSizeMax: '사용자 정책 페이지 크기 최대',
    userRankPageSizeEq: '사용자 랭크 페이지 크기 일치',
    userRankPageSizeNeq: '사용자 랭크 페이지 크기 불일치',
    userRankPageSizeMin: '사용자 랭크 페이지 크기 최소',
    userRankPageSizeMax: '사용자 랭크 페이지 크기 최대',
    userRankCacheSchedulerDurationMinutesEq: '사용자 랭크 캐시 스케줄러 주기(분) 일치',
    userRankCacheSchedulerDurationMinutesNeq: '사용자 랭크 캐시 스케줄러 주기(분) 불일치',
    userRankCacheSchedulerDurationMinutesMin: '사용자 랭크 캐시 스케줄러 주기(분) 최소',
    userRankCacheSchedulerDurationMinutesMax: '사용자 랭크 캐시 스케줄러 주기(분) 최대',
    userRankCacheRefreshDurationMinutesEq: '사용자 랭크 캐시 새로고침 시간(분) 일치',
    userRankCacheRefreshDurationMinutesNeq: '사용자 랭크 캐시 새로고침 시간(분) 불일치',
    userRankCacheRefreshDurationMinutesMin: '사용자 랭크 캐시 새로고침 시간(분) 최소',
    userRankCacheRefreshDurationMinutesMax: '사용자 랭크 캐시 새로고침 시간(분) 최대',
    userRankDurationEq: '사용자 랭크 집계 기간 일치',
    userRankDurationNeq: '사용자 랭크 집계 기간 불일치',
    userRankDurationMin: '사용자 랭크 집계 기간 최소',
    userRankDurationMax: '사용자 랭크 집계 기간 최대',
    userRankDurationIn: '사용자 랭크 집계 기간 포함',
    userRankHistoryPageSizeEq: '사용자 랭크 기록 페이지 크기 일치',
    userRankHistoryPageSizeNeq: '사용자 랭크 기록 페이지 크기 불일치',
    userRankHistoryPageSizeMin: '사용자 랭크 기록 페이지 크기 최소',
    userRankHistoryPageSizeMax: '사용자 랭크 기록 페이지 크기 최대',
    userSessionPageSizeEq: '사용자 세션 페이지 크기 일치',
    userSessionPageSizeNeq: '사용자 세션 페이지 크기 불일치',
    userSessionPageSizeMin: '사용자 세션 페이지 크기 최소',
    userSessionPageSizeMax: '사용자 세션 페이지 크기 최대',
    userSessionMaxAgeSecondsEq: '미사용 세션 유지 시간(초) 일치',
    userSessionMaxAgeSecondsNeq: '미사용 세션 유지 시간(초) 불일치',
    userSessionMaxAgeSecondsMin: '미사용 세션 유지 시간(초) 최소',
    userSessionMaxAgeSecondsMax: '미사용 세션 유지 시간(초) 최대',
    userSignInPageSizeEq: '사용자 로그인 페이지 크기 일치',
    userSignInPageSizeNeq: '사용자 로그인 페이지 크기 불일치',
    userSignInPageSizeMin: '사용자 로그인 페이지 크기 최소',
    userSignInPageSizeMax: '사용자 로그인 페이지 크기 최대',
    userWithdrawalPageSizeEq: '사용자 탈퇴 페이지 크기 일치',
    userWithdrawalPageSizeNeq: '사용자 탈퇴 페이지 크기 불일치',
    userWithdrawalPageSizeMin: '사용자 탈퇴 페이지 크기 최소',
    userWithdrawalPageSizeMax: '사용자 탈퇴 페이지 크기 최대',
    userWithdrawalReasonPageSizeEq: '사용자 탈퇴 사유 페이지 크기 일치',
    userWithdrawalReasonPageSizeNeq: '사용자 탈퇴 사유 페이지 크기 불일치',
    userWithdrawalReasonPageSizeMin: '사용자 탈퇴 사유 페이지 크기 최소',
    userWithdrawalReasonPageSizeMax: '사용자 탈퇴 사유 페이지 크기 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
