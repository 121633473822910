import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {SortOrder} from "ts-protocol-extension";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {
    AssetStarUsageValueObject
} from "../../../../data/value-object/asset/AssetStarUsageValueObject";
import {
    AssetStarUsageSearchOption
} from "../../../../data/search-option/asset/AssetStarUsageSearchOption";
import {AssetStarUsageIO} from "../../../../io/AssetIO";
import {
    AssetStarUsageDescription
} from "../../../../data/description/asset/AssetStarUsageDescription";
import {AssetStarUsageDescriptor} from "../../../../data/descriptor/asset/AssetStarUsageDescriptor";
import {
    AssetStarUsageSearchOptionDescription
} from "../../../../data/description/asset/AssetStarUsageSearchOptionDescription";
import {
    applyAssetStarUsageSearchOption,
    AssetStarUsageSearchOptionField
} from "../../../search-option-field/asset/AssetStarUsageSearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetStarUsageValueObject[]>([])
    const option = useStatePair<AssetStarUsageSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetStarUsageIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetStarUsageDescription}
                descriptor={AssetStarUsageDescriptor}
                showPost
                onPostClick={() => document.location = '/assets/stars/usages/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/stars/usages/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetStarUsageSearchOptionDescription}
                fields={AssetStarUsageSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetStarUsageSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetStarUsageDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}