import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {Body1, Button, Caption1Strong, Spinner} from "@fluentui/react-components";
import {mapByKey} from "../../../../util/ObjectUtil";
import {DeleteDialog, DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {
    AssetSolarWithdrawalOptionValueObject
} from "../../../../data/value-object/asset/AssetSolarWithdrawalOptionValueObject";
import {AssetLunarExchangeOptionIO, AssetSolarWithdrawalOptionIO} from "../../../../io/AssetIO";
import {
    AssetSolarWithdrawalOptionDescription
} from "../../../../data/description/asset/AssetSolarWithdrawalOptionDescription";
import {AssetSolarWithdrawalOptionDomain} from "../../../../data/domain/asset/AssetSolarWithdrawalOptionDomain";
import {AssetSolarWithdrawalOptionDescriptor} from "../../../../data/descriptor/asset/AssetSolarWithdrawalOptionDescriptor";
import {
    AssetLunarExchangeOptionDescription
} from "../../../../data/description/asset/AssetLunarExchangeOptionDescription";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetSolarWithdrawalOptionId: string }>()
    const item = useStatePair<AssetSolarWithdrawalOptionValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetSolarWithdrawalOptionId !== undefined) {
            AssetSolarWithdrawalOptionIO
                .getById(BigInt(params.assetSolarWithdrawalOptionId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetSolarWithdrawalOptionValueObject
}

function Page(props: PageProps) {
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    const onDeleteClick = () => {
        isDeleting.setTrue()
        AssetSolarWithdrawalOptionIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/assets/solars/exchangeOptions'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetSolarWithdrawalOptionIO}
                description={AssetSolarWithdrawalOptionDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetSolarWithdrawalOptionDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "solarAmount":
        case "cashAmount":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{AssetSolarWithdrawalOptionDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetSolarWithdrawalOptionDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}