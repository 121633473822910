import {UserRankDuration} from "../../../data/constant/user/UserRankDuration"
import {UserPolicySearchOptionDescription} from "../../../data/description/user/UserPolicySearchOptionDescription"
import {UserPolicySearchOption} from "../../../data/search-option/user/UserPolicySearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function UserPolicySearchOptionField(props: ListOptionFieldProps<UserPolicySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpSolarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpSolarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpSolarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpSolarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpSolarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpSolarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpSolarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpSolarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpLunarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpLunarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpLunarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpLunarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpLunarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpLunarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpLunarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpLunarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpStarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpStarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpStarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpStarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpStarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpStarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "signUpStarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.signUpStarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderSolarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderSolarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderSolarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderSolarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderSolarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderSolarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderSolarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderSolarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderLunarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderLunarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderLunarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderLunarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderLunarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderLunarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderLunarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderLunarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderStarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderStarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderStarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderStarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderStarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderStarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderStarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderStarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderRewardThresholdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderRewardThresholdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderRewardThresholdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderRewardThresholdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderRewardThresholdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderRewardThresholdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderRewardThresholdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommenderRewardThresholdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeSolarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeSolarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeSolarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeSolarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeSolarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeSolarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeSolarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeSolarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeLunarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeLunarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeLunarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeLunarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeLunarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeLunarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeLunarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeLunarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeStarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeStarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeStarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeStarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeStarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeStarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeStarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.recommendeeStarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAgreementPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAgreementPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAgreementPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAgreementPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAgreementPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAgreementPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAgreementPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAgreementPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeExpireSecondsEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeExpireSecondsEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeExpireSecondsNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeExpireSecondsNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeExpireSecondsMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeExpireSecondsMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeExpireSecondsMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeExpireSecondsMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userAuthenticationCodeThresholdCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userAuthenticationCodeThresholdCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockReasonPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockReasonPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockReasonPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockReasonPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockReasonPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockReasonPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockReasonPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userBlockReasonPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userExpPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userExpPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userExpPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userExpPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userInquiryPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userInquiryPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userInquiryPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userInquiryPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userInquiryPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userInquiryPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userInquiryPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userInquiryPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelHistoryPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelHistoryPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelHistoryPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelHistoryPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelHistoryPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelHistoryPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userLevelHistoryPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userLevelHistoryPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userNotificationPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userNotificationPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userNotificationPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userNotificationPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userNotificationPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userNotificationPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userNotificationPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userNotificationPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPolicyPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPolicyPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPolicyPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPolicyPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPolicyPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPolicyPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userPolicyPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userPolicyPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheSchedulerDurationMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheSchedulerDurationMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheSchedulerDurationMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheSchedulerDurationMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheSchedulerDurationMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheSchedulerDurationMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheSchedulerDurationMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheSchedulerDurationMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheRefreshDurationMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheRefreshDurationMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheRefreshDurationMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheRefreshDurationMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheRefreshDurationMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheRefreshDurationMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankCacheRefreshDurationMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankCacheRefreshDurationMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankDurationEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankDurationEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "userRankDurationNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankDurationNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "userRankDurationMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankDurationMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "userRankDurationMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankDurationMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "userRankDurationIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankDurationIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "userRankHistoryPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankHistoryPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankHistoryPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankHistoryPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankHistoryPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankHistoryPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankHistoryPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userRankHistoryPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionMaxAgeSecondsEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionMaxAgeSecondsEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionMaxAgeSecondsNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionMaxAgeSecondsNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionMaxAgeSecondsMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionMaxAgeSecondsMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSessionMaxAgeSecondsMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSessionMaxAgeSecondsMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSignInPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSignInPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSignInPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userSignInPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalReasonPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalReasonPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalReasonPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalReasonPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalReasonPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalReasonPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userWithdrawalReasonPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.userWithdrawalReasonPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserPolicySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserPolicySearchOption(key: keyof UserPolicySearchOption, value: string, into: StatePair<UserPolicySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "signUpSolarAmountEq":
            into.setter({
                ...into.value,
                signUpSolarAmountEq: Number(value)
            })
            break
        case "signUpSolarAmountNeq":
            into.setter({
                ...into.value,
                signUpSolarAmountNeq: Number(value)
            })
            break
        case "signUpSolarAmountMin":
            into.setter({
                ...into.value,
                signUpSolarAmountMin: Number(value)
            })
            break
        case "signUpSolarAmountMax":
            into.setter({
                ...into.value,
                signUpSolarAmountMax: Number(value)
            })
            break
        case "signUpLunarAmountEq":
            into.setter({
                ...into.value,
                signUpLunarAmountEq: Number(value)
            })
            break
        case "signUpLunarAmountNeq":
            into.setter({
                ...into.value,
                signUpLunarAmountNeq: Number(value)
            })
            break
        case "signUpLunarAmountMin":
            into.setter({
                ...into.value,
                signUpLunarAmountMin: Number(value)
            })
            break
        case "signUpLunarAmountMax":
            into.setter({
                ...into.value,
                signUpLunarAmountMax: Number(value)
            })
            break
        case "signUpStarAmountEq":
            into.setter({
                ...into.value,
                signUpStarAmountEq: Number(value)
            })
            break
        case "signUpStarAmountNeq":
            into.setter({
                ...into.value,
                signUpStarAmountNeq: Number(value)
            })
            break
        case "signUpStarAmountMin":
            into.setter({
                ...into.value,
                signUpStarAmountMin: Number(value)
            })
            break
        case "signUpStarAmountMax":
            into.setter({
                ...into.value,
                signUpStarAmountMax: Number(value)
            })
            break
        case "recommenderSolarAmountEq":
            into.setter({
                ...into.value,
                recommenderSolarAmountEq: Number(value)
            })
            break
        case "recommenderSolarAmountNeq":
            into.setter({
                ...into.value,
                recommenderSolarAmountNeq: Number(value)
            })
            break
        case "recommenderSolarAmountMin":
            into.setter({
                ...into.value,
                recommenderSolarAmountMin: Number(value)
            })
            break
        case "recommenderSolarAmountMax":
            into.setter({
                ...into.value,
                recommenderSolarAmountMax: Number(value)
            })
            break
        case "recommenderLunarAmountEq":
            into.setter({
                ...into.value,
                recommenderLunarAmountEq: Number(value)
            })
            break
        case "recommenderLunarAmountNeq":
            into.setter({
                ...into.value,
                recommenderLunarAmountNeq: Number(value)
            })
            break
        case "recommenderLunarAmountMin":
            into.setter({
                ...into.value,
                recommenderLunarAmountMin: Number(value)
            })
            break
        case "recommenderLunarAmountMax":
            into.setter({
                ...into.value,
                recommenderLunarAmountMax: Number(value)
            })
            break
        case "recommenderStarAmountEq":
            into.setter({
                ...into.value,
                recommenderStarAmountEq: Number(value)
            })
            break
        case "recommenderStarAmountNeq":
            into.setter({
                ...into.value,
                recommenderStarAmountNeq: Number(value)
            })
            break
        case "recommenderStarAmountMin":
            into.setter({
                ...into.value,
                recommenderStarAmountMin: Number(value)
            })
            break
        case "recommenderStarAmountMax":
            into.setter({
                ...into.value,
                recommenderStarAmountMax: Number(value)
            })
            break
        case "recommenderRewardThresholdEq":
            into.setter({
                ...into.value,
                recommenderRewardThresholdEq: Number(value)
            })
            break
        case "recommenderRewardThresholdNeq":
            into.setter({
                ...into.value,
                recommenderRewardThresholdNeq: Number(value)
            })
            break
        case "recommenderRewardThresholdMin":
            into.setter({
                ...into.value,
                recommenderRewardThresholdMin: Number(value)
            })
            break
        case "recommenderRewardThresholdMax":
            into.setter({
                ...into.value,
                recommenderRewardThresholdMax: Number(value)
            })
            break
        case "recommendeeSolarAmountEq":
            into.setter({
                ...into.value,
                recommendeeSolarAmountEq: Number(value)
            })
            break
        case "recommendeeSolarAmountNeq":
            into.setter({
                ...into.value,
                recommendeeSolarAmountNeq: Number(value)
            })
            break
        case "recommendeeSolarAmountMin":
            into.setter({
                ...into.value,
                recommendeeSolarAmountMin: Number(value)
            })
            break
        case "recommendeeSolarAmountMax":
            into.setter({
                ...into.value,
                recommendeeSolarAmountMax: Number(value)
            })
            break
        case "recommendeeLunarAmountEq":
            into.setter({
                ...into.value,
                recommendeeLunarAmountEq: Number(value)
            })
            break
        case "recommendeeLunarAmountNeq":
            into.setter({
                ...into.value,
                recommendeeLunarAmountNeq: Number(value)
            })
            break
        case "recommendeeLunarAmountMin":
            into.setter({
                ...into.value,
                recommendeeLunarAmountMin: Number(value)
            })
            break
        case "recommendeeLunarAmountMax":
            into.setter({
                ...into.value,
                recommendeeLunarAmountMax: Number(value)
            })
            break
        case "recommendeeStarAmountEq":
            into.setter({
                ...into.value,
                recommendeeStarAmountEq: Number(value)
            })
            break
        case "recommendeeStarAmountNeq":
            into.setter({
                ...into.value,
                recommendeeStarAmountNeq: Number(value)
            })
            break
        case "recommendeeStarAmountMin":
            into.setter({
                ...into.value,
                recommendeeStarAmountMin: Number(value)
            })
            break
        case "recommendeeStarAmountMax":
            into.setter({
                ...into.value,
                recommendeeStarAmountMax: Number(value)
            })
            break
        case "userPageSizeEq":
            into.setter({
                ...into.value,
                userPageSizeEq: Number(value)
            })
            break
        case "userPageSizeNeq":
            into.setter({
                ...into.value,
                userPageSizeNeq: Number(value)
            })
            break
        case "userPageSizeMin":
            into.setter({
                ...into.value,
                userPageSizeMin: Number(value)
            })
            break
        case "userPageSizeMax":
            into.setter({
                ...into.value,
                userPageSizeMax: Number(value)
            })
            break
        case "userAgreementPageSizeEq":
            into.setter({
                ...into.value,
                userAgreementPageSizeEq: Number(value)
            })
            break
        case "userAgreementPageSizeNeq":
            into.setter({
                ...into.value,
                userAgreementPageSizeNeq: Number(value)
            })
            break
        case "userAgreementPageSizeMin":
            into.setter({
                ...into.value,
                userAgreementPageSizeMin: Number(value)
            })
            break
        case "userAgreementPageSizeMax":
            into.setter({
                ...into.value,
                userAgreementPageSizeMax: Number(value)
            })
            break
        case "userAuthenticationCodePageSizeEq":
            into.setter({
                ...into.value,
                userAuthenticationCodePageSizeEq: Number(value)
            })
            break
        case "userAuthenticationCodePageSizeNeq":
            into.setter({
                ...into.value,
                userAuthenticationCodePageSizeNeq: Number(value)
            })
            break
        case "userAuthenticationCodePageSizeMin":
            into.setter({
                ...into.value,
                userAuthenticationCodePageSizeMin: Number(value)
            })
            break
        case "userAuthenticationCodePageSizeMax":
            into.setter({
                ...into.value,
                userAuthenticationCodePageSizeMax: Number(value)
            })
            break
        case "userAuthenticationCodeExpireSecondsEq":
            into.setter({
                ...into.value,
                userAuthenticationCodeExpireSecondsEq: Number(value)
            })
            break
        case "userAuthenticationCodeExpireSecondsNeq":
            into.setter({
                ...into.value,
                userAuthenticationCodeExpireSecondsNeq: Number(value)
            })
            break
        case "userAuthenticationCodeExpireSecondsMin":
            into.setter({
                ...into.value,
                userAuthenticationCodeExpireSecondsMin: Number(value)
            })
            break
        case "userAuthenticationCodeExpireSecondsMax":
            into.setter({
                ...into.value,
                userAuthenticationCodeExpireSecondsMax: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdMinutesEq":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdMinutesEq: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdMinutesNeq":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdMinutesNeq: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdMinutesMin":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdMinutesMin: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdMinutesMax":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdMinutesMax: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdCountEq":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdCountEq: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdCountNeq":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdCountNeq: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdCountMin":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdCountMin: Number(value)
            })
            break
        case "userAuthenticationCodeThresholdCountMax":
            into.setter({
                ...into.value,
                userAuthenticationCodeThresholdCountMax: Number(value)
            })
            break
        case "userBlockPageSizeEq":
            into.setter({
                ...into.value,
                userBlockPageSizeEq: Number(value)
            })
            break
        case "userBlockPageSizeNeq":
            into.setter({
                ...into.value,
                userBlockPageSizeNeq: Number(value)
            })
            break
        case "userBlockPageSizeMin":
            into.setter({
                ...into.value,
                userBlockPageSizeMin: Number(value)
            })
            break
        case "userBlockPageSizeMax":
            into.setter({
                ...into.value,
                userBlockPageSizeMax: Number(value)
            })
            break
        case "userBlockReasonPageSizeEq":
            into.setter({
                ...into.value,
                userBlockReasonPageSizeEq: Number(value)
            })
            break
        case "userBlockReasonPageSizeNeq":
            into.setter({
                ...into.value,
                userBlockReasonPageSizeNeq: Number(value)
            })
            break
        case "userBlockReasonPageSizeMin":
            into.setter({
                ...into.value,
                userBlockReasonPageSizeMin: Number(value)
            })
            break
        case "userBlockReasonPageSizeMax":
            into.setter({
                ...into.value,
                userBlockReasonPageSizeMax: Number(value)
            })
            break
        case "userExpPageSizeEq":
            into.setter({
                ...into.value,
                userExpPageSizeEq: Number(value)
            })
            break
        case "userExpPageSizeNeq":
            into.setter({
                ...into.value,
                userExpPageSizeNeq: Number(value)
            })
            break
        case "userExpPageSizeMin":
            into.setter({
                ...into.value,
                userExpPageSizeMin: Number(value)
            })
            break
        case "userExpPageSizeMax":
            into.setter({
                ...into.value,
                userExpPageSizeMax: Number(value)
            })
            break
        case "userInquiryPageSizeEq":
            into.setter({
                ...into.value,
                userInquiryPageSizeEq: Number(value)
            })
            break
        case "userInquiryPageSizeNeq":
            into.setter({
                ...into.value,
                userInquiryPageSizeNeq: Number(value)
            })
            break
        case "userInquiryPageSizeMin":
            into.setter({
                ...into.value,
                userInquiryPageSizeMin: Number(value)
            })
            break
        case "userInquiryPageSizeMax":
            into.setter({
                ...into.value,
                userInquiryPageSizeMax: Number(value)
            })
            break
        case "userLevelPageSizeEq":
            into.setter({
                ...into.value,
                userLevelPageSizeEq: Number(value)
            })
            break
        case "userLevelPageSizeNeq":
            into.setter({
                ...into.value,
                userLevelPageSizeNeq: Number(value)
            })
            break
        case "userLevelPageSizeMin":
            into.setter({
                ...into.value,
                userLevelPageSizeMin: Number(value)
            })
            break
        case "userLevelPageSizeMax":
            into.setter({
                ...into.value,
                userLevelPageSizeMax: Number(value)
            })
            break
        case "userLevelHistoryPageSizeEq":
            into.setter({
                ...into.value,
                userLevelHistoryPageSizeEq: Number(value)
            })
            break
        case "userLevelHistoryPageSizeNeq":
            into.setter({
                ...into.value,
                userLevelHistoryPageSizeNeq: Number(value)
            })
            break
        case "userLevelHistoryPageSizeMin":
            into.setter({
                ...into.value,
                userLevelHistoryPageSizeMin: Number(value)
            })
            break
        case "userLevelHistoryPageSizeMax":
            into.setter({
                ...into.value,
                userLevelHistoryPageSizeMax: Number(value)
            })
            break
        case "userNotificationPageSizeEq":
            into.setter({
                ...into.value,
                userNotificationPageSizeEq: Number(value)
            })
            break
        case "userNotificationPageSizeNeq":
            into.setter({
                ...into.value,
                userNotificationPageSizeNeq: Number(value)
            })
            break
        case "userNotificationPageSizeMin":
            into.setter({
                ...into.value,
                userNotificationPageSizeMin: Number(value)
            })
            break
        case "userNotificationPageSizeMax":
            into.setter({
                ...into.value,
                userNotificationPageSizeMax: Number(value)
            })
            break
        case "userPolicyPageSizeEq":
            into.setter({
                ...into.value,
                userPolicyPageSizeEq: Number(value)
            })
            break
        case "userPolicyPageSizeNeq":
            into.setter({
                ...into.value,
                userPolicyPageSizeNeq: Number(value)
            })
            break
        case "userPolicyPageSizeMin":
            into.setter({
                ...into.value,
                userPolicyPageSizeMin: Number(value)
            })
            break
        case "userPolicyPageSizeMax":
            into.setter({
                ...into.value,
                userPolicyPageSizeMax: Number(value)
            })
            break
        case "userRankPageSizeEq":
            into.setter({
                ...into.value,
                userRankPageSizeEq: Number(value)
            })
            break
        case "userRankPageSizeNeq":
            into.setter({
                ...into.value,
                userRankPageSizeNeq: Number(value)
            })
            break
        case "userRankPageSizeMin":
            into.setter({
                ...into.value,
                userRankPageSizeMin: Number(value)
            })
            break
        case "userRankPageSizeMax":
            into.setter({
                ...into.value,
                userRankPageSizeMax: Number(value)
            })
            break
        case "userRankCacheSchedulerDurationMinutesEq":
            into.setter({
                ...into.value,
                userRankCacheSchedulerDurationMinutesEq: Number(value)
            })
            break
        case "userRankCacheSchedulerDurationMinutesNeq":
            into.setter({
                ...into.value,
                userRankCacheSchedulerDurationMinutesNeq: Number(value)
            })
            break
        case "userRankCacheSchedulerDurationMinutesMin":
            into.setter({
                ...into.value,
                userRankCacheSchedulerDurationMinutesMin: Number(value)
            })
            break
        case "userRankCacheSchedulerDurationMinutesMax":
            into.setter({
                ...into.value,
                userRankCacheSchedulerDurationMinutesMax: Number(value)
            })
            break
        case "userRankCacheRefreshDurationMinutesEq":
            into.setter({
                ...into.value,
                userRankCacheRefreshDurationMinutesEq: Number(value)
            })
            break
        case "userRankCacheRefreshDurationMinutesNeq":
            into.setter({
                ...into.value,
                userRankCacheRefreshDurationMinutesNeq: Number(value)
            })
            break
        case "userRankCacheRefreshDurationMinutesMin":
            into.setter({
                ...into.value,
                userRankCacheRefreshDurationMinutesMin: Number(value)
            })
            break
        case "userRankCacheRefreshDurationMinutesMax":
            into.setter({
                ...into.value,
                userRankCacheRefreshDurationMinutesMax: Number(value)
            })
            break
        case "userRankDurationEq":
            into.setter({
                ...into.value,
                userRankDurationEq: value as UserRankDuration
            })
            break
        case "userRankDurationNeq":
            into.setter({
                ...into.value,
                userRankDurationNeq: value as UserRankDuration
            })
            break
        case "userRankDurationMin":
            into.setter({
                ...into.value,
                userRankDurationMin: value as UserRankDuration
            })
            break
        case "userRankDurationMax":
            into.setter({
                ...into.value,
                userRankDurationMax: value as UserRankDuration
            })
            break
        case "userRankDurationIn":
            into.setter({
                ...into.value,
                userRankDurationIn: value
                    .split('|')
                    .map(e => e as UserRankDuration)
            })
            break
        case "userRankHistoryPageSizeEq":
            into.setter({
                ...into.value,
                userRankHistoryPageSizeEq: Number(value)
            })
            break
        case "userRankHistoryPageSizeNeq":
            into.setter({
                ...into.value,
                userRankHistoryPageSizeNeq: Number(value)
            })
            break
        case "userRankHistoryPageSizeMin":
            into.setter({
                ...into.value,
                userRankHistoryPageSizeMin: Number(value)
            })
            break
        case "userRankHistoryPageSizeMax":
            into.setter({
                ...into.value,
                userRankHistoryPageSizeMax: Number(value)
            })
            break
        case "userSessionPageSizeEq":
            into.setter({
                ...into.value,
                userSessionPageSizeEq: Number(value)
            })
            break
        case "userSessionPageSizeNeq":
            into.setter({
                ...into.value,
                userSessionPageSizeNeq: Number(value)
            })
            break
        case "userSessionPageSizeMin":
            into.setter({
                ...into.value,
                userSessionPageSizeMin: Number(value)
            })
            break
        case "userSessionPageSizeMax":
            into.setter({
                ...into.value,
                userSessionPageSizeMax: Number(value)
            })
            break
        case "userSessionMaxAgeSecondsEq":
            into.setter({
                ...into.value,
                userSessionMaxAgeSecondsEq: Number(value)
            })
            break
        case "userSessionMaxAgeSecondsNeq":
            into.setter({
                ...into.value,
                userSessionMaxAgeSecondsNeq: Number(value)
            })
            break
        case "userSessionMaxAgeSecondsMin":
            into.setter({
                ...into.value,
                userSessionMaxAgeSecondsMin: Number(value)
            })
            break
        case "userSessionMaxAgeSecondsMax":
            into.setter({
                ...into.value,
                userSessionMaxAgeSecondsMax: Number(value)
            })
            break
        case "userSignInPageSizeEq":
            into.setter({
                ...into.value,
                userSignInPageSizeEq: Number(value)
            })
            break
        case "userSignInPageSizeNeq":
            into.setter({
                ...into.value,
                userSignInPageSizeNeq: Number(value)
            })
            break
        case "userSignInPageSizeMin":
            into.setter({
                ...into.value,
                userSignInPageSizeMin: Number(value)
            })
            break
        case "userSignInPageSizeMax":
            into.setter({
                ...into.value,
                userSignInPageSizeMax: Number(value)
            })
            break
        case "userWithdrawalPageSizeEq":
            into.setter({
                ...into.value,
                userWithdrawalPageSizeEq: Number(value)
            })
            break
        case "userWithdrawalPageSizeNeq":
            into.setter({
                ...into.value,
                userWithdrawalPageSizeNeq: Number(value)
            })
            break
        case "userWithdrawalPageSizeMin":
            into.setter({
                ...into.value,
                userWithdrawalPageSizeMin: Number(value)
            })
            break
        case "userWithdrawalPageSizeMax":
            into.setter({
                ...into.value,
                userWithdrawalPageSizeMax: Number(value)
            })
            break
        case "userWithdrawalReasonPageSizeEq":
            into.setter({
                ...into.value,
                userWithdrawalReasonPageSizeEq: Number(value)
            })
            break
        case "userWithdrawalReasonPageSizeNeq":
            into.setter({
                ...into.value,
                userWithdrawalReasonPageSizeNeq: Number(value)
            })
            break
        case "userWithdrawalReasonPageSizeMin":
            into.setter({
                ...into.value,
                userWithdrawalReasonPageSizeMin: Number(value)
            })
            break
        case "userWithdrawalReasonPageSizeMax":
            into.setter({
                ...into.value,
                userWithdrawalReasonPageSizeMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
