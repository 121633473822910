import {AssetLunarUsageType} from "../../constant/asset/AssetLunarUsageType"
import {AssetLunarUsageDomain} from "../../domain/asset/AssetLunarUsageDomain"

export const AssetLunarUsageDescriptor: Record<keyof AssetLunarUsageDomain, (o: AssetLunarUsageDomain) => string> = {
    id(o: AssetLunarUsageDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetLunarUsageDomain): string {
        return o.creatorId.toString()
    },
    userId(o: AssetLunarUsageDomain): string {
        return o.userId.toString()
    },
    type(o: AssetLunarUsageDomain): string {
        return AssetLunarUsageType.description(o.type)
    },
    amount(o: AssetLunarUsageDomain): string {
        return o.amount.toString()
    },
    lastAccumulation(o: AssetLunarUsageDomain): string {
        return o.lastAccumulation.toString()
    },
    positiveAccumulation(o: AssetLunarUsageDomain): string {
        return o.positiveAccumulation.toString()
    },
    negativeAccumulation(o: AssetLunarUsageDomain): string {
        return o.negativeAccumulation.toString()
    },
    createdAt(o: AssetLunarUsageDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
