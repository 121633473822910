import {EnvConstructionDomain} from "../../domain/env/EnvConstructionDomain"
import {EnvConstructionId} from "../../id/env/EnvConstructionId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 작업
 */
export class EnvConstructionValueObject implements ValueObject, EnvConstructionDomain {
    /**
     * 기본키
     */
    id: EnvConstructionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 제목
     */
    title: string
    /**
     * 내용
     */
    body: string
    /**
     * 시작일
     */
    startAt: Date
    /**
     * 종료일
     */
    endAt: Date
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof EnvConstructionDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.title = String(o.title)
        this.body = String(o.body)
        this.startAt = new Date(o.startAt)
        this.endAt = new Date(o.endAt)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
