import {UiPolicyIO, UiPolicyModificationIO} from "../../../io/UiIO";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    UiPolicyValueObject
} from "../../../data/value-object/ui/UiPolicyValueObject";
import {useEffect} from "react";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {
    UiPolicyDescription
} from "../../../data/description/ui/UiPolicyDescription";
import {UiPolicyDomain} from "../../../data/domain/ui/UiPolicyDomain";
import {UiPolicyDescriptor} from "../../../data/descriptor/ui/UiPolicyDescriptor";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {
    UiPolicyModificationValueObject
} from "../../../data/value-object/ui/UiPolicyModificationValueObject";
import {
    UiPolicyModificationSearchOption
} from "../../../data/search-option/ui/UiPolicyModificationSearchOption";
import {
    UiPolicyModificationDescription
} from "../../../data/description/ui/UiPolicyModificationDescription";
import {
    UiPolicyModificationDescriptor
} from "../../../data/descriptor/ui/UiPolicyModificationDescriptor";

enum Panel {
    Default,
    UiPolicyModificationList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ uiPolicyId: string }>()
    const item = useStatePair<UiPolicyValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.uiPolicyId !== undefined) {
            UiPolicyIO
                .getById(BigInt(params.uiPolicyId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UiPolicyValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UiPolicyModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UiPolicyIO}
                description={UiPolicyDescription}
                properties={props => <PropertyBody {...props} />} />
            <UiPolicyModificationListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UiPolicyDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "uiHomeContentSuggestionPageSize":
        case "uiHomeContentUserProductionPageSize":
        case "uiHomeNoticePageSize":
        case "uiOnboardingPageSize":
        case "uiPolicyPageSize":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{UiPolicyDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UiPolicyDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function UiPolicyModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<UiPolicyModificationValueObject[]>([])
    const modificationSearchOption: UiPolicyModificationSearchOption = { uiPolicyIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.UiPolicyModificationList && <>
            <ListPageWrapper
                {...props}
                client={UiPolicyModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UiPolicyModificationDescription}
                descriptor={UiPolicyModificationDescriptor} />
        </>}
    </>
}