import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    UiHomeContentUserProductionValueObject
} from "../../../../../data/value-object/ui/UiHomeContentUserProductionValueObject";
import {
    UiHomeContentUserProductionSearchOption
} from "../../../../../data/search-option/ui/UiHomeContentUserProductionSearchOption";
import {searchIndex, SearchIndex} from "../../../../../io/HttpClient";
import ListPageWrapper from "../../../../ListPageWrapper";
import {UiHomeContentUserProductionIO} from "../../../../../io/UiIO";
import {
    UiHomeContentUserProductionDescription
} from "../../../../../data/description/ui/UiHomeContentUserProductionDescription";
import {
    UiHomeContentUserProductionDescriptor
} from "../../../../../data/descriptor/ui/UiHomeContentUserProductionDescriptor";
import ListOption from "../../../../ListOption";
import {
    UiHomeContentUserProductionSearchOptionDescription
} from "../../../../../data/description/ui/UiHomeContentUserProductionSearchOptionDescription";
import {
    applyUiHomeContentUserProductionSearchOption,
    UiHomeContentUserProductionSearchOptionField
} from "../../../../search-option-field/ui/UiHomeContentUserProductionSearchOptionField";
import ListOrder from "../../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UiHomeContentUserProductionValueObject[]>([])
    const option = useStatePair<UiHomeContentUserProductionSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UiHomeContentUserProductionIO}
                items={items}
                option={option.value}
                index={index}
                description={UiHomeContentUserProductionDescription}
                descriptor={UiHomeContentUserProductionDescriptor}
                showPost
                onPostClick={() => document.location = '/ui/home/contents/userProductions/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/ui/home/contents/userProductions/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UiHomeContentUserProductionSearchOptionDescription}
                fields={UiHomeContentUserProductionSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUiHomeContentUserProductionSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UiHomeContentUserProductionDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}