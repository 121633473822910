import {UserRankDuration} from "../../constant/user/UserRankDuration"
import {UserPolicyDomain} from "../../domain/user/UserPolicyDomain"

export const UserPolicyDescriptor: Record<keyof UserPolicyDomain, (o: UserPolicyDomain) => string> = {
    id(o: UserPolicyDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserPolicyDomain): string {
        return o.creatorId.toString()
    },
    signUpSolarAmount(o: UserPolicyDomain): string {
        return o.signUpSolarAmount.toString()
    },
    signUpLunarAmount(o: UserPolicyDomain): string {
        return o.signUpLunarAmount.toString()
    },
    signUpStarAmount(o: UserPolicyDomain): string {
        return o.signUpStarAmount.toString()
    },
    recommenderSolarAmount(o: UserPolicyDomain): string {
        return o.recommenderSolarAmount.toString()
    },
    recommenderLunarAmount(o: UserPolicyDomain): string {
        return o.recommenderLunarAmount.toString()
    },
    recommenderStarAmount(o: UserPolicyDomain): string {
        return o.recommenderStarAmount.toString()
    },
    recommenderRewardThreshold(o: UserPolicyDomain): string {
        return o.recommenderRewardThreshold.toString()
    },
    recommendeeSolarAmount(o: UserPolicyDomain): string {
        return o.recommendeeSolarAmount.toString()
    },
    recommendeeLunarAmount(o: UserPolicyDomain): string {
        return o.recommendeeLunarAmount.toString()
    },
    recommendeeStarAmount(o: UserPolicyDomain): string {
        return o.recommendeeStarAmount.toString()
    },
    userPageSize(o: UserPolicyDomain): string {
        return o.userPageSize.toString()
    },
    userAgreementPageSize(o: UserPolicyDomain): string {
        return o.userAgreementPageSize.toString()
    },
    userAuthenticationCodePageSize(o: UserPolicyDomain): string {
        return o.userAuthenticationCodePageSize.toString()
    },
    userAuthenticationCodeExpireSeconds(o: UserPolicyDomain): string {
        return o.userAuthenticationCodeExpireSeconds.toString()
    },
    userAuthenticationCodeThresholdMinutes(o: UserPolicyDomain): string {
        return o.userAuthenticationCodeThresholdMinutes.toString()
    },
    userAuthenticationCodeThresholdCount(o: UserPolicyDomain): string {
        return o.userAuthenticationCodeThresholdCount.toString()
    },
    userBlockPageSize(o: UserPolicyDomain): string {
        return o.userBlockPageSize.toString()
    },
    userBlockReasonPageSize(o: UserPolicyDomain): string {
        return o.userBlockReasonPageSize.toString()
    },
    userExpPageSize(o: UserPolicyDomain): string {
        return o.userExpPageSize.toString()
    },
    userInquiryPageSize(o: UserPolicyDomain): string {
        return o.userInquiryPageSize.toString()
    },
    userLevelPageSize(o: UserPolicyDomain): string {
        return o.userLevelPageSize.toString()
    },
    userLevelHistoryPageSize(o: UserPolicyDomain): string {
        return o.userLevelHistoryPageSize.toString()
    },
    userNotificationPageSize(o: UserPolicyDomain): string {
        return o.userNotificationPageSize.toString()
    },
    userPolicyPageSize(o: UserPolicyDomain): string {
        return o.userPolicyPageSize.toString()
    },
    userRankPageSize(o: UserPolicyDomain): string {
        return o.userRankPageSize.toString()
    },
    userRankCacheSchedulerDurationMinutes(o: UserPolicyDomain): string {
        return o.userRankCacheSchedulerDurationMinutes.toString()
    },
    userRankCacheRefreshDurationMinutes(o: UserPolicyDomain): string {
        return o.userRankCacheRefreshDurationMinutes.toString()
    },
    userRankDuration(o: UserPolicyDomain): string {
        return UserRankDuration.description(o.userRankDuration)
    },
    userRankHistoryPageSize(o: UserPolicyDomain): string {
        return o.userRankHistoryPageSize.toString()
    },
    userSessionPageSize(o: UserPolicyDomain): string {
        return o.userSessionPageSize.toString()
    },
    userSessionMaxAgeSeconds(o: UserPolicyDomain): string {
        return o.userSessionMaxAgeSeconds.toString()
    },
    userSignInPageSize(o: UserPolicyDomain): string {
        return o.userSignInPageSize.toString()
    },
    userWithdrawalPageSize(o: UserPolicyDomain): string {
        return o.userWithdrawalPageSize.toString()
    },
    userWithdrawalReasonPageSize(o: UserPolicyDomain): string {
        return o.userWithdrawalReasonPageSize.toString()
    },
    createdAt(o: UserPolicyDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserPolicyDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
