import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import ExchangeOptionRouter from "./exchangeOption/ExchangeOptionRouter";
import UsageRouter from "./usage/UsageRouter";
import PurchaseOptionRouter from "./purchaseOption/PurchaseOptionRouter";
import {Button, mergeClasses} from "@fluentui/react-components";
import {ChevronRight24Filled} from "@fluentui/react-icons";

export default function LunarRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/exchangeOptions/*'} element={<ExchangeOptionRouter {...props} />} />
            <Route path={'/purchaseOptions/*'} element={<PurchaseOptionRouter {...props} />} />
            <Route path={'/usages/*'} element={<UsageRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/lunars/exchangeOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                루나 교환 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/lunars/purchaseOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                루나 구매 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/lunars/usages'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                루나 사용
            </Button>
        </div>
    </>
}