import {EnvPolicySearchOption} from "../../search-option/env/EnvPolicySearchOption"

/**
 * 환경 정책 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvPolicySearchOptionDescription: Record<keyof EnvPolicySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    envAdminPageSizeEq: 'Admin 페이지 크기 일치',
    envAdminPageSizeNeq: 'Admin 페이지 크기 불일치',
    envAdminPageSizeMin: 'Admin 페이지 크기 최소',
    envAdminPageSizeMax: 'Admin 페이지 크기 최대',
    envAgreementPageSizeEq: '이용 약관 페이지 크기 일치',
    envAgreementPageSizeNeq: '이용 약관 페이지 크기 불일치',
    envAgreementPageSizeMin: '이용 약관 페이지 크기 최소',
    envAgreementPageSizeMax: '이용 약관 페이지 크기 최대',
    envBankPageSizeEq: '은행 페이지 크기 일치',
    envBankPageSizeNeq: '은행 페이지 크기 불일치',
    envBankPageSizeMin: '은행 페이지 크기 최소',
    envBankPageSizeMax: '은행 페이지 크기 최대',
    envClientPageSizeEq: '클라이언트 페이지 크기 일치',
    envClientPageSizeNeq: '클라이언트 페이지 크기 불일치',
    envClientPageSizeMin: '클라이언트 페이지 크기 최소',
    envClientPageSizeMax: '클라이언트 페이지 크기 최대',
    envConstructionPageSizeEq: '작업 페이지 크기 일치',
    envConstructionPageSizeNeq: '작업 페이지 크기 불일치',
    envConstructionPageSizeMin: '작업 페이지 크기 최소',
    envConstructionPageSizeMax: '작업 페이지 크기 최대',
    envCountryPageSizeEq: '국가 페이지 크기 일치',
    envCountryPageSizeNeq: '국가 페이지 크기 불일치',
    envCountryPageSizeMin: '국가 페이지 크기 최소',
    envCountryPageSizeMax: '국가 페이지 크기 최대',
    envCountryRankDurationEq: '국가 랭크 집계 기간 일치',
    envCountryRankDurationNeq: '국가 랭크 집계 기간 불일치',
    envCountryRankDurationMin: '국가 랭크 집계 기간 최소',
    envCountryRankDurationMax: '국가 랭크 집계 기간 최대',
    envCountryRankDurationIn: '국가 랭크 집계 기간 포함',
    envEventPageSizeEq: '이벤트 페이지 크기 일치',
    envEventPageSizeNeq: '이벤트 페이지 크기 불일치',
    envEventPageSizeMin: '이벤트 페이지 크기 최소',
    envEventPageSizeMax: '이벤트 페이지 크기 최대',
    envFaqPageSizeEq: '자주 묻는 질문 페이지 크기 일치',
    envFaqPageSizeNeq: '자주 묻는 질문 페이지 크기 불일치',
    envFaqPageSizeMin: '자주 묻는 질문 페이지 크기 최소',
    envFaqPageSizeMax: '자주 묻는 질문 페이지 크기 최대',
    envFaqItemPageSizeEq: '자주 묻는 질문 항목 페이지 크기 일치',
    envFaqItemPageSizeNeq: '자주 묻는 질문 항목 페이지 크기 불일치',
    envFaqItemPageSizeMin: '자주 묻는 질문 항목 페이지 크기 최소',
    envFaqItemPageSizeMax: '자주 묻는 질문 항목 페이지 크기 최대',
    envNoticePageSizeEq: '공지사항 페이지 크기 일치',
    envNoticePageSizeNeq: '공지사항 페이지 크기 불일치',
    envNoticePageSizeMin: '공지사항 페이지 크기 최소',
    envNoticePageSizeMax: '공지사항 페이지 크기 최대',
    envPhoneBlockPageSizeEq: '전화번호 차단 페이지 크기 일치',
    envPhoneBlockPageSizeNeq: '전화번호 차단 페이지 크기 불일치',
    envPhoneBlockPageSizeMin: '전화번호 차단 페이지 크기 최소',
    envPhoneBlockPageSizeMax: '전화번호 차단 페이지 크기 최대',
    envPolicyPageSizeEq: '환경 정책 페이지 크기 일치',
    envPolicyPageSizeNeq: '환경 정책 페이지 크기 불일치',
    envPolicyPageSizeMin: '환경 정책 페이지 크기 최소',
    envPolicyPageSizeMax: '환경 정책 페이지 크기 최대',
    envStorageKeyPageSizeEq: 'AWS S3 키 페이지 크기 일치',
    envStorageKeyPageSizeNeq: 'AWS S3 키 페이지 크기 불일치',
    envStorageKeyPageSizeMin: 'AWS S3 키 페이지 크기 최소',
    envStorageKeyPageSizeMax: 'AWS S3 키 페이지 크기 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
