import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import DurationRouter from "./duration/DurationRouter";
import ParticipationRouter from "../participation/ParticipationRouter";
import RewardRouter from "./reward/RewardRouter";
import ScriptRouter from "./script/ScriptRouter";

export default function UserProductionRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/durations/*'} element={<DurationRouter {...props} />} />
            <Route path={'/participations/*'} element={<ParticipationRouter {...props} />} />
            <Route path={'/rewards/*'} element={<RewardRouter {...props} />} />
            <Route path={'/scripts/*'} element={<ScriptRouter {...props} />} />
        </Routes>
    </>
}