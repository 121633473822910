import {UserSessionSearchOptionDescription} from "../../../data/description/user/UserSessionSearchOptionDescription"
import {UserSessionSearchOption} from "../../../data/search-option/user/UserSessionSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function UserSessionSearchOptionField(props: ListOptionFieldProps<UserSessionSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.userSignInIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.userSignInIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.userSignInIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.userSignInIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.userSignInIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "outboundNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.outboundNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "outboundMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.outboundMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "outboundMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.outboundMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "outboundLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.outboundLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "outboundNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.outboundNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastModifiedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.lastModifiedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastModifiedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.lastModifiedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastModifiedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.lastModifiedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastModifiedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.lastModifiedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastModifiedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSessionSearchOptionDescription.lastModifiedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserSessionSearchOption(key: keyof UserSessionSearchOption, value: string, into: StatePair<UserSessionSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userSignInIdEq":
            into.setter({
                ...into.value,
                userSignInIdEq: BigInt(value)
            })
            break
        case "userSignInIdNeq":
            into.setter({
                ...into.value,
                userSignInIdNeq: BigInt(value)
            })
            break
        case "userSignInIdMin":
            into.setter({
                ...into.value,
                userSignInIdMin: BigInt(value)
            })
            break
        case "userSignInIdMax":
            into.setter({
                ...into.value,
                userSignInIdMax: BigInt(value)
            })
            break
        case "userSignInIdIsNull":
            into.setter({
                ...into.value,
                userSignInIdIsNull: Boolean(value)
            })
            break
        case "outboundNeq":
            into.setter({
                ...into.value,
                outboundNeq: String(value)
            })
            break
        case "outboundMin":
            into.setter({
                ...into.value,
                outboundMin: String(value)
            })
            break
        case "outboundMax":
            into.setter({
                ...into.value,
                outboundMax: String(value)
            })
            break
        case "outboundLike":
            into.setter({
                ...into.value,
                outboundLike: String(value)
            })
            break
        case "outboundNotLike":
            into.setter({
                ...into.value,
                outboundNotLike: String(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "lastModifiedAtEq":
            into.setter({
                ...into.value,
                lastModifiedAtEq: new Date(value)
            })
            break
        case "lastModifiedAtNeq":
            into.setter({
                ...into.value,
                lastModifiedAtNeq: new Date(value)
            })
            break
        case "lastModifiedAtMin":
            into.setter({
                ...into.value,
                lastModifiedAtMin: new Date(value)
            })
            break
        case "lastModifiedAtMax":
            into.setter({
                ...into.value,
                lastModifiedAtMax: new Date(value)
            })
            break
        case "lastModifiedAtIsNull":
            into.setter({
                ...into.value,
                lastModifiedAtIsNull: Boolean(value)
            })
            break
    }
}
