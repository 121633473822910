import {EnvNoticeVerboseDomain} from "../../domain/env/EnvNoticeVerboseDomain"
import {EnvNoticeId} from "../../id/env/EnvNoticeId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 공지사항 상세
 */
export class EnvNoticeVerboseValueObject implements VerboseValueObject, EnvNoticeVerboseDomain {
    /**
     * 기본키
     */
    id: EnvNoticeId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 제목
     */
    title: string
    /**
     * URL
     */
    url: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof EnvNoticeVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.title = String(o.title)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
