import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvStorageKeyDomain} from "../../domain/env/EnvStorageKeyDomain"

export const EnvStorageKeyDescriptor: Record<keyof EnvStorageKeyDomain, (o: EnvStorageKeyDomain) => string> = {
    id(o: EnvStorageKeyDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvStorageKeyDomain): string {
        return o.creatorId.toString()
    },
    accessKeyId(o: EnvStorageKeyDomain): string {
        return o.accessKeyId
    },
    secretAccessKey(o: EnvStorageKeyDomain): string {
        return o.secretAccessKey
    },
    bucket(o: EnvStorageKeyDomain): string {
        return o.bucket
    },
    region(o: EnvStorageKeyDomain): string {
        return o.region
    },
    distribution(o: EnvStorageKeyDomain): string {
        return EnvClientDistribution.description(o.distribution)
    },
    createdAt(o: EnvStorageKeyDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
