import {RouterProps} from "../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserValueObject} from "../../data/value-object/user/UserValueObject";
import {UserSearchOption} from "../../data/search-option/user/UserSearchOption";
import {SearchIndex} from "../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../ListPageWrapper";
import {UserIO} from "../../io/UserIO";
import {UserDescription} from "../../data/description/user/UserDescription";
import {UserDescriptor} from "../../data/descriptor/user/UserDescriptor";
import ListOption from "../ListOption";
import {UserSearchOptionDescription} from "../../data/description/user/UserSearchOptionDescription";
import {applyUserSearchOption, UserSearchOptionField} from "../search-option-field/user/UserSearchOptionField";
import ListOrder from "../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserValueObject[]>([])
    const option = useStatePair<UserSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserIO}
                items={items}
                option={option.value}
                index={index}
                description={UserDescription}
                descriptor={UserDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserSearchOptionDescription}
                fields={UserSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}