export enum UserExpType {
    /**
     * 회원가입
     */
    SignUp = 'SignUp',

    /**
     * 관리자 지급
     */
    Admin = 'Admin',

    /**
     * 콘텐츠 참여(오늘의 단어)
     */
    ContentDailyParticipation = 'ContentDailyParticipation',

    /**
     * 콘텐츠 합격(오늘의 단어)
     */
    ContentDailyParticipationPass = 'ContentDailyParticipationPass',

    /**
     * 콘텐츠 참여(따라말하기)
     */
    ContentRepeatParticipation = 'ContentRepeatParticipation',

    /**
     * 콘텐츠 합격(따라말하기)
     */
    ContentRepeatParticipationPass = 'ContentRepeatParticipationPass',

    /**
     * 콘텐츠 참여(암기하기)
     */
    ContentM10nParticipation = 'ContentM10nParticipation',

    /**
     * 콘텐츠 합격(암기하기)
     */
    ContentM10nParticipationPass = 'ContentM10nParticipationPass',

    /**
     * 콘텐츠 참여(와글와글)
     */
    ContentUserProductionParticipation = 'ContentUserProductionParticipation',

    /**
     * 콘텐츠 합격(와글와글)
     */
    ContentUserProductionParticipationPass = 'ContentUserProductionParticipationPass',

    /**
     * 콘텐츠 생성(와글와글)
     */
    ContentUserProduction = 'ContentUserProduction',

    /**
     * 콘텐츠 평가(와글와글)
     */
    ContentUserProductionReview = 'ContentUserProductionReview',

    /**
     * 콘텐츠 참여 완료 URL 클릭(공통)
     */
    ContentResultUrlClick = 'ContentResultUrlClick',

    /**
     * 루나 구매
     */
    AssetLunarPurchase = 'AssetLunarPurchase',

    /**
     * 루나 교환
     */
    AssetLunarExchange = 'AssetLunarExchange',

    /**
     * 솔라 인출
     */
    AssetSolarWithdrawal = 'AssetSolarWithdrawal',

    /**
     * 스타 교환
     */
    AssetStarExchange = 'AssetStarExchange',

    /**
     * 스타 소모
     */
    AssetStarUsage = 'AssetStarUsage'
}

export namespace UserExpType {
    export function description(o: UserExpType) {
        switch (o) {
            case UserExpType.SignUp: return '회원가입'
            case UserExpType.Admin: return '관리자 지급'
            case UserExpType.ContentDailyParticipation: return '콘텐츠 참여(오늘의 단어)'
            case UserExpType.ContentDailyParticipationPass: return '콘텐츠 합격(오늘의 단어)'
            case UserExpType.ContentRepeatParticipation: return '콘텐츠 참여(따라말하기)'
            case UserExpType.ContentRepeatParticipationPass: return '콘텐츠 합격(따라말하기)'
            case UserExpType.ContentM10nParticipation: return '콘텐츠 참여(암기하기)'
            case UserExpType.ContentM10nParticipationPass: return '콘텐츠 합격(암기하기)'
            case UserExpType.ContentUserProductionParticipation: return '콘텐츠 참여(와글와글)'
            case UserExpType.ContentUserProductionParticipationPass: return '콘텐츠 합격(와글와글)'
            case UserExpType.ContentUserProduction: return '콘텐츠 생성(와글와글)'
            case UserExpType.ContentUserProductionReview: return '콘텐츠 평가(와글와글)'
            case UserExpType.ContentResultUrlClick: return '콘텐츠 참여 완료 URL 클릭(공통)'
            case UserExpType.AssetLunarPurchase: return '루나 구매'
            case UserExpType.AssetLunarExchange: return '루나 교환'
            case UserExpType.AssetSolarWithdrawal: return '솔라 인출'
            case UserExpType.AssetStarExchange: return '스타 교환'
            case UserExpType.AssetStarUsage: return '스타 소모'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: UserExpType.SignUp, label: description(UserExpType.SignUp) },
        { value: UserExpType.Admin, label: description(UserExpType.Admin) },
        { value: UserExpType.ContentDailyParticipation, label: description(UserExpType.ContentDailyParticipation) },
        { value: UserExpType.ContentDailyParticipationPass, label: description(UserExpType.ContentDailyParticipationPass) },
        { value: UserExpType.ContentRepeatParticipation, label: description(UserExpType.ContentRepeatParticipation) },
        { value: UserExpType.ContentRepeatParticipationPass, label: description(UserExpType.ContentRepeatParticipationPass) },
        { value: UserExpType.ContentM10nParticipation, label: description(UserExpType.ContentM10nParticipation) },
        { value: UserExpType.ContentM10nParticipationPass, label: description(UserExpType.ContentM10nParticipationPass) },
        { value: UserExpType.ContentUserProductionParticipation, label: description(UserExpType.ContentUserProductionParticipation) },
        { value: UserExpType.ContentUserProductionParticipationPass, label: description(UserExpType.ContentUserProductionParticipationPass) },
        { value: UserExpType.ContentUserProduction, label: description(UserExpType.ContentUserProduction) },
        { value: UserExpType.ContentUserProductionReview, label: description(UserExpType.ContentUserProductionReview) },
        { value: UserExpType.ContentResultUrlClick, label: description(UserExpType.ContentResultUrlClick) },
        { value: UserExpType.AssetLunarPurchase, label: description(UserExpType.AssetLunarPurchase) },
        { value: UserExpType.AssetLunarExchange, label: description(UserExpType.AssetLunarExchange) },
        { value: UserExpType.AssetSolarWithdrawal, label: description(UserExpType.AssetSolarWithdrawal) },
        { value: UserExpType.AssetStarExchange, label: description(UserExpType.AssetStarExchange) },
        { value: UserExpType.AssetStarUsage, label: description(UserExpType.AssetStarUsage) }
    ]
}
