import {EnvConstructionDomain} from "../../domain/env/EnvConstructionDomain"

export const EnvConstructionDescriptor: Record<keyof EnvConstructionDomain, (o: EnvConstructionDomain) => string> = {
    id(o: EnvConstructionDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvConstructionDomain): string {
        return o.creatorId.toString()
    },
    title(o: EnvConstructionDomain): string {
        return o.title
    },
    body(o: EnvConstructionDomain): string {
        return o.body
    },
    startAt(o: EnvConstructionDomain): string {
        return o.startAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    endAt(o: EnvConstructionDomain): string {
        return o.endAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    createdAt(o: EnvConstructionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvConstructionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvConstructionDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
