import {ContentDetailUrlClickDomain} from "../../domain/content/ContentDetailUrlClickDomain"
import {ContentDetailUrlClickId} from "../../id/content/ContentDetailUrlClickId"
import {ContentDetailUrlId} from "../../id/content/ContentDetailUrlId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 더 알아보기 URL 클릭
 */
export class ContentDetailUrlClickValueObject implements ValueObject, ContentDetailUrlClickDomain {
    /**
     * 기본키
     */
    id: ContentDetailUrlClickId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 더 알아보기 URL ID
     */
    contentDetailUrlId: ContentDetailUrlId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentDetailUrlClickDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentDetailUrlId = BigInt(o.contentDetailUrlId)
        this.createdAt = new Date(o.createdAt)
    }
}
