import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvFaqItemValueObject} from "../../../../data/value-object/env/EnvFaqItemValueObject";
import {useEffect} from "react";
import {EnvFaqItemIO, EnvFaqItemModificationIO} from "../../../../io/EnvIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../../DetailViews";
import {EnvFaqItemDomain} from "../../../../data/domain/env/EnvFaqItemDomain";
import {EnvFaqItemDescriptor} from "../../../../data/descriptor/env/EnvFaqItemDescriptor";
import {EnvFaqItemDescription} from "../../../../data/description/env/EnvFaqItemDescription";
import {EnvFaqItemModificationValueObject} from "../../../../data/value-object/env/EnvFaqItemModificationValueObject";
import {
    EnvFaqItemModificationSearchOption
} from "../../../../data/search-option/env/EnvFaqItemModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {EnvFaqItemModificationDescription} from "../../../../data/description/env/EnvFaqItemModificationDescription";
import {EnvFaqItemModificationDescriptor} from "../../../../data/descriptor/env/EnvFaqItemModificationDescriptor";

enum Panel {
    Default,
    EnvFaqItemModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envFaqItemId: string }>()
    const item = useStatePair<EnvFaqItemValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envFaqItemId !== undefined) {
            EnvFaqItemIO
                .getById(BigInt(params.envFaqItemId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvFaqItemValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvFaqItemModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvFaqItemIO}
                description={EnvFaqItemDescription}
                properties={props => <PropertyBody {...props} />}/>
            <EnvFaqItemModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvFaqItemDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "title":
        case "body":
        case "displayOrder":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{EnvFaqItemDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a
                href={`/users/${props.item.creatorId}`}><Body1>{EnvFaqItemDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envFaqId":
            return <a
                href={`/env/faqs/${props.item.envFaqId}`}><Body1>{EnvFaqItemDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function EnvFaqItemModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvFaqItemModificationValueObject[]>([])
    const option: EnvFaqItemModificationSearchOption = { envFaqIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvFaqItemModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvFaqItemModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvFaqItemModificationDescription}
                descriptor={EnvFaqItemModificationDescriptor} />
        </>}
    </>
}