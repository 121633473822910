import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvClientPlatform} from "../../constant/env/EnvClientPlatform"
import {EnvClientModificationDomain} from "../../domain/env/EnvClientModificationDomain"

export const EnvClientModificationDescriptor: Record<keyof EnvClientModificationDomain, (o: EnvClientModificationDomain) => string> = {
    id(o: EnvClientModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvClientModificationDomain): string {
        return o.creatorId.toString()
    },
    envClientId(o: EnvClientModificationDomain): string {
        return o.envClientId.toString()
    },
    platform(o: EnvClientModificationDomain): string {
        return EnvClientPlatform.description(o.platform)
    },
    distribution(o: EnvClientModificationDomain): string {
        return EnvClientDistribution.description(o.distribution)
    },
    versionName(o: EnvClientModificationDomain): string {
        return o.versionName
    },
    expiredAt(o: EnvClientModificationDomain): string {
        return o.expiredAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    createdAt(o: EnvClientModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
