import {AssetStarChargeVerboseDomain} from "../../domain/asset/AssetStarChargeVerboseDomain"
import {AssetStarChargeId} from "../../id/asset/AssetStarChargeId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 스타 충전 상세
 */
export class AssetStarChargeVerboseValueObject implements VerboseValueObject, AssetStarChargeVerboseDomain {
    /**
     * 기본키
     */
    id: AssetStarChargeId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 충전 예정 시각
     */
    scheduledAt: Date
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof AssetStarChargeVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.scheduledAt = new Date(o.scheduledAt)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
    }
}
