import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvCountryValueObject} from "../../../data/value-object/env/EnvCountryValueObject";
import {JSX, useEffect} from "react";
import {EnvCountryIO, EnvCountryModificationIO} from "../../../io/EnvIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {EnvCountryDomain} from "../../../data/domain/env/EnvCountryDomain";
import {EnvCountryDescriptor} from "../../../data/descriptor/env/EnvCountryDescriptor";
import {EnvCountryDescription} from "../../../data/description/env/EnvCountryDescription";
import {EnvCountryModificationValueObject} from "../../../data/value-object/env/EnvCountryModificationValueObject";
import {
    EnvCountryModificationSearchOption
} from "../../../data/search-option/env/EnvCountryModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvCountryModificationDescription} from "../../../data/description/env/EnvCountryModificationDescription";
import {EnvCountryModificationDescriptor} from "../../../data/descriptor/env/EnvCountryModificationDescriptor";
import {UserValueObject} from "../../../data/value-object/user/UserValueObject";
import {UserSearchOption} from "../../../data/search-option/user/UserSearchOption";
import {UserIO} from "../../../io/UserIO";
import {UserDescription} from "../../../data/description/user/UserDescription";
import {UserDescriptor} from "../../../data/descriptor/user/UserDescriptor";

enum Panel {
    Default,
    EnvCountryModificationList,
    UserBirthList,
    UserResidenceList,
    UserPhoneList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envCountryId: string }>()
    const item = useStatePair<EnvCountryValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envCountryId !== undefined) {
            EnvCountryIO
                .getById(BigInt(params.envCountryId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvCountryValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvCountryModificationList}>수정 이력</Tab>
                <Tab value={Panel.UserBirthList}>출신 사용자 목록</Tab>
                <Tab value={Panel.UserResidenceList}>거주 사용자 목록</Tab>
                <Tab value={Panel.UserPhoneList}>휴대폰 사용자 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvCountryIO}
                description={EnvCountryDescription}
                properties={props => <PropertyBody {...props} />}/>
            <EnvCountryModificationListPanel
                {...props}
                panel={panel.value} />
            <UserBirthListPanel
                {...props}
                panel={panel.value} />
            <UserResidenceListPanel
                {...props}
                panel={panel.value} />
            <UserPhoneListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvCountryDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "name":
        case "isoCode":
        case "emoji":
        case "phoneCode":
        case "userBirthCount":
        case "userResidenceCount":
        case "userPhoneCodeCount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{EnvCountryDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a
                href={`/users/${props.item.creatorId}`}><Body1>{EnvCountryDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function EnvCountryModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvCountryModificationValueObject[]>([])
    const option: EnvCountryModificationSearchOption = { envCountryIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvCountryModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvCountryModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvCountryModificationDescription}
                descriptor={EnvCountryModificationDescriptor} />
        </>}
    </>
}

function UserBirthListPanel(props: PanelProps) {
    const items = useStatePair<UserValueObject[]>([])
    const option: UserSearchOption = { birthCountryIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserBirthList && <>
            <ListPageWrapper
                {...props}
                client={UserIO}
                items={items}
                option={option}
                index={index}
                description={UserDescription}
                descriptor={UserDescriptor} />
        </>}
    </>
}

function UserResidenceListPanel(props: PanelProps) {
    const items = useStatePair<UserValueObject[]>([])
    const option: UserSearchOption = { residenceCountryIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserResidenceList && <>
            <ListPageWrapper
                {...props}
                client={UserIO}
                items={items}
                option={option}
                index={index}
                description={UserDescription}
                descriptor={UserDescriptor} />
        </>}
    </>
}

function UserPhoneListPanel(props: PanelProps) {
    const items = useStatePair<UserValueObject[]>([])
    const option: UserSearchOption = { phoneCountryIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserPhoneList && <>
            <ListPageWrapper
                {...props}
                client={UserIO}
                items={items}
                option={option}
                index={index}
                description={UserDescription}
                descriptor={UserDescriptor} />
        </>}
    </>
}