import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryDomain} from "../../domain/content/ContentQueryDomain"

export const ContentQueryDescriptor: Record<keyof ContentQueryDomain, (o: ContentQueryDomain) => string> = {
    id(o: ContentQueryDomain): string {
        return o.id.toString()
    },
    userId(o: ContentQueryDomain): string {
        return o.userId.toString()
    },
    contentType(o: ContentQueryDomain): string {
        return ContentType.description(o.contentType)
    },
    body(o: ContentQueryDomain): string {
        return o.body
    },
    createdAt(o: ContentQueryDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    concealedAt(o: ContentQueryDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
