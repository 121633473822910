import {UiHomeContentSuggestionModificationDomain} from "../../domain/ui/UiHomeContentSuggestionModificationDomain"

export const UiHomeContentSuggestionModificationDescriptor: Record<keyof UiHomeContentSuggestionModificationDomain, (o: UiHomeContentSuggestionModificationDomain) => string> = {
    id(o: UiHomeContentSuggestionModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiHomeContentSuggestionModificationDomain): string {
        return o.creatorId.toString()
    },
    uiHomeContentSuggestionId(o: UiHomeContentSuggestionModificationDomain): string {
        return o.uiHomeContentSuggestionId.toString()
    },
    displayOrder(o: UiHomeContentSuggestionModificationDomain): string {
        return o.displayOrder.toString()
    },
    url(o: UiHomeContentSuggestionModificationDomain): string {
        return o.url
    },
    createdAt(o: UiHomeContentSuggestionModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
