import {StatePair, useStatePair} from "react-type-extension";
import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {EnvFaqItemVerboseValueObject} from "../../../../data/value-object/env/EnvFaqItemVerboseValueObject";
import React, {useCallback, useEffect} from "react";
import {EnvFaqIO, EnvFaqItemIO} from "../../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostBigIntField,
    PostFieldWrapper, PostFinishDialog,
    PostInputField,
    PostNumberField,
    PostTextareaField
} from "../../../PostField";
import {EnvFaqItemDescription} from "../../../../data/description/env/EnvFaqItemDescription";
import {EnvFaqId} from "../../../../data/id/env/EnvFaqId";
import {searchIndex} from "../../../../io/HttpClient";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import ListPageWrapper from "../../../ListPageWrapper";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {EnvFaqDomain} from "../../../../data/domain/env/EnvFaqDomain";
import {EnvFaqSearchOptionDescription} from "../../../../data/description/env/EnvFaqSearchOptionDescription";
import {
    applyEnvFaqSearchOption,
    EnvFaqSearchOptionField
} from "../../../search-option-field/env/EnvFaqSearchOptionField";
import {EnvFaqDescription} from "../../../../data/description/env/EnvFaqDescription";
import {EnvFaqDescriptor} from "../../../../data/descriptor/env/EnvFaqDescriptor";
import {EnvFaqSearchOption} from "../../../../data/search-option/env/EnvFaqSearchOption";
import {EnvFaqValueObject} from "../../../../data/value-object/env/EnvFaqValueObject";

enum Phase {
    Loading,
    Waiting,
    Manipulating,
    Finished,
    EnvFaqList,
    EnvFaqOption,
    EnvFaqOrder
}

export default function Edit(props: RouterProps) {
    const isPost = document.location.pathname.endsWith('post')
    const params = useParams<{ envFaqId: string, envFaqItemId: string }>()
    const faq = useStatePair<EnvFaqValueObject | undefined>(undefined)
    const item = useStatePair<EnvFaqItemVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const envFaqId = useStatePair<EnvFaqId>(0n)
    const title = useStatePair('')
    const body = useStatePair('')
    const displayOrder = useStatePair(0)

    useEffect(() => {
        if (params.envFaqId === undefined && params.envFaqItemId === undefined) {
            phase.setter(Phase.Waiting)
        }
    }, []);

    useEffect(() => {
        if (params.envFaqId !== undefined) {
            EnvFaqIO
                .getById(BigInt(params.envFaqId))
                .then(value => {
                    faq.setter(value)
                    envFaqId.setter(value.id)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    useEffect(() => {
        if (item.value === undefined && params.envFaqItemId !== undefined) {
            EnvFaqItemIO
                .getVerboseById(BigInt(params.envFaqItemId))
                .then(value => {
                    item.setter(value)
                    envFaqId.setter(value.envFaqId)
                    title.setter(value.title)
                    body.setter(value.body)
                    displayOrder.setter(value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Manipulating)
        const dto = {
            envFaqId: envFaqId.value,
            title: title.value,
            body: body.value,
            displayOrder: displayOrder.value,
        }

        if (isPost) {
            EnvFaqItemIO
                .post(dto)
                .then(() => phase.setter(Phase.Finished))
                .catch(reason => {
                    console.error(reason)
                    alert('추가를 실패했습니다.')
                    phase.setter(Phase.Waiting)
                })
        } else {
            const id = item.value?.id
            if (id === undefined) {
                return
            }

            EnvFaqItemIO
                .put(id, dto)
                .then(() => {
                    alert('수정되었습니다.')
                    document.location = `/env/faqs/items/${id}`
                })
                .catch(reason => {
                    console.error(reason)
                    alert('수정을 실패했습니다.')
                    phase.setter(Phase.Waiting)
                })
        }
    }, [envFaqId.value, title.value, body.value, displayOrder.value,])

    return <>
        <div className={props.styles.column16}>
            {(phase.value === Phase.Waiting || phase.value === Phase.Manipulating || phase.value === Phase.Finished) && <>
                <Title3>{isPost ? '새 항목' : '수정'}</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvFaqItemDescription.envFaqId}
                    showSearch={faq.value === undefined}
                    onSearchClick={() => phase.setter(Phase.EnvFaqList)}>
                    <PostBigIntField
                        {...props}
                        disabled={faq.value !== undefined}
                        value={envFaqId.value}
                        setter={envFaqId.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvFaqItemDescription.title}>
                    <PostInputField
                        {...props}
                        value={title.value}
                        setter={title.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvFaqItemDescription.body}>
                    <PostTextareaField
                        {...props}
                        value={body.value}
                        setter={body.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvFaqItemDescription.displayOrder}>
                    <PostNumberField
                        {...props}
                        value={displayOrder.value}
                        setter={displayOrder.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </>}
        </div>
        <EnvFaqSearch
            {...props}
            phase={phase}
            envFaqId={envFaqId} />
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/faqs/items'}/>
    </>
}

type EnvFaqSearchProps = RouterProps & {
    phase: StatePair<Phase>
    envFaqId: StatePair<EnvFaqId>
}

function EnvFaqSearch(props: EnvFaqSearchProps) {
    const items = useStatePair<EnvFaqDomain[]>([])
    const option = useStatePair<EnvFaqSearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.EnvFaqList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>자주 묻는 질문 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={EnvFaqIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={EnvFaqDescription}
                    descriptor={EnvFaqDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.EnvFaqOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.EnvFaqOrder)}
                    onItemClick={item => {
                        props.envFaqId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.EnvFaqOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)} />
                    <Title3>자주 묻는 질문 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={EnvFaqSearchOptionDescription}
                    fields={EnvFaqSearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.EnvFaqList)}
                    onApplyClick={(key, value) => {
                        applyEnvFaqSearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.EnvFaqOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvFaqDescription}
                onBackClick={() => props.phase.setter(Phase.EnvFaqList)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}