import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvPhoneBlockValueObject} from "../../../data/value-object/env/EnvPhoneBlockValueObject";
import {JSX, useEffect} from "react";
import {EnvPhoneBlockIO, EnvPhoneBlockModificationIO} from "../../../io/EnvIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {EnvPhoneBlockDomain} from "../../../data/domain/env/EnvPhoneBlockDomain";
import {EnvPhoneBlockDescriptor} from "../../../data/descriptor/env/EnvPhoneBlockDescriptor";
import {EnvPhoneBlockDescription} from "../../../data/description/env/EnvPhoneBlockDescription";
import {EnvPhoneBlockModificationValueObject} from "../../../data/value-object/env/EnvPhoneBlockModificationValueObject";
import {
    EnvPhoneBlockModificationSearchOption
} from "../../../data/search-option/env/EnvPhoneBlockModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvPhoneBlockModificationDescription} from "../../../data/description/env/EnvPhoneBlockModificationDescription";
import {EnvPhoneBlockModificationDescriptor} from "../../../data/descriptor/env/EnvPhoneBlockModificationDescriptor";

enum Panel {
    Default,
    EnvPhoneBlockModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envPhoneBlockId: string }>()
    const item = useStatePair<EnvPhoneBlockValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envPhoneBlockId !== undefined) {
            EnvPhoneBlockIO
                .getById(BigInt(params.envPhoneBlockId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvPhoneBlockValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvPhoneBlockModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvPhoneBlockIO}
                description={EnvPhoneBlockDescription}
                properties={props => <PropertyBody {...props} />}/>
            <EnvPhoneBlockModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvPhoneBlockDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "phone":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{EnvPhoneBlockDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{EnvPhoneBlockDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envCountryId":
            return <a href={`/env/countries/${props.item.envCountryId}`}><Body1>{EnvPhoneBlockDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function EnvPhoneBlockModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvPhoneBlockModificationValueObject[]>([])
    const option: EnvPhoneBlockModificationSearchOption = { envPhoneBlockIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvPhoneBlockModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvPhoneBlockModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvPhoneBlockModificationDescription}
                descriptor={EnvPhoneBlockModificationDescriptor} />
        </>}
    </>
}