import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {AssetCouponUsageValueObject} from "../../../../data/value-object/asset/AssetCouponUsageValueObject";
import {useEffect} from "react";
import {AssetCouponUsageIO} from "../../../../io/AssetIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {AssetCouponUsageDescription} from "../../../../data/description/asset/AssetCouponUsageDescription";
import {AssetCouponUsageDomain} from "../../../../data/domain/asset/AssetCouponUsageDomain";
import {AssetCouponUsageDescriptor} from "../../../../data/descriptor/asset/AssetCouponUsageDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetCouponUsageId: string }>()
    const item = useStatePair<AssetCouponUsageValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetCouponUsageId !== undefined) {
            AssetCouponUsageIO
                .getById(BigInt(params.assetCouponUsageId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetCouponUsageValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetCouponUsageIO}
                description={AssetCouponUsageDescription}
                properties={props => <PropertyBody {...props} />} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetCouponUsageDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{AssetCouponUsageDescriptor[props.itemKey](props.item)}</Body1>
        case "assetCouponId":
            return <a href={`/assets/coupons/${props.item.assetCouponId}`}><Body1>{AssetCouponUsageDescriptor[props.itemKey](props.item)}</Body1></a>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{AssetCouponUsageDescriptor[props.itemKey](props.item)}</Body1></a>
        case "assetSolarUsageId":
            return props.item.assetSolarUsageId !== undefined
                ? <a href={`/assets/lunars/usages/${props.item.assetSolarUsageId}`}><Body1>{AssetCouponUsageDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
        case "assetLunarUsageId":
            return props.item.assetLunarUsageId !== undefined
                ? <a href={`/assets/solars/usages/${props.item.assetLunarUsageId}`}><Body1>{AssetCouponUsageDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
        case "assetStarUsageId":
            return props.item.assetStarUsageId !== undefined
                ? <a href={`/assets/stars/usages/${props.item.assetStarUsageId}`}><Body1>{AssetCouponUsageDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
    }
}