import {SearchProps} from "./SearchProps";
import {StatePair, useStatePair} from "react-type-extension";
import {BaseHttpCount, BaseHttpSearch, searchIndex} from "../../io/HttpClient";
import {Button, Title3} from "@fluentui/react-components";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import ListPageWrapper from "../ListPageWrapper";
import ListOption, {ListOptionFieldProps} from "../ListOption";
import ListOrder from "../ListOrder";
import React, {JSX} from "react";

export type SearchWrapperProps<E, VO, SO> = SearchProps<E, VO> & {
    title: string
    client: BaseHttpSearch<SO, VO, any> & BaseHttpCount<SO>
    voDescription: Record<keyof VO, string>
    soDescription: Record<keyof SO, string>
    descriptor: Record<keyof VO, (o: VO) => string>
    fields: (props: ListOptionFieldProps<SO>) => JSX.Element
    optionApplier: (key: keyof SO, value: string, into: StatePair<Partial<SO>>) => void
}

export function SearchWrapper<E, VO extends { id: bigint }, SO>(props: SearchWrapperProps<E, VO, SO>) {
    const items = useStatePair<VO[]>([])
    const option = useStatePair<Partial<SO>>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === props.phases.list && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(props.phases.waiting)}>
                    </Button>
                    <Title3>{props.title} 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={props.client}
                    items={items}
                    option={option.value}
                    index={index}
                    description={props.voDescription}
                    descriptor={props.descriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(props.phases.option)}
                    showSort
                    onSortClick={() => props.phase.setter(props.phases.order)}
                    onItemClick={item => {
                        props.setter(item)
                        props.phase.setter(props.phases.waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === props.phases.option && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(props.phases.waiting)} />
                    <Title3>{props.title} 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={props.soDescription}
                    fields={props.fields}
                    onBackClick={() => props.phase.setter(props.phases.list)}
                    onApplyClick={(key, value) => {
                        props.optionApplier(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === props.phases.order && <>
            <ListOrder
                {...props}
                index={index}
                description={props.voDescription}
                onBackClick={() => props.phase.setter(props.phases.list)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}