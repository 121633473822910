import {ContentCommentReportReasonModificationDomain} from "../../domain/content/ContentCommentReportReasonModificationDomain"
import {ContentCommentReportReasonId} from "../../id/content/ContentCommentReportReasonId"
import {ContentCommentReportReasonModificationId} from "../../id/content/ContentCommentReportReasonModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 댓글 신고 사유 수정 이력
 */
export class ContentCommentReportReasonModificationValueObject implements ValueObject, ContentCommentReportReasonModificationDomain {
    /**
     * 기본키
     */
    id: ContentCommentReportReasonModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 댓글 신고 사유 ID
     */
    contentCommentReportReasonId: ContentCommentReportReasonId
    /**
     * 내용
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentCommentReportReasonModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentCommentReportReasonId = BigInt(o.contentCommentReportReasonId)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
