import {EnvClientModificationDomain} from "../../domain/env/EnvClientModificationDomain"

/**
 * 클라이언트 수정 이력 속성 설명
 */
export const EnvClientModificationDescription: Record<keyof EnvClientModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envClientId: '클라이언트 ID',
    platform: '플랫폼 유형',
    distribution: '배포 유형',
    versionName: '이름',
    expiredAt: '만료일',
    createdAt: '수정일'
}
