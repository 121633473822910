export enum UserGender {
    /**
     * 남성
     */
    Male = 'Male',

    /**
     * 여성
     */
    Female = 'Female'
}

export namespace UserGender {
    export function description(o: UserGender) {
        switch (o) {
            case UserGender.Male: return '남성'
            case UserGender.Female: return '여성'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: UserGender.Male, label: description(UserGender.Male) },
        { value: UserGender.Female, label: description(UserGender.Female) }
    ]
}
