import {ContentCommentReportReasonPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/content/ContentCommentReportReasonPutDataTransferObjectDescription"
import {ContentCommentReportReasonPutState} from "../../../data/data-transfer-object-state/content/ContentCommentReportReasonPutState"
import {ContentCommentReportReasonPutDataTransferObject} from "../../../data/data-transfer-object/content/ContentCommentReportReasonPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: ContentCommentReportReasonPutState
}

export const ContentCommentReportReasonPutField: Record<Capitalize<keyof ContentCommentReportReasonPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Body(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentCommentReportReasonPutDataTransferObjectDescription.body}>
                <PostTextareaField
                    {...props}
                    value={props.state.body.value}
                    setter={props.state.body.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    DisplayOrder(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentCommentReportReasonPutDataTransferObjectDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={props.state.displayOrder.value}
                    setter={props.state.displayOrder.setter} />
            </PostFieldWrapper>
        )
    },
}

export function ContentCommentReportReasonPutFields(props: PostFieldProps) {
    return <>
        <ContentCommentReportReasonPutField.Body
            {...props}
            state={props.state} />
        <ContentCommentReportReasonPutField.DisplayOrder
            {...props}
            state={props.state} />
    </>
}
