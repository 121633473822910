import {UiHomeContentUserProductionDomain} from "../../domain/ui/UiHomeContentUserProductionDomain"

export const UiHomeContentUserProductionDescriptor: Record<keyof UiHomeContentUserProductionDomain, (o: UiHomeContentUserProductionDomain) => string> = {
    id(o: UiHomeContentUserProductionDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiHomeContentUserProductionDomain): string {
        return o.creatorId.toString()
    },
    displayOrder(o: UiHomeContentUserProductionDomain): string {
        return o.displayOrder.toString()
    },
    url(o: UiHomeContentUserProductionDomain): string {
        return o.url
    },
    createdAt(o: UiHomeContentUserProductionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UiHomeContentUserProductionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
