import {EnvBrowser} from "../../../data/constant/env/EnvBrowser"
import {ContentPolicyPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/content/ContentPolicyPutDataTransferObjectDescription"
import {ContentPolicyPutState} from "../../../data/data-transfer-object-state/content/ContentPolicyPutState"
import {ContentPolicyPutDataTransferObject} from "../../../data/data-transfer-object/content/ContentPolicyPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: ContentPolicyPutState
}

export const ContentPolicyPutField: Record<Capitalize<keyof ContentPolicyPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    ContentPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentPageSize.value}
                    setter={props.state.contentPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportAutoHiddenCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentReportAutoHiddenCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportAutoHiddenCount.value}
                    setter={props.state.contentReportAutoHiddenCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentPageSize.value}
                    setter={props.state.contentCommentPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentWithAudioSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentWithAudioSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentWithAudioSolarAmount.value}
                    setter={props.state.contentCommentWithAudioSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentReportAutoHiddenCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentReportAutoHiddenCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentReportAutoHiddenCount.value}
                    setter={props.state.contentCommentReportAutoHiddenCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentThresholdMinutes.value}
                    setter={props.state.contentCommentThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentThresholdCount.value}
                    setter={props.state.contentCommentThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentLikePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentLikePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentLikePageSize.value}
                    setter={props.state.contentCommentLikePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentReportPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentReportPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentReportPageSize.value}
                    setter={props.state.contentCommentReportPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentReportReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentCommentReportReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentReportReasonPageSize.value}
                    setter={props.state.contentCommentReportReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentParticipationPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentParticipationPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentParticipationPageSize.value}
                    setter={props.state.contentParticipationPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDailyParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentDailyParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDailyParticipationExp.value}
                    setter={props.state.contentDailyParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDailyParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentDailyParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDailyParticipationPassExp.value}
                    setter={props.state.contentDailyParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentRepeatParticipationThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationThresholdMinutes.value}
                    setter={props.state.contentRepeatParticipationThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentRepeatParticipationThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationThresholdCount.value}
                    setter={props.state.contentRepeatParticipationThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentRepeatParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationExp.value}
                    setter={props.state.contentRepeatParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentRepeatParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationPassExp.value}
                    setter={props.state.contentRepeatParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentM10nParticipationThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationThresholdMinutes.value}
                    setter={props.state.contentM10nParticipationThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentM10nParticipationThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationThresholdCount.value}
                    setter={props.state.contentM10nParticipationThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentM10nParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationExp.value}
                    setter={props.state.contentM10nParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentM10nParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationPassExp.value}
                    setter={props.state.contentM10nParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionParticipationThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationThresholdMinutes.value}
                    setter={props.state.contentUserProductionParticipationThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionParticipationThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationThresholdCount.value}
                    setter={props.state.contentUserProductionParticipationThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationExp.value}
                    setter={props.state.contentUserProductionParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationPassExp.value}
                    setter={props.state.contentUserProductionParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPresetPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionPresetPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPresetPageSize.value}
                    setter={props.state.contentUserProductionPresetPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionMinimumAssetLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionMinimumAssetLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionMinimumAssetLunarAmount.value}
                    setter={props.state.contentUserProductionMinimumAssetLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionMinimumUserLevelId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionMinimumUserLevelId}>
                <PostBigIntField
                    {...props}
                    value={props.state.contentUserProductionMinimumUserLevelId.value}
                    setter={props.state.contentUserProductionMinimumUserLevelId.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionMinimumAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionMinimumAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionMinimumAccuracy.value}
                    setter={props.state.contentUserProductionMinimumAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPassAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionPassAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPassAccuracy.value}
                    setter={props.state.contentUserProductionPassAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPassHighAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionPassHighAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPassHighAccuracy.value}
                    setter={props.state.contentUserProductionPassHighAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPassSolarAmountHighAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionPassSolarAmountHighAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPassSolarAmountHighAccuracy.value}
                    setter={props.state.contentUserProductionPassSolarAmountHighAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionStarAmount.value}
                    setter={props.state.contentUserProductionStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultDetailUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionDefaultDetailUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentUserProductionDefaultDetailUrl.value}
                    setter={props.state.contentUserProductionDefaultDetailUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultDetailUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionDefaultDetailUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentUserProductionDefaultDetailUrlBrowser.value}
                    setter={props.state.contentUserProductionDefaultDetailUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultResultUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionDefaultResultUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentUserProductionDefaultResultUrl.value}
                    setter={props.state.contentUserProductionDefaultResultUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultResultUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionDefaultResultUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentUserProductionDefaultResultUrlBrowser.value}
                    setter={props.state.contentUserProductionDefaultResultUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionScriptPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentUserProductionScriptPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionScriptPageSize.value}
                    setter={props.state.contentUserProductionScriptPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentLikePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentLikePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentLikePageSize.value}
                    setter={props.state.contentLikePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentPolicyPageSize.value}
                    setter={props.state.contentPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentQueryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentQueryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentQueryPageSize.value}
                    setter={props.state.contentQueryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentQueryPopularDurationMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentQueryPopularDurationMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentQueryPopularDurationMinutes.value}
                    setter={props.state.contentQueryPopularDurationMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentQueryPopularPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentQueryPopularPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentQueryPopularPageSize.value}
                    setter={props.state.contentQueryPopularPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentReportReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportReasonPageSize.value}
                    setter={props.state.contentReportReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentReportPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportPageSize.value}
                    setter={props.state.contentReportPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportDailyThreshold(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentReportDailyThreshold}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportDailyThreshold.value}
                    setter={props.state.contentReportDailyThreshold.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentDetailUrlPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDetailUrlPageSize.value}
                    setter={props.state.contentDetailUrlPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlClickPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentDetailUrlClickPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDetailUrlClickPageSize.value}
                    setter={props.state.contentDetailUrlClickPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlClickExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentDetailUrlClickExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDetailUrlClickExp.value}
                    setter={props.state.contentDetailUrlClickExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentResultUrlPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentResultUrlPageSize.value}
                    setter={props.state.contentResultUrlPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlClickPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentResultUrlClickPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentResultUrlClickPageSize.value}
                    setter={props.state.contentResultUrlClickPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlClickExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentResultUrlClickExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentResultUrlClickExp.value}
                    setter={props.state.contentResultUrlClickExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReviewPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentReviewPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReviewPageSize.value}
                    setter={props.state.contentReviewPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentTargetPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPutDataTransferObjectDescription.contentTargetPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentTargetPageSize.value}
                    setter={props.state.contentTargetPageSize.setter} />
            </PostFieldWrapper>
        )
    },
}

export function ContentPolicyPutFields(props: PostFieldProps) {
    return <>
        <ContentPolicyPutField.ContentPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentReportAutoHiddenCount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentWithAudioSolarAmount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentReportAutoHiddenCount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentLikePageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentReportPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentCommentReportReasonPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentParticipationPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentDailyParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentDailyParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentRepeatParticipationThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentRepeatParticipationThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentRepeatParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentRepeatParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentM10nParticipationThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentM10nParticipationThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentM10nParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentM10nParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionParticipationThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionParticipationThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionPresetPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionMinimumAssetLunarAmount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionMinimumUserLevelId
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionMinimumAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionPassAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionPassHighAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionPassSolarAmountHighAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionStarAmount
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionDefaultDetailUrl
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionDefaultDetailUrlBrowser
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionDefaultResultUrl
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionDefaultResultUrlBrowser
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentUserProductionScriptPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentLikePageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentPolicyPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentQueryPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentQueryPopularDurationMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentQueryPopularPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentReportReasonPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentReportPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentReportDailyThreshold
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentDetailUrlPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentDetailUrlClickPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentDetailUrlClickExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentResultUrlPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentResultUrlClickPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentResultUrlClickExp
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentReviewPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPutField.ContentTargetPageSize
            {...props}
            state={props.state} />
    </>
}
