import {EnvNoticeSearchOption} from "../../search-option/env/EnvNoticeSearchOption"

/**
 * 공지사항 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvNoticeSearchOptionDescription: Record<keyof EnvNoticeSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    titleEq: '제목 일치',
    titleNeq: '제목 불일치',
    titleMin: '제목 최소',
    titleMax: '제목 최대',
    titleLike: '제목 포함',
    titleNotLike: '제목 제외',
    urlEq: 'URL 일치',
    urlNeq: 'URL 불일치',
    urlMin: 'URL 최소',
    urlMax: 'URL 최대',
    urlLike: 'URL 포함',
    urlNotLike: 'URL 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
