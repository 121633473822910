import {ContentUserProductionRewardModificationDomain} from "../../domain/content/ContentUserProductionRewardModificationDomain"

export const ContentUserProductionRewardModificationDescriptor: Record<keyof ContentUserProductionRewardModificationDomain, (o: ContentUserProductionRewardModificationDomain) => string> = {
    id(o: ContentUserProductionRewardModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentUserProductionRewardModificationDomain): string {
        return o.creatorId.toString()
    },
    contentUserProductionRewardId(o: ContentUserProductionRewardModificationDomain): string {
        return o.contentUserProductionRewardId.toString()
    },
    value(o: ContentUserProductionRewardModificationDomain): string {
        return o.value.toString()
    },
    displayOrder(o: ContentUserProductionRewardModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: ContentUserProductionRewardModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
