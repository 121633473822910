import {UserGender} from "../../constant/user/UserGender"
import {ContentTargetModificationVerboseDomain} from "../../domain/content/ContentTargetModificationVerboseDomain"
import {ContentTargetId} from "../../id/content/ContentTargetId"
import {ContentTargetModificationId} from "../../id/content/ContentTargetModificationId"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {UserId} from "../../id/user/UserId"
import {EnvCountryValueObject} from "../env/EnvCountryValueObject"
import {UserValueObject} from "../user/UserValueObject"
import {ContentTargetValueObject} from "./ContentTargetValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 노출 대상 수정 이력 상세
 */
export class ContentTargetModificationVerboseValueObject implements VerboseValueObject, ContentTargetModificationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentTargetModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 콘텐츠 노출 대상 ID
     */
    contentTargetId: ContentTargetId
    /**
     * 국가 ID
     */
    envCountryId?: EnvCountryId
    /**
     * 성별
     */
    userGender?: UserGender
    /**
     * 출생년도 최소
     */
    userBirthYearMin?: number
    /**
     * 출생년도 최대
     */
    userBirthYearMax?: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 콘텐츠 노출 대상 ID
     */
    contentTarget: ContentTargetValueObject
    /**
     * 국가 ID
     */
    envCountry?: EnvCountryValueObject

    constructor(o: Record<keyof ContentTargetModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentTargetId = BigInt(o.contentTargetId)
        if (o.envCountryId !== undefined && o.envCountryId !== null) {
            this.envCountryId = BigInt(o.envCountryId)
        }
        if (o.userGender !== undefined && o.userGender !== null) {
            this.userGender = o.userGender as UserGender
        }
        if (o.userBirthYearMin !== undefined && o.userBirthYearMin !== null) {
            this.userBirthYearMin = Number(o.userBirthYearMin)
        }
        if (o.userBirthYearMax !== undefined && o.userBirthYearMax !== null) {
            this.userBirthYearMax = Number(o.userBirthYearMax)
        }
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.contentTarget = new ContentTargetValueObject(o.contentTarget)
        if (o.envCountry !== undefined && o.envCountry !== null) {
            this.envCountry = new EnvCountryValueObject(o.envCountry)
        }
    }
}
