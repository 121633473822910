import {EnvNoticeModificationVerboseDomain} from "../../domain/env/EnvNoticeModificationVerboseDomain"
import {EnvNoticeId} from "../../id/env/EnvNoticeId"
import {EnvNoticeModificationId} from "../../id/env/EnvNoticeModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvNoticeValueObject} from "./EnvNoticeValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 공지사항 수정 이력 상세
 */
export class EnvNoticeModificationVerboseValueObject implements VerboseValueObject, EnvNoticeModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvNoticeModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 공지사항 ID
     */
    envNoticeId: EnvNoticeId
    /**
     * 제목
     */
    title: string
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 공지사항 ID
     */
    envNotice: EnvNoticeValueObject

    constructor(o: Record<keyof EnvNoticeModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envNoticeId = BigInt(o.envNoticeId)
        this.title = String(o.title)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envNotice = new EnvNoticeValueObject(o.envNotice)
    }
}
