import {UserNotificationStatus} from "../../constant/user/UserNotificationStatus"
import {UserNotificationSubject} from "../../constant/user/UserNotificationSubject"
import {UserNotificationDomain} from "../../domain/user/UserNotificationDomain"

export const UserNotificationDescriptor: Record<keyof UserNotificationDomain, (o: UserNotificationDomain) => string> = {
    id(o: UserNotificationDomain): string {
        return o.id.toString()
    },
    userId(o: UserNotificationDomain): string {
        return o.userId.toString()
    },
    creatorId(o: UserNotificationDomain): string {
        return o.creatorId.toString()
    },
    title(o: UserNotificationDomain): string {
        return o.title
    },
    body(o: UserNotificationDomain): string {
        return o.body
    },
    subject(o: UserNotificationDomain): string {
        return UserNotificationSubject.description(o.subject)
    },
    deepLink(o: UserNotificationDomain): string {
        return o.deepLink ?? '-'
    },
    statusFlags(o: UserNotificationDomain): string {
        let enabled: string[] = []
        for (let key in UserNotificationStatus) {
            const flag = (UserNotificationStatus as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.statusFlags & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    createdAt(o: UserNotificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserNotificationDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
