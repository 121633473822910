import {EnvAgreementType} from "../../constant/env/EnvAgreementType"
import {EnvAgreementDomain} from "../../domain/env/EnvAgreementDomain"
import {EnvAgreementId} from "../../id/env/EnvAgreementId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 이용 약관
 */
export class EnvAgreementValueObject implements ValueObject, EnvAgreementDomain {
    /**
     * 기본키
     */
    id: EnvAgreementId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 유형
     */
    type: EnvAgreementType
    /**
     * 내용
     */
    body: string
    /**
     * 약관에 동의한 사용자 수
     */
    userAgreementCount: number
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvAgreementDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.type = o.type as EnvAgreementType
        this.body = String(o.body)
        this.userAgreementCount = Number(o.userAgreementCount)
        this.createdAt = new Date(o.createdAt)
    }
}
