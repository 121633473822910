import {EnvPhoneBlockDomain} from "../../domain/env/EnvPhoneBlockDomain"

/**
 * 전화번호 차단 속성 설명
 */
export const EnvPhoneBlockDescription: Record<keyof EnvPhoneBlockDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    envCountryId: '국가 ID',
    phone: '전화번호',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
