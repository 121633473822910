import {ContentDomain} from "../../domain/content/ContentDomain"

/**
 * 참여 가능 항목 속성 설명
 */
export const ContentDescription: Record<keyof ContentDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    advertiserId: '광고주 ID',
    type: '유형',
    participationMethod: '참여 방법',
    title: '제목',
    titleHighlight: '제목 강조',
    body: '내용',
    script: '스크립트',
    scriptVisibility: '스크립트 표시 유형',
    contentDetailUrlId: '콘텐츠 더 알아보기 URL ID',
    contentResultUrlId: '콘텐츠 참여 결과 URL ID',
    gradingMessage: '채점 중 텍스트',
    amount: '보상 지급 수량',
    passAccuracy: '합격 기준(%)',
    passAccuracyHigh: '높은 일치율 기준(%)',
    passSolarAmount: '합격 지급 솔라',
    passSolarAmountHighAccuracy: '높은 일치율 지급 솔라',
    starAmount: '소모 스타',
    difficulty: '난이도',
    headerImageCount: '상단 이미지 수',
    descriptionImageCount: '설명 이미지 수',
    exposedAt: '노출 시작일',
    startAt: '참여 시작일',
    endAt: '참여 종료일',
    statusFlags: '상태 플래그',
    contentTargetCount: '대상 수',
    participationCount: '참여 수',
    participationPassCount: '합격 수',
    participationHighAccuracyCount: '높은 일치율 수',
    commentCount: '댓글 수',
    reportCount: '(와글와글) 신고 수',
    reviewPositiveCount: '(와글와글) 긍정 평가 수',
    reviewNeutralCount: '(와글와글) 중립 평가 수',
    reviewNegativeCount: '(와글와글) 부정 평가 수',
    likeCount: '좋아요 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
