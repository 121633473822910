import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {ContentPolicyIO} from "../../../io/ContentIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFinishDialog, PostNumberField} from "../../PostField";
import {ContentPolicyDescription} from "../../../data/description/content/ContentPolicyDescription";
import {useContentPolicyPostState} from "../../../data/data-transfer-object-state/content/ContentPolicyPostState";
import {ContentPolicyPostField, ContentPolicyPostFields} from "../../data-transfer-object-field/content/ContentPolicyPostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useContentPolicyPostState()

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        ContentPolicyIO
            .post(state.toDataTransferObject())
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <ContentPolicyPostFields
                    {...props}
                    state={state} />
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/contents/policies'}/>
    </>
}