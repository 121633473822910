import {EnvNoticeModificationDomain} from "../../domain/env/EnvNoticeModificationDomain"

export const EnvNoticeModificationDescriptor: Record<keyof EnvNoticeModificationDomain, (o: EnvNoticeModificationDomain) => string> = {
    id(o: EnvNoticeModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvNoticeModificationDomain): string {
        return o.creatorId.toString()
    },
    envNoticeId(o: EnvNoticeModificationDomain): string {
        return o.envNoticeId.toString()
    },
    title(o: EnvNoticeModificationDomain): string {
        return o.title
    },
    url(o: EnvNoticeModificationDomain): string {
        return o.url
    },
    createdAt(o: EnvNoticeModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
