import {EnvClientSearchOption} from "../../search-option/env/EnvClientSearchOption"

/**
 * 클라이언트 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvClientSearchOptionDescription: Record<keyof EnvClientSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    platformEq: '플랫폼 유형 일치',
    platformNeq: '플랫폼 유형 불일치',
    platformMin: '플랫폼 유형 최소',
    platformMax: '플랫폼 유형 최대',
    platformIn: '플랫폼 유형 포함',
    distributionEq: '배포 유형 일치',
    distributionNeq: '배포 유형 불일치',
    distributionMin: '배포 유형 최소',
    distributionMax: '배포 유형 최대',
    distributionIn: '배포 유형 포함',
    versionNameEq: '이름 일치',
    versionNameNeq: '이름 불일치',
    versionNameMin: '이름 최소',
    versionNameMax: '이름 최대',
    versionNameLike: '이름 포함',
    versionNameNotLike: '이름 제외',
    expiredAtEq: '만료일 일치',
    expiredAtNeq: '만료일 불일치',
    expiredAtMin: '만료일 최소',
    expiredAtMax: '만료일 최대',
    expiredAtIsNull: '만료일 값 존재 여부',
    userSignInCountEq: '로그인한 사용자 수 일치',
    userSignInCountNeq: '로그인한 사용자 수 불일치',
    userSignInCountMin: '로그인한 사용자 수 최소',
    userSignInCountMax: '로그인한 사용자 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
