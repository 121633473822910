import {ContentUserProductionScriptVerboseDomain} from "../../domain/content/ContentUserProductionScriptVerboseDomain"
import {ContentUserProductionScriptId} from "../../id/content/ContentUserProductionScriptId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 와글와글 스크립트 상세
 */
export class ContentUserProductionScriptVerboseValueObject implements VerboseValueObject, ContentUserProductionScriptVerboseDomain {
    /**
     * 기본키
     */
    id: ContentUserProductionScriptId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 오디오 ID
     */
    audioId?: bigint
    /**
     * 본문
     */
    script: string
    /**
     * 인식된 텍스트
     */
    transcription: string
    /**
     * 일치율(%)
     */
    accuracy: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof ContentUserProductionScriptVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        if (o.audioId !== undefined && o.audioId !== null) {
            this.audioId = BigInt(o.audioId)
        }
        this.script = String(o.script)
        this.transcription = String(o.transcription)
        this.accuracy = Number(o.accuracy)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
    }
}
