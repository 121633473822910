import {AssetCouponUsageVerboseDomain} from "../../domain/asset/AssetCouponUsageVerboseDomain"
import {AssetCouponId} from "../../id/asset/AssetCouponId"
import {AssetCouponUsageId} from "../../id/asset/AssetCouponUsageId"
import {AssetLunarUsageId} from "../../id/asset/AssetLunarUsageId"
import {AssetSolarUsageId} from "../../id/asset/AssetSolarUsageId"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {AssetCouponValueObject} from "./AssetCouponValueObject"
import {AssetLunarUsageValueObject} from "./AssetLunarUsageValueObject"
import {AssetSolarUsageValueObject} from "./AssetSolarUsageValueObject"
import {AssetStarUsageValueObject} from "./AssetStarUsageValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 쿠폰 사용 상세
 */
export class AssetCouponUsageVerboseValueObject implements VerboseValueObject, AssetCouponUsageVerboseDomain {
    /**
     * 기본키
     */
    id: AssetCouponUsageId
    /**
     * 쿠폰 ID
     */
    assetCouponId: AssetCouponId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 솔라 지급 ID
     */
    assetSolarUsageId?: AssetSolarUsageId
    /**
     * 루나 지급 ID
     */
    assetLunarUsageId?: AssetLunarUsageId
    /**
     * 스타 지급 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 쿠폰 ID
     */
    assetCoupon: AssetCouponValueObject
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 솔라 지급 ID
     */
    assetSolarUsage?: AssetSolarUsageValueObject
    /**
     * 루나 지급 ID
     */
    assetLunarUsage?: AssetLunarUsageValueObject
    /**
     * 스타 지급 ID
     */
    assetStarUsage?: AssetStarUsageValueObject

    constructor(o: Record<keyof AssetCouponUsageVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.assetCouponId = BigInt(o.assetCouponId)
        this.userId = BigInt(o.userId)
        if (o.assetSolarUsageId !== undefined && o.assetSolarUsageId !== null) {
            this.assetSolarUsageId = BigInt(o.assetSolarUsageId)
        }
        if (o.assetLunarUsageId !== undefined && o.assetLunarUsageId !== null) {
            this.assetLunarUsageId = BigInt(o.assetLunarUsageId)
        }
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        this.createdAt = new Date(o.createdAt)
        this.assetCoupon = new AssetCouponValueObject(o.assetCoupon)
        this.user = new UserValueObject(o.user)
        if (o.assetSolarUsage !== undefined && o.assetSolarUsage !== null) {
            this.assetSolarUsage = new AssetSolarUsageValueObject(o.assetSolarUsage)
        }
        if (o.assetLunarUsage !== undefined && o.assetLunarUsage !== null) {
            this.assetLunarUsage = new AssetLunarUsageValueObject(o.assetLunarUsage)
        }
        if (o.assetStarUsage !== undefined && o.assetStarUsage !== null) {
            this.assetStarUsage = new AssetStarUsageValueObject(o.assetStarUsage)
        }
    }
}
