import {UiHomeContentSuggestionDomain} from "../../domain/ui/UiHomeContentSuggestionDomain"
import {UiHomeContentSuggestionId} from "../../id/ui/UiHomeContentSuggestionId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 홈 추천 콘텐츠
 */
export class UiHomeContentSuggestionValueObject implements ValueObject, UiHomeContentSuggestionDomain {
    /**
     * 기본키
     */
    id: UiHomeContentSuggestionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * URL
     */
    url: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date

    constructor(o: Record<keyof UiHomeContentSuggestionDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.displayOrder = Number(o.displayOrder)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
    }
}
