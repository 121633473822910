import {ContentResultUrlClickDomain} from "../../domain/content/ContentResultUrlClickDomain"

/**
 * 콘텐츠 참여 결과 URL 클릭 속성 설명
 */
export const ContentResultUrlClickDescription: Record<keyof ContentResultUrlClickDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    contentResultUrlId: '콘텐츠 참여 결과 URL ID',
    assetStarUsageId: '스타 지급 ID',
    userExpId: '사용자 경험치 지급 ID',
    createdAt: '생성일'
}
