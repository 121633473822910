import {UserInquiryDomain} from "../../domain/user/UserInquiryDomain"

export const UserInquiryDescriptor: Record<keyof UserInquiryDomain, (o: UserInquiryDomain) => string> = {
    id(o: UserInquiryDomain): string {
        return o.id.toString()
    },
    userId(o: UserInquiryDomain): string {
        return o.userId?.toString() ?? '-'
    },
    envClientId(o: UserInquiryDomain): string {
        return o.envClientId.toString()
    },
    envCountryId(o: UserInquiryDomain): string {
        return o.envCountryId.toString()
    },
    email(o: UserInquiryDomain): string {
        return o.email
    },
    remoteAddress(o: UserInquiryDomain): string {
        return o.remoteAddress
    },
    deviceName(o: UserInquiryDomain): string {
        return o.deviceName
    },
    deviceVersion(o: UserInquiryDomain): string {
        return o.deviceVersion
    },
    body(o: UserInquiryDomain): string {
        return o.body
    },
    createdAt(o: UserInquiryDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
