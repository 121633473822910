import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryDomain} from "../../domain/content/ContentQueryDomain"
import {ContentQueryId} from "../../id/content/ContentQueryId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 검색
 */
export class ContentQueryValueObject implements ValueObject, ContentQueryDomain {
    /**
     * 기본키
     */
    id: ContentQueryId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 유형
     */
    contentType: ContentType
    /**
     * 질의어
     */
    body: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof ContentQueryDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentType = o.contentType as ContentType
        this.body = String(o.body)
        this.createdAt = new Date(o.createdAt)
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
