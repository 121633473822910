import Detail from "./Detail";
import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Post from "./Post";
import Edit from "./Edit";

export default function PhoneBlockRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/post'} element={<Post {...props} />} />
            <Route path={'/:envPhoneBlockId'} element={<Detail {...props} />} />
            <Route path={'/:envPhoneBlockId/edit'} element={<Edit {...props} />} />
        </Routes>
    </>
}