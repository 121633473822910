import {AssetPolicyPutDataTransferObject} from "../../data-transfer-object/asset/AssetPolicyPutDataTransferObject"
import {AssetPolicyValueObject} from "../../value-object/asset/AssetPolicyValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetPolicyPutState = {
    assetCouponPageSize: StatePair<number>
    assetCouponUsagePageSize: StatePair<number>
    assetLunarUsagePageSize: StatePair<number>
    assetLunarExchangeOptionPageSize: StatePair<number>
    assetLunarExchangeExp: StatePair<number>
    assetLunarPurchaseOptionPageSize: StatePair<number>
    assetLunarPurchaseExp: StatePair<number>
    assetPolicyPageSize: StatePair<number>
    assetSolarUsagePageSize: StatePair<number>
    assetSolarWithdrawalPageSize: StatePair<number>
    assetSolarWithdrawalOptionPageSize: StatePair<number>
    assetSolarWithdrawalExp: StatePair<number>
    assetStarChargePageSize: StatePair<number>
    assetStarUsagePageSize: StatePair<number>
    assetStarUsageExp: StatePair<number>
    assetStarExchangeOptionPageSize: StatePair<number>
    assetStarExchangeExp: StatePair<number>
    assetStarChargeMinutes: StatePair<number>
    assetStarChargeMax: StatePair<number>
    toDataTransferObject(): AssetPolicyPutDataTransferObject
    fromValueObject(vo: AssetPolicyValueObject): void
    values(): [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number]
}

export function useAssetPolicyPutState(): AssetPolicyPutState {
    return {
        assetCouponPageSize: useStatePair<number>(0),
        assetCouponUsagePageSize: useStatePair<number>(0),
        assetLunarUsagePageSize: useStatePair<number>(0),
        assetLunarExchangeOptionPageSize: useStatePair<number>(0),
        assetLunarExchangeExp: useStatePair<number>(0),
        assetLunarPurchaseOptionPageSize: useStatePair<number>(0),
        assetLunarPurchaseExp: useStatePair<number>(0),
        assetPolicyPageSize: useStatePair<number>(0),
        assetSolarUsagePageSize: useStatePair<number>(0),
        assetSolarWithdrawalPageSize: useStatePair<number>(0),
        assetSolarWithdrawalOptionPageSize: useStatePair<number>(0),
        assetSolarWithdrawalExp: useStatePair<number>(0),
        assetStarChargePageSize: useStatePair<number>(0),
        assetStarUsagePageSize: useStatePair<number>(0),
        assetStarUsageExp: useStatePair<number>(0),
        assetStarExchangeOptionPageSize: useStatePair<number>(0),
        assetStarExchangeExp: useStatePair<number>(0),
        assetStarChargeMinutes: useStatePair<number>(0),
        assetStarChargeMax: useStatePair<number>(0),
        toDataTransferObject(): AssetPolicyPutDataTransferObject {
            return {
                assetCouponPageSize: this.assetCouponPageSize.value,
                assetCouponUsagePageSize: this.assetCouponUsagePageSize.value,
                assetLunarUsagePageSize: this.assetLunarUsagePageSize.value,
                assetLunarExchangeOptionPageSize: this.assetLunarExchangeOptionPageSize.value,
                assetLunarExchangeExp: this.assetLunarExchangeExp.value,
                assetLunarPurchaseOptionPageSize: this.assetLunarPurchaseOptionPageSize.value,
                assetLunarPurchaseExp: this.assetLunarPurchaseExp.value,
                assetPolicyPageSize: this.assetPolicyPageSize.value,
                assetSolarUsagePageSize: this.assetSolarUsagePageSize.value,
                assetSolarWithdrawalPageSize: this.assetSolarWithdrawalPageSize.value,
                assetSolarWithdrawalOptionPageSize: this.assetSolarWithdrawalOptionPageSize.value,
                assetSolarWithdrawalExp: this.assetSolarWithdrawalExp.value,
                assetStarChargePageSize: this.assetStarChargePageSize.value,
                assetStarUsagePageSize: this.assetStarUsagePageSize.value,
                assetStarUsageExp: this.assetStarUsageExp.value,
                assetStarExchangeOptionPageSize: this.assetStarExchangeOptionPageSize.value,
                assetStarExchangeExp: this.assetStarExchangeExp.value,
                assetStarChargeMinutes: this.assetStarChargeMinutes.value,
                assetStarChargeMax: this.assetStarChargeMax.value,
            }
        },
        fromValueObject(vo: AssetPolicyValueObject) {
            this.assetCouponPageSize.setter(vo.assetCouponPageSize)
            this.assetCouponUsagePageSize.setter(vo.assetCouponUsagePageSize)
            this.assetLunarUsagePageSize.setter(vo.assetLunarUsagePageSize)
            this.assetLunarExchangeOptionPageSize.setter(vo.assetLunarExchangeOptionPageSize)
            this.assetLunarExchangeExp.setter(vo.assetLunarExchangeExp)
            this.assetLunarPurchaseOptionPageSize.setter(vo.assetLunarPurchaseOptionPageSize)
            this.assetLunarPurchaseExp.setter(vo.assetLunarPurchaseExp)
            this.assetPolicyPageSize.setter(vo.assetPolicyPageSize)
            this.assetSolarUsagePageSize.setter(vo.assetSolarUsagePageSize)
            this.assetSolarWithdrawalPageSize.setter(vo.assetSolarWithdrawalPageSize)
            this.assetSolarWithdrawalOptionPageSize.setter(vo.assetSolarWithdrawalOptionPageSize)
            this.assetSolarWithdrawalExp.setter(vo.assetSolarWithdrawalExp)
            this.assetStarChargePageSize.setter(vo.assetStarChargePageSize)
            this.assetStarUsagePageSize.setter(vo.assetStarUsagePageSize)
            this.assetStarUsageExp.setter(vo.assetStarUsageExp)
            this.assetStarExchangeOptionPageSize.setter(vo.assetStarExchangeOptionPageSize)
            this.assetStarExchangeExp.setter(vo.assetStarExchangeExp)
            this.assetStarChargeMinutes.setter(vo.assetStarChargeMinutes)
            this.assetStarChargeMax.setter(vo.assetStarChargeMax)
        },
        values(): [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number] {
            return [
                this.assetCouponPageSize.value,
                this.assetCouponUsagePageSize.value,
                this.assetLunarUsagePageSize.value,
                this.assetLunarExchangeOptionPageSize.value,
                this.assetLunarExchangeExp.value,
                this.assetLunarPurchaseOptionPageSize.value,
                this.assetLunarPurchaseExp.value,
                this.assetPolicyPageSize.value,
                this.assetSolarUsagePageSize.value,
                this.assetSolarWithdrawalPageSize.value,
                this.assetSolarWithdrawalOptionPageSize.value,
                this.assetSolarWithdrawalExp.value,
                this.assetStarChargePageSize.value,
                this.assetStarUsagePageSize.value,
                this.assetStarUsageExp.value,
                this.assetStarExchangeOptionPageSize.value,
                this.assetStarExchangeExp.value,
                this.assetStarChargeMinutes.value,
                this.assetStarChargeMax.value,
            ]
        }
    }
}
