import {ContentParticipationVerboseDomain} from "../../domain/content/ContentParticipationVerboseDomain"
import {AssetSolarUsageId} from "../../id/asset/AssetSolarUsageId"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {ContentId} from "../../id/content/ContentId"
import {ContentParticipationId} from "../../id/content/ContentParticipationId"
import {UserId} from "../../id/user/UserId"
import {AssetSolarUsageValueObject} from "../asset/AssetSolarUsageValueObject"
import {AssetStarUsageValueObject} from "../asset/AssetStarUsageValueObject"
import {UserValueObject} from "../user/UserValueObject"
import {ContentValueObject} from "./ContentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 참여 상세
 */
export class ContentParticipationVerboseValueObject implements VerboseValueObject, ContentParticipationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentParticipationId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 솔라 지급 ID
     */
    assetSolarUsageId?: AssetSolarUsageId
    /**
     * 스타 사용 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 오디오 ID
     */
    audioId?: bigint
    /**
     * 인식된 텍스트
     */
    transcription: string
    /**
     * 일치율(%)
     */
    accuracy: number
    /**
     * 합격 여부
     */
    isPassed: boolean
    /**
     * 높은 일치율 여부
     */
    isHighAccuracy: boolean
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 콘텐츠 ID
     */
    content: ContentValueObject
    /**
     * 솔라 지급 ID
     */
    assetSolarUsage?: AssetSolarUsageValueObject
    /**
     * 스타 사용 ID
     */
    assetStarUsage?: AssetStarUsageValueObject

    constructor(o: Record<keyof ContentParticipationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentId = BigInt(o.contentId)
        if (o.assetSolarUsageId !== undefined && o.assetSolarUsageId !== null) {
            this.assetSolarUsageId = BigInt(o.assetSolarUsageId)
        }
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        if (o.audioId !== undefined && o.audioId !== null) {
            this.audioId = BigInt(o.audioId)
        }
        this.transcription = String(o.transcription)
        this.accuracy = Number(o.accuracy)
        this.isPassed = Boolean(o.isPassed)
        this.isHighAccuracy = Boolean(o.isHighAccuracy)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.content = new ContentValueObject(o.content)
        if (o.assetSolarUsage !== undefined && o.assetSolarUsage !== null) {
            this.assetSolarUsage = new AssetSolarUsageValueObject(o.assetSolarUsage)
        }
        if (o.assetStarUsage !== undefined && o.assetStarUsage !== null) {
            this.assetStarUsage = new AssetStarUsageValueObject(o.assetStarUsage)
        }
    }
}
