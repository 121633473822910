import {AssetStarExchangeOptionPostDataTransferObject} from "../../data-transfer-object/asset/AssetStarExchangeOptionPostDataTransferObject"
import {AssetStarExchangeOptionValueObject} from "../../value-object/asset/AssetStarExchangeOptionValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetStarExchangeOptionPostState = {
    starAmount: StatePair<number>
    solarAmount: StatePair<number>
    toDataTransferObject(): AssetStarExchangeOptionPostDataTransferObject
    fromValueObject(vo: AssetStarExchangeOptionValueObject): void
    values(): [number, number]
}

export function useAssetStarExchangeOptionPostState(): AssetStarExchangeOptionPostState {
    return {
        starAmount: useStatePair<number>(0),
        solarAmount: useStatePair<number>(0),
        toDataTransferObject(): AssetStarExchangeOptionPostDataTransferObject {
            return {
                starAmount: this.starAmount.value,
                solarAmount: this.solarAmount.value,
            }
        },
        fromValueObject(vo: AssetStarExchangeOptionValueObject) {
            this.starAmount.setter(vo.starAmount)
            this.solarAmount.setter(vo.solarAmount)
        },
        values(): [number, number] {
            return [
                this.starAmount.value,
                this.solarAmount.value,
            ]
        }
    }
}
