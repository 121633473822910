import {AssetPolicyPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetPolicyPostDataTransferObjectDescription"
import {AssetPolicyPostState} from "../../../data/data-transfer-object-state/asset/AssetPolicyPostState"
import {AssetPolicyPostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetPolicyPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetPolicyPostState
}

export const AssetPolicyPostField: Record<Capitalize<keyof AssetPolicyPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    AssetCouponPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetCouponPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetCouponPageSize.value}
                    setter={props.state.assetCouponPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetCouponUsagePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetCouponUsagePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetCouponUsagePageSize.value}
                    setter={props.state.assetCouponUsagePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarUsagePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetLunarUsagePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarUsagePageSize.value}
                    setter={props.state.assetLunarUsagePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarExchangeOptionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetLunarExchangeOptionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarExchangeOptionPageSize.value}
                    setter={props.state.assetLunarExchangeOptionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarExchangeExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetLunarExchangeExp}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarExchangeExp.value}
                    setter={props.state.assetLunarExchangeExp.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarPurchaseOptionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetLunarPurchaseOptionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarPurchaseOptionPageSize.value}
                    setter={props.state.assetLunarPurchaseOptionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarPurchaseExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetLunarPurchaseExp}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarPurchaseExp.value}
                    setter={props.state.assetLunarPurchaseExp.setter} />
            </PostFieldWrapper>
        )
    },
    AssetPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetPolicyPageSize.value}
                    setter={props.state.assetPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetSolarUsagePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetSolarUsagePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetSolarUsagePageSize.value}
                    setter={props.state.assetSolarUsagePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetSolarWithdrawalPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetSolarWithdrawalPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetSolarWithdrawalPageSize.value}
                    setter={props.state.assetSolarWithdrawalPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetSolarWithdrawalOptionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetSolarWithdrawalOptionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetSolarWithdrawalOptionPageSize.value}
                    setter={props.state.assetSolarWithdrawalOptionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetSolarWithdrawalExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetSolarWithdrawalExp}>
                <PostNumberField
                    {...props}
                    value={props.state.assetSolarWithdrawalExp.value}
                    setter={props.state.assetSolarWithdrawalExp.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarChargePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarChargePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarChargePageSize.value}
                    setter={props.state.assetStarChargePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarUsagePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarUsagePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarUsagePageSize.value}
                    setter={props.state.assetStarUsagePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarUsageExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarUsageExp}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarUsageExp.value}
                    setter={props.state.assetStarUsageExp.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarExchangeOptionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarExchangeOptionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarExchangeOptionPageSize.value}
                    setter={props.state.assetStarExchangeOptionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarExchangeExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarExchangeExp}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarExchangeExp.value}
                    setter={props.state.assetStarExchangeExp.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarChargeMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarChargeMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarChargeMinutes.value}
                    setter={props.state.assetStarChargeMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarChargeMax(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetPolicyPostDataTransferObjectDescription.assetStarChargeMax}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarChargeMax.value}
                    setter={props.state.assetStarChargeMax.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetPolicyPostFields(props: PostFieldProps) {
    return <>
        <AssetPolicyPostField.AssetCouponPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetCouponUsagePageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetLunarUsagePageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetLunarExchangeOptionPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetLunarExchangeExp
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetLunarPurchaseOptionPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetLunarPurchaseExp
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetPolicyPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetSolarUsagePageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetSolarWithdrawalPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetSolarWithdrawalOptionPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetSolarWithdrawalExp
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarChargePageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarUsagePageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarUsageExp
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarExchangeOptionPageSize
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarExchangeExp
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarChargeMinutes
            {...props}
            state={props.state} />
        <AssetPolicyPostField.AssetStarChargeMax
            {...props}
            state={props.state} />
    </>
}
