import {UserWithdrawalDomain} from "../../domain/user/UserWithdrawalDomain"

export const UserWithdrawalDescriptor: Record<keyof UserWithdrawalDomain, (o: UserWithdrawalDomain) => string> = {
    id(o: UserWithdrawalDomain): string {
        return o.id.toString()
    },
    userId(o: UserWithdrawalDomain): string {
        return o.userId.toString()
    },
    userWithdrawalReasonId(o: UserWithdrawalDomain): string {
        return o.userWithdrawalReasonId.toString()
    },
    createdAt(o: UserWithdrawalDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
