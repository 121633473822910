import { RouterProps } from "../../../RouterProps"
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    UserWithdrawalReasonVerboseValueObject
} from "../../../../data/value-object/user/UserWithdrawalReasonVerboseValueObject";
import {useCallback, useEffect} from "react";
import {UserWithdrawalReasonIO} from "../../../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostInputField, PostNumberField} from "../../../PostField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userWithdrawalReasonId: string }>()
    const item = useStatePair<UserWithdrawalReasonVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const body = useStatePair<string>('')
    const displayOrder = useStatePair<number>(0)

    useEffect(() => {
        if (item.value === undefined && params.userWithdrawalReasonId !== undefined) {
            UserWithdrawalReasonIO
                .getVerboseById(BigInt(params.userWithdrawalReasonId))
                .then(value => {
                    item.setter(value)
                    body.setter(value.body)
                    displayOrder.setter(value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UserWithdrawalReasonIO
            .put(id, {
                body: body.value,
                displayOrder: displayOrder.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/withdrawals/reasons/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [body.value, displayOrder.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={'사유'}>
                <PostInputField
                    {...props}
                    value={body.value}
                    setter={body.setter}/>
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={'표시 우선 순위'}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter}/>
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}