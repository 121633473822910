import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    UiHomeContentSuggestionValueObject
} from "../../../../../data/value-object/ui/UiHomeContentSuggestionValueObject";
import {
    UiHomeContentSuggestionSearchOption
} from "../../../../../data/search-option/ui/UiHomeContentSuggestionSearchOption";
import {searchIndex, SearchIndex} from "../../../../../io/HttpClient";
import ListPageWrapper from "../../../../ListPageWrapper";
import {UiHomeContentSuggestionIO} from "../../../../../io/UiIO";
import {
    UiHomeContentSuggestionDescription
} from "../../../../../data/description/ui/UiHomeContentSuggestionDescription";
import {
    UiHomeContentSuggestionDescriptor
} from "../../../../../data/descriptor/ui/UiHomeContentSuggestionDescriptor";
import ListOption from "../../../../ListOption";
import {
    UiHomeContentSuggestionSearchOptionDescription
} from "../../../../../data/description/ui/UiHomeContentSuggestionSearchOptionDescription";
import {
    applyUiHomeContentSuggestionSearchOption,
    UiHomeContentSuggestionSearchOptionField
} from "../../../../search-option-field/ui/UiHomeContentSuggestionSearchOptionField";
import ListOrder from "../../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UiHomeContentSuggestionValueObject[]>([])
    const option = useStatePair<UiHomeContentSuggestionSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UiHomeContentSuggestionIO}
                items={items}
                option={option.value}
                index={index}
                description={UiHomeContentSuggestionDescription}
                descriptor={UiHomeContentSuggestionDescriptor}
                showPost
                onPostClick={() => document.location = '/ui/home/contents/suggestions/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/ui/home/contents/suggestions/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UiHomeContentSuggestionSearchOptionDescription}
                fields={UiHomeContentSuggestionSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUiHomeContentSuggestionSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UiHomeContentSuggestionDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}