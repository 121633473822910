import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {RouterProps} from "../../RouterProps";
import {EnvBankIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFilesField, PostFinishDialog} from "../../PostField";
import {MediaFile} from "../../Media";
import {StorageIO, StorageKey} from "../../../io/StorageIO";
import {useEnvBankPostState} from "../../../data/data-transfer-object-state/env/EnvBankPostState";
import {EnvBankPostFields} from "../../data-transfer-object-field/env/EnvBankPostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useEnvBankPostState()
    const icon = useStatePair<MediaFile[]>([])

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        post()
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [...state.values(), icon.value])

    const post = async () => {
        const response = await EnvBankIO.post(state.toDataTransferObject())
        await StorageIO.putFile(StorageKey.Env.bank(response.id), icon.value[0])
    }

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <EnvBankPostFields
                    {...props}
                    state={state} />
                <PostFieldWrapper
                    {...props}
                    title={'아이콘'}>
                    <PostFilesField
                        {...props}
                        value={icon.value}
                        setter={icon.setter}
                        accept={'image/*'} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/banks'}/>
    </>
}