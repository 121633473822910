import {ContentUserProductionRewardModificationVerboseDomain} from "../../domain/content/ContentUserProductionRewardModificationVerboseDomain"
import {ContentUserProductionRewardId} from "../../id/content/ContentUserProductionRewardId"
import {ContentUserProductionRewardModificationId} from "../../id/content/ContentUserProductionRewardModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentUserProductionRewardValueObject} from "./ContentUserProductionRewardValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 와글와글 보상 프리셋 수정 이력 상세
 */
export class ContentUserProductionRewardModificationVerboseValueObject implements VerboseValueObject, ContentUserProductionRewardModificationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentUserProductionRewardModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 와글와글 보상 프리셋 ID
     */
    contentUserProductionRewardId: ContentUserProductionRewardId
    /**
     * 지급 솔라
     */
    value: number
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 와글와글 보상 프리셋 ID
     */
    contentUserProductionReward: ContentUserProductionRewardValueObject

    constructor(o: Record<keyof ContentUserProductionRewardModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentUserProductionRewardId = BigInt(o.contentUserProductionRewardId)
        this.value = Number(o.value)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.contentUserProductionReward = new ContentUserProductionRewardValueObject(o.contentUserProductionReward)
    }
}
