import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentReportReasonValueObject
} from "../../../../data/value-object/content/ContentReportReasonValueObject";
import {useEffect} from "react";
import {
    ContentReportIO,
    ContentReportReasonIO,
    ContentReportReasonModificationIO
} from "../../../../io/ContentIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../../DetailViews";
import {ContentReportReasonDomain} from "../../../../data/domain/content/ContentReportReasonDomain";
import {ContentReportReasonDescriptor} from "../../../../data/descriptor/content/ContentReportReasonDescriptor";
import {ContentReportReasonDescription} from "../../../../data/description/content/ContentReportReasonDescription";
import {
    ContentReportReasonModificationValueObject
} from "../../../../data/value-object/content/ContentReportReasonModificationValueObject";
import {
    ContentReportReasonModificationSearchOption
} from "../../../../data/search-option/content/ContentReportReasonModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {
    ContentReportReasonModificationDescription
} from "../../../../data/description/content/ContentReportReasonModificationDescription";
import {
    ContentReportReasonModificationDescriptor
} from "../../../../data/descriptor/content/ContentReportReasonModificationDescriptor";
import {ContentReportValueObject} from "../../../../data/value-object/content/ContentReportValueObject";
import {ContentReportSearchOption} from "../../../../data/search-option/content/ContentReportSearchOption";
import {ContentReportDescription} from "../../../../data/description/content/ContentReportDescription";
import {ContentReportDescriptor} from "../../../../data/descriptor/content/ContentReportDescriptor";

enum Panel {
    Default,
    ContentReportReasonModificationList,
    ContentReportList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentReportReasonId: string }>()
    const item = useStatePair<ContentReportReasonValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentReportReasonId !== undefined) {
            ContentReportReasonIO
                .getById(BigInt(params.contentReportReasonId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentReportReasonValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.ContentReportReasonModificationList}>수정 이력</Tab>
                <Tab value={Panel.ContentReportList}>신고 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                client={ContentReportReasonIO}
                description={ContentReportReasonDescription}
                properties={props => <PropertyBody {...props} />}/>
            <ContentReportReasonModificationListPanel
                {...props}
                panel={panel.value}/>
            <ContentReportListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentReportReasonDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "body":
        case "displayOrder":
        case "reportCount":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{ContentReportReasonDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentReportReasonDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function ContentReportReasonModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentReportReasonModificationValueObject[]>([])
    const modificationSearchOption: ContentReportReasonModificationSearchOption = { contentReportReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentReportReasonModificationList && <>
            <ListPageWrapper
                {...props}
                client={ContentReportReasonModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentReportReasonModificationDescription}
                descriptor={ContentReportReasonModificationDescriptor}
                showNewTab={false}
                onItemClick={item => `/contents/reports/reasons/${item.id}`} />
        </>}
    </>
}

function ContentReportListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentReportValueObject[]>([])
    const modificationSearchOption: ContentReportSearchOption = { contentReportReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentReportList && <>
            <ListPageWrapper
                {...props}
                client={ContentReportIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentReportDescription}
                descriptor={ContentReportDescriptor}
                onItemClick={item => `/contents/reports/${item.id}`} />
        </>}
    </>
}