import {SortOrder} from "ts-protocol-extension";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserSignInValueObject} from "../../../data/value-object/user/UserSignInValueObject";
import {UserSignInSearchOption} from "../../../data/search-option/user/UserSignInSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {UserSignInIO} from "../../../io/UserIO";
import {UserSignInDescription} from "../../../data/description/user/UserSignInDescription";
import {UserSignInDescriptor} from "../../../data/descriptor/user/UserSignInDescriptor";
import ListOption from "../../ListOption";
import {
    UserSignInSearchOptionDescription
} from "../../../data/description/user/UserSignInSearchOptionDescription";
import {
    applyUserSignInSearchOption,
    UserSignInSearchOptionField
} from "../../search-option-field/user/UserSignInSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserSignInValueObject[]>([])
    const option = useStatePair<UserSignInSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserSignInIO}
                items={items}
                option={option.value}
                index={index}
                description={UserSignInDescription}
                descriptor={UserSignInDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/signIns/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserSignInSearchOptionDescription}
                fields={UserSignInSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserSignInSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserSignInDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}