import {EnvAgreementDomain} from "../../domain/env/EnvAgreementDomain"

/**
 * 이용 약관 속성 설명
 */
export const EnvAgreementDescription: Record<keyof EnvAgreementDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    type: '유형',
    body: '내용',
    userAgreementCount: '약관에 동의한 사용자 수',
    createdAt: '생성일'
}
