import {Mutex} from "async-mutex";

type Uninitialized = 'uninitialized-lazy-value'
const uninitialized: Uninitialized = 'uninitialized-lazy-value'

export class Lazy<T> {
    private readonly lock: Mutex
    private readonly init: () => Promise<T>
    private value: T | Uninitialized

    constructor(init: () => Promise<T>) {
        this.lock = new Mutex()
        this.value = uninitialized
        this.init = init
    }

    async get() {
        if (this.value !== uninitialized) {
            return this.value
        }

        await this.lock.acquire()
        if (this.value !== uninitialized) {
            this.lock.release()
            return this.value
        }

        try {
            this.value = await this.init()
        } catch (e) {
            this.lock.release()
            throw e
        }

        this.lock.release()

        return this.value
    }

    getOrUndefined() {
        if (this.value === uninitialized) {
            return undefined
        } else {
            return this.value
        }
    }
}