import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserRankValueObject} from "../../../data/value-object/user/UserRankValueObject";
import {useCallback, useEffect} from "react";
import {UserRankIO} from "../../../io/UserIO";
import {Button, Spinner, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFileField, PostInputField, PostNumberField} from "../../PostField";
import {UserRankDescription} from "../../../data/description/user/UserRankDescription";
import {MediaFile} from "../../Media";
import {StorageIO, StorageKey} from "../../../io/StorageIO";
import {UserRankId} from "../../../data/id/user/UserRankId";
import {createMediaFileFromStorageObject} from "../../../util/FileUtils";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userRankId: string }>()
    const item = useStatePair<UserRankValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const name = useStatePair('')
    const percentile = useStatePair<number | undefined>(undefined)
    const fixed = useStatePair<number | undefined>(undefined)
    const applyOrder = useStatePair<number>(0)
    const rewardAssetLunarAmount = useStatePair(0)
    const rewardAssetSolarAmount = useStatePair(0)
    const rewardAssetStarAmount = useStatePair(0)
    const icon = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.userRankId !== undefined) {
            const id = BigInt(params.userRankId)
            UserRankIO
                .getById(id)
                .then(value => {
                    item.setter(value)
                    name.setter(value.name)
                    percentile.setter(value.percentile)
                    fixed.setter(value.fixed)
                    applyOrder.setter(value.applyOrder)
                    rewardAssetLunarAmount.setter(value.rewardAssetLunarAmount)
                    rewardAssetSolarAmount.setter(value.rewardAssetSolarAmount)
                    rewardAssetStarAmount.setter(value.rewardAssetStarAmount)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })

            StorageIO
                .getObjectByteArray(StorageKey.User.rank(id))
                .then(value => icon.setter(createMediaFileFromStorageObject(value)))
                .catch(reason => {
                    console.error(reason)
                    alert('이미지를 불러올 수 없습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        const iconFile = icon.value
        if (iconFile === undefined) {
            alert('아이콘을 선택해주세요.')
            return
        }

        phase.setter(Phase.Putting)
        put(id, iconFile)
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/ranks/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [name.value, percentile.value, fixed.value, applyOrder.value, rewardAssetLunarAmount.value, rewardAssetSolarAmount.value, rewardAssetStarAmount.value, icon.value])


    const put = async (id: UserRankId, iconFile: MediaFile) => {
        await UserRankIO.put(id, {
            name: name.value,
            percentile: percentile.value,
            fixed: fixed.value,
            applyOrder: applyOrder.value,
            rewardAssetLunarAmount: rewardAssetLunarAmount.value,
            rewardAssetSolarAmount: rewardAssetSolarAmount.value,
            rewardAssetStarAmount: rewardAssetStarAmount.value
        })
        await StorageIO.putFile(StorageKey.User.rank(id), iconFile)
    }

    return <>
        {phase.value === Phase.Loading && <Spinner />}
        {(phase.value === Phase.Waiting || phase.value === Phase.Putting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.name}>
                    <PostInputField
                        {...props}
                        value={name.value}
                        setter={name.setter}
                        maxLength={20}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.percentile}>
                    <PostNumberField
                        {...props}
                        value={percentile.value}
                        setter={percentile.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.fixed}>
                    <PostNumberField
                        {...props}
                        value={fixed.value}
                        setter={fixed.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.applyOrder}>
                    <PostNumberField
                        {...props}
                        value={applyOrder.value}
                        setter={applyOrder.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.rewardAssetLunarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetLunarAmount.value}
                        setter={rewardAssetLunarAmount.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.rewardAssetSolarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetSolarAmount.value}
                        setter={rewardAssetSolarAmount.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.rewardAssetStarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetStarAmount.value}
                        setter={rewardAssetStarAmount.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'아이콘'}>
                    <PostFileField
                        {...props}
                        value={icon.value}
                        setter={icon.setter}
                        accept={'image/*'} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
    </>
}