import {AssetLunarExchangeOptionModificationDomain} from "../../domain/asset/AssetLunarExchangeOptionModificationDomain"
import {AssetLunarExchangeOptionId} from "../../id/asset/AssetLunarExchangeOptionId"
import {AssetLunarExchangeOptionModificationId} from "../../id/asset/AssetLunarExchangeOptionModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 루나 교환 옵션 수정 이력
 */
export class AssetLunarExchangeOptionModificationValueObject implements ValueObject, AssetLunarExchangeOptionModificationDomain {
    /**
     * 기본키
     */
    id: AssetLunarExchangeOptionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 루나 교환 옵션 ID
     */
    assetLunarExchangeOptionId: AssetLunarExchangeOptionId
    /**
     * 지급 루나
     */
    lunarAmount: number
    /**
     * 소모 솔라
     */
    solarAmount: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetLunarExchangeOptionModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetLunarExchangeOptionId = BigInt(o.assetLunarExchangeOptionId)
        this.lunarAmount = Number(o.lunarAmount)
        this.solarAmount = Number(o.solarAmount)
        this.createdAt = new Date(o.createdAt)
    }
}
