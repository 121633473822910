import {AssetSolarWithdrawalOptionPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetSolarWithdrawalOptionPutDataTransferObjectDescription"
import {AssetSolarWithdrawalOptionPutState} from "../../../data/data-transfer-object-state/asset/AssetSolarWithdrawalOptionPutState"
import {AssetSolarWithdrawalOptionPutDataTransferObject} from "../../../data/data-transfer-object/asset/AssetSolarWithdrawalOptionPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetSolarWithdrawalOptionPutState
}

export const AssetSolarWithdrawalOptionPutField: Record<Capitalize<keyof AssetSolarWithdrawalOptionPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    SolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarWithdrawalOptionPutDataTransferObjectDescription.solarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.solarAmount.value}
                    setter={props.state.solarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    CashAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarWithdrawalOptionPutDataTransferObjectDescription.cashAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.cashAmount.value}
                    setter={props.state.cashAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetSolarWithdrawalOptionPutFields(props: PostFieldProps) {
    return <>
        <AssetSolarWithdrawalOptionPutField.SolarAmount
            {...props}
            state={props.state} />
        <AssetSolarWithdrawalOptionPutField.CashAmount
            {...props}
            state={props.state} />
    </>
}
