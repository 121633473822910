export enum AssetStarUsageType {
    /**
     * 관리자 지급
     */
    Admin = 'Admin',

    /**
     * 콘텐츠 참여
     */
    ContentParticipation = 'ContentParticipation',

    /**
     * 솔라를 사용하여 스타 교환
     */
    Exchange = 'Exchange',

    /**
     * 회원가입
     */
    SignUp = 'SignUp',

    /**
     * 추천인
     */
    UserRecommender = 'UserRecommender',

    /**
     * 피추천인
     */
    UserRecommendee = 'UserRecommendee',

    /**
     * 레벨업 보상
     */
    UserLevelReward = 'UserLevelReward',

    /**
     * 랭크 보상
     */
    UserRankReward = 'UserRankReward',

    /**
     * 콘텐츠 참여 결과 URL 클릭
     */
    ContentResultUrlClick = 'ContentResultUrlClick',

    /**
     * 스케줄러에 의한 충전
     */
    SchedulerCharge = 'SchedulerCharge',

    /**
     * 쿠폰
     */
    Coupon = 'Coupon'
}

export namespace AssetStarUsageType {
    export function description(o: AssetStarUsageType) {
        switch (o) {
            case AssetStarUsageType.Admin: return '관리자 지급'
            case AssetStarUsageType.ContentParticipation: return '콘텐츠 참여'
            case AssetStarUsageType.Exchange: return '솔라를 사용하여 스타 교환'
            case AssetStarUsageType.SignUp: return '회원가입'
            case AssetStarUsageType.UserRecommender: return '추천인'
            case AssetStarUsageType.UserRecommendee: return '피추천인'
            case AssetStarUsageType.UserLevelReward: return '레벨업 보상'
            case AssetStarUsageType.UserRankReward: return '랭크 보상'
            case AssetStarUsageType.ContentResultUrlClick: return '콘텐츠 참여 결과 URL 클릭'
            case AssetStarUsageType.SchedulerCharge: return '스케줄러에 의한 충전'
            case AssetStarUsageType.Coupon: return '쿠폰'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: AssetStarUsageType.Admin, label: description(AssetStarUsageType.Admin) },
        { value: AssetStarUsageType.ContentParticipation, label: description(AssetStarUsageType.ContentParticipation) },
        { value: AssetStarUsageType.Exchange, label: description(AssetStarUsageType.Exchange) },
        { value: AssetStarUsageType.SignUp, label: description(AssetStarUsageType.SignUp) },
        { value: AssetStarUsageType.UserRecommender, label: description(AssetStarUsageType.UserRecommender) },
        { value: AssetStarUsageType.UserRecommendee, label: description(AssetStarUsageType.UserRecommendee) },
        { value: AssetStarUsageType.UserLevelReward, label: description(AssetStarUsageType.UserLevelReward) },
        { value: AssetStarUsageType.UserRankReward, label: description(AssetStarUsageType.UserRankReward) },
        { value: AssetStarUsageType.ContentResultUrlClick, label: description(AssetStarUsageType.ContentResultUrlClick) },
        { value: AssetStarUsageType.SchedulerCharge, label: description(AssetStarUsageType.SchedulerCharge) },
        { value: AssetStarUsageType.Coupon, label: description(AssetStarUsageType.Coupon) }
    ]
}
