import {ContentTargetDomain} from "../../domain/content/ContentTargetDomain"

/**
 * 콘텐츠 노출 대상 속성 설명
 */
export const ContentTargetDescription: Record<keyof ContentTargetDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    contentId: '콘텐츠 ID',
    envCountryId: '국가 ID',
    userGender: '성별',
    userBirthYearMin: '출생년도 최소',
    userBirthYearMax: '출생년도 최대',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
