import {RouterProps} from "../RouterProps";
import {Route, Routes} from "react-router-dom";
import LunarRouter from "./lunar/LunarRouter";
import PolicyRouter from "./policy/PolicyRouter";
import SolarRouter from "./solar/SolarRouter";
import StarRouter from "./star/StarRouter";
import {Button} from "@fluentui/react-components";
import {ChevronRight24Filled} from "@fluentui/react-icons";
import CouponRouter from "./coupon/CouponRouter";

export default function AssetRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/coupons/*'} element={<CouponRouter {...props} />} />
            <Route path={'/lunars/*'} element={<LunarRouter {...props} />} />
            <Route path={'/policies/*'} element={<PolicyRouter {...props} />} />
            <Route path={'/solars/*'} element={<SolarRouter {...props} />} />
            <Route path={'/stars/*'} element={<StarRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/coupons'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                쿠폰
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/lunars/exchangeOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                루나 교환 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/lunars/purchaseOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                루나 구매 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/lunars/usages'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                루나 사용
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/policies'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                재화 정책
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/solars/usages'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                솔라 사용
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/solars/withdrawalOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                솔라 인출 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/stars/exchangeOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                스타 교환 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/stars/usages'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                스타 사용
            </Button>
        </div>
    </>
}