import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentPolicyPutDataTransferObject} from "../../data-transfer-object/content/ContentPolicyPutDataTransferObject"
import {ContentPolicyValueObject} from "../../value-object/content/ContentPolicyValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type ContentPolicyPutState = {
    contentPageSize: StatePair<number>
    contentReportAutoHiddenCount: StatePair<number>
    contentCommentPageSize: StatePair<number>
    contentCommentWithAudioSolarAmount: StatePair<number>
    contentCommentReportAutoHiddenCount: StatePair<number>
    contentCommentThresholdMinutes: StatePair<number>
    contentCommentThresholdCount: StatePair<number>
    contentCommentLikePageSize: StatePair<number>
    contentCommentReportPageSize: StatePair<number>
    contentCommentReportReasonPageSize: StatePair<number>
    contentParticipationPageSize: StatePair<number>
    contentDailyParticipationExp: StatePair<number>
    contentDailyParticipationPassExp: StatePair<number>
    contentRepeatParticipationThresholdMinutes: StatePair<number>
    contentRepeatParticipationThresholdCount: StatePair<number>
    contentRepeatParticipationExp: StatePair<number>
    contentRepeatParticipationPassExp: StatePair<number>
    contentM10nParticipationThresholdMinutes: StatePair<number>
    contentM10nParticipationThresholdCount: StatePair<number>
    contentM10nParticipationExp: StatePair<number>
    contentM10nParticipationPassExp: StatePair<number>
    contentUserProductionParticipationThresholdMinutes: StatePair<number>
    contentUserProductionParticipationThresholdCount: StatePair<number>
    contentUserProductionParticipationExp: StatePair<number>
    contentUserProductionParticipationPassExp: StatePair<number>
    contentUserProductionPresetPageSize: StatePair<number>
    contentUserProductionMinimumAssetLunarAmount: StatePair<number>
    contentUserProductionMinimumUserLevelId: StatePair<bigint>
    contentUserProductionMinimumAccuracy: StatePair<number>
    contentUserProductionPassAccuracy: StatePair<number>
    contentUserProductionPassHighAccuracy: StatePair<number>
    contentUserProductionPassSolarAmountHighAccuracy: StatePair<number>
    contentUserProductionStarAmount: StatePair<number>
    contentUserProductionDefaultDetailUrl: StatePair<string>
    contentUserProductionDefaultDetailUrlBrowser: StatePair<EnvBrowser>
    contentUserProductionDefaultResultUrl: StatePair<string>
    contentUserProductionDefaultResultUrlBrowser: StatePair<EnvBrowser>
    contentUserProductionScriptPageSize: StatePair<number>
    contentLikePageSize: StatePair<number>
    contentPolicyPageSize: StatePair<number>
    contentQueryPageSize: StatePair<number>
    contentQueryPopularDurationMinutes: StatePair<number>
    contentQueryPopularPageSize: StatePair<number>
    contentReportReasonPageSize: StatePair<number>
    contentReportPageSize: StatePair<number>
    contentReportDailyThreshold: StatePair<number>
    contentDetailUrlPageSize: StatePair<number>
    contentDetailUrlClickPageSize: StatePair<number>
    contentDetailUrlClickExp: StatePair<number>
    contentResultUrlPageSize: StatePair<number>
    contentResultUrlClickPageSize: StatePair<number>
    contentResultUrlClickExp: StatePair<number>
    contentReviewPageSize: StatePair<number>
    contentTargetPageSize: StatePair<number>
    toDataTransferObject(): ContentPolicyPutDataTransferObject
    fromValueObject(vo: ContentPolicyValueObject): void
    values(): [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, bigint, number, number, number, number, number, string, EnvBrowser, string, EnvBrowser, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number]
}

export function useContentPolicyPutState(): ContentPolicyPutState {
    return {
        contentPageSize: useStatePair<number>(0),
        contentReportAutoHiddenCount: useStatePair<number>(0),
        contentCommentPageSize: useStatePair<number>(0),
        contentCommentWithAudioSolarAmount: useStatePair<number>(0),
        contentCommentReportAutoHiddenCount: useStatePair<number>(0),
        contentCommentThresholdMinutes: useStatePair<number>(0),
        contentCommentThresholdCount: useStatePair<number>(0),
        contentCommentLikePageSize: useStatePair<number>(0),
        contentCommentReportPageSize: useStatePair<number>(0),
        contentCommentReportReasonPageSize: useStatePair<number>(0),
        contentParticipationPageSize: useStatePair<number>(0),
        contentDailyParticipationExp: useStatePair<number>(0),
        contentDailyParticipationPassExp: useStatePair<number>(0),
        contentRepeatParticipationThresholdMinutes: useStatePair<number>(0),
        contentRepeatParticipationThresholdCount: useStatePair<number>(0),
        contentRepeatParticipationExp: useStatePair<number>(0),
        contentRepeatParticipationPassExp: useStatePair<number>(0),
        contentM10nParticipationThresholdMinutes: useStatePair<number>(0),
        contentM10nParticipationThresholdCount: useStatePair<number>(0),
        contentM10nParticipationExp: useStatePair<number>(0),
        contentM10nParticipationPassExp: useStatePair<number>(0),
        contentUserProductionParticipationThresholdMinutes: useStatePair<number>(0),
        contentUserProductionParticipationThresholdCount: useStatePair<number>(0),
        contentUserProductionParticipationExp: useStatePair<number>(0),
        contentUserProductionParticipationPassExp: useStatePair<number>(0),
        contentUserProductionPresetPageSize: useStatePair<number>(0),
        contentUserProductionMinimumAssetLunarAmount: useStatePair<number>(0),
        contentUserProductionMinimumUserLevelId: useStatePair<bigint>(0n),
        contentUserProductionMinimumAccuracy: useStatePair<number>(0),
        contentUserProductionPassAccuracy: useStatePair<number>(0),
        contentUserProductionPassHighAccuracy: useStatePair<number>(0),
        contentUserProductionPassSolarAmountHighAccuracy: useStatePair<number>(0),
        contentUserProductionStarAmount: useStatePair<number>(0),
        contentUserProductionDefaultDetailUrl: useStatePair<string>(''),
        contentUserProductionDefaultDetailUrlBrowser: useStatePair<EnvBrowser>(EnvBrowser.DefaultBrowser),
        contentUserProductionDefaultResultUrl: useStatePair<string>(''),
        contentUserProductionDefaultResultUrlBrowser: useStatePair<EnvBrowser>(EnvBrowser.DefaultBrowser),
        contentUserProductionScriptPageSize: useStatePair<number>(0),
        contentLikePageSize: useStatePair<number>(0),
        contentPolicyPageSize: useStatePair<number>(0),
        contentQueryPageSize: useStatePair<number>(0),
        contentQueryPopularDurationMinutes: useStatePair<number>(0),
        contentQueryPopularPageSize: useStatePair<number>(0),
        contentReportReasonPageSize: useStatePair<number>(0),
        contentReportPageSize: useStatePair<number>(0),
        contentReportDailyThreshold: useStatePair<number>(0),
        contentDetailUrlPageSize: useStatePair<number>(0),
        contentDetailUrlClickPageSize: useStatePair<number>(0),
        contentDetailUrlClickExp: useStatePair<number>(0),
        contentResultUrlPageSize: useStatePair<number>(0),
        contentResultUrlClickPageSize: useStatePair<number>(0),
        contentResultUrlClickExp: useStatePair<number>(0),
        contentReviewPageSize: useStatePair<number>(0),
        contentTargetPageSize: useStatePair<number>(0),
        toDataTransferObject(): ContentPolicyPutDataTransferObject {
            return {
                contentPageSize: this.contentPageSize.value,
                contentReportAutoHiddenCount: this.contentReportAutoHiddenCount.value,
                contentCommentPageSize: this.contentCommentPageSize.value,
                contentCommentWithAudioSolarAmount: this.contentCommentWithAudioSolarAmount.value,
                contentCommentReportAutoHiddenCount: this.contentCommentReportAutoHiddenCount.value,
                contentCommentThresholdMinutes: this.contentCommentThresholdMinutes.value,
                contentCommentThresholdCount: this.contentCommentThresholdCount.value,
                contentCommentLikePageSize: this.contentCommentLikePageSize.value,
                contentCommentReportPageSize: this.contentCommentReportPageSize.value,
                contentCommentReportReasonPageSize: this.contentCommentReportReasonPageSize.value,
                contentParticipationPageSize: this.contentParticipationPageSize.value,
                contentDailyParticipationExp: this.contentDailyParticipationExp.value,
                contentDailyParticipationPassExp: this.contentDailyParticipationPassExp.value,
                contentRepeatParticipationThresholdMinutes: this.contentRepeatParticipationThresholdMinutes.value,
                contentRepeatParticipationThresholdCount: this.contentRepeatParticipationThresholdCount.value,
                contentRepeatParticipationExp: this.contentRepeatParticipationExp.value,
                contentRepeatParticipationPassExp: this.contentRepeatParticipationPassExp.value,
                contentM10nParticipationThresholdMinutes: this.contentM10nParticipationThresholdMinutes.value,
                contentM10nParticipationThresholdCount: this.contentM10nParticipationThresholdCount.value,
                contentM10nParticipationExp: this.contentM10nParticipationExp.value,
                contentM10nParticipationPassExp: this.contentM10nParticipationPassExp.value,
                contentUserProductionParticipationThresholdMinutes: this.contentUserProductionParticipationThresholdMinutes.value,
                contentUserProductionParticipationThresholdCount: this.contentUserProductionParticipationThresholdCount.value,
                contentUserProductionParticipationExp: this.contentUserProductionParticipationExp.value,
                contentUserProductionParticipationPassExp: this.contentUserProductionParticipationPassExp.value,
                contentUserProductionPresetPageSize: this.contentUserProductionPresetPageSize.value,
                contentUserProductionMinimumAssetLunarAmount: this.contentUserProductionMinimumAssetLunarAmount.value,
                contentUserProductionMinimumUserLevelId: this.contentUserProductionMinimumUserLevelId.value,
                contentUserProductionMinimumAccuracy: this.contentUserProductionMinimumAccuracy.value,
                contentUserProductionPassAccuracy: this.contentUserProductionPassAccuracy.value,
                contentUserProductionPassHighAccuracy: this.contentUserProductionPassHighAccuracy.value,
                contentUserProductionPassSolarAmountHighAccuracy: this.contentUserProductionPassSolarAmountHighAccuracy.value,
                contentUserProductionStarAmount: this.contentUserProductionStarAmount.value,
                contentUserProductionDefaultDetailUrl: this.contentUserProductionDefaultDetailUrl.value,
                contentUserProductionDefaultDetailUrlBrowser: this.contentUserProductionDefaultDetailUrlBrowser.value,
                contentUserProductionDefaultResultUrl: this.contentUserProductionDefaultResultUrl.value,
                contentUserProductionDefaultResultUrlBrowser: this.contentUserProductionDefaultResultUrlBrowser.value,
                contentUserProductionScriptPageSize: this.contentUserProductionScriptPageSize.value,
                contentLikePageSize: this.contentLikePageSize.value,
                contentPolicyPageSize: this.contentPolicyPageSize.value,
                contentQueryPageSize: this.contentQueryPageSize.value,
                contentQueryPopularDurationMinutes: this.contentQueryPopularDurationMinutes.value,
                contentQueryPopularPageSize: this.contentQueryPopularPageSize.value,
                contentReportReasonPageSize: this.contentReportReasonPageSize.value,
                contentReportPageSize: this.contentReportPageSize.value,
                contentReportDailyThreshold: this.contentReportDailyThreshold.value,
                contentDetailUrlPageSize: this.contentDetailUrlPageSize.value,
                contentDetailUrlClickPageSize: this.contentDetailUrlClickPageSize.value,
                contentDetailUrlClickExp: this.contentDetailUrlClickExp.value,
                contentResultUrlPageSize: this.contentResultUrlPageSize.value,
                contentResultUrlClickPageSize: this.contentResultUrlClickPageSize.value,
                contentResultUrlClickExp: this.contentResultUrlClickExp.value,
                contentReviewPageSize: this.contentReviewPageSize.value,
                contentTargetPageSize: this.contentTargetPageSize.value,
            }
        },
        fromValueObject(vo: ContentPolicyValueObject) {
            this.contentPageSize.setter(vo.contentPageSize)
            this.contentReportAutoHiddenCount.setter(vo.contentReportAutoHiddenCount)
            this.contentCommentPageSize.setter(vo.contentCommentPageSize)
            this.contentCommentWithAudioSolarAmount.setter(vo.contentCommentWithAudioSolarAmount)
            this.contentCommentReportAutoHiddenCount.setter(vo.contentCommentReportAutoHiddenCount)
            this.contentCommentThresholdMinutes.setter(vo.contentCommentThresholdMinutes)
            this.contentCommentThresholdCount.setter(vo.contentCommentThresholdCount)
            this.contentCommentLikePageSize.setter(vo.contentCommentLikePageSize)
            this.contentCommentReportPageSize.setter(vo.contentCommentReportPageSize)
            this.contentCommentReportReasonPageSize.setter(vo.contentCommentReportReasonPageSize)
            this.contentParticipationPageSize.setter(vo.contentParticipationPageSize)
            this.contentDailyParticipationExp.setter(vo.contentDailyParticipationExp)
            this.contentDailyParticipationPassExp.setter(vo.contentDailyParticipationPassExp)
            this.contentRepeatParticipationThresholdMinutes.setter(vo.contentRepeatParticipationThresholdMinutes)
            this.contentRepeatParticipationThresholdCount.setter(vo.contentRepeatParticipationThresholdCount)
            this.contentRepeatParticipationExp.setter(vo.contentRepeatParticipationExp)
            this.contentRepeatParticipationPassExp.setter(vo.contentRepeatParticipationPassExp)
            this.contentM10nParticipationThresholdMinutes.setter(vo.contentM10nParticipationThresholdMinutes)
            this.contentM10nParticipationThresholdCount.setter(vo.contentM10nParticipationThresholdCount)
            this.contentM10nParticipationExp.setter(vo.contentM10nParticipationExp)
            this.contentM10nParticipationPassExp.setter(vo.contentM10nParticipationPassExp)
            this.contentUserProductionParticipationThresholdMinutes.setter(vo.contentUserProductionParticipationThresholdMinutes)
            this.contentUserProductionParticipationThresholdCount.setter(vo.contentUserProductionParticipationThresholdCount)
            this.contentUserProductionParticipationExp.setter(vo.contentUserProductionParticipationExp)
            this.contentUserProductionParticipationPassExp.setter(vo.contentUserProductionParticipationPassExp)
            this.contentUserProductionPresetPageSize.setter(vo.contentUserProductionPresetPageSize)
            this.contentUserProductionMinimumAssetLunarAmount.setter(vo.contentUserProductionMinimumAssetLunarAmount)
            this.contentUserProductionMinimumUserLevelId.setter(vo.contentUserProductionMinimumUserLevelId)
            this.contentUserProductionMinimumAccuracy.setter(vo.contentUserProductionMinimumAccuracy)
            this.contentUserProductionPassAccuracy.setter(vo.contentUserProductionPassAccuracy)
            this.contentUserProductionPassHighAccuracy.setter(vo.contentUserProductionPassHighAccuracy)
            this.contentUserProductionPassSolarAmountHighAccuracy.setter(vo.contentUserProductionPassSolarAmountHighAccuracy)
            this.contentUserProductionStarAmount.setter(vo.contentUserProductionStarAmount)
            this.contentUserProductionDefaultDetailUrl.setter(vo.contentUserProductionDefaultDetailUrl)
            this.contentUserProductionDefaultDetailUrlBrowser.setter(vo.contentUserProductionDefaultDetailUrlBrowser)
            this.contentUserProductionDefaultResultUrl.setter(vo.contentUserProductionDefaultResultUrl)
            this.contentUserProductionDefaultResultUrlBrowser.setter(vo.contentUserProductionDefaultResultUrlBrowser)
            this.contentUserProductionScriptPageSize.setter(vo.contentUserProductionScriptPageSize)
            this.contentLikePageSize.setter(vo.contentLikePageSize)
            this.contentPolicyPageSize.setter(vo.contentPolicyPageSize)
            this.contentQueryPageSize.setter(vo.contentQueryPageSize)
            this.contentQueryPopularDurationMinutes.setter(vo.contentQueryPopularDurationMinutes)
            this.contentQueryPopularPageSize.setter(vo.contentQueryPopularPageSize)
            this.contentReportReasonPageSize.setter(vo.contentReportReasonPageSize)
            this.contentReportPageSize.setter(vo.contentReportPageSize)
            this.contentReportDailyThreshold.setter(vo.contentReportDailyThreshold)
            this.contentDetailUrlPageSize.setter(vo.contentDetailUrlPageSize)
            this.contentDetailUrlClickPageSize.setter(vo.contentDetailUrlClickPageSize)
            this.contentDetailUrlClickExp.setter(vo.contentDetailUrlClickExp)
            this.contentResultUrlPageSize.setter(vo.contentResultUrlPageSize)
            this.contentResultUrlClickPageSize.setter(vo.contentResultUrlClickPageSize)
            this.contentResultUrlClickExp.setter(vo.contentResultUrlClickExp)
            this.contentReviewPageSize.setter(vo.contentReviewPageSize)
            this.contentTargetPageSize.setter(vo.contentTargetPageSize)
        },
        values(): [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, bigint, number, number, number, number, number, string, EnvBrowser, string, EnvBrowser, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number] {
            return [
                this.contentPageSize.value,
                this.contentReportAutoHiddenCount.value,
                this.contentCommentPageSize.value,
                this.contentCommentWithAudioSolarAmount.value,
                this.contentCommentReportAutoHiddenCount.value,
                this.contentCommentThresholdMinutes.value,
                this.contentCommentThresholdCount.value,
                this.contentCommentLikePageSize.value,
                this.contentCommentReportPageSize.value,
                this.contentCommentReportReasonPageSize.value,
                this.contentParticipationPageSize.value,
                this.contentDailyParticipationExp.value,
                this.contentDailyParticipationPassExp.value,
                this.contentRepeatParticipationThresholdMinutes.value,
                this.contentRepeatParticipationThresholdCount.value,
                this.contentRepeatParticipationExp.value,
                this.contentRepeatParticipationPassExp.value,
                this.contentM10nParticipationThresholdMinutes.value,
                this.contentM10nParticipationThresholdCount.value,
                this.contentM10nParticipationExp.value,
                this.contentM10nParticipationPassExp.value,
                this.contentUserProductionParticipationThresholdMinutes.value,
                this.contentUserProductionParticipationThresholdCount.value,
                this.contentUserProductionParticipationExp.value,
                this.contentUserProductionParticipationPassExp.value,
                this.contentUserProductionPresetPageSize.value,
                this.contentUserProductionMinimumAssetLunarAmount.value,
                this.contentUserProductionMinimumUserLevelId.value,
                this.contentUserProductionMinimumAccuracy.value,
                this.contentUserProductionPassAccuracy.value,
                this.contentUserProductionPassHighAccuracy.value,
                this.contentUserProductionPassSolarAmountHighAccuracy.value,
                this.contentUserProductionStarAmount.value,
                this.contentUserProductionDefaultDetailUrl.value,
                this.contentUserProductionDefaultDetailUrlBrowser.value,
                this.contentUserProductionDefaultResultUrl.value,
                this.contentUserProductionDefaultResultUrlBrowser.value,
                this.contentUserProductionScriptPageSize.value,
                this.contentLikePageSize.value,
                this.contentPolicyPageSize.value,
                this.contentQueryPageSize.value,
                this.contentQueryPopularDurationMinutes.value,
                this.contentQueryPopularPageSize.value,
                this.contentReportReasonPageSize.value,
                this.contentReportPageSize.value,
                this.contentReportDailyThreshold.value,
                this.contentDetailUrlPageSize.value,
                this.contentDetailUrlClickPageSize.value,
                this.contentDetailUrlClickExp.value,
                this.contentResultUrlPageSize.value,
                this.contentResultUrlClickPageSize.value,
                this.contentResultUrlClickExp.value,
                this.contentReviewPageSize.value,
                this.contentTargetPageSize.value,
            ]
        }
    }
}
