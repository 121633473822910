import {UserGender} from "../../constant/user/UserGender"
import {ContentTargetModificationDomain} from "../../domain/content/ContentTargetModificationDomain"

export const ContentTargetModificationDescriptor: Record<keyof ContentTargetModificationDomain, (o: ContentTargetModificationDomain) => string> = {
    id(o: ContentTargetModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentTargetModificationDomain): string {
        return o.creatorId.toString()
    },
    contentTargetId(o: ContentTargetModificationDomain): string {
        return o.contentTargetId.toString()
    },
    envCountryId(o: ContentTargetModificationDomain): string {
        return o.envCountryId?.toString() ?? '-'
    },
    userGender(o: ContentTargetModificationDomain): string {
        if (o.userGender !== undefined) {
            return UserGender.description(o.userGender)
        } else {
            return '-'
        }
    },
    userBirthYearMin(o: ContentTargetModificationDomain): string {
        return o.userBirthYearMin?.toString() ?? '-'
    },
    userBirthYearMax(o: ContentTargetModificationDomain): string {
        return o.userBirthYearMax?.toString() ?? '-'
    },
    createdAt(o: ContentTargetModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
