import {ContentQueryPopularPreemptionDomain} from "../../domain/content/ContentQueryPopularPreemptionDomain"

/**
 * 콘텐츠 인기 검색어 예약 속성 설명
 */
export const ContentQueryPopularPreemptionDescription: Record<keyof ContentQueryPopularPreemptionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    contentType: '콘텐츠 유형',
    query: '질의어',
    exposedAt: '노출 시작일',
    exposedUntil: '노출 종료일',
    displayOrder: '표시 우선 순위',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
