import {ContentResultUrlClickDomain} from "../../domain/content/ContentResultUrlClickDomain"

export const ContentResultUrlClickDescriptor: Record<keyof ContentResultUrlClickDomain, (o: ContentResultUrlClickDomain) => string> = {
    id(o: ContentResultUrlClickDomain): string {
        return o.id.toString()
    },
    userId(o: ContentResultUrlClickDomain): string {
        return o.userId.toString()
    },
    contentResultUrlId(o: ContentResultUrlClickDomain): string {
        return o.contentResultUrlId.toString()
    },
    assetStarUsageId(o: ContentResultUrlClickDomain): string {
        return o.assetStarUsageId?.toString() ?? '-'
    },
    userExpId(o: ContentResultUrlClickDomain): string {
        return o.userExpId?.toString() ?? '-'
    },
    createdAt(o: ContentResultUrlClickDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
