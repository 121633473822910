import {EnvClientDistribution} from "../../../data/constant/env/EnvClientDistribution"
import {EnvClientPlatform} from "../../../data/constant/env/EnvClientPlatform"
import {EnvClientSearchOptionDescription} from "../../../data/description/env/EnvClientSearchOptionDescription"
import {EnvClientSearchOption} from "../../../data/search-option/env/EnvClientSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function EnvClientSearchOptionField(props: ListOptionFieldProps<EnvClientSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "platformEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.platformEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientPlatform.selections} />
                </ListOptionWrapper>
            </>
        case "platformNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.platformNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientPlatform.selections} />
                </ListOptionWrapper>
            </>
        case "platformMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.platformMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientPlatform.selections} />
                </ListOptionWrapper>
            </>
        case "platformMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.platformMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientPlatform.selections} />
                </ListOptionWrapper>
            </>
        case "platformIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.platformIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={EnvClientPlatform.selections} />
                </ListOptionWrapper>
            </>
        case "distributionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.distributionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.distributionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.distributionMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.distributionMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.distributionIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "versionNameEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.versionNameEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "versionNameNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.versionNameNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "versionNameMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.versionNameMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "versionNameMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.versionNameMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "versionNameLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.versionNameLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "versionNameNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.versionNameNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expiredAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.expiredAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expiredAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.expiredAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expiredAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.expiredAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expiredAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.expiredAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expiredAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.expiredAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.userSignInCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.userSignInCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.userSignInCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userSignInCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.userSignInCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvClientSearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyEnvClientSearchOption(key: keyof EnvClientSearchOption, value: string, into: StatePair<EnvClientSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "platformEq":
            into.setter({
                ...into.value,
                platformEq: value as EnvClientPlatform
            })
            break
        case "platformNeq":
            into.setter({
                ...into.value,
                platformNeq: value as EnvClientPlatform
            })
            break
        case "platformMin":
            into.setter({
                ...into.value,
                platformMin: value as EnvClientPlatform
            })
            break
        case "platformMax":
            into.setter({
                ...into.value,
                platformMax: value as EnvClientPlatform
            })
            break
        case "platformIn":
            into.setter({
                ...into.value,
                platformIn: value
                    .split('|')
                    .map(e => e as EnvClientPlatform)
            })
            break
        case "distributionEq":
            into.setter({
                ...into.value,
                distributionEq: value as EnvClientDistribution
            })
            break
        case "distributionNeq":
            into.setter({
                ...into.value,
                distributionNeq: value as EnvClientDistribution
            })
            break
        case "distributionMin":
            into.setter({
                ...into.value,
                distributionMin: value as EnvClientDistribution
            })
            break
        case "distributionMax":
            into.setter({
                ...into.value,
                distributionMax: value as EnvClientDistribution
            })
            break
        case "distributionIn":
            into.setter({
                ...into.value,
                distributionIn: value
                    .split('|')
                    .map(e => e as EnvClientDistribution)
            })
            break
        case "versionNameEq":
            into.setter({
                ...into.value,
                versionNameEq: String(value)
            })
            break
        case "versionNameNeq":
            into.setter({
                ...into.value,
                versionNameNeq: String(value)
            })
            break
        case "versionNameMin":
            into.setter({
                ...into.value,
                versionNameMin: String(value)
            })
            break
        case "versionNameMax":
            into.setter({
                ...into.value,
                versionNameMax: String(value)
            })
            break
        case "versionNameLike":
            into.setter({
                ...into.value,
                versionNameLike: String(value)
            })
            break
        case "versionNameNotLike":
            into.setter({
                ...into.value,
                versionNameNotLike: String(value)
            })
            break
        case "expiredAtEq":
            into.setter({
                ...into.value,
                expiredAtEq: new Date(value)
            })
            break
        case "expiredAtNeq":
            into.setter({
                ...into.value,
                expiredAtNeq: new Date(value)
            })
            break
        case "expiredAtMin":
            into.setter({
                ...into.value,
                expiredAtMin: new Date(value)
            })
            break
        case "expiredAtMax":
            into.setter({
                ...into.value,
                expiredAtMax: new Date(value)
            })
            break
        case "expiredAtIsNull":
            into.setter({
                ...into.value,
                expiredAtIsNull: Boolean(value)
            })
            break
        case "userSignInCountEq":
            into.setter({
                ...into.value,
                userSignInCountEq: Number(value)
            })
            break
        case "userSignInCountNeq":
            into.setter({
                ...into.value,
                userSignInCountNeq: Number(value)
            })
            break
        case "userSignInCountMin":
            into.setter({
                ...into.value,
                userSignInCountMin: Number(value)
            })
            break
        case "userSignInCountMax":
            into.setter({
                ...into.value,
                userSignInCountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
