import {EnvEventModificationVerboseDomain} from "../../domain/env/EnvEventModificationVerboseDomain"
import {EnvEventId} from "../../id/env/EnvEventId"
import {EnvEventModificationId} from "../../id/env/EnvEventModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvEventValueObject} from "./EnvEventValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 이벤트 수정 이력 상세
 */
export class EnvEventModificationVerboseValueObject implements VerboseValueObject, EnvEventModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvEventModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 이벤트 ID
     */
    envEventId: EnvEventId
    /**
     * 제목
     */
    title: string
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 이벤트 ID
     */
    envEvent: EnvEventValueObject

    constructor(o: Record<keyof EnvEventModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envEventId = BigInt(o.envEventId)
        this.title = String(o.title)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envEvent = new EnvEventValueObject(o.envEvent)
    }
}
