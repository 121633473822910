import {UserBlockModificationDomain} from "../../domain/user/UserBlockModificationDomain"
import {UserBlockId} from "../../id/user/UserBlockId"
import {UserBlockModificationId} from "../../id/user/UserBlockModificationId"
import {UserBlockReasonId} from "../../id/user/UserBlockReasonId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 정지 수정 이력
 */
export class UserBlockModificationValueObject implements ValueObject, UserBlockModificationDomain {
    /**
     * 기본키
     */
    id: UserBlockModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 이용 정지 ID
     */
    userBlockId: UserBlockId
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReasonId: UserBlockReasonId
    /**
     * 이용 정지 시작일
     */
    blockedAt: Date
    /**
     * 이용 정지 종료일
     */
    blockedUntil?: Date
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UserBlockModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userBlockId = BigInt(o.userBlockId)
        this.userBlockReasonId = BigInt(o.userBlockReasonId)
        this.blockedAt = new Date(o.blockedAt)
        if (o.blockedUntil !== undefined && o.blockedUntil !== null) {
            this.blockedUntil = new Date(o.blockedUntil)
        }
        this.createdAt = new Date(o.createdAt)
    }
}
