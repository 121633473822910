import {EnvClientDomain} from "../../domain/env/EnvClientDomain"

/**
 * 클라이언트 속성 설명
 */
export const EnvClientDescription: Record<keyof EnvClientDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    platform: '플랫폼 유형',
    distribution: '배포 유형',
    versionName: '이름',
    expiredAt: '만료일',
    userSignInCount: '로그인한 사용자 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
