import ListPageWrapper from "../../ListPageWrapper";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentReviewValueObject} from "../../../data/value-object/content/ContentReviewValueObject";
import {ContentReviewSearchOption} from "../../../data/search-option/content/ContentReviewSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import {ContentReviewIO} from "../../../io/ContentIO";
import {ContentReviewDescription} from "../../../data/description/content/ContentReviewDescription";
import {ContentReviewDescriptor} from "../../../data/descriptor/content/ContentReviewDescriptor";
import ListOption from "../../ListOption";
import {
    ContentReviewSearchOptionDescription
} from "../../../data/description/content/ContentReviewSearchOptionDescription";
import {
    applyContentReviewSearchOption,
    ContentReviewSearchOptionField
} from "../../search-option-field/content/ContentReviewSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentReviewValueObject[]>([])
    const option = useStatePair<ContentReviewSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentReviewIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentReviewDescription}
                descriptor={ContentReviewDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/participations/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentReviewSearchOptionDescription}
                fields={ContentReviewSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentReviewSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentReviewDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}