import {ContentDifficulty} from "../../constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../constant/content/ContentScriptVisibility"
import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentPutDataTransferObject} from "../../data-transfer-object/content/ContentPutDataTransferObject"
import {ContentValueObject} from "../../value-object/content/ContentValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type ContentPutState = {
    advertiserId: StatePair<bigint | undefined>
    participationMethod: StatePair<ContentParticipationMethod>
    title: StatePair<string>
    titleHighlight: StatePair<string | undefined>
    body: StatePair<string>
    script: StatePair<string>
    scriptVisibility: StatePair<ContentScriptVisibility>
    gradingMessage: StatePair<string | undefined>
    amount: StatePair<number>
    passAccuracy: StatePair<number>
    passAccuracyHigh: StatePair<number | undefined>
    passSolarAmount: StatePair<number>
    passSolarAmountHighAccuracy: StatePair<number>
    starAmount: StatePair<number>
    difficulty: StatePair<ContentDifficulty>
    headerImageCount: StatePair<number>
    descriptionImageCount: StatePair<number>
    exposedAt: StatePair<string>
    startAt: StatePair<string>
    endAt: StatePair<string>
    statusFlags: StatePair<number>
    contentDetailUrl: StatePair<string>
    contentDetailUrlBrowser: StatePair<EnvBrowser>
    contentResultUrl: StatePair<string>
    contentResultUrlBrowser: StatePair<EnvBrowser>
    toDataTransferObject(): ContentPutDataTransferObject
    fromValueObject(vo: ContentValueObject): void
    values(): [bigint | undefined, ContentParticipationMethod, string, string | undefined, string, string, ContentScriptVisibility, string | undefined, number, number, number | undefined, number, number, number, ContentDifficulty, number, number, string, string, string, number, string, EnvBrowser, string, EnvBrowser]
}

export function useContentPutState(): ContentPutState {
    return {
        advertiserId: useStatePair<bigint | undefined>(undefined),
        participationMethod: useStatePair<ContentParticipationMethod>(ContentParticipationMethod.Typing),
        title: useStatePair<string>(''),
        titleHighlight: useStatePair<string | undefined>(undefined),
        body: useStatePair<string>(''),
        script: useStatePair<string>(''),
        scriptVisibility: useStatePair<ContentScriptVisibility>(ContentScriptVisibility.Visible),
        gradingMessage: useStatePair<string | undefined>(undefined),
        amount: useStatePair<number>(0),
        passAccuracy: useStatePair<number>(0),
        passAccuracyHigh: useStatePair<number | undefined>(undefined),
        passSolarAmount: useStatePair<number>(0),
        passSolarAmountHighAccuracy: useStatePair<number>(0),
        starAmount: useStatePair<number>(0),
        difficulty: useStatePair<ContentDifficulty>(ContentDifficulty.VeryEasy),
        headerImageCount: useStatePair<number>(0),
        descriptionImageCount: useStatePair<number>(0),
        exposedAt: useStatePair<string>(''),
        startAt: useStatePair<string>(''),
        endAt: useStatePair<string>(''),
        statusFlags: useStatePair<number>(0),
        contentDetailUrl: useStatePair<string>(''),
        contentDetailUrlBrowser: useStatePair<EnvBrowser>(EnvBrowser.DefaultBrowser),
        contentResultUrl: useStatePair<string>(''),
        contentResultUrlBrowser: useStatePair<EnvBrowser>(EnvBrowser.DefaultBrowser),
        toDataTransferObject(): ContentPutDataTransferObject {
            return {
                advertiserId: this.advertiserId.value,
                participationMethod: this.participationMethod.value,
                title: this.title.value,
                titleHighlight: this.titleHighlight.value,
                body: this.body.value,
                script: this.script.value,
                scriptVisibility: this.scriptVisibility.value,
                gradingMessage: this.gradingMessage.value,
                amount: this.amount.value,
                passAccuracy: this.passAccuracy.value,
                passAccuracyHigh: this.passAccuracyHigh.value,
                passSolarAmount: this.passSolarAmount.value,
                passSolarAmountHighAccuracy: this.passSolarAmountHighAccuracy.value,
                starAmount: this.starAmount.value,
                difficulty: this.difficulty.value,
                headerImageCount: this.headerImageCount.value,
                descriptionImageCount: this.descriptionImageCount.value,
                exposedAt: new Date(this.exposedAt.value),
                startAt: new Date(this.startAt.value),
                endAt: new Date(this.endAt.value),
                statusFlags: this.statusFlags.value,
                contentDetailUrl: this.contentDetailUrl.value,
                contentDetailUrlBrowser: this.contentDetailUrlBrowser.value,
                contentResultUrl: this.contentResultUrl.value,
                contentResultUrlBrowser: this.contentResultUrlBrowser.value,
            }
        },
        fromValueObject(vo: ContentValueObject) {
            this.advertiserId.setter(vo.advertiserId)
            this.participationMethod.setter(vo.participationMethod)
            this.title.setter(vo.title)
            this.titleHighlight.setter(vo.titleHighlight)
            this.body.setter(vo.body)
            this.script.setter(vo.script)
            this.scriptVisibility.setter(vo.scriptVisibility)
            this.gradingMessage.setter(vo.gradingMessage)
            this.amount.setter(vo.amount)
            this.passAccuracy.setter(vo.passAccuracy)
            this.passAccuracyHigh.setter(vo.passAccuracyHigh)
            this.passSolarAmount.setter(vo.passSolarAmount)
            this.passSolarAmountHighAccuracy.setter(vo.passSolarAmountHighAccuracy)
            this.starAmount.setter(vo.starAmount)
            this.difficulty.setter(vo.difficulty)
            this.headerImageCount.setter(vo.headerImageCount)
            this.descriptionImageCount.setter(vo.descriptionImageCount)
            this.exposedAt.setter(DateFormat.tableDateTime(vo.exposedAt))
            this.startAt.setter(DateFormat.tableDateTime(vo.startAt))
            this.endAt.setter(DateFormat.tableDateTime(vo.endAt))
            this.statusFlags.setter(vo.statusFlags)
        },
        values(): [bigint | undefined, ContentParticipationMethod, string, string | undefined, string, string, ContentScriptVisibility, string | undefined, number, number, number | undefined, number, number, number, ContentDifficulty, number, number, string, string, string, number, string, EnvBrowser, string, EnvBrowser] {
            return [
                this.advertiserId.value,
                this.participationMethod.value,
                this.title.value,
                this.titleHighlight.value,
                this.body.value,
                this.script.value,
                this.scriptVisibility.value,
                this.gradingMessage.value,
                this.amount.value,
                this.passAccuracy.value,
                this.passAccuracyHigh.value,
                this.passSolarAmount.value,
                this.passSolarAmountHighAccuracy.value,
                this.starAmount.value,
                this.difficulty.value,
                this.headerImageCount.value,
                this.descriptionImageCount.value,
                this.exposedAt.value,
                this.startAt.value,
                this.endAt.value,
                this.statusFlags.value,
                this.contentDetailUrl.value,
                this.contentDetailUrlBrowser.value,
                this.contentResultUrl.value,
                this.contentResultUrlBrowser.value,
            ]
        }
    }
}
