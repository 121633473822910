import {UserRankHistorySearchOption} from "../../search-option/user/UserRankHistorySearchOption"

/**
 * 사용자 랭크 기록 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserRankHistorySearchOptionDescription: Record<keyof UserRankHistorySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    userRankIdEq: '사용자 랭크 ID 일치',
    userRankIdNeq: '사용자 랭크 ID 불일치',
    userRankIdMin: '사용자 랭크 ID 최소',
    userRankIdMax: '사용자 랭크 ID 최대',
    assetLunarUsageIdEq: '루나 지급 ID 일치',
    assetLunarUsageIdNeq: '루나 지급 ID 불일치',
    assetLunarUsageIdMin: '루나 지급 ID 최소',
    assetLunarUsageIdMax: '루나 지급 ID 최대',
    assetLunarUsageIdIsNull: '루나 지급 ID 값 존재 여부',
    assetSolarUsageIdEq: '솔라 지급 ID 일치',
    assetSolarUsageIdNeq: '솔라 지급 ID 불일치',
    assetSolarUsageIdMin: '솔라 지급 ID 최소',
    assetSolarUsageIdMax: '솔라 지급 ID 최대',
    assetSolarUsageIdIsNull: '솔라 지급 ID 값 존재 여부',
    assetStarUsageIdEq: '스타 지급 ID 일치',
    assetStarUsageIdNeq: '스타 지급 ID 불일치',
    assetStarUsageIdMin: '스타 지급 ID 최소',
    assetStarUsageIdMax: '스타 지급 ID 최대',
    assetStarUsageIdIsNull: '스타 지급 ID 값 존재 여부',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
