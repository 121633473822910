import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvBankValueObject} from "../../../data/value-object/env/EnvBankValueObject";
import {useCallback, useEffect} from "react";
import {EnvBankIO} from "../../../io/EnvIO";
import {Button, Spinner, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFileField} from "../../PostField";
import {MediaFile} from "../../Media";
import {StorageIO, StorageKey} from "../../../io/StorageIO";
import {EnvBankId} from "../../../data/id/env/EnvBankId";
import {createMediaFileFromStorageObject} from "../../../util/FileUtils";
import {useEnvBankPutState} from "../../../data/data-transfer-object-state/env/EnvBankPutState";
import {EnvBankPutFields} from "../../data-transfer-object-field/env/EnvBankPutField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envBankId: string }>()
    const item = useStatePair<EnvBankValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const state = useEnvBankPutState()
    const icon = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.envBankId !== undefined) {
            const id = BigInt(params.envBankId)
            EnvBankIO
                .getById(id)
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })

            StorageIO
                .getObjectByteArray(StorageKey.Env.bank(id))
                .then(value => icon.setter(createMediaFileFromStorageObject(value)))
                .catch(reason => {
                    console.error(reason)
                    alert('이미지를 불러올 수 없습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        const iconFile = icon.value
        if (iconFile === undefined) {
            alert('아이콘을 선택해주세요.')
            return
        }

        phase.setter(Phase.Putting)
        put(id, iconFile)
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/banks/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [...state.values(), icon.value])


    const put = async (id: EnvBankId, iconFile: MediaFile) => {
        await EnvBankIO.put(id, state.toDataTransferObject())
        await StorageIO.putFile(StorageKey.Env.bank(id), iconFile)
    }

    return <>
        {phase.value === Phase.Loading && <Spinner />}
        {(phase.value === Phase.Waiting || phase.value === Phase.Putting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <EnvBankPutFields
                    {...props}
                    state={state} />
                <PostFieldWrapper
                    {...props}
                    title={'아이콘'}>
                    <PostFileField
                        {...props}
                        value={icon.value}
                        setter={icon.setter}
                        accept={'image/*'} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
    </>
}