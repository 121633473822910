import {AssetLunarExchangeOptionModificationVerboseDomain} from "../../domain/asset/AssetLunarExchangeOptionModificationVerboseDomain"
import {AssetLunarExchangeOptionId} from "../../id/asset/AssetLunarExchangeOptionId"
import {AssetLunarExchangeOptionModificationId} from "../../id/asset/AssetLunarExchangeOptionModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {AssetLunarExchangeOptionValueObject} from "./AssetLunarExchangeOptionValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 루나 교환 옵션 수정 이력 상세
 */
export class AssetLunarExchangeOptionModificationVerboseValueObject implements VerboseValueObject, AssetLunarExchangeOptionModificationVerboseDomain {
    /**
     * 기본키
     */
    id: AssetLunarExchangeOptionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 루나 교환 옵션 ID
     */
    assetLunarExchangeOptionId: AssetLunarExchangeOptionId
    /**
     * 지급 루나
     */
    lunarAmount: number
    /**
     * 소모 솔라
     */
    solarAmount: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 루나 교환 옵션 ID
     */
    assetLunarExchangeOption: AssetLunarExchangeOptionValueObject

    constructor(o: Record<keyof AssetLunarExchangeOptionModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetLunarExchangeOptionId = BigInt(o.assetLunarExchangeOptionId)
        this.lunarAmount = Number(o.lunarAmount)
        this.solarAmount = Number(o.solarAmount)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.assetLunarExchangeOption = new AssetLunarExchangeOptionValueObject(o.assetLunarExchangeOption)
    }
}
