import {EnvBrowser} from "../../../data/constant/env/EnvBrowser"
import {ContentPolicyPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/content/ContentPolicyPostDataTransferObjectDescription"
import {ContentPolicyPostState} from "../../../data/data-transfer-object-state/content/ContentPolicyPostState"
import {ContentPolicyPostDataTransferObject} from "../../../data/data-transfer-object/content/ContentPolicyPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: ContentPolicyPostState
}

export const ContentPolicyPostField: Record<Capitalize<keyof ContentPolicyPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    ContentPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentPageSize.value}
                    setter={props.state.contentPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportAutoHiddenCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentReportAutoHiddenCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportAutoHiddenCount.value}
                    setter={props.state.contentReportAutoHiddenCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentPageSize.value}
                    setter={props.state.contentCommentPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentWithAudioSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentWithAudioSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentWithAudioSolarAmount.value}
                    setter={props.state.contentCommentWithAudioSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentReportAutoHiddenCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentReportAutoHiddenCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentReportAutoHiddenCount.value}
                    setter={props.state.contentCommentReportAutoHiddenCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentThresholdMinutes.value}
                    setter={props.state.contentCommentThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentThresholdCount.value}
                    setter={props.state.contentCommentThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentLikePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentLikePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentLikePageSize.value}
                    setter={props.state.contentCommentLikePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentReportPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentReportPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentReportPageSize.value}
                    setter={props.state.contentCommentReportPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentCommentReportReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentCommentReportReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentCommentReportReasonPageSize.value}
                    setter={props.state.contentCommentReportReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentParticipationPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentParticipationPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentParticipationPageSize.value}
                    setter={props.state.contentParticipationPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDailyParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentDailyParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDailyParticipationExp.value}
                    setter={props.state.contentDailyParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDailyParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentDailyParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDailyParticipationPassExp.value}
                    setter={props.state.contentDailyParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentRepeatParticipationThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationThresholdMinutes.value}
                    setter={props.state.contentRepeatParticipationThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentRepeatParticipationThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationThresholdCount.value}
                    setter={props.state.contentRepeatParticipationThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentRepeatParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationExp.value}
                    setter={props.state.contentRepeatParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentRepeatParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentRepeatParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentRepeatParticipationPassExp.value}
                    setter={props.state.contentRepeatParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentM10nParticipationThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationThresholdMinutes.value}
                    setter={props.state.contentM10nParticipationThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentM10nParticipationThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationThresholdCount.value}
                    setter={props.state.contentM10nParticipationThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentM10nParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationExp.value}
                    setter={props.state.contentM10nParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentM10nParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentM10nParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentM10nParticipationPassExp.value}
                    setter={props.state.contentM10nParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionParticipationThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationThresholdMinutes.value}
                    setter={props.state.contentUserProductionParticipationThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionParticipationThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationThresholdCount.value}
                    setter={props.state.contentUserProductionParticipationThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionParticipationExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationExp.value}
                    setter={props.state.contentUserProductionParticipationExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionParticipationPassExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionParticipationPassExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionParticipationPassExp.value}
                    setter={props.state.contentUserProductionParticipationPassExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPresetPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionPresetPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPresetPageSize.value}
                    setter={props.state.contentUserProductionPresetPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionMinimumAssetLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionMinimumAssetLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionMinimumAssetLunarAmount.value}
                    setter={props.state.contentUserProductionMinimumAssetLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionMinimumUserLevelId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionMinimumUserLevelId}>
                <PostBigIntField
                    {...props}
                    value={props.state.contentUserProductionMinimumUserLevelId.value}
                    setter={props.state.contentUserProductionMinimumUserLevelId.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionMinimumAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionMinimumAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionMinimumAccuracy.value}
                    setter={props.state.contentUserProductionMinimumAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPassAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionPassAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPassAccuracy.value}
                    setter={props.state.contentUserProductionPassAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPassHighAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionPassHighAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPassHighAccuracy.value}
                    setter={props.state.contentUserProductionPassHighAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionPassSolarAmountHighAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionPassSolarAmountHighAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionPassSolarAmountHighAccuracy.value}
                    setter={props.state.contentUserProductionPassSolarAmountHighAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionStarAmount.value}
                    setter={props.state.contentUserProductionStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultDetailUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionDefaultDetailUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentUserProductionDefaultDetailUrl.value}
                    setter={props.state.contentUserProductionDefaultDetailUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultDetailUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionDefaultDetailUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentUserProductionDefaultDetailUrlBrowser.value}
                    setter={props.state.contentUserProductionDefaultDetailUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultResultUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionDefaultResultUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentUserProductionDefaultResultUrl.value}
                    setter={props.state.contentUserProductionDefaultResultUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionDefaultResultUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionDefaultResultUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentUserProductionDefaultResultUrlBrowser.value}
                    setter={props.state.contentUserProductionDefaultResultUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    ContentUserProductionScriptPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentUserProductionScriptPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentUserProductionScriptPageSize.value}
                    setter={props.state.contentUserProductionScriptPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentLikePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentLikePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentLikePageSize.value}
                    setter={props.state.contentLikePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentPolicyPageSize.value}
                    setter={props.state.contentPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentQueryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentQueryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentQueryPageSize.value}
                    setter={props.state.contentQueryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentQueryPopularDurationMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentQueryPopularDurationMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.contentQueryPopularDurationMinutes.value}
                    setter={props.state.contentQueryPopularDurationMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    ContentQueryPopularPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentQueryPopularPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentQueryPopularPageSize.value}
                    setter={props.state.contentQueryPopularPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentReportReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportReasonPageSize.value}
                    setter={props.state.contentReportReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentReportPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportPageSize.value}
                    setter={props.state.contentReportPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReportDailyThreshold(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentReportDailyThreshold}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReportDailyThreshold.value}
                    setter={props.state.contentReportDailyThreshold.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentDetailUrlPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDetailUrlPageSize.value}
                    setter={props.state.contentDetailUrlPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlClickPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentDetailUrlClickPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDetailUrlClickPageSize.value}
                    setter={props.state.contentDetailUrlClickPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlClickExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentDetailUrlClickExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentDetailUrlClickExp.value}
                    setter={props.state.contentDetailUrlClickExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentResultUrlPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentResultUrlPageSize.value}
                    setter={props.state.contentResultUrlPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlClickPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentResultUrlClickPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentResultUrlClickPageSize.value}
                    setter={props.state.contentResultUrlClickPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlClickExp(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentResultUrlClickExp}>
                <PostNumberField
                    {...props}
                    value={props.state.contentResultUrlClickExp.value}
                    setter={props.state.contentResultUrlClickExp.setter} />
            </PostFieldWrapper>
        )
    },
    ContentReviewPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentReviewPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentReviewPageSize.value}
                    setter={props.state.contentReviewPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    ContentTargetPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPolicyPostDataTransferObjectDescription.contentTargetPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.contentTargetPageSize.value}
                    setter={props.state.contentTargetPageSize.setter} />
            </PostFieldWrapper>
        )
    },
}

export function ContentPolicyPostFields(props: PostFieldProps) {
    return <>
        <ContentPolicyPostField.ContentPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentReportAutoHiddenCount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentWithAudioSolarAmount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentReportAutoHiddenCount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentLikePageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentReportPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentCommentReportReasonPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentParticipationPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentDailyParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentDailyParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentRepeatParticipationThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentRepeatParticipationThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentRepeatParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentRepeatParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentM10nParticipationThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentM10nParticipationThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentM10nParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentM10nParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionParticipationThresholdMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionParticipationThresholdCount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionParticipationExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionParticipationPassExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionPresetPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionMinimumAssetLunarAmount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionMinimumUserLevelId
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionMinimumAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionPassAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionPassHighAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionPassSolarAmountHighAccuracy
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionStarAmount
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionDefaultDetailUrl
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionDefaultDetailUrlBrowser
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionDefaultResultUrl
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionDefaultResultUrlBrowser
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentUserProductionScriptPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentLikePageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentPolicyPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentQueryPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentQueryPopularDurationMinutes
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentQueryPopularPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentReportReasonPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentReportPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentReportDailyThreshold
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentDetailUrlPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentDetailUrlClickPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentDetailUrlClickExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentResultUrlPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentResultUrlClickPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentResultUrlClickExp
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentReviewPageSize
            {...props}
            state={props.state} />
        <ContentPolicyPostField.ContentTargetPageSize
            {...props}
            state={props.state} />
    </>
}
