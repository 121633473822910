import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvClientPlatform} from "../../constant/env/EnvClientPlatform"
import {EnvClientModificationVerboseDomain} from "../../domain/env/EnvClientModificationVerboseDomain"
import {EnvClientId} from "../../id/env/EnvClientId"
import {EnvClientModificationId} from "../../id/env/EnvClientModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvClientValueObject} from "./EnvClientValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 클라이언트 수정 이력 상세
 */
export class EnvClientModificationVerboseValueObject implements VerboseValueObject, EnvClientModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvClientModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 클라이언트 ID
     */
    envClientId: EnvClientId
    /**
     * 플랫폼 유형
     */
    platform: EnvClientPlatform
    /**
     * 배포 유형
     */
    distribution: EnvClientDistribution
    /**
     * 이름
     */
    versionName: string
    /**
     * 만료일
     */
    expiredAt?: Date
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 클라이언트 ID
     */
    envClient: EnvClientValueObject

    constructor(o: Record<keyof EnvClientModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envClientId = BigInt(o.envClientId)
        this.platform = o.platform as EnvClientPlatform
        this.distribution = o.distribution as EnvClientDistribution
        this.versionName = String(o.versionName)
        if (o.expiredAt !== undefined && o.expiredAt !== null) {
            this.expiredAt = new Date(o.expiredAt)
        }
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envClient = new EnvClientValueObject(o.envClient)
    }
}
