import {BaseHttpClient} from "./HttpClient";
import {ContentCommentLikeId} from "../data/id/content/ContentCommentLikeId";
import {
    ContentCommentLikePostDataTransferObject
} from "../data/data-transfer-object/content/ContentCommentLikePostDataTransferObject";
import {ContentCommentLikeValueObject} from "../data/value-object/content/ContentCommentLikeValueObject";
import {ContentCommentLikeVerboseValueObject} from "../data/value-object/content/ContentCommentLikeVerboseValueObject";
import {ContentCommentLikeSearchOption} from "../data/search-option/content/ContentCommentLikeSearchOption";
import {ContentCommentModificationId} from "../data/id/content/ContentCommentModificationId";
import {
    ContentCommentModificationValueObject
} from "../data/value-object/content/ContentCommentModificationValueObject";
import {
    ContentCommentModificationVerboseValueObject
} from "../data/value-object/content/ContentCommentModificationVerboseValueObject";
import {
    ContentCommentModificationSearchOption
} from "../data/search-option/content/ContentCommentModificationSearchOption";
import {ContentCommentReportReasonModificationId} from "../data/id/content/ContentCommentReportReasonModificationId";
import {
    ContentCommentReportReasonModificationValueObject
} from "../data/value-object/content/ContentCommentReportReasonModificationValueObject";
import {
    ContentCommentReportReasonModificationVerboseValueObject
} from "../data/value-object/content/ContentCommentReportReasonModificationVerboseValueObject";
import {
    ContentCommentReportReasonModificationSearchOption
} from "../data/search-option/content/ContentCommentReportReasonModificationSearchOption";
import {ContentCommentReportReasonId} from "../data/id/content/ContentCommentReportReasonId";
import {
    ContentCommentReportReasonPostDataTransferObject
} from "../data/data-transfer-object/content/ContentCommentReportReasonPostDataTransferObject";
import {
    ContentCommentReportReasonPutDataTransferObject
} from "../data/data-transfer-object/content/ContentCommentReportReasonPutDataTransferObject";
import {
    ContentCommentReportReasonValueObject
} from "../data/value-object/content/ContentCommentReportReasonValueObject";
import {
    ContentCommentReportReasonVerboseValueObject
} from "../data/value-object/content/ContentCommentReportReasonVerboseValueObject";
import {
    ContentCommentReportReasonSearchOption
} from "../data/search-option/content/ContentCommentReportReasonSearchOption";
import {ContentCommentReportId} from "../data/id/content/ContentCommentReportId";
import {
    ContentCommentReportPostDataTransferObject
} from "../data/data-transfer-object/content/ContentCommentReportPostDataTransferObject";
import {ContentCommentReportValueObject} from "../data/value-object/content/ContentCommentReportValueObject";
import {
    ContentCommentReportVerboseValueObject
} from "../data/value-object/content/ContentCommentReportVerboseValueObject";
import {ContentCommentReportSearchOption} from "../data/search-option/content/ContentCommentReportSearchOption";
import {ContentCommentId} from "../data/id/content/ContentCommentId";
import {
    ContentCommentPostDataTransferObject
} from "../data/data-transfer-object/content/ContentCommentPostDataTransferObject";
import {
    ContentCommentPutDataTransferObject
} from "../data/data-transfer-object/content/ContentCommentPutDataTransferObject";
import {ContentCommentValueObject} from "../data/value-object/content/ContentCommentValueObject";
import {ContentCommentVerboseValueObject} from "../data/value-object/content/ContentCommentVerboseValueObject";
import {ContentCommentSearchOption} from "../data/search-option/content/ContentCommentSearchOption";
import {ContentModificationId} from "../data/id/content/ContentModificationId";
import {ContentModificationValueObject} from "../data/value-object/content/ContentModificationValueObject";
import {
    ContentModificationVerboseValueObject
} from "../data/value-object/content/ContentModificationVerboseValueObject";
import {ContentModificationSearchOption} from "../data/search-option/content/ContentModificationSearchOption";
import {ContentParticipationId} from "../data/id/content/ContentParticipationId";
import {
    ContentParticipationPostDataTransferObject
} from "../data/data-transfer-object/content/ContentParticipationPostDataTransferObject";
import {ContentParticipationValueObject} from "../data/value-object/content/ContentParticipationValueObject";
import {
    ContentParticipationVerboseValueObject
} from "../data/value-object/content/ContentParticipationVerboseValueObject";
import {ContentParticipationSearchOption} from "../data/search-option/content/ContentParticipationSearchOption";
import {ContentPolicyModificationId} from "../data/id/content/ContentPolicyModificationId";
import {ContentPolicyModificationValueObject} from "../data/value-object/content/ContentPolicyModificationValueObject";
import {
    ContentPolicyModificationVerboseValueObject
} from "../data/value-object/content/ContentPolicyModificationVerboseValueObject";
import {
    ContentPolicyModificationSearchOption
} from "../data/search-option/content/ContentPolicyModificationSearchOption";
import {ContentPolicyId} from "../data/id/content/ContentPolicyId";
import {
    ContentPolicyPostDataTransferObject
} from "../data/data-transfer-object/content/ContentPolicyPostDataTransferObject";
import {
    ContentPolicyPutDataTransferObject
} from "../data/data-transfer-object/content/ContentPolicyPutDataTransferObject";
import {ContentPolicyValueObject} from "../data/value-object/content/ContentPolicyValueObject";
import {ContentPolicyVerboseValueObject} from "../data/value-object/content/ContentPolicyVerboseValueObject";
import {ContentPolicySearchOption} from "../data/search-option/content/ContentPolicySearchOption";
import {ContentReportReasonModificationId} from "../data/id/content/ContentReportReasonModificationId";
import {
    ContentReportReasonModificationValueObject
} from "../data/value-object/content/ContentReportReasonModificationValueObject";
import {
    ContentReportReasonModificationVerboseValueObject
} from "../data/value-object/content/ContentReportReasonModificationVerboseValueObject";
import {
    ContentReportReasonModificationSearchOption
} from "../data/search-option/content/ContentReportReasonModificationSearchOption";
import {ContentReportReasonId} from "../data/id/content/ContentReportReasonId";
import {
    ContentReportReasonPostDataTransferObject
} from "../data/data-transfer-object/content/ContentReportReasonPostDataTransferObject";
import {
    ContentReportReasonPutDataTransferObject
} from "../data/data-transfer-object/content/ContentReportReasonPutDataTransferObject";
import {ContentReportReasonValueObject} from "../data/value-object/content/ContentReportReasonValueObject";
import {
    ContentReportReasonVerboseValueObject
} from "../data/value-object/content/ContentReportReasonVerboseValueObject";
import {ContentReportReasonSearchOption} from "../data/search-option/content/ContentReportReasonSearchOption";
import {ContentReportId} from "../data/id/content/ContentReportId";
import {
    ContentReportPostDataTransferObject
} from "../data/data-transfer-object/content/ContentReportPostDataTransferObject";
import {ContentReportValueObject} from "../data/value-object/content/ContentReportValueObject";
import {ContentReportVerboseValueObject} from "../data/value-object/content/ContentReportVerboseValueObject";
import {ContentReportSearchOption} from "../data/search-option/content/ContentReportSearchOption";
import {ContentReviewId} from "../data/id/content/ContentReviewId";
import {
    ContentReviewPostDataTransferObject
} from "../data/data-transfer-object/content/ContentReviewPostDataTransferObject";
import {ContentReviewValueObject} from "../data/value-object/content/ContentReviewValueObject";
import {ContentReviewVerboseValueObject} from "../data/value-object/content/ContentReviewVerboseValueObject";
import {ContentReviewSearchOption} from "../data/search-option/content/ContentReviewSearchOption";
import {ContentId} from "../data/id/content/ContentId";
import {ContentPostDataTransferObject} from "../data/data-transfer-object/content/ContentPostDataTransferObject";
import {ContentPutDataTransferObject} from "../data/data-transfer-object/content/ContentPutDataTransferObject";
import {ContentValueObject} from "../data/value-object/content/ContentValueObject";
import {ContentVerboseValueObject} from "../data/value-object/content/ContentVerboseValueObject";
import {ContentSearchOption} from "../data/search-option/content/ContentSearchOption";
import {ContentTargetModificationId} from "../data/id/content/ContentTargetModificationId";
import {ContentTargetModificationValueObject} from "../data/value-object/content/ContentTargetModificationValueObject";
import {
    ContentTargetModificationVerboseValueObject
} from "../data/value-object/content/ContentTargetModificationVerboseValueObject";
import {
    ContentTargetModificationSearchOption
} from "../data/search-option/content/ContentTargetModificationSearchOption";
import {ContentTargetId} from "../data/id/content/ContentTargetId";
import {
    ContentTargetPostDataTransferObject
} from "../data/data-transfer-object/content/ContentTargetPostDataTransferObject";
import {
    ContentTargetPutDataTransferObject
} from "../data/data-transfer-object/content/ContentTargetPutDataTransferObject";
import {ContentTargetValueObject} from "../data/value-object/content/ContentTargetValueObject";
import {ContentTargetVerboseValueObject} from "../data/value-object/content/ContentTargetVerboseValueObject";
import {ContentTargetSearchOption} from "../data/search-option/content/ContentTargetSearchOption";
import {ContentQueryId} from "../data/id/content/ContentQueryId";
import {
    ContentQueryPostDataTransferObject
} from "../data/data-transfer-object/content/ContentQueryPostDataTransferObject";
import {ContentQueryValueObject} from "../data/value-object/content/ContentQueryValueObject";
import {ContentQueryVerboseValueObject} from "../data/value-object/content/ContentQueryVerboseValueObject";
import {ContentQuerySearchOption} from "../data/search-option/content/ContentQuerySearchOption";
import {ContentQueryPopularId} from "../data/id/content/ContentQueryPopularId";
import {ContentQueryPopularValueObject} from "../data/value-object/content/ContentQueryPopularValueObject";
import {
    ContentQueryPopularVerboseValueObject
} from "../data/value-object/content/ContentQueryPopularVerboseValueObject";
import {ContentQueryPopularSearchOption} from "../data/search-option/content/ContentQueryPopularSearchOption";
import {ContentQueryPopularPreemptionId} from "../data/id/content/ContentQueryPopularPreemptionId";
import {
    ContentQueryPopularPreemptionPostDataTransferObject
} from "../data/data-transfer-object/content/ContentQueryPopularPreemptionPostDataTransferObject";
import {
    ContentQueryPopularPreemptionPutDataTransferObject
} from "../data/data-transfer-object/content/ContentQueryPopularPreemptionPutDataTransferObject";
import {
    ContentQueryPopularPreemptionValueObject
} from "../data/value-object/content/ContentQueryPopularPreemptionValueObject";
import {
    ContentQueryPopularPreemptionVerboseValueObject
} from "../data/value-object/content/ContentQueryPopularPreemptionVerboseValueObject";
import {
    ContentQueryPopularPreemptionSearchOption
} from "../data/search-option/content/ContentQueryPopularPreemptionSearchOption";
import {ContentDetailUrlClickId} from "../data/id/content/ContentDetailUrlClickId";
import {
    ContentDetailUrlClickPostDataTransferObject
} from "../data/data-transfer-object/content/ContentDetailUrlClickPostDataTransferObject";
import {ContentDetailUrlClickValueObject} from "../data/value-object/content/ContentDetailUrlClickValueObject";
import {
    ContentDetailUrlClickVerboseValueObject
} from "../data/value-object/content/ContentDetailUrlClickVerboseValueObject";
import {ContentDetailUrlClickSearchOption} from "../data/search-option/content/ContentDetailUrlClickSearchOption";
import {ContentResultUrlClickId} from "../data/id/content/ContentResultUrlClickId";
import {
    ContentResultUrlClickPostDataTransferObject
} from "../data/data-transfer-object/content/ContentResultUrlClickPostDataTransferObject";
import {ContentResultUrlClickValueObject} from "../data/value-object/content/ContentResultUrlClickValueObject";
import {
    ContentResultUrlClickVerboseValueObject
} from "../data/value-object/content/ContentResultUrlClickVerboseValueObject";
import {ContentResultUrlClickSearchOption} from "../data/search-option/content/ContentResultUrlClickSearchOption";
import {ContentUserProductionDurationId} from "../data/id/content/ContentUserProductionDurationId";
import {
    ContentUserProductionDurationPostDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionDurationPostDataTransferObject";
import {
    ContentUserProductionDurationPutDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionDurationPutDataTransferObject";
import {
    ContentUserProductionDurationValueObject
} from "../data/value-object/content/ContentUserProductionDurationValueObject";
import {
    ContentUserProductionDurationVerboseValueObject
} from "../data/value-object/content/ContentUserProductionDurationVerboseValueObject";
import {
    ContentUserProductionDurationSearchOption
} from "../data/search-option/content/ContentUserProductionDurationSearchOption";
import {
    ContentUserProductionDurationModificationId
} from "../data/id/content/ContentUserProductionDurationModificationId";
import {
    ContentUserProductionDurationModificationValueObject
} from "../data/value-object/content/ContentUserProductionDurationModificationValueObject";
import {
    ContentUserProductionDurationModificationVerboseValueObject
} from "../data/value-object/content/ContentUserProductionDurationModificationVerboseValueObject";
import {
    ContentUserProductionDurationModificationSearchOption
} from "../data/search-option/content/ContentUserProductionDurationModificationSearchOption";
import {ContentUserProductionParticipantId} from "../data/id/content/ContentUserProductionParticipantId";
import {
    ContentUserProductionParticipantPostDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionParticipantPostDataTransferObject";
import {
    ContentUserProductionParticipantPutDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionParticipantPutDataTransferObject";
import {
    ContentUserProductionParticipantValueObject
} from "../data/value-object/content/ContentUserProductionParticipantValueObject";
import {
    ContentUserProductionParticipantVerboseValueObject
} from "../data/value-object/content/ContentUserProductionParticipantVerboseValueObject";
import {
    ContentUserProductionParticipantSearchOption
} from "../data/search-option/content/ContentUserProductionParticipantSearchOption";
import {
    ContentUserProductionParticipantModificationId
} from "../data/id/content/ContentUserProductionParticipantModificationId";
import {
    ContentUserProductionParticipantModificationValueObject
} from "../data/value-object/content/ContentUserProductionParticipantModificationValueObject";
import {
    ContentUserProductionParticipantModificationVerboseValueObject
} from "../data/value-object/content/ContentUserProductionParticipantModificationVerboseValueObject";
import {
    ContentUserProductionParticipantModificationSearchOption
} from "../data/search-option/content/ContentUserProductionParticipantModificationSearchOption";
import {
    ContentUserProductionRewardModificationValueObject
} from "../data/value-object/content/ContentUserProductionRewardModificationValueObject";
import {ContentUserProductionRewardId} from "../data/id/content/ContentUserProductionRewardId";
import {
    ContentUserProductionRewardPostDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionRewardPostDataTransferObject";
import {
    ContentUserProductionRewardPutDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionRewardPutDataTransferObject";
import {
    ContentUserProductionRewardValueObject
} from "../data/value-object/content/ContentUserProductionRewardValueObject";
import {
    ContentUserProductionRewardVerboseValueObject
} from "../data/value-object/content/ContentUserProductionRewardVerboseValueObject";
import {
    ContentUserProductionRewardSearchOption
} from "../data/search-option/content/ContentUserProductionRewardSearchOption";
import {ContentUserProductionRewardModificationId} from "../data/id/content/ContentUserProductionRewardModificationId";
import {
    ContentUserProductionRewardModificationVerboseValueObject
} from "../data/value-object/content/ContentUserProductionRewardModificationVerboseValueObject";
import {
    ContentUserProductionRewardModificationSearchOption
} from "../data/search-option/content/ContentUserProductionRewardModificationSearchOption";
import {ContentDetailUrlId} from "../data/id/content/ContentDetailUrlId";
import {
    ContentDetailUrlPostDataTransferObject
} from "../data/data-transfer-object/content/ContentDetailUrlPostDataTransferObject";
import {
    ContentDetailUrlPutDataTransferObject
} from "../data/data-transfer-object/content/ContentDetailUrlPutDataTransferObject";
import {ContentDetailUrlValueObject} from "../data/value-object/content/ContentDetailUrlValueObject";
import {ContentDetailUrlVerboseValueObject} from "../data/value-object/content/ContentDetailUrlVerboseValueObject";
import {ContentDetailUrlSearchOption} from "../data/search-option/content/ContentDetailUrlSearchOption";
import {ContentResultUrlId} from "../data/id/content/ContentResultUrlId";
import {
    ContentResultUrlPostDataTransferObject
} from "../data/data-transfer-object/content/ContentResultUrlPostDataTransferObject";
import {
    ContentResultUrlPutDataTransferObject
} from "../data/data-transfer-object/content/ContentResultUrlPutDataTransferObject";
import {ContentResultUrlValueObject} from "../data/value-object/content/ContentResultUrlValueObject";
import {ContentResultUrlVerboseValueObject} from "../data/value-object/content/ContentResultUrlVerboseValueObject";
import {ContentResultUrlSearchOption} from "../data/search-option/content/ContentResultUrlSearchOption";
import {ContentLikeId} from "../data/id/content/ContentLikeId";
import {
    ContentLikePostDataTransferObject
} from "../data/data-transfer-object/content/ContentLikePostDataTransferObject";
import {ContentLikeValueObject} from "../data/value-object/content/ContentLikeValueObject";
import {ContentLikeVerboseValueObject} from "../data/value-object/content/ContentLikeVerboseValueObject";
import {ContentLikeSearchOption} from "../data/search-option/content/ContentLikeSearchOption";
import {ContentUserProductionScriptId} from "../data/id/content/ContentUserProductionScriptId";
import {
    ContentUserProductionScriptPostDataTransferObject
} from "../data/data-transfer-object/content/ContentUserProductionScriptPostDataTransferObject";
import {
    ContentUserProductionScriptValueObject
} from "../data/value-object/content/ContentUserProductionScriptValueObject";
import {
    ContentUserProductionScriptVerboseValueObject
} from "../data/value-object/content/ContentUserProductionScriptVerboseValueObject";
import {
    ContentUserProductionScriptSearchOption
} from "../data/search-option/content/ContentUserProductionScriptSearchOption";

export const ContentCommentLikeIO = new BaseHttpClient<
    ContentCommentLikeId,
    ContentCommentLikePostDataTransferObject,
    never,
    ContentCommentLikeValueObject,
    ContentCommentLikeVerboseValueObject,
    ContentCommentLikeSearchOption
>(
    '/contents/comments/likes',
    o => new ContentCommentLikeValueObject(o),
    o => new ContentCommentLikeVerboseValueObject(o)
)

export const ContentCommentModificationIO = new BaseHttpClient<
    ContentCommentModificationId,
    never,
    never,
    ContentCommentModificationValueObject,
    ContentCommentModificationVerboseValueObject,
    ContentCommentModificationSearchOption
>(
    '/contents/comments/modifications',
    o => new ContentCommentModificationValueObject(o),
    o => new ContentCommentModificationVerboseValueObject(o)
)

export const ContentCommentReportReasonModificationIO = new BaseHttpClient<
    ContentCommentReportReasonModificationId,
    never,
    never,
    ContentCommentReportReasonModificationValueObject,
    ContentCommentReportReasonModificationVerboseValueObject,
    ContentCommentReportReasonModificationSearchOption
>(
    '/contents/comments/reports/reasons/modifications',
    o => new ContentCommentReportReasonModificationValueObject(o),
    o => new ContentCommentReportReasonModificationVerboseValueObject(o)
)

export const ContentCommentReportReasonIO = new BaseHttpClient<
    ContentCommentReportReasonId,
    ContentCommentReportReasonPostDataTransferObject,
    ContentCommentReportReasonPutDataTransferObject,
    ContentCommentReportReasonValueObject,
    ContentCommentReportReasonVerboseValueObject,
    ContentCommentReportReasonSearchOption
>(
    '/contents/comments/reports/reasons',
    o => new ContentCommentReportReasonValueObject(o),
    o => new ContentCommentReportReasonVerboseValueObject(o)
)

export const ContentCommentReportIO = new BaseHttpClient<
    ContentCommentReportId,
    ContentCommentReportPostDataTransferObject,
    never,
    ContentCommentReportValueObject,
    ContentCommentReportVerboseValueObject,
    ContentCommentReportSearchOption
>(
    '/contents/comments/reports',
    o => new ContentCommentReportValueObject(o),
    o => new ContentCommentReportVerboseValueObject(o)
)

export const ContentCommentIO = new BaseHttpClient<
    ContentCommentId,
    ContentCommentPostDataTransferObject,
    ContentCommentPutDataTransferObject,
    ContentCommentValueObject,
    ContentCommentVerboseValueObject,
    ContentCommentSearchOption
>(
    '/contents/comments',
    o => new ContentCommentValueObject(o),
    o => new ContentCommentVerboseValueObject(o)
)

export const ContentDetailUrlIO = new BaseHttpClient<
    ContentDetailUrlId,
    ContentDetailUrlPostDataTransferObject,
    ContentDetailUrlPutDataTransferObject,
    ContentDetailUrlValueObject,
    ContentDetailUrlVerboseValueObject,
    ContentDetailUrlSearchOption
>(
    '/contents/detailUrls',
    o => new ContentDetailUrlValueObject(o),
    o => new ContentDetailUrlVerboseValueObject(o)
)

export const ContentDetailUrlClickIO = new BaseHttpClient<
    ContentDetailUrlClickId,
    never,
    never,
    ContentDetailUrlClickValueObject,
    ContentDetailUrlClickVerboseValueObject,
    ContentDetailUrlClickSearchOption
>(
    '/contents/detailUrls/clicks',
    o => new ContentDetailUrlClickValueObject(o),
    o => new ContentDetailUrlClickVerboseValueObject(o)
)

export const ContentLikeIO = new BaseHttpClient<
    ContentLikeId,
    ContentLikePostDataTransferObject,
    never,
    ContentLikeValueObject,
    ContentLikeVerboseValueObject,
    ContentLikeSearchOption
>(
    '/contents/likes',
    o => new ContentLikeValueObject(o),
    o => new ContentLikeVerboseValueObject(o)
)

export const ContentModificationIO = new BaseHttpClient<
    ContentModificationId,
    never,
    never,
    ContentModificationValueObject,
    ContentModificationVerboseValueObject,
    ContentModificationSearchOption
>(
    '/contents/modifications',
    o => new ContentModificationValueObject(o),
    o => new ContentModificationVerboseValueObject(o)
)

export const ContentParticipationIO = new BaseHttpClient<
    ContentParticipationId,
    ContentParticipationPostDataTransferObject,
    never,
    ContentParticipationValueObject,
    ContentParticipationVerboseValueObject,
    ContentParticipationSearchOption
>(
    '/contents/participations',
    o => new ContentParticipationValueObject(o),
    o => new ContentParticipationVerboseValueObject(o)
)

export const ContentPolicyModificationIO = new BaseHttpClient<
    ContentPolicyModificationId,
    never,
    never,
    ContentPolicyModificationValueObject,
    ContentPolicyModificationVerboseValueObject,
    ContentPolicyModificationSearchOption
>(
    '/contents/policies/modifications',
    o => new ContentPolicyModificationValueObject(o),
    o => new ContentPolicyModificationVerboseValueObject(o)
)

export const ContentPolicyIO = new BaseHttpClient<
    ContentPolicyId,
    ContentPolicyPostDataTransferObject,
    ContentPolicyPutDataTransferObject,
    ContentPolicyValueObject,
    ContentPolicyVerboseValueObject,
    ContentPolicySearchOption
>(
    '/contents/policies',
    o => new ContentPolicyValueObject(o),
    o => new ContentPolicyVerboseValueObject(o)
)

export const ContentQueryIO = new BaseHttpClient<
    ContentQueryId,
    never,
    never,
    ContentQueryValueObject,
    ContentQueryVerboseValueObject,
    ContentQuerySearchOption
>(
    '/contents/queries',
    o => new ContentQueryValueObject(o),
    o => new ContentQueryVerboseValueObject(o)
)

export const ContentQueryPopularIO = new BaseHttpClient<
    ContentQueryPopularId,
    never,
    never,
    ContentQueryPopularValueObject,
    ContentQueryPopularVerboseValueObject,
    ContentQueryPopularSearchOption
>(
    '/contents/queries/populars',
    o => new ContentQueryPopularValueObject(o),
    o => new ContentQueryPopularVerboseValueObject(o)
)

export const ContentQueryPopularPreemptionIO = new BaseHttpClient<
    ContentQueryPopularPreemptionId,
    ContentQueryPopularPreemptionPostDataTransferObject,
    ContentQueryPopularPreemptionPutDataTransferObject,
    ContentQueryPopularPreemptionValueObject,
    ContentQueryPopularPreemptionVerboseValueObject,
    ContentQueryPopularPreemptionSearchOption
>(
    '/contents/queries/populars/preemptions',
    o => new ContentQueryPopularPreemptionValueObject(o),
    o => new ContentQueryPopularPreemptionVerboseValueObject(o)
)

export const ContentReportReasonModificationIO = new BaseHttpClient<
    ContentReportReasonModificationId,
    never,
    never,
    ContentReportReasonModificationValueObject,
    ContentReportReasonModificationVerboseValueObject,
    ContentReportReasonModificationSearchOption
>(
    '/contents/reports/reasons/modifications',
    o => new ContentReportReasonModificationValueObject(o),
    o => new ContentReportReasonModificationVerboseValueObject(o)
)

export const ContentReportReasonIO = new BaseHttpClient<
    ContentReportReasonId,
    ContentReportReasonPostDataTransferObject,
    ContentReportReasonPutDataTransferObject,
    ContentReportReasonValueObject,
    ContentReportReasonVerboseValueObject,
    ContentReportReasonSearchOption
>(
    '/contents/reports/reasons',
    o => new ContentReportReasonValueObject(o),
    o => new ContentReportReasonVerboseValueObject(o)
)

export const ContentReportIO = new BaseHttpClient<
    ContentReportId,
    ContentReportPostDataTransferObject,
    never,
    ContentReportValueObject,
    ContentReportVerboseValueObject,
    ContentReportSearchOption
>(
    '/contents/reports',
    o => new ContentReportValueObject(o),
    o => new ContentReportVerboseValueObject(o)
)

export const ContentResultUrlIO = new BaseHttpClient<
    ContentResultUrlId,
    ContentResultUrlPostDataTransferObject,
    ContentResultUrlPutDataTransferObject,
    ContentResultUrlValueObject,
    ContentResultUrlVerboseValueObject,
    ContentResultUrlSearchOption
>(
    '/contents/resultUrls',
    o => new ContentResultUrlValueObject(o),
    o => new ContentResultUrlVerboseValueObject(o)
)

export const ContentResultUrlClickIO = new BaseHttpClient<
    ContentResultUrlClickId,
    never,
    never,
    ContentResultUrlClickValueObject,
    ContentResultUrlClickVerboseValueObject,
    ContentResultUrlClickSearchOption
>(
    '/contents/resultUrls/clicks',
    o => new ContentResultUrlClickValueObject(o),
    o => new ContentResultUrlClickVerboseValueObject(o)
)

export const ContentReviewIO = new BaseHttpClient<
    ContentReviewId,
    ContentReviewPostDataTransferObject,
    never,
    ContentReviewValueObject,
    ContentReviewVerboseValueObject,
    ContentReviewSearchOption
>(
    '/contents/reviews',
    o => new ContentReviewValueObject(o),
    o => new ContentReviewVerboseValueObject(o)
)

export const ContentIO = new BaseHttpClient<
    ContentId,
    ContentPostDataTransferObject,
    ContentPutDataTransferObject,
    ContentValueObject,
    ContentVerboseValueObject,
    ContentSearchOption
>(
    '/contents',
    o => new ContentValueObject(o),
    o => new ContentVerboseValueObject(o)
)

export const ContentTargetModificationIO = new BaseHttpClient<
    ContentTargetModificationId,
    never,
    never,
    ContentTargetModificationValueObject,
    ContentTargetModificationVerboseValueObject,
    ContentTargetModificationSearchOption
>(
    '/contents/targets/modifications',
    o => new ContentTargetModificationValueObject(o),
    o => new ContentTargetModificationVerboseValueObject(o)
)

export const ContentTargetIO = new BaseHttpClient<
    ContentTargetId,
    ContentTargetPostDataTransferObject,
    ContentTargetPutDataTransferObject,
    ContentTargetValueObject,
    ContentTargetVerboseValueObject,
    ContentTargetSearchOption
>(
    '/contents/targets',
    o => new ContentTargetValueObject(o),
    o => new ContentTargetVerboseValueObject(o)
)

export const ContentUserProductionDurationIO = new BaseHttpClient<
    ContentUserProductionDurationId,
    ContentUserProductionDurationPostDataTransferObject,
    ContentUserProductionDurationPutDataTransferObject,
    ContentUserProductionDurationValueObject,
    ContentUserProductionDurationVerboseValueObject,
    ContentUserProductionDurationSearchOption
>(
    '/contents/userProduction/durations',
    o => new ContentUserProductionDurationValueObject(o),
    o => new ContentUserProductionDurationVerboseValueObject(o)
)

export const ContentUserProductionDurationModificationIO = new BaseHttpClient<
    ContentUserProductionDurationModificationId,
    never,
    never,
    ContentUserProductionDurationModificationValueObject,
    ContentUserProductionDurationModificationVerboseValueObject,
    ContentUserProductionDurationModificationSearchOption
>(
    '/contents/userProduction/durations/modifications',
    o => new ContentUserProductionDurationModificationValueObject(o),
    o => new ContentUserProductionDurationModificationVerboseValueObject(o)
)

export const ContentUserProductionParticipantIO = new BaseHttpClient<
    ContentUserProductionParticipantId,
    ContentUserProductionParticipantPostDataTransferObject,
    ContentUserProductionParticipantPutDataTransferObject,
    ContentUserProductionParticipantValueObject,
    ContentUserProductionParticipantVerboseValueObject,
    ContentUserProductionParticipantSearchOption
>(
    '/contents/userProduction/participants',
    o => new ContentUserProductionParticipantValueObject(o),
    o => new ContentUserProductionParticipantVerboseValueObject(o)
)

export const ContentUserProductionParticipantModificationIO = new BaseHttpClient<
    ContentUserProductionParticipantModificationId,
    never,
    never,
    ContentUserProductionParticipantModificationValueObject,
    ContentUserProductionParticipantModificationVerboseValueObject,
    ContentUserProductionParticipantModificationSearchOption
>(
    '/contents/userProduction/participants/modifications',
    o => new ContentUserProductionParticipantModificationValueObject(o),
    o => new ContentUserProductionParticipantModificationVerboseValueObject(o)
)

export const ContentUserProductionRewardIO = new BaseHttpClient<
    ContentUserProductionRewardId,
    ContentUserProductionRewardPostDataTransferObject,
    ContentUserProductionRewardPutDataTransferObject,
    ContentUserProductionRewardValueObject,
    ContentUserProductionRewardVerboseValueObject,
    ContentUserProductionRewardSearchOption
>(
    '/contents/userProduction/rewards',
    o => new ContentUserProductionRewardValueObject(o),
    o => new ContentUserProductionRewardVerboseValueObject(o)
)

export const ContentUserProductionRewardModificationIO = new BaseHttpClient<
    ContentUserProductionRewardModificationId,
    never,
    never,
    ContentUserProductionRewardModificationValueObject,
    ContentUserProductionRewardModificationVerboseValueObject,
    ContentUserProductionRewardModificationSearchOption
>(
    '/contents/userProduction/rewards/modifications',
    o => new ContentUserProductionRewardModificationValueObject(o),
    o => new ContentUserProductionRewardModificationVerboseValueObject(o)
)

export const ContentUserProductionScriptIO = new BaseHttpClient<
    ContentUserProductionScriptId,
    ContentUserProductionScriptPostDataTransferObject,
    never,
    ContentUserProductionScriptValueObject,
    ContentUserProductionScriptVerboseValueObject,
    ContentUserProductionScriptSearchOption
>(
    '/contents/userProduction/scripts',
    o => new ContentUserProductionScriptValueObject(o),
    o => new ContentUserProductionScriptVerboseValueObject(o)
)