import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {UserPolicyValueObject} from "../../../data/value-object/user/UserPolicyValueObject";
import {useEffect} from "react";
import {UserPolicyIO} from "../../../io/UserIO";
import {Body1, Button, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {DeleteDialog} from "../../DetailViews";
import {UserPolicyDomain} from "../../../data/domain/user/UserPolicyDomain";
import {UserPolicyDescriptor} from "../../../data/descriptor/user/UserPolicyDescriptor";
import {mapByKey} from "../../../util/ObjectUtil";
import {UserPolicyDescription} from "../../../data/description/user/UserPolicyDescription";

enum Panel {
    Default,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userPolicyId: string }>()
    const item = useStatePair<UserPolicyValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userPolicyId !== undefined) {
            UserPolicyIO
                .getById(BigInt(params.userPolicyId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserPolicyValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    const onDeleteClick = () => {
        isDeleting.setTrue()
        UserPolicyIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/users/policies'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
            </TabList>
            <DefaultPanel
                {...props}
                panel={panel.value}>
                <div className={props.styles.row8}>
                    <Button onClick={() => document.location = `/users/policies/${props.item.id}/edit`}>수정</Button>
                    <Button onClick={isDeleteDialogVisible.setTrue}>삭제</Button>
                </div>
            </DefaultPanel>
        </div>
        <DeleteDialog
            {...props}
            visible={isDeleteDialogVisible.value}
            disabled={isDeleting.value}
            onConfirmClick={onDeleteClick}
            onCancelClick={() => isDeleteDialogVisible.setFalse()}/>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserPolicyDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "recommenderRewardThreshold":
        case "userInquiryPageSize":
        case "id":
        case "signUpSolarAmount":
        case "signUpLunarAmount":
        case "signUpStarAmount":
        case "recommenderSolarAmount":
        case "recommenderLunarAmount":
        case "recommenderStarAmount":
        case "recommendeeSolarAmount":
        case "recommendeeLunarAmount":
        case "recommendeeStarAmount":
        case "userPageSize":
        case "userAgreementPageSize":
        case "userAuthenticationCodePageSize":
        case "userAuthenticationCodeExpireSeconds":
        case "userAuthenticationCodeThresholdMinutes":
        case "userAuthenticationCodeThresholdCount":
        case "userBlockPageSize":
        case "userBlockReasonPageSize":
        case "userExpPageSize":
        case "userLevelPageSize":
        case "userLevelHistoryPageSize":
        case "userNotificationPageSize":
        case "userPolicyPageSize":
        case "userRankPageSize":
        case "userRankCacheSchedulerDurationMinutes":
        case "userRankCacheRefreshDurationMinutes":
        case "userRankDuration":
        case "userRankHistoryPageSize":
        case "userSessionPageSize":
        case "userSessionMaxAgeSeconds":
        case "userSignInPageSize":
        case "userWithdrawalPageSize":
        case "userWithdrawalReasonPageSize":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{UserPolicyDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserPolicyDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(UserPolicyDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{UserPolicyDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}