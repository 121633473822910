import {RouterProps} from "../../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentQueryPopularPreemptionVerboseValueObject
} from "../../../../../data/value-object/content/ContentQueryPopularPreemptionVerboseValueObject";
import {useCallback, useEffect} from "react";
import {ContentQueryPopularPreemptionIO} from "../../../../../io/ContentIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField} from "../../../../PostField";
import {ContentType} from "../../../../../data/constant/content/ContentType";
import {
    ContentQueryPopularPreemptionDescription
} from "../../../../../data/description/content/ContentQueryPopularPreemptionDescription";
import {DateFormat} from "ts-format-extension";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ contentQueryPopularPreemptionId: string }>()
    const item = useStatePair<ContentQueryPopularPreemptionVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const contentType = useStatePair<ContentType>(ContentType.Repeat)
    const query = useStatePair('')
    const exposedAt = useStatePair('')
    const exposedUntil = useStatePair('')
    const displayOrder = useStatePair(0)

    useEffect(() => {
        if (item.value === undefined && params.contentQueryPopularPreemptionId !== undefined) {
            ContentQueryPopularPreemptionIO
                .getVerboseById(BigInt(params.contentQueryPopularPreemptionId))
                .then(value => {
                    item.setter(value)
                    contentType.setter(value.contentType)
                    query.setter(value.query)
                    exposedAt.setter(DateFormat.tableDateTime(value.exposedAt))
                    exposedUntil.setter(DateFormat.tableDateTime(value.exposedUntil))
                    displayOrder.setter(value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        ContentQueryPopularPreemptionIO
            .put(id, {
                contentType: contentType.value,
                query: query.value,
                exposedAt: new Date(exposedAt.value),
                exposedUntil: new Date(exposedUntil.value),
                displayOrder: displayOrder.value,
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/contents/queries/populars/preemptions/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [contentType.value, query.value, exposedAt.value, exposedUntil.value, displayOrder.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={ContentQueryPopularPreemptionDescription.contentType}>
                <PostEnumField
                    {...props}
                    value={contentType.value}
                    setter={contentType.setter}
                    selections={ContentType.selections} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={ContentQueryPopularPreemptionDescription.query}>
                <PostInputField
                    {...props}
                    value={query.value}
                    setter={query.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={ContentQueryPopularPreemptionDescription.exposedAt}>
                <PostDateField
                    {...props}
                    value={exposedAt.value}
                    setter={exposedAt.setter}
                    maxLength={19} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={ContentQueryPopularPreemptionDescription.exposedUntil}>
                <PostDateField
                    {...props}
                    value={exposedUntil.value}
                    setter={exposedUntil.setter}
                    maxLength={19} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={ContentQueryPopularPreemptionDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}