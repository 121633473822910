import {EnvConstructionModificationDomain} from "../../domain/env/EnvConstructionModificationDomain"

export const EnvConstructionModificationDescriptor: Record<keyof EnvConstructionModificationDomain, (o: EnvConstructionModificationDomain) => string> = {
    id(o: EnvConstructionModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvConstructionModificationDomain): string {
        return o.creatorId.toString()
    },
    envConstructionId(o: EnvConstructionModificationDomain): string {
        return o.envConstructionId.toString()
    },
    title(o: EnvConstructionModificationDomain): string {
        return o.title
    },
    body(o: EnvConstructionModificationDomain): string {
        return o.body
    },
    startAt(o: EnvConstructionModificationDomain): string {
        return o.startAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    endAt(o: EnvConstructionModificationDomain): string {
        return o.endAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    createdAt(o: EnvConstructionModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
