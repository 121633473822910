import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentDetailUrlDomain} from "../../domain/content/ContentDetailUrlDomain"

export const ContentDetailUrlDescriptor: Record<keyof ContentDetailUrlDomain, (o: ContentDetailUrlDomain) => string> = {
    id(o: ContentDetailUrlDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentDetailUrlDomain): string {
        return o.creatorId.toString()
    },
    browser(o: ContentDetailUrlDomain): string {
        return EnvBrowser.description(o.browser)
    },
    url(o: ContentDetailUrlDomain): string {
        return o.url
    },
    clickCount(o: ContentDetailUrlDomain): string {
        return o.clickCount.toString()
    },
    createdAt(o: ContentDetailUrlDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentDetailUrlDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: ContentDetailUrlDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
