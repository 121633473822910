import {ContentQueryDomain} from "../../domain/content/ContentQueryDomain"

/**
 * 콘텐츠 검색 속성 설명
 */
export const ContentQueryDescription: Record<keyof ContentQueryDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    contentType: '콘텐츠 유형',
    body: '질의어',
    createdAt: '생성일',
    concealedAt: '삭제일'
}
