import {UserRankHistoryDomain} from "../../domain/user/UserRankHistoryDomain"

export const UserRankHistoryDescriptor: Record<keyof UserRankHistoryDomain, (o: UserRankHistoryDomain) => string> = {
    id(o: UserRankHistoryDomain): string {
        return o.id.toString()
    },
    userId(o: UserRankHistoryDomain): string {
        return o.userId.toString()
    },
    userRankId(o: UserRankHistoryDomain): string {
        return o.userRankId.toString()
    },
    assetLunarUsageId(o: UserRankHistoryDomain): string {
        return o.assetLunarUsageId?.toString() ?? '-'
    },
    assetSolarUsageId(o: UserRankHistoryDomain): string {
        return o.assetSolarUsageId?.toString() ?? '-'
    },
    assetStarUsageId(o: UserRankHistoryDomain): string {
        return o.assetStarUsageId?.toString() ?? '-'
    },
    createdAt(o: UserRankHistoryDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
