import {ContentParticipationDomain} from "../../domain/content/ContentParticipationDomain"
import {AssetSolarUsageId} from "../../id/asset/AssetSolarUsageId"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {ContentId} from "../../id/content/ContentId"
import {ContentParticipationId} from "../../id/content/ContentParticipationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 참여
 */
export class ContentParticipationValueObject implements ValueObject, ContentParticipationDomain {
    /**
     * 기본키
     */
    id: ContentParticipationId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 솔라 지급 ID
     */
    assetSolarUsageId?: AssetSolarUsageId
    /**
     * 스타 사용 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 오디오 ID
     */
    audioId?: bigint
    /**
     * 인식된 텍스트
     */
    transcription: string
    /**
     * 일치율(%)
     */
    accuracy: number
    /**
     * 합격 여부
     */
    isPassed: boolean
    /**
     * 높은 일치율 여부
     */
    isHighAccuracy: boolean
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentParticipationDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentId = BigInt(o.contentId)
        if (o.assetSolarUsageId !== undefined && o.assetSolarUsageId !== null) {
            this.assetSolarUsageId = BigInt(o.assetSolarUsageId)
        }
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        if (o.audioId !== undefined && o.audioId !== null) {
            this.audioId = BigInt(o.audioId)
        }
        this.transcription = String(o.transcription)
        this.accuracy = Number(o.accuracy)
        this.isPassed = Boolean(o.isPassed)
        this.isHighAccuracy = Boolean(o.isHighAccuracy)
        this.createdAt = new Date(o.createdAt)
    }
}
