import {ContentUserProductionDurationModificationDomain} from "../../domain/content/ContentUserProductionDurationModificationDomain"

export const ContentUserProductionDurationModificationDescriptor: Record<keyof ContentUserProductionDurationModificationDomain, (o: ContentUserProductionDurationModificationDomain) => string> = {
    id(o: ContentUserProductionDurationModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentUserProductionDurationModificationDomain): string {
        return o.creatorId.toString()
    },
    contentUserProductionDurationId(o: ContentUserProductionDurationModificationDomain): string {
        return o.contentUserProductionDurationId.toString()
    },
    value(o: ContentUserProductionDurationModificationDomain): string {
        return o.value.toString()
    },
    displayOrder(o: ContentUserProductionDurationModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: ContentUserProductionDurationModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
