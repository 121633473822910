import {Button, Field, Image, Input, makeStyles, Title3} from "@fluentui/react-components";
import {useStatePair} from "react-type-extension";
import {UserSignInIO} from "../io/UserIO";
import {detect} from "detect-browser";

const useStyles = makeStyles({
    page: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    headerText: {
        marginLeft: '16px'
    },
    fields: {
        marginTop: '16px'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: '16px'
    }
})

export default function SignInPage() {
    const styles = useStyles()
    const authenticationUid = useStatePair('')

    const onSignInClick = () => {
        if (authenticationUid.value.length === 0) {
            return
        }

        const browser = detect()
        const os = browser?.os ?? '<unknown os>'
        const deviceName = os + '/' + browser?.name ?? '<unknown browser>'
        const deviceVersion = browser?.version ?? '<unknown version>'
        UserSignInIO
            .post({
                deviceName: deviceName,
                deviceVersion: deviceVersion,
                authenticationUid: authenticationUid.value,
            })
            .then(() => document.location.reload())
            .catch(reason => {
                console.error(reason)
                alert('로그인을 실패했습니다.')
            })
    }

    return <>
        <div className={styles.page}>
            <div className={styles.header}>
                <Image src={'/logo.svg'} />
                <Title3 as={'h1'} className={styles.headerText}>TwoDo 관리자</Title3>
            </div>
            <div className={styles.fields}>
                <Field
                    label={'Authentication UID'}
                    orientation={'horizontal'}>
                    <Input
                        value={authenticationUid.value}
                        onChange={(ev, data) => authenticationUid.setter(data.value)}
                        type={'password'}
                        maxLength={28} />
                </Field>
            </div>
            <div className={styles.actions}>
                <Button
                    onClick={onSignInClick}
                    appearance={'primary'}>
                    로그인
                </Button>
            </div>
        </div>
    </>
}