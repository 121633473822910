import {ContentType} from "../../../data/constant/content/ContentType"
import {ContentQuerySearchOptionDescription} from "../../../data/description/content/ContentQuerySearchOptionDescription"
import {ContentQuerySearchOption} from "../../../data/search-option/content/ContentQuerySearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentQuerySearchOptionField(props: ListOptionFieldProps<ContentQuerySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTypeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.contentTypeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.contentTypeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.contentTypeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.contentTypeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "contentTypeIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.contentTypeIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "bodyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.bodyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.bodyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.bodyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.bodyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.bodyLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.bodyNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentQuerySearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentQuerySearchOption(key: keyof ContentQuerySearchOption, value: string, into: StatePair<ContentQuerySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "contentTypeEq":
            into.setter({
                ...into.value,
                contentTypeEq: value as ContentType
            })
            break
        case "contentTypeNeq":
            into.setter({
                ...into.value,
                contentTypeNeq: value as ContentType
            })
            break
        case "contentTypeMin":
            into.setter({
                ...into.value,
                contentTypeMin: value as ContentType
            })
            break
        case "contentTypeMax":
            into.setter({
                ...into.value,
                contentTypeMax: value as ContentType
            })
            break
        case "contentTypeIn":
            into.setter({
                ...into.value,
                contentTypeIn: value
                    .split('|')
                    .map(e => e as ContentType)
            })
            break
        case "bodyEq":
            into.setter({
                ...into.value,
                bodyEq: String(value)
            })
            break
        case "bodyNeq":
            into.setter({
                ...into.value,
                bodyNeq: String(value)
            })
            break
        case "bodyMin":
            into.setter({
                ...into.value,
                bodyMin: String(value)
            })
            break
        case "bodyMax":
            into.setter({
                ...into.value,
                bodyMax: String(value)
            })
            break
        case "bodyLike":
            into.setter({
                ...into.value,
                bodyLike: String(value)
            })
            break
        case "bodyNotLike":
            into.setter({
                ...into.value,
                bodyNotLike: String(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
