import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    UiPolicyValueObject
} from "../../../data/value-object/ui/UiPolicyValueObject";
import {useCallback, useEffect} from "react";
import {UiPolicyIO} from "../../../io/UiIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostNumberField} from "../../PostField";
import {
    UiPolicyDescription
} from "../../../data/description/ui/UiPolicyDescription";

enum Phase {
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ uiPolicyId: string }>()
    const item = useStatePair<UiPolicyValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Waiting)
    const uiHomeContentSuggestionPageSize = useStatePair(0)
    const uiHomeContentUserProductionPageSize = useStatePair(0)
    const uiHomeNoticePageSize = useStatePair(0)
    const uiOnboardingPageSize = useStatePair(0)
    const uiPolicyPageSize = useStatePair(0)

    useEffect(() => {
        if (params.uiPolicyId !== undefined) {
            UiPolicyIO
                .getVerboseById(BigInt(params.uiPolicyId))
                .then(value => {
                    item.setter(value)
                    uiHomeContentSuggestionPageSize.setter(value.uiHomeContentSuggestionPageSize)
                    uiHomeContentUserProductionPageSize.setter(value.uiHomeContentUserProductionPageSize)
                    uiHomeNoticePageSize.setter(value.uiHomeNoticePageSize)
                    uiOnboardingPageSize.setter(value.uiOnboardingPageSize)
                    uiPolicyPageSize.setter(value.uiPolicyPageSize)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UiPolicyIO
            .put(id, {
                uiHomeContentSuggestionPageSize: uiHomeContentSuggestionPageSize.value,
                uiHomeContentUserProductionPageSize: uiHomeContentUserProductionPageSize.value,
                uiHomeNoticePageSize: uiHomeNoticePageSize.value,
                uiOnboardingPageSize: uiOnboardingPageSize.value,
                uiPolicyPageSize: uiPolicyPageSize.value,
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/ui/policies/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [uiHomeContentSuggestionPageSize.value, uiHomeContentUserProductionPageSize.value, uiHomeNoticePageSize.value, uiOnboardingPageSize.value, uiPolicyPageSize.value,])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiHomeContentSuggestionPageSize}>
                <PostNumberField
                    {...props}
                    value={uiHomeContentSuggestionPageSize.value}
                    setter={uiHomeContentSuggestionPageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiHomeContentUserProductionPageSize}>
                <PostNumberField
                    {...props}
                    value={uiHomeContentUserProductionPageSize.value}
                    setter={uiHomeContentUserProductionPageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiHomeNoticePageSize}>
                <PostNumberField
                    {...props}
                    value={uiHomeNoticePageSize.value}
                    setter={uiHomeNoticePageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiOnboardingPageSize}>
                <PostNumberField
                    {...props}
                    value={uiOnboardingPageSize.value}
                    setter={uiOnboardingPageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={uiPolicyPageSize.value}
                    setter={uiPolicyPageSize.setter} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}