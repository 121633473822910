import {EnvNoticeModificationDomain} from "../../domain/env/EnvNoticeModificationDomain"
import {EnvNoticeId} from "../../id/env/EnvNoticeId"
import {EnvNoticeModificationId} from "../../id/env/EnvNoticeModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 공지사항 수정 이력
 */
export class EnvNoticeModificationValueObject implements ValueObject, EnvNoticeModificationDomain {
    /**
     * 기본키
     */
    id: EnvNoticeModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 공지사항 ID
     */
    envNoticeId: EnvNoticeId
    /**
     * 제목
     */
    title: string
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvNoticeModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envNoticeId = BigInt(o.envNoticeId)
        this.title = String(o.title)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
    }
}
