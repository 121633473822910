import {UserBlockReasonModificationVerboseDomain} from "../../domain/user/UserBlockReasonModificationVerboseDomain"
import {UserBlockReasonId} from "../../id/user/UserBlockReasonId"
import {UserBlockReasonModificationId} from "../../id/user/UserBlockReasonModificationId"
import {UserId} from "../../id/user/UserId"
import {UserBlockReasonValueObject} from "./UserBlockReasonValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 정지 사유 수정 이력 상세
 */
export class UserBlockReasonModificationVerboseValueObject implements VerboseValueObject, UserBlockReasonModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UserBlockReasonModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReasonId: UserBlockReasonId
    /**
     * 사유
     */
    body: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReason: UserBlockReasonValueObject

    constructor(o: Record<keyof UserBlockReasonModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userBlockReasonId = BigInt(o.userBlockReasonId)
        this.body = String(o.body)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.userBlockReason = new UserBlockReasonValueObject(o.userBlockReason)
    }
}
