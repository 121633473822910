import {ContentPutDataTransferObject} from "../../data-transfer-object/content/ContentPutDataTransferObject"

/**
 * 참여 가능 항목 속성 설명
 */
export const ContentPutDataTransferObjectDescription: Record<keyof ContentPutDataTransferObject, string> = {
    advertiserId: '광고주 ID',
    participationMethod: '참여 방법',
    title: '제목',
    titleHighlight: '제목 강조',
    body: '내용',
    script: '스크립트',
    scriptVisibility: '스크립트 표시 유형',
    gradingMessage: '채점 중 텍스트',
    amount: '보상 지급 수량',
    passAccuracy: '합격 기준(%)',
    passAccuracyHigh: '높은 일치율 기준(%)',
    passSolarAmount: '합격 지급 솔라',
    passSolarAmountHighAccuracy: '높은 일치율 지급 솔라',
    starAmount: '소모 스타',
    difficulty: '난이도',
    headerImageCount: '상단 이미지 수',
    descriptionImageCount: '설명 이미지 수',
    exposedAt: '노출 시작일',
    startAt: '참여 시작일',
    endAt: '참여 종료일',
    statusFlags: '상태 플래그',
    contentDetailUrl: '콘텐츠 더 알아보기 URL',
    contentDetailUrlBrowser: '콘텐츠 더 알아보기 URL 브라우저',
    contentResultUrl: '콘텐츠 참여 결과 URL',
    contentResultUrlBrowser: '콘텐츠 참여 결과 URL 브라우저'
}
