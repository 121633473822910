import {EnvPhoneBlockModificationVerboseDomain} from "../../domain/env/EnvPhoneBlockModificationVerboseDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {EnvPhoneBlockId} from "../../id/env/EnvPhoneBlockId"
import {EnvPhoneBlockModificationId} from "../../id/env/EnvPhoneBlockModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvCountryValueObject} from "./EnvCountryValueObject"
import {EnvPhoneBlockValueObject} from "./EnvPhoneBlockValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 전화번호 차단 수정 이력 상세
 */
export class EnvPhoneBlockModificationVerboseValueObject implements VerboseValueObject, EnvPhoneBlockModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvPhoneBlockModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 전화번호 차단 ID
     */
    envPhoneBlockId: EnvPhoneBlockId
    /**
     * 국가 ID
     */
    envCountryId: EnvCountryId
    /**
     * 전화번호
     */
    phone: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 전화번호 차단 ID
     */
    envPhoneBlock: EnvPhoneBlockValueObject
    /**
     * 국가 ID
     */
    envCountry: EnvCountryValueObject

    constructor(o: Record<keyof EnvPhoneBlockModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envPhoneBlockId = BigInt(o.envPhoneBlockId)
        this.envCountryId = BigInt(o.envCountryId)
        this.phone = String(o.phone)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envPhoneBlock = new EnvPhoneBlockValueObject(o.envPhoneBlock)
        this.envCountry = new EnvCountryValueObject(o.envCountry)
    }
}
