import {AssetCouponPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetCouponPostDataTransferObjectDescription"
import {AssetCouponPostState} from "../../../data/data-transfer-object-state/asset/AssetCouponPostState"
import {AssetCouponPostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetCouponPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetCouponPostState
}

export const AssetCouponPostField: Record<Capitalize<keyof AssetCouponPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Code(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPostDataTransferObjectDescription.code}>
                <PostTextareaField
                    {...props}
                    value={props.state.code.value}
                    setter={props.state.code.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPostDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPostDataTransferObjectDescription.assetLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarAmount.value}
                    setter={props.state.assetLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    AssetSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPostDataTransferObjectDescription.assetSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.assetSolarAmount.value}
                    setter={props.state.assetSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPostDataTransferObjectDescription.assetStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarAmount.value}
                    setter={props.state.assetStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetCouponPostFields(props: PostFieldProps) {
    return <>
        <AssetCouponPostField.Code
            {...props}
            state={props.state} />
        <AssetCouponPostField.Amount
            {...props}
            state={props.state} />
        <AssetCouponPostField.AssetLunarAmount
            {...props}
            state={props.state} />
        <AssetCouponPostField.AssetSolarAmount
            {...props}
            state={props.state} />
        <AssetCouponPostField.AssetStarAmount
            {...props}
            state={props.state} />
    </>
}
