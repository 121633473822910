import {AssetPolicyDomain} from "../../domain/asset/AssetPolicyDomain"
import {AssetPolicyId} from "../../id/asset/AssetPolicyId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 재화 정책
 */
export class AssetPolicyValueObject implements ValueObject, AssetPolicyDomain {
    /**
     * 기본키
     */
    id: AssetPolicyId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 쿠폰 페이지 크기
     */
    assetCouponPageSize: number
    /**
     * 쿠폰 사용 페이지 크기
     */
    assetCouponUsagePageSize: number
    /**
     * 루나 페이지 크기
     */
    assetLunarUsagePageSize: number
    /**
     * 루나 교환 옵션 페이지 크기
     */
    assetLunarExchangeOptionPageSize: number
    /**
     * 루나 교환 경험치
     */
    assetLunarExchangeExp: number
    /**
     * 루나 구매 옵션 페이지 크기
     */
    assetLunarPurchaseOptionPageSize: number
    /**
     * 루나 구매 경험치
     */
    assetLunarPurchaseExp: number
    /**
     * 재화 정책 페이지 크기
     */
    assetPolicyPageSize: number
    /**
     * 솔라 페이지 크기
     */
    assetSolarUsagePageSize: number
    /**
     * 솔라 인출 페이지 크기
     */
    assetSolarWithdrawalPageSize: number
    /**
     * 솔라 인출 옵션 페이지 크기
     */
    assetSolarWithdrawalOptionPageSize: number
    /**
     * 솔라 인출 경험치
     */
    assetSolarWithdrawalExp: number
    /**
     * 스타 충전 페이지 크기
     */
    assetStarChargePageSize: number
    /**
     * 스타 페이지 크기
     */
    assetStarUsagePageSize: number
    /**
     * 스타 소모 경험치
     */
    assetStarUsageExp: number
    /**
     * 스타 교환 옵션 페이지 크기
     */
    assetStarExchangeOptionPageSize: number
    /**
     * 스타 교환 경험치
     */
    assetStarExchangeExp: number
    /**
     * 스타 충전 소요 시간
     */
    assetStarChargeMinutes: number
    /**
     * 스타 최대 충전
     */
    assetStarChargeMax: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date

    constructor(o: Record<keyof AssetPolicyDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetCouponPageSize = Number(o.assetCouponPageSize)
        this.assetCouponUsagePageSize = Number(o.assetCouponUsagePageSize)
        this.assetLunarUsagePageSize = Number(o.assetLunarUsagePageSize)
        this.assetLunarExchangeOptionPageSize = Number(o.assetLunarExchangeOptionPageSize)
        this.assetLunarExchangeExp = Number(o.assetLunarExchangeExp)
        this.assetLunarPurchaseOptionPageSize = Number(o.assetLunarPurchaseOptionPageSize)
        this.assetLunarPurchaseExp = Number(o.assetLunarPurchaseExp)
        this.assetPolicyPageSize = Number(o.assetPolicyPageSize)
        this.assetSolarUsagePageSize = Number(o.assetSolarUsagePageSize)
        this.assetSolarWithdrawalPageSize = Number(o.assetSolarWithdrawalPageSize)
        this.assetSolarWithdrawalOptionPageSize = Number(o.assetSolarWithdrawalOptionPageSize)
        this.assetSolarWithdrawalExp = Number(o.assetSolarWithdrawalExp)
        this.assetStarChargePageSize = Number(o.assetStarChargePageSize)
        this.assetStarUsagePageSize = Number(o.assetStarUsagePageSize)
        this.assetStarUsageExp = Number(o.assetStarUsageExp)
        this.assetStarExchangeOptionPageSize = Number(o.assetStarExchangeOptionPageSize)
        this.assetStarExchangeExp = Number(o.assetStarExchangeExp)
        this.assetStarChargeMinutes = Number(o.assetStarChargeMinutes)
        this.assetStarChargeMax = Number(o.assetStarChargeMax)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
    }
}
