import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserWithdrawalReasonValueObject} from "../../../../data/value-object/user/UserWithdrawalReasonValueObject";
import {UserWithdrawalReasonSearchOption} from "../../../../data/search-option/user/UserWithdrawalReasonSearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {UserWithdrawalReasonIO} from "../../../../io/UserIO";
import {UserWithdrawalReasonDescription} from "../../../../data/description/user/UserWithdrawalReasonDescription";
import {UserWithdrawalReasonDescriptor} from "../../../../data/descriptor/user/UserWithdrawalReasonDescriptor";
import ListOption from "../../../ListOption";
import {
    UserWithdrawalReasonSearchOptionDescription
} from "../../../../data/description/user/UserWithdrawalReasonSearchOptionDescription";
import {
    applyUserWithdrawalReasonSearchOption,
    UserWithdrawalReasonSearchOptionField
} from "../../../search-option-field/user/UserWithdrawalReasonSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserWithdrawalReasonValueObject[]>([])
    const option = useStatePair<UserWithdrawalReasonSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserWithdrawalReasonIO}
                items={items}
                option={option.value}
                index={index}
                description={UserWithdrawalReasonDescription}
                descriptor={UserWithdrawalReasonDescriptor}
                showPost
                onPostClick={() => document.location = '/users/withdrawals/reasons/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/withdrawals/reasons/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserWithdrawalReasonSearchOptionDescription}
                fields={UserWithdrawalReasonSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserWithdrawalReasonSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserWithdrawalReasonDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}