import {ContentReportReasonModificationDomain} from "../../domain/content/ContentReportReasonModificationDomain"

/**
 * 콘텐츠 신고 사유 수정 이력 속성 설명
 */
export const ContentReportReasonModificationDescription: Record<keyof ContentReportReasonModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    contentReportReasonId: '콘텐츠 신고 사유 ID',
    body: '내용',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
