import {ContentDetailUrlClickValueObject} from "../../../../data/value-object/content/ContentDetailUrlClickValueObject";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    ContentDetailUrlClickSearchOption
} from "../../../../data/search-option/content/ContentDetailUrlClickSearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {ContentDetailUrlClickIO} from "../../../../io/ContentIO";
import {ContentDetailUrlClickDescription} from "../../../../data/description/content/ContentDetailUrlClickDescription";
import {ContentDetailUrlClickDescriptor} from "../../../../data/descriptor/content/ContentDetailUrlClickDescriptor";
import ListOption from "../../../ListOption";
import {
    ContentDetailUrlClickSearchOptionDescription
} from "../../../../data/description/content/ContentDetailUrlClickSearchOptionDescription";
import {
    applyContentDetailUrlClickSearchOption,
    ContentDetailUrlClickSearchOptionField
} from "../../../search-option-field/content/ContentDetailUrlClickSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentDetailUrlClickValueObject[]>([])
    const option = useStatePair<ContentDetailUrlClickSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentDetailUrlClickIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentDetailUrlClickDescription}
                descriptor={ContentDetailUrlClickDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/detailUrls/clicks/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentDetailUrlClickSearchOptionDescription}
                fields={ContentDetailUrlClickSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentDetailUrlClickSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentDetailUrlClickDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}