import {UiHomeContentSuggestionModificationDomain} from "../../domain/ui/UiHomeContentSuggestionModificationDomain"
import {UiHomeContentSuggestionId} from "../../id/ui/UiHomeContentSuggestionId"
import {UiHomeContentSuggestionModificationId} from "../../id/ui/UiHomeContentSuggestionModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 홈 추천 콘텐츠 수정 이력
 */
export class UiHomeContentSuggestionModificationValueObject implements ValueObject, UiHomeContentSuggestionModificationDomain {
    /**
     * 기본키
     */
    id: UiHomeContentSuggestionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 홈 추천 콘텐츠 ID
     */
    uiHomeContentSuggestionId: UiHomeContentSuggestionId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UiHomeContentSuggestionModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiHomeContentSuggestionId = BigInt(o.uiHomeContentSuggestionId)
        this.displayOrder = Number(o.displayOrder)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
    }
}
