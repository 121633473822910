import ListPageWrapper from "../../ListPageWrapper";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentResultUrlValueObject} from "../../../data/value-object/content/ContentResultUrlValueObject";
import {ContentResultUrlSearchOption} from "../../../data/search-option/content/ContentResultUrlSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import {ContentResultUrlIO} from "../../../io/ContentIO";
import {ContentResultUrlDescription} from "../../../data/description/content/ContentResultUrlDescription";
import {ContentResultUrlDescriptor} from "../../../data/descriptor/content/ContentResultUrlDescriptor";
import ListOption from "../../ListOption";
import {
    ContentResultUrlSearchOptionDescription
} from "../../../data/description/content/ContentResultUrlSearchOptionDescription";
import {
    applyContentResultUrlSearchOption,
    ContentResultUrlSearchOptionField
} from "../../search-option-field/content/ContentResultUrlSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentResultUrlValueObject[]>([])
    const option = useStatePair<ContentResultUrlSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentResultUrlIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentResultUrlDescription}
                descriptor={ContentResultUrlDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/resultUrls/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentResultUrlSearchOptionDescription}
                fields={ContentResultUrlSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentResultUrlSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentResultUrlDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}