import {AssetPolicySearchOption} from "../../search-option/asset/AssetPolicySearchOption"

/**
 * 재화 정책 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const AssetPolicySearchOptionDescription: Record<keyof AssetPolicySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    assetCouponPageSizeEq: '쿠폰 페이지 크기 일치',
    assetCouponPageSizeNeq: '쿠폰 페이지 크기 불일치',
    assetCouponPageSizeMin: '쿠폰 페이지 크기 최소',
    assetCouponPageSizeMax: '쿠폰 페이지 크기 최대',
    assetCouponUsagePageSizeEq: '쿠폰 사용 페이지 크기 일치',
    assetCouponUsagePageSizeNeq: '쿠폰 사용 페이지 크기 불일치',
    assetCouponUsagePageSizeMin: '쿠폰 사용 페이지 크기 최소',
    assetCouponUsagePageSizeMax: '쿠폰 사용 페이지 크기 최대',
    assetLunarUsagePageSizeEq: '루나 페이지 크기 일치',
    assetLunarUsagePageSizeNeq: '루나 페이지 크기 불일치',
    assetLunarUsagePageSizeMin: '루나 페이지 크기 최소',
    assetLunarUsagePageSizeMax: '루나 페이지 크기 최대',
    assetLunarExchangeOptionPageSizeEq: '루나 교환 옵션 페이지 크기 일치',
    assetLunarExchangeOptionPageSizeNeq: '루나 교환 옵션 페이지 크기 불일치',
    assetLunarExchangeOptionPageSizeMin: '루나 교환 옵션 페이지 크기 최소',
    assetLunarExchangeOptionPageSizeMax: '루나 교환 옵션 페이지 크기 최대',
    assetLunarExchangeExpEq: '루나 교환 경험치 일치',
    assetLunarExchangeExpNeq: '루나 교환 경험치 불일치',
    assetLunarExchangeExpMin: '루나 교환 경험치 최소',
    assetLunarExchangeExpMax: '루나 교환 경험치 최대',
    assetLunarPurchaseOptionPageSizeEq: '루나 구매 옵션 페이지 크기 일치',
    assetLunarPurchaseOptionPageSizeNeq: '루나 구매 옵션 페이지 크기 불일치',
    assetLunarPurchaseOptionPageSizeMin: '루나 구매 옵션 페이지 크기 최소',
    assetLunarPurchaseOptionPageSizeMax: '루나 구매 옵션 페이지 크기 최대',
    assetLunarPurchaseExpEq: '루나 구매 경험치 일치',
    assetLunarPurchaseExpNeq: '루나 구매 경험치 불일치',
    assetLunarPurchaseExpMin: '루나 구매 경험치 최소',
    assetLunarPurchaseExpMax: '루나 구매 경험치 최대',
    assetPolicyPageSizeEq: '재화 정책 페이지 크기 일치',
    assetPolicyPageSizeNeq: '재화 정책 페이지 크기 불일치',
    assetPolicyPageSizeMin: '재화 정책 페이지 크기 최소',
    assetPolicyPageSizeMax: '재화 정책 페이지 크기 최대',
    assetSolarUsagePageSizeEq: '솔라 페이지 크기 일치',
    assetSolarUsagePageSizeNeq: '솔라 페이지 크기 불일치',
    assetSolarUsagePageSizeMin: '솔라 페이지 크기 최소',
    assetSolarUsagePageSizeMax: '솔라 페이지 크기 최대',
    assetSolarWithdrawalPageSizeEq: '솔라 인출 페이지 크기 일치',
    assetSolarWithdrawalPageSizeNeq: '솔라 인출 페이지 크기 불일치',
    assetSolarWithdrawalPageSizeMin: '솔라 인출 페이지 크기 최소',
    assetSolarWithdrawalPageSizeMax: '솔라 인출 페이지 크기 최대',
    assetSolarWithdrawalOptionPageSizeEq: '솔라 인출 옵션 페이지 크기 일치',
    assetSolarWithdrawalOptionPageSizeNeq: '솔라 인출 옵션 페이지 크기 불일치',
    assetSolarWithdrawalOptionPageSizeMin: '솔라 인출 옵션 페이지 크기 최소',
    assetSolarWithdrawalOptionPageSizeMax: '솔라 인출 옵션 페이지 크기 최대',
    assetSolarWithdrawalExpEq: '솔라 인출 경험치 일치',
    assetSolarWithdrawalExpNeq: '솔라 인출 경험치 불일치',
    assetSolarWithdrawalExpMin: '솔라 인출 경험치 최소',
    assetSolarWithdrawalExpMax: '솔라 인출 경험치 최대',
    assetStarChargePageSizeEq: '스타 충전 페이지 크기 일치',
    assetStarChargePageSizeNeq: '스타 충전 페이지 크기 불일치',
    assetStarChargePageSizeMin: '스타 충전 페이지 크기 최소',
    assetStarChargePageSizeMax: '스타 충전 페이지 크기 최대',
    assetStarUsagePageSizeEq: '스타 페이지 크기 일치',
    assetStarUsagePageSizeNeq: '스타 페이지 크기 불일치',
    assetStarUsagePageSizeMin: '스타 페이지 크기 최소',
    assetStarUsagePageSizeMax: '스타 페이지 크기 최대',
    assetStarUsageExpEq: '스타 소모 경험치 일치',
    assetStarUsageExpNeq: '스타 소모 경험치 불일치',
    assetStarUsageExpMin: '스타 소모 경험치 최소',
    assetStarUsageExpMax: '스타 소모 경험치 최대',
    assetStarExchangeOptionPageSizeEq: '스타 교환 옵션 페이지 크기 일치',
    assetStarExchangeOptionPageSizeNeq: '스타 교환 옵션 페이지 크기 불일치',
    assetStarExchangeOptionPageSizeMin: '스타 교환 옵션 페이지 크기 최소',
    assetStarExchangeOptionPageSizeMax: '스타 교환 옵션 페이지 크기 최대',
    assetStarExchangeExpEq: '스타 교환 경험치 일치',
    assetStarExchangeExpNeq: '스타 교환 경험치 불일치',
    assetStarExchangeExpMin: '스타 교환 경험치 최소',
    assetStarExchangeExpMax: '스타 교환 경험치 최대',
    assetStarChargeMinutesEq: '스타 충전 소요 시간 일치',
    assetStarChargeMinutesNeq: '스타 충전 소요 시간 불일치',
    assetStarChargeMinutesMin: '스타 충전 소요 시간 최소',
    assetStarChargeMinutesMax: '스타 충전 소요 시간 최대',
    assetStarChargeMaxEq: '스타 최대 충전 일치',
    assetStarChargeMaxNeq: '스타 최대 충전 불일치',
    assetStarChargeMaxMin: '스타 최대 충전 최소',
    assetStarChargeMaxMax: '스타 최대 충전 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
