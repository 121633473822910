import {ContentUserProductionScriptSearchOptionDescription} from "../../../data/description/content/ContentUserProductionScriptSearchOptionDescription"
import {ContentUserProductionScriptSearchOption} from "../../../data/search-option/content/ContentUserProductionScriptSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentUserProductionScriptSearchOptionField(props: ListOptionFieldProps<ContentUserProductionScriptSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.audioIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.audioIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.audioIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.audioIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.scriptEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.scriptNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.scriptMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.scriptMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.scriptLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.scriptNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.transcriptionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.transcriptionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.transcriptionMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.transcriptionMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.transcriptionLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.transcriptionNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.accuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.accuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.accuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.accuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentUserProductionScriptSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentUserProductionScriptSearchOption(key: keyof ContentUserProductionScriptSearchOption, value: string, into: StatePair<ContentUserProductionScriptSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "audioIdNeq":
            into.setter({
                ...into.value,
                audioIdNeq: BigInt(value)
            })
            break
        case "audioIdMin":
            into.setter({
                ...into.value,
                audioIdMin: BigInt(value)
            })
            break
        case "audioIdMax":
            into.setter({
                ...into.value,
                audioIdMax: BigInt(value)
            })
            break
        case "audioIdIsNull":
            into.setter({
                ...into.value,
                audioIdIsNull: Boolean(value)
            })
            break
        case "scriptEq":
            into.setter({
                ...into.value,
                scriptEq: String(value)
            })
            break
        case "scriptNeq":
            into.setter({
                ...into.value,
                scriptNeq: String(value)
            })
            break
        case "scriptMin":
            into.setter({
                ...into.value,
                scriptMin: String(value)
            })
            break
        case "scriptMax":
            into.setter({
                ...into.value,
                scriptMax: String(value)
            })
            break
        case "scriptLike":
            into.setter({
                ...into.value,
                scriptLike: String(value)
            })
            break
        case "scriptNotLike":
            into.setter({
                ...into.value,
                scriptNotLike: String(value)
            })
            break
        case "transcriptionEq":
            into.setter({
                ...into.value,
                transcriptionEq: String(value)
            })
            break
        case "transcriptionNeq":
            into.setter({
                ...into.value,
                transcriptionNeq: String(value)
            })
            break
        case "transcriptionMin":
            into.setter({
                ...into.value,
                transcriptionMin: String(value)
            })
            break
        case "transcriptionMax":
            into.setter({
                ...into.value,
                transcriptionMax: String(value)
            })
            break
        case "transcriptionLike":
            into.setter({
                ...into.value,
                transcriptionLike: String(value)
            })
            break
        case "transcriptionNotLike":
            into.setter({
                ...into.value,
                transcriptionNotLike: String(value)
            })
            break
        case "accuracyEq":
            into.setter({
                ...into.value,
                accuracyEq: Number(value)
            })
            break
        case "accuracyNeq":
            into.setter({
                ...into.value,
                accuracyNeq: Number(value)
            })
            break
        case "accuracyMin":
            into.setter({
                ...into.value,
                accuracyMin: Number(value)
            })
            break
        case "accuracyMax":
            into.setter({
                ...into.value,
                accuracyMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
