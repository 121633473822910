import {UserGender} from "../../../data/constant/user/UserGender"
import {ContentTargetSearchOptionDescription} from "../../../data/description/content/ContentTargetSearchOptionDescription"
import {ContentTargetSearchOption} from "../../../data/search-option/content/ContentTargetSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentTargetSearchOptionField(props: ListOptionFieldProps<ContentTargetSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.contentIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.contentIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.contentIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.contentIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.envCountryIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.envCountryIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.envCountryIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.envCountryIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.envCountryIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userGenderEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userGenderEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "userGenderNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userGenderNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "userGenderMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userGenderMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "userGenderMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userGenderMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "userGenderIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userGenderIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "userGenderIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userGenderIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "존재하지 않는 데이터만" },
                            { value: "false", label: "존재하는 데이터만" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "userBirthYearMinEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMinEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMinNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMinNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMinMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMinMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMinMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMinMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMinIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMinIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMaxEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMaxEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMaxNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMaxNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMaxMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMaxMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMaxMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMaxMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBirthYearMaxIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.userBirthYearMaxIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentTargetSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentTargetSearchOption(key: keyof ContentTargetSearchOption, value: string, into: StatePair<ContentTargetSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "contentIdEq":
            into.setter({
                ...into.value,
                contentIdEq: BigInt(value)
            })
            break
        case "contentIdNeq":
            into.setter({
                ...into.value,
                contentIdNeq: BigInt(value)
            })
            break
        case "contentIdMin":
            into.setter({
                ...into.value,
                contentIdMin: BigInt(value)
            })
            break
        case "contentIdMax":
            into.setter({
                ...into.value,
                contentIdMax: BigInt(value)
            })
            break
        case "envCountryIdEq":
            into.setter({
                ...into.value,
                envCountryIdEq: BigInt(value)
            })
            break
        case "envCountryIdNeq":
            into.setter({
                ...into.value,
                envCountryIdNeq: BigInt(value)
            })
            break
        case "envCountryIdMin":
            into.setter({
                ...into.value,
                envCountryIdMin: BigInt(value)
            })
            break
        case "envCountryIdMax":
            into.setter({
                ...into.value,
                envCountryIdMax: BigInt(value)
            })
            break
        case "envCountryIdIsNull":
            into.setter({
                ...into.value,
                envCountryIdIsNull: Boolean(value)
            })
            break
        case "userGenderEq":
            into.setter({
                ...into.value,
                userGenderEq: value as UserGender
            })
            break
        case "userGenderNeq":
            into.setter({
                ...into.value,
                userGenderNeq: value as UserGender
            })
            break
        case "userGenderMin":
            into.setter({
                ...into.value,
                userGenderMin: value as UserGender
            })
            break
        case "userGenderMax":
            into.setter({
                ...into.value,
                userGenderMax: value as UserGender
            })
            break
        case "userGenderIn":
            into.setter({
                ...into.value,
                userGenderIn: value
                    .split('|')
                    .map(e => e as UserGender)
            })
            break
        case "userGenderIsNull":
            into.setter({
                ...into.value,
                userGenderIsNull: Boolean(value)
            })
            break
        case "userBirthYearMinEq":
            into.setter({
                ...into.value,
                userBirthYearMinEq: Number(value)
            })
            break
        case "userBirthYearMinNeq":
            into.setter({
                ...into.value,
                userBirthYearMinNeq: Number(value)
            })
            break
        case "userBirthYearMinMin":
            into.setter({
                ...into.value,
                userBirthYearMinMin: Number(value)
            })
            break
        case "userBirthYearMinMax":
            into.setter({
                ...into.value,
                userBirthYearMinMax: Number(value)
            })
            break
        case "userBirthYearMinIsNull":
            into.setter({
                ...into.value,
                userBirthYearMinIsNull: Boolean(value)
            })
            break
        case "userBirthYearMaxEq":
            into.setter({
                ...into.value,
                userBirthYearMaxEq: Number(value)
            })
            break
        case "userBirthYearMaxNeq":
            into.setter({
                ...into.value,
                userBirthYearMaxNeq: Number(value)
            })
            break
        case "userBirthYearMaxMin":
            into.setter({
                ...into.value,
                userBirthYearMaxMin: Number(value)
            })
            break
        case "userBirthYearMaxMax":
            into.setter({
                ...into.value,
                userBirthYearMaxMax: Number(value)
            })
            break
        case "userBirthYearMaxIsNull":
            into.setter({
                ...into.value,
                userBirthYearMaxIsNull: Boolean(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
