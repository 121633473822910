import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {Button, Spinner, Title3} from "@fluentui/react-components";
import React, {useEffect} from "react";
import {AssetPolicyIO} from "../../../io/AssetIO";
import {useParams} from "react-router-dom";
import {AssetPolicyVerboseValueObject} from "../../../data/value-object/asset/AssetPolicyVerboseValueObject";
import {useAssetPolicyPutState} from "../../../data/data-transfer-object-state/asset/AssetPolicyPutState";
import {AssetPolicyPutFields} from "../../data-transfer-object-field/asset/AssetPolicyPutField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Loading)
    const params = useParams<{ assetPolicyId: string }>()
    const item = useStatePair<AssetPolicyVerboseValueObject | undefined>(undefined)
    const state = useAssetPolicyPutState()

    useEffect(() => {
        if (item.value === undefined && params.assetPolicyId !== undefined) {
            AssetPolicyIO
                .getVerboseById(BigInt(params.assetPolicyId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = () => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        AssetPolicyIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                alert('수정되었습니다.')
                document.location = `/assets/policies/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            {item.value === undefined && <Spinner/>}
            {item.value !== undefined && <>
                <AssetPolicyPutFields
                    {...props}
                    state={state} />
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </>}
        </div>
    </>
}