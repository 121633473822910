import {AssetSolarWithdrawalDomain} from "../../domain/asset/AssetSolarWithdrawalDomain"

/**
 * 솔라 인출 속성 설명
 */
export const AssetSolarWithdrawalDescription: Record<keyof AssetSolarWithdrawalDomain, string> = {
    id: '기본키',
    assetSolarUsageId: '솔라 사용 ID',
    envBankId: '은행 ID',
    account: '계좌번호',
    cashAmount: '요청 금액(원)',
    createdAt: '생성일'
}
