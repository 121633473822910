import {EnvBankDomain} from "../../domain/env/EnvBankDomain"

/**
 * 은행 속성 설명
 */
export const EnvBankDescription: Record<keyof EnvBankDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    name: '이름',
    displayOrder: '표시 우선 순위',
    assetSolarWithdrawalCount: '솔라 인출 횟수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
