import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserRankValueObject} from "../../../data/value-object/user/UserRankValueObject";
import {useEffect} from "react";
import {UserRankIO} from "../../../io/UserIO";
import {Body1, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {DetailDefaultPanel} from "../../DetailViews";
import {UserRankDescription} from "../../../data/description/user/UserRankDescription";
import {UserRankDomain} from "../../../data/domain/user/UserRankDomain";
import {UserRankDescriptor} from "../../../data/descriptor/user/UserRankDescriptor";
import {MediaFile, Media} from "../../Media";
import {StorageKey} from "../../../io/StorageIO";

enum Panel {
    Default,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userRankId: string }>()
    const item = useStatePair<UserRankValueObject | undefined>(undefined)
    const icon = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.userRankId !== undefined) {
            const id = BigInt(params.userRankId)
            UserRankIO
                .getById(id)
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
            MediaFile
                .createFromStorageKey(StorageKey.User.rank(id))
                .then(icon.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('아이콘을 찾을 수 없습니다. 아이콘을 먼저 수정해주세요.')
                    document.location = document.location.pathname + '/edit'
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && icon.value !== undefined && <>
            <Page
                {...props}
                item={item.value}
                icon={icon.value} />
        </>}
    </>
}

type PageProps = RouterProps & {
    item: UserRankValueObject
    icon: MediaFile
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
            </TabList>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UserRankIO}
                description={UserRankDescription}
                properties={props => <PropertyBody {...props} />}
                panelChildren={<>
                    <Caption1Strong>아이콘</Caption1Strong>
                    <Media
                        {...props}
                        index={0}
                        source={props.icon} />
                </>}/>
        </div>
    </>
}

type PropertyBodyProps = Omit<PageProps, 'icon'> & {
    itemKey: keyof UserRankDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "name":
        case "percentile":
        case "fixed":
        case "applyOrder":
        case "rewardAssetLunarAmount":
        case "rewardAssetSolarAmount":
        case "rewardAssetStarAmount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{UserRankDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserRankDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}