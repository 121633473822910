import {ContentDifficulty} from "../../constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../constant/content/ContentScriptVisibility"
import {ContentModificationVerboseDomain} from "../../domain/content/ContentModificationVerboseDomain"
import {ContentDetailUrlId} from "../../id/content/ContentDetailUrlId"
import {ContentId} from "../../id/content/ContentId"
import {ContentModificationId} from "../../id/content/ContentModificationId"
import {ContentResultUrlId} from "../../id/content/ContentResultUrlId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentDetailUrlValueObject} from "./ContentDetailUrlValueObject"
import {ContentResultUrlValueObject} from "./ContentResultUrlValueObject"
import {ContentValueObject} from "./ContentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 참여 가능 항목 수정 이력 상세
 */
export class ContentModificationVerboseValueObject implements VerboseValueObject, ContentModificationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 참여 가능 항목 ID
     */
    contentId: ContentId
    /**
     * 참여 방법
     */
    participationMethod: ContentParticipationMethod
    /**
     * 제목
     */
    title: string
    /**
     * 제목 강조
     */
    titleHighlight?: string
    /**
     * 내용
     */
    body: string
    /**
     * 스크립트
     */
    script: string
    /**
     * 스크립트 표시 유형
     */
    scriptVisibility: ContentScriptVisibility
    /**
     * 콘텐츠 더 알아보기 URL ID
     */
    contentDetailUrlId: ContentDetailUrlId
    /**
     * 콘텐츠 참여 결과 URL ID
     */
    contentResultUrlId: ContentResultUrlId
    /**
     * 채점 중 텍스트
     */
    gradingMessage?: string
    /**
     * 보상 지급 수량
     */
    amount: number
    /**
     * 합격 기준(%)
     */
    passAccuracy: number
    /**
     * 높은 일치율 기준(%)
     */
    passAccuracyHigh?: number
    /**
     * 합격 지급 솔라
     */
    passSolarAmount: number
    /**
     * 높은 일치율 지급 솔라
     */
    passSolarAmountHighAccuracy: number
    /**
     * 소모 스타
     */
    starAmount: number
    /**
     * 난이도
     */
    difficulty: ContentDifficulty
    /**
     * 상단 이미지 수
     */
    headerImageCount: number
    /**
     * 설명 이미지 수
     */
    descriptionImageCount: number
    /**
     * 노출 시작일
     */
    exposedAt: Date
    /**
     * 참여 시작일
     */
    startAt: Date
    /**
     * 참여 종료일
     */
    endAt: Date
    /**
     * 상태 플래그
     */
    statusFlags: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 참여 가능 항목 ID
     */
    content: ContentValueObject
    /**
     * 콘텐츠 더 알아보기 URL ID
     */
    contentDetailUrl: ContentDetailUrlValueObject
    /**
     * 콘텐츠 참여 결과 URL ID
     */
    contentResultUrl: ContentResultUrlValueObject

    constructor(o: Record<keyof ContentModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentId = BigInt(o.contentId)
        this.participationMethod = o.participationMethod as ContentParticipationMethod
        this.title = String(o.title)
        if (o.titleHighlight !== undefined && o.titleHighlight !== null) {
            this.titleHighlight = String(o.titleHighlight)
        }
        this.body = String(o.body)
        this.script = String(o.script)
        this.scriptVisibility = o.scriptVisibility as ContentScriptVisibility
        this.contentDetailUrlId = BigInt(o.contentDetailUrlId)
        this.contentResultUrlId = BigInt(o.contentResultUrlId)
        if (o.gradingMessage !== undefined && o.gradingMessage !== null) {
            this.gradingMessage = String(o.gradingMessage)
        }
        this.amount = Number(o.amount)
        this.passAccuracy = Number(o.passAccuracy)
        if (o.passAccuracyHigh !== undefined && o.passAccuracyHigh !== null) {
            this.passAccuracyHigh = Number(o.passAccuracyHigh)
        }
        this.passSolarAmount = Number(o.passSolarAmount)
        this.passSolarAmountHighAccuracy = Number(o.passSolarAmountHighAccuracy)
        this.starAmount = Number(o.starAmount)
        this.difficulty = o.difficulty as ContentDifficulty
        this.headerImageCount = Number(o.headerImageCount)
        this.descriptionImageCount = Number(o.descriptionImageCount)
        this.exposedAt = new Date(o.exposedAt)
        this.startAt = new Date(o.startAt)
        this.endAt = new Date(o.endAt)
        this.statusFlags = Number(o.statusFlags)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.content = new ContentValueObject(o.content)
        this.contentDetailUrl = new ContentDetailUrlValueObject(o.contentDetailUrl)
        this.contentResultUrl = new ContentResultUrlValueObject(o.contentResultUrl)
    }
}
