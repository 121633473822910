import {UserExpType} from "../../constant/user/UserExpType"
import {UserExpDomain} from "../../domain/user/UserExpDomain"

export const UserExpDescriptor: Record<keyof UserExpDomain, (o: UserExpDomain) => string> = {
    id(o: UserExpDomain): string {
        return o.id.toString()
    },
    userId(o: UserExpDomain): string {
        return o.userId.toString()
    },
    creatorId(o: UserExpDomain): string {
        return o.creatorId?.toString() ?? '-'
    },
    type(o: UserExpDomain): string {
        return UserExpType.description(o.type)
    },
    amount(o: UserExpDomain): string {
        return o.amount.toString()
    },
    lastAccumulation(o: UserExpDomain): string {
        return o.lastAccumulation.toString()
    },
    inDay(o: UserExpDomain): string {
        return o.inDay.toString()
    },
    inWeek(o: UserExpDomain): string {
        return o.inWeek.toString()
    },
    inMonth(o: UserExpDomain): string {
        return o.inMonth.toString()
    },
    inQuarter(o: UserExpDomain): string {
        return o.inQuarter.toString()
    },
    inHalf(o: UserExpDomain): string {
        return o.inHalf.toString()
    },
    inYear(o: UserExpDomain): string {
        return o.inYear.toString()
    },
    createdAt(o: UserExpDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
