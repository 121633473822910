import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {AssetPolicyIO} from "../../../io/AssetIO";
import ListOption from "../../ListOption";
import ListOrder from "../../ListOrder";
import {AssetPolicyValueObject} from "../../../data/value-object/asset/AssetPolicyValueObject";
import {AssetPolicySearchOption} from "../../../data/search-option/asset/AssetPolicySearchOption";
import {AssetPolicyDescription} from "../../../data/description/asset/AssetPolicyDescription";
import {AssetPolicyDescriptor} from "../../../data/descriptor/asset/AssetPolicyDescriptor";
import {AssetPolicySearchOptionDescription} from "../../../data/description/asset/AssetPolicySearchOptionDescription";
import {
    applyAssetPolicySearchOption,
    AssetPolicySearchOptionField
} from "../../search-option-field/asset/AssetPolicySearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetPolicyValueObject[]>([])
    const option = useStatePair<AssetPolicySearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetPolicyIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetPolicyDescription}
                descriptor={AssetPolicyDescriptor}
                showPost
                onPostClick={() => document.location = '/assets/policies/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/policies/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetPolicySearchOptionDescription}
                fields={AssetPolicySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetPolicySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetPolicyDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}