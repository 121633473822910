import {UserGender} from "../../../data/constant/user/UserGender"
import {UserPermission} from "../../../data/constant/user/UserPermission"
import {UserRole} from "../../../data/constant/user/UserRole"
import {UserStatus} from "../../../data/constant/user/UserStatus"
import {UserSearchOptionDescription} from "../../../data/description/user/UserSearchOptionDescription"
import {UserSearchOption} from "../../../data/search-option/user/UserSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"
import {BitMaskFlag} from "ts-protocol-extension"

export function UserSearchOptionField(props: ListOptionFieldProps<UserSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "authenticationUidNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.authenticationUidNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "authenticationUidMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.authenticationUidMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "authenticationUidMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.authenticationUidMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "authenticationUidLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.authenticationUidLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "authenticationUidNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.authenticationUidNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "roleEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.roleEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRole.selections} />
                </ListOptionWrapper>
            </>
        case "roleNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.roleNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRole.selections} />
                </ListOptionWrapper>
            </>
        case "roleMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.roleMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRole.selections} />
                </ListOptionWrapper>
            </>
        case "roleMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.roleMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRole.selections} />
                </ListOptionWrapper>
            </>
        case "roleIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.roleIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserRole.selections} />
                </ListOptionWrapper>
            </>
        case "adminPermissionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.adminPermissionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserPermission.selections} />
                </ListOptionWrapper>
            </>
        case "adminPermissionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.adminPermissionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserPermission.selections} />
                </ListOptionWrapper>
            </>
        case "adminPermissionAny":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.adminPermissionAny}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserPermission.selections} />
                </ListOptionWrapper>
            </>
        case "adminPermissionAll":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.adminPermissionAll}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserPermission.selections} />
                </ListOptionWrapper>
            </>
        case "adminPermissionNone":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.adminPermissionNone}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserPermission.selections} />
                </ListOptionWrapper>
            </>
        case "emailEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.emailEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emailNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.emailNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emailMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.emailMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emailMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.emailMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emailLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.emailLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "emailNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.emailNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cloudMessagingTokenNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.cloudMessagingTokenNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cloudMessagingTokenMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.cloudMessagingTokenMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cloudMessagingTokenMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.cloudMessagingTokenMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cloudMessagingTokenLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.cloudMessagingTokenLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cloudMessagingTokenNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.cloudMessagingTokenNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cloudMessagingTokenIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.cloudMessagingTokenIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nicknameNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.nicknameNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nicknameMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.nicknameMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nicknameMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.nicknameMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nicknameLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.nicknameLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nicknameNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.nicknameNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthCountryIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthCountryIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthCountryIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthCountryIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthCountryIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthCountryIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthCountryIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthCountryIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "residenceCountryIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.residenceCountryIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "residenceCountryIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.residenceCountryIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "residenceCountryIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.residenceCountryIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "residenceCountryIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.residenceCountryIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "birthNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.birthNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "genderEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.genderEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "genderNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.genderNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "genderMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.genderMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "genderMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.genderMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "genderIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.genderIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserGender.selections} />
                </ListOptionWrapper>
            </>
        case "phoneCountryIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneCountryIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCountryIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneCountryIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCountryIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneCountryIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCountryIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneCountryIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneCountryIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneCountryIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "phoneIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.phoneIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommenderIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommenderIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommenderIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommenderIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommenderIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommenderIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendationCodeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendationCodeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendationCodeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendationCodeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendationCodeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendationCodeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendationCodeLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendationCodeLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendationCodeNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendationCodeNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "statusFlagsEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.statusFlagsEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.statusFlagsNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsAny":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.statusFlagsAny}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsAll":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.statusFlagsAll}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsNone":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.statusFlagsNone}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserStatus.selections} />
                </ListOptionWrapper>
            </>
        case "recommendeeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendeeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendeeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendeeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "recommendeeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.recommendeeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationPassCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationPassCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationPassCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentDailyParticipationPassCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatLikeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatLikeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatLikeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatLikeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatLikeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatLikeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatLikeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatLikeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationPassCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationPassCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationPassCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationPassCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationHighAccuracyCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationHighAccuracyCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationHighAccuracyCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationHighAccuracyCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationHighAccuracyCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationHighAccuracyCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationHighAccuracyCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatParticipationHighAccuracyCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentLikeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentLikeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentLikeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentLikeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentLikeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentLikeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentLikeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentLikeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportedCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportedCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportedCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportedCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportedCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportedCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatCommentReportedCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentRepeatCommentReportedCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nLikeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nLikeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nLikeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nLikeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nLikeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nLikeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nLikeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nLikeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationPassCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationPassCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationPassCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationPassCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationHighAccuracyCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationHighAccuracyCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationHighAccuracyCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationHighAccuracyCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationHighAccuracyCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationHighAccuracyCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationHighAccuracyCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nParticipationHighAccuracyCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentLikeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentLikeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentLikeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentLikeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentLikeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentLikeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentLikeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentLikeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportedCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportedCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportedCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportedCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportedCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportedCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nCommentReportedCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentM10nCommentReportedCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionLikeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionLikeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionLikeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionLikeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionLikeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionLikeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionLikeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionLikeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationPassCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationPassCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationPassCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationPassCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationHighAccuracyCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationHighAccuracyCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationHighAccuracyCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationHighAccuracyCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationHighAccuracyCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationHighAccuracyCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationHighAccuracyCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionParticipationHighAccuracyCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportedCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportedCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportedCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportedCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportedCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportedCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReportedCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReportedCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentLikeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentLikeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentLikeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentLikeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentLikeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentLikeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentLikeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentLikeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportedCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportedCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportedCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportedCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportedCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportedCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionCommentReportedCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionCommentReportedCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewPositiveCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewPositiveCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewPositiveCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewPositiveCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewPositiveCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewPositiveCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewPositiveCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewPositiveCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNeutralCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNeutralCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNeutralCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNeutralCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNeutralCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNeutralCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNeutralCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNeutralCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNegativeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNegativeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNegativeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNegativeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNegativeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNegativeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionReviewNegativeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.contentUserProductionReviewNegativeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.userBlockCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.userBlockCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.userBlockCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userBlockCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.userBlockCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserSearchOption(key: keyof UserSearchOption, value: string, into: StatePair<UserSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "authenticationUidNeq":
            into.setter({
                ...into.value,
                authenticationUidNeq: String(value)
            })
            break
        case "authenticationUidMin":
            into.setter({
                ...into.value,
                authenticationUidMin: String(value)
            })
            break
        case "authenticationUidMax":
            into.setter({
                ...into.value,
                authenticationUidMax: String(value)
            })
            break
        case "authenticationUidLike":
            into.setter({
                ...into.value,
                authenticationUidLike: String(value)
            })
            break
        case "authenticationUidNotLike":
            into.setter({
                ...into.value,
                authenticationUidNotLike: String(value)
            })
            break
        case "roleEq":
            into.setter({
                ...into.value,
                roleEq: value as UserRole
            })
            break
        case "roleNeq":
            into.setter({
                ...into.value,
                roleNeq: value as UserRole
            })
            break
        case "roleMin":
            into.setter({
                ...into.value,
                roleMin: value as UserRole
            })
            break
        case "roleMax":
            into.setter({
                ...into.value,
                roleMax: value as UserRole
            })
            break
        case "roleIn":
            into.setter({
                ...into.value,
                roleIn: value
                    .split('|')
                    .map(e => e as UserRole)
            })
            break
        case "adminPermissionEq":
            into.setter({
                ...into.value,
                adminPermissionEq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "adminPermissionNeq":
            into.setter({
                ...into.value,
                adminPermissionNeq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "adminPermissionAny":
            into.setter({
                ...into.value,
                adminPermissionAny: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "adminPermissionAll":
            into.setter({
                ...into.value,
                adminPermissionAll: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "adminPermissionNone":
            into.setter({
                ...into.value,
                adminPermissionNone: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "emailEq":
            into.setter({
                ...into.value,
                emailEq: String(value)
            })
            break
        case "emailNeq":
            into.setter({
                ...into.value,
                emailNeq: String(value)
            })
            break
        case "emailMin":
            into.setter({
                ...into.value,
                emailMin: String(value)
            })
            break
        case "emailMax":
            into.setter({
                ...into.value,
                emailMax: String(value)
            })
            break
        case "emailLike":
            into.setter({
                ...into.value,
                emailLike: String(value)
            })
            break
        case "emailNotLike":
            into.setter({
                ...into.value,
                emailNotLike: String(value)
            })
            break
        case "cloudMessagingTokenNeq":
            into.setter({
                ...into.value,
                cloudMessagingTokenNeq: String(value)
            })
            break
        case "cloudMessagingTokenMin":
            into.setter({
                ...into.value,
                cloudMessagingTokenMin: String(value)
            })
            break
        case "cloudMessagingTokenMax":
            into.setter({
                ...into.value,
                cloudMessagingTokenMax: String(value)
            })
            break
        case "cloudMessagingTokenLike":
            into.setter({
                ...into.value,
                cloudMessagingTokenLike: String(value)
            })
            break
        case "cloudMessagingTokenNotLike":
            into.setter({
                ...into.value,
                cloudMessagingTokenNotLike: String(value)
            })
            break
        case "cloudMessagingTokenIsNull":
            into.setter({
                ...into.value,
                cloudMessagingTokenIsNull: Boolean(value)
            })
            break
        case "nicknameNeq":
            into.setter({
                ...into.value,
                nicknameNeq: String(value)
            })
            break
        case "nicknameMin":
            into.setter({
                ...into.value,
                nicknameMin: String(value)
            })
            break
        case "nicknameMax":
            into.setter({
                ...into.value,
                nicknameMax: String(value)
            })
            break
        case "nicknameLike":
            into.setter({
                ...into.value,
                nicknameLike: String(value)
            })
            break
        case "nicknameNotLike":
            into.setter({
                ...into.value,
                nicknameNotLike: String(value)
            })
            break
        case "birthCountryIdEq":
            into.setter({
                ...into.value,
                birthCountryIdEq: BigInt(value)
            })
            break
        case "birthCountryIdNeq":
            into.setter({
                ...into.value,
                birthCountryIdNeq: BigInt(value)
            })
            break
        case "birthCountryIdMin":
            into.setter({
                ...into.value,
                birthCountryIdMin: BigInt(value)
            })
            break
        case "birthCountryIdMax":
            into.setter({
                ...into.value,
                birthCountryIdMax: BigInt(value)
            })
            break
        case "residenceCountryIdEq":
            into.setter({
                ...into.value,
                residenceCountryIdEq: BigInt(value)
            })
            break
        case "residenceCountryIdNeq":
            into.setter({
                ...into.value,
                residenceCountryIdNeq: BigInt(value)
            })
            break
        case "residenceCountryIdMin":
            into.setter({
                ...into.value,
                residenceCountryIdMin: BigInt(value)
            })
            break
        case "residenceCountryIdMax":
            into.setter({
                ...into.value,
                residenceCountryIdMax: BigInt(value)
            })
            break
        case "birthEq":
            into.setter({
                ...into.value,
                birthEq: String(value)
            })
            break
        case "birthNeq":
            into.setter({
                ...into.value,
                birthNeq: String(value)
            })
            break
        case "birthMin":
            into.setter({
                ...into.value,
                birthMin: String(value)
            })
            break
        case "birthMax":
            into.setter({
                ...into.value,
                birthMax: String(value)
            })
            break
        case "birthLike":
            into.setter({
                ...into.value,
                birthLike: String(value)
            })
            break
        case "birthNotLike":
            into.setter({
                ...into.value,
                birthNotLike: String(value)
            })
            break
        case "genderEq":
            into.setter({
                ...into.value,
                genderEq: value as UserGender
            })
            break
        case "genderNeq":
            into.setter({
                ...into.value,
                genderNeq: value as UserGender
            })
            break
        case "genderMin":
            into.setter({
                ...into.value,
                genderMin: value as UserGender
            })
            break
        case "genderMax":
            into.setter({
                ...into.value,
                genderMax: value as UserGender
            })
            break
        case "genderIn":
            into.setter({
                ...into.value,
                genderIn: value
                    .split('|')
                    .map(e => e as UserGender)
            })
            break
        case "phoneCountryIdEq":
            into.setter({
                ...into.value,
                phoneCountryIdEq: BigInt(value)
            })
            break
        case "phoneCountryIdNeq":
            into.setter({
                ...into.value,
                phoneCountryIdNeq: BigInt(value)
            })
            break
        case "phoneCountryIdMin":
            into.setter({
                ...into.value,
                phoneCountryIdMin: BigInt(value)
            })
            break
        case "phoneCountryIdMax":
            into.setter({
                ...into.value,
                phoneCountryIdMax: BigInt(value)
            })
            break
        case "phoneCountryIdIsNull":
            into.setter({
                ...into.value,
                phoneCountryIdIsNull: Boolean(value)
            })
            break
        case "phoneEq":
            into.setter({
                ...into.value,
                phoneEq: String(value)
            })
            break
        case "phoneNeq":
            into.setter({
                ...into.value,
                phoneNeq: String(value)
            })
            break
        case "phoneMin":
            into.setter({
                ...into.value,
                phoneMin: String(value)
            })
            break
        case "phoneMax":
            into.setter({
                ...into.value,
                phoneMax: String(value)
            })
            break
        case "phoneLike":
            into.setter({
                ...into.value,
                phoneLike: String(value)
            })
            break
        case "phoneNotLike":
            into.setter({
                ...into.value,
                phoneNotLike: String(value)
            })
            break
        case "phoneIsNull":
            into.setter({
                ...into.value,
                phoneIsNull: Boolean(value)
            })
            break
        case "recommenderIdEq":
            into.setter({
                ...into.value,
                recommenderIdEq: BigInt(value)
            })
            break
        case "recommenderIdNeq":
            into.setter({
                ...into.value,
                recommenderIdNeq: BigInt(value)
            })
            break
        case "recommenderIdMin":
            into.setter({
                ...into.value,
                recommenderIdMin: BigInt(value)
            })
            break
        case "recommenderIdMax":
            into.setter({
                ...into.value,
                recommenderIdMax: BigInt(value)
            })
            break
        case "recommenderIdIsNull":
            into.setter({
                ...into.value,
                recommenderIdIsNull: Boolean(value)
            })
            break
        case "recommendationCodeNeq":
            into.setter({
                ...into.value,
                recommendationCodeNeq: String(value)
            })
            break
        case "recommendationCodeMin":
            into.setter({
                ...into.value,
                recommendationCodeMin: String(value)
            })
            break
        case "recommendationCodeMax":
            into.setter({
                ...into.value,
                recommendationCodeMax: String(value)
            })
            break
        case "recommendationCodeLike":
            into.setter({
                ...into.value,
                recommendationCodeLike: String(value)
            })
            break
        case "recommendationCodeNotLike":
            into.setter({
                ...into.value,
                recommendationCodeNotLike: String(value)
            })
            break
        case "statusFlagsEq":
            into.setter({
                ...into.value,
                statusFlagsEq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsNeq":
            into.setter({
                ...into.value,
                statusFlagsNeq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsAny":
            into.setter({
                ...into.value,
                statusFlagsAny: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsAll":
            into.setter({
                ...into.value,
                statusFlagsAll: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsNone":
            into.setter({
                ...into.value,
                statusFlagsNone: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "recommendeeCountEq":
            into.setter({
                ...into.value,
                recommendeeCountEq: Number(value)
            })
            break
        case "recommendeeCountNeq":
            into.setter({
                ...into.value,
                recommendeeCountNeq: Number(value)
            })
            break
        case "recommendeeCountMin":
            into.setter({
                ...into.value,
                recommendeeCountMin: Number(value)
            })
            break
        case "recommendeeCountMax":
            into.setter({
                ...into.value,
                recommendeeCountMax: Number(value)
            })
            break
        case "contentDailyParticipationCountEq":
            into.setter({
                ...into.value,
                contentDailyParticipationCountEq: Number(value)
            })
            break
        case "contentDailyParticipationCountNeq":
            into.setter({
                ...into.value,
                contentDailyParticipationCountNeq: Number(value)
            })
            break
        case "contentDailyParticipationCountMin":
            into.setter({
                ...into.value,
                contentDailyParticipationCountMin: Number(value)
            })
            break
        case "contentDailyParticipationCountMax":
            into.setter({
                ...into.value,
                contentDailyParticipationCountMax: Number(value)
            })
            break
        case "contentDailyParticipationPassCountEq":
            into.setter({
                ...into.value,
                contentDailyParticipationPassCountEq: Number(value)
            })
            break
        case "contentDailyParticipationPassCountNeq":
            into.setter({
                ...into.value,
                contentDailyParticipationPassCountNeq: Number(value)
            })
            break
        case "contentDailyParticipationPassCountMin":
            into.setter({
                ...into.value,
                contentDailyParticipationPassCountMin: Number(value)
            })
            break
        case "contentDailyParticipationPassCountMax":
            into.setter({
                ...into.value,
                contentDailyParticipationPassCountMax: Number(value)
            })
            break
        case "contentRepeatLikeCountEq":
            into.setter({
                ...into.value,
                contentRepeatLikeCountEq: Number(value)
            })
            break
        case "contentRepeatLikeCountNeq":
            into.setter({
                ...into.value,
                contentRepeatLikeCountNeq: Number(value)
            })
            break
        case "contentRepeatLikeCountMin":
            into.setter({
                ...into.value,
                contentRepeatLikeCountMin: Number(value)
            })
            break
        case "contentRepeatLikeCountMax":
            into.setter({
                ...into.value,
                contentRepeatLikeCountMax: Number(value)
            })
            break
        case "contentRepeatParticipationCountEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationCountEq: Number(value)
            })
            break
        case "contentRepeatParticipationCountNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationCountNeq: Number(value)
            })
            break
        case "contentRepeatParticipationCountMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationCountMin: Number(value)
            })
            break
        case "contentRepeatParticipationCountMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationCountMax: Number(value)
            })
            break
        case "contentRepeatParticipationPassCountEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassCountEq: Number(value)
            })
            break
        case "contentRepeatParticipationPassCountNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassCountNeq: Number(value)
            })
            break
        case "contentRepeatParticipationPassCountMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassCountMin: Number(value)
            })
            break
        case "contentRepeatParticipationPassCountMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassCountMax: Number(value)
            })
            break
        case "contentRepeatParticipationHighAccuracyCountEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationHighAccuracyCountEq: Number(value)
            })
            break
        case "contentRepeatParticipationHighAccuracyCountNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationHighAccuracyCountNeq: Number(value)
            })
            break
        case "contentRepeatParticipationHighAccuracyCountMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationHighAccuracyCountMin: Number(value)
            })
            break
        case "contentRepeatParticipationHighAccuracyCountMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationHighAccuracyCountMax: Number(value)
            })
            break
        case "contentRepeatCommentCountEq":
            into.setter({
                ...into.value,
                contentRepeatCommentCountEq: Number(value)
            })
            break
        case "contentRepeatCommentCountNeq":
            into.setter({
                ...into.value,
                contentRepeatCommentCountNeq: Number(value)
            })
            break
        case "contentRepeatCommentCountMin":
            into.setter({
                ...into.value,
                contentRepeatCommentCountMin: Number(value)
            })
            break
        case "contentRepeatCommentCountMax":
            into.setter({
                ...into.value,
                contentRepeatCommentCountMax: Number(value)
            })
            break
        case "contentRepeatCommentLikeCountEq":
            into.setter({
                ...into.value,
                contentRepeatCommentLikeCountEq: Number(value)
            })
            break
        case "contentRepeatCommentLikeCountNeq":
            into.setter({
                ...into.value,
                contentRepeatCommentLikeCountNeq: Number(value)
            })
            break
        case "contentRepeatCommentLikeCountMin":
            into.setter({
                ...into.value,
                contentRepeatCommentLikeCountMin: Number(value)
            })
            break
        case "contentRepeatCommentLikeCountMax":
            into.setter({
                ...into.value,
                contentRepeatCommentLikeCountMax: Number(value)
            })
            break
        case "contentRepeatCommentReportCountEq":
            into.setter({
                ...into.value,
                contentRepeatCommentReportCountEq: Number(value)
            })
            break
        case "contentRepeatCommentReportCountNeq":
            into.setter({
                ...into.value,
                contentRepeatCommentReportCountNeq: Number(value)
            })
            break
        case "contentRepeatCommentReportCountMin":
            into.setter({
                ...into.value,
                contentRepeatCommentReportCountMin: Number(value)
            })
            break
        case "contentRepeatCommentReportCountMax":
            into.setter({
                ...into.value,
                contentRepeatCommentReportCountMax: Number(value)
            })
            break
        case "contentRepeatCommentReportedCountEq":
            into.setter({
                ...into.value,
                contentRepeatCommentReportedCountEq: Number(value)
            })
            break
        case "contentRepeatCommentReportedCountNeq":
            into.setter({
                ...into.value,
                contentRepeatCommentReportedCountNeq: Number(value)
            })
            break
        case "contentRepeatCommentReportedCountMin":
            into.setter({
                ...into.value,
                contentRepeatCommentReportedCountMin: Number(value)
            })
            break
        case "contentRepeatCommentReportedCountMax":
            into.setter({
                ...into.value,
                contentRepeatCommentReportedCountMax: Number(value)
            })
            break
        case "contentM10nLikeCountEq":
            into.setter({
                ...into.value,
                contentM10nLikeCountEq: Number(value)
            })
            break
        case "contentM10nLikeCountNeq":
            into.setter({
                ...into.value,
                contentM10nLikeCountNeq: Number(value)
            })
            break
        case "contentM10nLikeCountMin":
            into.setter({
                ...into.value,
                contentM10nLikeCountMin: Number(value)
            })
            break
        case "contentM10nLikeCountMax":
            into.setter({
                ...into.value,
                contentM10nLikeCountMax: Number(value)
            })
            break
        case "contentM10nParticipationCountEq":
            into.setter({
                ...into.value,
                contentM10nParticipationCountEq: Number(value)
            })
            break
        case "contentM10nParticipationCountNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationCountNeq: Number(value)
            })
            break
        case "contentM10nParticipationCountMin":
            into.setter({
                ...into.value,
                contentM10nParticipationCountMin: Number(value)
            })
            break
        case "contentM10nParticipationCountMax":
            into.setter({
                ...into.value,
                contentM10nParticipationCountMax: Number(value)
            })
            break
        case "contentM10nParticipationPassCountEq":
            into.setter({
                ...into.value,
                contentM10nParticipationPassCountEq: Number(value)
            })
            break
        case "contentM10nParticipationPassCountNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationPassCountNeq: Number(value)
            })
            break
        case "contentM10nParticipationPassCountMin":
            into.setter({
                ...into.value,
                contentM10nParticipationPassCountMin: Number(value)
            })
            break
        case "contentM10nParticipationPassCountMax":
            into.setter({
                ...into.value,
                contentM10nParticipationPassCountMax: Number(value)
            })
            break
        case "contentM10nParticipationHighAccuracyCountEq":
            into.setter({
                ...into.value,
                contentM10nParticipationHighAccuracyCountEq: Number(value)
            })
            break
        case "contentM10nParticipationHighAccuracyCountNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationHighAccuracyCountNeq: Number(value)
            })
            break
        case "contentM10nParticipationHighAccuracyCountMin":
            into.setter({
                ...into.value,
                contentM10nParticipationHighAccuracyCountMin: Number(value)
            })
            break
        case "contentM10nParticipationHighAccuracyCountMax":
            into.setter({
                ...into.value,
                contentM10nParticipationHighAccuracyCountMax: Number(value)
            })
            break
        case "contentM10nCommentCountEq":
            into.setter({
                ...into.value,
                contentM10nCommentCountEq: Number(value)
            })
            break
        case "contentM10nCommentCountNeq":
            into.setter({
                ...into.value,
                contentM10nCommentCountNeq: Number(value)
            })
            break
        case "contentM10nCommentCountMin":
            into.setter({
                ...into.value,
                contentM10nCommentCountMin: Number(value)
            })
            break
        case "contentM10nCommentCountMax":
            into.setter({
                ...into.value,
                contentM10nCommentCountMax: Number(value)
            })
            break
        case "contentM10nCommentLikeCountEq":
            into.setter({
                ...into.value,
                contentM10nCommentLikeCountEq: Number(value)
            })
            break
        case "contentM10nCommentLikeCountNeq":
            into.setter({
                ...into.value,
                contentM10nCommentLikeCountNeq: Number(value)
            })
            break
        case "contentM10nCommentLikeCountMin":
            into.setter({
                ...into.value,
                contentM10nCommentLikeCountMin: Number(value)
            })
            break
        case "contentM10nCommentLikeCountMax":
            into.setter({
                ...into.value,
                contentM10nCommentLikeCountMax: Number(value)
            })
            break
        case "contentM10nCommentReportCountEq":
            into.setter({
                ...into.value,
                contentM10nCommentReportCountEq: Number(value)
            })
            break
        case "contentM10nCommentReportCountNeq":
            into.setter({
                ...into.value,
                contentM10nCommentReportCountNeq: Number(value)
            })
            break
        case "contentM10nCommentReportCountMin":
            into.setter({
                ...into.value,
                contentM10nCommentReportCountMin: Number(value)
            })
            break
        case "contentM10nCommentReportCountMax":
            into.setter({
                ...into.value,
                contentM10nCommentReportCountMax: Number(value)
            })
            break
        case "contentM10nCommentReportedCountEq":
            into.setter({
                ...into.value,
                contentM10nCommentReportedCountEq: Number(value)
            })
            break
        case "contentM10nCommentReportedCountNeq":
            into.setter({
                ...into.value,
                contentM10nCommentReportedCountNeq: Number(value)
            })
            break
        case "contentM10nCommentReportedCountMin":
            into.setter({
                ...into.value,
                contentM10nCommentReportedCountMin: Number(value)
            })
            break
        case "contentM10nCommentReportedCountMax":
            into.setter({
                ...into.value,
                contentM10nCommentReportedCountMax: Number(value)
            })
            break
        case "contentUserProductionCountEq":
            into.setter({
                ...into.value,
                contentUserProductionCountEq: Number(value)
            })
            break
        case "contentUserProductionCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionCountNeq: Number(value)
            })
            break
        case "contentUserProductionCountMin":
            into.setter({
                ...into.value,
                contentUserProductionCountMin: Number(value)
            })
            break
        case "contentUserProductionCountMax":
            into.setter({
                ...into.value,
                contentUserProductionCountMax: Number(value)
            })
            break
        case "contentUserProductionLikeCountEq":
            into.setter({
                ...into.value,
                contentUserProductionLikeCountEq: Number(value)
            })
            break
        case "contentUserProductionLikeCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionLikeCountNeq: Number(value)
            })
            break
        case "contentUserProductionLikeCountMin":
            into.setter({
                ...into.value,
                contentUserProductionLikeCountMin: Number(value)
            })
            break
        case "contentUserProductionLikeCountMax":
            into.setter({
                ...into.value,
                contentUserProductionLikeCountMax: Number(value)
            })
            break
        case "contentUserProductionParticipationCountEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationCountEq: Number(value)
            })
            break
        case "contentUserProductionParticipationCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationCountNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationCountMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationCountMin: Number(value)
            })
            break
        case "contentUserProductionParticipationCountMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationCountMax: Number(value)
            })
            break
        case "contentUserProductionParticipationPassCountEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassCountEq: Number(value)
            })
            break
        case "contentUserProductionParticipationPassCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassCountNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationPassCountMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassCountMin: Number(value)
            })
            break
        case "contentUserProductionParticipationPassCountMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassCountMax: Number(value)
            })
            break
        case "contentUserProductionParticipationHighAccuracyCountEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationHighAccuracyCountEq: Number(value)
            })
            break
        case "contentUserProductionParticipationHighAccuracyCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationHighAccuracyCountNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationHighAccuracyCountMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationHighAccuracyCountMin: Number(value)
            })
            break
        case "contentUserProductionParticipationHighAccuracyCountMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationHighAccuracyCountMax: Number(value)
            })
            break
        case "contentUserProductionReportCountEq":
            into.setter({
                ...into.value,
                contentUserProductionReportCountEq: Number(value)
            })
            break
        case "contentUserProductionReportCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionReportCountNeq: Number(value)
            })
            break
        case "contentUserProductionReportCountMin":
            into.setter({
                ...into.value,
                contentUserProductionReportCountMin: Number(value)
            })
            break
        case "contentUserProductionReportCountMax":
            into.setter({
                ...into.value,
                contentUserProductionReportCountMax: Number(value)
            })
            break
        case "contentUserProductionReportedCountEq":
            into.setter({
                ...into.value,
                contentUserProductionReportedCountEq: Number(value)
            })
            break
        case "contentUserProductionReportedCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionReportedCountNeq: Number(value)
            })
            break
        case "contentUserProductionReportedCountMin":
            into.setter({
                ...into.value,
                contentUserProductionReportedCountMin: Number(value)
            })
            break
        case "contentUserProductionReportedCountMax":
            into.setter({
                ...into.value,
                contentUserProductionReportedCountMax: Number(value)
            })
            break
        case "contentUserProductionCommentCountEq":
            into.setter({
                ...into.value,
                contentUserProductionCommentCountEq: Number(value)
            })
            break
        case "contentUserProductionCommentCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionCommentCountNeq: Number(value)
            })
            break
        case "contentUserProductionCommentCountMin":
            into.setter({
                ...into.value,
                contentUserProductionCommentCountMin: Number(value)
            })
            break
        case "contentUserProductionCommentCountMax":
            into.setter({
                ...into.value,
                contentUserProductionCommentCountMax: Number(value)
            })
            break
        case "contentUserProductionCommentLikeCountEq":
            into.setter({
                ...into.value,
                contentUserProductionCommentLikeCountEq: Number(value)
            })
            break
        case "contentUserProductionCommentLikeCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionCommentLikeCountNeq: Number(value)
            })
            break
        case "contentUserProductionCommentLikeCountMin":
            into.setter({
                ...into.value,
                contentUserProductionCommentLikeCountMin: Number(value)
            })
            break
        case "contentUserProductionCommentLikeCountMax":
            into.setter({
                ...into.value,
                contentUserProductionCommentLikeCountMax: Number(value)
            })
            break
        case "contentUserProductionCommentReportCountEq":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportCountEq: Number(value)
            })
            break
        case "contentUserProductionCommentReportCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportCountNeq: Number(value)
            })
            break
        case "contentUserProductionCommentReportCountMin":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportCountMin: Number(value)
            })
            break
        case "contentUserProductionCommentReportCountMax":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportCountMax: Number(value)
            })
            break
        case "contentUserProductionCommentReportedCountEq":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportedCountEq: Number(value)
            })
            break
        case "contentUserProductionCommentReportedCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportedCountNeq: Number(value)
            })
            break
        case "contentUserProductionCommentReportedCountMin":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportedCountMin: Number(value)
            })
            break
        case "contentUserProductionCommentReportedCountMax":
            into.setter({
                ...into.value,
                contentUserProductionCommentReportedCountMax: Number(value)
            })
            break
        case "contentUserProductionReviewPositiveCountEq":
            into.setter({
                ...into.value,
                contentUserProductionReviewPositiveCountEq: Number(value)
            })
            break
        case "contentUserProductionReviewPositiveCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionReviewPositiveCountNeq: Number(value)
            })
            break
        case "contentUserProductionReviewPositiveCountMin":
            into.setter({
                ...into.value,
                contentUserProductionReviewPositiveCountMin: Number(value)
            })
            break
        case "contentUserProductionReviewPositiveCountMax":
            into.setter({
                ...into.value,
                contentUserProductionReviewPositiveCountMax: Number(value)
            })
            break
        case "contentUserProductionReviewNeutralCountEq":
            into.setter({
                ...into.value,
                contentUserProductionReviewNeutralCountEq: Number(value)
            })
            break
        case "contentUserProductionReviewNeutralCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionReviewNeutralCountNeq: Number(value)
            })
            break
        case "contentUserProductionReviewNeutralCountMin":
            into.setter({
                ...into.value,
                contentUserProductionReviewNeutralCountMin: Number(value)
            })
            break
        case "contentUserProductionReviewNeutralCountMax":
            into.setter({
                ...into.value,
                contentUserProductionReviewNeutralCountMax: Number(value)
            })
            break
        case "contentUserProductionReviewNegativeCountEq":
            into.setter({
                ...into.value,
                contentUserProductionReviewNegativeCountEq: Number(value)
            })
            break
        case "contentUserProductionReviewNegativeCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionReviewNegativeCountNeq: Number(value)
            })
            break
        case "contentUserProductionReviewNegativeCountMin":
            into.setter({
                ...into.value,
                contentUserProductionReviewNegativeCountMin: Number(value)
            })
            break
        case "contentUserProductionReviewNegativeCountMax":
            into.setter({
                ...into.value,
                contentUserProductionReviewNegativeCountMax: Number(value)
            })
            break
        case "userBlockCountEq":
            into.setter({
                ...into.value,
                userBlockCountEq: Number(value)
            })
            break
        case "userBlockCountNeq":
            into.setter({
                ...into.value,
                userBlockCountNeq: Number(value)
            })
            break
        case "userBlockCountMin":
            into.setter({
                ...into.value,
                userBlockCountMin: Number(value)
            })
            break
        case "userBlockCountMax":
            into.setter({
                ...into.value,
                userBlockCountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
