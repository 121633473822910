import {AssetStarUsageType} from "../../../data/constant/asset/AssetStarUsageType"
import {AssetStarUsagePostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetStarUsagePostDataTransferObjectDescription"
import {AssetStarUsagePostState} from "../../../data/data-transfer-object-state/asset/AssetStarUsagePostState"
import {AssetStarUsagePostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetStarUsagePostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetStarUsagePostState
}

export const AssetStarUsagePostField: Record<Capitalize<keyof AssetStarUsagePostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    UserId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetStarUsagePostDataTransferObjectDescription.userId}>
                <PostBigIntField
                    {...props}
                    value={props.state.userId.value}
                    setter={props.state.userId.setter} />
            </PostFieldWrapper>
        )
    },
    Type(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetStarUsagePostDataTransferObjectDescription.type}>
                <PostEnumField
                    {...props}
                    value={props.state.type.value}
                    setter={props.state.type.setter}
                    selections={AssetStarUsageType.selections} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetStarUsagePostDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetStarUsagePostFields(props: PostFieldProps) {
    return <>
        <AssetStarUsagePostField.UserId
            {...props}
            state={props.state} />
        <AssetStarUsagePostField.Type
            {...props}
            state={props.state} />
        <AssetStarUsagePostField.Amount
            {...props}
            state={props.state} />
    </>
}
