import {ContentParticipationDomain} from "../../domain/content/ContentParticipationDomain"

/**
 * 콘텐츠 참여 속성 설명
 */
export const ContentParticipationDescription: Record<keyof ContentParticipationDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    contentId: '콘텐츠 ID',
    assetSolarUsageId: '솔라 지급 ID',
    assetStarUsageId: '스타 사용 ID',
    audioId: '오디오 ID',
    transcription: '인식된 텍스트',
    accuracy: '일치율(%)',
    isPassed: '합격 여부',
    isHighAccuracy: '높은 일치율 여부',
    createdAt: '생성일'
}
