import {UserRankDomain} from "../../domain/user/UserRankDomain"
import {UserId} from "../../id/user/UserId"
import {UserRankId} from "../../id/user/UserRankId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 랭크
 */
export class UserRankValueObject implements ValueObject, UserRankDomain {
    /**
     * 기본키
     */
    id: UserRankId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 이름
     */
    name: string
    /**
     * 백분위
     */
    percentile?: number
    /**
     * 고정
     */
    fixed?: number
    /**
     * 적용 우선 순위
     */
    applyOrder: number
    /**
     * 보상 루나
     */
    rewardAssetLunarAmount: number
    /**
     * 보상 솔라
     */
    rewardAssetSolarAmount: number
    /**
     * 보상 스타
     */
    rewardAssetStarAmount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof UserRankDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.name = String(o.name)
        if (o.percentile !== undefined && o.percentile !== null) {
            this.percentile = Number(o.percentile)
        }
        if (o.fixed !== undefined && o.fixed !== null) {
            this.fixed = Number(o.fixed)
        }
        this.applyOrder = Number(o.applyOrder)
        this.rewardAssetLunarAmount = Number(o.rewardAssetLunarAmount)
        this.rewardAssetSolarAmount = Number(o.rewardAssetSolarAmount)
        this.rewardAssetStarAmount = Number(o.rewardAssetStarAmount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
