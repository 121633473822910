import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {
    UserWithdrawalReasonVerboseValueObject
} from "../../../../data/value-object/user/UserWithdrawalReasonVerboseValueObject";
import {useEffect} from "react";
import {UserWithdrawalIO, UserWithdrawalReasonIO, UserWithdrawalReasonModificationIO} from "../../../../io/UserIO";
import {Body1, Button, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {DeleteDialog} from "../../../DetailViews";
import {UserWithdrawalReasonDomain} from "../../../../data/domain/user/UserWithdrawalReasonDomain";
import {UserWithdrawalReasonDescriptor} from "../../../../data/descriptor/user/UserWithdrawalReasonDescriptor";
import {mapByKey} from "../../../../util/ObjectUtil";
import {UserWithdrawalReasonDescription} from "../../../../data/description/user/UserWithdrawalReasonDescription";
import {
    UserWithdrawalReasonModificationValueObject
} from "../../../../data/value-object/user/UserWithdrawalReasonModificationValueObject";
import {
    UserWithdrawalReasonModificationSearchOption
} from "../../../../data/search-option/user/UserWithdrawalReasonModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {
    UserWithdrawalReasonModificationDescription
} from "../../../../data/description/user/UserWithdrawalReasonModificationDescription";
import {
    UserWithdrawalReasonModificationDescriptor
} from "../../../../data/descriptor/user/UserWithdrawalReasonModificationDescriptor";
import {UserWithdrawalValueObject} from "../../../../data/value-object/user/UserWithdrawalValueObject";
import {UserWithdrawalSearchOption} from "../../../../data/search-option/user/UserWithdrawalSearchOption";
import {UserWithdrawalDescription} from "../../../../data/description/user/UserWithdrawalDescription";
import {UserWithdrawalDescriptor} from "../../../../data/descriptor/user/UserWithdrawalDescriptor";
enum Panel {
    Default,
    UserWithdrawalReasonModificationList,
    UserWithdrawalList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userWithdrawalReasonId: string }>()
    const item = useStatePair<UserWithdrawalReasonVerboseValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userWithdrawalReasonId !== undefined) {
            UserWithdrawalReasonIO
                .getVerboseById(BigInt(params.userWithdrawalReasonId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserWithdrawalReasonVerboseValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    const onDeleteClick = () => {
        isDeleting.setTrue()
        UserWithdrawalReasonIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/users/withdrawals/reasons'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserWithdrawalReasonModificationList}>수정 이력</Tab>
                <Tab value={Panel.UserWithdrawalList}>신고 목록</Tab>
            </TabList>
            <DefaultPanel
                {...props}
                panel={panel.value}>
                {props.item.concealedAt === undefined && <>
                    <div className={props.styles.row8}>
                        <Button
                            onClick={() => document.location = `/users/withdrawals/reasons/${props.item.id}/edit`}>수정</Button>
                        <Button onClick={isDeleteDialogVisible.setTrue}>삭제</Button>
                    </div>
                </>}
            </DefaultPanel>
            <UserWithdrawalReasonModificationListPanel
                {...props}
                panel={panel.value}/>
            <UserWithdrawalListPanel
                {...props}
                panel={panel.value}/>
        </div>
        <DeleteDialog
            {...props}
            visible={isDeleteDialogVisible.value}
            disabled={isDeleting.value}
            onConfirmClick={onDeleteClick}
            onCancelClick={() => isDeleteDialogVisible.setFalse()}/>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserWithdrawalReasonDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "body":
        case "displayOrder":
        case "userWithdrawalCount":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{UserWithdrawalReasonDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserWithdrawalReasonDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(UserWithdrawalReasonDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{UserWithdrawalReasonDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}

function UserWithdrawalReasonModificationListPanel(props: PanelProps) {
    const items = useStatePair<UserWithdrawalReasonModificationValueObject[]>([])
    const option: UserWithdrawalReasonModificationSearchOption = { userWithdrawalReasonIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserWithdrawalReasonModificationList && <>
            <ListPageWrapper
                {...props}
                client={UserWithdrawalReasonModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={UserWithdrawalReasonModificationDescription}
                descriptor={UserWithdrawalReasonModificationDescriptor} />
        </>}
    </>
}

function UserWithdrawalListPanel(props: PanelProps) {
    const modifications = useStatePair<UserWithdrawalValueObject[]>([])
    const modificationSearchOption: UserWithdrawalSearchOption = { userWithdrawalReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserWithdrawalList && <>
            <ListPageWrapper
                {...props}
                client={UserWithdrawalIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UserWithdrawalDescription}
                descriptor={UserWithdrawalDescriptor}
                onItemClick={item => `/users/withdrawals/${item.id}`} />
        </>}
    </>
}