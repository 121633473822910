import {ContentReportReasonSearchOption} from "../../search-option/content/ContentReportReasonSearchOption"

/**
 * 콘텐츠 신고 사유 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentReportReasonSearchOptionDescription: Record<keyof ContentReportReasonSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    displayOrderEq: '표시 순서 일치',
    displayOrderNeq: '표시 순서 불일치',
    displayOrderMin: '표시 순서 최소',
    displayOrderMax: '표시 순서 최대',
    reportCountEq: '신고 수 일치',
    reportCountNeq: '신고 수 불일치',
    reportCountMin: '신고 수 최소',
    reportCountMax: '신고 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
