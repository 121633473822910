import {UserNotificationModificationDomain} from "../../domain/user/UserNotificationModificationDomain"

/**
 * 사용자 알림 수정 이력 속성 설명
 */
export const UserNotificationModificationDescription: Record<keyof UserNotificationModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    userNotificationId: '사용자 알림 ID',
    title: '제목',
    body: '내용',
    subject: '광고 주제',
    deepLink: '딥링크',
    statusFlags: '상태',
    createdAt: '수정일'
}
