import {AssetStarExchangeOptionDomain} from "../../domain/asset/AssetStarExchangeOptionDomain"

export const AssetStarExchangeOptionDescriptor: Record<keyof AssetStarExchangeOptionDomain, (o: AssetStarExchangeOptionDomain) => string> = {
    id(o: AssetStarExchangeOptionDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetStarExchangeOptionDomain): string {
        return o.creatorId.toString()
    },
    starAmount(o: AssetStarExchangeOptionDomain): string {
        return o.starAmount.toString()
    },
    solarAmount(o: AssetStarExchangeOptionDomain): string {
        return o.solarAmount.toString()
    },
    createdAt(o: AssetStarExchangeOptionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: AssetStarExchangeOptionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
