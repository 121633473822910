import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentPolicyModificationVerboseDomain} from "../../domain/content/ContentPolicyModificationVerboseDomain"
import {ContentPolicyId} from "../../id/content/ContentPolicyId"
import {ContentPolicyModificationId} from "../../id/content/ContentPolicyModificationId"
import {UserId} from "../../id/user/UserId"
import {UserLevelId} from "../../id/user/UserLevelId"
import {UserLevelValueObject} from "../user/UserLevelValueObject"
import {UserValueObject} from "../user/UserValueObject"
import {ContentPolicyValueObject} from "./ContentPolicyValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 정책 수정 이력 상세
 */
export class ContentPolicyModificationVerboseValueObject implements VerboseValueObject, ContentPolicyModificationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentPolicyModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 콘텐츠 정책 ID
     */
    contentPolicyId: ContentPolicyId
    /**
     * 콘텐츠 페이지 크기
     */
    contentPageSize: number
    /**
     * 자동 숨김을 위한 신고 수
     */
    contentReportAutoHiddenCount: number
    /**
     * 콘텐츠 댓글 페이지 크기
     */
    contentCommentPageSize: number
    /**
     * 콘텐츠 음성 댓글 소모 솔라
     */
    contentCommentWithAudioSolarAmount: number
    /**
     * 콘텐츠 댓글 자동 숨김 신고 수
     */
    contentCommentReportAutoHiddenCount: number
    /**
     * 콘텐츠 댓글 작성 제한 단위 시간
     */
    contentCommentThresholdMinutes: number
    /**
     * 콘텐츠 댓글 작성 제한 횟수
     */
    contentCommentThresholdCount: number
    /**
     * 콘텐츠 댓글 좋아요 페이지 크기
     */
    contentCommentLikePageSize: number
    /**
     * 콘텐츠 댓글 신고 페이지 크기
     */
    contentCommentReportPageSize: number
    /**
     * 콘텐츠 댓글 신고 사유 페이지 크기
     */
    contentCommentReportReasonPageSize: number
    /**
     * 콘텐츠 참여 페이지 크기
     */
    contentParticipationPageSize: number
    /**
     * 콘텐츠 참여 EXP(오늘의 단어)
     */
    contentDailyParticipationExp: number
    /**
     * 콘텐츠 합격 EXP(오늘의 단어)
     */
    contentDailyParticipationPassExp: number
    /**
     * 콘텐츠 참여 제한 단위 시간(따라말하기)
     */
    contentRepeatParticipationThresholdMinutes: number
    /**
     * 콘텐츠 참여 제한 횟수(따라말하기)
     */
    contentRepeatParticipationThresholdCount: number
    /**
     * 콘텐츠 참여 EXP(따라말하기)
     */
    contentRepeatParticipationExp: number
    /**
     * 콘텐츠 합격 EXP(따라말하기)
     */
    contentRepeatParticipationPassExp: number
    /**
     * 콘텐츠 참여 제한 단위 시간(암기하기)
     */
    contentM10nParticipationThresholdMinutes: number
    /**
     * 콘텐츠 참여 제한 횟수(암기하기)
     */
    contentM10nParticipationThresholdCount: number
    /**
     * 콘텐츠 참여 EXP(암기하기)
     */
    contentM10nParticipationExp: number
    /**
     * 콘텐츠 합격 EXP(암기하기)
     */
    contentM10nParticipationPassExp: number
    /**
     * 콘텐츠 참여 제한 단위 시간(와글와글)
     */
    contentUserProductionParticipationThresholdMinutes: number
    /**
     * 콘텐츠 참여 제한 횟수(와글와글)
     */
    contentUserProductionParticipationThresholdCount: number
    /**
     * 콘텐츠 참여 EXP(와글와글)
     */
    contentUserProductionParticipationExp: number
    /**
     * 콘텐츠 합격 EXP(와글와글)
     */
    contentUserProductionParticipationPassExp: number
    /**
     * 콘텐츠 프리셋 페이지 크기(와글와글)
     */
    contentUserProductionPresetPageSize: number
    /**
     * 콘텐츠 제작 최소 루나
     */
    contentUserProductionMinimumAssetLunarAmount: number
    /**
     * 콘텐츠 제작 최소 레벨
     */
    contentUserProductionMinimumUserLevelId: UserLevelId
    /**
     * 콘텐츠 제작 최소 일치율
     */
    contentUserProductionMinimumAccuracy: number
    /**
     * 콘텐츠 일치율(와글와글)
     */
    contentUserProductionPassAccuracy: number
    /**
     * 콘텐츠 높은 일치율(와글와글)
     */
    contentUserProductionPassHighAccuracy: number
    /**
     * 콘텐츠 높은 일치율 지급 솔라(와글와글)
     */
    contentUserProductionPassSolarAmountHighAccuracy: number
    /**
     * 콘텐츠 소모 스타(와글와글)
     */
    contentUserProductionStarAmount: number
    /**
     * 콘텐츠 더 알아보기 기본 URL
     */
    contentUserProductionDefaultDetailUrl: string
    /**
     * 콘텐츠 더 알아보기 기본 브라우저
     */
    contentUserProductionDefaultDetailUrlBrowser: EnvBrowser
    /**
     * 콘텐츠 참여 결과 기본 URL
     */
    contentUserProductionDefaultResultUrl: string
    /**
     * 콘텐츠 참여 결과 기본 브라우저
     */
    contentUserProductionDefaultResultUrlBrowser: EnvBrowser
    /**
     * 콘텐츠 스크립트 페이지 크기(와글와글)
     */
    contentUserProductionScriptPageSize: number
    /**
     * 콘텐츠 좋아요 페이지 크기
     */
    contentLikePageSize: number
    /**
     * 콘텐츠 정책 페이지 크기
     */
    contentPolicyPageSize: number
    /**
     * 콘텐츠 검색 페이지 크기
     */
    contentQueryPageSize: number
    /**
     * 콘텐츠 인기 검색어 갱신 주기(분)
     */
    contentQueryPopularDurationMinutes: number
    /**
     * 콘텐츠 인기 검색어 페이지 크기
     */
    contentQueryPopularPageSize: number
    /**
     * 콘텐츠 신고 사유 페이지 크기
     */
    contentReportReasonPageSize: number
    /**
     * 콘텐츠 신고 페이지 크기
     */
    contentReportPageSize: number
    /**
     * 콘텐츠 일일 신고 가능 횟수
     */
    contentReportDailyThreshold: number
    /**
     * 콘텐츠 더 알아보기 URL 페이지 크기
     */
    contentDetailUrlPageSize: number
    /**
     * 콘텐츠 더 알아보기 URL 클릭 페이지 크기
     */
    contentDetailUrlClickPageSize: number
    /**
     * 콘텐츠 더 알아보기 URL 클릭 경험치
     */
    contentDetailUrlClickExp: number
    /**
     * 콘텐츠 참여 결과 URL 페이지 크기
     */
    contentResultUrlPageSize: number
    /**
     * 콘텐츠 참여 결과 URL 클릭 페이지 크기
     */
    contentResultUrlClickPageSize: number
    /**
     * 콘텐츠 참여 결과 URL 클릭 경험치
     */
    contentResultUrlClickExp: number
    /**
     * 콘텐츠 평가 페이지 크기(와글와글)
     */
    contentReviewPageSize: number
    /**
     * 콘텐츠 대상 페이지 크기
     */
    contentTargetPageSize: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 콘텐츠 정책 ID
     */
    contentPolicy: ContentPolicyValueObject
    /**
     * 콘텐츠 제작 최소 레벨
     */
    contentUserProductionMinimumUserLevel: UserLevelValueObject

    constructor(o: Record<keyof ContentPolicyModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentPolicyId = BigInt(o.contentPolicyId)
        this.contentPageSize = Number(o.contentPageSize)
        this.contentReportAutoHiddenCount = Number(o.contentReportAutoHiddenCount)
        this.contentCommentPageSize = Number(o.contentCommentPageSize)
        this.contentCommentWithAudioSolarAmount = Number(o.contentCommentWithAudioSolarAmount)
        this.contentCommentReportAutoHiddenCount = Number(o.contentCommentReportAutoHiddenCount)
        this.contentCommentThresholdMinutes = Number(o.contentCommentThresholdMinutes)
        this.contentCommentThresholdCount = Number(o.contentCommentThresholdCount)
        this.contentCommentLikePageSize = Number(o.contentCommentLikePageSize)
        this.contentCommentReportPageSize = Number(o.contentCommentReportPageSize)
        this.contentCommentReportReasonPageSize = Number(o.contentCommentReportReasonPageSize)
        this.contentParticipationPageSize = Number(o.contentParticipationPageSize)
        this.contentDailyParticipationExp = Number(o.contentDailyParticipationExp)
        this.contentDailyParticipationPassExp = Number(o.contentDailyParticipationPassExp)
        this.contentRepeatParticipationThresholdMinutes = Number(o.contentRepeatParticipationThresholdMinutes)
        this.contentRepeatParticipationThresholdCount = Number(o.contentRepeatParticipationThresholdCount)
        this.contentRepeatParticipationExp = Number(o.contentRepeatParticipationExp)
        this.contentRepeatParticipationPassExp = Number(o.contentRepeatParticipationPassExp)
        this.contentM10nParticipationThresholdMinutes = Number(o.contentM10nParticipationThresholdMinutes)
        this.contentM10nParticipationThresholdCount = Number(o.contentM10nParticipationThresholdCount)
        this.contentM10nParticipationExp = Number(o.contentM10nParticipationExp)
        this.contentM10nParticipationPassExp = Number(o.contentM10nParticipationPassExp)
        this.contentUserProductionParticipationThresholdMinutes = Number(o.contentUserProductionParticipationThresholdMinutes)
        this.contentUserProductionParticipationThresholdCount = Number(o.contentUserProductionParticipationThresholdCount)
        this.contentUserProductionParticipationExp = Number(o.contentUserProductionParticipationExp)
        this.contentUserProductionParticipationPassExp = Number(o.contentUserProductionParticipationPassExp)
        this.contentUserProductionPresetPageSize = Number(o.contentUserProductionPresetPageSize)
        this.contentUserProductionMinimumAssetLunarAmount = Number(o.contentUserProductionMinimumAssetLunarAmount)
        this.contentUserProductionMinimumUserLevelId = BigInt(o.contentUserProductionMinimumUserLevelId)
        this.contentUserProductionMinimumAccuracy = Number(o.contentUserProductionMinimumAccuracy)
        this.contentUserProductionPassAccuracy = Number(o.contentUserProductionPassAccuracy)
        this.contentUserProductionPassHighAccuracy = Number(o.contentUserProductionPassHighAccuracy)
        this.contentUserProductionPassSolarAmountHighAccuracy = Number(o.contentUserProductionPassSolarAmountHighAccuracy)
        this.contentUserProductionStarAmount = Number(o.contentUserProductionStarAmount)
        this.contentUserProductionDefaultDetailUrl = String(o.contentUserProductionDefaultDetailUrl)
        this.contentUserProductionDefaultDetailUrlBrowser = o.contentUserProductionDefaultDetailUrlBrowser as EnvBrowser
        this.contentUserProductionDefaultResultUrl = String(o.contentUserProductionDefaultResultUrl)
        this.contentUserProductionDefaultResultUrlBrowser = o.contentUserProductionDefaultResultUrlBrowser as EnvBrowser
        this.contentUserProductionScriptPageSize = Number(o.contentUserProductionScriptPageSize)
        this.contentLikePageSize = Number(o.contentLikePageSize)
        this.contentPolicyPageSize = Number(o.contentPolicyPageSize)
        this.contentQueryPageSize = Number(o.contentQueryPageSize)
        this.contentQueryPopularDurationMinutes = Number(o.contentQueryPopularDurationMinutes)
        this.contentQueryPopularPageSize = Number(o.contentQueryPopularPageSize)
        this.contentReportReasonPageSize = Number(o.contentReportReasonPageSize)
        this.contentReportPageSize = Number(o.contentReportPageSize)
        this.contentReportDailyThreshold = Number(o.contentReportDailyThreshold)
        this.contentDetailUrlPageSize = Number(o.contentDetailUrlPageSize)
        this.contentDetailUrlClickPageSize = Number(o.contentDetailUrlClickPageSize)
        this.contentDetailUrlClickExp = Number(o.contentDetailUrlClickExp)
        this.contentResultUrlPageSize = Number(o.contentResultUrlPageSize)
        this.contentResultUrlClickPageSize = Number(o.contentResultUrlClickPageSize)
        this.contentResultUrlClickExp = Number(o.contentResultUrlClickExp)
        this.contentReviewPageSize = Number(o.contentReviewPageSize)
        this.contentTargetPageSize = Number(o.contentTargetPageSize)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.contentPolicy = new ContentPolicyValueObject(o.contentPolicy)
        this.contentUserProductionMinimumUserLevel = new UserLevelValueObject(o.contentUserProductionMinimumUserLevel)
    }
}
