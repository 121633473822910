import {UserRankDomain} from "../../domain/user/UserRankDomain"

/**
 * 사용자 랭크 속성 설명
 */
export const UserRankDescription: Record<keyof UserRankDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    name: '이름',
    percentile: '백분위',
    fixed: '고정',
    applyOrder: '적용 우선 순위',
    rewardAssetLunarAmount: '보상 루나',
    rewardAssetSolarAmount: '보상 솔라',
    rewardAssetStarAmount: '보상 스타',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
