import {UserLevelModificationVerboseDomain} from "../../domain/user/UserLevelModificationVerboseDomain"
import {UserId} from "../../id/user/UserId"
import {UserLevelId} from "../../id/user/UserLevelId"
import {UserLevelModificationId} from "../../id/user/UserLevelModificationId"
import {UserLevelValueObject} from "./UserLevelValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 레벨 수정 이력 상세
 */
export class UserLevelModificationVerboseValueObject implements VerboseValueObject, UserLevelModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UserLevelModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 레벨 ID
     */
    userLevelId: UserLevelId
    /**
     * 이름
     */
    name: string
    /**
     * 필요 경험치
     */
    exp: number
    /**
     * 보상 루나
     */
    rewardAssetLunarAmount: number
    /**
     * 보상 솔라
     */
    rewardAssetSolarAmount: number
    /**
     * 보상 스타
     */
    rewardAssetStarAmount: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 레벨 ID
     */
    userLevel: UserLevelValueObject

    constructor(o: Record<keyof UserLevelModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userLevelId = BigInt(o.userLevelId)
        this.name = String(o.name)
        this.exp = Number(o.exp)
        this.rewardAssetLunarAmount = Number(o.rewardAssetLunarAmount)
        this.rewardAssetSolarAmount = Number(o.rewardAssetSolarAmount)
        this.rewardAssetStarAmount = Number(o.rewardAssetStarAmount)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.userLevel = new UserLevelValueObject(o.userLevel)
    }
}
