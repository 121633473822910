import {RouterProps} from "./RouterProps";
import {
    Button, Caption1Strong,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle, Tab, TabList
} from "@fluentui/react-components";
import {StatePair, useBooleanPair} from "react-type-extension";
import {mapByKey} from "../util/ObjectUtil";
import {JSX} from "react";
import {BaseHttpDelete} from "../io/HttpClient";
import {StorageIO} from "../io/StorageIO";

export type DeleteDialogProps = RouterProps & {
    visible?: boolean
    disabled?: boolean
    onConfirmClick?: () => void
    onCancelClick?: () => void
}

export function DeleteDialog(props: DeleteDialogProps) {
    return <>
        <Dialog open={props.visible === true}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>삭제</DialogTitle>
                    <DialogContent>이 항목을 삭제하시겠습니까?</DialogContent>
                </DialogBody>
                <DialogActions>
                    <Button
                        disabled={props.disabled}
                        onClick={props.onConfirmClick}>예</Button>
                    <Button
                        disabled={props.disabled}
                        onClick={props.onCancelClick}>아니오</Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    </>
}

export type DetailActionProps<T extends { id: Id, concealedAt?: Date }, Id> = RouterProps & {
    item: T
    client?: BaseHttpDelete<Id>
    hideEdit?: boolean
    hideDelete?: boolean
    storageKeys?: () => (string | string[])
    children?: false | JSX.Element | JSX.Element[]
}

export function DetailAction<T extends { id: Id, concealedAt?: Date }, Id>(props: DetailActionProps<T, Id>) {
    const isDeleting = useBooleanPair(false)
    const isDeleteDialogVisible = useBooleanPair(false)

    const onDeleteClick = () => {
        const client = props.client
        if (client === undefined) {
            throw Error('client is undefined.')
        }

        performDelete(client)
            .then(() => {
                alert('삭제되었습니다.')
                const tokens = document.location.pathname.split('/')
                document.location = tokens.slice(0, tokens.length - 1).join('/')
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    const performDelete = async (client: BaseHttpDelete<Id>) => {
        isDeleting.setTrue()
        await client.delete(props.item.id)
        const storageKeys = props.storageKeys?.() ?? []
        if (Array.isArray(storageKeys)) {
            for (const storageKey of storageKeys) {
                await StorageIO.deleteObject(storageKey)
            }
        } else {
            await StorageIO.deleteObject(storageKeys)
        }
    }

    return <>
        {props.item.concealedAt === undefined && <>
            <div className={props.styles.row8}>
                {props.hideEdit !== true && <Button onClick={() => document.location = document.location.pathname + '/edit'}>수정</Button>}
                {props.hideDelete !== true && <Button onClick={() => isDeleteDialogVisible.setTrue()}>삭제</Button>}
                {props.children}
            </div>
        </>}
        <DeleteDialog
            {...props}
            visible={isDeleteDialogVisible.value}
            disabled={isDeleting.value}
            onConfirmClick={onDeleteClick}
            onCancelClick={isDeleteDialogVisible.setFalse}/>
    </>
}

export type DetailDefaultPanelProps<T extends { id: Id, concealedAt?: Date }, Id> = RouterProps & DetailActionProps<T, Id> & {
    visible?: boolean
    description: Record<keyof T, string>
    properties?: (props: RouterProps & { item: T, itemKey: keyof T }) => false | JSX.Element | JSX.Element[]
    panelChildren?: false | JSX.Element | JSX.Element[]
    actionChildren?: false | JSX.Element | JSX.Element[]
}

export function DetailDefaultPanel<T extends { id: Id, concealedAt?: Date }, Id>(props: DetailDefaultPanelProps<T, Id>) {
    return <>
        {props.visible !== false && <>
            <div className={props.styles.column16}>
                {mapByKey(props.description, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{props.description[key]}</Caption1Strong>
                        {props.properties?.({...props, itemKey: key})}
                    </div>
                </>)}
                {props.panelChildren}
                <DetailAction {...props}>{props.actionChildren}</DetailAction>
            </div>
        </>}
    </>
}

export type DetailTabProps<T> = RouterProps & {
    panel: StatePair<T>
    children?: false | JSX.Element | JSX.Element[]
}

export function DetailTab<T extends string | number>(props: DetailTabProps<T>) {
    return <>
        <TabList
            selectedValue={props.panel.value}
            onTabSelect={(_, data) => props.panel.setter(data.value as T)}
            vertical>
            {props.children}
        </TabList>
    </>
}

export function DetailDefaultTab(props: RouterProps) {
    return <>
        <DetailTab
            {...props}
            panel={{ value: 0, setter: () => {} }}>
            <Tab value={0}>상세</Tab>
        </DetailTab>
    </>
}

// export type DetailListPanelProps<VO, SO> = Omit<ListPageWrapperProps<VO, SO>, 'items' | 'index'> & {
//     option: SO
// }
//
// export function DetailListPanel<VO extends { id: bigint }, SO>(props: DetailListPanelProps<VO, SO>) {
//     const items = useStatePair<VO[]>([])
//     const index = useStatePair<SearchIndex>(searchIndex())
//
//     return <>
//         <ListPageWrapper
//             {...props}
//             items={items}
//             index={index} />
//     </>
// }
//
// export type DetailPagePanel = { title: string, element: false | JSX.Element | JSX.Element[] }
//
// export type DetailPageProps<Id, VO extends { id: Id, concealedAt?: Date }> = RouterProps & Omit<DetailDefaultPanelProps<VO, Id>, 'item'> & {
//     client: BaseHttpDelete<Id> & BaseHttpGetById<Id, VO, any>
//     id: Id | undefined
//     listPanel?: (item: VO) => DetailPagePanel[]
// }
//
// export function DetailPage<T extends { id: Id, concealedAt?: Date }, Id>(props: DetailPageProps<Id, T>) {
//     const item = useStatePair<T | undefined>(undefined)
//     const panelIndex = useStatePair(0)
//     const panels = useStatePair<DetailPagePanel[] | undefined>(undefined)
//
//     useEffect(() => {
//         if (props.id !== undefined) {
//             props.client
//                 .getById(props.id)
//                 .then(item.setter)
//                 .catch(reason => {
//                     console.error(reason)
//                     alert('오류가 발생했습니다.')
//                 })
//         }
//     }, []);
//
//     useEffect(() => {
//         if (item.value === undefined) {
//             return
//         }
//
//         panels.setter([
//             {
//                 title: '상세',
//                 element: <DetailDefaultPanel {...props} item={item.value}/>
//             },
//             ...(props.listPanel?.(item.value) ?? [])
//         ])
//     }, [item.value]);
//
//     return <>
//         {(item.value === undefined || panels.value === undefined) && <Spinner />}
//         {(item.value !== undefined && panels.value !== undefined) && <>
//             <div className={props.styles.row16}>
//                 <TabList
//                     selectedValue={panelIndex.value}
//                     onTabSelect={(_, data) => panelIndex.setter(data.value as number)}
//                     vertical>
//                     {panels.value.map((panel, index) => <>
//                         <Tab
//                             value={index}
//                             key={`detail-tab-${index}`}>
//                             {panel.title}
//                         </Tab>
//                     </>)}
//                 </TabList>
//                 {panels.value[panelIndex.value].element}
//             </div>
//         </>}
//     </>
// }