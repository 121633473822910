import {AssetSolarWithdrawalOptionDomain} from "../../domain/asset/AssetSolarWithdrawalOptionDomain"

export const AssetSolarWithdrawalOptionDescriptor: Record<keyof AssetSolarWithdrawalOptionDomain, (o: AssetSolarWithdrawalOptionDomain) => string> = {
    id(o: AssetSolarWithdrawalOptionDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetSolarWithdrawalOptionDomain): string {
        return o.creatorId.toString()
    },
    solarAmount(o: AssetSolarWithdrawalOptionDomain): string {
        return o.solarAmount.toString()
    },
    cashAmount(o: AssetSolarWithdrawalOptionDomain): string {
        return o.cashAmount.toString()
    },
    createdAt(o: AssetSolarWithdrawalOptionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: AssetSolarWithdrawalOptionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
