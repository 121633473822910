type Job<T> = T | PromiseLike<T>

export class Promises<T> {
    private jobs: Job<T>[] = []

    constructor(jobs?: Job<T> | Job<T>[]) {
        if (jobs !== undefined) {
            this.pend(jobs)
        }
    }

    pend(job: Job<T> | Job<T>[]) {
        if (Array.isArray(job)) {
            this.jobs.push(...job)
        } else {
            this.jobs.push(job)
        }

        return this
    }

    pendEach<E>(iterable: Iterable<E>, job: (value: E, index: number, iterable: Iterable<E>) => Job<T>) {
        let index = 0
        for (let value of iterable) {
            this.pend(job(value, index, iterable))
        }

        return this
    }

    pendIf<E>(value: NonNullable<E> | null | undefined, job: (value: NonNullable<E>) => Job<T>) {
        if (value !== null && value !== undefined) {
            this.pend(job(value))
        }

        return this
    }

    pendEachIf<E>(iterable: NonNullable<Iterable<E>> | null | undefined, job: (value: E, index: number, iterable: Iterable<E>) => Job<T>) {
        if (iterable !== null && iterable !== undefined) {
            this.pendEach(iterable, job)
        }

        return this
    }

    async await() {
        return await Promise.all(this.jobs)
    }
}