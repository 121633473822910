import {Route, Routes} from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import {RouterProps} from "../../../RouterProps";

export default function HistoryRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/:userLevelHistoryId'} element={<Detail {...props} />} />
        </Routes>
    </>
}