import {ContentCommentReportReasonSearchOptionDescription} from "../../../data/description/content/ContentCommentReportReasonSearchOptionDescription"
import {ContentCommentReportReasonSearchOption} from "../../../data/search-option/content/ContentCommentReportReasonSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentCommentReportReasonSearchOptionField(props: ListOptionFieldProps<ContentCommentReportReasonSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.bodyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.bodyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.bodyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.bodyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.bodyLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.bodyNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.displayOrderEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.displayOrderNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.displayOrderMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "displayOrderMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.displayOrderMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.reportCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.reportCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.reportCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.reportCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentCommentReportReasonSearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentCommentReportReasonSearchOption(key: keyof ContentCommentReportReasonSearchOption, value: string, into: StatePair<ContentCommentReportReasonSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "bodyEq":
            into.setter({
                ...into.value,
                bodyEq: String(value)
            })
            break
        case "bodyNeq":
            into.setter({
                ...into.value,
                bodyNeq: String(value)
            })
            break
        case "bodyMin":
            into.setter({
                ...into.value,
                bodyMin: String(value)
            })
            break
        case "bodyMax":
            into.setter({
                ...into.value,
                bodyMax: String(value)
            })
            break
        case "bodyLike":
            into.setter({
                ...into.value,
                bodyLike: String(value)
            })
            break
        case "bodyNotLike":
            into.setter({
                ...into.value,
                bodyNotLike: String(value)
            })
            break
        case "displayOrderEq":
            into.setter({
                ...into.value,
                displayOrderEq: Number(value)
            })
            break
        case "displayOrderNeq":
            into.setter({
                ...into.value,
                displayOrderNeq: Number(value)
            })
            break
        case "displayOrderMin":
            into.setter({
                ...into.value,
                displayOrderMin: Number(value)
            })
            break
        case "displayOrderMax":
            into.setter({
                ...into.value,
                displayOrderMax: Number(value)
            })
            break
        case "reportCountEq":
            into.setter({
                ...into.value,
                reportCountEq: Number(value)
            })
            break
        case "reportCountNeq":
            into.setter({
                ...into.value,
                reportCountNeq: Number(value)
            })
            break
        case "reportCountMin":
            into.setter({
                ...into.value,
                reportCountMin: Number(value)
            })
            break
        case "reportCountMax":
            into.setter({
                ...into.value,
                reportCountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
