import {AssetSolarUsageType} from "../../constant/asset/AssetSolarUsageType"
import {AssetSolarUsageDomain} from "../../domain/asset/AssetSolarUsageDomain"

export const AssetSolarUsageDescriptor: Record<keyof AssetSolarUsageDomain, (o: AssetSolarUsageDomain) => string> = {
    id(o: AssetSolarUsageDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetSolarUsageDomain): string {
        return o.creatorId.toString()
    },
    userId(o: AssetSolarUsageDomain): string {
        return o.userId.toString()
    },
    type(o: AssetSolarUsageDomain): string {
        return AssetSolarUsageType.description(o.type)
    },
    amount(o: AssetSolarUsageDomain): string {
        return o.amount.toString()
    },
    lastAccumulation(o: AssetSolarUsageDomain): string {
        return o.lastAccumulation.toString()
    },
    positiveAccumulation(o: AssetSolarUsageDomain): string {
        return o.positiveAccumulation.toString()
    },
    negativeAccumulation(o: AssetSolarUsageDomain): string {
        return o.negativeAccumulation.toString()
    },
    createdAt(o: AssetSolarUsageDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
