import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {EnvClientValueObject} from "../../../data/value-object/env/EnvClientValueObject";
import {JSX, useEffect} from "react";
import {EnvClientIO, EnvClientModificationIO} from "../../../io/EnvIO";
import {Body1, Button, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {DeleteDialog, DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {EnvClientDomain} from "../../../data/domain/env/EnvClientDomain";
import {EnvClientDescriptor} from "../../../data/descriptor/env/EnvClientDescriptor";
import {mapByKey} from "../../../util/ObjectUtil";
import {EnvClientDescription} from "../../../data/description/env/EnvClientDescription";
import {EnvClientModificationValueObject} from "../../../data/value-object/env/EnvClientModificationValueObject";
import {
    EnvClientModificationSearchOption
} from "../../../data/search-option/env/EnvClientModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvClientModificationDescription} from "../../../data/description/env/EnvClientModificationDescription";
import {EnvClientModificationDescriptor} from "../../../data/descriptor/env/EnvClientModificationDescriptor";
import {Environments} from "../../../Environments";
import {WindowNew24Regular} from "@fluentui/react-icons";
import {UserSignInValueObject} from "../../../data/value-object/user/UserSignInValueObject";
import {UserSignInSearchOption} from "../../../data/search-option/user/UserSignInSearchOption";
import {UserSignInIO} from "../../../io/UserIO";
import {UserSignInDescription} from "../../../data/description/user/UserSignInDescription";
import {UserSignInDescriptor} from "../../../data/descriptor/user/UserSignInDescriptor";

enum Panel {
    Default,
    EnvClientModificationList,
    UserSignInList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envClientId: string }>()
    const item = useStatePair<EnvClientValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envClientId !== undefined) {
            EnvClientIO
                .getById(BigInt(params.envClientId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvClientValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvClientModificationList}>수정 이력</Tab>
                <Tab value={Panel.UserSignInList}>사용자 로그인 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvClientIO}
                description={EnvClientDescription}
                properties={props => <PropertyBody {...props} />} />
            <EnvClientModificationListPanel
                {...props}
                panel={panel.value}/>
            <UserSignInListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvClientDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "platform":
        case "distribution":
        case "versionName":
        case "expiredAt":
        case "userSignInCount":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{EnvClientDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a
                href={`/users/${props.item.creatorId}`}><Body1>{EnvClientDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(EnvClientDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{EnvClientDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}

function EnvClientModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvClientModificationValueObject[]>([])
    const option: EnvClientModificationSearchOption = { envClientIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvClientModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvClientModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvClientModificationDescription}
                descriptor={EnvClientModificationDescriptor} />
        </>}
    </>
}

function UserSignInListPanel(props: PanelProps) {
    const items = useStatePair<UserSignInValueObject[]>([])
    const option: UserSignInSearchOption = { envClientIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserSignInList && <>
            <ListPageWrapper
                {...props}
                client={UserSignInIO}
                items={items}
                option={option}
                index={index}
                description={UserSignInDescription}
                descriptor={UserSignInDescriptor} />
        </>}
    </>
}