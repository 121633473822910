import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryVerboseDomain} from "../../domain/content/ContentQueryVerboseDomain"
import {ContentQueryId} from "../../id/content/ContentQueryId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 검색 상세
 */
export class ContentQueryVerboseValueObject implements VerboseValueObject, ContentQueryVerboseDomain {
    /**
     * 기본키
     */
    id: ContentQueryId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 유형
     */
    contentType: ContentType
    /**
     * 질의어
     */
    body: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof ContentQueryVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentType = o.contentType as ContentType
        this.body = String(o.body)
        this.createdAt = new Date(o.createdAt)
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.user = new UserValueObject(o.user)
    }
}
