import {AssetStarUsagePostDataTransferObject} from "../../data-transfer-object/asset/AssetStarUsagePostDataTransferObject"

/**
 * 스타 사용 및 지급 이력 속성 설명
 */
export const AssetStarUsagePostDataTransferObjectDescription: Record<keyof AssetStarUsagePostDataTransferObject, string> = {
    userId: '사용자 ID',
    type: '유형',
    amount: '변화'
}
