import {AssetLunarPurchaseOptionPutDataTransferObject} from "../../data-transfer-object/asset/AssetLunarPurchaseOptionPutDataTransferObject"
import {AssetLunarPurchaseOptionValueObject} from "../../value-object/asset/AssetLunarPurchaseOptionValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetLunarPurchaseOptionPutState = {
    lunarAmount: StatePair<number>
    cashAmount: StatePair<number>
    toDataTransferObject(): AssetLunarPurchaseOptionPutDataTransferObject
    fromValueObject(vo: AssetLunarPurchaseOptionValueObject): void
    values(): [number, number]
}

export function useAssetLunarPurchaseOptionPutState(): AssetLunarPurchaseOptionPutState {
    return {
        lunarAmount: useStatePair<number>(0),
        cashAmount: useStatePair<number>(0),
        toDataTransferObject(): AssetLunarPurchaseOptionPutDataTransferObject {
            return {
                lunarAmount: this.lunarAmount.value,
                cashAmount: this.cashAmount.value,
            }
        },
        fromValueObject(vo: AssetLunarPurchaseOptionValueObject) {
            this.lunarAmount.setter(vo.lunarAmount)
            this.cashAmount.setter(vo.cashAmount)
        },
        values(): [number, number] {
            return [
                this.lunarAmount.value,
                this.cashAmount.value,
            ]
        }
    }
}
