import {ContentReportReasonDomain} from "../../domain/content/ContentReportReasonDomain"

/**
 * 콘텐츠 신고 사유 속성 설명
 */
export const ContentReportReasonDescription: Record<keyof ContentReportReasonDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    body: '내용',
    displayOrder: '표시 순서',
    reportCount: '신고 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
