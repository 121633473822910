import {UserGender} from "../../constant/user/UserGender"
import {ContentTargetDomain} from "../../domain/content/ContentTargetDomain"

export const ContentTargetDescriptor: Record<keyof ContentTargetDomain, (o: ContentTargetDomain) => string> = {
    id(o: ContentTargetDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentTargetDomain): string {
        return o.creatorId.toString()
    },
    contentId(o: ContentTargetDomain): string {
        return o.contentId.toString()
    },
    envCountryId(o: ContentTargetDomain): string {
        return o.envCountryId?.toString() ?? '-'
    },
    userGender(o: ContentTargetDomain): string {
        if (o.userGender !== undefined) {
            return UserGender.description(o.userGender)
        } else {
            return '-'
        }
    },
    userBirthYearMin(o: ContentTargetDomain): string {
        return o.userBirthYearMin?.toString() ?? '-'
    },
    userBirthYearMax(o: ContentTargetDomain): string {
        return o.userBirthYearMax?.toString() ?? '-'
    },
    createdAt(o: ContentTargetDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentTargetDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
