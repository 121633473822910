import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    UiOnboardingValueObject
} from "../../../data/value-object/ui/UiOnboardingValueObject";
import {useCallback, useEffect} from "react";
import {UiOnboardingIO} from "../../../io/UiIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFileField, PostFilesField, PostNumberField} from "../../PostField";
import {
    UiOnboardingDescription
} from "../../../data/description/ui/UiOnboardingDescription";
import {MediaFile} from "../../Media";
import {StorageIO, StorageKey} from "../../../io/StorageIO";
import {UiOnboardingId} from "../../../data/id/ui/UiOnboardingId";
import {createMediaFileFromStorageObject} from "../../../util/FileUtils";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ uiOnboardingId: string }>()
    const item = useStatePair<UiOnboardingValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const displayOrder = useStatePair(0)
    const image = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.uiOnboardingId !== undefined) {
            UiOnboardingIO
                .getVerboseById(BigInt(params.uiOnboardingId))
                .then(value => {
                    item.setter(value)
                    displayOrder.setter(value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    useEffect(() => {
        if (params.uiOnboardingId !== undefined) {
            const objectKey = StorageKey.Ui.onboarding(BigInt(params.uiOnboardingId))
            StorageIO
                .getObjectByteArray(objectKey)
                .then(value => image.setter(createMediaFileFromStorageObject(value)))
                .catch(reason => {
                    console.error(reason)
                    alert('이미지를 불러올 수 없습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        const imageFile = image.value
        if (imageFile === undefined) {
            alert('이미지를 선택해주세요.')
            return
        }

        put(id, imageFile)
            .then(() => {
                alert('수정되었습니다.')
                document.location = `/ui/onboardings/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [displayOrder.value, image.value])

    const put = async (id: UiOnboardingId, imageFile: MediaFile) => {
        phase.setter(Phase.Putting);
        await UiOnboardingIO.put(id, { displayOrder: displayOrder.value })
        await StorageIO.putFile(StorageKey.Ui.onboarding(id), imageFile)
        phase.setter(Phase.Finished)
    }

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={UiOnboardingDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter}/>
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={'이미지'}>
                <PostFileField
                    {...props}
                    value={image.value}
                    setter={image.setter}
                    accept={'image/*'} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}