import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvPhoneBlockVerboseValueObject} from "../../../data/value-object/env/EnvPhoneBlockVerboseValueObject";
import {useCallback, useEffect} from "react";
import {EnvPhoneBlockIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostBigIntField, PostFieldWrapper, PostInputField} from "../../PostField";
import {EnvPhoneBlockDescription} from "../../../data/description/env/EnvPhoneBlockDescription";
import {EnvCountryId} from "../../../data/id/env/EnvCountryId";
import {EnvCountrySearch} from "../country/Search";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
    EnvCountryList,
    EnvCountryOption,
    EnvCountryOrder
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envPhoneBlockId: string }>()
    const item = useStatePair<EnvPhoneBlockVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const envCountryId = useStatePair<EnvCountryId>(0n)
    const phone = useStatePair('')

    useEffect(() => {
        if (params.envPhoneBlockId !== undefined) {
            EnvPhoneBlockIO
                .getVerboseById(BigInt(params.envPhoneBlockId))
                .then(value => {
                    item.setter(value)
                    envCountryId.setter(value.envCountryId)
                    phone.setter(value.phone)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        EnvPhoneBlockIO
            .put(id, {
                envCountryId: envCountryId.value,
                phone: phone.value,
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/phoneBlocks/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [envCountryId.value, phone.value])

    return <>
        {(phase.value === Phase.Waiting || phase.value === Phase.Putting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvPhoneBlockDescription.envCountryId}
                    showSearch
                    onSearchClick={() => phase.setter(Phase.EnvCountryList)}>
                    <PostBigIntField
                        {...props}
                        value={envCountryId.value}
                        setter={envCountryId.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvPhoneBlockDescription.phone}>
                    <PostInputField
                        {...props}
                        value={phone.value}
                        setter={phone.setter}/>
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <EnvCountrySearch
            {...props}
            phase={phase}
            phases={{
                waiting: Phase.Waiting,
                list: Phase.EnvCountryList,
                option: Phase.EnvCountryOption,
                order: Phase.EnvCountryOrder
            }}
            setter={value => envCountryId.setter(value.id)}/>
    </>
}