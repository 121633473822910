import {EnvFaqModificationDomain} from "../../domain/env/EnvFaqModificationDomain"
import {EnvFaqId} from "../../id/env/EnvFaqId"
import {EnvFaqModificationId} from "../../id/env/EnvFaqModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 자주 묻는 질문 수정 이력
 */
export class EnvFaqModificationValueObject implements ValueObject, EnvFaqModificationDomain {
    /**
     * 기본키
     */
    id: EnvFaqModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 자주 묻는 질문 ID
     */
    envFaqId: EnvFaqId
    /**
     * 제목
     */
    title: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvFaqModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envFaqId = BigInt(o.envFaqId)
        this.title = String(o.title)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
