import {UserWithdrawalVerboseDomain} from "../../domain/user/UserWithdrawalVerboseDomain"
import {UserId} from "../../id/user/UserId"
import {UserWithdrawalId} from "../../id/user/UserWithdrawalId"
import {UserWithdrawalReasonId} from "../../id/user/UserWithdrawalReasonId"
import {UserValueObject} from "./UserValueObject"
import {UserWithdrawalReasonValueObject} from "./UserWithdrawalReasonValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 탈퇴 상세
 */
export class UserWithdrawalVerboseValueObject implements VerboseValueObject, UserWithdrawalVerboseDomain {
    /**
     * 기본키
     */
    id: UserWithdrawalId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 사용자 탈퇴 사유 ID
     */
    userWithdrawalReasonId: UserWithdrawalReasonId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 사용자 탈퇴 사유 ID
     */
    userWithdrawalReason: UserWithdrawalReasonValueObject

    constructor(o: Record<keyof UserWithdrawalVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.userWithdrawalReasonId = BigInt(o.userWithdrawalReasonId)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.userWithdrawalReason = new UserWithdrawalReasonValueObject(o.userWithdrawalReason)
    }
}
