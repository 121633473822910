import {UserRankDuration} from "../../../data/constant/user/UserRankDuration"
import {EnvPolicySearchOptionDescription} from "../../../data/description/env/EnvPolicySearchOptionDescription"
import {EnvPolicySearchOption} from "../../../data/search-option/env/EnvPolicySearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function EnvPolicySearchOptionField(props: ListOptionFieldProps<EnvPolicySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAdminPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAdminPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAdminPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAdminPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAdminPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAdminPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAdminPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAdminPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAgreementPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAgreementPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAgreementPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAgreementPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAgreementPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAgreementPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envAgreementPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envAgreementPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envBankPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envBankPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envBankPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envBankPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envClientPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envClientPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envClientPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envClientPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envConstructionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envConstructionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envConstructionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envConstructionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envConstructionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envConstructionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envConstructionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envConstructionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envCountryRankDurationEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryRankDurationEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "envCountryRankDurationNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryRankDurationNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "envCountryRankDurationMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryRankDurationMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "envCountryRankDurationMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryRankDurationMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "envCountryRankDurationIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envCountryRankDurationIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserRankDuration.selections} />
                </ListOptionWrapper>
            </>
        case "envEventPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envEventPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envEventPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envEventPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envEventPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envEventPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envEventPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envEventPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqItemPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqItemPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqItemPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqItemPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqItemPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqItemPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envFaqItemPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envFaqItemPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envNoticePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envNoticePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envNoticePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envNoticePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envNoticePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envNoticePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envNoticePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envNoticePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPhoneBlockPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPhoneBlockPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPhoneBlockPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPhoneBlockPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPhoneBlockPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPhoneBlockPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPhoneBlockPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPhoneBlockPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPolicyPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPolicyPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPolicyPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPolicyPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPolicyPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPolicyPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envPolicyPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envPolicyPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envStorageKeyPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envStorageKeyPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envStorageKeyPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envStorageKeyPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envStorageKeyPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envStorageKeyPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envStorageKeyPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.envStorageKeyPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvPolicySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyEnvPolicySearchOption(key: keyof EnvPolicySearchOption, value: string, into: StatePair<EnvPolicySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "envAdminPageSizeEq":
            into.setter({
                ...into.value,
                envAdminPageSizeEq: Number(value)
            })
            break
        case "envAdminPageSizeNeq":
            into.setter({
                ...into.value,
                envAdminPageSizeNeq: Number(value)
            })
            break
        case "envAdminPageSizeMin":
            into.setter({
                ...into.value,
                envAdminPageSizeMin: Number(value)
            })
            break
        case "envAdminPageSizeMax":
            into.setter({
                ...into.value,
                envAdminPageSizeMax: Number(value)
            })
            break
        case "envAgreementPageSizeEq":
            into.setter({
                ...into.value,
                envAgreementPageSizeEq: Number(value)
            })
            break
        case "envAgreementPageSizeNeq":
            into.setter({
                ...into.value,
                envAgreementPageSizeNeq: Number(value)
            })
            break
        case "envAgreementPageSizeMin":
            into.setter({
                ...into.value,
                envAgreementPageSizeMin: Number(value)
            })
            break
        case "envAgreementPageSizeMax":
            into.setter({
                ...into.value,
                envAgreementPageSizeMax: Number(value)
            })
            break
        case "envBankPageSizeEq":
            into.setter({
                ...into.value,
                envBankPageSizeEq: Number(value)
            })
            break
        case "envBankPageSizeNeq":
            into.setter({
                ...into.value,
                envBankPageSizeNeq: Number(value)
            })
            break
        case "envBankPageSizeMin":
            into.setter({
                ...into.value,
                envBankPageSizeMin: Number(value)
            })
            break
        case "envBankPageSizeMax":
            into.setter({
                ...into.value,
                envBankPageSizeMax: Number(value)
            })
            break
        case "envClientPageSizeEq":
            into.setter({
                ...into.value,
                envClientPageSizeEq: Number(value)
            })
            break
        case "envClientPageSizeNeq":
            into.setter({
                ...into.value,
                envClientPageSizeNeq: Number(value)
            })
            break
        case "envClientPageSizeMin":
            into.setter({
                ...into.value,
                envClientPageSizeMin: Number(value)
            })
            break
        case "envClientPageSizeMax":
            into.setter({
                ...into.value,
                envClientPageSizeMax: Number(value)
            })
            break
        case "envConstructionPageSizeEq":
            into.setter({
                ...into.value,
                envConstructionPageSizeEq: Number(value)
            })
            break
        case "envConstructionPageSizeNeq":
            into.setter({
                ...into.value,
                envConstructionPageSizeNeq: Number(value)
            })
            break
        case "envConstructionPageSizeMin":
            into.setter({
                ...into.value,
                envConstructionPageSizeMin: Number(value)
            })
            break
        case "envConstructionPageSizeMax":
            into.setter({
                ...into.value,
                envConstructionPageSizeMax: Number(value)
            })
            break
        case "envCountryPageSizeEq":
            into.setter({
                ...into.value,
                envCountryPageSizeEq: Number(value)
            })
            break
        case "envCountryPageSizeNeq":
            into.setter({
                ...into.value,
                envCountryPageSizeNeq: Number(value)
            })
            break
        case "envCountryPageSizeMin":
            into.setter({
                ...into.value,
                envCountryPageSizeMin: Number(value)
            })
            break
        case "envCountryPageSizeMax":
            into.setter({
                ...into.value,
                envCountryPageSizeMax: Number(value)
            })
            break
        case "envCountryRankDurationEq":
            into.setter({
                ...into.value,
                envCountryRankDurationEq: value as UserRankDuration
            })
            break
        case "envCountryRankDurationNeq":
            into.setter({
                ...into.value,
                envCountryRankDurationNeq: value as UserRankDuration
            })
            break
        case "envCountryRankDurationMin":
            into.setter({
                ...into.value,
                envCountryRankDurationMin: value as UserRankDuration
            })
            break
        case "envCountryRankDurationMax":
            into.setter({
                ...into.value,
                envCountryRankDurationMax: value as UserRankDuration
            })
            break
        case "envCountryRankDurationIn":
            into.setter({
                ...into.value,
                envCountryRankDurationIn: value
                    .split('|')
                    .map(e => e as UserRankDuration)
            })
            break
        case "envEventPageSizeEq":
            into.setter({
                ...into.value,
                envEventPageSizeEq: Number(value)
            })
            break
        case "envEventPageSizeNeq":
            into.setter({
                ...into.value,
                envEventPageSizeNeq: Number(value)
            })
            break
        case "envEventPageSizeMin":
            into.setter({
                ...into.value,
                envEventPageSizeMin: Number(value)
            })
            break
        case "envEventPageSizeMax":
            into.setter({
                ...into.value,
                envEventPageSizeMax: Number(value)
            })
            break
        case "envFaqPageSizeEq":
            into.setter({
                ...into.value,
                envFaqPageSizeEq: Number(value)
            })
            break
        case "envFaqPageSizeNeq":
            into.setter({
                ...into.value,
                envFaqPageSizeNeq: Number(value)
            })
            break
        case "envFaqPageSizeMin":
            into.setter({
                ...into.value,
                envFaqPageSizeMin: Number(value)
            })
            break
        case "envFaqPageSizeMax":
            into.setter({
                ...into.value,
                envFaqPageSizeMax: Number(value)
            })
            break
        case "envFaqItemPageSizeEq":
            into.setter({
                ...into.value,
                envFaqItemPageSizeEq: Number(value)
            })
            break
        case "envFaqItemPageSizeNeq":
            into.setter({
                ...into.value,
                envFaqItemPageSizeNeq: Number(value)
            })
            break
        case "envFaqItemPageSizeMin":
            into.setter({
                ...into.value,
                envFaqItemPageSizeMin: Number(value)
            })
            break
        case "envFaqItemPageSizeMax":
            into.setter({
                ...into.value,
                envFaqItemPageSizeMax: Number(value)
            })
            break
        case "envNoticePageSizeEq":
            into.setter({
                ...into.value,
                envNoticePageSizeEq: Number(value)
            })
            break
        case "envNoticePageSizeNeq":
            into.setter({
                ...into.value,
                envNoticePageSizeNeq: Number(value)
            })
            break
        case "envNoticePageSizeMin":
            into.setter({
                ...into.value,
                envNoticePageSizeMin: Number(value)
            })
            break
        case "envNoticePageSizeMax":
            into.setter({
                ...into.value,
                envNoticePageSizeMax: Number(value)
            })
            break
        case "envPhoneBlockPageSizeEq":
            into.setter({
                ...into.value,
                envPhoneBlockPageSizeEq: Number(value)
            })
            break
        case "envPhoneBlockPageSizeNeq":
            into.setter({
                ...into.value,
                envPhoneBlockPageSizeNeq: Number(value)
            })
            break
        case "envPhoneBlockPageSizeMin":
            into.setter({
                ...into.value,
                envPhoneBlockPageSizeMin: Number(value)
            })
            break
        case "envPhoneBlockPageSizeMax":
            into.setter({
                ...into.value,
                envPhoneBlockPageSizeMax: Number(value)
            })
            break
        case "envPolicyPageSizeEq":
            into.setter({
                ...into.value,
                envPolicyPageSizeEq: Number(value)
            })
            break
        case "envPolicyPageSizeNeq":
            into.setter({
                ...into.value,
                envPolicyPageSizeNeq: Number(value)
            })
            break
        case "envPolicyPageSizeMin":
            into.setter({
                ...into.value,
                envPolicyPageSizeMin: Number(value)
            })
            break
        case "envPolicyPageSizeMax":
            into.setter({
                ...into.value,
                envPolicyPageSizeMax: Number(value)
            })
            break
        case "envStorageKeyPageSizeEq":
            into.setter({
                ...into.value,
                envStorageKeyPageSizeEq: Number(value)
            })
            break
        case "envStorageKeyPageSizeNeq":
            into.setter({
                ...into.value,
                envStorageKeyPageSizeNeq: Number(value)
            })
            break
        case "envStorageKeyPageSizeMin":
            into.setter({
                ...into.value,
                envStorageKeyPageSizeMin: Number(value)
            })
            break
        case "envStorageKeyPageSizeMax":
            into.setter({
                ...into.value,
                envStorageKeyPageSizeMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
