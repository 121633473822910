import {UserNotificationSearchOption} from "../../search-option/user/UserNotificationSearchOption"

/**
 * 사용자 알림 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserNotificationSearchOptionDescription: Record<keyof UserNotificationSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    titleEq: '제목 일치',
    titleNeq: '제목 불일치',
    titleMin: '제목 최소',
    titleMax: '제목 최대',
    titleLike: '제목 포함',
    titleNotLike: '제목 제외',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    subjectEq: '광고 주제 일치',
    subjectNeq: '광고 주제 불일치',
    subjectMin: '광고 주제 최소',
    subjectMax: '광고 주제 최대',
    subjectIn: '광고 주제 포함',
    deepLinkEq: '딥링크 일치',
    deepLinkNeq: '딥링크 불일치',
    deepLinkMin: '딥링크 최소',
    deepLinkMax: '딥링크 최대',
    deepLinkLike: '딥링크 포함',
    deepLinkNotLike: '딥링크 제외',
    deepLinkIsNull: '딥링크 값 존재 여부',
    statusFlagsEq: '상태 일치',
    statusFlagsNeq: '상태 불일치',
    statusFlagsAny: '상태 일부 해당',
    statusFlagsAll: '상태 전체 해당',
    statusFlagsNone: '상태 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
