import {ContentLikeDomain} from "../../domain/content/ContentLikeDomain"

export const ContentLikeDescriptor: Record<keyof ContentLikeDomain, (o: ContentLikeDomain) => string> = {
    id(o: ContentLikeDomain): string {
        return o.id.toString()
    },
    contentId(o: ContentLikeDomain): string {
        return o.contentId.toString()
    },
    userId(o: ContentLikeDomain): string {
        return o.userId.toString()
    },
    createdAt(o: ContentLikeDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
