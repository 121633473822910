import {ContentCommentReportDomain} from "../../domain/content/ContentCommentReportDomain"
import {ContentCommentId} from "../../id/content/ContentCommentId"
import {ContentCommentReportId} from "../../id/content/ContentCommentReportId"
import {ContentCommentReportReasonId} from "../../id/content/ContentCommentReportReasonId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 댓글 신고
 */
export class ContentCommentReportValueObject implements ValueObject, ContentCommentReportDomain {
    /**
     * 기본키
     */
    id: ContentCommentReportId
    /**
     * 댓글 ID
     */
    contentCommentId: ContentCommentId
    /**
     * 댓글 신고 사유 ID
     */
    contentCommentReportReasonId: ContentCommentReportReasonId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentCommentReportDomain, any>) {
        this.id = BigInt(o.id)
        this.contentCommentId = BigInt(o.contentCommentId)
        this.contentCommentReportReasonId = BigInt(o.contentCommentReportReasonId)
        this.userId = BigInt(o.userId)
        this.createdAt = new Date(o.createdAt)
    }
}
