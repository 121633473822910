import {ContentUserProductionDurationSearchOption} from "../../search-option/content/ContentUserProductionDurationSearchOption"

/**
 * 와글와글 기간 프리셋 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentUserProductionDurationSearchOptionDescription: Record<keyof ContentUserProductionDurationSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    valueEq: '기간(주) 일치',
    valueNeq: '기간(주) 불일치',
    valueMin: '기간(주) 최소',
    valueMax: '기간(주) 최대',
    displayOrderNeq: '표시 순서 불일치',
    displayOrderMin: '표시 순서 최소',
    displayOrderMax: '표시 순서 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
