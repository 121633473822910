import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {
    AssetStarExchangeOptionValueObject
} from "../../../../data/value-object/asset/AssetStarExchangeOptionValueObject";
import {AssetStarExchangeOptionIO} from "../../../../io/AssetIO";
import {
    AssetStarExchangeOptionDescription
} from "../../../../data/description/asset/AssetStarExchangeOptionDescription";
import {AssetStarExchangeOptionDomain} from "../../../../data/domain/asset/AssetStarExchangeOptionDomain";
import {AssetStarExchangeOptionDescriptor} from "../../../../data/descriptor/asset/AssetStarExchangeOptionDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetStarExchangeOptionId: string }>()
    const item = useStatePair<AssetStarExchangeOptionValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetStarExchangeOptionId !== undefined) {
            AssetStarExchangeOptionIO
                .getById(BigInt(params.assetStarExchangeOptionId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetStarExchangeOptionValueObject
}

function Page(props: PageProps) {
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    const onDeleteClick = () => {
        isDeleting.setTrue()
        AssetStarExchangeOptionIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/assets/stars/exchangeOptions'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetStarExchangeOptionIO}
                description={AssetStarExchangeOptionDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetStarExchangeOptionDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "solarAmount":
        case "starAmount":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{AssetStarExchangeOptionDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetStarExchangeOptionDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}