import {ContentQueryPopularPreemptionSearchOption} from "../../search-option/content/ContentQueryPopularPreemptionSearchOption"

/**
 * 콘텐츠 인기 검색어 예약 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentQueryPopularPreemptionSearchOptionDescription: Record<keyof ContentQueryPopularPreemptionSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    contentTypeEq: '콘텐츠 유형 일치',
    contentTypeNeq: '콘텐츠 유형 불일치',
    contentTypeMin: '콘텐츠 유형 최소',
    contentTypeMax: '콘텐츠 유형 최대',
    contentTypeIn: '콘텐츠 유형 포함',
    queryEq: '질의어 일치',
    queryNeq: '질의어 불일치',
    queryMin: '질의어 최소',
    queryMax: '질의어 최대',
    queryLike: '질의어 포함',
    queryNotLike: '질의어 제외',
    exposedAtEq: '노출 시작일 일치',
    exposedAtNeq: '노출 시작일 불일치',
    exposedAtMin: '노출 시작일 최소',
    exposedAtMax: '노출 시작일 최대',
    exposedUntilEq: '노출 종료일 일치',
    exposedUntilNeq: '노출 종료일 불일치',
    exposedUntilMin: '노출 종료일 최소',
    exposedUntilMax: '노출 종료일 최대',
    displayOrderEq: '표시 우선 순위 일치',
    displayOrderNeq: '표시 우선 순위 불일치',
    displayOrderMin: '표시 우선 순위 최소',
    displayOrderMax: '표시 우선 순위 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
