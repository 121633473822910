import {RouterProps} from "../../../RouterProps";
import {UserBlockReasonValueObject} from "../../../../data/value-object/user/UserBlockReasonValueObject";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {UserBlockReasonIO} from "../../../../io/UserIO";
import {
    Button,
    Caption1,
    Dialog, DialogActions,
    DialogBody, DialogContent,
    DialogSurface, DialogTitle,
    Field,
    Input,
    Spinner,
    Title3
} from "@fluentui/react-components";

enum Phase {
    Waiting,
    Putting,
    Finished
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userBlockReasonId: string }>()
    const item = useStatePair<UserBlockReasonValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userBlockReasonId !== undefined) {
            UserBlockReasonIO
                .getById(BigInt(params.userBlockReasonId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserBlockReasonValueObject
}

function Page(props: PageProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const body = useStatePair(props.item.body)

    const onDoneClick = () => {
        phase.setter(Phase.Putting)
        UserBlockReasonIO
            .put(props.item.id, { body: body.value })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <div className={props.styles.row}>
                <div className={props.styles.column8}>
                    <Caption1>사유</Caption1>
                    <Field>
                        <Input
                            value={body.value}
                            onChange={(_, data) => body.setter(data.value)}
                            type={'text'}
                            maxLength={200} />
                    </Field>
                </div>
            </div>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    onClick={onDoneClick}
                    disabled={phase.value !== Phase.Waiting || body.value.length === 0}>
                    완료
                </Button>
            </div>
        </div>
        <Dialog open={phase.value === Phase.Finished}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>완료</DialogTitle>
                    <DialogContent>수정되었습니다.</DialogContent>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => document.location = `/users/blocks/reasons/${props.item.id}`}>항목으로</Button>
                    <Button onClick={() => document.location = '/users/blocks/reasons'}>목록으로</Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    </>
}