import {EnvNoticeDomain} from "../../domain/env/EnvNoticeDomain"

/**
 * 공지사항 속성 설명
 */
export const EnvNoticeDescription: Record<keyof EnvNoticeDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    title: '제목',
    url: 'URL',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
