import {UserBlockVerboseDomain} from "../../domain/user/UserBlockVerboseDomain"
import {UserBlockId} from "../../id/user/UserBlockId"
import {UserBlockReasonId} from "../../id/user/UserBlockReasonId"
import {UserId} from "../../id/user/UserId"
import {UserBlockReasonValueObject} from "./UserBlockReasonValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 정지 상세
 */
export class UserBlockVerboseValueObject implements VerboseValueObject, UserBlockVerboseDomain {
    /**
     * 기본키
     */
    id: UserBlockId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReasonId: UserBlockReasonId
    /**
     * 이용 정지 시작일
     */
    blockedAt: Date
    /**
     * 이용 정지 종료일
     */
    blockedUntil?: Date
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReason: UserBlockReasonValueObject

    constructor(o: Record<keyof UserBlockVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userId = BigInt(o.userId)
        this.userBlockReasonId = BigInt(o.userBlockReasonId)
        this.blockedAt = new Date(o.blockedAt)
        if (o.blockedUntil !== undefined && o.blockedUntil !== null) {
            this.blockedUntil = new Date(o.blockedUntil)
        }
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
        this.user = new UserValueObject(o.user)
        this.userBlockReason = new UserBlockReasonValueObject(o.userBlockReason)
    }
}
