import {EnvFaqItemModificationDomain} from "../../domain/env/EnvFaqItemModificationDomain"

/**
 * 자주 묻는 질문 항목 수정 이력 속성 설명
 */
export const EnvFaqItemModificationDescription: Record<keyof EnvFaqItemModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envFaqItemId: '자주 묻는 질문 항목 ID',
    envFaqId: '자주 묻는 질문 ID',
    title: '제목',
    body: '내용',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
