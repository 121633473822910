import {UserPolicyPutDataTransferObject} from "../../data-transfer-object/user/UserPolicyPutDataTransferObject"

/**
 * 사용자 정책 속성 설명
 */
export const UserPolicyPutDataTransferObjectDescription: Record<keyof UserPolicyPutDataTransferObject, string> = {
    signUpSolarAmount: '회원가입 지급 솔라',
    signUpLunarAmount: '회원가입 지급 루나',
    signUpStarAmount: '회원가입 지급 스타',
    recommenderSolarAmount: '추천인 지급 솔라',
    recommenderLunarAmount: '추천인 지급 루나',
    recommenderStarAmount: '추천인 지급 스타',
    recommenderRewardThreshold: '추천인 보상 기준',
    recommendeeSolarAmount: '피추천인 지급 솔라',
    recommendeeLunarAmount: '피추천인 지급 루나',
    recommendeeStarAmount: '피추천인 지급 스타',
    userPageSize: '사용자 페이지 크기',
    userAgreementPageSize: '사용자 이용 약관 동의 페이지 크기',
    userAuthenticationCodePageSize: '사용자 인증 번호 페이지 크기',
    userAuthenticationCodeExpireSeconds: '사용자 인증 번호 만료 시간(초)',
    userAuthenticationCodeThresholdMinutes: '사용자 인증 번호 제한 단위 시간(분)',
    userAuthenticationCodeThresholdCount: '사용자 인증 번호 제한 단위 횟수',
    userBlockPageSize: '사용자 이용 정지 페이지 크기',
    userBlockReasonPageSize: '사용자 이용 정지 사유 페이지 크기',
    userExpPageSize: '사용자 경험치 페이지 크기',
    userInquiryPageSize: '사용자 문의 페이지 크기',
    userLevelPageSize: '사용자 레벨 페이지 크기',
    userLevelHistoryPageSize: '사용자 레벨 기록 페이지 크기',
    userNotificationPageSize: '사용자 알림 페이지 크기',
    userPolicyPageSize: '사용자 정책 페이지 크기',
    userRankPageSize: '사용자 랭크 페이지 크기',
    userRankCacheSchedulerDurationMinutes: '사용자 랭크 캐시 스케줄러 주기(분)',
    userRankCacheRefreshDurationMinutes: '사용자 랭크 캐시 새로고침 시간(분)',
    userRankDuration: '사용자 랭크 집계 기간',
    userRankHistoryPageSize: '사용자 랭크 기록 페이지 크기',
    userSessionPageSize: '사용자 세션 페이지 크기',
    userSessionMaxAgeSeconds: '미사용 세션 유지 시간(초)',
    userSignInPageSize: '사용자 로그인 페이지 크기',
    userWithdrawalPageSize: '사용자 탈퇴 페이지 크기',
    userWithdrawalReasonPageSize: '사용자 탈퇴 사유 페이지 크기'
}
