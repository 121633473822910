import {ContentReviewVerboseDomain} from "../../domain/content/ContentReviewVerboseDomain"
import {ContentId} from "../../id/content/ContentId"
import {ContentReviewId} from "../../id/content/ContentReviewId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentValueObject} from "./ContentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 리뷰(와글와글만 가능) 상세
 */
export class ContentReviewVerboseValueObject implements VerboseValueObject, ContentReviewVerboseDomain {
    /**
     * 기본키
     */
    id: ContentReviewId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 평가
     */
    review: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 콘텐츠 ID
     */
    content: ContentValueObject

    constructor(o: Record<keyof ContentReviewVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentId = BigInt(o.contentId)
        this.review = Number(o.review)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.content = new ContentValueObject(o.content)
    }
}
