import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvClientPlatform} from "../../constant/env/EnvClientPlatform"
import {EnvClientDomain} from "../../domain/env/EnvClientDomain"

export const EnvClientDescriptor: Record<keyof EnvClientDomain, (o: EnvClientDomain) => string> = {
    id(o: EnvClientDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvClientDomain): string {
        return o.creatorId.toString()
    },
    platform(o: EnvClientDomain): string {
        return EnvClientPlatform.description(o.platform)
    },
    distribution(o: EnvClientDomain): string {
        return EnvClientDistribution.description(o.distribution)
    },
    versionName(o: EnvClientDomain): string {
        return o.versionName
    },
    expiredAt(o: EnvClientDomain): string {
        return o.expiredAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    userSignInCount(o: EnvClientDomain): string {
        return o.userSignInCount.toString()
    },
    createdAt(o: EnvClientDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvClientDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvClientDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
