import {EnvStorageKeySearchOption} from "../../search-option/env/EnvStorageKeySearchOption"

/**
 * AWS S3 인증 수단 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvStorageKeySearchOptionDescription: Record<keyof EnvStorageKeySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    accessKeyIdNeq: '액세스 키 ID 불일치',
    accessKeyIdMin: '액세스 키 ID 최소',
    accessKeyIdMax: '액세스 키 ID 최대',
    accessKeyIdLike: '액세스 키 ID 포함',
    accessKeyIdNotLike: '액세스 키 ID 제외',
    secretAccessKeyNeq: '비밀키 불일치',
    secretAccessKeyMin: '비밀키 최소',
    secretAccessKeyMax: '비밀키 최대',
    secretAccessKeyLike: '비밀키 포함',
    secretAccessKeyNotLike: '비밀키 제외',
    bucketEq: '버킷 일치',
    bucketNeq: '버킷 불일치',
    bucketMin: '버킷 최소',
    bucketMax: '버킷 최대',
    bucketLike: '버킷 포함',
    bucketNotLike: '버킷 제외',
    regionEq: '리전 일치',
    regionNeq: '리전 불일치',
    regionMin: '리전 최소',
    regionMax: '리전 최대',
    regionLike: '리전 포함',
    regionNotLike: '리전 제외',
    distributionEq: '배포 유형 일치',
    distributionNeq: '배포 유형 불일치',
    distributionMin: '배포 유형 최소',
    distributionMax: '배포 유형 최대',
    distributionIn: '배포 유형 포함',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
