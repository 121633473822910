import {UserSessionSearchOption} from "../../search-option/user/UserSessionSearchOption"

/**
 * 사용자 세션 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserSessionSearchOptionDescription: Record<keyof UserSessionSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userSignInIdEq: '사용자 로그인 ID 일치',
    userSignInIdNeq: '사용자 로그인 ID 불일치',
    userSignInIdMin: '사용자 로그인 ID 최소',
    userSignInIdMax: '사용자 로그인 ID 최대',
    userSignInIdIsNull: '사용자 로그인 ID 값 존재 여부',
    outboundNeq: '쿠키에 포함되는 ID 불일치',
    outboundMin: '쿠키에 포함되는 ID 최소',
    outboundMax: '쿠키에 포함되는 ID 최대',
    outboundLike: '쿠키에 포함되는 ID 포함',
    outboundNotLike: '쿠키에 포함되는 ID 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    lastModifiedAtEq: '마지막 수정일 일치',
    lastModifiedAtNeq: '마지막 수정일 불일치',
    lastModifiedAtMin: '마지막 수정일 최소',
    lastModifiedAtMax: '마지막 수정일 최대',
    lastModifiedAtIsNull: '마지막 수정일 값 존재 여부',
}
