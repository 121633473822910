import {UserSignInVerboseDomain} from "../../domain/user/UserSignInVerboseDomain"
import {EnvClientId} from "../../id/env/EnvClientId"
import {UserId} from "../../id/user/UserId"
import {UserSignInId} from "../../id/user/UserSignInId"
import {EnvClientValueObject} from "../env/EnvClientValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 로그인 상세
 */
export class UserSignInVerboseValueObject implements VerboseValueObject, UserSignInVerboseDomain {
    /**
     * 기본키
     */
    id: UserSignInId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 클라이언트 ID
     */
    envClientId: EnvClientId
    /**
     * 요청자 주소
     */
    remoteAddress: string
    /**
     * 장치 이름
     */
    deviceName: string
    /**
     * 장치 버전
     */
    deviceVersion: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 클라이언트 ID
     */
    envClient: EnvClientValueObject

    constructor(o: Record<keyof UserSignInVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.envClientId = BigInt(o.envClientId)
        this.remoteAddress = String(o.remoteAddress)
        this.deviceName = String(o.deviceName)
        this.deviceVersion = String(o.deviceVersion)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.envClient = new EnvClientValueObject(o.envClient)
    }
}
