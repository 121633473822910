import {AssetSolarWithdrawalOptionDomain} from "../../domain/asset/AssetSolarWithdrawalOptionDomain"

/**
 * 솔라 인출 옵션 속성 설명
 */
export const AssetSolarWithdrawalOptionDescription: Record<keyof AssetSolarWithdrawalOptionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    solarAmount: '차감 솔라',
    cashAmount: '지급 금액',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
