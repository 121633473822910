import {blobToUrl} from "./BlobUtils";

export async function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    return new Promise<string>(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result as string)
        reader.readAsDataURL(new Blob([arrayBuffer]))
    })
}

export function arrayBufferToUrl(arrayBuffer: ArrayBuffer) {
    return blobToUrl(new Blob([arrayBuffer]))
}