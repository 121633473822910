import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {AssetPolicyIO} from "../../../io/AssetIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";
import {AssetPolicyValueObject} from "../../../data/value-object/asset/AssetPolicyValueObject";
import {AssetPolicyDescription} from "../../../data/description/asset/AssetPolicyDescription";
import {AssetPolicyDomain} from "../../../data/domain/asset/AssetPolicyDomain";
import {AssetPolicyDescriptor} from "../../../data/descriptor/asset/AssetPolicyDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetPolicyId: string }>()
    const item = useStatePair<AssetPolicyValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetPolicyId !== undefined) {
            AssetPolicyIO
                .getById(BigInt(params.assetPolicyId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetPolicyValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetPolicyIO}
                description={AssetPolicyDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetPolicyDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "assetCouponPageSize":
        case "assetCouponUsagePageSize":
        case "assetLunarUsagePageSize":
        case "assetLunarExchangeOptionPageSize":
        case "assetLunarExchangeExp":
        case "assetLunarPurchaseOptionPageSize":
        case "assetLunarPurchaseExp":
        case "assetPolicyPageSize":
        case "assetSolarUsagePageSize":
        case "assetSolarWithdrawalPageSize":
        case "assetSolarWithdrawalOptionPageSize":
        case "assetSolarWithdrawalExp":
        case "assetStarChargePageSize":
        case "assetStarUsagePageSize":
        case "assetStarUsageExp":
        case "assetStarExchangeOptionPageSize":
        case "assetStarExchangeExp":
        case "assetStarChargeMinutes":
        case "assetStarChargeMax":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{AssetPolicyDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetPolicyDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}