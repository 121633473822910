import {ContentUserProductionParticipantModificationDomain} from "../../domain/content/ContentUserProductionParticipantModificationDomain"
import {ContentUserProductionParticipantId} from "../../id/content/ContentUserProductionParticipantId"
import {ContentUserProductionParticipantModificationId} from "../../id/content/ContentUserProductionParticipantModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 와글와글 인원 프리셋 수정 이력
 */
export class ContentUserProductionParticipantModificationValueObject implements ValueObject, ContentUserProductionParticipantModificationDomain {
    /**
     * 기본키
     */
    id: ContentUserProductionParticipantModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 와글와글 인원 프리셋 ID
     */
    contentUserProductionParticipantId: ContentUserProductionParticipantId
    /**
     * 인원
     */
    value: number
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentUserProductionParticipantModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentUserProductionParticipantId = BigInt(o.contentUserProductionParticipantId)
        this.value = Number(o.value)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
