import {UserLevelDomain} from "../../domain/user/UserLevelDomain"

export const UserLevelDescriptor: Record<keyof UserLevelDomain, (o: UserLevelDomain) => string> = {
    id(o: UserLevelDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserLevelDomain): string {
        return o.creatorId.toString()
    },
    name(o: UserLevelDomain): string {
        return o.name
    },
    exp(o: UserLevelDomain): string {
        return o.exp.toString()
    },
    rewardAssetLunarAmount(o: UserLevelDomain): string {
        return o.rewardAssetLunarAmount.toString()
    },
    rewardAssetSolarAmount(o: UserLevelDomain): string {
        return o.rewardAssetSolarAmount.toString()
    },
    rewardAssetStarAmount(o: UserLevelDomain): string {
        return o.rewardAssetStarAmount.toString()
    },
    createdAt(o: UserLevelDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserLevelDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: UserLevelDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
