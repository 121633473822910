import {UiOnboardingIO} from "../../../io/UiIO";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    UiOnboardingValueObject
} from "../../../data/value-object/ui/UiOnboardingValueObject";
import {
    UiOnboardingSearchOption
} from "../../../data/search-option/ui/UiOnboardingSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {
    UiOnboardingDescription
} from "../../../data/description/ui/UiOnboardingDescription";
import {UiOnboardingDescriptor} from "../../../data/descriptor/ui/UiOnboardingDescriptor";
import ListOption from "../../ListOption";
import {
    UiOnboardingSearchOptionDescription
} from "../../../data/description/ui/UiOnboardingSearchOptionDescription";
import {
    applyUiOnboardingSearchOption,
    UiOnboardingSearchOptionField
} from "../../search-option-field/ui/UiOnboardingSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UiOnboardingValueObject[]>([])
    const option = useStatePair<UiOnboardingSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UiOnboardingIO}
                items={items}
                option={option.value}
                index={index}
                description={UiOnboardingDescription}
                descriptor={UiOnboardingDescriptor}
                showPost
                onPostClick={() => document.location = '/ui/onboardings/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/ui/onboardings/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UiOnboardingSearchOptionDescription}
                fields={UiOnboardingSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUiOnboardingSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UiOnboardingDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}