import {UiPolicyVerboseDomain} from "../../domain/ui/UiPolicyVerboseDomain"
import {UiPolicyId} from "../../id/ui/UiPolicyId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * UI 정책 상세
 */
export class UiPolicyVerboseValueObject implements VerboseValueObject, UiPolicyVerboseDomain {
    /**
     * 기본키
     */
    id: UiPolicyId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 홈 추천 콘텐츠 페이지 크기
     */
    uiHomeContentSuggestionPageSize: number
    /**
     * 홈 와글와글 콘텐츠 페이지 크기
     */
    uiHomeContentUserProductionPageSize: number
    /**
     * 홈 공지사항 페이지 크기
     */
    uiHomeNoticePageSize: number
    /**
     * 온보딩 페이지 크기
     */
    uiOnboardingPageSize: number
    /**
     * UI 정책 페이지 크기
     */
    uiPolicyPageSize: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof UiPolicyVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiHomeContentSuggestionPageSize = Number(o.uiHomeContentSuggestionPageSize)
        this.uiHomeContentUserProductionPageSize = Number(o.uiHomeContentUserProductionPageSize)
        this.uiHomeNoticePageSize = Number(o.uiHomeNoticePageSize)
        this.uiOnboardingPageSize = Number(o.uiOnboardingPageSize)
        this.uiPolicyPageSize = Number(o.uiPolicyPageSize)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
