import {BitMaskFlag} from "ts-protocol-extension"

export const ContentStatus: {
    ShouldWatchVideo: BitMaskFlag,
    ShouldWatchDetailUrl: BitMaskFlag,
    ShouldWatchDescription: BitMaskFlag,
    ResultUrlStar: BitMaskFlag,
    UserProductionAnonymous: BitMaskFlag,
    HasAiVoice: BitMaskFlag,
    HasKoreanVoice: BitMaskFlag,
    selections: { value: string, label: string }[]
} = new (class _ {
    /**
     * 영상을 시청해야 참여할 수 있음
     */
    ShouldWatchVideo: BitMaskFlag = {
        value: 1 << 0,
        name: '영상을 시청해야 참여할 수 있음'
    }

    /**
     * 더 알아보기를 클릭해야 참여할 수 있음
     */
    ShouldWatchDetailUrl: BitMaskFlag = {
        value: 1 << 1,
        name: '더 알아보기를 클릭해야 참여할 수 있음'
    }

    /**
     * 상세정보를 모두 확인해야 참여할 수 있음
     */
    ShouldWatchDescription: BitMaskFlag = {
        value: 1 << 2,
        name: '상세정보를 모두 확인해야 참여할 수 있음'
    }

    /**
     * 참여 결과 URL 클릭 시 스타 지급
     */
    ResultUrlStar: BitMaskFlag = {
        value: 1 << 3,
        name: '참여 결과 URL 클릭 시 스타 지급'
    }

    /**
     * 와글와글 익명
     */
    UserProductionAnonymous: BitMaskFlag = {
        value: 1 << 4,
        name: '와글와글 익명'
    }

    /**
     * AI 음성을 포함
     */
    HasAiVoice: BitMaskFlag = {
        value: 1 << 5,
        name: 'AI 음성을 포함'
    }

    /**
     * 한국인 음성을 포함
     */
    HasKoreanVoice: BitMaskFlag = {
        value: 1 << 6,
        name: '한국인 음성을 포함'
    }

    selections: { value: string, label: string }[] = [
        { value: this.ShouldWatchVideo.value.toString(), label: this.ShouldWatchVideo.name },
        { value: this.ShouldWatchDetailUrl.value.toString(), label: this.ShouldWatchDetailUrl.name },
        { value: this.ShouldWatchDescription.value.toString(), label: this.ShouldWatchDescription.name },
        { value: this.ResultUrlStar.value.toString(), label: this.ResultUrlStar.name },
        { value: this.UserProductionAnonymous.value.toString(), label: this.UserProductionAnonymous.name },
        { value: this.HasAiVoice.value.toString(), label: this.HasAiVoice.name },
        { value: this.HasKoreanVoice.value.toString(), label: this.HasKoreanVoice.name }
    ]
})()
