import {BitMaskFlag} from "ts-protocol-extension"

export const UserPermission: {
    SignInAdmin: BitMaskFlag,
    WritePolicy: BitMaskFlag,
    WriteAdvertiser: BitMaskFlag,
    WriteAssetLunarUsage: BitMaskFlag,
    WriteAssetSolarUsage: BitMaskFlag,
    WriteAssetStarUsage: BitMaskFlag,
    WriteAssetOption: BitMaskFlag,
    WriteContentDaily: BitMaskFlag,
    WriteContentRepeat: BitMaskFlag,
    WriteContentM10n: BitMaskFlag,
    WriteContentUserProductionPreset: BitMaskFlag,
    WriteContentReportReason: BitMaskFlag,
    WriteContentCommentReportReason: BitMaskFlag,
    WriteContentQueryPreemption: BitMaskFlag,
    WriteEnv: BitMaskFlag,
    WriteUi: BitMaskFlag,
    WriteUserRole: BitMaskFlag,
    WriteUserAdminPermission: BitMaskFlag,
    WriteUserAgreement: BitMaskFlag,
    WriteUserBlock: BitMaskFlag,
    WriteUserBlockReason: BitMaskFlag,
    WriteUserExp: BitMaskFlag,
    WriteUserLevel: BitMaskFlag,
    WriteUserRank: BitMaskFlag,
    WriteUserNotification: BitMaskFlag,
    WriteUserWithdrawalReason: BitMaskFlag,
    selections: { value: string, label: string }[]
} = new (class _ {
    /**
     * 관리자 페이지 로그인
     */
    SignInAdmin: BitMaskFlag = {
        value: 1 << 0,
        name: '관리자 페이지 로그인'
    }

    /**
     * 정책 생성 및 수정
     */
    WritePolicy: BitMaskFlag = {
        value: 1 << 1,
        name: '정책 생성 및 수정'
    }

    /**
     * 광고주 생성 및 수정
     */
    WriteAdvertiser: BitMaskFlag = {
        value: 1 << 2,
        name: '광고주 생성 및 수정'
    }

    /**
     * 루나 지급 및 차감
     */
    WriteAssetLunarUsage: BitMaskFlag = {
        value: 1 << 3,
        name: '루나 지급 및 차감'
    }

    /**
     * 솔라 지급 및 차감
     */
    WriteAssetSolarUsage: BitMaskFlag = {
        value: 1 << 4,
        name: '솔라 지급 및 차감'
    }

    /**
     * 스타 지급 및 차감
     */
    WriteAssetStarUsage: BitMaskFlag = {
        value: 1 << 5,
        name: '스타 지급 및 차감'
    }

    /**
     * 재화 옵션 생성 및 수정
     */
    WriteAssetOption: BitMaskFlag = {
        value: 1 << 6,
        name: '재화 옵션 생성 및 수정'
    }

    /**
     * 콘텐츠(오늘의 단어) 생성 및 수정
     */
    WriteContentDaily: BitMaskFlag = {
        value: 1 << 7,
        name: '콘텐츠(오늘의 단어) 생성 및 수정'
    }

    /**
     * 콘텐츠(따라 말하기) 생성 및 수정
     */
    WriteContentRepeat: BitMaskFlag = {
        value: 1 << 8,
        name: '콘텐츠(따라 말하기) 생성 및 수정'
    }

    /**
     * 콘텐츠(암기하기) 생성 및 수정
     */
    WriteContentM10n: BitMaskFlag = {
        value: 1 << 9,
        name: '콘텐츠(암기하기) 생성 및 수정'
    }

    /**
     * 콘텐츠(와글와글) 프리셋 생성 및 수정
     */
    WriteContentUserProductionPreset: BitMaskFlag = {
        value: 1 << 10,
        name: '콘텐츠(와글와글) 프리셋 생성 및 수정'
    }

    /**
     * 콘텐츠 신고 사유 생성 및 수정
     */
    WriteContentReportReason: BitMaskFlag = {
        value: 1 << 11,
        name: '콘텐츠 신고 사유 생성 및 수정'
    }

    /**
     * 콘텐츠 댓글 신고 사유 생성 및 수정
     */
    WriteContentCommentReportReason: BitMaskFlag = {
        value: 1 << 12,
        name: '콘텐츠 댓글 신고 사유 생성 및 수정'
    }

    /**
     * 콘텐츠 인기 검색어 예약 생성 및 수정
     */
    WriteContentQueryPreemption: BitMaskFlag = {
        value: 1 << 13,
        name: '콘텐츠 인기 검색어 예약 생성 및 수정'
    }

    /**
     * 환경 수정
     */
    WriteEnv: BitMaskFlag = {
        value: 1 << 14,
        name: '환경 수정'
    }

    /**
     * UI 수정
     */
    WriteUi: BitMaskFlag = {
        value: 1 << 15,
        name: 'UI 수정'
    }

    /**
     * 사용자 역할 수정
     */
    WriteUserRole: BitMaskFlag = {
        value: 1 << 16,
        name: '사용자 역할 수정'
    }

    /**
     * 관리자 권한 수정
     */
    WriteUserAdminPermission: BitMaskFlag = {
        value: 1 << 17,
        name: '관리자 권한 수정'
    }

    /**
     * 사용자 이용 약관 동의 삭제
     */
    WriteUserAgreement: BitMaskFlag = {
        value: 1 << 18,
        name: '사용자 이용 약관 동의 삭제'
    }

    /**
     * 사용자 이용 정지 생성 및 수정
     */
    WriteUserBlock: BitMaskFlag = {
        value: 1 << 19,
        name: '사용자 이용 정지 생성 및 수정'
    }

    /**
     * 사용자 이용 정지 사유 생성 및 수정
     */
    WriteUserBlockReason: BitMaskFlag = {
        value: 1 << 20,
        name: '사용자 이용 정지 사유 생성 및 수정'
    }

    /**
     * 사용자 경험치 지급
     */
    WriteUserExp: BitMaskFlag = {
        value: 1 << 21,
        name: '사용자 경험치 지급'
    }

    /**
     * 사용자 레벨 생성 및 수정
     */
    WriteUserLevel: BitMaskFlag = {
        value: 1 << 22,
        name: '사용자 레벨 생성 및 수정'
    }

    /**
     * 사용자 랭크 생성 및 수정
     */
    WriteUserRank: BitMaskFlag = {
        value: 1 << 23,
        name: '사용자 랭크 생성 및 수정'
    }

    /**
     * 사용자 알림 생성 및 수정
     */
    WriteUserNotification: BitMaskFlag = {
        value: 1 << 24,
        name: '사용자 알림 생성 및 수정'
    }

    /**
     * 사용자 탈퇴 사유 생성 및 수정
     */
    WriteUserWithdrawalReason: BitMaskFlag = {
        value: 1 << 25,
        name: '사용자 탈퇴 사유 생성 및 수정'
    }

    selections: { value: string, label: string }[] = [
        { value: this.SignInAdmin.value.toString(), label: this.SignInAdmin.name },
        { value: this.WritePolicy.value.toString(), label: this.WritePolicy.name },
        { value: this.WriteAdvertiser.value.toString(), label: this.WriteAdvertiser.name },
        { value: this.WriteAssetLunarUsage.value.toString(), label: this.WriteAssetLunarUsage.name },
        { value: this.WriteAssetSolarUsage.value.toString(), label: this.WriteAssetSolarUsage.name },
        { value: this.WriteAssetStarUsage.value.toString(), label: this.WriteAssetStarUsage.name },
        { value: this.WriteAssetOption.value.toString(), label: this.WriteAssetOption.name },
        { value: this.WriteContentDaily.value.toString(), label: this.WriteContentDaily.name },
        { value: this.WriteContentRepeat.value.toString(), label: this.WriteContentRepeat.name },
        { value: this.WriteContentM10n.value.toString(), label: this.WriteContentM10n.name },
        { value: this.WriteContentUserProductionPreset.value.toString(), label: this.WriteContentUserProductionPreset.name },
        { value: this.WriteContentReportReason.value.toString(), label: this.WriteContentReportReason.name },
        { value: this.WriteContentCommentReportReason.value.toString(), label: this.WriteContentCommentReportReason.name },
        { value: this.WriteContentQueryPreemption.value.toString(), label: this.WriteContentQueryPreemption.name },
        { value: this.WriteEnv.value.toString(), label: this.WriteEnv.name },
        { value: this.WriteUi.value.toString(), label: this.WriteUi.name },
        { value: this.WriteUserRole.value.toString(), label: this.WriteUserRole.name },
        { value: this.WriteUserAdminPermission.value.toString(), label: this.WriteUserAdminPermission.name },
        { value: this.WriteUserAgreement.value.toString(), label: this.WriteUserAgreement.name },
        { value: this.WriteUserBlock.value.toString(), label: this.WriteUserBlock.name },
        { value: this.WriteUserBlockReason.value.toString(), label: this.WriteUserBlockReason.name },
        { value: this.WriteUserExp.value.toString(), label: this.WriteUserExp.name },
        { value: this.WriteUserLevel.value.toString(), label: this.WriteUserLevel.name },
        { value: this.WriteUserRank.value.toString(), label: this.WriteUserRank.name },
        { value: this.WriteUserNotification.value.toString(), label: this.WriteUserNotification.name },
        { value: this.WriteUserWithdrawalReason.value.toString(), label: this.WriteUserWithdrawalReason.name }
    ]
})()
