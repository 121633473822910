import {RouterProps} from "../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import React, {useCallback, useEffect} from "react";
import {UserIO} from "../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostBitMaskField, PostFieldWrapper} from "../PostField";
import {UserDescription} from "../../data/description/user/UserDescription";
import {UserPermission} from "../../data/constant/user/UserPermission";
import {UserValueObject} from "../../data/value-object/user/UserValueObject";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function EditAdminPermission(props: RouterProps) {
    const params = useParams<{ userId: string }>()
    const item = useStatePair<UserValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const adminPermission = useStatePair(0)

    useEffect(() => {
        if (params.userId !== undefined) {
            UserIO
                .getVerboseById(BigInt(params.userId))
                .then(value => {
                    item.setter(value)
                    adminPermission.setter(value.adminPermission)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UserIO
            .patchAdminPermission(id, {
                adminPermission: adminPermission.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [adminPermission.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>권한 수정</Title3>
            <PostFieldWrapper
                {...props}
                title={UserDescription.adminPermission}>
                <PostBitMaskField
                    {...props}
                    value={adminPermission.value}
                    setter={adminPermission.setter}
                    selections={UserPermission.selections} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}