import {UiHomeNoticeDomain} from "../../domain/ui/UiHomeNoticeDomain"

export const UiHomeNoticeDescriptor: Record<keyof UiHomeNoticeDomain, (o: UiHomeNoticeDomain) => string> = {
    id(o: UiHomeNoticeDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiHomeNoticeDomain): string {
        return o.creatorId.toString()
    },
    displayOrder(o: UiHomeNoticeDomain): string {
        return o.displayOrder.toString()
    },
    url(o: UiHomeNoticeDomain): string {
        return o.url
    },
    createdAt(o: UiHomeNoticeDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UiHomeNoticeDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
