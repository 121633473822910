import {UserNotificationSubject} from "../../constant/user/UserNotificationSubject"
import {UserNotificationModificationDomain} from "../../domain/user/UserNotificationModificationDomain"
import {UserId} from "../../id/user/UserId"
import {UserNotificationId} from "../../id/user/UserNotificationId"
import {UserNotificationModificationId} from "../../id/user/UserNotificationModificationId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 알림 수정 이력
 */
export class UserNotificationModificationValueObject implements ValueObject, UserNotificationModificationDomain {
    /**
     * 기본키
     */
    id: UserNotificationModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 알림 ID
     */
    userNotificationId: UserNotificationId
    /**
     * 제목
     */
    title: string
    /**
     * 내용
     */
    body: string
    /**
     * 광고 주제
     */
    subject: UserNotificationSubject
    /**
     * 딥링크
     */
    deepLink?: string
    /**
     * 상태
     */
    statusFlags: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UserNotificationModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userNotificationId = BigInt(o.userNotificationId)
        this.title = String(o.title)
        this.body = String(o.body)
        this.subject = o.subject as UserNotificationSubject
        if (o.deepLink !== undefined && o.deepLink !== null) {
            this.deepLink = String(o.deepLink)
        }
        this.statusFlags = Number(o.statusFlags)
        this.createdAt = new Date(o.createdAt)
    }
}
