import {ContentResultUrlSearchOption} from "../../search-option/content/ContentResultUrlSearchOption"

/**
 * 콘텐츠 참여 결과 URL 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentResultUrlSearchOptionDescription: Record<keyof ContentResultUrlSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    browserEq: '브라우저 일치',
    browserNeq: '브라우저 불일치',
    browserMin: '브라우저 최소',
    browserMax: '브라우저 최대',
    browserIn: '브라우저 포함',
    urlEq: '참여 결과 URL 일치',
    urlNeq: '참여 결과 URL 불일치',
    urlMin: '참여 결과 URL 최소',
    urlMax: '참여 결과 URL 최대',
    urlLike: '참여 결과 URL 포함',
    urlNotLike: '참여 결과 URL 제외',
    clickCountEq: '클릭 수 일치',
    clickCountNeq: '클릭 수 불일치',
    clickCountMin: '클릭 수 최소',
    clickCountMax: '클릭 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
