import {EnvPhoneBlockDomain} from "../../domain/env/EnvPhoneBlockDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {EnvPhoneBlockId} from "../../id/env/EnvPhoneBlockId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 전화번호 차단
 */
export class EnvPhoneBlockValueObject implements ValueObject, EnvPhoneBlockDomain {
    /**
     * 기본키
     */
    id: EnvPhoneBlockId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 국가 ID
     */
    envCountryId: EnvCountryId
    /**
     * 전화번호
     */
    phone: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date

    constructor(o: Record<keyof EnvPhoneBlockDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envCountryId = BigInt(o.envCountryId)
        this.phone = String(o.phone)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
    }
}
