import {UserLevelHistoryDomain} from "../../domain/user/UserLevelHistoryDomain"

export const UserLevelHistoryDescriptor: Record<keyof UserLevelHistoryDomain, (o: UserLevelHistoryDomain) => string> = {
    id(o: UserLevelHistoryDomain): string {
        return o.id.toString()
    },
    userId(o: UserLevelHistoryDomain): string {
        return o.userId.toString()
    },
    userLevelId(o: UserLevelHistoryDomain): string {
        return o.userLevelId.toString()
    },
    assetLunarUsageId(o: UserLevelHistoryDomain): string {
        return o.assetLunarUsageId?.toString() ?? '-'
    },
    assetSolarUsageId(o: UserLevelHistoryDomain): string {
        return o.assetSolarUsageId?.toString() ?? '-'
    },
    assetStarUsageId(o: UserLevelHistoryDomain): string {
        return o.assetStarUsageId?.toString() ?? '-'
    },
    createdAt(o: UserLevelHistoryDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
