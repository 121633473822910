import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvClientVerboseValueObject} from "../../../data/value-object/env/EnvClientVerboseValueObject";
import {useCallback, useEffect} from "react";
import {EnvClientIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField} from "../../PostField";
import {EnvClientPlatform} from "../../../data/constant/env/EnvClientPlatform";
import {EnvClientDistribution} from "../../../data/constant/env/EnvClientDistribution";
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension";
import {EnvClientDescription} from "../../../data/description/env/EnvClientDescription";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envClientId: string }>()
    const item = useStatePair<EnvClientVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const platform = useStatePair<EnvClientPlatform>(EnvClientPlatform.AdminWeb)
    const distribution = useStatePair<EnvClientDistribution>(EnvClientDistribution.LocalTest)
    const versionName = useStatePair('')
    const expiredAt = useStatePair<string | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envClientId !== undefined) {
            EnvClientIO
                .getVerboseById(BigInt(params.envClientId))
                .then(value => {
                    item.setter(value)
                    platform.setter(value.platform)
                    distribution.setter(value.distribution)
                    versionName.setter(value.versionName)
                    expiredAt.setter(unwrapUndefinedString(value.expiredAt))
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        EnvClientIO
            .put(id, {
                platform: platform.value,
                distribution: distribution.value,
                versionName: versionName.value,
                expiredAt: unwrapUndefinedDate(expiredAt.value)
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/clients/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [platform.value, distribution.value, versionName.value, expiredAt.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={EnvClientDescription.platform}>
                <PostEnumField
                    {...props}
                    value={platform.value}
                    setter={platform.setter}
                    selections={EnvClientPlatform.selections} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvClientDescription.distribution}>
                <PostEnumField
                    {...props}
                    value={distribution.value}
                    setter={distribution.setter}
                    selections={EnvClientDistribution.selections} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvClientDescription.versionName}>
                <PostInputField
                    {...props}
                    value={versionName.value}
                    setter={versionName.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvClientDescription.expiredAt}
                showUnusedCheckbox
                isUnusedCheckboxChecked={expiredAt.value === undefined}
                onUnusedCheckboxChange={checked => expiredAt.setter(checked ? undefined : '')}>
                <PostDateField
                    {...props}
                    disabled={expiredAt.value === undefined}
                    value={expiredAt.value}
                    setter={expiredAt.setter}
                    maxLength={19} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}