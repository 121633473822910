import {EnvCountrySearchOption} from "../../search-option/env/EnvCountrySearchOption"

/**
 * 국가 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvCountrySearchOptionDescription: Record<keyof EnvCountrySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    nameEq: '이름 일치',
    nameNeq: '이름 불일치',
    nameMin: '이름 최소',
    nameMax: '이름 최대',
    nameLike: '이름 포함',
    nameNotLike: '이름 제외',
    isoCodeNeq: 'ISO 코드 불일치',
    isoCodeMin: 'ISO 코드 최소',
    isoCodeMax: 'ISO 코드 최대',
    isoCodeLike: 'ISO 코드 포함',
    isoCodeNotLike: 'ISO 코드 제외',
    phoneCodeEq: '전화번호 코드 일치',
    phoneCodeNeq: '전화번호 코드 불일치',
    phoneCodeMin: '전화번호 코드 최소',
    phoneCodeMax: '전화번호 코드 최대',
    emojiEq: '이모지 일치',
    emojiNeq: '이모지 불일치',
    emojiMin: '이모지 최소',
    emojiMax: '이모지 최대',
    emojiLike: '이모지 포함',
    emojiNotLike: '이모지 제외',
    userBirthCountEq: '출신 국가로 등록한 사용자 수 일치',
    userBirthCountNeq: '출신 국가로 등록한 사용자 수 불일치',
    userBirthCountMin: '출신 국가로 등록한 사용자 수 최소',
    userBirthCountMax: '출신 국가로 등록한 사용자 수 최대',
    userResidenceCountEq: '거주 국가로 등록한 사용자 수 일치',
    userResidenceCountNeq: '거주 국가로 등록한 사용자 수 불일치',
    userResidenceCountMin: '거주 국가로 등록한 사용자 수 최소',
    userResidenceCountMax: '거주 국가로 등록한 사용자 수 최대',
    userPhoneCodeCountEq: '전화번호 코드로 등록한 사용자 수 일치',
    userPhoneCodeCountNeq: '전화번호 코드로 등록한 사용자 수 불일치',
    userPhoneCodeCountMin: '전화번호 코드로 등록한 사용자 수 최소',
    userPhoneCodeCountMax: '전화번호 코드로 등록한 사용자 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
