import {ContentCommentReportReasonDomain} from "../../domain/content/ContentCommentReportReasonDomain"

export const ContentCommentReportReasonDescriptor: Record<keyof ContentCommentReportReasonDomain, (o: ContentCommentReportReasonDomain) => string> = {
    id(o: ContentCommentReportReasonDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentCommentReportReasonDomain): string {
        return o.creatorId.toString()
    },
    body(o: ContentCommentReportReasonDomain): string {
        return o.body
    },
    displayOrder(o: ContentCommentReportReasonDomain): string {
        return o.displayOrder.toString()
    },
    reportCount(o: ContentCommentReportReasonDomain): string {
        return o.reportCount.toString()
    },
    createdAt(o: ContentCommentReportReasonDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentCommentReportReasonDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: ContentCommentReportReasonDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
