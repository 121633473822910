import {AssetCouponPutDataTransferObject} from "../../data-transfer-object/asset/AssetCouponPutDataTransferObject"

/**
 * 재화 쿠폰 속성 설명
 */
export const AssetCouponPutDataTransferObjectDescription: Record<keyof AssetCouponPutDataTransferObject, string> = {
    code: '코드',
    amount: '수량',
    assetLunarAmount: '지급 루나',
    assetSolarAmount: '지급 솔라',
    assetStarAmount: '지급 스타'
}
