import {EnvAgreementType} from "../../constant/env/EnvAgreementType"
import {EnvAgreementDomain} from "../../domain/env/EnvAgreementDomain"

export const EnvAgreementDescriptor: Record<keyof EnvAgreementDomain, (o: EnvAgreementDomain) => string> = {
    id(o: EnvAgreementDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvAgreementDomain): string {
        return o.creatorId.toString()
    },
    type(o: EnvAgreementDomain): string {
        return EnvAgreementType.description(o.type)
    },
    body(o: EnvAgreementDomain): string {
        return o.body
    },
    userAgreementCount(o: EnvAgreementDomain): string {
        return o.userAgreementCount.toString()
    },
    createdAt(o: EnvAgreementDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
