import {EnvBankVerboseDomain} from "../../domain/env/EnvBankVerboseDomain"
import {EnvBankId} from "../../id/env/EnvBankId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 은행 상세
 */
export class EnvBankVerboseValueObject implements VerboseValueObject, EnvBankVerboseDomain {
    /**
     * 기본키
     */
    id: EnvBankId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 이름
     */
    name: string
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * 솔라 인출 횟수
     */
    assetSolarWithdrawalCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof EnvBankVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.name = String(o.name)
        this.displayOrder = Number(o.displayOrder)
        this.assetSolarWithdrawalCount = Number(o.assetSolarWithdrawalCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
