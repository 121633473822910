import {AssetCouponModificationDomain} from "../../domain/asset/AssetCouponModificationDomain"
import {AssetCouponId} from "../../id/asset/AssetCouponId"
import {AssetCouponModificationId} from "../../id/asset/AssetCouponModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 재화 쿠폰 수정 이력
 */
export class AssetCouponModificationValueObject implements ValueObject, AssetCouponModificationDomain {
    /**
     * 기본키
     */
    id: AssetCouponModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 재화 쿠폰 ID
     */
    assetCouponId: AssetCouponId
    /**
     * 코드
     */
    code: string
    /**
     * 수량
     */
    amount: number
    /**
     * 지급 루나
     */
    assetLunarAmount: number
    /**
     * 지급 솔라
     */
    assetSolarAmount: number
    /**
     * 지급 스타
     */
    assetStarAmount: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetCouponModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetCouponId = BigInt(o.assetCouponId)
        this.code = String(o.code)
        this.amount = Number(o.amount)
        this.assetLunarAmount = Number(o.assetLunarAmount)
        this.assetSolarAmount = Number(o.assetSolarAmount)
        this.assetStarAmount = Number(o.assetStarAmount)
        this.createdAt = new Date(o.createdAt)
    }
}
