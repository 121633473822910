import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {Button, Title3} from "@fluentui/react-components";
import {PostFinishDialog} from "../../../PostField";
import React, {useCallback} from "react";
import {AssetSolarWithdrawalOptionIO} from "../../../../io/AssetIO";
import {
    useAssetSolarWithdrawalOptionPostState
} from "../../../../data/data-transfer-object-state/asset/AssetSolarWithdrawalOptionPostState";
import {
    AssetSolarWithdrawalOptionPostFields
} from "../../../data-transfer-object-field/asset/AssetSolarWithdrawalOptionPostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useAssetSolarWithdrawalOptionPostState()

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        AssetSolarWithdrawalOptionIO
            .post(state.toDataTransferObject())
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        <div className={props.styles.column16}>
            <Title3>새 항목</Title3>
            <AssetSolarWithdrawalOptionPostFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/assets/solars/withdrawalOptions'} />
    </>
}