import {UserRankDuration} from "../../../data/constant/user/UserRankDuration"
import {EnvPolicyPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvPolicyPostDataTransferObjectDescription"
import {EnvPolicyPostState} from "../../../data/data-transfer-object-state/env/EnvPolicyPostState"
import {EnvPolicyPostDataTransferObject} from "../../../data/data-transfer-object/env/EnvPolicyPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvPolicyPostState
}

export const EnvPolicyPostField: Record<Capitalize<keyof EnvPolicyPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    EnvAdminPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envAdminPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envAdminPageSize.value}
                    setter={props.state.envAdminPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvAgreementPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envAgreementPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envAgreementPageSize.value}
                    setter={props.state.envAgreementPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvBankPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envBankPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envBankPageSize.value}
                    setter={props.state.envBankPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvClientPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envClientPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envClientPageSize.value}
                    setter={props.state.envClientPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvConstructionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envConstructionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envConstructionPageSize.value}
                    setter={props.state.envConstructionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvCountryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envCountryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envCountryPageSize.value}
                    setter={props.state.envCountryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvCountryRankDuration(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envCountryRankDuration}>
                <PostEnumField
                    {...props}
                    value={props.state.envCountryRankDuration.value}
                    setter={props.state.envCountryRankDuration.setter}
                    selections={UserRankDuration.selections} />
            </PostFieldWrapper>
        )
    },
    EnvEventPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envEventPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envEventPageSize.value}
                    setter={props.state.envEventPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvFaqPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envFaqPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envFaqPageSize.value}
                    setter={props.state.envFaqPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvFaqItemPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envFaqItemPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envFaqItemPageSize.value}
                    setter={props.state.envFaqItemPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvNoticePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envNoticePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envNoticePageSize.value}
                    setter={props.state.envNoticePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvPhoneBlockPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envPhoneBlockPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envPhoneBlockPageSize.value}
                    setter={props.state.envPhoneBlockPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envPolicyPageSize.value}
                    setter={props.state.envPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvStorageKeyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPostDataTransferObjectDescription.envStorageKeyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envStorageKeyPageSize.value}
                    setter={props.state.envStorageKeyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
}

export function EnvPolicyPostFields(props: PostFieldProps) {
    return <>
        <EnvPolicyPostField.EnvAdminPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvAgreementPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvBankPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvClientPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvConstructionPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvCountryPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvCountryRankDuration
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvEventPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvFaqPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvFaqItemPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvNoticePageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvPhoneBlockPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvPolicyPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPostField.EnvStorageKeyPageSize
            {...props}
            state={props.state} />
    </>
}
