import {AssetStarExchangeOptionDomain} from "../../domain/asset/AssetStarExchangeOptionDomain"

/**
 * 스타 교환 옵션 속성 설명
 */
export const AssetStarExchangeOptionDescription: Record<keyof AssetStarExchangeOptionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    starAmount: '지급 스타',
    solarAmount: '차감 솔라',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
