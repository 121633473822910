import {EnvPhoneBlockModificationDomain} from "../../domain/env/EnvPhoneBlockModificationDomain"

/**
 * 전화번호 차단 수정 이력 속성 설명
 */
export const EnvPhoneBlockModificationDescription: Record<keyof EnvPhoneBlockModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envPhoneBlockId: '전화번호 차단 ID',
    envCountryId: '국가 ID',
    phone: '전화번호',
    createdAt: '수정일'
}
