import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentLikeValueObject
} from "../../../data/value-object/content/ContentLikeValueObject";
import {useEffect} from "react";
import {ContentLikeIO} from "../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {ContentLikeDescription} from "../../../data/description/content/ContentLikeDescription";
import {ContentLikeDomain} from "../../../data/domain/content/ContentLikeDomain";
import {ContentLikeDescriptor} from "../../../data/descriptor/content/ContentLikeDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentLikeId: string }>()
    const item = useStatePair<ContentLikeValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentLikeId !== undefined) {
            ContentLikeIO
                .getById(BigInt(params.contentLikeId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentLikeValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentLikeIO}
                description={ContentLikeDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentLikeDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{ContentLikeDescriptor[props.itemKey](props.item)}</Body1>
        case "contentId":
            return <a href={`/contents/${props.item.contentId}`}><Body1>{ContentLikeDescriptor[props.itemKey](props.item)}</Body1></a>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentLikeDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}