import {EnvFaqDomain} from "../../domain/env/EnvFaqDomain"

export const EnvFaqDescriptor: Record<keyof EnvFaqDomain, (o: EnvFaqDomain) => string> = {
    id(o: EnvFaqDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvFaqDomain): string {
        return o.creatorId.toString()
    },
    title(o: EnvFaqDomain): string {
        return o.title
    },
    displayOrder(o: EnvFaqDomain): string {
        return o.displayOrder.toString()
    },
    envFaqItemCount(o: EnvFaqDomain): string {
        return o.envFaqItemCount.toString()
    },
    createdAt(o: EnvFaqDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvFaqDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvFaqDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
