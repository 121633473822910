import {SearchProps} from "../common/SearchProps";
import {SearchWrapper} from "../common/Search";
import {UserValueObject} from "../../data/value-object/user/UserValueObject";
import {UserIO} from "../../io/UserIO";
import {UserDescription} from "../../data/description/user/UserDescription";
import {UserSearchOptionDescription} from "../../data/description/user/UserSearchOptionDescription";
import {UserDescriptor} from "../../data/descriptor/user/UserDescriptor";
import {applyUserSearchOption, UserSearchOptionField} from "../search-option-field/user/UserSearchOptionField";

export type UserSearchProps<E> = SearchProps<E, UserValueObject>

export function UserSearch<E>(props: UserSearchProps<E>) {
    return <>
        <SearchWrapper
            {...props}
            title={'사용자'}
            client={UserIO}
            voDescription={UserDescription}
            soDescription={UserSearchOptionDescription}
            descriptor={UserDescriptor}
            fields={UserSearchOptionField}
            optionApplier={applyUserSearchOption} />
    </>
}