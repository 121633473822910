import {AssetCouponPutDataTransferObject} from "../../data-transfer-object/asset/AssetCouponPutDataTransferObject"
import {AssetCouponValueObject} from "../../value-object/asset/AssetCouponValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetCouponPutState = {
    code: StatePair<string>
    amount: StatePair<number>
    assetLunarAmount: StatePair<number>
    assetSolarAmount: StatePair<number>
    assetStarAmount: StatePair<number>
    toDataTransferObject(): AssetCouponPutDataTransferObject
    fromValueObject(vo: AssetCouponValueObject): void
    values(): [string, number, number, number, number]
}

export function useAssetCouponPutState(): AssetCouponPutState {
    return {
        code: useStatePair<string>(''),
        amount: useStatePair<number>(0),
        assetLunarAmount: useStatePair<number>(0),
        assetSolarAmount: useStatePair<number>(0),
        assetStarAmount: useStatePair<number>(0),
        toDataTransferObject(): AssetCouponPutDataTransferObject {
            return {
                code: this.code.value,
                amount: this.amount.value,
                assetLunarAmount: this.assetLunarAmount.value,
                assetSolarAmount: this.assetSolarAmount.value,
                assetStarAmount: this.assetStarAmount.value,
            }
        },
        fromValueObject(vo: AssetCouponValueObject) {
            this.code.setter(vo.code)
            this.amount.setter(vo.amount)
            this.assetLunarAmount.setter(vo.assetLunarAmount)
            this.assetSolarAmount.setter(vo.assetSolarAmount)
            this.assetStarAmount.setter(vo.assetStarAmount)
        },
        values(): [string, number, number, number, number] {
            return [
                this.code.value,
                this.amount.value,
                this.assetLunarAmount.value,
                this.assetSolarAmount.value,
                this.assetStarAmount.value,
            ]
        }
    }
}
