import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentReportReasonValueObject} from "../../../../data/value-object/content/ContentReportReasonValueObject";
import {ContentReportReasonSearchOption} from "../../../../data/search-option/content/ContentReportReasonSearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {ContentReportReasonIO} from "../../../../io/ContentIO";
import {ContentReportReasonDescription} from "../../../../data/description/content/ContentReportReasonDescription";
import {ContentReportReasonDescriptor} from "../../../../data/descriptor/content/ContentReportReasonDescriptor";
import ListOption from "../../../ListOption";
import {
    ContentReportReasonSearchOptionDescription
} from "../../../../data/description/content/ContentReportReasonSearchOptionDescription";
import {
    applyContentReportReasonSearchOption,
    ContentReportReasonSearchOptionField
} from "../../../search-option-field/content/ContentReportReasonSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentReportReasonValueObject[]>([])
    const option = useStatePair<ContentReportReasonSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentReportReasonIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentReportReasonDescription}
                descriptor={ContentReportReasonDescriptor}
                showPost
                onPostClick={() => document.location = '/contents/reports/reasons/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/reports/reasons/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentReportReasonSearchOptionDescription}
                fields={ContentReportReasonSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentReportReasonSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentReportReasonDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}