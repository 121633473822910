import {EnvCountryDescriptor} from "../../../data/descriptor/env/EnvCountryDescriptor";
import {EnvCountrySearchOptionDescription} from "../../../data/description/env/EnvCountrySearchOptionDescription";
import {
    applyEnvCountrySearchOption,
    EnvCountrySearchOptionField
} from "../../search-option-field/env/EnvCountrySearchOptionField";
import {SearchProps} from "../../common/SearchProps";
import {SearchWrapper} from "../../common/Search";
import {EnvCountryValueObject} from "../../../data/value-object/env/EnvCountryValueObject";
import {EnvCountryIO} from "../../../io/EnvIO";
import {EnvCountryDescription} from "../../../data/description/env/EnvCountryDescription";

export type EnvCountrySearchProps<E> = SearchProps<E, EnvCountryValueObject>

export function EnvCountrySearch<E>(props: EnvCountrySearchProps<E>) {
    return <>
        <SearchWrapper
            {...props}
            title={'국가'}
            client={EnvCountryIO}
            voDescription={EnvCountryDescription}
            soDescription={EnvCountrySearchOptionDescription}
            descriptor={EnvCountryDescriptor}
            fields={EnvCountrySearchOptionField}
            optionApplier={applyEnvCountrySearchOption} />
    </>
}