import {EnvEventDomain} from "../../domain/env/EnvEventDomain"

export const EnvEventDescriptor: Record<keyof EnvEventDomain, (o: EnvEventDomain) => string> = {
    id(o: EnvEventDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvEventDomain): string {
        return o.creatorId.toString()
    },
    title(o: EnvEventDomain): string {
        return o.title
    },
    url(o: EnvEventDomain): string {
        return o.url
    },
    createdAt(o: EnvEventDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvEventDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvEventDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
