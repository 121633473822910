import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryPopularPreemptionDomain} from "../../domain/content/ContentQueryPopularPreemptionDomain"

export const ContentQueryPopularPreemptionDescriptor: Record<keyof ContentQueryPopularPreemptionDomain, (o: ContentQueryPopularPreemptionDomain) => string> = {
    id(o: ContentQueryPopularPreemptionDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentQueryPopularPreemptionDomain): string {
        return o.creatorId.toString()
    },
    contentType(o: ContentQueryPopularPreemptionDomain): string {
        return ContentType.description(o.contentType)
    },
    query(o: ContentQueryPopularPreemptionDomain): string {
        return o.query
    },
    exposedAt(o: ContentQueryPopularPreemptionDomain): string {
        return o.exposedAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    exposedUntil(o: ContentQueryPopularPreemptionDomain): string {
        return o.exposedUntil.toISOString().substring(0, 19).replace('T', ' ')
    },
    displayOrder(o: ContentQueryPopularPreemptionDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: ContentQueryPopularPreemptionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentQueryPopularPreemptionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
