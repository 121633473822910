import {UserExpSearchOption} from "../../search-option/user/UserExpSearchOption"

/**
 * 사용자 경험치 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserExpSearchOptionDescription: Record<keyof UserExpSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    creatorIdIsNull: '생성자 ID 값 존재 여부',
    typeEq: '지급 유형 일치',
    typeNeq: '지급 유형 불일치',
    typeMin: '지급 유형 최소',
    typeMax: '지급 유형 최대',
    typeIn: '지급 유형 포함',
    amountEq: '변화량 일치',
    amountNeq: '변화량 불일치',
    amountMin: '변화량 최소',
    amountMax: '변화량 최대',
    lastAccumulationEq: '직전 누계 일치',
    lastAccumulationNeq: '직전 누계 불일치',
    lastAccumulationMin: '직전 누계 최소',
    lastAccumulationMax: '직전 누계 최대',
    inDayEq: '일간 누적 일치',
    inDayNeq: '일간 누적 불일치',
    inDayMin: '일간 누적 최소',
    inDayMax: '일간 누적 최대',
    inWeekEq: '주간 누적 일치',
    inWeekNeq: '주간 누적 불일치',
    inWeekMin: '주간 누적 최소',
    inWeekMax: '주간 누적 최대',
    inMonthEq: '월간 누적 일치',
    inMonthNeq: '월간 누적 불일치',
    inMonthMin: '월간 누적 최소',
    inMonthMax: '월간 누적 최대',
    inQuarterEq: '분기간 누적 일치',
    inQuarterNeq: '분기간 누적 불일치',
    inQuarterMin: '분기간 누적 최소',
    inQuarterMax: '분기간 누적 최대',
    inHalfEq: '반기간 누적 일치',
    inHalfNeq: '반기간 누적 불일치',
    inHalfMin: '반기간 누적 최소',
    inHalfMax: '반기간 누적 최대',
    inYearEq: '연간 누적 일치',
    inYearNeq: '연간 누적 불일치',
    inYearMin: '연간 누적 최소',
    inYearMax: '연간 누적 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
