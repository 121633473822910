import {EnvPhoneBlockSearchOption} from "../../search-option/env/EnvPhoneBlockSearchOption"

/**
 * 전화번호 차단 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvPhoneBlockSearchOptionDescription: Record<keyof EnvPhoneBlockSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    envCountryIdEq: '국가 ID 일치',
    envCountryIdNeq: '국가 ID 불일치',
    envCountryIdMin: '국가 ID 최소',
    envCountryIdMax: '국가 ID 최대',
    phoneEq: '전화번호 일치',
    phoneNeq: '전화번호 불일치',
    phoneMin: '전화번호 최소',
    phoneMax: '전화번호 최대',
    phoneLike: '전화번호 포함',
    phoneNotLike: '전화번호 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
