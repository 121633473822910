import {useStatePair} from "react-type-extension";
import {UserExpType} from "../../../data/constant/user/UserExpType";
import {RouterProps} from "../../RouterProps";
import {useCallback} from "react";
import {UserExpIO} from "../../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostBigIntField, PostEnumField, PostFieldWrapper, PostFinishDialog, PostNumberField} from "../../PostField";
import {UserExpDescription} from "../../../data/description/user/UserExpDescription";
import {UserSearch} from "../Search";

enum Phase {
    Waiting,
    Posting,
    Finished,
    UserList,
    UserOption,
    UserOrder
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const userId = useStatePair(0n)
    const type = useStatePair<UserExpType>(UserExpType.Admin)
    const amount = useStatePair(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        UserExpIO
            .post({
                userId: userId.value,
                type: type.value,
                amount: amount.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [userId.value, type.value, amount.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    showSearch
                    onSearchClick={() => phase.setter(Phase.UserList)}
                    title={UserExpDescription.userId}>
                    <PostBigIntField
                        {...props}
                        value={userId.value}
                        setter={userId.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserExpDescription.type}>
                    <PostEnumField
                        {...props}
                        value={type.value}
                        setter={type.setter}
                        selections={UserExpType.selections} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserExpDescription.amount}>
                    <PostNumberField
                        {...props}
                        value={amount.value}
                        setter={amount.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <UserSearch
            {...props}
            phase={phase}
            phases={{ waiting: Phase.Waiting, list: Phase.UserList, order: Phase.UserOrder, option: Phase.UserOption }}
            setter={value => userId.setter(value.id)} />
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/users/exps'}/>
    </>
}