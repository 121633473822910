import {useStatePair} from "react-type-extension";
import {ContentType} from "../../data/constant/content/ContentType";
import {useParams} from "react-router-dom";
import {RouterProps} from "../RouterProps";
import {ContentValueObject} from "../../data/value-object/content/ContentValueObject";
import React, {useCallback, useEffect} from "react";
import {ContentDetailUrlIO, ContentIO, ContentResultUrlIO} from "../../io/ContentIO";
import {MediaFile} from "../Media";
import {ContentId} from "../../data/id/content/ContentId";
import {StorageIO, StorageKey} from "../../io/StorageIO";
import {Button, Spinner, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFileField, PostFilesField} from "../PostField";
import {Promises} from "../../util/PromiseUtils";
import {useContentPutState} from "../../data/data-transfer-object-state/content/ContentPutState";
import {ContentPutFields} from "../data-transfer-object-field/content/ContentPutField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ contentId: string }>()
    const item = useStatePair<ContentValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const state = useContentPutState()
    const thumbnailImage = useStatePair<MediaFile | undefined>(undefined)
    const headerImages = useStatePair<MediaFile[] | undefined>(undefined)
    const descriptionImages = useStatePair<MediaFile[] | undefined>(undefined)
    const participationMedia = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.contentId !== undefined) {
            fetch(BigInt(params.contentId))
                .then(() => phase.setter(Phase.Waiting))
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const fetch = async (id: ContentId) => {
        const response = await ContentIO.getById(id)
        const contentDetailUrlResponse = await ContentDetailUrlIO.getById(response.contentDetailUrlId)
        const contentResultUrlResponse = await ContentResultUrlIO.getById(response.contentResultUrlId)

        item.setter(response)
        state.fromValueObject(response)
        state.contentDetailUrl.setter(contentDetailUrlResponse.url)
        state.contentResultUrl.setter(contentResultUrlResponse.url)
        try {
            thumbnailImage.setter(await MediaFile.createFromStorageKey(StorageKey.Content.thumbnail(response.id)))
            headerImages.setter(await MediaFile.createFromStorageKeys(StorageKey.Content.headers(response.id, response.headerImageCount)))
            descriptionImages.setter(await MediaFile.createFromStorageKeys(StorageKey.Content.descriptions(response.id, response.descriptionImageCount)))
            participationMedia.setter(await MediaFile.createFromStorageKey(StorageKey.Content.participation(response.id)))
        } catch (e) {
            if ((e as Error).name !== 'NoSuchKey') {
                throw e
            }
        }
    }

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        if (thumbnailImage.value === undefined) {
            alert('썸네일 이미지를 선택해주세요.')
            return
        }
        if (headerImages.value === undefined) {
            alert('상단 이미지를 선택해주세요.')
            return
        }
        if (descriptionImages.value === undefined) {
            alert('설명 이미지를 선택해주세요.')
            return
        }

        phase.setter(Phase.Putting)
        put(id, thumbnailImage.value, headerImages.value, descriptionImages.value, participationMedia.value)
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/contents/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [...state.values(), thumbnailImage.value, headerImages.value, descriptionImages.value, participationMedia.value])

    const put = async (
        id: ContentId,
        thumbnailImage: MediaFile,
        headerImages: MediaFile[],
        descriptionImages: MediaFile[],
        participationMedia: MediaFile | undefined
    ) => {
        const content = await ContentIO.put(id, state.toDataTransferObject())
        await new Promises()
            .pend(StorageIO.putFile(StorageKey.Content.thumbnail(content.id), thumbnailImage))
            .pendEach(headerImages, (value, index) => StorageIO.putFile(StorageKey.Content.header(content.id, index), value))
            .pendEach(descriptionImages, (value, index) => StorageIO.putFile(StorageKey.Content.description(content.id, index), value))
            .pendIf(participationMedia, value => StorageIO.putFile(StorageKey.Content.participation(content.id), value))
            .await()
    }

    return <>
        {phase.value === Phase.Loading && <Spinner />}
        {item.value !== undefined && (phase.value === Phase.Waiting || phase.value === Phase.Putting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <ContentPutFields
                    {...props}
                    state={state} />
                {/* 오늘의 단어가 아닐 때에만 이미지 등록 */}
                {item.value.type !== ContentType.Daily && <>
                    <PostFieldWrapper
                        {...props}
                        title={'썸네일 이미지'}>
                        <PostFileField
                            {...props}
                            value={thumbnailImage.value}
                            setter={thumbnailImage.setter}
                            accept={'image/*'} />
                    </PostFieldWrapper>
                    <PostFieldWrapper
                        {...props}
                        title={'상단 이미지'}>
                        <PostFilesField
                            {...props}
                            value={headerImages.value}
                            setter={headerImages.setter}
                            accept={'image/*'}
                            multiple />
                    </PostFieldWrapper>
                    <PostFieldWrapper
                        {...props}
                        title={'설명 이미지'}>
                        <PostFilesField
                            {...props}
                            value={descriptionImages.value}
                            setter={descriptionImages.setter}
                            accept={'image/*'}
                            multiple />
                    </PostFieldWrapper>
                    <PostFieldWrapper
                        {...props}
                        title={'참여 이미지 또는 비디오'}>
                        <PostFileField
                            {...props}
                            value={participationMedia.value}
                            setter={participationMedia.setter}
                            accept={'image/*,video/*'} />
                    </PostFieldWrapper>
                </>}
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
    </>
}