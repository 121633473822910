import {UserRankDomain} from "../../domain/user/UserRankDomain"

export const UserRankDescriptor: Record<keyof UserRankDomain, (o: UserRankDomain) => string> = {
    id(o: UserRankDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserRankDomain): string {
        return o.creatorId.toString()
    },
    name(o: UserRankDomain): string {
        return o.name
    },
    percentile(o: UserRankDomain): string {
        return o.percentile?.toString() ?? '-'
    },
    fixed(o: UserRankDomain): string {
        return o.fixed?.toString() ?? '-'
    },
    applyOrder(o: UserRankDomain): string {
        return o.applyOrder.toString()
    },
    rewardAssetLunarAmount(o: UserRankDomain): string {
        return o.rewardAssetLunarAmount.toString()
    },
    rewardAssetSolarAmount(o: UserRankDomain): string {
        return o.rewardAssetSolarAmount.toString()
    },
    rewardAssetStarAmount(o: UserRankDomain): string {
        return o.rewardAssetStarAmount.toString()
    },
    createdAt(o: UserRankDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserRankDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: UserRankDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
