import {UserBlockReasonSearchOption} from "../../search-option/user/UserBlockReasonSearchOption"

/**
 * 사용자 이용 정지 사유 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserBlockReasonSearchOptionDescription: Record<keyof UserBlockReasonSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    bodyEq: '사유 일치',
    bodyNeq: '사유 불일치',
    bodyMin: '사유 최소',
    bodyMax: '사유 최대',
    bodyLike: '사유 포함',
    bodyNotLike: '사유 제외',
    userBlockCountEq: '사유 적용 수 일치',
    userBlockCountNeq: '사유 적용 수 불일치',
    userBlockCountMin: '사유 적용 수 최소',
    userBlockCountMax: '사유 적용 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
