import {RouterProps} from "../../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentCommentReportReasonVerboseValueObject
} from "../../../../../data/value-object/content/ContentCommentReportReasonVerboseValueObject";
import {useCallback, useEffect} from "react";
import {ContentCommentReportReasonIO} from "../../../../../io/ContentIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    useContentCommentReportReasonPutState
} from "../../../../../data/data-transfer-object-state/content/ContentCommentReportReasonPutState";
import {
    ContentCommentReportReasonPutFields
} from "../../../../data-transfer-object-field/content/ContentCommentReportReasonPutField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ contentCommentReportReasonId: string }>()
    const item = useStatePair<ContentCommentReportReasonVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const state = useContentCommentReportReasonPutState()

    useEffect(() => {
        if (item.value === undefined && params.contentCommentReportReasonId !== undefined) {
            ContentCommentReportReasonIO
                .getVerboseById(BigInt(params.contentCommentReportReasonId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        ContentCommentReportReasonIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/contents/comments/reports/reasons/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <ContentCommentReportReasonPutFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}