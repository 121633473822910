import {ContentDifficulty} from "../../constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../constant/content/ContentScriptVisibility"
import {ContentType} from "../../constant/content/ContentType"
import {ContentDomain} from "../../domain/content/ContentDomain"
import {AdvertiserId} from "../../id/advertiser/AdvertiserId"
import {ContentDetailUrlId} from "../../id/content/ContentDetailUrlId"
import {ContentId} from "../../id/content/ContentId"
import {ContentResultUrlId} from "../../id/content/ContentResultUrlId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 참여 가능 항목
 */
export class ContentValueObject implements ValueObject, ContentDomain {
    /**
     * 기본키
     */
    id: ContentId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 광고주 ID
     */
    advertiserId?: AdvertiserId
    /**
     * 유형
     */
    type: ContentType
    /**
     * 참여 방법
     */
    participationMethod: ContentParticipationMethod
    /**
     * 제목
     */
    title: string
    /**
     * 제목 강조
     */
    titleHighlight?: string
    /**
     * 내용
     */
    body: string
    /**
     * 스크립트
     */
    script: string
    /**
     * 스크립트 표시 유형
     */
    scriptVisibility: ContentScriptVisibility
    /**
     * 콘텐츠 더 알아보기 URL ID
     */
    contentDetailUrlId: ContentDetailUrlId
    /**
     * 콘텐츠 참여 결과 URL ID
     */
    contentResultUrlId: ContentResultUrlId
    /**
     * 채점 중 텍스트
     */
    gradingMessage?: string
    /**
     * 보상 지급 수량
     */
    amount: number
    /**
     * 합격 기준(%)
     */
    passAccuracy: number
    /**
     * 높은 일치율 기준(%)
     */
    passAccuracyHigh?: number
    /**
     * 합격 지급 솔라
     */
    passSolarAmount: number
    /**
     * 높은 일치율 지급 솔라
     */
    passSolarAmountHighAccuracy: number
    /**
     * 소모 스타
     */
    starAmount: number
    /**
     * 난이도
     */
    difficulty: ContentDifficulty
    /**
     * 상단 이미지 수
     */
    headerImageCount: number
    /**
     * 설명 이미지 수
     */
    descriptionImageCount: number
    /**
     * 노출 시작일
     */
    exposedAt: Date
    /**
     * 참여 시작일
     */
    startAt: Date
    /**
     * 참여 종료일
     */
    endAt: Date
    /**
     * 상태 플래그
     */
    statusFlags: number
    /**
     * 대상 수
     */
    contentTargetCount: number
    /**
     * 참여 수
     */
    participationCount: number
    /**
     * 합격 수
     */
    participationPassCount: number
    /**
     * 높은 일치율 수
     */
    participationHighAccuracyCount: number
    /**
     * 댓글 수
     */
    commentCount: number
    /**
     * (와글와글) 신고 수
     */
    reportCount: number
    /**
     * (와글와글) 긍정 평가 수
     */
    reviewPositiveCount: number
    /**
     * (와글와글) 중립 평가 수
     */
    reviewNeutralCount: number
    /**
     * (와글와글) 부정 평가 수
     */
    reviewNegativeCount: number
    /**
     * 좋아요 수
     */
    likeCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof ContentDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        if (o.advertiserId !== undefined && o.advertiserId !== null) {
            this.advertiserId = BigInt(o.advertiserId)
        }
        this.type = o.type as ContentType
        this.participationMethod = o.participationMethod as ContentParticipationMethod
        this.title = String(o.title)
        if (o.titleHighlight !== undefined && o.titleHighlight !== null) {
            this.titleHighlight = String(o.titleHighlight)
        }
        this.body = String(o.body)
        this.script = String(o.script)
        this.scriptVisibility = o.scriptVisibility as ContentScriptVisibility
        this.contentDetailUrlId = BigInt(o.contentDetailUrlId)
        this.contentResultUrlId = BigInt(o.contentResultUrlId)
        if (o.gradingMessage !== undefined && o.gradingMessage !== null) {
            this.gradingMessage = String(o.gradingMessage)
        }
        this.amount = Number(o.amount)
        this.passAccuracy = Number(o.passAccuracy)
        if (o.passAccuracyHigh !== undefined && o.passAccuracyHigh !== null) {
            this.passAccuracyHigh = Number(o.passAccuracyHigh)
        }
        this.passSolarAmount = Number(o.passSolarAmount)
        this.passSolarAmountHighAccuracy = Number(o.passSolarAmountHighAccuracy)
        this.starAmount = Number(o.starAmount)
        this.difficulty = o.difficulty as ContentDifficulty
        this.headerImageCount = Number(o.headerImageCount)
        this.descriptionImageCount = Number(o.descriptionImageCount)
        this.exposedAt = new Date(o.exposedAt)
        this.startAt = new Date(o.startAt)
        this.endAt = new Date(o.endAt)
        this.statusFlags = Number(o.statusFlags)
        this.contentTargetCount = Number(o.contentTargetCount)
        this.participationCount = Number(o.participationCount)
        this.participationPassCount = Number(o.participationPassCount)
        this.participationHighAccuracyCount = Number(o.participationHighAccuracyCount)
        this.commentCount = Number(o.commentCount)
        this.reportCount = Number(o.reportCount)
        this.reviewPositiveCount = Number(o.reviewPositiveCount)
        this.reviewNeutralCount = Number(o.reviewNeutralCount)
        this.reviewNegativeCount = Number(o.reviewNegativeCount)
        this.likeCount = Number(o.likeCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
