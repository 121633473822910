import {EnvCountryModificationDomain} from "../../domain/env/EnvCountryModificationDomain"

export const EnvCountryModificationDescriptor: Record<keyof EnvCountryModificationDomain, (o: EnvCountryModificationDomain) => string> = {
    id(o: EnvCountryModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvCountryModificationDomain): string {
        return o.creatorId.toString()
    },
    envCountryId(o: EnvCountryModificationDomain): string {
        return o.envCountryId.toString()
    },
    name(o: EnvCountryModificationDomain): string {
        return o.name
    },
    isoCode(o: EnvCountryModificationDomain): string {
        return o.isoCode
    },
    phoneCode(o: EnvCountryModificationDomain): string {
        return o.phoneCode.toString()
    },
    emoji(o: EnvCountryModificationDomain): string {
        return o.emoji
    },
    createdAt(o: EnvCountryModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
