import {RouterProps} from "../RouterProps";
import {useParams} from "react-router-dom";
import {StatePair, useStatePair} from "react-type-extension";
import React, {useCallback, useEffect} from "react";
import {UserIO} from "../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {EnvCountryId} from "../../data/id/env/EnvCountryId";
import {searchIndex} from "../../io/HttpClient";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import ListPageWrapper from "../ListPageWrapper";
import ListOption from "../ListOption";
import ListOrder from "../ListOrder";
import {EnvCountryDomain} from "../../data/domain/env/EnvCountryDomain";
import {EnvCountrySearchOption} from "../../data/search-option/env/EnvCountrySearchOption";
import {EnvCountryIO} from "../../io/EnvIO";
import {EnvCountryDescription} from "../../data/description/env/EnvCountryDescription";
import {EnvCountryDescriptor} from "../../data/descriptor/env/EnvCountryDescriptor";
import {EnvCountrySearchOptionDescription} from "../../data/description/env/EnvCountrySearchOptionDescription";
import {
    applyEnvCountrySearchOption,
    EnvCountrySearchOptionField
} from "../search-option-field/env/EnvCountrySearchOptionField";
import {UserValueObject} from "../../data/value-object/user/UserValueObject";
import {useUserPutState} from "../../data/data-transfer-object-state/user/UserPutState";
import {UserPutFields} from "../data-transfer-object-field/user/UserPutField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
    ResidenceCountryList,
    ResidenceCountryOption,
    ResidenceCountryOrder,
    PhoneCountryList,
    PhoneCountryOption,
    PhoneCountryOrder
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userId: string }>()
    const item = useStatePair<UserValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const state = useUserPutState()

    useEffect(() => {
        if (params.userId !== undefined) {
            UserIO
                .getVerboseById(BigInt(params.userId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UserIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        {(phase.value === Phase.Putting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <UserPutFields
                    {...props}
                    state={state} />
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        {/*<ResidenceCountrySearch*/}
        {/*    {...props}*/}
        {/*    phase={phase}*/}
        {/*    residenceCountryId={residenceCountryId}/>*/}
        {/*<PhoneCountrySearch*/}
        {/*    {...props}*/}
        {/*    phase={phase}*/}
        {/*    phoneCountryId={phoneCountryId}/>*/}
    </>
}

type ResidenceCountrySearchProps = RouterProps & {
    phase: StatePair<Phase>
    residenceCountryId: StatePair<EnvCountryId>
}

function ResidenceCountrySearch(props: ResidenceCountrySearchProps) {
    const items = useStatePair<EnvCountryDomain[]>([])
    const option = useStatePair<EnvCountrySearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.ResidenceCountryList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>거주 국가 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={EnvCountryIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={EnvCountryDescription}
                    descriptor={EnvCountryDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.ResidenceCountryOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.ResidenceCountryOrder)}
                    onItemClick={item => {
                        props.residenceCountryId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.ResidenceCountryOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}/>
                    <Title3>거주 국가 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={EnvCountrySearchOptionDescription}
                    fields={EnvCountrySearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.ResidenceCountryList)}
                    onApplyClick={(key, value) => {
                        applyEnvCountrySearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.ResidenceCountryOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvCountryDescription}
                onBackClick={() => props.phase.setter(Phase.ResidenceCountryList)}
                onOrderUpdated={() => items.setter([])}/>
        </>}
    </>
}

type PhoneCountrySearchProps = RouterProps & {
    phase: StatePair<Phase>
    phoneCountryId: StatePair<EnvCountryId>
}

function PhoneCountrySearch(props: PhoneCountrySearchProps) {
    const items = useStatePair<EnvCountryDomain[]>([])
    const option = useStatePair<EnvCountrySearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.PhoneCountryList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>거주 국가 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={EnvCountryIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={EnvCountryDescription}
                    descriptor={EnvCountryDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.PhoneCountryOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.PhoneCountryOrder)}
                    onItemClick={item => {
                        props.phoneCountryId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.PhoneCountryOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)} />
                    <Title3>거주 국가 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={EnvCountrySearchOptionDescription}
                    fields={EnvCountrySearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.PhoneCountryList)}
                    onApplyClick={(key, value) => {
                        applyEnvCountrySearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.PhoneCountryOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvCountryDescription}
                onBackClick={() => props.phase.setter(Phase.PhoneCountryList)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}