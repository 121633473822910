import {AssetLunarExchangeOptionDomain} from "../../domain/asset/AssetLunarExchangeOptionDomain"

export const AssetLunarExchangeOptionDescriptor: Record<keyof AssetLunarExchangeOptionDomain, (o: AssetLunarExchangeOptionDomain) => string> = {
    id(o: AssetLunarExchangeOptionDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetLunarExchangeOptionDomain): string {
        return o.creatorId.toString()
    },
    lunarAmount(o: AssetLunarExchangeOptionDomain): string {
        return o.lunarAmount.toString()
    },
    solarAmount(o: AssetLunarExchangeOptionDomain): string {
        return o.solarAmount.toString()
    },
    createdAt(o: AssetLunarExchangeOptionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: AssetLunarExchangeOptionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
