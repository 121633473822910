import ListPageWrapper from "../../../../ListPageWrapper";
import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    ContentQueryPopularPreemptionValueObject
} from "../../../../../data/value-object/content/ContentQueryPopularPreemptionValueObject";
import {
    ContentQueryPopularPreemptionSearchOption
} from "../../../../../data/search-option/content/ContentQueryPopularPreemptionSearchOption";
import {SearchIndex} from "../../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import {ContentQueryPopularPreemptionIO} from "../../../../../io/ContentIO";
import {
    ContentQueryPopularPreemptionDescription
} from "../../../../../data/description/content/ContentQueryPopularPreemptionDescription";
import {
    ContentQueryPopularPreemptionDescriptor
} from "../../../../../data/descriptor/content/ContentQueryPopularPreemptionDescriptor";
import ListOption from "../../../../ListOption";
import {
    ContentQueryPopularPreemptionSearchOptionDescription
} from "../../../../../data/description/content/ContentQueryPopularPreemptionSearchOptionDescription";
import {
    applyContentQueryPopularPreemptionSearchOption,
    ContentQueryPopularPreemptionSearchOptionField
} from "../../../../search-option-field/content/ContentQueryPopularPreemptionSearchOptionField";
import ListOrder from "../../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentQueryPopularPreemptionValueObject[]>([])
    const option = useStatePair<ContentQueryPopularPreemptionSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentQueryPopularPreemptionIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentQueryPopularPreemptionDescription}
                descriptor={ContentQueryPopularPreemptionDescriptor}
                showPost
                onPostClick={() => document.location = '/contents/queries/populars/preemptions/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/queries/populars/preemptions/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentQueryPopularPreemptionSearchOptionDescription}
                fields={ContentQueryPopularPreemptionSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentQueryPopularPreemptionSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentQueryPopularPreemptionDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}