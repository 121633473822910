import {AssetLunarUsageType} from "../../constant/asset/AssetLunarUsageType"
import {AssetLunarUsagePostDataTransferObject} from "../../data-transfer-object/asset/AssetLunarUsagePostDataTransferObject"
import {AssetLunarUsageValueObject} from "../../value-object/asset/AssetLunarUsageValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetLunarUsagePostState = {
    userId: StatePair<bigint>
    type: StatePair<AssetLunarUsageType>
    amount: StatePair<number>
    toDataTransferObject(): AssetLunarUsagePostDataTransferObject
    fromValueObject(vo: AssetLunarUsageValueObject): void
    values(): [bigint, AssetLunarUsageType, number]
}

export function useAssetLunarUsagePostState(): AssetLunarUsagePostState {
    return {
        userId: useStatePair<bigint>(0n),
        type: useStatePair<AssetLunarUsageType>(AssetLunarUsageType.Admin),
        amount: useStatePair<number>(0),
        toDataTransferObject(): AssetLunarUsagePostDataTransferObject {
            return {
                userId: this.userId.value,
                type: this.type.value,
                amount: this.amount.value,
            }
        },
        fromValueObject(vo: AssetLunarUsageValueObject) {
            this.userId.setter(vo.userId)
            this.type.setter(vo.type)
            this.amount.setter(vo.amount)
        },
        values(): [bigint, AssetLunarUsageType, number] {
            return [
                this.userId.value,
                this.type.value,
                this.amount.value,
            ]
        }
    }
}
