import {AssetLunarPurchaseOptionModificationVerboseDomain} from "../../domain/asset/AssetLunarPurchaseOptionModificationVerboseDomain"
import {AssetLunarPurchaseOptionId} from "../../id/asset/AssetLunarPurchaseOptionId"
import {AssetLunarPurchaseOptionModificationId} from "../../id/asset/AssetLunarPurchaseOptionModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {AssetLunarPurchaseOptionValueObject} from "./AssetLunarPurchaseOptionValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 루나 구매 옵션 수정 이력 상세
 */
export class AssetLunarPurchaseOptionModificationVerboseValueObject implements VerboseValueObject, AssetLunarPurchaseOptionModificationVerboseDomain {
    /**
     * 기본키
     */
    id: AssetLunarPurchaseOptionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 루나 구매 옵션 ID
     */
    assetLunarPurchaseOptionId: AssetLunarPurchaseOptionId
    /**
     * 지급 루나
     */
    lunarAmount: number
    /**
     * 지불 금액
     */
    cashAmount: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 루나 구매 옵션 ID
     */
    assetLunarPurchaseOption: AssetLunarPurchaseOptionValueObject

    constructor(o: Record<keyof AssetLunarPurchaseOptionModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetLunarPurchaseOptionId = BigInt(o.assetLunarPurchaseOptionId)
        this.lunarAmount = Number(o.lunarAmount)
        this.cashAmount = Number(o.cashAmount)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.assetLunarPurchaseOption = new AssetLunarPurchaseOptionValueObject(o.assetLunarPurchaseOption)
    }
}
