import {ContentCommentDomain} from "../../domain/content/ContentCommentDomain"

/**
 * 댓글 속성 설명
 */
export const ContentCommentDescription: Record<keyof ContentCommentDomain, string> = {
    id: '기본키',
    contentId: '콘텐츠 ID',
    parentId: '부모 댓글 ID',
    userId: '사용자 ID',
    body: '내용',
    audioId: '오디오 ID',
    childCount: '대댓글 수',
    likeCount: '좋아요 수',
    reportCount: '신고 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
