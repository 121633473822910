import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvAgreementValueObject} from "../../../data/value-object/env/EnvAgreementValueObject";
import {JSX, useEffect} from "react";
import {EnvAgreementIO} from "../../../io/EnvIO";
import {Body1, Button, Spinner, Tab} from "@fluentui/react-components";
import {EnvAgreementDomain} from "../../../data/domain/env/EnvAgreementDomain";
import {EnvAgreementDescriptor} from "../../../data/descriptor/env/EnvAgreementDescriptor";
import {EnvAgreementDescription} from "../../../data/description/env/EnvAgreementDescription";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {UserAgreementIO} from "../../../io/UserIO";
import {UserAgreementValueObject} from "../../../data/value-object/user/UserAgreementValueObject";
import {UserAgreementSearchOption} from "../../../data/search-option/user/UserAgreementSearchOption";
import {UserAgreementDescription} from "../../../data/description/user/UserAgreementDescription";
import {UserAgreementDescriptor} from "../../../data/descriptor/user/UserAgreementDescriptor";
import {Environments} from "../../../Environments";
import {WindowNew24Regular} from "@fluentui/react-icons";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";

enum Panel {
    Default,
    UserAgreementList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envAgreementId: string }>()
    const item = useStatePair<EnvAgreementValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envAgreementId !== undefined) {
            EnvAgreementIO
                .getById(BigInt(params.envAgreementId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvAgreementValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserAgreementList}>사용자 이용 약관 동의 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvAgreementIO}
                description={EnvAgreementDescription}
                properties={props => <PropertyBody {...props} />}
                actionChildren={<>
                    <Button
                        as={'a'}
                        href={`${Environments.baseUrl}/env/agreements/${props.item.id}.html`}
                        target={'_blank'}
                        icon={<WindowNew24Regular/>}>
                        열기
                    </Button>
                    <Button onClick={() => document.location = `/env/agreements/${props.item.id}/copy`}>복사</Button>
                </>}
                hideEdit
                hideDelete />
            <UserAgreementListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvAgreementDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "type":
        case "body":
        case "userAgreementCount":
        case "createdAt":
            return <Body1>{EnvAgreementDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a
                href={`/users/${props.item.creatorId}`}><Body1>{EnvAgreementDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function UserAgreementListPanel(props: PanelProps) {
    const modifications = useStatePair<UserAgreementValueObject[]>([])
    const modificationSearchOption: UserAgreementSearchOption = { envAgreementIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserAgreementList && <>
            <ListPageWrapper
                {...props}
                client={UserAgreementIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UserAgreementDescription}
                descriptor={UserAgreementDescriptor} />
        </>}
    </>
}