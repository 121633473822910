import {Body1, Spinner} from "@fluentui/react-components";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvStorageKeyValueObject} from "../../../data/value-object/env/EnvStorageKeyValueObject";
import {useEffect} from "react";
import {EnvStorageKeyIO} from "../../../io/EnvIO";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";
import {EnvStorageKeyDomain} from "../../../data/domain/env/EnvStorageKeyDomain";
import {EnvStorageKeyDescriptor} from "../../../data/descriptor/env/EnvStorageKeyDescriptor";
import {EnvStorageKeyDescription} from "../../../data/description/env/EnvStorageKeyDescription";

export default function Detail(props: RouterProps) {
    const params = useParams<{ envStorageKeyId: string }>()
    const item = useStatePair<EnvStorageKeyValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envStorageKeyId !== undefined) {
            EnvStorageKeyIO
                .getById(BigInt(params.envStorageKeyId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvStorageKeyValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={EnvStorageKeyIO}
                description={EnvStorageKeyDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvStorageKeyDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "accessKeyId":
        case "secretAccessKey":
        case "bucket":
        case "region":
        case "distribution":
        case "createdAt":
            return <Body1>{EnvStorageKeyDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{EnvStorageKeyDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}