import {EnvPolicyDomain} from "../../domain/env/EnvPolicyDomain"

/**
 * 환경 정책 속성 설명
 */
export const EnvPolicyDescription: Record<keyof EnvPolicyDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    envAdminPageSize: 'Admin 페이지 크기',
    envAgreementPageSize: '이용 약관 페이지 크기',
    envBankPageSize: '은행 페이지 크기',
    envClientPageSize: '클라이언트 페이지 크기',
    envConstructionPageSize: '작업 페이지 크기',
    envCountryPageSize: '국가 페이지 크기',
    envCountryRankDuration: '국가 랭크 집계 기간',
    envEventPageSize: '이벤트 페이지 크기',
    envFaqPageSize: '자주 묻는 질문 페이지 크기',
    envFaqItemPageSize: '자주 묻는 질문 항목 페이지 크기',
    envNoticePageSize: '공지사항 페이지 크기',
    envPhoneBlockPageSize: '전화번호 차단 페이지 크기',
    envPolicyPageSize: '환경 정책 페이지 크기',
    envStorageKeyPageSize: 'AWS S3 키 페이지 크기',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
