import {UiHomeContentUserProductionModificationDomain} from "../../domain/ui/UiHomeContentUserProductionModificationDomain"
import {UiHomeContentUserProductionId} from "../../id/ui/UiHomeContentUserProductionId"
import {UiHomeContentUserProductionModificationId} from "../../id/ui/UiHomeContentUserProductionModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 홈 와글와글 콘텐츠 수정 이력
 */
export class UiHomeContentUserProductionModificationValueObject implements ValueObject, UiHomeContentUserProductionModificationDomain {
    /**
     * 기본키
     */
    id: UiHomeContentUserProductionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 홈 와글와글 콘텐츠 ID
     */
    uiHomeContentUserProductionId: UiHomeContentUserProductionId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UiHomeContentUserProductionModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiHomeContentUserProductionId = BigInt(o.uiHomeContentUserProductionId)
        this.displayOrder = Number(o.displayOrder)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
    }
}
