import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {EnvFaqIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField, PostNumberField
} from "../../PostField";
import {EnvFaqDescription} from "../../../data/description/env/EnvFaqDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const title = useStatePair('')
    const displayOrder = useStatePair(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvFaqIO
            .post({
                title: title.value,
                displayOrder: displayOrder.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [title.value, displayOrder.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvFaqDescription.title}>
                    <PostInputField
                        {...props}
                        value={title.value}
                        setter={title.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvFaqDescription.displayOrder}>
                    <PostNumberField
                        {...props}
                        value={displayOrder.value}
                        setter={displayOrder.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/faqs'}/>
    </>
}