import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import UsageRouter from "./usage/UsageRouter";
import {Button, mergeClasses} from "@fluentui/react-components";
import {ChevronRight24Filled} from "@fluentui/react-icons";
import WithdrawalOptionRouter from "./withdrawalOption/WithdrawalOptionRouter";
import WithdrawalRouter from "./withdrawal/WithdrawalRouter";

export default function SolarRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/usages/*'} element={<UsageRouter {...props} />} />
            <Route path={'/withdrawals/*'} element={<WithdrawalRouter {...props} />} />
            <Route path={'/withdrawalOptions/*'} element={<WithdrawalOptionRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/solars/usages'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                솔라 사용
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/solars/withdrawalOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                솔라 인출 옵션
            </Button>
        </div>
    </>
}