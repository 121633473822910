import {ContentUserProductionScriptDomain} from "../../domain/content/ContentUserProductionScriptDomain"

export const ContentUserProductionScriptDescriptor: Record<keyof ContentUserProductionScriptDomain, (o: ContentUserProductionScriptDomain) => string> = {
    id(o: ContentUserProductionScriptDomain): string {
        return o.id.toString()
    },
    userId(o: ContentUserProductionScriptDomain): string {
        return o.userId.toString()
    },
    audioId(o: ContentUserProductionScriptDomain): string {
        return o.audioId?.toString() ?? '-'
    },
    script(o: ContentUserProductionScriptDomain): string {
        return o.script
    },
    transcription(o: ContentUserProductionScriptDomain): string {
        return o.transcription
    },
    accuracy(o: ContentUserProductionScriptDomain): string {
        return o.accuracy.toString()
    },
    createdAt(o: ContentUserProductionScriptDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
