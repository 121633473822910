import {UserExpType} from "../../constant/user/UserExpType"
import {UserExpVerboseDomain} from "../../domain/user/UserExpVerboseDomain"
import {UserExpId} from "../../id/user/UserExpId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 경험치 상세
 */
export class UserExpVerboseValueObject implements VerboseValueObject, UserExpVerboseDomain {
    /**
     * 기본키
     */
    id: UserExpId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성자 ID
     */
    creatorId?: UserId
    /**
     * 지급 유형
     */
    type: UserExpType
    /**
     * 변화량
     */
    amount: number
    /**
     * 직전 누계
     */
    lastAccumulation: number
    /**
     * 일간 누적
     */
    inDay: number
    /**
     * 주간 누적
     */
    inWeek: number
    /**
     * 월간 누적
     */
    inMonth: number
    /**
     * 분기간 누적
     */
    inQuarter: number
    /**
     * 반기간 누적
     */
    inHalf: number
    /**
     * 연간 누적
     */
    inYear: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 생성자 ID
     */
    creator?: UserValueObject

    constructor(o: Record<keyof UserExpVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        if (o.creatorId !== undefined && o.creatorId !== null) {
            this.creatorId = BigInt(o.creatorId)
        }
        this.type = o.type as UserExpType
        this.amount = Number(o.amount)
        this.lastAccumulation = Number(o.lastAccumulation)
        this.inDay = Number(o.inDay)
        this.inWeek = Number(o.inWeek)
        this.inMonth = Number(o.inMonth)
        this.inQuarter = Number(o.inQuarter)
        this.inHalf = Number(o.inHalf)
        this.inYear = Number(o.inYear)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        if (o.creator !== undefined && o.creator !== null) {
            this.creator = new UserValueObject(o.creator)
        }
    }
}
