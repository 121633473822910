import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {AssetCouponUsageValueObject} from "../../../../data/value-object/asset/AssetCouponUsageValueObject";
import {AssetCouponUsageSearchOption} from "../../../../data/search-option/asset/AssetCouponUsageSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {AssetCouponUsageIO} from "../../../../io/AssetIO";
import {AssetCouponUsageDescription} from "../../../../data/description/asset/AssetCouponUsageDescription";
import {AssetCouponUsageDescriptor} from "../../../../data/descriptor/asset/AssetCouponUsageDescriptor";
import ListOption from "../../../ListOption";
import {
    AssetCouponUsageSearchOptionDescription
} from "../../../../data/description/asset/AssetCouponUsageSearchOptionDescription";
import {
    applyAssetCouponUsageSearchOption,
    AssetCouponUsageSearchOptionField
} from "../../../search-option-field/asset/AssetCouponUsageSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetCouponUsageValueObject[]>([])
    const option = useStatePair<AssetCouponUsageSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetCouponUsageIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetCouponUsageDescription}
                descriptor={AssetCouponUsageDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/coupons/usages/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetCouponUsageSearchOptionDescription}
                fields={AssetCouponUsageSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetCouponUsageSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetCouponUsageDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}