export enum ContentDifficulty {
    /**
     * 매우 쉬움
     */
    VeryEasy = 'VeryEasy',

    /**
     * 쉬움
     */
    Easy = 'Easy',

    /**
     * 보통
     */
    Normal = 'Normal',

    /**
     * 어려움
     */
    Hard = 'Hard',

    /**
     * 매우 어려움
     */
    Expert = 'Expert'
}

export namespace ContentDifficulty {
    export function description(o: ContentDifficulty) {
        switch (o) {
            case ContentDifficulty.VeryEasy: return '매우 쉬움'
            case ContentDifficulty.Easy: return '쉬움'
            case ContentDifficulty.Normal: return '보통'
            case ContentDifficulty.Hard: return '어려움'
            case ContentDifficulty.Expert: return '매우 어려움'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: ContentDifficulty.VeryEasy, label: description(ContentDifficulty.VeryEasy) },
        { value: ContentDifficulty.Easy, label: description(ContentDifficulty.Easy) },
        { value: ContentDifficulty.Normal, label: description(ContentDifficulty.Normal) },
        { value: ContentDifficulty.Hard, label: description(ContentDifficulty.Hard) },
        { value: ContentDifficulty.Expert, label: description(ContentDifficulty.Expert) }
    ]
}
