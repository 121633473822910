import {RouterProps} from "../RouterProps";
import {useStatePair} from "react-type-extension";
import {SearchIndex} from "../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../ListPageWrapper";
import {ContentIO} from "../../io/ContentIO";
import ListOption from "../ListOption";
import ListOrder from "../ListOrder";
import {ContentValueObject} from "../../data/value-object/content/ContentValueObject";
import {ContentSearchOption} from "../../data/search-option/content/ContentSearchOption";
import {ContentDescription} from "../../data/description/content/ContentDescription";
import {ContentDescriptor} from "../../data/descriptor/content/ContentDescriptor";
import {ContentSearchOptionDescription} from "../../data/description/content/ContentSearchOptionDescription";
import {
    applyContentSearchOption,
    ContentSearchOptionField
} from "../search-option-field/content/ContentSearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentValueObject[]>([])
    const option = useStatePair<ContentSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentDescription}
                descriptor={ContentDescriptor}
                showPost
                onPostClick={() => document.location = '/contents/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => document.location = `/contents/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentSearchOptionDescription}
                fields={ContentSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}