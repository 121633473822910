import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UiHomeNoticeValueObject} from "../../../../data/value-object/ui/UiHomeNoticeValueObject";
import {useEffect} from "react";
import {UiHomeNoticeIO, UiHomeNoticeModificationIO} from "../../../../io/UiIO";
import {StorageKey} from "../../../../io/StorageIO";
import {Body1, Caption1Strong, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../../DetailViews";
import {UiHomeNoticeDescription} from "../../../../data/description/ui/UiHomeNoticeDescription";
import {UiHomeNoticeDomain} from "../../../../data/domain/ui/UiHomeNoticeDomain";
import {UiHomeNoticeDescriptor} from "../../../../data/descriptor/ui/UiHomeNoticeDescriptor";
import {
    UiHomeNoticeModificationValueObject
} from "../../../../data/value-object/ui/UiHomeNoticeModificationValueObject";
import {
    UiHomeNoticeModificationSearchOption
} from "../../../../data/search-option/ui/UiHomeNoticeModificationSearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {UiHomeNoticeModificationDescription} from "../../../../data/description/ui/UiHomeNoticeModificationDescription";
import {UiHomeNoticeModificationDescriptor} from "../../../../data/descriptor/ui/UiHomeNoticeModificationDescriptor";
import {Media, MediaFile} from "../../../Media";

enum Panel {
    Default,
    UiHomeNoticeModificationList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ uiHomeNoticeId: string }>()
    const item = useStatePair<UiHomeNoticeValueObject | undefined>(undefined)
    const image = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (params.uiHomeNoticeId !== undefined) {
            const id = BigInt(params.uiHomeNoticeId)
            UiHomeNoticeIO
                .getById(id)
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
            MediaFile
                .createFromStorageKey(StorageKey.Ui.Home.notice(id))
                .then(image.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('이미지를 찾을 수 없습니다. 이미지를 먼저 수정해주세요.')
                    document.location = document.location.pathname + '/edit'
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && image.value !== undefined && <Page {...props} item={item.value} image={image.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UiHomeNoticeValueObject
    image: MediaFile
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UiHomeNoticeModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UiHomeNoticeIO}
                description={UiHomeNoticeDescription}
                properties={props => <PropertyBody {...props} />}
                panelChildren={<>
                    <Caption1Strong>이미지</Caption1Strong>
                    <Media
                        {...props}
                        source={props.image} />
                </>}
                storageKeys={() => StorageKey.Ui.Home.notice(props.item.id)} />
            <UiHomeNoticeModificationListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = RouterProps & {
    item: UiHomeNoticeDomain
    itemKey: keyof UiHomeNoticeDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "displayOrder":
        case "url":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{UiHomeNoticeDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UiHomeNoticeDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function UiHomeNoticeModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<UiHomeNoticeModificationValueObject[]>([])
    const modificationSearchOption: UiHomeNoticeModificationSearchOption = { uiHomeNoticeIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.UiHomeNoticeModificationList && <>
            <ListPageWrapper
                {...props}
                client={UiHomeNoticeModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UiHomeNoticeModificationDescription}
                descriptor={UiHomeNoticeModificationDescriptor} />
        </>}
    </>
}