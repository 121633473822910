import {AssetLunarPurchaseOptionDomain} from "../../domain/asset/AssetLunarPurchaseOptionDomain"

/**
 * 루나 구매 옵션 속성 설명
 */
export const AssetLunarPurchaseOptionDescription: Record<keyof AssetLunarPurchaseOptionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    lunarAmount: '지급 루나',
    cashAmount: '지불 금액',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
