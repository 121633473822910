import {useStatePair} from "react-type-extension";
import {RouterProps} from "../../RouterProps";
import {useCallback} from "react";
import {UserLevelIO} from "../../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField,
    PostNumberField
} from "../../PostField";
import {UserLevelDescription} from "../../../data/description/user/UserLevelDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const name = useStatePair('')
    const exp = useStatePair(0)
    const rewardAssetLunarAmount = useStatePair(0)
    const rewardAssetSolarAmount = useStatePair(0)
    const rewardAssetStarAmount = useStatePair(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        UserLevelIO
            .post({
                name: name.value,
                exp: exp.value,
                rewardAssetLunarAmount: rewardAssetLunarAmount.value,
                rewardAssetSolarAmount: rewardAssetSolarAmount.value,
                rewardAssetStarAmount: rewardAssetStarAmount.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [name.value, exp.value, rewardAssetLunarAmount.value, rewardAssetSolarAmount.value, rewardAssetStarAmount.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.name}>
                    <PostInputField
                        {...props}
                        value={name.value}
                        setter={name.setter}
                        maxLength={20} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.exp}>
                    <PostNumberField
                        {...props}
                        value={exp.value}
                        setter={exp.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.rewardAssetLunarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetLunarAmount.value}
                        setter={rewardAssetLunarAmount.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.rewardAssetSolarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetSolarAmount.value}
                        setter={rewardAssetSolarAmount.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.rewardAssetStarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetStarAmount.value}
                        setter={rewardAssetStarAmount.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/users/levels'}/>
    </>
}