import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {ContentTargetValueObject} from "../../../data/value-object/content/ContentTargetValueObject";
import {useEffect} from "react";
import {
    ContentReportIO,
    ContentReportReasonIO,
    ContentTargetIO,
    ContentTargetModificationIO
} from "../../../io/ContentIO";
import {Body1, Button, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {DeleteDialog, DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {ContentTargetDomain} from "../../../data/domain/content/ContentTargetDomain";
import {ContentTargetDescriptor} from "../../../data/descriptor/content/ContentTargetDescriptor";
import {mapByKey} from "../../../util/ObjectUtil";
import {ContentTargetDescription} from "../../../data/description/content/ContentTargetDescription";
import {
    ContentTargetModificationValueObject
} from "../../../data/value-object/content/ContentTargetModificationValueObject";
import {
    ContentTargetModificationSearchOption
} from "../../../data/search-option/content/ContentTargetModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {
    ContentTargetModificationDescription
} from "../../../data/description/content/ContentTargetModificationDescription";
import {
    ContentTargetModificationDescriptor
} from "../../../data/descriptor/content/ContentTargetModificationDescriptor";
import {ContentReportReasonDescription} from "../../../data/description/content/ContentReportReasonDescription";

enum Panel {
    Default,
    ContentTargetModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentTargetId: string }>()
    const item = useStatePair<ContentTargetValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentTargetId !== undefined) {
            ContentTargetIO
                .getById(BigInt(params.contentTargetId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentTargetValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    const onDeleteClick = () => {
        isDeleting.setTrue()
        ContentTargetIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/contents/reports/reasons'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.ContentTargetModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                client={ContentTargetIO}
                description={ContentTargetDescription}
                properties={props => <PropertyBody {...props} />}/>
            <ContentTargetModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentTargetDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "userGender":
        case "userBirthYearMin":
        case "userBirthYearMax":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{ContentTargetDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a
                href={`/users/${props.item.creatorId}`}><Body1>{ContentTargetDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentId":
            return <a
                href={`/contents/${props.item.contentId}`}><Body1>{ContentTargetDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envCountryId":
            return (props.item.envCountryId !== undefined)
                ? <a href={`/env/countries/${props.item.envCountryId}`}><Body1>{ContentTargetDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(ContentTargetDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{ContentTargetDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}

function ContentTargetModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentTargetModificationValueObject[]>([])
    const modificationSearchOption: ContentTargetModificationSearchOption = { contentTargetIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentTargetModificationList && <>
            <ListPageWrapper
                {...props}
                client={ContentTargetModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentTargetModificationDescription}
                descriptor={ContentTargetModificationDescriptor}
                showNewTab={false}
                onItemClick={item => `/contents/reports/reasons/${item.id}`} />
        </>}
    </>
}