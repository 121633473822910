import {ContentLikeDomain} from "../../domain/content/ContentLikeDomain"

/**
 * 콘텐츠 좋아요 속성 설명
 */
export const ContentLikeDescription: Record<keyof ContentLikeDomain, string> = {
    id: '기본키',
    contentId: '콘텐츠 ID',
    userId: '사용자 ID',
    createdAt: '생성일'
}
