import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserAgreementValueObject} from "../../../data/value-object/user/UserAgreementValueObject";
import {useEffect} from "react";
import {UserAgreementIO} from "../../../io/UserIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {UserAgreementDomain} from "../../../data/domain/user/UserAgreementDomain";
import {UserAgreementDescriptor} from "../../../data/descriptor/user/UserAgreementDescriptor";
import {UserAgreementDescription} from "../../../data/description/user/UserAgreementDescription";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ userAgreementId: string }>()
    const item = useStatePair<UserAgreementValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userAgreementId !== undefined) {
            UserAgreementIO
                .getById(BigInt(params.userAgreementId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserAgreementValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={UserAgreementIO}
                description={UserAgreementDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserAgreementDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{UserAgreementDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{UserAgreementDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envAgreementId":
            return <a href={`/env/agreements/${props.item.envAgreementId}`}><Body1>{UserAgreementDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}