import {ContentUserProductionDurationModificationDomain} from "../../domain/content/ContentUserProductionDurationModificationDomain"
import {ContentUserProductionDurationId} from "../../id/content/ContentUserProductionDurationId"
import {ContentUserProductionDurationModificationId} from "../../id/content/ContentUserProductionDurationModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 와글와글 기간 프리셋 수정 이력
 */
export class ContentUserProductionDurationModificationValueObject implements ValueObject, ContentUserProductionDurationModificationDomain {
    /**
     * 기본키
     */
    id: ContentUserProductionDurationModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 와글와글 기간 프리셋 ID
     */
    contentUserProductionDurationId: ContentUserProductionDurationId
    /**
     * 기간(주)
     */
    value: number
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentUserProductionDurationModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentUserProductionDurationId = BigInt(o.contentUserProductionDurationId)
        this.value = Number(o.value)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
