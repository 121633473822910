import {UserGender} from "../../constant/user/UserGender"
import {ContentTargetModificationDomain} from "../../domain/content/ContentTargetModificationDomain"
import {ContentTargetId} from "../../id/content/ContentTargetId"
import {ContentTargetModificationId} from "../../id/content/ContentTargetModificationId"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 노출 대상 수정 이력
 */
export class ContentTargetModificationValueObject implements ValueObject, ContentTargetModificationDomain {
    /**
     * 기본키
     */
    id: ContentTargetModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 콘텐츠 노출 대상 ID
     */
    contentTargetId: ContentTargetId
    /**
     * 국가 ID
     */
    envCountryId?: EnvCountryId
    /**
     * 성별
     */
    userGender?: UserGender
    /**
     * 출생년도 최소
     */
    userBirthYearMin?: number
    /**
     * 출생년도 최대
     */
    userBirthYearMax?: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentTargetModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentTargetId = BigInt(o.contentTargetId)
        if (o.envCountryId !== undefined && o.envCountryId !== null) {
            this.envCountryId = BigInt(o.envCountryId)
        }
        if (o.userGender !== undefined && o.userGender !== null) {
            this.userGender = o.userGender as UserGender
        }
        if (o.userBirthYearMin !== undefined && o.userBirthYearMin !== null) {
            this.userBirthYearMin = Number(o.userBirthYearMin)
        }
        if (o.userBirthYearMax !== undefined && o.userBirthYearMax !== null) {
            this.userBirthYearMax = Number(o.userBirthYearMax)
        }
        this.createdAt = new Date(o.createdAt)
    }
}
