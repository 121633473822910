import {UserDomain} from "../../domain/user/UserDomain"

/**
 * 사용자 속성 설명
 */
export const UserDescription: Record<keyof UserDomain, string> = {
    id: '기본키',
    authenticationUid: 'Firebase Authentication UID',
    role: '역할',
    adminPermission: '관리자 권한',
    email: '이메일',
    cloudMessagingToken: 'Firebase Cloud Messaging Token',
    nickname: '닉네임',
    birthCountryId: '출신 국가 ID',
    residenceCountryId: '거주 국가 ID',
    birth: '생년월일',
    gender: '성별',
    phoneCountryId: '전화번호 국가 ID',
    phone: '전화번호',
    recommenderId: '추천인 ID',
    recommendationCode: '추천 코드',
    statusFlags: '상태 플래그',
    recommendeeCount: '추천한 사용자 수',
    contentDailyParticipationCount: '오늘의 단어 참여 수',
    contentDailyParticipationPassCount: '오늘의 단어 합격 수',
    contentRepeatLikeCount: '따라 말하기 좋아요 수',
    contentRepeatParticipationCount: '따라 말하기 참여 수',
    contentRepeatParticipationPassCount: '따라 말하기 합격 수',
    contentRepeatParticipationHighAccuracyCount: '따라 말하기 높은 일치율 수',
    contentRepeatCommentCount: '따라 말하기 댓글 수',
    contentRepeatCommentLikeCount: '따라 말하기 댓글 좋아요 수',
    contentRepeatCommentReportCount: '따라 말하기 댓글 신고 수',
    contentRepeatCommentReportedCount: '따라 말하기 댓글 피신고 수',
    contentM10nLikeCount: '암기하기 좋아요 수',
    contentM10nParticipationCount: '암기하기 참여 수',
    contentM10nParticipationPassCount: '암기하기 합격 수',
    contentM10nParticipationHighAccuracyCount: '암기하기 높은 일치율 수',
    contentM10nCommentCount: '암기하기 댓글 수',
    contentM10nCommentLikeCount: '암기하기 댓글 좋아요 수',
    contentM10nCommentReportCount: '암기하기 댓글 신고 수',
    contentM10nCommentReportedCount: '암기하기 댓글 피신고 수',
    contentUserProductionCount: '와글와글 수',
    contentUserProductionLikeCount: '와글와글 좋아요 수',
    contentUserProductionParticipationCount: '와글와글 참여 수',
    contentUserProductionParticipationPassCount: '와글와글 합격 수',
    contentUserProductionParticipationHighAccuracyCount: '와글와글 높은 일치율 수',
    contentUserProductionReportCount: '와글와글 신고 수',
    contentUserProductionReportedCount: '와글와글 피신고 수',
    contentUserProductionCommentCount: '와글와글 댓글 수',
    contentUserProductionCommentLikeCount: '와글와글 댓글 좋아요 수',
    contentUserProductionCommentReportCount: '와글와글 댓글 신고 수',
    contentUserProductionCommentReportedCount: '와글와글 댓글 피신고 수',
    contentUserProductionReviewPositiveCount: '와글와글 긍정 평가 수',
    contentUserProductionReviewNeutralCount: '와글와글 중립 평가 수',
    contentUserProductionReviewNegativeCount: '와글와글 부정 평가 수',
    userBlockCount: '이용 정지 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
