import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    AssetCouponValueObject
} from "../../../data/value-object/asset/AssetCouponValueObject";
import {
    useAssetCouponPutState
} from "../../../data/data-transfer-object-state/asset/AssetCouponPutState";
import React, {useCallback, useEffect} from "react";
import {AssetCouponIO} from "../../../io/AssetIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    AssetCouponPutFields
} from "../../data-transfer-object-field/asset/AssetCouponPutField";

enum Phase {
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ assetCouponId: string }>()
    const item = useStatePair<AssetCouponValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useAssetCouponPutState()

    useEffect(() => {
        if (params.assetCouponId !== undefined) {
            AssetCouponIO
                .getVerboseById(BigInt(params.assetCouponId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        AssetCouponIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/assets/coupons/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <AssetCouponPutFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}