import {EnvPhoneBlockVerboseDomain} from "../../domain/env/EnvPhoneBlockVerboseDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {EnvPhoneBlockId} from "../../id/env/EnvPhoneBlockId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvCountryValueObject} from "./EnvCountryValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 전화번호 차단 상세
 */
export class EnvPhoneBlockVerboseValueObject implements VerboseValueObject, EnvPhoneBlockVerboseDomain {
    /**
     * 기본키
     */
    id: EnvPhoneBlockId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 국가 ID
     */
    envCountryId: EnvCountryId
    /**
     * 전화번호
     */
    phone: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject
    /**
     * 국가 ID
     */
    envCountry: EnvCountryValueObject

    constructor(o: Record<keyof EnvPhoneBlockVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envCountryId = BigInt(o.envCountryId)
        this.phone = String(o.phone)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
        this.envCountry = new EnvCountryValueObject(o.envCountry)
    }
}
