import {BaseHttpClient} from "./HttpClient";
import {UiHomeContentSuggestionId} from "../data/id/ui/UiHomeContentSuggestionId";
import {
    UiHomeContentSuggestionPostDataTransferObject
} from "../data/data-transfer-object/ui/UiHomeContentSuggestionPostDataTransferObject";
import {
    UiHomeContentSuggestionPutDataTransferObject
} from "../data/data-transfer-object/ui/UiHomeContentSuggestionPutDataTransferObject";
import {UiHomeContentSuggestionValueObject} from "../data/value-object/ui/UiHomeContentSuggestionValueObject";
import {
    UiHomeContentSuggestionVerboseValueObject
} from "../data/value-object/ui/UiHomeContentSuggestionVerboseValueObject";
import {UiHomeContentSuggestionSearchOption} from "../data/search-option/ui/UiHomeContentSuggestionSearchOption";
import {UiHomeContentSuggestionModificationId} from "../data/id/ui/UiHomeContentSuggestionModificationId";
import {
    UiHomeContentSuggestionModificationValueObject
} from "../data/value-object/ui/UiHomeContentSuggestionModificationValueObject";
import {
    UiHomeContentSuggestionModificationVerboseValueObject
} from "../data/value-object/ui/UiHomeContentSuggestionModificationVerboseValueObject";
import {
    UiHomeContentSuggestionModificationSearchOption
} from "../data/search-option/ui/UiHomeContentSuggestionModificationSearchOption";
import {UiHomeContentUserProductionId} from "../data/id/ui/UiHomeContentUserProductionId";
import {
    UiHomeContentUserProductionPostDataTransferObject
} from "../data/data-transfer-object/ui/UiHomeContentUserProductionPostDataTransferObject";
import {
    UiHomeContentUserProductionPutDataTransferObject
} from "../data/data-transfer-object/ui/UiHomeContentUserProductionPutDataTransferObject";
import {UiHomeContentUserProductionValueObject} from "../data/value-object/ui/UiHomeContentUserProductionValueObject";
import {
    UiHomeContentUserProductionVerboseValueObject
} from "../data/value-object/ui/UiHomeContentUserProductionVerboseValueObject";
import {
    UiHomeContentUserProductionSearchOption
} from "../data/search-option/ui/UiHomeContentUserProductionSearchOption";
import {UiHomeContentUserProductionModificationId} from "../data/id/ui/UiHomeContentUserProductionModificationId";
import {
    UiHomeContentUserProductionModificationValueObject
} from "../data/value-object/ui/UiHomeContentUserProductionModificationValueObject";
import {
    UiHomeContentUserProductionModificationVerboseValueObject
} from "../data/value-object/ui/UiHomeContentUserProductionModificationVerboseValueObject";
import {
    UiHomeContentUserProductionModificationSearchOption
} from "../data/search-option/ui/UiHomeContentUserProductionModificationSearchOption";
import {UiHomeNoticeId} from "../data/id/ui/UiHomeNoticeId";
import {UiHomeNoticePostDataTransferObject} from "../data/data-transfer-object/ui/UiHomeNoticePostDataTransferObject";
import {UiHomeNoticePutDataTransferObject} from "../data/data-transfer-object/ui/UiHomeNoticePutDataTransferObject";
import {UiHomeNoticeValueObject} from "../data/value-object/ui/UiHomeNoticeValueObject";
import {UiHomeNoticeVerboseValueObject} from "../data/value-object/ui/UiHomeNoticeVerboseValueObject";
import {UiHomeNoticeSearchOption} from "../data/search-option/ui/UiHomeNoticeSearchOption";
import {UiHomeNoticeModificationId} from "../data/id/ui/UiHomeNoticeModificationId";
import {UiHomeNoticeModificationValueObject} from "../data/value-object/ui/UiHomeNoticeModificationValueObject";
import {
    UiHomeNoticeModificationVerboseValueObject
} from "../data/value-object/ui/UiHomeNoticeModificationVerboseValueObject";
import {UiHomeNoticeModificationSearchOption} from "../data/search-option/ui/UiHomeNoticeModificationSearchOption";
import {UiOnboardingId} from "../data/id/ui/UiOnboardingId";
import {UiOnboardingPostDataTransferObject} from "../data/data-transfer-object/ui/UiOnboardingPostDataTransferObject";
import {UiOnboardingPutDataTransferObject} from "../data/data-transfer-object/ui/UiOnboardingPutDataTransferObject";
import {UiOnboardingValueObject} from "../data/value-object/ui/UiOnboardingValueObject";
import {UiOnboardingVerboseValueObject} from "../data/value-object/ui/UiOnboardingVerboseValueObject";
import {UiOnboardingSearchOption} from "../data/search-option/ui/UiOnboardingSearchOption";
import {UiOnboardingModificationId} from "../data/id/ui/UiOnboardingModificationId";
import {UiOnboardingModificationValueObject} from "../data/value-object/ui/UiOnboardingModificationValueObject";
import {
    UiOnboardingModificationVerboseValueObject
} from "../data/value-object/ui/UiOnboardingModificationVerboseValueObject";
import {UiOnboardingModificationSearchOption} from "../data/search-option/ui/UiOnboardingModificationSearchOption";
import {UiPolicyId} from "../data/id/ui/UiPolicyId";
import {UiPolicyPostDataTransferObject} from "../data/data-transfer-object/ui/UiPolicyPostDataTransferObject";
import {UiPolicyPutDataTransferObject} from "../data/data-transfer-object/ui/UiPolicyPutDataTransferObject";
import {UiPolicyValueObject} from "../data/value-object/ui/UiPolicyValueObject";
import {UiPolicyVerboseValueObject} from "../data/value-object/ui/UiPolicyVerboseValueObject";
import {UiPolicySearchOption} from "../data/search-option/ui/UiPolicySearchOption";
import {UiPolicyModificationId} from "../data/id/ui/UiPolicyModificationId";
import {UiPolicyModificationValueObject} from "../data/value-object/ui/UiPolicyModificationValueObject";
import {UiPolicyModificationVerboseValueObject} from "../data/value-object/ui/UiPolicyModificationVerboseValueObject";
import {UiPolicyModificationSearchOption} from "../data/search-option/ui/UiPolicyModificationSearchOption";

export const UiHomeContentSuggestionIO = new BaseHttpClient<
    UiHomeContentSuggestionId,
    UiHomeContentSuggestionPostDataTransferObject,
    UiHomeContentSuggestionPutDataTransferObject,
    UiHomeContentSuggestionValueObject,
    UiHomeContentSuggestionVerboseValueObject,
    UiHomeContentSuggestionSearchOption
>(
    '/ui/home/contents/suggestions',
    o => new UiHomeContentSuggestionValueObject(o),
    o => new UiHomeContentSuggestionVerboseValueObject(o)
)

export const UiHomeContentSuggestionModificationIO = new BaseHttpClient<
    UiHomeContentSuggestionModificationId,
    never,
    never,
    UiHomeContentSuggestionModificationValueObject,
    UiHomeContentSuggestionModificationVerboseValueObject,
    UiHomeContentSuggestionModificationSearchOption
>(
    '/ui/home/contents/suggestions/modifications',
    o => new UiHomeContentSuggestionModificationValueObject(o),
    o => new UiHomeContentSuggestionModificationVerboseValueObject(o)
)

export const UiHomeContentUserProductionIO = new BaseHttpClient<
    UiHomeContentUserProductionId,
    UiHomeContentUserProductionPostDataTransferObject,
    UiHomeContentUserProductionPutDataTransferObject,
    UiHomeContentUserProductionValueObject,
    UiHomeContentUserProductionVerboseValueObject,
    UiHomeContentUserProductionSearchOption
>(
    '/ui/home/contents/userProductions',
    o => new UiHomeContentUserProductionValueObject(o),
    o => new UiHomeContentUserProductionVerboseValueObject(o)
)

export const UiHomeContentUserProductionModificationIO = new BaseHttpClient<
    UiHomeContentUserProductionModificationId,
    never,
    never,
    UiHomeContentUserProductionModificationValueObject,
    UiHomeContentUserProductionModificationVerboseValueObject,
    UiHomeContentUserProductionModificationSearchOption
>(
    '/ui/home/contents/userProductions/modifications',
    o => new UiHomeContentUserProductionModificationValueObject(o),
    o => new UiHomeContentUserProductionModificationVerboseValueObject(o)
)

export const UiHomeNoticeIO = new BaseHttpClient<
    UiHomeNoticeId,
    UiHomeNoticePostDataTransferObject,
    UiHomeNoticePutDataTransferObject,
    UiHomeNoticeValueObject,
    UiHomeNoticeVerboseValueObject,
    UiHomeNoticeSearchOption
>(
    '/ui/home/notices',
    o => new UiHomeNoticeValueObject(o),
    o => new UiHomeNoticeVerboseValueObject(o)
)

export const UiHomeNoticeModificationIO = new BaseHttpClient<
    UiHomeNoticeModificationId,
    never,
    never,
    UiHomeNoticeModificationValueObject,
    UiHomeNoticeModificationVerboseValueObject,
    UiHomeNoticeModificationSearchOption
>(
    '/ui/home/notices/modifications',
    o => new UiHomeNoticeModificationValueObject(o),
    o => new UiHomeNoticeModificationVerboseValueObject(o)
)

export const UiOnboardingIO = new BaseHttpClient<
    UiOnboardingId,
    UiOnboardingPostDataTransferObject,
    UiOnboardingPutDataTransferObject,
    UiOnboardingValueObject,
    UiOnboardingVerboseValueObject,
    UiOnboardingSearchOption
>(
    '/ui/onboardings',
    o => new UiOnboardingValueObject(o),
    o => new UiOnboardingVerboseValueObject(o)
)

export const UiOnboardingModificationIO = new BaseHttpClient<
    UiOnboardingModificationId,
    never,
    never,
    UiOnboardingModificationValueObject,
    UiOnboardingModificationVerboseValueObject,
    UiOnboardingModificationSearchOption
>(
    '/ui/onboardings/modifications',
    o => new UiOnboardingModificationValueObject(o),
    o => new UiOnboardingModificationVerboseValueObject(o)
)

export const UiPolicyIO = new BaseHttpClient<
    UiPolicyId,
    UiPolicyPostDataTransferObject,
    UiPolicyPutDataTransferObject,
    UiPolicyValueObject,
    UiPolicyVerboseValueObject,
    UiPolicySearchOption
>(
    '/ui/policies',
    o => new UiPolicyValueObject(o),
    o => new UiPolicyVerboseValueObject(o)
)

export const UiPolicyModificationIO = new BaseHttpClient<
    UiPolicyModificationId,
    never,
    never,
    UiPolicyModificationValueObject,
    UiPolicyModificationVerboseValueObject,
    UiPolicyModificationSearchOption
>(
    '/ui/policies/modifications',
    o => new UiPolicyModificationValueObject(o),
    o => new UiPolicyModificationVerboseValueObject(o)
)