import {AssetSolarUsagePostDataTransferObject} from "../../data-transfer-object/asset/AssetSolarUsagePostDataTransferObject"

/**
 * 솔라 사용 및 지급 이력 속성 설명
 */
export const AssetSolarUsagePostDataTransferObjectDescription: Record<keyof AssetSolarUsagePostDataTransferObject, string> = {
    userId: '사용자 ID',
    type: '유형',
    amount: '변화'
}
