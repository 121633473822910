import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvFaqValueObject} from "../../../data/value-object/env/EnvFaqValueObject";
import {JSX, useEffect} from "react";
import {EnvFaqIO, EnvFaqItemIO, EnvFaqModificationIO} from "../../../io/EnvIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {EnvFaqDomain} from "../../../data/domain/env/EnvFaqDomain";
import {EnvFaqDescriptor} from "../../../data/descriptor/env/EnvFaqDescriptor";
import {EnvFaqDescription} from "../../../data/description/env/EnvFaqDescription";
import {EnvFaqModificationValueObject} from "../../../data/value-object/env/EnvFaqModificationValueObject";
import {
    EnvFaqModificationSearchOption
} from "../../../data/search-option/env/EnvFaqModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvFaqModificationDescription} from "../../../data/description/env/EnvFaqModificationDescription";
import {EnvFaqModificationDescriptor} from "../../../data/descriptor/env/EnvFaqModificationDescriptor";
import {EnvFaqItemValueObject} from "../../../data/value-object/env/EnvFaqItemValueObject";
import {EnvFaqItemSearchOption} from "../../../data/search-option/env/EnvFaqItemSearchOption";
import {EnvFaqItemDescription} from "../../../data/description/env/EnvFaqItemDescription";
import {EnvFaqItemDescriptor} from "../../../data/descriptor/env/EnvFaqItemDescriptor";

enum Panel {
    Default,
    EnvFaqModificationList,
    EnvFaqItemList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envFaqId: string }>()
    const item = useStatePair<EnvFaqValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envFaqId !== undefined) {
            EnvFaqIO
                .getById(BigInt(params.envFaqId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvFaqValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvFaqModificationList}>수정 이력</Tab>
                <Tab value={Panel.EnvFaqItemList}>세부 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvFaqIO}
                description={EnvFaqDescription}
                properties={props => <PropertyBody {...props} />}/>
            <EnvFaqModificationListPanel
                {...props}
                panel={panel.value}/>
            <EnvFaqItemListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvFaqDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "title":
        case "displayOrder":
        case "envFaqItemCount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{EnvFaqDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{EnvFaqDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function EnvFaqModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvFaqModificationValueObject[]>([])
    const option: EnvFaqModificationSearchOption = { envFaqIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvFaqModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvFaqModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvFaqModificationDescription}
                descriptor={EnvFaqModificationDescriptor} />
        </>}
    </>
}

function EnvFaqItemListPanel(props: PanelProps) {
    const items = useStatePair<EnvFaqItemValueObject[]>([])
    const option: EnvFaqItemSearchOption = { envFaqIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvFaqItemList && <>
            <ListPageWrapper
                {...props}
                client={EnvFaqItemIO}
                items={items}
                option={option}
                index={index}
                showPost
                onPostClick={() => document.location = `/env/faqs/${props.item.id}/items/post`}
                description={EnvFaqItemDescription}
                descriptor={EnvFaqItemDescriptor}
                onItemClick={item => `/env/faqs/items/${item.id}`} />
        </>}
    </>
}