import {ContentQueryPopularDomain} from "../../domain/content/ContentQueryPopularDomain"

/**
 * 콘텐츠 인기 검색어 속성 설명
 */
export const ContentQueryPopularDescription: Record<keyof ContentQueryPopularDomain, string> = {
    id: '기본키',
    contentType: '콘텐츠 유형',
    displayOrder: '순위',
    displayOrderDiff: '순위 변동',
    body: '질의어',
    createdAt: '생성일'
}
