export enum UserRankDuration {
    /**
     * 일간
     */
    Day = 'Day',

    /**
     * 주간
     */
    Week = 'Week',

    /**
     * 월간
     */
    Month = 'Month',

    /**
     * 분기간
     */
    Quarter = 'Quarter',

    /**
     * 반기간
     */
    Half = 'Half',

    /**
     * 연간
     */
    Year = 'Year'
}

export namespace UserRankDuration {
    export function description(o: UserRankDuration) {
        switch (o) {
            case UserRankDuration.Day: return '일간'
            case UserRankDuration.Week: return '주간'
            case UserRankDuration.Month: return '월간'
            case UserRankDuration.Quarter: return '분기간'
            case UserRankDuration.Half: return '반기간'
            case UserRankDuration.Year: return '연간'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: UserRankDuration.Day, label: description(UserRankDuration.Day) },
        { value: UserRankDuration.Week, label: description(UserRankDuration.Week) },
        { value: UserRankDuration.Month, label: description(UserRankDuration.Month) },
        { value: UserRankDuration.Quarter, label: description(UserRankDuration.Quarter) },
        { value: UserRankDuration.Half, label: description(UserRankDuration.Half) },
        { value: UserRankDuration.Year, label: description(UserRankDuration.Year) }
    ]
}
