import {UserInquiryVerboseDomain} from "../../domain/user/UserInquiryVerboseDomain"
import {EnvClientId} from "../../id/env/EnvClientId"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {UserId} from "../../id/user/UserId"
import {UserInquiryId} from "../../id/user/UserInquiryId"
import {EnvClientValueObject} from "../env/EnvClientValueObject"
import {EnvCountryValueObject} from "../env/EnvCountryValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 문의 상세
 */
export class UserInquiryVerboseValueObject implements VerboseValueObject, UserInquiryVerboseDomain {
    /**
     * 기본키
     */
    id: UserInquiryId
    /**
     * 사용자 ID
     */
    userId?: UserId
    /**
     * 클라이언트 ID
     */
    envClientId: EnvClientId
    /**
     * 국가 ID
     */
    envCountryId: EnvCountryId
    /**
     * 회신 이메일
     */
    email: string
    /**
     * 요청자 주소
     */
    remoteAddress: string
    /**
     * 장치 이름
     */
    deviceName: string
    /**
     * 장치 버전
     */
    deviceVersion: string
    /**
     * 내용
     */
    body: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user?: UserValueObject
    /**
     * 클라이언트 ID
     */
    envClient: EnvClientValueObject
    /**
     * 국가 ID
     */
    envCountry: EnvCountryValueObject

    constructor(o: Record<keyof UserInquiryVerboseDomain, any>) {
        this.id = BigInt(o.id)
        if (o.userId !== undefined && o.userId !== null) {
            this.userId = BigInt(o.userId)
        }
        this.envClientId = BigInt(o.envClientId)
        this.envCountryId = BigInt(o.envCountryId)
        this.email = String(o.email)
        this.remoteAddress = String(o.remoteAddress)
        this.deviceName = String(o.deviceName)
        this.deviceVersion = String(o.deviceVersion)
        this.body = String(o.body)
        this.createdAt = new Date(o.createdAt)
        if (o.user !== undefined && o.user !== null) {
            this.user = new UserValueObject(o.user)
        }
        this.envClient = new EnvClientValueObject(o.envClient)
        this.envCountry = new EnvCountryValueObject(o.envCountry)
    }
}
