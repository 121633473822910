import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvEventValueObject} from "../../../data/value-object/env/EnvEventValueObject";
import {JSX, useEffect} from "react";
import {EnvEventIO, EnvEventModificationIO} from "../../../io/EnvIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {EnvEventDomain} from "../../../data/domain/env/EnvEventDomain";
import {EnvEventDescriptor} from "../../../data/descriptor/env/EnvEventDescriptor";
import {EnvEventDescription} from "../../../data/description/env/EnvEventDescription";
import {EnvEventModificationValueObject} from "../../../data/value-object/env/EnvEventModificationValueObject";
import {
    EnvEventModificationSearchOption
} from "../../../data/search-option/env/EnvEventModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvEventModificationDescription} from "../../../data/description/env/EnvEventModificationDescription";
import {EnvEventModificationDescriptor} from "../../../data/descriptor/env/EnvEventModificationDescriptor";

enum Panel {
    Default,
    EnvEventModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envEventId: string }>()
    const item = useStatePair<EnvEventValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envEventId !== undefined) {
            EnvEventIO
                .getById(BigInt(params.envEventId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvEventValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvEventModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvEventIO}
                description={EnvEventDescription}
                properties={props => <PropertyBody {...props} />}/>
            <EnvEventModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvEventDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "title":
        case "url":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{EnvEventDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{EnvEventDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function EnvEventModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvEventModificationValueObject[]>([])
    const option: EnvEventModificationSearchOption = { envEventIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvEventModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvEventModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvEventModificationDescription}
                descriptor={EnvEventModificationDescriptor} />
        </>}
    </>
}