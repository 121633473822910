import {UserAgreementDomain} from "../../domain/user/UserAgreementDomain"

/**
 * 사용자 이용 약관 동의 속성 설명
 */
export const UserAgreementDescription: Record<keyof UserAgreementDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    envAgreementId: '이용 약관 ID',
    createdAt: '생성일'
}
