import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {AssetLunarExchangeOptionIO, AssetLunarPurchaseOptionIO} from "../../../../io/AssetIO";
import {
    AssetLunarPurchaseOptionDescription
} from "../../../../data/description/asset/AssetLunarPurchaseOptionDescription";
import {AssetLunarPurchaseOptionDomain} from "../../../../data/domain/asset/AssetLunarPurchaseOptionDomain";
import {AssetLunarPurchaseOptionDescriptor} from "../../../../data/descriptor/asset/AssetLunarPurchaseOptionDescriptor";
import {
    AssetLunarPurchaseOptionValueObject
} from "../../../../data/value-object/asset/AssetLunarPurchaseOptionValueObject";
import {
    AssetLunarExchangeOptionDescription
} from "../../../../data/description/asset/AssetLunarExchangeOptionDescription";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetLunarPurchaseOptionId: string }>()
    const item = useStatePair<AssetLunarPurchaseOptionValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetLunarPurchaseOptionId !== undefined) {
            AssetLunarPurchaseOptionIO
                .getById(BigInt(params.assetLunarPurchaseOptionId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetLunarPurchaseOptionValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetLunarPurchaseOptionIO}
                description={AssetLunarPurchaseOptionDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetLunarPurchaseOptionDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "lunarAmount":
        case "cashAmount":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{AssetLunarPurchaseOptionDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetLunarPurchaseOptionDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}