import {AssetSolarWithdrawalSearchOption} from "../../search-option/asset/AssetSolarWithdrawalSearchOption"

/**
 * 솔라 인출 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const AssetSolarWithdrawalSearchOptionDescription: Record<keyof AssetSolarWithdrawalSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    assetSolarUsageIdEq: '솔라 사용 ID 일치',
    assetSolarUsageIdNeq: '솔라 사용 ID 불일치',
    assetSolarUsageIdMin: '솔라 사용 ID 최소',
    assetSolarUsageIdMax: '솔라 사용 ID 최대',
    envBankIdEq: '은행 ID 일치',
    envBankIdNeq: '은행 ID 불일치',
    envBankIdMin: '은행 ID 최소',
    envBankIdMax: '은행 ID 최대',
    accountEq: '계좌번호 일치',
    accountNeq: '계좌번호 불일치',
    accountMin: '계좌번호 최소',
    accountMax: '계좌번호 최대',
    accountLike: '계좌번호 포함',
    accountNotLike: '계좌번호 제외',
    cashAmountEq: '요청 금액(원) 일치',
    cashAmountNeq: '요청 금액(원) 불일치',
    cashAmountMin: '요청 금액(원) 최소',
    cashAmountMax: '요청 금액(원) 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
