import {EnvCountryDomain} from "../../domain/env/EnvCountryDomain"

/**
 * 국가 속성 설명
 */
export const EnvCountryDescription: Record<keyof EnvCountryDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    name: '이름',
    isoCode: 'ISO 코드',
    phoneCode: '전화번호 코드',
    emoji: '이모지',
    userBirthCount: '출신 국가로 등록한 사용자 수',
    userResidenceCount: '거주 국가로 등록한 사용자 수',
    userPhoneCodeCount: '전화번호 코드로 등록한 사용자 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
