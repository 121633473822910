import {RouterProps} from "./RouterProps";
import {ChevronRight24Filled} from "@fluentui/react-icons";
import {Button} from "@fluentui/react-components";

export type RouterNavigationButtonProps = RouterProps & {
    href?: string
    children?: string
}

export function RouterNavigationButton(props: RouterNavigationButtonProps) {
    return <>
        <Button
            className={props.styles.navigationButton}
            as={'a'}
            href={props.href}
            appearance={'subtle'}
            icon={<ChevronRight24Filled/>}
            iconPosition={'after'}>
            {props.children}
        </Button>
    </>
}