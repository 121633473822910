import {UiPolicyModificationDomain} from "../../domain/ui/UiPolicyModificationDomain"
import {UiPolicyId} from "../../id/ui/UiPolicyId"
import {UiPolicyModificationId} from "../../id/ui/UiPolicyModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * UI 정책 수정 이력
 */
export class UiPolicyModificationValueObject implements ValueObject, UiPolicyModificationDomain {
    /**
     * 기본키
     */
    id: UiPolicyModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * UI 정책 ID
     */
    uiPolicyId: UiPolicyId
    /**
     * 홈 추천 콘텐츠 페이지 크기
     */
    uiHomeContentSuggestionPageSize: number
    /**
     * 홈 와글와글 콘텐츠 페이지 크기
     */
    uiHomeContentUserProductionPageSize: number
    /**
     * 홈 공지사항 페이지 크기
     */
    uiHomeNoticePageSize: number
    /**
     * 온보딩 페이지 크기
     */
    uiOnboardingPageSize: number
    /**
     * UI 정책 페이지 크기
     */
    uiPolicyPageSize: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UiPolicyModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiPolicyId = BigInt(o.uiPolicyId)
        this.uiHomeContentSuggestionPageSize = Number(o.uiHomeContentSuggestionPageSize)
        this.uiHomeContentUserProductionPageSize = Number(o.uiHomeContentUserProductionPageSize)
        this.uiHomeNoticePageSize = Number(o.uiHomeNoticePageSize)
        this.uiOnboardingPageSize = Number(o.uiOnboardingPageSize)
        this.uiPolicyPageSize = Number(o.uiPolicyPageSize)
        this.createdAt = new Date(o.createdAt)
    }
}
