import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentUserProductionRewardValueObject
} from "../../../../data/value-object/content/ContentUserProductionRewardValueObject";
import {useEffect} from "react";
import {
    ContentUserProductionRewardIO,
    ContentUserProductionRewardModificationIO
} from "../../../../io/ContentIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../../DetailViews";
import {ContentUserProductionRewardDomain} from "../../../../data/domain/content/ContentUserProductionRewardDomain";
import {ContentUserProductionRewardDescriptor} from "../../../../data/descriptor/content/ContentUserProductionRewardDescriptor";
import {ContentUserProductionRewardDescription} from "../../../../data/description/content/ContentUserProductionRewardDescription";
import {
    ContentUserProductionRewardModificationValueObject
} from "../../../../data/value-object/content/ContentUserProductionRewardModificationValueObject";
import {
    ContentUserProductionRewardModificationSearchOption
} from "../../../../data/search-option/content/ContentUserProductionRewardModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {
    ContentUserProductionRewardModificationDescription
} from "../../../../data/description/content/ContentUserProductionRewardModificationDescription";
import {
    ContentUserProductionRewardModificationDescriptor
} from "../../../../data/descriptor/content/ContentUserProductionRewardModificationDescriptor";

enum Panel {
    Default,
    ContentUserProductionRewardModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentUserProductionRewardId: string }>()
    const item = useStatePair<ContentUserProductionRewardValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentUserProductionRewardId !== undefined) {
            ContentUserProductionRewardIO
                .getById(BigInt(params.contentUserProductionRewardId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentUserProductionRewardValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.ContentUserProductionRewardModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                client={ContentUserProductionRewardIO}
                description={ContentUserProductionRewardDescription}
                properties={props => <PropertyBody {...props} />}/>
            <ContentUserProductionRewardModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentUserProductionRewardDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "value":
        case "displayOrder":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{ContentUserProductionRewardDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentUserProductionRewardDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function ContentUserProductionRewardModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentUserProductionRewardModificationValueObject[]>([])
    const modificationSearchOption: ContentUserProductionRewardModificationSearchOption = { contentUserProductionRewardIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentUserProductionRewardModificationList && <>
            <ListPageWrapper
                {...props}
                client={ContentUserProductionRewardModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentUserProductionRewardModificationDescription}
                descriptor={ContentUserProductionRewardModificationDescriptor}
                showNewTab={false}
                onItemClick={item => `/contents/userProduction/durations/${item.id}`} />
        </>}
    </>
}