import {EnvFaqItemSearchOption} from "../../search-option/env/EnvFaqItemSearchOption"

/**
 * 자주 묻는 질문 항목 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvFaqItemSearchOptionDescription: Record<keyof EnvFaqItemSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    envFaqIdEq: '자주 묻는 질문 ID 일치',
    envFaqIdNeq: '자주 묻는 질문 ID 불일치',
    envFaqIdMin: '자주 묻는 질문 ID 최소',
    envFaqIdMax: '자주 묻는 질문 ID 최대',
    titleEq: '제목 일치',
    titleNeq: '제목 불일치',
    titleMin: '제목 최소',
    titleMax: '제목 최대',
    titleLike: '제목 포함',
    titleNotLike: '제목 제외',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    displayOrderEq: '표시 순서 일치',
    displayOrderNeq: '표시 순서 불일치',
    displayOrderMin: '표시 순서 최소',
    displayOrderMax: '표시 순서 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
