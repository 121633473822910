import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvConstructionValueObject} from "../../../data/value-object/env/EnvConstructionValueObject";
import {JSX, useEffect} from "react";
import {EnvConstructionIO, EnvConstructionModificationIO} from "../../../io/EnvIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {EnvConstructionDomain} from "../../../data/domain/env/EnvConstructionDomain";
import {EnvConstructionDescriptor} from "../../../data/descriptor/env/EnvConstructionDescriptor";
import {EnvConstructionDescription} from "../../../data/description/env/EnvConstructionDescription";
import {EnvConstructionModificationValueObject} from "../../../data/value-object/env/EnvConstructionModificationValueObject";
import {
    EnvConstructionModificationSearchOption
} from "../../../data/search-option/env/EnvConstructionModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvConstructionModificationDescription} from "../../../data/description/env/EnvConstructionModificationDescription";
import {EnvConstructionModificationDescriptor} from "../../../data/descriptor/env/EnvConstructionModificationDescriptor";

enum Panel {
    Default,
    EnvConstructionModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envConstructionId: string }>()
    const item = useStatePair<EnvConstructionValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envConstructionId !== undefined) {
            EnvConstructionIO
                .getById(BigInt(params.envConstructionId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: EnvConstructionValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.EnvConstructionModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={EnvConstructionIO}
                description={EnvConstructionDescription}
                properties={props => <PropertyBody {...props} />}/>
            <EnvConstructionModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof EnvConstructionDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "title":
        case "body":
        case "startAt":
        case "endAt":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{EnvConstructionDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a
                href={`/users/${props.item.creatorId}`}><Body1>{EnvConstructionDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function EnvConstructionModificationListPanel(props: PanelProps) {
    const items = useStatePair<EnvConstructionModificationValueObject[]>([])
    const option: EnvConstructionModificationSearchOption = { envConstructionIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.EnvConstructionModificationList && <>
            <ListPageWrapper
                {...props}
                client={EnvConstructionModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={EnvConstructionModificationDescription}
                descriptor={EnvConstructionModificationDescriptor} />
        </>}
    </>
}