import {UserSessionVerboseDomain} from "../../domain/user/UserSessionVerboseDomain"
import {UserSessionId} from "../../id/user/UserSessionId"
import {UserSignInId} from "../../id/user/UserSignInId"
import {UserSignInValueObject} from "./UserSignInValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 세션 상세
 */
export class UserSessionVerboseValueObject implements VerboseValueObject, UserSessionVerboseDomain {
    /**
     * 기본키
     */
    id: UserSessionId
    /**
     * 사용자 로그인 ID
     */
    userSignInId?: UserSignInId
    /**
     * 쿠키에 포함되는 ID
     */
    outbound: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 사용자 로그인 ID
     */
    userSignIn?: UserSignInValueObject

    constructor(o: Record<keyof UserSessionVerboseDomain, any>) {
        this.id = BigInt(o.id)
        if (o.userSignInId !== undefined && o.userSignInId !== null) {
            this.userSignInId = BigInt(o.userSignInId)
        }
        this.outbound = String(o.outbound)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.userSignIn !== undefined && o.userSignIn !== null) {
            this.userSignIn = new UserSignInValueObject(o.userSignIn)
        }
    }
}
