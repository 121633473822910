import {UserRankDuration} from "../../../data/constant/user/UserRankDuration"
import {UserPolicyPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/user/UserPolicyPutDataTransferObjectDescription"
import {UserPolicyPutState} from "../../../data/data-transfer-object-state/user/UserPolicyPutState"
import {UserPolicyPutDataTransferObject} from "../../../data/data-transfer-object/user/UserPolicyPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: UserPolicyPutState
}

export const UserPolicyPutField: Record<Capitalize<keyof UserPolicyPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    SignUpSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.signUpSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.signUpSolarAmount.value}
                    setter={props.state.signUpSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    SignUpLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.signUpLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.signUpLunarAmount.value}
                    setter={props.state.signUpLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    SignUpStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.signUpStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.signUpStarAmount.value}
                    setter={props.state.signUpStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommenderSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderSolarAmount.value}
                    setter={props.state.recommenderSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommenderLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderLunarAmount.value}
                    setter={props.state.recommenderLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommenderStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderStarAmount.value}
                    setter={props.state.recommenderStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderRewardThreshold(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommenderRewardThreshold}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderRewardThreshold.value}
                    setter={props.state.recommenderRewardThreshold.setter} />
            </PostFieldWrapper>
        )
    },
    RecommendeeSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommendeeSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommendeeSolarAmount.value}
                    setter={props.state.recommendeeSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommendeeLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommendeeLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommendeeLunarAmount.value}
                    setter={props.state.recommendeeLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommendeeStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.recommendeeStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommendeeStarAmount.value}
                    setter={props.state.recommendeeStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    UserPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userPageSize.value}
                    setter={props.state.userPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserAgreementPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userAgreementPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userAgreementPageSize.value}
                    setter={props.state.userAgreementPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userAuthenticationCodePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodePageSize.value}
                    setter={props.state.userAuthenticationCodePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodeExpireSeconds(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userAuthenticationCodeExpireSeconds}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodeExpireSeconds.value}
                    setter={props.state.userAuthenticationCodeExpireSeconds.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodeThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userAuthenticationCodeThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodeThresholdMinutes.value}
                    setter={props.state.userAuthenticationCodeThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodeThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userAuthenticationCodeThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodeThresholdCount.value}
                    setter={props.state.userAuthenticationCodeThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    UserBlockPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userBlockPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userBlockPageSize.value}
                    setter={props.state.userBlockPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserBlockReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userBlockReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userBlockReasonPageSize.value}
                    setter={props.state.userBlockReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserExpPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userExpPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userExpPageSize.value}
                    setter={props.state.userExpPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserInquiryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userInquiryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userInquiryPageSize.value}
                    setter={props.state.userInquiryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserLevelPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userLevelPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userLevelPageSize.value}
                    setter={props.state.userLevelPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserLevelHistoryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userLevelHistoryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userLevelHistoryPageSize.value}
                    setter={props.state.userLevelHistoryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserNotificationPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userNotificationPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userNotificationPageSize.value}
                    setter={props.state.userNotificationPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userPolicyPageSize.value}
                    setter={props.state.userPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userRankPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankPageSize.value}
                    setter={props.state.userRankPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankCacheSchedulerDurationMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userRankCacheSchedulerDurationMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankCacheSchedulerDurationMinutes.value}
                    setter={props.state.userRankCacheSchedulerDurationMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankCacheRefreshDurationMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userRankCacheRefreshDurationMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankCacheRefreshDurationMinutes.value}
                    setter={props.state.userRankCacheRefreshDurationMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankDuration(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userRankDuration}>
                <PostEnumField
                    {...props}
                    value={props.state.userRankDuration.value}
                    setter={props.state.userRankDuration.setter}
                    selections={UserRankDuration.selections} />
            </PostFieldWrapper>
        )
    },
    UserRankHistoryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userRankHistoryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankHistoryPageSize.value}
                    setter={props.state.userRankHistoryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserSessionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userSessionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userSessionPageSize.value}
                    setter={props.state.userSessionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserSessionMaxAgeSeconds(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userSessionMaxAgeSeconds}>
                <PostNumberField
                    {...props}
                    value={props.state.userSessionMaxAgeSeconds.value}
                    setter={props.state.userSessionMaxAgeSeconds.setter} />
            </PostFieldWrapper>
        )
    },
    UserSignInPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userSignInPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userSignInPageSize.value}
                    setter={props.state.userSignInPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserWithdrawalPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userWithdrawalPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userWithdrawalPageSize.value}
                    setter={props.state.userWithdrawalPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserWithdrawalReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPutDataTransferObjectDescription.userWithdrawalReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userWithdrawalReasonPageSize.value}
                    setter={props.state.userWithdrawalReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
}

export function UserPolicyPutFields(props: PostFieldProps) {
    return <>
        <UserPolicyPutField.SignUpSolarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.SignUpLunarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.SignUpStarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommenderSolarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommenderLunarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommenderStarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommenderRewardThreshold
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommendeeSolarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommendeeLunarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.RecommendeeStarAmount
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserAgreementPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserAuthenticationCodePageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserAuthenticationCodeExpireSeconds
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserAuthenticationCodeThresholdMinutes
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserAuthenticationCodeThresholdCount
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserBlockPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserBlockReasonPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserExpPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserInquiryPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserLevelPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserLevelHistoryPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserNotificationPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserPolicyPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserRankPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserRankCacheSchedulerDurationMinutes
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserRankCacheRefreshDurationMinutes
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserRankDuration
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserRankHistoryPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserSessionPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserSessionMaxAgeSeconds
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserSignInPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserWithdrawalPageSize
            {...props}
            state={props.state} />
        <UserPolicyPutField.UserWithdrawalReasonPageSize
            {...props}
            state={props.state} />
    </>
}
