import {ContentDifficulty} from "../../../data/constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../../data/constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../../data/constant/content/ContentScriptVisibility"
import {ContentStatus} from "../../../data/constant/content/ContentStatus"
import {EnvBrowser} from "../../../data/constant/env/EnvBrowser"
import {ContentPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/content/ContentPutDataTransferObjectDescription"
import {ContentPutState} from "../../../data/data-transfer-object-state/content/ContentPutState"
import {ContentPutDataTransferObject} from "../../../data/data-transfer-object/content/ContentPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: ContentPutState
}

export const ContentPutField: Record<Capitalize<keyof ContentPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    AdvertiserId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.advertiserId}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.advertiserId.value === undefined}
                onUnusedCheckboxChange={checked => props.state.advertiserId.setter(checked ? undefined : 0n)}>
                <PostBigIntField
                    {...props}
                    value={props.state.advertiserId.value}
                    setter={props.state.advertiserId.setter}
                    disabled={props.state.advertiserId.value === undefined} />
            </PostFieldWrapper>
        )
    },
    ParticipationMethod(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.participationMethod}>
                <PostEnumField
                    {...props}
                    value={props.state.participationMethod.value}
                    setter={props.state.participationMethod.setter}
                    selections={ContentParticipationMethod.selections} />
            </PostFieldWrapper>
        )
    },
    Title(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.title}>
                <PostTextareaField
                    {...props}
                    value={props.state.title.value}
                    setter={props.state.title.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    TitleHighlight(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.titleHighlight}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.titleHighlight.value === undefined}
                onUnusedCheckboxChange={checked => props.state.titleHighlight.setter(checked ? undefined : '')}>
                <PostInputField
                    {...props}
                    value={props.state.titleHighlight.value}
                    setter={props.state.titleHighlight.setter}
                    maxLength={20}
                    disabled={props.state.titleHighlight.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Body(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.body}>
                <PostTextareaField
                    {...props}
                    value={props.state.body.value}
                    setter={props.state.body.setter}
                    maxLength={2000} />
            </PostFieldWrapper>
        )
    },
    Script(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.script}>
                <PostTextareaField
                    {...props}
                    value={props.state.script.value}
                    setter={props.state.script.setter}
                    maxLength={1000} />
            </PostFieldWrapper>
        )
    },
    ScriptVisibility(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.scriptVisibility}>
                <PostEnumField
                    {...props}
                    value={props.state.scriptVisibility.value}
                    setter={props.state.scriptVisibility.setter}
                    selections={ContentScriptVisibility.selections} />
            </PostFieldWrapper>
        )
    },
    GradingMessage(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.gradingMessage}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.gradingMessage.value === undefined}
                onUnusedCheckboxChange={checked => props.state.gradingMessage.setter(checked ? undefined : '')}>
                <PostTextareaField
                    {...props}
                    value={props.state.gradingMessage.value}
                    setter={props.state.gradingMessage.setter}
                    maxLength={400}
                    disabled={props.state.gradingMessage.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
    PassAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.passAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.passAccuracy.value}
                    setter={props.state.passAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    PassAccuracyHigh(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.passAccuracyHigh}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.passAccuracyHigh.value === undefined}
                onUnusedCheckboxChange={checked => props.state.passAccuracyHigh.setter(checked ? undefined : 0)}>
                <PostNumberField
                    {...props}
                    value={props.state.passAccuracyHigh.value}
                    setter={props.state.passAccuracyHigh.setter}
                    disabled={props.state.passAccuracyHigh.value === undefined} />
            </PostFieldWrapper>
        )
    },
    PassSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.passSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.passSolarAmount.value}
                    setter={props.state.passSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    PassSolarAmountHighAccuracy(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.passSolarAmountHighAccuracy}>
                <PostNumberField
                    {...props}
                    value={props.state.passSolarAmountHighAccuracy.value}
                    setter={props.state.passSolarAmountHighAccuracy.setter} />
            </PostFieldWrapper>
        )
    },
    StarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.starAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.starAmount.value}
                    setter={props.state.starAmount.setter} />
            </PostFieldWrapper>
        )
    },
    Difficulty(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.difficulty}>
                <PostEnumField
                    {...props}
                    value={props.state.difficulty.value}
                    setter={props.state.difficulty.setter}
                    selections={ContentDifficulty.selections} />
            </PostFieldWrapper>
        )
    },
    HeaderImageCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.headerImageCount}>
                <PostNumberField
                    {...props}
                    value={props.state.headerImageCount.value}
                    setter={props.state.headerImageCount.setter} />
            </PostFieldWrapper>
        )
    },
    DescriptionImageCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.descriptionImageCount}>
                <PostNumberField
                    {...props}
                    value={props.state.descriptionImageCount.value}
                    setter={props.state.descriptionImageCount.setter} />
            </PostFieldWrapper>
        )
    },
    ExposedAt(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.exposedAt}>
                <PostDateField
                    {...props}
                    value={props.state.exposedAt.value}
                    setter={props.state.exposedAt.setter} />
            </PostFieldWrapper>
        )
    },
    StartAt(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.startAt}>
                <PostDateField
                    {...props}
                    value={props.state.startAt.value}
                    setter={props.state.startAt.setter} />
            </PostFieldWrapper>
        )
    },
    EndAt(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.endAt}>
                <PostDateField
                    {...props}
                    value={props.state.endAt.value}
                    setter={props.state.endAt.setter} />
            </PostFieldWrapper>
        )
    },
    StatusFlags(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.statusFlags}>
                <PostBitMaskField
                    {...props}
                    value={props.state.statusFlags.value}
                    setter={props.state.statusFlags.setter}
                    selections={ContentStatus.selections} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.contentDetailUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentDetailUrl.value}
                    setter={props.state.contentDetailUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentDetailUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.contentDetailUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentDetailUrlBrowser.value}
                    setter={props.state.contentDetailUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrl(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.contentResultUrl}>
                <PostTextareaField
                    {...props}
                    value={props.state.contentResultUrl.value}
                    setter={props.state.contentResultUrl.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    ContentResultUrlBrowser(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={ContentPutDataTransferObjectDescription.contentResultUrlBrowser}>
                <PostEnumField
                    {...props}
                    value={props.state.contentResultUrlBrowser.value}
                    setter={props.state.contentResultUrlBrowser.setter}
                    selections={EnvBrowser.selections} />
            </PostFieldWrapper>
        )
    },
}

export function ContentPutFields(props: PostFieldProps) {
    return <>
        <ContentPutField.AdvertiserId
            {...props}
            state={props.state} />
        <ContentPutField.ParticipationMethod
            {...props}
            state={props.state} />
        <ContentPutField.Title
            {...props}
            state={props.state} />
        <ContentPutField.TitleHighlight
            {...props}
            state={props.state} />
        <ContentPutField.Body
            {...props}
            state={props.state} />
        <ContentPutField.Script
            {...props}
            state={props.state} />
        <ContentPutField.ScriptVisibility
            {...props}
            state={props.state} />
        <ContentPutField.GradingMessage
            {...props}
            state={props.state} />
        <ContentPutField.Amount
            {...props}
            state={props.state} />
        <ContentPutField.PassAccuracy
            {...props}
            state={props.state} />
        <ContentPutField.PassAccuracyHigh
            {...props}
            state={props.state} />
        <ContentPutField.PassSolarAmount
            {...props}
            state={props.state} />
        <ContentPutField.PassSolarAmountHighAccuracy
            {...props}
            state={props.state} />
        <ContentPutField.StarAmount
            {...props}
            state={props.state} />
        <ContentPutField.Difficulty
            {...props}
            state={props.state} />
        <ContentPutField.HeaderImageCount
            {...props}
            state={props.state} />
        <ContentPutField.DescriptionImageCount
            {...props}
            state={props.state} />
        <ContentPutField.ExposedAt
            {...props}
            state={props.state} />
        <ContentPutField.StartAt
            {...props}
            state={props.state} />
        <ContentPutField.EndAt
            {...props}
            state={props.state} />
        <ContentPutField.StatusFlags
            {...props}
            state={props.state} />
        <ContentPutField.ContentDetailUrl
            {...props}
            state={props.state} />
        <ContentPutField.ContentDetailUrlBrowser
            {...props}
            state={props.state} />
        <ContentPutField.ContentResultUrl
            {...props}
            state={props.state} />
        <ContentPutField.ContentResultUrlBrowser
            {...props}
            state={props.state} />
    </>
}
