import {ContentReportDomain} from "../../domain/content/ContentReportDomain"

export const ContentReportDescriptor: Record<keyof ContentReportDomain, (o: ContentReportDomain) => string> = {
    id(o: ContentReportDomain): string {
        return o.id.toString()
    },
    contentId(o: ContentReportDomain): string {
        return o.contentId.toString()
    },
    contentReportReasonId(o: ContentReportDomain): string {
        return o.contentReportReasonId.toString()
    },
    userId(o: ContentReportDomain): string {
        return o.userId.toString()
    },
    createdAt(o: ContentReportDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
