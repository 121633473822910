import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {RouterProps} from "../../RouterProps";
import {UserRankIO} from "../../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFilesField, PostFinishDialog, PostInputField, PostNumberField} from "../../PostField";
import {UserRankDescription} from "../../../data/description/user/UserRankDescription";
import {MediaFile} from "../../Media";
import {StorageIO, StorageKey} from "../../../io/StorageIO";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const name = useStatePair('')
    const percentile = useStatePair<number | undefined>(undefined)
    const fixed = useStatePair<number | undefined>(undefined)
    const applyOrder = useStatePair<number>(0)
    const rewardAssetLunarAmount = useStatePair(0)
    const rewardAssetSolarAmount = useStatePair(0)
    const rewardAssetStarAmount = useStatePair(0)
    const icon = useStatePair<MediaFile[]>([])

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        post()
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [name.value, percentile.value, fixed.value, applyOrder.value, rewardAssetLunarAmount.value, rewardAssetSolarAmount.value, rewardAssetStarAmount.value, icon.value])

    const post = async () => {
        const response = await UserRankIO.post({
            name: name.value,
            percentile: percentile.value,
            fixed: fixed.value,
            applyOrder: applyOrder.value,
            rewardAssetLunarAmount: rewardAssetLunarAmount.value,
            rewardAssetSolarAmount: rewardAssetSolarAmount.value,
            rewardAssetStarAmount: rewardAssetStarAmount.value
        })
        await StorageIO.putFile(StorageKey.User.rank(response.id), icon.value[0])
    }

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.name}>
                    <PostInputField
                        {...props}
                        value={name.value}
                        setter={name.setter}
                        maxLength={20} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.percentile}>
                    <PostNumberField
                        {...props}
                        value={percentile.value}
                        setter={percentile.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.fixed}>
                    <PostNumberField
                        {...props}
                        value={fixed.value}
                        setter={fixed.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.applyOrder}>
                    <PostNumberField
                        {...props}
                        value={applyOrder.value}
                        setter={applyOrder.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.rewardAssetLunarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetLunarAmount.value}
                        setter={rewardAssetLunarAmount.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.rewardAssetSolarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetSolarAmount.value}
                        setter={rewardAssetSolarAmount.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserRankDescription.rewardAssetStarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetStarAmount.value}
                        setter={rewardAssetStarAmount.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'아이콘'}>
                    <PostFilesField
                        {...props}
                        value={icon.value}
                        setter={icon.setter}
                        accept={'image/*'} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/users/ranks/ratios'}/>
    </>
}