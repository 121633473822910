import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {MediaFile} from "../../../../Media";
import {useCallback} from "react";
import {UiHomeContentSuggestionIO} from "../../../../../io/UiIO";
import {StorageIO, StorageKey} from "../../../../../io/StorageIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostFieldWrapper,
    PostFilesField,
    PostFinishDialog,
    PostInputField,
    PostNumberField
} from "../../../../PostField";
import {
    UiHomeContentSuggestionDescription
} from "../../../../../data/description/ui/UiHomeContentSuggestionDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const displayOrder = useStatePair(0)
    const url = useStatePair('')
    const image = useStatePair<MediaFile[]>([])

    const onDoneClick = useCallback(() => {
        post()
            .then(() => {})
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            });
    }, [displayOrder.value, image.value])

    const post = async () => {
        phase.setter(Phase.Posting)
        const response = await UiHomeContentSuggestionIO.post({
            displayOrder: displayOrder.value,
            url: url.value
        })
        await StorageIO.putFile(StorageKey.Ui.Home.Content.suggestion(response.id), image.value[0])
        phase.setter(Phase.Finished)
    }

    return <>
        <div className={props.styles.column16}>
            <Title3>새 항목</Title3>
            <PostFieldWrapper
                {...props}
                title={UiHomeContentSuggestionDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter}/>
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiHomeContentSuggestionDescription.url}>
                <PostInputField
                    {...props}
                    value={url.value}
                    setter={url.setter}/>
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={'이미지'}>
                <PostFilesField
                    {...props}
                    value={image.value}
                    setter={image.setter}
                    accept={'image/*'} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/ui/home/contents/suggestions'} />
    </>
}