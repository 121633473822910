import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvNoticeVerboseValueObject} from "../../../data/value-object/env/EnvNoticeVerboseValueObject";
import {useCallback, useEffect} from "react";
import {EnvNoticeIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {useEnvNoticePutState} from "../../../data/data-transfer-object-state/env/EnvNoticePutState";
import {EnvNoticePutFields} from "../../data-transfer-object-field/env/EnvNoticePutField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envNoticeId: string }>()
    const item = useStatePair<EnvNoticeVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const state = useEnvNoticePutState()

    useEffect(() => {
        if (item.value === undefined && params.envNoticeId !== undefined) {
            EnvNoticeIO
                .getVerboseById(BigInt(params.envNoticeId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        EnvNoticeIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/notices/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <EnvNoticePutFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}