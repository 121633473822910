import {ContentDetailUrlClickDomain} from "../../domain/content/ContentDetailUrlClickDomain"

export const ContentDetailUrlClickDescriptor: Record<keyof ContentDetailUrlClickDomain, (o: ContentDetailUrlClickDomain) => string> = {
    id(o: ContentDetailUrlClickDomain): string {
        return o.id.toString()
    },
    userId(o: ContentDetailUrlClickDomain): string {
        return o.userId.toString()
    },
    contentDetailUrlId(o: ContentDetailUrlClickDomain): string {
        return o.contentDetailUrlId.toString()
    },
    createdAt(o: ContentDetailUrlClickDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
