import {BitMaskFlag} from "ts-protocol-extension"

export const UserNotificationStatus: {
    Shown: BitMaskFlag,
    Hidden: BitMaskFlag,
    selections: { value: string, label: string }[]
} = new (class _ {
    /**
     * 사용자에게 표시됨
     */
    Shown: BitMaskFlag = {
        value: 1 << 0,
        name: '사용자에게 표시됨'
    }

    /**
     * 사용자가 숨김
     */
    Hidden: BitMaskFlag = {
        value: 1 << 1,
        name: '사용자가 숨김'
    }

    selections: { value: string, label: string }[] = [
        { value: this.Shown.value.toString(), label: this.Shown.name },
        { value: this.Hidden.value.toString(), label: this.Hidden.name }
    ]
})()
