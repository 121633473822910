import {ContentCommentSearchOption} from "../../search-option/content/ContentCommentSearchOption"

/**
 * 댓글 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentCommentSearchOptionDescription: Record<keyof ContentCommentSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    contentIdEq: '콘텐츠 ID 일치',
    contentIdNeq: '콘텐츠 ID 불일치',
    contentIdMin: '콘텐츠 ID 최소',
    contentIdMax: '콘텐츠 ID 최대',
    parentIdEq: '부모 댓글 ID 일치',
    parentIdNeq: '부모 댓글 ID 불일치',
    parentIdMin: '부모 댓글 ID 최소',
    parentIdMax: '부모 댓글 ID 최대',
    parentIdIsNull: '부모 댓글 ID 값 존재 여부',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    audioIdNeq: '오디오 ID 불일치',
    audioIdMin: '오디오 ID 최소',
    audioIdMax: '오디오 ID 최대',
    audioIdIsNull: '오디오 ID 값 존재 여부',
    childCountEq: '대댓글 수 일치',
    childCountNeq: '대댓글 수 불일치',
    childCountMin: '대댓글 수 최소',
    childCountMax: '대댓글 수 최대',
    likeCountEq: '좋아요 수 일치',
    likeCountNeq: '좋아요 수 불일치',
    likeCountMin: '좋아요 수 최소',
    likeCountMax: '좋아요 수 최대',
    reportCountEq: '신고 수 일치',
    reportCountNeq: '신고 수 불일치',
    reportCountMin: '신고 수 최소',
    reportCountMax: '신고 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
