import {AssetSolarWithdrawalDomain} from "../../domain/asset/AssetSolarWithdrawalDomain"

export const AssetSolarWithdrawalDescriptor: Record<keyof AssetSolarWithdrawalDomain, (o: AssetSolarWithdrawalDomain) => string> = {
    id(o: AssetSolarWithdrawalDomain): string {
        return o.id.toString()
    },
    assetSolarUsageId(o: AssetSolarWithdrawalDomain): string {
        return o.assetSolarUsageId.toString()
    },
    envBankId(o: AssetSolarWithdrawalDomain): string {
        return o.envBankId.toString()
    },
    account(o: AssetSolarWithdrawalDomain): string {
        return o.account
    },
    cashAmount(o: AssetSolarWithdrawalDomain): string {
        return o.cashAmount.toString()
    },
    createdAt(o: AssetSolarWithdrawalDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
