import {EnvFaqItemModificationDomain} from "../../domain/env/EnvFaqItemModificationDomain"
import {EnvFaqId} from "../../id/env/EnvFaqId"
import {EnvFaqItemId} from "../../id/env/EnvFaqItemId"
import {EnvFaqItemModificationId} from "../../id/env/EnvFaqItemModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 자주 묻는 질문 항목 수정 이력
 */
export class EnvFaqItemModificationValueObject implements ValueObject, EnvFaqItemModificationDomain {
    /**
     * 기본키
     */
    id: EnvFaqItemModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 자주 묻는 질문 항목 ID
     */
    envFaqItemId: EnvFaqItemId
    /**
     * 자주 묻는 질문 ID
     */
    envFaqId: EnvFaqId
    /**
     * 제목
     */
    title: string
    /**
     * 내용
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvFaqItemModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envFaqItemId = BigInt(o.envFaqItemId)
        this.envFaqId = BigInt(o.envFaqId)
        this.title = String(o.title)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
