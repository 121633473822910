import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {
    AssetSolarWithdrawalValueObject
} from "../../../../data/value-object/asset/AssetSolarWithdrawalValueObject";
import {
    AssetSolarWithdrawalSearchOption
} from "../../../../data/search-option/asset/AssetSolarWithdrawalSearchOption";
import {AssetSolarUsageIO, AssetSolarWithdrawalIO} from "../../../../io/AssetIO";
import {
    AssetSolarWithdrawalDescription
} from "../../../../data/description/asset/AssetSolarWithdrawalDescription";
import {AssetSolarWithdrawalDescriptor} from "../../../../data/descriptor/asset/AssetSolarWithdrawalDescriptor";
import {
    AssetSolarWithdrawalSearchOptionDescription
} from "../../../../data/description/asset/AssetSolarWithdrawalSearchOptionDescription";
import {
    applyAssetSolarWithdrawalSearchOption,
    AssetSolarWithdrawalSearchOptionField
} from "../../../search-option-field/asset/AssetSolarWithdrawalSearchOptionField";
import {AssetSolarUsageValueObject} from "../../../../data/value-object/asset/AssetSolarUsageValueObject";
import {AssetSolarUsageId} from "../../../../data/id/asset/AssetSolarUsageId";
import {EnvBankValueObject} from "../../../../data/value-object/env/EnvBankValueObject";
import {EnvBankId} from "../../../../data/id/env/EnvBankId";
import {EnvBankIO} from "../../../../io/EnvIO";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetSolarWithdrawalValueObject[]>([])
    const option = useStatePair<AssetSolarWithdrawalSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())
    const assetSolarUsages = useStatePair<Map<AssetSolarUsageId, AssetSolarUsageValueObject>>(new Map())
    const envBanks = useStatePair<Map<EnvBankId, EnvBankValueObject>>(new Map())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetSolarWithdrawalIO}
                postSearch={async (_present, response) => {
                    const assetSolarUsagesAcc = new Map<AssetSolarUsageId, AssetSolarUsageValueObject>(assetSolarUsages.value)
                    const envBanksAcc = new Map<EnvBankId, EnvBankValueObject>(envBanks.value)
                    for (const item of response) {
                        if (envBanksAcc.has(item.envBankId)) {
                            continue
                        }

                        const assetSolarUsage = await AssetSolarUsageIO.getById(item.assetSolarUsageId)
                        assetSolarUsagesAcc.set(assetSolarUsage.id, assetSolarUsage)

                        const envBank = await EnvBankIO.getById(item.envBankId)
                        envBanksAcc.set(envBank.id, envBank)
                    }
                    assetSolarUsages.setter(assetSolarUsagesAcc)
                    envBanks.setter(envBanksAcc)
                }}
                items={items}
                option={option.value}
                index={index}
                description={AssetSolarWithdrawalDescription}
                descriptor={AssetSolarWithdrawalDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/solars/withdrawals/${item.id}`}
                additionalColumns={[
                    {
                        header: '은행',
                        row(item: AssetSolarWithdrawalValueObject) {
                            const envBank = envBanks.value.get(item.envBankId)
                            if (envBank === undefined) {
                                throw new Error('envBank === undefined')
                            }

                            return envBank.name
                        }
                    }
                ]}
            />
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetSolarWithdrawalSearchOptionDescription}
                fields={AssetSolarWithdrawalSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetSolarWithdrawalSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetSolarWithdrawalDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}