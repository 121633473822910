import {UiOnboardingDomain} from "../../domain/ui/UiOnboardingDomain"
import {UiOnboardingId} from "../../id/ui/UiOnboardingId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 온보딩 이미지
 */
export class UiOnboardingValueObject implements ValueObject, UiOnboardingDomain {
    /**
     * 기본키
     */
    id: UiOnboardingId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof UiOnboardingDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
