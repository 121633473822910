import {AssetSolarWithdrawalOptionPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetSolarWithdrawalOptionPostDataTransferObjectDescription"
import {AssetSolarWithdrawalOptionPostState} from "../../../data/data-transfer-object-state/asset/AssetSolarWithdrawalOptionPostState"
import {AssetSolarWithdrawalOptionPostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetSolarWithdrawalOptionPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetSolarWithdrawalOptionPostState
}

export const AssetSolarWithdrawalOptionPostField: Record<Capitalize<keyof AssetSolarWithdrawalOptionPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    SolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarWithdrawalOptionPostDataTransferObjectDescription.solarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.solarAmount.value}
                    setter={props.state.solarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    CashAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarWithdrawalOptionPostDataTransferObjectDescription.cashAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.cashAmount.value}
                    setter={props.state.cashAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetSolarWithdrawalOptionPostFields(props: PostFieldProps) {
    return <>
        <AssetSolarWithdrawalOptionPostField.SolarAmount
            {...props}
            state={props.state} />
        <AssetSolarWithdrawalOptionPostField.CashAmount
            {...props}
            state={props.state} />
    </>
}
