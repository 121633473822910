import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import React, {useCallback, useEffect} from "react";
import {AssetSolarWithdrawalOptionIO} from "../../../../io/AssetIO";
import {Button, Title3} from "@fluentui/react-components";
import {useParams} from "react-router-dom";
import {
    AssetSolarWithdrawalOptionVerboseValueObject
} from "../../../../data/value-object/asset/AssetSolarWithdrawalOptionVerboseValueObject";
import {
    useAssetSolarWithdrawalOptionPutState
} from "../../../../data/data-transfer-object-state/asset/AssetSolarWithdrawalOptionPutState";
import {
    AssetSolarWithdrawalOptionPutFields
} from "../../../data-transfer-object-field/asset/AssetSolarWithdrawalOptionPutField";

enum Phase {
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ assetSolarWithdrawalOptionId: string }>()
    const item = useStatePair<AssetSolarWithdrawalOptionVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useAssetSolarWithdrawalOptionPutState()

    useEffect(() => {
        if (item.value === undefined && params.assetSolarWithdrawalOptionId !== undefined) {
            AssetSolarWithdrawalOptionIO
                .getVerboseById(BigInt(params.assetSolarWithdrawalOptionId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        AssetSolarWithdrawalOptionIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/assets/solars/withdrawalOptions/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <AssetSolarWithdrawalOptionPutFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}