import {EnvClientDistribution} from "../../../data/constant/env/EnvClientDistribution"
import {EnvStorageKeySearchOptionDescription} from "../../../data/description/env/EnvStorageKeySearchOptionDescription"
import {EnvStorageKeySearchOption} from "../../../data/search-option/env/EnvStorageKeySearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function EnvStorageKeySearchOptionField(props: ListOptionFieldProps<EnvStorageKeySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accessKeyIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.accessKeyIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accessKeyIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.accessKeyIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accessKeyIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.accessKeyIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accessKeyIdLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.accessKeyIdLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accessKeyIdNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.accessKeyIdNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "secretAccessKeyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.secretAccessKeyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "secretAccessKeyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.secretAccessKeyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "secretAccessKeyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.secretAccessKeyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "secretAccessKeyLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.secretAccessKeyLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "secretAccessKeyNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.secretAccessKeyNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bucketEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.bucketEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bucketNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.bucketNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bucketMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.bucketMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bucketMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.bucketMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bucketLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.bucketLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bucketNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.bucketNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "regionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.regionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "regionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.regionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "regionMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.regionMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "regionMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.regionMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "regionLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.regionLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "regionNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.regionNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "distributionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.distributionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.distributionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.distributionMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.distributionMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "distributionIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.distributionIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={EnvClientDistribution.selections} />
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvStorageKeySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyEnvStorageKeySearchOption(key: keyof EnvStorageKeySearchOption, value: string, into: StatePair<EnvStorageKeySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "accessKeyIdNeq":
            into.setter({
                ...into.value,
                accessKeyIdNeq: String(value)
            })
            break
        case "accessKeyIdMin":
            into.setter({
                ...into.value,
                accessKeyIdMin: String(value)
            })
            break
        case "accessKeyIdMax":
            into.setter({
                ...into.value,
                accessKeyIdMax: String(value)
            })
            break
        case "accessKeyIdLike":
            into.setter({
                ...into.value,
                accessKeyIdLike: String(value)
            })
            break
        case "accessKeyIdNotLike":
            into.setter({
                ...into.value,
                accessKeyIdNotLike: String(value)
            })
            break
        case "secretAccessKeyNeq":
            into.setter({
                ...into.value,
                secretAccessKeyNeq: String(value)
            })
            break
        case "secretAccessKeyMin":
            into.setter({
                ...into.value,
                secretAccessKeyMin: String(value)
            })
            break
        case "secretAccessKeyMax":
            into.setter({
                ...into.value,
                secretAccessKeyMax: String(value)
            })
            break
        case "secretAccessKeyLike":
            into.setter({
                ...into.value,
                secretAccessKeyLike: String(value)
            })
            break
        case "secretAccessKeyNotLike":
            into.setter({
                ...into.value,
                secretAccessKeyNotLike: String(value)
            })
            break
        case "bucketEq":
            into.setter({
                ...into.value,
                bucketEq: String(value)
            })
            break
        case "bucketNeq":
            into.setter({
                ...into.value,
                bucketNeq: String(value)
            })
            break
        case "bucketMin":
            into.setter({
                ...into.value,
                bucketMin: String(value)
            })
            break
        case "bucketMax":
            into.setter({
                ...into.value,
                bucketMax: String(value)
            })
            break
        case "bucketLike":
            into.setter({
                ...into.value,
                bucketLike: String(value)
            })
            break
        case "bucketNotLike":
            into.setter({
                ...into.value,
                bucketNotLike: String(value)
            })
            break
        case "regionEq":
            into.setter({
                ...into.value,
                regionEq: String(value)
            })
            break
        case "regionNeq":
            into.setter({
                ...into.value,
                regionNeq: String(value)
            })
            break
        case "regionMin":
            into.setter({
                ...into.value,
                regionMin: String(value)
            })
            break
        case "regionMax":
            into.setter({
                ...into.value,
                regionMax: String(value)
            })
            break
        case "regionLike":
            into.setter({
                ...into.value,
                regionLike: String(value)
            })
            break
        case "regionNotLike":
            into.setter({
                ...into.value,
                regionNotLike: String(value)
            })
            break
        case "distributionEq":
            into.setter({
                ...into.value,
                distributionEq: value as EnvClientDistribution
            })
            break
        case "distributionNeq":
            into.setter({
                ...into.value,
                distributionNeq: value as EnvClientDistribution
            })
            break
        case "distributionMin":
            into.setter({
                ...into.value,
                distributionMin: value as EnvClientDistribution
            })
            break
        case "distributionMax":
            into.setter({
                ...into.value,
                distributionMax: value as EnvClientDistribution
            })
            break
        case "distributionIn":
            into.setter({
                ...into.value,
                distributionIn: value
                    .split('|')
                    .map(e => e as EnvClientDistribution)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
