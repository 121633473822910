import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {EnvClientValueObject} from "../../../data/value-object/env/EnvClientValueObject";
import {EnvClientSearchOption} from "../../../data/search-option/env/EnvClientSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvClientIO} from "../../../io/EnvIO";
import {EnvClientDescription} from "../../../data/description/env/EnvClientDescription";
import {EnvClientDescriptor} from "../../../data/descriptor/env/EnvClientDescriptor";
import ListOption from "../../ListOption";
import {EnvClientSearchOptionDescription} from "../../../data/description/env/EnvClientSearchOptionDescription";
import {
    applyEnvClientSearchOption,
    EnvClientSearchOptionField
} from "../../search-option-field/env/EnvClientSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<EnvClientValueObject[]>([])
    const option = useStatePair<EnvClientSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={EnvClientIO}
                items={items}
                option={option.value}
                index={index}
                description={EnvClientDescription}
                descriptor={EnvClientDescriptor}
                showPost
                onPostClick={() => document.location = '/env/clients/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/env/clients/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={EnvClientSearchOptionDescription}
                fields={EnvClientSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyEnvClientSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvClientDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}