import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {EnvBankValueObject} from "../../../data/value-object/env/EnvBankValueObject";
import {EnvBankSearchOption} from "../../../data/search-option/env/EnvBankSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvBankIO} from "../../../io/EnvIO";
import {EnvBankDescription} from "../../../data/description/env/EnvBankDescription";
import {EnvBankDescriptor} from "../../../data/descriptor/env/EnvBankDescriptor";
import ListOption from "../../ListOption";
import {
    EnvBankSearchOptionDescription
} from "../../../data/description/env/EnvBankSearchOptionDescription";
import {
    applyEnvBankSearchOption,
    EnvBankSearchOptionField
} from "../../search-option-field/env/EnvBankSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<EnvBankValueObject[]>([])
    const option = useStatePair<EnvBankSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={EnvBankIO}
                items={items}
                option={option.value}
                index={index}
                description={EnvBankDescription}
                descriptor={EnvBankDescriptor}
                showPost
                onPostClick={() => document.location = '/env/banks/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/env/banks/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={EnvBankSearchOptionDescription}
                fields={EnvBankSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyEnvBankSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvBankDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}