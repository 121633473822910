import {BaseHttpClient, BaseHttpGetCurrent} from "./HttpClient";
import {UserAgreementId} from "../data/id/user/UserAgreementId";
import {
    UserAgreementPostDataTransferObject
} from "../data/data-transfer-object/user/UserAgreementPostDataTransferObject";
import {UserAgreementValueObject} from "../data/value-object/user/UserAgreementValueObject";
import {UserAgreementVerboseValueObject} from "../data/value-object/user/UserAgreementVerboseValueObject";
import {UserAgreementSearchOption} from "../data/search-option/user/UserAgreementSearchOption";
import {UserBlockModificationId} from "../data/id/user/UserBlockModificationId";
import {UserBlockModificationValueObject} from "../data/value-object/user/UserBlockModificationValueObject";
import {
    UserBlockModificationVerboseValueObject
} from "../data/value-object/user/UserBlockModificationVerboseValueObject";
import {UserBlockModificationSearchOption} from "../data/search-option/user/UserBlockModificationSearchOption";
import {UserBlockReasonModificationId} from "../data/id/user/UserBlockReasonModificationId";
import {UserBlockReasonModificationValueObject} from "../data/value-object/user/UserBlockReasonModificationValueObject";
import {
    UserBlockReasonModificationVerboseValueObject
} from "../data/value-object/user/UserBlockReasonModificationVerboseValueObject";
import {
    UserBlockReasonModificationSearchOption
} from "../data/search-option/user/UserBlockReasonModificationSearchOption";
import {UserBlockReasonId} from "../data/id/user/UserBlockReasonId";
import {
    UserBlockReasonPostDataTransferObject
} from "../data/data-transfer-object/user/UserBlockReasonPostDataTransferObject";
import {
    UserBlockReasonPutDataTransferObject
} from "../data/data-transfer-object/user/UserBlockReasonPutDataTransferObject";
import {UserBlockReasonValueObject} from "../data/value-object/user/UserBlockReasonValueObject";
import {UserBlockReasonVerboseValueObject} from "../data/value-object/user/UserBlockReasonVerboseValueObject";
import {UserBlockReasonSearchOption} from "../data/search-option/user/UserBlockReasonSearchOption";
import {UserModificationId} from "../data/id/user/UserModificationId";
import {UserModificationValueObject} from "../data/value-object/user/UserModificationValueObject";
import {UserModificationVerboseValueObject} from "../data/value-object/user/UserModificationVerboseValueObject";
import {UserModificationSearchOption} from "../data/search-option/user/UserModificationSearchOption";
import {UserNotificationModificationId} from "../data/id/user/UserNotificationModificationId";
import {
    UserNotificationModificationValueObject
} from "../data/value-object/user/UserNotificationModificationValueObject";
import {
    UserNotificationModificationVerboseValueObject
} from "../data/value-object/user/UserNotificationModificationVerboseValueObject";
import {
    UserNotificationModificationSearchOption
} from "../data/search-option/user/UserNotificationModificationSearchOption";
import {UserNotificationId} from "../data/id/user/UserNotificationId";
import {
    UserNotificationPostDataTransferObject
} from "../data/data-transfer-object/user/UserNotificationPostDataTransferObject";
import {
    UserNotificationPutDataTransferObject
} from "../data/data-transfer-object/user/UserNotificationPutDataTransferObject";
import {UserNotificationValueObject} from "../data/value-object/user/UserNotificationValueObject";
import {UserNotificationVerboseValueObject} from "../data/value-object/user/UserNotificationVerboseValueObject";
import {UserNotificationSearchOption} from "../data/search-option/user/UserNotificationSearchOption";
import {UserPolicyModificationId} from "../data/id/user/UserPolicyModificationId";
import {UserPolicyModificationValueObject} from "../data/value-object/user/UserPolicyModificationValueObject";
import {
    UserPolicyModificationVerboseValueObject
} from "../data/value-object/user/UserPolicyModificationVerboseValueObject";
import {UserPolicyModificationSearchOption} from "../data/search-option/user/UserPolicyModificationSearchOption";
import {UserPolicyId} from "../data/id/user/UserPolicyId";
import {UserPolicyPostDataTransferObject} from "../data/data-transfer-object/user/UserPolicyPostDataTransferObject";
import {UserPolicyPutDataTransferObject} from "../data/data-transfer-object/user/UserPolicyPutDataTransferObject";
import {UserPolicyValueObject} from "../data/value-object/user/UserPolicyValueObject";
import {UserPolicyVerboseValueObject} from "../data/value-object/user/UserPolicyVerboseValueObject";
import {UserPolicySearchOption} from "../data/search-option/user/UserPolicySearchOption";
import {UserId} from "../data/id/user/UserId";
import {UserPostDataTransferObject} from "../data/data-transfer-object/user/UserPostDataTransferObject";
import {UserPutDataTransferObject} from "../data/data-transfer-object/user/UserPutDataTransferObject";
import {UserValueObject} from "../data/value-object/user/UserValueObject";
import {UserVerboseValueObject} from "../data/value-object/user/UserVerboseValueObject";
import {UserSearchOption} from "../data/search-option/user/UserSearchOption";
import {UserSessionId} from "../data/id/user/UserSessionId";
import {UserSessionValueObject} from "../data/value-object/user/UserSessionValueObject";
import {UserSessionVerboseValueObject} from "../data/value-object/user/UserSessionVerboseValueObject";
import {UserSessionSearchOption} from "../data/search-option/user/UserSessionSearchOption";
import {UserSignInId} from "../data/id/user/UserSignInId";
import {UserSignInPostDataTransferObject} from "../data/data-transfer-object/user/UserSignInPostDataTransferObject";
import {UserSignInValueObject} from "../data/value-object/user/UserSignInValueObject";
import {UserSignInVerboseValueObject} from "../data/value-object/user/UserSignInVerboseValueObject";
import {UserSignInSearchOption} from "../data/search-option/user/UserSignInSearchOption";
import {UserWithdrawalReasonModificationId} from "../data/id/user/UserWithdrawalReasonModificationId";
import {
    UserWithdrawalReasonModificationValueObject
} from "../data/value-object/user/UserWithdrawalReasonModificationValueObject";
import {
    UserWithdrawalReasonModificationVerboseValueObject
} from "../data/value-object/user/UserWithdrawalReasonModificationVerboseValueObject";
import {
    UserWithdrawalReasonModificationSearchOption
} from "../data/search-option/user/UserWithdrawalReasonModificationSearchOption";
import {UserWithdrawalReasonId} from "../data/id/user/UserWithdrawalReasonId";
import {
    UserWithdrawalReasonPostDataTransferObject
} from "../data/data-transfer-object/user/UserWithdrawalReasonPostDataTransferObject";
import {
    UserWithdrawalReasonPutDataTransferObject
} from "../data/data-transfer-object/user/UserWithdrawalReasonPutDataTransferObject";
import {UserWithdrawalReasonValueObject} from "../data/value-object/user/UserWithdrawalReasonValueObject";
import {UserWithdrawalReasonVerboseValueObject} from "../data/value-object/user/UserWithdrawalReasonVerboseValueObject";
import {UserWithdrawalReasonSearchOption} from "../data/search-option/user/UserWithdrawalReasonSearchOption";
import {UserWithdrawalId} from "../data/id/user/UserWithdrawalId";
import {
    UserWithdrawalPostDataTransferObject
} from "../data/data-transfer-object/user/UserWithdrawalPostDataTransferObject";
import {UserWithdrawalValueObject} from "../data/value-object/user/UserWithdrawalValueObject";
import {UserWithdrawalVerboseValueObject} from "../data/value-object/user/UserWithdrawalVerboseValueObject";
import {UserWithdrawalSearchOption} from "../data/search-option/user/UserWithdrawalSearchOption";
import {HttpMethod} from "ts-axios-client-extension";
import {UserBlockId} from "../data/id/user/UserBlockId";
import {UserBlockPostDataTransferObject} from "../data/data-transfer-object/user/UserBlockPostDataTransferObject";
import {UserBlockPutDataTransferObject} from "../data/data-transfer-object/user/UserBlockPutDataTransferObject";
import {UserBlockValueObject} from "../data/value-object/user/UserBlockValueObject";
import {UserBlockVerboseValueObject} from "../data/value-object/user/UserBlockVerboseValueObject";
import {UserBlockSearchOption} from "../data/search-option/user/UserBlockSearchOption";
import {
    UserPatchAdminPermissionDataTransferObject
} from "../data/data-transfer-object/user/UserPatchAdminPermissionDataTransferObject";
import {UserPatchRoleDataTransferObject} from "../data/data-transfer-object/user/UserPatchRoleDataTransferObject";
import {UserExpId} from "../data/id/user/UserExpId";
import {UserExpPostDataTransferObject} from "../data/data-transfer-object/user/UserExpPostDataTransferObject";
import {UserExpValueObject} from "../data/value-object/user/UserExpValueObject";
import {UserExpVerboseValueObject} from "../data/value-object/user/UserExpVerboseValueObject";
import {UserExpSearchOption} from "../data/search-option/user/UserExpSearchOption";
import {UserLevelId} from "../data/id/user/UserLevelId";
import {UserLevelPostDataTransferObject} from "../data/data-transfer-object/user/UserLevelPostDataTransferObject";
import {UserLevelPutDataTransferObject} from "../data/data-transfer-object/user/UserLevelPutDataTransferObject";
import {UserLevelValueObject} from "../data/value-object/user/UserLevelValueObject";
import {UserLevelVerboseValueObject} from "../data/value-object/user/UserLevelVerboseValueObject";
import {UserLevelSearchOption} from "../data/search-option/user/UserLevelSearchOption";
import {UserLevelModificationId} from "../data/id/user/UserLevelModificationId";
import {UserLevelModificationValueObject} from "../data/value-object/user/UserLevelModificationValueObject";
import {
    UserLevelModificationVerboseValueObject
} from "../data/value-object/user/UserLevelModificationVerboseValueObject";
import {UserLevelModificationSearchOption} from "../data/search-option/user/UserLevelModificationSearchOption";
import {UserRankId} from "../data/id/user/UserRankId";
import {
    UserRankPostDataTransferObject
} from "../data/data-transfer-object/user/UserRankPostDataTransferObject";
import {UserRankPutDataTransferObject} from "../data/data-transfer-object/user/UserRankPutDataTransferObject";
import {UserRankValueObject} from "../data/value-object/user/UserRankValueObject";
import {UserRankVerboseValueObject} from "../data/value-object/user/UserRankVerboseValueObject";
import {UserRankSearchOption} from "../data/search-option/user/UserRankSearchOption";
import {UserRankModificationId} from "../data/id/user/UserRankModificationId";
import {UserRankModificationValueObject} from "../data/value-object/user/UserRankModificationValueObject";
import {
    UserRankModificationVerboseValueObject
} from "../data/value-object/user/UserRankModificationVerboseValueObject";
import {UserRankModificationSearchOption} from "../data/search-option/user/UserRankModificationSearchOption";
import {UserLevelHistoryId} from "../data/id/user/UserLevelHistoryId";
import {UserLevelHistoryValueObject} from "../data/value-object/user/UserLevelHistoryValueObject";
import {UserLevelHistoryVerboseValueObject} from "../data/value-object/user/UserLevelHistoryVerboseValueObject";
import {UserLevelHistorySearchOption} from "../data/search-option/user/UserLevelHistorySearchOption";
import {UserRankHistoryId} from "../data/id/user/UserRankHistoryId";
import {UserRankHistoryValueObject} from "../data/value-object/user/UserRankHistoryValueObject";
import {UserRankHistoryVerboseValueObject} from "../data/value-object/user/UserRankHistoryVerboseValueObject";
import {UserRankHistorySearchOption} from "../data/search-option/user/UserRankHistorySearchOption";
import {UserInquiryId} from "../data/id/user/UserInquiryId";
import {UserInquiryPostDataTransferObject} from "../data/data-transfer-object/user/UserInquiryPostDataTransferObject";
import {UserInquiryValueObject} from "../data/value-object/user/UserInquiryValueObject";
import {UserInquiryVerboseValueObject} from "../data/value-object/user/UserInquiryVerboseValueObject";
import {UserInquirySearchOption} from "../data/search-option/user/UserInquirySearchOption";

export const UserAgreementIO = new BaseHttpClient<
    UserAgreementId,
    UserAgreementPostDataTransferObject,
    never,
    UserAgreementValueObject,
    UserAgreementVerboseValueObject,
    UserAgreementSearchOption
>(
    '/users/agreements',
    o => new UserAgreementValueObject(o),
    o => new UserAgreementVerboseValueObject(o)
)

export const UserBlockModificationIO = new BaseHttpClient<
    UserBlockModificationId,
    never,
    never,
    UserBlockModificationValueObject,
    UserBlockModificationVerboseValueObject,
    UserBlockModificationSearchOption
>(
    '/users/blocks/modifications',
    o => new UserBlockModificationValueObject(o),
    o => new UserBlockModificationVerboseValueObject(o)
)

export const UserBlockReasonModificationIO = new BaseHttpClient<
    UserBlockReasonModificationId,
    never,
    never,
    UserBlockReasonModificationValueObject,
    UserBlockReasonModificationVerboseValueObject,
    UserBlockReasonModificationSearchOption
>(
    '/users/blocks/reasons/modifications',
    o => new UserBlockReasonModificationValueObject(o),
    o => new UserBlockReasonModificationVerboseValueObject(o)
)

export const UserBlockReasonIO = new BaseHttpClient<
    UserBlockReasonId,
    UserBlockReasonPostDataTransferObject,
    UserBlockReasonPutDataTransferObject,
    UserBlockReasonValueObject,
    UserBlockReasonVerboseValueObject,
    UserBlockReasonSearchOption
>(
    '/users/blocks/reasons',
    o => new UserBlockReasonValueObject(o),
    o => new UserBlockReasonVerboseValueObject(o)
)

export const UserBlockIO = new BaseHttpClient<
    UserBlockId,
    UserBlockPostDataTransferObject,
    UserBlockPutDataTransferObject,
    UserBlockValueObject,
    UserBlockVerboseValueObject,
    UserBlockSearchOption
>(
    '/users/blocks',
    o => new UserBlockValueObject(o),
    o => new UserBlockVerboseValueObject(o)
)

export const UserExpIO = new BaseHttpClient<
    UserExpId,
    UserExpPostDataTransferObject,
    never,
    UserExpValueObject,
    UserExpVerboseValueObject,
    UserExpSearchOption
>(
    '/users/exps',
    o => new UserExpValueObject(o),
    o => new UserExpVerboseValueObject(o)
)

export const UserInquiryIO = new BaseHttpClient<
    UserInquiryId,
    UserInquiryPostDataTransferObject,
    never,
    UserInquiryValueObject,
    UserInquiryVerboseValueObject,
    UserInquirySearchOption
>(
    '/users/inquiries',
    o => new UserInquiryValueObject(o),
    o => new UserInquiryVerboseValueObject(o)
)

export const UserLevelIO = new BaseHttpClient<
    UserLevelId,
    UserLevelPostDataTransferObject,
    UserLevelPutDataTransferObject,
    UserLevelValueObject,
    UserLevelVerboseValueObject,
    UserLevelSearchOption
>(
    '/users/levels',
    o => new UserLevelValueObject(o),
    o => new UserLevelVerboseValueObject(o)
)

export const UserLevelModificationIO = new BaseHttpClient<
    UserLevelModificationId,
    never,
    never,
    UserLevelModificationValueObject,
    UserLevelModificationVerboseValueObject,
    UserLevelModificationSearchOption
>(
    '/users/levels/modifications',
    o => new UserLevelModificationValueObject(o),
    o => new UserLevelModificationVerboseValueObject(o)
)

export const UserLevelHistoryIO = new BaseHttpClient<
    UserLevelHistoryId,
    never,
    never,
    UserLevelHistoryValueObject,
    UserLevelHistoryVerboseValueObject,
    UserLevelHistorySearchOption
>(
    '/users/levels/histories',
    o => new UserLevelHistoryValueObject(o),
    o => new UserLevelHistoryVerboseValueObject(o)
)

export const UserModificationIO = new BaseHttpClient<
    UserModificationId,
    never,
    never,
    UserModificationValueObject,
    UserModificationVerboseValueObject,
    UserModificationSearchOption
>(
    '/users/modifications',
    o => new UserModificationValueObject(o),
    o => new UserModificationVerboseValueObject(o)
)

export const UserNotificationModificationIO = new BaseHttpClient<
    UserNotificationModificationId,
    never,
    never,
    UserNotificationModificationValueObject,
    UserNotificationModificationVerboseValueObject,
    UserNotificationModificationSearchOption
>(
    '/users/notifications/modifications',
    o => new UserNotificationModificationValueObject(o),
    o => new UserNotificationModificationVerboseValueObject(o)
)

export const UserNotificationIO = new BaseHttpClient<
    UserNotificationId,
    UserNotificationPostDataTransferObject,
    UserNotificationPutDataTransferObject,
    UserNotificationValueObject,
    UserNotificationVerboseValueObject,
    UserNotificationSearchOption
>(
    '/users/notifications',
    o => new UserNotificationValueObject(o),
    o => new UserNotificationVerboseValueObject(o)
)

export const UserPolicyModificationIO = new BaseHttpClient<
    UserPolicyModificationId,
    never,
    never,
    UserPolicyModificationValueObject,
    UserPolicyModificationVerboseValueObject,
    UserPolicyModificationSearchOption
>(
    '/users/policies/modifications',
    o => new UserPolicyModificationValueObject(o),
    o => new UserPolicyModificationVerboseValueObject(o)
)

export const UserPolicyIO = new BaseHttpClient<
    UserPolicyId,
    UserPolicyPostDataTransferObject,
    UserPolicyPutDataTransferObject,
    UserPolicyValueObject,
    UserPolicyVerboseValueObject,
    UserPolicySearchOption
>(
    '/users/policies',
    o => new UserPolicyValueObject(o),
    o => new UserPolicyVerboseValueObject(o)
)

export const UserRankIO = new BaseHttpClient<
    UserRankId,
    UserRankPostDataTransferObject,
    UserRankPutDataTransferObject,
    UserRankValueObject,
    UserRankVerboseValueObject,
    UserRankSearchOption
>(
    '/users/ranks',
    o => new UserRankValueObject(o),
    o => new UserRankVerboseValueObject(o)
)

export const UserRankModificationIO = new BaseHttpClient<
    UserRankModificationId,
    never,
    never,
    UserRankModificationValueObject,
    UserRankModificationVerboseValueObject,
    UserRankModificationSearchOption
>(
    '/users/ranks/modifications',
    o => new UserRankModificationValueObject(o),
    o => new UserRankModificationVerboseValueObject(o)
)

export const UserRankHistoryIO = new BaseHttpClient<
    UserRankHistoryId,
    never,
    never,
    UserRankHistoryValueObject,
    UserRankHistoryVerboseValueObject,
    UserRankHistorySearchOption
>(
    '/users/ranks/histories',
    o => new UserRankHistoryValueObject(o),
    o => new UserRankHistoryVerboseValueObject(o)
)

export const UserIO = new (class _ extends BaseHttpClient<
    UserId,
    UserPostDataTransferObject,
    UserPutDataTransferObject,
    UserValueObject,
    UserVerboseValueObject,
    UserSearchOption
> implements BaseHttpGetCurrent<UserValueObject, UserVerboseValueObject> {
    patchAdminPermission = async (id: UserId, dto: UserPatchAdminPermissionDataTransferObject) => (await this.httpClient
        .prepare({ path: `/users/${id}/adminPermission`, method: HttpMethod.PATCH, config: { data: dto } })
        .bodyAsObject(this.voCtor))
        .result
    patchRole = async (id: UserId, dto: UserPatchRoleDataTransferObject) => (await this.httpClient
        .prepare({ path: `/users/${id}/role`, method: HttpMethod.PATCH, config: { data: dto } })
        .bodyAsObject(this.voCtor))
        .result
    getCurrent = async () => (await this.httpClient
        .prepare({ path: '/users/current', method: HttpMethod.GET })
        .bodyAsObject(o => new UserValueObject(o)))
        .result
    getCurrentVerbose = async () => (await this.httpClient
        .prepare({ path: '/users/current/verbose', method: HttpMethod.GET })
        .bodyAsObject(o => new UserVerboseValueObject(o)))
        .result
})(
    '/users',
    o => new UserValueObject(o),
    o => new UserVerboseValueObject(o)
)

export const UserSessionIO = new BaseHttpClient<
    UserSessionId,
    never,
    never,
    UserSessionValueObject,
    UserSessionVerboseValueObject,
    UserSessionSearchOption
>(
    '/users/sessions',
    o => new UserSessionValueObject(o),
    o => new UserSessionVerboseValueObject(o)
)

export const UserSignInIO = new BaseHttpClient<
    UserSignInId,
    UserSignInPostDataTransferObject,
    never,
    UserSignInValueObject,
    UserSignInVerboseValueObject,
    UserSignInSearchOption
>(
    '/users/signIns',
    o => new UserSignInValueObject(o),
    o => new UserSignInVerboseValueObject(o)
)

export const UserWithdrawalReasonModificationIO = new BaseHttpClient<
    UserWithdrawalReasonModificationId,
    never,
    never,
    UserWithdrawalReasonModificationValueObject,
    UserWithdrawalReasonModificationVerboseValueObject,
    UserWithdrawalReasonModificationSearchOption
>(
    '/users/withdrawal/reasons/modifications',
    o => new UserWithdrawalReasonModificationValueObject(o),
    o => new UserWithdrawalReasonModificationVerboseValueObject(o)
)

export const UserWithdrawalReasonIO = new BaseHttpClient<
    UserWithdrawalReasonId,
    UserWithdrawalReasonPostDataTransferObject,
    UserWithdrawalReasonPutDataTransferObject,
    UserWithdrawalReasonValueObject,
    UserWithdrawalReasonVerboseValueObject,
    UserWithdrawalReasonSearchOption
>(
    '/users/withdrawals/reasons',
    o => new UserWithdrawalReasonValueObject(o),
    o => new UserWithdrawalReasonVerboseValueObject(o)
)

export const UserWithdrawalIO = new (class _ extends BaseHttpClient<
    UserWithdrawalId,
    UserWithdrawalPostDataTransferObject,
    never,
    UserWithdrawalValueObject,
    UserWithdrawalVerboseValueObject,
    UserWithdrawalSearchOption
> {
    getByUserId = async (userId: UserId) => (await this.httpClient
        .prepare({ path: '/users/withdrawals', method: HttpMethod.GET, config: { params: { userId: userId } } })
        .bodyAsObject(this.voCtor)
    ).result
    getVerboseByUserId = async (userId: UserId) => (await this.httpClient
            .prepare({ path: '/users/withdrawals/verbose', method: HttpMethod.GET, config: { params: { userId: userId } } })
            .bodyAsObject(this.voCtor)
    ).result
})(
    '/users/withdrawals',
    o => new UserWithdrawalValueObject(o),
    o => new UserWithdrawalVerboseValueObject(o)
)