import {UserGender} from "../../constant/user/UserGender"
import {UserPermission} from "../../constant/user/UserPermission"
import {UserRole} from "../../constant/user/UserRole"
import {UserStatus} from "../../constant/user/UserStatus"
import {UserModificationDomain} from "../../domain/user/UserModificationDomain"

export const UserModificationDescriptor: Record<keyof UserModificationDomain, (o: UserModificationDomain) => string> = {
    id(o: UserModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserModificationDomain): string {
        return o.creatorId.toString()
    },
    userId(o: UserModificationDomain): string {
        return o.userId.toString()
    },
    role(o: UserModificationDomain): string {
        return UserRole.description(o.role)
    },
    adminPermission(o: UserModificationDomain): string {
        let enabled: string[] = []
        for (let key in UserPermission) {
            const flag = (UserPermission as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.adminPermission & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    email(o: UserModificationDomain): string {
        return o.email
    },
    cloudMessagingToken(o: UserModificationDomain): string {
        return o.cloudMessagingToken ?? '-'
    },
    nickname(o: UserModificationDomain): string {
        return o.nickname
    },
    residenceCountryId(o: UserModificationDomain): string {
        return o.residenceCountryId.toString()
    },
    birth(o: UserModificationDomain): string {
        return o.birth
    },
    gender(o: UserModificationDomain): string {
        return UserGender.description(o.gender)
    },
    phoneCountryId(o: UserModificationDomain): string {
        return o.phoneCountryId?.toString() ?? '-'
    },
    phone(o: UserModificationDomain): string {
        return o.phone ?? '-'
    },
    statusFlags(o: UserModificationDomain): string {
        let enabled: string[] = []
        for (let key in UserStatus) {
            const flag = (UserStatus as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.statusFlags & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    createdAt(o: UserModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
