import {UserRankDuration} from "../../constant/user/UserRankDuration"
import {EnvPolicyModificationDomain} from "../../domain/env/EnvPolicyModificationDomain"
import {EnvPolicyId} from "../../id/env/EnvPolicyId"
import {EnvPolicyModificationId} from "../../id/env/EnvPolicyModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 환경 정책 수정 이력
 */
export class EnvPolicyModificationValueObject implements ValueObject, EnvPolicyModificationDomain {
    /**
     * 기본키
     */
    id: EnvPolicyModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 환경 정책 ID
     */
    envPolicyId: EnvPolicyId
    /**
     * Admin 페이지 크기
     */
    envAdminPageSize: number
    /**
     * 이용 약관 페이지 크기
     */
    envAgreementPageSize: number
    /**
     * 은행 페이지 크기
     */
    envBankPageSize: number
    /**
     * 클라이언트 페이지 크기
     */
    envClientPageSize: number
    /**
     * 작업 페이지 크기
     */
    envConstructionPageSize: number
    /**
     * 국가 페이지 크기
     */
    envCountryPageSize: number
    /**
     * 국가 랭크 집계 기간
     */
    envCountryRankDuration: UserRankDuration
    /**
     * 이벤트 페이지 크기
     */
    envEventPageSize: number
    /**
     * 자주 묻는 질문 페이지 크기
     */
    envFaqPageSize: number
    /**
     * 자주 묻는 질문 항목 페이지 크기
     */
    envFaqItemPageSize: number
    /**
     * 공지사항 페이지 크기
     */
    envNoticePageSize: number
    /**
     * 전화번호 차단 페이지 크기
     */
    envPhoneBlockPageSize: number
    /**
     * 환경 정책 페이지 크기
     */
    envPolicyPageSize: number
    /**
     * AWS S3 키 페이지 크기
     */
    envStorageKeyPageSize: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvPolicyModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envPolicyId = BigInt(o.envPolicyId)
        this.envAdminPageSize = Number(o.envAdminPageSize)
        this.envAgreementPageSize = Number(o.envAgreementPageSize)
        this.envBankPageSize = Number(o.envBankPageSize)
        this.envClientPageSize = Number(o.envClientPageSize)
        this.envConstructionPageSize = Number(o.envConstructionPageSize)
        this.envCountryPageSize = Number(o.envCountryPageSize)
        this.envCountryRankDuration = o.envCountryRankDuration as UserRankDuration
        this.envEventPageSize = Number(o.envEventPageSize)
        this.envFaqPageSize = Number(o.envFaqPageSize)
        this.envFaqItemPageSize = Number(o.envFaqItemPageSize)
        this.envNoticePageSize = Number(o.envNoticePageSize)
        this.envPhoneBlockPageSize = Number(o.envPhoneBlockPageSize)
        this.envPolicyPageSize = Number(o.envPolicyPageSize)
        this.envStorageKeyPageSize = Number(o.envStorageKeyPageSize)
        this.createdAt = new Date(o.createdAt)
    }
}
