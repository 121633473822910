import Detail from "./Detail";
import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Post from "./Post";
import Edit from "./Edit";
import ItemRouter from "./item/ItemRouter";

export default function FaqRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/post'} element={<Post {...props} />} />
            <Route path={'/:envFaqId'} element={<Detail {...props} />} />
            <Route path={'/:envFaqId/edit'} element={<Edit {...props} />} />
            <Route path={'/items/*'} element={<ItemRouter {...props} />} />
            <Route path={'/:envFaqId/items/*'} element={<ItemRouter {...props} />} />
        </Routes>
    </>
}