import {AssetStarExchangeOptionVerboseDomain} from "../../domain/asset/AssetStarExchangeOptionVerboseDomain"
import {AssetStarExchangeOptionId} from "../../id/asset/AssetStarExchangeOptionId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 스타 교환 옵션 상세
 */
export class AssetStarExchangeOptionVerboseValueObject implements VerboseValueObject, AssetStarExchangeOptionVerboseDomain {
    /**
     * 기본키
     */
    id: AssetStarExchangeOptionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 지급 스타
     */
    starAmount: number
    /**
     * 차감 솔라
     */
    solarAmount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof AssetStarExchangeOptionVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.starAmount = Number(o.starAmount)
        this.solarAmount = Number(o.solarAmount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
