import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserPolicyValueObject} from "../../../data/value-object/user/UserPolicyValueObject";
import {UserPolicySearchOption} from "../../../data/search-option/user/UserPolicySearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {UserPolicyIO} from "../../../io/UserIO";
import {UserPolicyDescription} from "../../../data/description/user/UserPolicyDescription";
import {UserPolicyDescriptor} from "../../../data/descriptor/user/UserPolicyDescriptor";
import ListOption from "../../ListOption";
import {UserPolicySearchOptionDescription} from "../../../data/description/user/UserPolicySearchOptionDescription";
import {
    applyUserPolicySearchOption,
    UserPolicySearchOptionField
} from "../../search-option-field/user/UserPolicySearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserPolicyValueObject[]>([])
    const option = useStatePair<UserPolicySearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserPolicyIO}
                items={items}
                option={option.value}
                index={index}
                description={UserPolicyDescription}
                descriptor={UserPolicyDescriptor}
                showPost
                onPostClick={() => document.location = '/users/policies/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/policies/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserPolicySearchOptionDescription}
                fields={UserPolicySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserPolicySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserPolicyDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}