export function insertDatePunctuation(value: string) {
    const plain = value.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', '')
    if (plain.length <= 4) {
        return plain
    } else if (plain.length <= 6) {
        return plain.substring(0, 4) + '-' + plain.substring(4, plain.length)
    } else if (plain.length <= 8) {
        return plain.substring(0, 4) + '-' + plain.substring(4, 6) + '-' + plain.substring(6, plain.length)
    } else if (plain.length <= 10) {
        return plain.substring(0, 4) + '-' + plain.substring(4, 6) + '-' + plain.substring(6, 8) + ' ' + plain.substring(8, plain.length)
    } else if (plain.length <= 12) {
        return plain.substring(0, 4) + '-' + plain.substring(4, 6) + '-' + plain.substring(6, 8) + ' ' + plain.substring(8, 10) + ':' + plain.substring(10, plain.length)
    } else {
        return plain.substring(0, 4) + '-' + plain.substring(4, 6) + '-' + plain.substring(6, 8) + ' ' + plain.substring(8, 10) + ':' + plain.substring(10, 12) + ':' + plain.substring(12, plain.length)
    }
}