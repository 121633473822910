import {RouterProps} from "../../../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Post from "./Post";
import Detail from "./Detail";

export default function UsageRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/post'} element={<Post {...props} />} />
            <Route path={'/:assetSolarUsageId'} element={<Detail {...props} />} />
        </Routes>
    </>
}