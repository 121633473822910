import {AssetStarExchangeOptionPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetStarExchangeOptionPostDataTransferObjectDescription"
import {AssetStarExchangeOptionPostState} from "../../../data/data-transfer-object-state/asset/AssetStarExchangeOptionPostState"
import {AssetStarExchangeOptionPostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetStarExchangeOptionPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetStarExchangeOptionPostState
}

export const AssetStarExchangeOptionPostField: Record<Capitalize<keyof AssetStarExchangeOptionPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    StarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetStarExchangeOptionPostDataTransferObjectDescription.starAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.starAmount.value}
                    setter={props.state.starAmount.setter} />
            </PostFieldWrapper>
        )
    },
    SolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetStarExchangeOptionPostDataTransferObjectDescription.solarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.solarAmount.value}
                    setter={props.state.solarAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetStarExchangeOptionPostFields(props: PostFieldProps) {
    return <>
        <AssetStarExchangeOptionPostField.StarAmount
            {...props}
            state={props.state} />
        <AssetStarExchangeOptionPostField.SolarAmount
            {...props}
            state={props.state} />
    </>
}
