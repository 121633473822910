import {EnvBankPostDataTransferObject} from "../../data-transfer-object/env/EnvBankPostDataTransferObject"
import {EnvBankValueObject} from "../../value-object/env/EnvBankValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type EnvBankPostState = {
    name: StatePair<string>
    displayOrder: StatePair<number>
    toDataTransferObject(): EnvBankPostDataTransferObject
    fromValueObject(vo: EnvBankValueObject): void
    values(): [string, number]
}

export function useEnvBankPostState(): EnvBankPostState {
    return {
        name: useStatePair<string>(''),
        displayOrder: useStatePair<number>(0),
        toDataTransferObject(): EnvBankPostDataTransferObject {
            return {
                name: this.name.value,
                displayOrder: this.displayOrder.value,
            }
        },
        fromValueObject(vo: EnvBankValueObject) {
            this.name.setter(vo.name)
            this.displayOrder.setter(vo.displayOrder)
        },
        values(): [string, number] {
            return [
                this.name.value,
                this.displayOrder.value,
            ]
        }
    }
}
