import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserLevelValueObject} from "../../../data/value-object/user/UserLevelValueObject";
import {useEffect} from "react";
import {UserLevelHistoryIO, UserLevelIO} from "../../../io/UserIO";
import {Body1, Spinner, Tab, TabList} from "@fluentui/react-components";
import {UserLevelDomain} from "../../../data/domain/user/UserLevelDomain";
import {UserLevelDescriptor} from "../../../data/descriptor/user/UserLevelDescriptor";
import {UserLevelDescription} from "../../../data/description/user/UserLevelDescription";
import {DetailDefaultPanel} from "../../DetailViews";
import {UserLevelHistoryValueObject} from "../../../data/value-object/user/UserLevelHistoryValueObject";
import {UserLevelHistorySearchOption} from "../../../data/search-option/user/UserLevelHistorySearchOption";
import {searchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {UserLevelHistoryDescription} from "../../../data/description/user/UserLevelHistoryDescription";
import {UserLevelHistoryDescriptor} from "../../../data/descriptor/user/UserLevelHistoryDescriptor";

enum Panel {
    Default,
    UserLevelHistoryList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userLevelId: string }>()
    const item = useStatePair<UserLevelValueObject | undefined>(undefined)

    useEffect(() => {
        if (params.userLevelId !== undefined) {
            UserLevelIO
                .getById(BigInt(params.userLevelId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserLevelValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserLevelHistoryList}>사용자 레벨 이력 목록</Tab>
            </TabList>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UserLevelIO}
                description={UserLevelDescription}
                properties={props => <PropertyBody {...props} />}/>
            <UserLevelHistoryListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserLevelDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "name":
        case "exp":
        case "rewardAssetLunarAmount":
        case "rewardAssetSolarAmount":
        case "rewardAssetStarAmount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{UserLevelDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserLevelDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function UserLevelHistoryListPanel(props: PanelProps) {
    const items = useStatePair<UserLevelHistoryValueObject[]>([])
    const option: UserLevelHistorySearchOption = { userLevelIdEq: props.item.id }
    const index = useStatePair(searchIndex())

    return <>
        {props.panel === Panel.UserLevelHistoryList && <>
            <ListPageWrapper
                {...props}
                client={UserLevelHistoryIO}
                items={items}
                option={option}
                index={index}
                description={UserLevelHistoryDescription}
                descriptor={UserLevelHistoryDescriptor}
                onItemClick={item => `/users/levels/histories/${item.id}`} />
        </>}
    </>
}

//function ContentModificationListPanel(props: PanelProps) {
//     const modifications = useStatePair<ContentModificationValueObject[]>([])
//     const modificationSearchOption: ContentModificationSearchOption = { contentIdEq: props.item.id }
//     const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })
//
//     return <>
//         {props.panel === Panel.ModificationList && <>
//             <ListPageWrapper
//                 {...props}
//                 client={ContentModificationIO}
//                 items={modifications}
//                 option={modificationSearchOption}
//                 index={modificationSearchIndex}
//                 description={ContentModificationDescription}
//                 descriptor={ContentModificationDescriptor} />
//         </>}
//     </>
// }