import {UiHomeContentSuggestionDomain} from "../../domain/ui/UiHomeContentSuggestionDomain"

/**
 * 홈 추천 콘텐츠 속성 설명
 */
export const UiHomeContentSuggestionDescription: Record<keyof UiHomeContentSuggestionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    displayOrder: '표시 우선 순위',
    url: 'URL',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
