import {RouterProps} from "../RouterProps";
import {Route, Routes} from "react-router-dom";
import BlockRouter from "./block/BlockRouter";
import PolicyRouter from "./policy/PolicyRouter";
import AgreementRouter from "./agreement/AgreementRouter";
import NotificationRouter from "./notification/NotificationRouter";
import SessionRouter from "./session/SessionRouter";
import SignInRouter from "./signIn/SignInRouter";
import WithdrawalRouter from "./withdrawal/WithdrawalRouter";
import List from "./List";
import Detail from "./Detail";
import Edit from "./Edit";
import EditAdminPermission from "./EditAdminPermission";
import EditRole from "./EditRole";
import ExpRouter from "./exp/ExpRouter";
import LevelRouter from "./level/LevelRouter";
import RankRouter from "./rank/RankRouter";
import InquiryRouter from "./inquiry/InquiryRouter";

export default function UserRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/:userId'} element={<Detail {...props} />} />
            <Route path={'/:userId/edit'} element={<Edit {...props} />} />
            <Route path={'/:userId/edit/adminPermission'} element={<EditAdminPermission {...props} />} />
            <Route path={'/:userId/edit/role'} element={<EditRole {...props} />} />
            <Route path={'/agreements/*'} element={<AgreementRouter {...props} />} />
            <Route path={'/blocks/*'} element={<BlockRouter {...props} />} />
            <Route path={'/exps/*'} element={<ExpRouter {...props} />} />
            <Route path={'/inquiries/*'} element={<InquiryRouter {...props} />} />
            <Route path={'/levels/*'} element={<LevelRouter {...props} />} />
            <Route path={'/notifications/*'} element={<NotificationRouter {...props} />} />
            <Route path={'/policies/*'} element={<PolicyRouter {...props} />} />
            <Route path={'/ranks/*'} element={<RankRouter {...props} />} />
            <Route path={'/sessions/*'} element={<SessionRouter {...props} />} />
            <Route path={'/signIns/*'} element={<SignInRouter {...props} />} />
            <Route path={'/withdrawals/*'} element={<WithdrawalRouter {...props} />} />
        </Routes>
    </>
}