import {ContentDetailUrlClickSearchOption} from "../../search-option/content/ContentDetailUrlClickSearchOption"

/**
 * 콘텐츠 더 알아보기 URL 클릭 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentDetailUrlClickSearchOptionDescription: Record<keyof ContentDetailUrlClickSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    contentDetailUrlIdEq: '콘텐츠 더 알아보기 URL ID 일치',
    contentDetailUrlIdNeq: '콘텐츠 더 알아보기 URL ID 불일치',
    contentDetailUrlIdMin: '콘텐츠 더 알아보기 URL ID 최소',
    contentDetailUrlIdMax: '콘텐츠 더 알아보기 URL ID 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
