import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentPolicyDomain} from "../../domain/content/ContentPolicyDomain"

export const ContentPolicyDescriptor: Record<keyof ContentPolicyDomain, (o: ContentPolicyDomain) => string> = {
    id(o: ContentPolicyDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentPolicyDomain): string {
        return o.creatorId.toString()
    },
    contentPageSize(o: ContentPolicyDomain): string {
        return o.contentPageSize.toString()
    },
    contentReportAutoHiddenCount(o: ContentPolicyDomain): string {
        return o.contentReportAutoHiddenCount.toString()
    },
    contentCommentPageSize(o: ContentPolicyDomain): string {
        return o.contentCommentPageSize.toString()
    },
    contentCommentWithAudioSolarAmount(o: ContentPolicyDomain): string {
        return o.contentCommentWithAudioSolarAmount.toString()
    },
    contentCommentReportAutoHiddenCount(o: ContentPolicyDomain): string {
        return o.contentCommentReportAutoHiddenCount.toString()
    },
    contentCommentThresholdMinutes(o: ContentPolicyDomain): string {
        return o.contentCommentThresholdMinutes.toString()
    },
    contentCommentThresholdCount(o: ContentPolicyDomain): string {
        return o.contentCommentThresholdCount.toString()
    },
    contentCommentLikePageSize(o: ContentPolicyDomain): string {
        return o.contentCommentLikePageSize.toString()
    },
    contentCommentReportPageSize(o: ContentPolicyDomain): string {
        return o.contentCommentReportPageSize.toString()
    },
    contentCommentReportReasonPageSize(o: ContentPolicyDomain): string {
        return o.contentCommentReportReasonPageSize.toString()
    },
    contentParticipationPageSize(o: ContentPolicyDomain): string {
        return o.contentParticipationPageSize.toString()
    },
    contentDailyParticipationExp(o: ContentPolicyDomain): string {
        return o.contentDailyParticipationExp.toString()
    },
    contentDailyParticipationPassExp(o: ContentPolicyDomain): string {
        return o.contentDailyParticipationPassExp.toString()
    },
    contentRepeatParticipationThresholdMinutes(o: ContentPolicyDomain): string {
        return o.contentRepeatParticipationThresholdMinutes.toString()
    },
    contentRepeatParticipationThresholdCount(o: ContentPolicyDomain): string {
        return o.contentRepeatParticipationThresholdCount.toString()
    },
    contentRepeatParticipationExp(o: ContentPolicyDomain): string {
        return o.contentRepeatParticipationExp.toString()
    },
    contentRepeatParticipationPassExp(o: ContentPolicyDomain): string {
        return o.contentRepeatParticipationPassExp.toString()
    },
    contentM10nParticipationThresholdMinutes(o: ContentPolicyDomain): string {
        return o.contentM10nParticipationThresholdMinutes.toString()
    },
    contentM10nParticipationThresholdCount(o: ContentPolicyDomain): string {
        return o.contentM10nParticipationThresholdCount.toString()
    },
    contentM10nParticipationExp(o: ContentPolicyDomain): string {
        return o.contentM10nParticipationExp.toString()
    },
    contentM10nParticipationPassExp(o: ContentPolicyDomain): string {
        return o.contentM10nParticipationPassExp.toString()
    },
    contentUserProductionParticipationThresholdMinutes(o: ContentPolicyDomain): string {
        return o.contentUserProductionParticipationThresholdMinutes.toString()
    },
    contentUserProductionParticipationThresholdCount(o: ContentPolicyDomain): string {
        return o.contentUserProductionParticipationThresholdCount.toString()
    },
    contentUserProductionParticipationExp(o: ContentPolicyDomain): string {
        return o.contentUserProductionParticipationExp.toString()
    },
    contentUserProductionParticipationPassExp(o: ContentPolicyDomain): string {
        return o.contentUserProductionParticipationPassExp.toString()
    },
    contentUserProductionPresetPageSize(o: ContentPolicyDomain): string {
        return o.contentUserProductionPresetPageSize.toString()
    },
    contentUserProductionMinimumAssetLunarAmount(o: ContentPolicyDomain): string {
        return o.contentUserProductionMinimumAssetLunarAmount.toString()
    },
    contentUserProductionMinimumUserLevelId(o: ContentPolicyDomain): string {
        return o.contentUserProductionMinimumUserLevelId.toString()
    },
    contentUserProductionMinimumAccuracy(o: ContentPolicyDomain): string {
        return o.contentUserProductionMinimumAccuracy.toString()
    },
    contentUserProductionPassAccuracy(o: ContentPolicyDomain): string {
        return o.contentUserProductionPassAccuracy.toString()
    },
    contentUserProductionPassHighAccuracy(o: ContentPolicyDomain): string {
        return o.contentUserProductionPassHighAccuracy.toString()
    },
    contentUserProductionPassSolarAmountHighAccuracy(o: ContentPolicyDomain): string {
        return o.contentUserProductionPassSolarAmountHighAccuracy.toString()
    },
    contentUserProductionStarAmount(o: ContentPolicyDomain): string {
        return o.contentUserProductionStarAmount.toString()
    },
    contentUserProductionDefaultDetailUrl(o: ContentPolicyDomain): string {
        return o.contentUserProductionDefaultDetailUrl
    },
    contentUserProductionDefaultDetailUrlBrowser(o: ContentPolicyDomain): string {
        return EnvBrowser.description(o.contentUserProductionDefaultDetailUrlBrowser)
    },
    contentUserProductionDefaultResultUrl(o: ContentPolicyDomain): string {
        return o.contentUserProductionDefaultResultUrl
    },
    contentUserProductionDefaultResultUrlBrowser(o: ContentPolicyDomain): string {
        return EnvBrowser.description(o.contentUserProductionDefaultResultUrlBrowser)
    },
    contentUserProductionScriptPageSize(o: ContentPolicyDomain): string {
        return o.contentUserProductionScriptPageSize.toString()
    },
    contentLikePageSize(o: ContentPolicyDomain): string {
        return o.contentLikePageSize.toString()
    },
    contentPolicyPageSize(o: ContentPolicyDomain): string {
        return o.contentPolicyPageSize.toString()
    },
    contentQueryPageSize(o: ContentPolicyDomain): string {
        return o.contentQueryPageSize.toString()
    },
    contentQueryPopularDurationMinutes(o: ContentPolicyDomain): string {
        return o.contentQueryPopularDurationMinutes.toString()
    },
    contentQueryPopularPageSize(o: ContentPolicyDomain): string {
        return o.contentQueryPopularPageSize.toString()
    },
    contentReportReasonPageSize(o: ContentPolicyDomain): string {
        return o.contentReportReasonPageSize.toString()
    },
    contentReportPageSize(o: ContentPolicyDomain): string {
        return o.contentReportPageSize.toString()
    },
    contentReportDailyThreshold(o: ContentPolicyDomain): string {
        return o.contentReportDailyThreshold.toString()
    },
    contentDetailUrlPageSize(o: ContentPolicyDomain): string {
        return o.contentDetailUrlPageSize.toString()
    },
    contentDetailUrlClickPageSize(o: ContentPolicyDomain): string {
        return o.contentDetailUrlClickPageSize.toString()
    },
    contentDetailUrlClickExp(o: ContentPolicyDomain): string {
        return o.contentDetailUrlClickExp.toString()
    },
    contentResultUrlPageSize(o: ContentPolicyDomain): string {
        return o.contentResultUrlPageSize.toString()
    },
    contentResultUrlClickPageSize(o: ContentPolicyDomain): string {
        return o.contentResultUrlClickPageSize.toString()
    },
    contentResultUrlClickExp(o: ContentPolicyDomain): string {
        return o.contentResultUrlClickExp.toString()
    },
    contentReviewPageSize(o: ContentPolicyDomain): string {
        return o.contentReviewPageSize.toString()
    },
    contentTargetPageSize(o: ContentPolicyDomain): string {
        return o.contentTargetPageSize.toString()
    },
    createdAt(o: ContentPolicyDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentPolicyDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
