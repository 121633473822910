import {ContentUserProductionDurationVerboseDomain} from "../../domain/content/ContentUserProductionDurationVerboseDomain"
import {ContentUserProductionDurationId} from "../../id/content/ContentUserProductionDurationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 와글와글 기간 프리셋 상세
 */
export class ContentUserProductionDurationVerboseValueObject implements VerboseValueObject, ContentUserProductionDurationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentUserProductionDurationId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 기간(주)
     */
    value: number
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof ContentUserProductionDurationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.value = Number(o.value)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
