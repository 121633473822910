import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {PostEnumField, PostFieldWrapper, PostFinishDialog, PostInputField, PostNumberField} from "../../PostField";
import {EnvStorageKeyDescription} from "../../../data/description/env/EnvStorageKeyDescription";
import {EnvStorageKeyIO} from "../../../io/EnvIO";
import {EnvClientDistribution} from "../../../data/constant/env/EnvClientDistribution";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const accessKeyId = useStatePair('')
    const secretAccessKey = useStatePair('')
    const bucket = useStatePair('')
    const region = useStatePair('')
    const distribution = useStatePair<EnvClientDistribution>(EnvClientDistribution.LocalTest)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvStorageKeyIO
            .post({
                accessKeyId: accessKeyId.value,
                secretAccessKey: secretAccessKey.value,
                bucket: bucket.value,
                region: region.value,
                distribution: distribution.value,
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [accessKeyId.value, secretAccessKey.value, bucket.value, region.value, distribution.value,])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvStorageKeyDescription.accessKeyId}>
                    <PostInputField
                        {...props}
                        value={accessKeyId.value}
                        setter={accessKeyId.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvStorageKeyDescription.secretAccessKey}>
                    <PostInputField
                        {...props}
                        value={secretAccessKey.value}
                        setter={secretAccessKey.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvStorageKeyDescription.bucket}>
                    <PostInputField
                        {...props}
                        value={bucket.value}
                        setter={bucket.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvStorageKeyDescription.region}>
                    <PostInputField
                        {...props}
                        value={region.value}
                        setter={region.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvStorageKeyDescription.distribution}>
                    <PostEnumField
                        {...props}
                        value={distribution.value}
                        setter={distribution.setter}
                        selections={EnvClientDistribution.selections}/>
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/storageKeys'}/>
    </>
}