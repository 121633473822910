import {AssetStarUsageSearchOption} from "../../search-option/asset/AssetStarUsageSearchOption"

/**
 * 스타 사용 및 지급 이력 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const AssetStarUsageSearchOptionDescription: Record<keyof AssetStarUsageSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    typeEq: '유형 일치',
    typeNeq: '유형 불일치',
    typeMin: '유형 최소',
    typeMax: '유형 최대',
    typeIn: '유형 포함',
    amountEq: '변화 일치',
    amountNeq: '변화 불일치',
    amountMin: '변화 최소',
    amountMax: '변화 최대',
    lastAccumulationEq: '직전 누계 일치',
    lastAccumulationNeq: '직전 누계 불일치',
    lastAccumulationMin: '직전 누계 최소',
    lastAccumulationMax: '직전 누계 최대',
    positiveAccumulationEq: '지급 누계 일치',
    positiveAccumulationNeq: '지급 누계 불일치',
    positiveAccumulationMin: '지급 누계 최소',
    positiveAccumulationMax: '지급 누계 최대',
    negativeAccumulationEq: '사용 누계 일치',
    negativeAccumulationNeq: '사용 누계 불일치',
    negativeAccumulationMin: '사용 누계 최소',
    negativeAccumulationMax: '사용 누계 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
