export enum EnvClientDistribution {
    /**
     * 개발 테스트
     */
    LocalTest = 'LocalTest',

    /**
     * 내부 테스트
     */
    InnerTest = 'InnerTest',

    /**
     * 공개 테스트
     */
    OpenTest = 'OpenTest',

    /**
     * 배포
     */
    Release = 'Release'
}

export namespace EnvClientDistribution {
    export function description(o: EnvClientDistribution) {
        switch (o) {
            case EnvClientDistribution.LocalTest: return '개발 테스트'
            case EnvClientDistribution.InnerTest: return '내부 테스트'
            case EnvClientDistribution.OpenTest: return '공개 테스트'
            case EnvClientDistribution.Release: return '배포'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: EnvClientDistribution.LocalTest, label: description(EnvClientDistribution.LocalTest) },
        { value: EnvClientDistribution.InnerTest, label: description(EnvClientDistribution.InnerTest) },
        { value: EnvClientDistribution.OpenTest, label: description(EnvClientDistribution.OpenTest) },
        { value: EnvClientDistribution.Release, label: description(EnvClientDistribution.Release) }
    ]
}
