import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {EnvCountryIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField, PostNumberField
} from "../../PostField";
import {EnvCountryDescription} from "../../../data/description/env/EnvCountryDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const name = useStatePair('')
    const isoCode = useStatePair('')
    const phoneCode = useStatePair(0)
    const emoji = useStatePair('')

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvCountryIO
            .post({
                name: name.value,
                isoCode: isoCode.value,
                phoneCode: phoneCode.value,
                emoji: emoji.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [name.value, phoneCode.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvCountryDescription.name}>
                    <PostInputField
                        {...props}
                        value={name.value}
                        setter={name.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvCountryDescription.isoCode}>
                    <PostInputField
                        {...props}
                        value={isoCode.value}
                        setter={isoCode.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvCountryDescription.phoneCode}>
                    <PostNumberField
                        {...props}
                        value={phoneCode.value}
                        setter={phoneCode.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvCountryDescription.emoji}>
                    <PostInputField
                        {...props}
                        value={emoji.value}
                        setter={emoji.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/countries'}/>
    </>
}