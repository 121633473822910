import {UserSignInSearchOptionDescription} from "../../../data/description/user/UserSignInSearchOptionDescription"
import {UserSignInSearchOption} from "../../../data/search-option/user/UserSignInSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function UserSignInSearchOptionField(props: ListOptionFieldProps<UserSignInSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.envClientIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.envClientIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.envClientIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envClientIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.envClientIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "remoteAddressEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.remoteAddressEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "remoteAddressNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.remoteAddressNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "remoteAddressMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.remoteAddressMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "remoteAddressMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.remoteAddressMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "remoteAddressLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.remoteAddressLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "remoteAddressNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.remoteAddressNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceNameEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceNameEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceNameNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceNameNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceNameMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceNameMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceNameMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceNameMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceNameLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceNameLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceNameNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceNameNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceVersionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceVersionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceVersionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceVersionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceVersionMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceVersionMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceVersionMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceVersionMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceVersionLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceVersionLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deviceVersionNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.deviceVersionNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserSignInSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserSignInSearchOption(key: keyof UserSignInSearchOption, value: string, into: StatePair<UserSignInSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "envClientIdEq":
            into.setter({
                ...into.value,
                envClientIdEq: BigInt(value)
            })
            break
        case "envClientIdNeq":
            into.setter({
                ...into.value,
                envClientIdNeq: BigInt(value)
            })
            break
        case "envClientIdMin":
            into.setter({
                ...into.value,
                envClientIdMin: BigInt(value)
            })
            break
        case "envClientIdMax":
            into.setter({
                ...into.value,
                envClientIdMax: BigInt(value)
            })
            break
        case "remoteAddressEq":
            into.setter({
                ...into.value,
                remoteAddressEq: String(value)
            })
            break
        case "remoteAddressNeq":
            into.setter({
                ...into.value,
                remoteAddressNeq: String(value)
            })
            break
        case "remoteAddressMin":
            into.setter({
                ...into.value,
                remoteAddressMin: String(value)
            })
            break
        case "remoteAddressMax":
            into.setter({
                ...into.value,
                remoteAddressMax: String(value)
            })
            break
        case "remoteAddressLike":
            into.setter({
                ...into.value,
                remoteAddressLike: String(value)
            })
            break
        case "remoteAddressNotLike":
            into.setter({
                ...into.value,
                remoteAddressNotLike: String(value)
            })
            break
        case "deviceNameEq":
            into.setter({
                ...into.value,
                deviceNameEq: String(value)
            })
            break
        case "deviceNameNeq":
            into.setter({
                ...into.value,
                deviceNameNeq: String(value)
            })
            break
        case "deviceNameMin":
            into.setter({
                ...into.value,
                deviceNameMin: String(value)
            })
            break
        case "deviceNameMax":
            into.setter({
                ...into.value,
                deviceNameMax: String(value)
            })
            break
        case "deviceNameLike":
            into.setter({
                ...into.value,
                deviceNameLike: String(value)
            })
            break
        case "deviceNameNotLike":
            into.setter({
                ...into.value,
                deviceNameNotLike: String(value)
            })
            break
        case "deviceVersionEq":
            into.setter({
                ...into.value,
                deviceVersionEq: String(value)
            })
            break
        case "deviceVersionNeq":
            into.setter({
                ...into.value,
                deviceVersionNeq: String(value)
            })
            break
        case "deviceVersionMin":
            into.setter({
                ...into.value,
                deviceVersionMin: String(value)
            })
            break
        case "deviceVersionMax":
            into.setter({
                ...into.value,
                deviceVersionMax: String(value)
            })
            break
        case "deviceVersionLike":
            into.setter({
                ...into.value,
                deviceVersionLike: String(value)
            })
            break
        case "deviceVersionNotLike":
            into.setter({
                ...into.value,
                deviceVersionNotLike: String(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
