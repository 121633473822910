import ListPageWrapper from "../../ListPageWrapper";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentCommentValueObject} from "../../../data/value-object/content/ContentCommentValueObject";
import {ContentCommentSearchOption} from "../../../data/search-option/content/ContentCommentSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import {ContentCommentIO} from "../../../io/ContentIO";
import {ContentCommentDescription} from "../../../data/description/content/ContentCommentDescription";
import {ContentCommentDescriptor} from "../../../data/descriptor/content/ContentCommentDescriptor";
import ListOption from "../../ListOption";
import {
    ContentCommentSearchOptionDescription
} from "../../../data/description/content/ContentCommentSearchOptionDescription";
import {
    applyContentCommentSearchOption,
    ContentCommentSearchOptionField
} from "../../search-option-field/content/ContentCommentSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentCommentValueObject[]>([])
    const option = useStatePair<ContentCommentSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentCommentDescription}
                descriptor={ContentCommentDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/comments/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentCommentSearchOptionDescription}
                fields={ContentCommentSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentCommentSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentCommentDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}