import {ContentReportReasonModificationDomain} from "../../domain/content/ContentReportReasonModificationDomain"

export const ContentReportReasonModificationDescriptor: Record<keyof ContentReportReasonModificationDomain, (o: ContentReportReasonModificationDomain) => string> = {
    id(o: ContentReportReasonModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentReportReasonModificationDomain): string {
        return o.creatorId.toString()
    },
    contentReportReasonId(o: ContentReportReasonModificationDomain): string {
        return o.contentReportReasonId.toString()
    },
    body(o: ContentReportReasonModificationDomain): string {
        return o.body
    },
    displayOrder(o: ContentReportReasonModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: ContentReportReasonModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
