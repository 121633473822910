import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {AssetLunarExchangeOptionIO, AssetLunarUsageIO} from "../../../../io/AssetIO";
import {Body1, Caption1Strong, Spinner} from "@fluentui/react-components";
import {mapByKey} from "../../../../util/ObjectUtil";
import {
    AssetLunarUsageDescription
} from "../../../../data/description/asset/AssetLunarUsageDescription";
import {AssetLunarUsageDomain} from "../../../../data/domain/asset/AssetLunarUsageDomain";
import {AssetLunarUsageDescriptor} from "../../../../data/descriptor/asset/AssetLunarUsageDescriptor";
import {AssetLunarUsageValueObject} from "../../../../data/value-object/asset/AssetLunarUsageValueObject";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {
    AssetLunarExchangeOptionDescription
} from "../../../../data/description/asset/AssetLunarExchangeOptionDescription";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetLunarUsageId: string }>()
    const item = useStatePair<AssetLunarUsageValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetLunarUsageId !== undefined) {
            AssetLunarUsageIO
                .getById(BigInt(params.assetLunarUsageId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetLunarUsageValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetLunarUsageIO}
                description={AssetLunarUsageDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetLunarUsageDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "type":
        case "amount":
        case "lastAccumulation":
        case "positiveAccumulation":
        case "negativeAccumulation":
        case "createdAt":
            return <Body1>{AssetLunarUsageDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{AssetLunarUsageDescriptor[props.itemKey](props.item)}</Body1></a>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetLunarUsageDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}