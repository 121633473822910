import {UserWithdrawalReasonModificationVerboseDomain} from "../../domain/user/UserWithdrawalReasonModificationVerboseDomain"
import {UserId} from "../../id/user/UserId"
import {UserWithdrawalReasonId} from "../../id/user/UserWithdrawalReasonId"
import {UserWithdrawalReasonModificationId} from "../../id/user/UserWithdrawalReasonModificationId"
import {UserValueObject} from "./UserValueObject"
import {UserWithdrawalReasonValueObject} from "./UserWithdrawalReasonValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 탈퇴 사유 수정 이력 상세
 */
export class UserWithdrawalReasonModificationVerboseValueObject implements VerboseValueObject, UserWithdrawalReasonModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UserWithdrawalReasonModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 탈퇴 사유 ID
     */
    userWithdrawalReasonId: UserWithdrawalReasonId
    /**
     * 사유
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 탈퇴 사유 ID
     */
    userWithdrawalReason: UserWithdrawalReasonValueObject

    constructor(o: Record<keyof UserWithdrawalReasonModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userWithdrawalReasonId = BigInt(o.userWithdrawalReasonId)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.userWithdrawalReason = new UserWithdrawalReasonValueObject(o.userWithdrawalReason)
    }
}
