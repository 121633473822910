import {RouterProps} from "./RouterProps";
import {Button, Image, makeStyles, mergeClasses, Spinner} from "@fluentui/react-components";
import {ChevronLeft16Filled, ChevronRight16Filled, Dismiss16Filled} from "@fluentui/react-icons";
import {arrayBufferToUrl} from "../util/ArrayBufferUtils";
import {removeAt, switchBackward, switchForward} from "../util/ArrayUtils";
import React, {Dispatch} from "react";
import {GetObjectByteArrayCommandOutput, StorageIO} from "../io/StorageIO";
import {randomString} from "../util/StringUtils";

const useStyles = makeStyles({
    mediaItem: {
        width: '240px'
    }
})

export type MediaFile = {
    file: File,
    arrayBuffer: ArrayBuffer
    text: string
}

export namespace MediaFile {
    export async function createFromFile(file: File) {
        const mediaFile: MediaFile = {
            file: file,
            arrayBuffer: await file.arrayBuffer(),
            text: await file.text()
        }

        return mediaFile
    }

    export async function createFromFileList(fileList: FileList) {
        const promises: Promise<MediaFile>[] = []
        for (const file of fileList) {
            promises.push(createFromFile(file))
        }

        return await Promise.all(promises)
    }

    export function createFromStorageObject(
        o: GetObjectByteArrayCommandOutput,
        fileName: string = randomString({ length: 10 })
    ) {
        const mediaFile: MediaFile = {
            file: new File([o.Body.buffer], fileName, { type: o.ContentType }),
            arrayBuffer: o.Body.buffer,
            text: new TextDecoder().decode(o.Body.buffer)
        }

        return mediaFile
    }

    export async function createFromStorageKey(
        key: string,
        fileName: string = randomString({ length: 10 })
    ) {
        return createFromStorageObject(
            await StorageIO.getObjectByteArray(key),
            fileName
        )
    }

    export async function createFromStorageKeys(
        keys: string[],
        fileNames: string[] = keys.map(() => randomString({ length: 10 }))
    ) {
        const promises: Promise<MediaFile>[] = []
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            const fileName = fileNames[i]
            promises.push(createFromStorageKey(key, fileName))
        }

        return await Promise.all(promises)
    }
}

export type MediaItemProps = RouterProps & {
    source: MediaFile
    index?: number
    showLeft?: boolean
    onLeftClick?: (index: number) => void
    showRight?: boolean
    onRightClick?: (index: number) => void
    showDismiss?: boolean
    onDismissClick?: (index: number) => void
}

export function Media(props: MediaItemProps) {
    const styles = useStyles()

    return <>
        <div className={mergeClasses(props.styles.column, styles.mediaItem)}>
            <div className={props.styles.rowReverse}>
                {props.showDismiss === true && <>
                    <Button
                        appearance={'subtle'}
                        icon={<Dismiss16Filled />}
                        onClick={() => props.onDismissClick?.(props.index ?? 0)} />
                </>}
                {props.showRight === true && <>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronRight16Filled />}
                        onClick={() => props.onRightClick?.(props.index ?? 0)} />
                </>}
                {props.showLeft === true && <>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft16Filled />}
                        onClick={() => props.onLeftClick?.(props.index ?? 0)} />
                </>}
            </div>
            <MediaItemSurface source={props.source} />
        </div>
    </>
}

function MediaItemSurface(props: { source: MediaFile | undefined }) {
    if (props.source === undefined) {
        return <Spinner />
    }

    const [type, subtype] = props.source.file.type.split('/')
    if (type === 'image') {
        if (subtype === 'svg+xml') {
            const parser = new DOMParser()
            const parsed = parser.parseFromString(props.source.text, 'image/svg+xml')
            const svg = parsed.querySelector('svg')
            if (svg === null) {
                throw Error('Invalid file: svg is null.')
            }

            return <div dangerouslySetInnerHTML={{ __html: svg.outerHTML }} />
        } else {
            return <Image src={arrayBufferToUrl(props.source.arrayBuffer)} />
        }
    } else {
        return <video src={arrayBufferToUrl(props.source.arrayBuffer)} controls />
    }
}

export type MediaListProps = RouterProps & {
    sources: MediaFile[] | undefined
}

export function MediaList(props: MediaListProps) {
    const sources = props.sources
    if (sources === undefined) {
        return <Spinner />
    }

    return <>
        <div className={props.styles.row8}>
            {sources.map((value, index) => <>
                <Media
                    {...props}
                    key={`media-item-${index}`}
                    index={index}
                    source={value} />
            </>)}
        </div>
    </>
}

export type MutableMediaListProps = RouterProps & {
    value: MediaFile[] | undefined
    setter: Dispatch<MediaFile[]>
}

export function MutableMediaList(props: MutableMediaListProps) {
    const sources = props.value
    if (sources === undefined) {
        return <Spinner />
    }

    const onLeftClick = (index: number) => {
        props.setter(switchForward(sources, index))
    }

    const onRightClick = (index: number) => {
        props.setter(switchBackward(sources, index))
    }

    const onDismissClick = (index: number) => {
        props.setter(removeAt(sources, index))
    }

    return <>
        <div className={props.styles.row8}>
            {sources.map((value, index) => <>
                <Media
                    {...props}
                    key={`media-item-${index}`}
                    index={index}
                    source={value}
                    showLeft={index !== 0}
                    onLeftClick={onLeftClick}
                    showRight={index < sources.length - 1}
                    onRightClick={onRightClick}
                    showDismiss
                    onDismissClick={onDismissClick}/>
            </>)}
        </div>
    </>
}