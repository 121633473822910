import {ContentPolicySearchOption} from "../../search-option/content/ContentPolicySearchOption"

/**
 * 콘텐츠 정책 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentPolicySearchOptionDescription: Record<keyof ContentPolicySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    contentPageSizeEq: '콘텐츠 페이지 크기 일치',
    contentPageSizeNeq: '콘텐츠 페이지 크기 불일치',
    contentPageSizeMin: '콘텐츠 페이지 크기 최소',
    contentPageSizeMax: '콘텐츠 페이지 크기 최대',
    contentReportAutoHiddenCountEq: '자동 숨김을 위한 신고 수 일치',
    contentReportAutoHiddenCountNeq: '자동 숨김을 위한 신고 수 불일치',
    contentReportAutoHiddenCountMin: '자동 숨김을 위한 신고 수 최소',
    contentReportAutoHiddenCountMax: '자동 숨김을 위한 신고 수 최대',
    contentCommentPageSizeEq: '콘텐츠 댓글 페이지 크기 일치',
    contentCommentPageSizeNeq: '콘텐츠 댓글 페이지 크기 불일치',
    contentCommentPageSizeMin: '콘텐츠 댓글 페이지 크기 최소',
    contentCommentPageSizeMax: '콘텐츠 댓글 페이지 크기 최대',
    contentCommentWithAudioSolarAmountEq: '콘텐츠 음성 댓글 소모 솔라 일치',
    contentCommentWithAudioSolarAmountNeq: '콘텐츠 음성 댓글 소모 솔라 불일치',
    contentCommentWithAudioSolarAmountMin: '콘텐츠 음성 댓글 소모 솔라 최소',
    contentCommentWithAudioSolarAmountMax: '콘텐츠 음성 댓글 소모 솔라 최대',
    contentCommentReportAutoHiddenCountEq: '콘텐츠 댓글 자동 숨김 신고 수 일치',
    contentCommentReportAutoHiddenCountNeq: '콘텐츠 댓글 자동 숨김 신고 수 불일치',
    contentCommentReportAutoHiddenCountMin: '콘텐츠 댓글 자동 숨김 신고 수 최소',
    contentCommentReportAutoHiddenCountMax: '콘텐츠 댓글 자동 숨김 신고 수 최대',
    contentCommentThresholdMinutesEq: '콘텐츠 댓글 작성 제한 단위 시간 일치',
    contentCommentThresholdMinutesNeq: '콘텐츠 댓글 작성 제한 단위 시간 불일치',
    contentCommentThresholdMinutesMin: '콘텐츠 댓글 작성 제한 단위 시간 최소',
    contentCommentThresholdMinutesMax: '콘텐츠 댓글 작성 제한 단위 시간 최대',
    contentCommentThresholdCountEq: '콘텐츠 댓글 작성 제한 횟수 일치',
    contentCommentThresholdCountNeq: '콘텐츠 댓글 작성 제한 횟수 불일치',
    contentCommentThresholdCountMin: '콘텐츠 댓글 작성 제한 횟수 최소',
    contentCommentThresholdCountMax: '콘텐츠 댓글 작성 제한 횟수 최대',
    contentCommentLikePageSizeEq: '콘텐츠 댓글 좋아요 페이지 크기 일치',
    contentCommentLikePageSizeNeq: '콘텐츠 댓글 좋아요 페이지 크기 불일치',
    contentCommentLikePageSizeMin: '콘텐츠 댓글 좋아요 페이지 크기 최소',
    contentCommentLikePageSizeMax: '콘텐츠 댓글 좋아요 페이지 크기 최대',
    contentCommentReportPageSizeEq: '콘텐츠 댓글 신고 페이지 크기 일치',
    contentCommentReportPageSizeNeq: '콘텐츠 댓글 신고 페이지 크기 불일치',
    contentCommentReportPageSizeMin: '콘텐츠 댓글 신고 페이지 크기 최소',
    contentCommentReportPageSizeMax: '콘텐츠 댓글 신고 페이지 크기 최대',
    contentCommentReportReasonPageSizeEq: '콘텐츠 댓글 신고 사유 페이지 크기 일치',
    contentCommentReportReasonPageSizeNeq: '콘텐츠 댓글 신고 사유 페이지 크기 불일치',
    contentCommentReportReasonPageSizeMin: '콘텐츠 댓글 신고 사유 페이지 크기 최소',
    contentCommentReportReasonPageSizeMax: '콘텐츠 댓글 신고 사유 페이지 크기 최대',
    contentParticipationPageSizeEq: '콘텐츠 참여 페이지 크기 일치',
    contentParticipationPageSizeNeq: '콘텐츠 참여 페이지 크기 불일치',
    contentParticipationPageSizeMin: '콘텐츠 참여 페이지 크기 최소',
    contentParticipationPageSizeMax: '콘텐츠 참여 페이지 크기 최대',
    contentDailyParticipationExpEq: '콘텐츠 참여 EXP(오늘의 단어) 일치',
    contentDailyParticipationExpNeq: '콘텐츠 참여 EXP(오늘의 단어) 불일치',
    contentDailyParticipationExpMin: '콘텐츠 참여 EXP(오늘의 단어) 최소',
    contentDailyParticipationExpMax: '콘텐츠 참여 EXP(오늘의 단어) 최대',
    contentDailyParticipationPassExpEq: '콘텐츠 합격 EXP(오늘의 단어) 일치',
    contentDailyParticipationPassExpNeq: '콘텐츠 합격 EXP(오늘의 단어) 불일치',
    contentDailyParticipationPassExpMin: '콘텐츠 합격 EXP(오늘의 단어) 최소',
    contentDailyParticipationPassExpMax: '콘텐츠 합격 EXP(오늘의 단어) 최대',
    contentRepeatParticipationThresholdMinutesEq: '콘텐츠 참여 제한 단위 시간(따라말하기) 일치',
    contentRepeatParticipationThresholdMinutesNeq: '콘텐츠 참여 제한 단위 시간(따라말하기) 불일치',
    contentRepeatParticipationThresholdMinutesMin: '콘텐츠 참여 제한 단위 시간(따라말하기) 최소',
    contentRepeatParticipationThresholdMinutesMax: '콘텐츠 참여 제한 단위 시간(따라말하기) 최대',
    contentRepeatParticipationThresholdCountEq: '콘텐츠 참여 제한 횟수(따라말하기) 일치',
    contentRepeatParticipationThresholdCountNeq: '콘텐츠 참여 제한 횟수(따라말하기) 불일치',
    contentRepeatParticipationThresholdCountMin: '콘텐츠 참여 제한 횟수(따라말하기) 최소',
    contentRepeatParticipationThresholdCountMax: '콘텐츠 참여 제한 횟수(따라말하기) 최대',
    contentRepeatParticipationExpEq: '콘텐츠 참여 EXP(따라말하기) 일치',
    contentRepeatParticipationExpNeq: '콘텐츠 참여 EXP(따라말하기) 불일치',
    contentRepeatParticipationExpMin: '콘텐츠 참여 EXP(따라말하기) 최소',
    contentRepeatParticipationExpMax: '콘텐츠 참여 EXP(따라말하기) 최대',
    contentRepeatParticipationPassExpEq: '콘텐츠 합격 EXP(따라말하기) 일치',
    contentRepeatParticipationPassExpNeq: '콘텐츠 합격 EXP(따라말하기) 불일치',
    contentRepeatParticipationPassExpMin: '콘텐츠 합격 EXP(따라말하기) 최소',
    contentRepeatParticipationPassExpMax: '콘텐츠 합격 EXP(따라말하기) 최대',
    contentM10nParticipationThresholdMinutesEq: '콘텐츠 참여 제한 단위 시간(암기하기) 일치',
    contentM10nParticipationThresholdMinutesNeq: '콘텐츠 참여 제한 단위 시간(암기하기) 불일치',
    contentM10nParticipationThresholdMinutesMin: '콘텐츠 참여 제한 단위 시간(암기하기) 최소',
    contentM10nParticipationThresholdMinutesMax: '콘텐츠 참여 제한 단위 시간(암기하기) 최대',
    contentM10nParticipationThresholdCountEq: '콘텐츠 참여 제한 횟수(암기하기) 일치',
    contentM10nParticipationThresholdCountNeq: '콘텐츠 참여 제한 횟수(암기하기) 불일치',
    contentM10nParticipationThresholdCountMin: '콘텐츠 참여 제한 횟수(암기하기) 최소',
    contentM10nParticipationThresholdCountMax: '콘텐츠 참여 제한 횟수(암기하기) 최대',
    contentM10nParticipationExpEq: '콘텐츠 참여 EXP(암기하기) 일치',
    contentM10nParticipationExpNeq: '콘텐츠 참여 EXP(암기하기) 불일치',
    contentM10nParticipationExpMin: '콘텐츠 참여 EXP(암기하기) 최소',
    contentM10nParticipationExpMax: '콘텐츠 참여 EXP(암기하기) 최대',
    contentM10nParticipationPassExpEq: '콘텐츠 합격 EXP(암기하기) 일치',
    contentM10nParticipationPassExpNeq: '콘텐츠 합격 EXP(암기하기) 불일치',
    contentM10nParticipationPassExpMin: '콘텐츠 합격 EXP(암기하기) 최소',
    contentM10nParticipationPassExpMax: '콘텐츠 합격 EXP(암기하기) 최대',
    contentUserProductionParticipationThresholdMinutesEq: '콘텐츠 참여 제한 단위 시간(와글와글) 일치',
    contentUserProductionParticipationThresholdMinutesNeq: '콘텐츠 참여 제한 단위 시간(와글와글) 불일치',
    contentUserProductionParticipationThresholdMinutesMin: '콘텐츠 참여 제한 단위 시간(와글와글) 최소',
    contentUserProductionParticipationThresholdMinutesMax: '콘텐츠 참여 제한 단위 시간(와글와글) 최대',
    contentUserProductionParticipationThresholdCountEq: '콘텐츠 참여 제한 횟수(와글와글) 일치',
    contentUserProductionParticipationThresholdCountNeq: '콘텐츠 참여 제한 횟수(와글와글) 불일치',
    contentUserProductionParticipationThresholdCountMin: '콘텐츠 참여 제한 횟수(와글와글) 최소',
    contentUserProductionParticipationThresholdCountMax: '콘텐츠 참여 제한 횟수(와글와글) 최대',
    contentUserProductionParticipationExpEq: '콘텐츠 참여 EXP(와글와글) 일치',
    contentUserProductionParticipationExpNeq: '콘텐츠 참여 EXP(와글와글) 불일치',
    contentUserProductionParticipationExpMin: '콘텐츠 참여 EXP(와글와글) 최소',
    contentUserProductionParticipationExpMax: '콘텐츠 참여 EXP(와글와글) 최대',
    contentUserProductionParticipationPassExpEq: '콘텐츠 합격 EXP(와글와글) 일치',
    contentUserProductionParticipationPassExpNeq: '콘텐츠 합격 EXP(와글와글) 불일치',
    contentUserProductionParticipationPassExpMin: '콘텐츠 합격 EXP(와글와글) 최소',
    contentUserProductionParticipationPassExpMax: '콘텐츠 합격 EXP(와글와글) 최대',
    contentUserProductionPresetPageSizeEq: '콘텐츠 프리셋 페이지 크기(와글와글) 일치',
    contentUserProductionPresetPageSizeNeq: '콘텐츠 프리셋 페이지 크기(와글와글) 불일치',
    contentUserProductionPresetPageSizeMin: '콘텐츠 프리셋 페이지 크기(와글와글) 최소',
    contentUserProductionPresetPageSizeMax: '콘텐츠 프리셋 페이지 크기(와글와글) 최대',
    contentUserProductionMinimumAssetLunarAmountEq: '콘텐츠 제작 최소 루나 일치',
    contentUserProductionMinimumAssetLunarAmountNeq: '콘텐츠 제작 최소 루나 불일치',
    contentUserProductionMinimumAssetLunarAmountMin: '콘텐츠 제작 최소 루나 최소',
    contentUserProductionMinimumAssetLunarAmountMax: '콘텐츠 제작 최소 루나 최대',
    contentUserProductionMinimumUserLevelIdEq: '콘텐츠 제작 최소 레벨 일치',
    contentUserProductionMinimumUserLevelIdNeq: '콘텐츠 제작 최소 레벨 불일치',
    contentUserProductionMinimumUserLevelIdMin: '콘텐츠 제작 최소 레벨 최소',
    contentUserProductionMinimumUserLevelIdMax: '콘텐츠 제작 최소 레벨 최대',
    contentUserProductionMinimumAccuracyEq: '콘텐츠 제작 최소 일치율 일치',
    contentUserProductionMinimumAccuracyNeq: '콘텐츠 제작 최소 일치율 불일치',
    contentUserProductionMinimumAccuracyMin: '콘텐츠 제작 최소 일치율 최소',
    contentUserProductionMinimumAccuracyMax: '콘텐츠 제작 최소 일치율 최대',
    contentUserProductionPassAccuracyEq: '콘텐츠 일치율(와글와글) 일치',
    contentUserProductionPassAccuracyNeq: '콘텐츠 일치율(와글와글) 불일치',
    contentUserProductionPassAccuracyMin: '콘텐츠 일치율(와글와글) 최소',
    contentUserProductionPassAccuracyMax: '콘텐츠 일치율(와글와글) 최대',
    contentUserProductionPassHighAccuracyEq: '콘텐츠 높은 일치율(와글와글) 일치',
    contentUserProductionPassHighAccuracyNeq: '콘텐츠 높은 일치율(와글와글) 불일치',
    contentUserProductionPassHighAccuracyMin: '콘텐츠 높은 일치율(와글와글) 최소',
    contentUserProductionPassHighAccuracyMax: '콘텐츠 높은 일치율(와글와글) 최대',
    contentUserProductionPassSolarAmountHighAccuracyEq: '콘텐츠 높은 일치율 지급 솔라(와글와글) 일치',
    contentUserProductionPassSolarAmountHighAccuracyNeq: '콘텐츠 높은 일치율 지급 솔라(와글와글) 불일치',
    contentUserProductionPassSolarAmountHighAccuracyMin: '콘텐츠 높은 일치율 지급 솔라(와글와글) 최소',
    contentUserProductionPassSolarAmountHighAccuracyMax: '콘텐츠 높은 일치율 지급 솔라(와글와글) 최대',
    contentUserProductionStarAmountEq: '콘텐츠 소모 스타(와글와글) 일치',
    contentUserProductionStarAmountNeq: '콘텐츠 소모 스타(와글와글) 불일치',
    contentUserProductionStarAmountMin: '콘텐츠 소모 스타(와글와글) 최소',
    contentUserProductionStarAmountMax: '콘텐츠 소모 스타(와글와글) 최대',
    contentUserProductionDefaultDetailUrlEq: '콘텐츠 더 알아보기 기본 URL 일치',
    contentUserProductionDefaultDetailUrlNeq: '콘텐츠 더 알아보기 기본 URL 불일치',
    contentUserProductionDefaultDetailUrlMin: '콘텐츠 더 알아보기 기본 URL 최소',
    contentUserProductionDefaultDetailUrlMax: '콘텐츠 더 알아보기 기본 URL 최대',
    contentUserProductionDefaultDetailUrlLike: '콘텐츠 더 알아보기 기본 URL 포함',
    contentUserProductionDefaultDetailUrlNotLike: '콘텐츠 더 알아보기 기본 URL 제외',
    contentUserProductionDefaultDetailUrlBrowserEq: '콘텐츠 더 알아보기 기본 브라우저 일치',
    contentUserProductionDefaultDetailUrlBrowserNeq: '콘텐츠 더 알아보기 기본 브라우저 불일치',
    contentUserProductionDefaultDetailUrlBrowserMin: '콘텐츠 더 알아보기 기본 브라우저 최소',
    contentUserProductionDefaultDetailUrlBrowserMax: '콘텐츠 더 알아보기 기본 브라우저 최대',
    contentUserProductionDefaultDetailUrlBrowserIn: '콘텐츠 더 알아보기 기본 브라우저 포함',
    contentUserProductionDefaultResultUrlEq: '콘텐츠 참여 결과 기본 URL 일치',
    contentUserProductionDefaultResultUrlNeq: '콘텐츠 참여 결과 기본 URL 불일치',
    contentUserProductionDefaultResultUrlMin: '콘텐츠 참여 결과 기본 URL 최소',
    contentUserProductionDefaultResultUrlMax: '콘텐츠 참여 결과 기본 URL 최대',
    contentUserProductionDefaultResultUrlLike: '콘텐츠 참여 결과 기본 URL 포함',
    contentUserProductionDefaultResultUrlNotLike: '콘텐츠 참여 결과 기본 URL 제외',
    contentUserProductionDefaultResultUrlBrowserEq: '콘텐츠 참여 결과 기본 브라우저 일치',
    contentUserProductionDefaultResultUrlBrowserNeq: '콘텐츠 참여 결과 기본 브라우저 불일치',
    contentUserProductionDefaultResultUrlBrowserMin: '콘텐츠 참여 결과 기본 브라우저 최소',
    contentUserProductionDefaultResultUrlBrowserMax: '콘텐츠 참여 결과 기본 브라우저 최대',
    contentUserProductionDefaultResultUrlBrowserIn: '콘텐츠 참여 결과 기본 브라우저 포함',
    contentUserProductionScriptPageSizeEq: '콘텐츠 스크립트 페이지 크기(와글와글) 일치',
    contentUserProductionScriptPageSizeNeq: '콘텐츠 스크립트 페이지 크기(와글와글) 불일치',
    contentUserProductionScriptPageSizeMin: '콘텐츠 스크립트 페이지 크기(와글와글) 최소',
    contentUserProductionScriptPageSizeMax: '콘텐츠 스크립트 페이지 크기(와글와글) 최대',
    contentLikePageSizeEq: '콘텐츠 좋아요 페이지 크기 일치',
    contentLikePageSizeNeq: '콘텐츠 좋아요 페이지 크기 불일치',
    contentLikePageSizeMin: '콘텐츠 좋아요 페이지 크기 최소',
    contentLikePageSizeMax: '콘텐츠 좋아요 페이지 크기 최대',
    contentPolicyPageSizeEq: '콘텐츠 정책 페이지 크기 일치',
    contentPolicyPageSizeNeq: '콘텐츠 정책 페이지 크기 불일치',
    contentPolicyPageSizeMin: '콘텐츠 정책 페이지 크기 최소',
    contentPolicyPageSizeMax: '콘텐츠 정책 페이지 크기 최대',
    contentQueryPageSizeEq: '콘텐츠 검색 페이지 크기 일치',
    contentQueryPageSizeNeq: '콘텐츠 검색 페이지 크기 불일치',
    contentQueryPageSizeMin: '콘텐츠 검색 페이지 크기 최소',
    contentQueryPageSizeMax: '콘텐츠 검색 페이지 크기 최대',
    contentQueryPopularDurationMinutesEq: '콘텐츠 인기 검색어 갱신 주기(분) 일치',
    contentQueryPopularDurationMinutesNeq: '콘텐츠 인기 검색어 갱신 주기(분) 불일치',
    contentQueryPopularDurationMinutesMin: '콘텐츠 인기 검색어 갱신 주기(분) 최소',
    contentQueryPopularDurationMinutesMax: '콘텐츠 인기 검색어 갱신 주기(분) 최대',
    contentQueryPopularPageSizeEq: '콘텐츠 인기 검색어 페이지 크기 일치',
    contentQueryPopularPageSizeNeq: '콘텐츠 인기 검색어 페이지 크기 불일치',
    contentQueryPopularPageSizeMin: '콘텐츠 인기 검색어 페이지 크기 최소',
    contentQueryPopularPageSizeMax: '콘텐츠 인기 검색어 페이지 크기 최대',
    contentReportReasonPageSizeEq: '콘텐츠 신고 사유 페이지 크기 일치',
    contentReportReasonPageSizeNeq: '콘텐츠 신고 사유 페이지 크기 불일치',
    contentReportReasonPageSizeMin: '콘텐츠 신고 사유 페이지 크기 최소',
    contentReportReasonPageSizeMax: '콘텐츠 신고 사유 페이지 크기 최대',
    contentReportPageSizeEq: '콘텐츠 신고 페이지 크기 일치',
    contentReportPageSizeNeq: '콘텐츠 신고 페이지 크기 불일치',
    contentReportPageSizeMin: '콘텐츠 신고 페이지 크기 최소',
    contentReportPageSizeMax: '콘텐츠 신고 페이지 크기 최대',
    contentReportDailyThresholdEq: '콘텐츠 일일 신고 가능 횟수 일치',
    contentReportDailyThresholdNeq: '콘텐츠 일일 신고 가능 횟수 불일치',
    contentReportDailyThresholdMin: '콘텐츠 일일 신고 가능 횟수 최소',
    contentReportDailyThresholdMax: '콘텐츠 일일 신고 가능 횟수 최대',
    contentDetailUrlPageSizeEq: '콘텐츠 더 알아보기 URL 페이지 크기 일치',
    contentDetailUrlPageSizeNeq: '콘텐츠 더 알아보기 URL 페이지 크기 불일치',
    contentDetailUrlPageSizeMin: '콘텐츠 더 알아보기 URL 페이지 크기 최소',
    contentDetailUrlPageSizeMax: '콘텐츠 더 알아보기 URL 페이지 크기 최대',
    contentDetailUrlClickPageSizeEq: '콘텐츠 더 알아보기 URL 클릭 페이지 크기 일치',
    contentDetailUrlClickPageSizeNeq: '콘텐츠 더 알아보기 URL 클릭 페이지 크기 불일치',
    contentDetailUrlClickPageSizeMin: '콘텐츠 더 알아보기 URL 클릭 페이지 크기 최소',
    contentDetailUrlClickPageSizeMax: '콘텐츠 더 알아보기 URL 클릭 페이지 크기 최대',
    contentDetailUrlClickExpEq: '콘텐츠 더 알아보기 URL 클릭 경험치 일치',
    contentDetailUrlClickExpNeq: '콘텐츠 더 알아보기 URL 클릭 경험치 불일치',
    contentDetailUrlClickExpMin: '콘텐츠 더 알아보기 URL 클릭 경험치 최소',
    contentDetailUrlClickExpMax: '콘텐츠 더 알아보기 URL 클릭 경험치 최대',
    contentResultUrlPageSizeEq: '콘텐츠 참여 결과 URL 페이지 크기 일치',
    contentResultUrlPageSizeNeq: '콘텐츠 참여 결과 URL 페이지 크기 불일치',
    contentResultUrlPageSizeMin: '콘텐츠 참여 결과 URL 페이지 크기 최소',
    contentResultUrlPageSizeMax: '콘텐츠 참여 결과 URL 페이지 크기 최대',
    contentResultUrlClickPageSizeEq: '콘텐츠 참여 결과 URL 클릭 페이지 크기 일치',
    contentResultUrlClickPageSizeNeq: '콘텐츠 참여 결과 URL 클릭 페이지 크기 불일치',
    contentResultUrlClickPageSizeMin: '콘텐츠 참여 결과 URL 클릭 페이지 크기 최소',
    contentResultUrlClickPageSizeMax: '콘텐츠 참여 결과 URL 클릭 페이지 크기 최대',
    contentResultUrlClickExpEq: '콘텐츠 참여 결과 URL 클릭 경험치 일치',
    contentResultUrlClickExpNeq: '콘텐츠 참여 결과 URL 클릭 경험치 불일치',
    contentResultUrlClickExpMin: '콘텐츠 참여 결과 URL 클릭 경험치 최소',
    contentResultUrlClickExpMax: '콘텐츠 참여 결과 URL 클릭 경험치 최대',
    contentReviewPageSizeEq: '콘텐츠 평가 페이지 크기(와글와글) 일치',
    contentReviewPageSizeNeq: '콘텐츠 평가 페이지 크기(와글와글) 불일치',
    contentReviewPageSizeMin: '콘텐츠 평가 페이지 크기(와글와글) 최소',
    contentReviewPageSizeMax: '콘텐츠 평가 페이지 크기(와글와글) 최대',
    contentTargetPageSizeEq: '콘텐츠 대상 페이지 크기 일치',
    contentTargetPageSizeNeq: '콘텐츠 대상 페이지 크기 불일치',
    contentTargetPageSizeMin: '콘텐츠 대상 페이지 크기 최소',
    contentTargetPageSizeMax: '콘텐츠 대상 페이지 크기 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
