import {ContentDifficulty} from "../../../data/constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../../data/constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../../data/constant/content/ContentScriptVisibility"
import {ContentStatus} from "../../../data/constant/content/ContentStatus"
import {ContentType} from "../../../data/constant/content/ContentType"
import {ContentSearchOptionDescription} from "../../../data/description/content/ContentSearchOptionDescription"
import {ContentSearchOption} from "../../../data/search-option/content/ContentSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"
import {BitMaskFlag} from "ts-protocol-extension"

export function ContentSearchOptionField(props: ListOptionFieldProps<ContentSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "advertiserIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.advertiserIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "advertiserIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.advertiserIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "advertiserIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.advertiserIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "advertiserIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.advertiserIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "advertiserIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.advertiserIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "typeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.typeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "typeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.typeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "typeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.typeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "typeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.typeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "typeIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.typeIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentType.selections} />
                </ListOptionWrapper>
            </>
        case "participationMethodEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationMethodEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentParticipationMethod.selections} />
                </ListOptionWrapper>
            </>
        case "participationMethodNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationMethodNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentParticipationMethod.selections} />
                </ListOptionWrapper>
            </>
        case "participationMethodMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationMethodMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentParticipationMethod.selections} />
                </ListOptionWrapper>
            </>
        case "participationMethodMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationMethodMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentParticipationMethod.selections} />
                </ListOptionWrapper>
            </>
        case "participationMethodIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationMethodIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentParticipationMethod.selections} />
                </ListOptionWrapper>
            </>
        case "titleEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleHighlightIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.titleHighlightIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.bodyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.bodyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.bodyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.bodyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.bodyLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.bodyNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "scriptVisibilityEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptVisibilityEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentScriptVisibility.selections} />
                </ListOptionWrapper>
            </>
        case "scriptVisibilityNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptVisibilityNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentScriptVisibility.selections} />
                </ListOptionWrapper>
            </>
        case "scriptVisibilityMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptVisibilityMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentScriptVisibility.selections} />
                </ListOptionWrapper>
            </>
        case "scriptVisibilityMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptVisibilityMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentScriptVisibility.selections} />
                </ListOptionWrapper>
            </>
        case "scriptVisibilityIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.scriptVisibilityIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentScriptVisibility.selections} />
                </ListOptionWrapper>
            </>
        case "contentDetailUrlIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentDetailUrlIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentDetailUrlIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentDetailUrlIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentResultUrlIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentResultUrlIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentResultUrlIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "gradingMessageIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.gradingMessageIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.amountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.amountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.amountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.amountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyHighEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyHighEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyHighNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyHighNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyHighMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyHighMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyHighMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyHighMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passAccuracyHighIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passAccuracyHighIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountHighAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountHighAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountHighAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountHighAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountHighAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountHighAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "passSolarAmountHighAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.passSolarAmountHighAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.starAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.starAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.starAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.starAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "difficultyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.difficultyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentDifficulty.selections} />
                </ListOptionWrapper>
            </>
        case "difficultyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.difficultyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentDifficulty.selections} />
                </ListOptionWrapper>
            </>
        case "difficultyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.difficultyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentDifficulty.selections} />
                </ListOptionWrapper>
            </>
        case "difficultyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.difficultyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={ContentDifficulty.selections} />
                </ListOptionWrapper>
            </>
        case "difficultyIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.difficultyIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentDifficulty.selections} />
                </ListOptionWrapper>
            </>
        case "headerImageCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.headerImageCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "headerImageCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.headerImageCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "headerImageCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.headerImageCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "headerImageCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.headerImageCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "descriptionImageCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.descriptionImageCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "descriptionImageCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.descriptionImageCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "descriptionImageCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.descriptionImageCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "descriptionImageCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.descriptionImageCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.exposedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.exposedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.exposedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "exposedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.exposedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "startAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.startAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "startAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.startAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "startAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.startAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "startAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.startAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "endAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.endAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "endAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.endAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "endAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.endAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "endAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.endAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "statusFlagsEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.statusFlagsEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.statusFlagsNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsAny":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.statusFlagsAny}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsAll":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.statusFlagsAll}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsNone":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.statusFlagsNone}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={ContentStatus.selections} />
                </ListOptionWrapper>
            </>
        case "contentTargetCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentTargetCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentTargetCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentTargetCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.contentTargetCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationPassCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationPassCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationPassCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationPassCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationPassCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationPassCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationPassCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationPassCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationHighAccuracyCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationHighAccuracyCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationHighAccuracyCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationHighAccuracyCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationHighAccuracyCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationHighAccuracyCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "participationHighAccuracyCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.participationHighAccuracyCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "commentCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.commentCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "commentCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.commentCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "commentCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.commentCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "commentCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.commentCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reportCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reportCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reportCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reportCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reportCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewPositiveCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewPositiveCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewPositiveCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewPositiveCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewPositiveCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewPositiveCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewPositiveCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewPositiveCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNeutralCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNeutralCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNeutralCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNeutralCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNeutralCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNeutralCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNeutralCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNeutralCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNegativeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNegativeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNegativeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNegativeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNegativeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNegativeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "reviewNegativeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.reviewNegativeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "likeCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.likeCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "likeCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.likeCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "likeCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.likeCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "likeCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.likeCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentSearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentSearchOption(key: keyof ContentSearchOption, value: string, into: StatePair<ContentSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "advertiserIdEq":
            into.setter({
                ...into.value,
                advertiserIdEq: BigInt(value)
            })
            break
        case "advertiserIdNeq":
            into.setter({
                ...into.value,
                advertiserIdNeq: BigInt(value)
            })
            break
        case "advertiserIdMin":
            into.setter({
                ...into.value,
                advertiserIdMin: BigInt(value)
            })
            break
        case "advertiserIdMax":
            into.setter({
                ...into.value,
                advertiserIdMax: BigInt(value)
            })
            break
        case "advertiserIdIsNull":
            into.setter({
                ...into.value,
                advertiserIdIsNull: Boolean(value)
            })
            break
        case "typeEq":
            into.setter({
                ...into.value,
                typeEq: value as ContentType
            })
            break
        case "typeNeq":
            into.setter({
                ...into.value,
                typeNeq: value as ContentType
            })
            break
        case "typeMin":
            into.setter({
                ...into.value,
                typeMin: value as ContentType
            })
            break
        case "typeMax":
            into.setter({
                ...into.value,
                typeMax: value as ContentType
            })
            break
        case "typeIn":
            into.setter({
                ...into.value,
                typeIn: value
                    .split('|')
                    .map(e => e as ContentType)
            })
            break
        case "participationMethodEq":
            into.setter({
                ...into.value,
                participationMethodEq: value as ContentParticipationMethod
            })
            break
        case "participationMethodNeq":
            into.setter({
                ...into.value,
                participationMethodNeq: value as ContentParticipationMethod
            })
            break
        case "participationMethodMin":
            into.setter({
                ...into.value,
                participationMethodMin: value as ContentParticipationMethod
            })
            break
        case "participationMethodMax":
            into.setter({
                ...into.value,
                participationMethodMax: value as ContentParticipationMethod
            })
            break
        case "participationMethodIn":
            into.setter({
                ...into.value,
                participationMethodIn: value
                    .split('|')
                    .map(e => e as ContentParticipationMethod)
            })
            break
        case "titleEq":
            into.setter({
                ...into.value,
                titleEq: String(value)
            })
            break
        case "titleNeq":
            into.setter({
                ...into.value,
                titleNeq: String(value)
            })
            break
        case "titleMin":
            into.setter({
                ...into.value,
                titleMin: String(value)
            })
            break
        case "titleMax":
            into.setter({
                ...into.value,
                titleMax: String(value)
            })
            break
        case "titleLike":
            into.setter({
                ...into.value,
                titleLike: String(value)
            })
            break
        case "titleNotLike":
            into.setter({
                ...into.value,
                titleNotLike: String(value)
            })
            break
        case "titleHighlightEq":
            into.setter({
                ...into.value,
                titleHighlightEq: String(value)
            })
            break
        case "titleHighlightNeq":
            into.setter({
                ...into.value,
                titleHighlightNeq: String(value)
            })
            break
        case "titleHighlightMin":
            into.setter({
                ...into.value,
                titleHighlightMin: String(value)
            })
            break
        case "titleHighlightMax":
            into.setter({
                ...into.value,
                titleHighlightMax: String(value)
            })
            break
        case "titleHighlightLike":
            into.setter({
                ...into.value,
                titleHighlightLike: String(value)
            })
            break
        case "titleHighlightNotLike":
            into.setter({
                ...into.value,
                titleHighlightNotLike: String(value)
            })
            break
        case "titleHighlightIsNull":
            into.setter({
                ...into.value,
                titleHighlightIsNull: Boolean(value)
            })
            break
        case "bodyEq":
            into.setter({
                ...into.value,
                bodyEq: String(value)
            })
            break
        case "bodyNeq":
            into.setter({
                ...into.value,
                bodyNeq: String(value)
            })
            break
        case "bodyMin":
            into.setter({
                ...into.value,
                bodyMin: String(value)
            })
            break
        case "bodyMax":
            into.setter({
                ...into.value,
                bodyMax: String(value)
            })
            break
        case "bodyLike":
            into.setter({
                ...into.value,
                bodyLike: String(value)
            })
            break
        case "bodyNotLike":
            into.setter({
                ...into.value,
                bodyNotLike: String(value)
            })
            break
        case "scriptEq":
            into.setter({
                ...into.value,
                scriptEq: String(value)
            })
            break
        case "scriptNeq":
            into.setter({
                ...into.value,
                scriptNeq: String(value)
            })
            break
        case "scriptMin":
            into.setter({
                ...into.value,
                scriptMin: String(value)
            })
            break
        case "scriptMax":
            into.setter({
                ...into.value,
                scriptMax: String(value)
            })
            break
        case "scriptLike":
            into.setter({
                ...into.value,
                scriptLike: String(value)
            })
            break
        case "scriptNotLike":
            into.setter({
                ...into.value,
                scriptNotLike: String(value)
            })
            break
        case "scriptVisibilityEq":
            into.setter({
                ...into.value,
                scriptVisibilityEq: value as ContentScriptVisibility
            })
            break
        case "scriptVisibilityNeq":
            into.setter({
                ...into.value,
                scriptVisibilityNeq: value as ContentScriptVisibility
            })
            break
        case "scriptVisibilityMin":
            into.setter({
                ...into.value,
                scriptVisibilityMin: value as ContentScriptVisibility
            })
            break
        case "scriptVisibilityMax":
            into.setter({
                ...into.value,
                scriptVisibilityMax: value as ContentScriptVisibility
            })
            break
        case "scriptVisibilityIn":
            into.setter({
                ...into.value,
                scriptVisibilityIn: value
                    .split('|')
                    .map(e => e as ContentScriptVisibility)
            })
            break
        case "contentDetailUrlIdNeq":
            into.setter({
                ...into.value,
                contentDetailUrlIdNeq: BigInt(value)
            })
            break
        case "contentDetailUrlIdMin":
            into.setter({
                ...into.value,
                contentDetailUrlIdMin: BigInt(value)
            })
            break
        case "contentDetailUrlIdMax":
            into.setter({
                ...into.value,
                contentDetailUrlIdMax: BigInt(value)
            })
            break
        case "contentResultUrlIdNeq":
            into.setter({
                ...into.value,
                contentResultUrlIdNeq: BigInt(value)
            })
            break
        case "contentResultUrlIdMin":
            into.setter({
                ...into.value,
                contentResultUrlIdMin: BigInt(value)
            })
            break
        case "contentResultUrlIdMax":
            into.setter({
                ...into.value,
                contentResultUrlIdMax: BigInt(value)
            })
            break
        case "gradingMessageEq":
            into.setter({
                ...into.value,
                gradingMessageEq: String(value)
            })
            break
        case "gradingMessageNeq":
            into.setter({
                ...into.value,
                gradingMessageNeq: String(value)
            })
            break
        case "gradingMessageMin":
            into.setter({
                ...into.value,
                gradingMessageMin: String(value)
            })
            break
        case "gradingMessageMax":
            into.setter({
                ...into.value,
                gradingMessageMax: String(value)
            })
            break
        case "gradingMessageLike":
            into.setter({
                ...into.value,
                gradingMessageLike: String(value)
            })
            break
        case "gradingMessageNotLike":
            into.setter({
                ...into.value,
                gradingMessageNotLike: String(value)
            })
            break
        case "gradingMessageIsNull":
            into.setter({
                ...into.value,
                gradingMessageIsNull: Boolean(value)
            })
            break
        case "amountEq":
            into.setter({
                ...into.value,
                amountEq: Number(value)
            })
            break
        case "amountNeq":
            into.setter({
                ...into.value,
                amountNeq: Number(value)
            })
            break
        case "amountMin":
            into.setter({
                ...into.value,
                amountMin: Number(value)
            })
            break
        case "amountMax":
            into.setter({
                ...into.value,
                amountMax: Number(value)
            })
            break
        case "passAccuracyEq":
            into.setter({
                ...into.value,
                passAccuracyEq: Number(value)
            })
            break
        case "passAccuracyNeq":
            into.setter({
                ...into.value,
                passAccuracyNeq: Number(value)
            })
            break
        case "passAccuracyMin":
            into.setter({
                ...into.value,
                passAccuracyMin: Number(value)
            })
            break
        case "passAccuracyMax":
            into.setter({
                ...into.value,
                passAccuracyMax: Number(value)
            })
            break
        case "passAccuracyHighEq":
            into.setter({
                ...into.value,
                passAccuracyHighEq: Number(value)
            })
            break
        case "passAccuracyHighNeq":
            into.setter({
                ...into.value,
                passAccuracyHighNeq: Number(value)
            })
            break
        case "passAccuracyHighMin":
            into.setter({
                ...into.value,
                passAccuracyHighMin: Number(value)
            })
            break
        case "passAccuracyHighMax":
            into.setter({
                ...into.value,
                passAccuracyHighMax: Number(value)
            })
            break
        case "passAccuracyHighIsNull":
            into.setter({
                ...into.value,
                passAccuracyHighIsNull: Boolean(value)
            })
            break
        case "passSolarAmountEq":
            into.setter({
                ...into.value,
                passSolarAmountEq: Number(value)
            })
            break
        case "passSolarAmountNeq":
            into.setter({
                ...into.value,
                passSolarAmountNeq: Number(value)
            })
            break
        case "passSolarAmountMin":
            into.setter({
                ...into.value,
                passSolarAmountMin: Number(value)
            })
            break
        case "passSolarAmountMax":
            into.setter({
                ...into.value,
                passSolarAmountMax: Number(value)
            })
            break
        case "passSolarAmountHighAccuracyEq":
            into.setter({
                ...into.value,
                passSolarAmountHighAccuracyEq: Number(value)
            })
            break
        case "passSolarAmountHighAccuracyNeq":
            into.setter({
                ...into.value,
                passSolarAmountHighAccuracyNeq: Number(value)
            })
            break
        case "passSolarAmountHighAccuracyMin":
            into.setter({
                ...into.value,
                passSolarAmountHighAccuracyMin: Number(value)
            })
            break
        case "passSolarAmountHighAccuracyMax":
            into.setter({
                ...into.value,
                passSolarAmountHighAccuracyMax: Number(value)
            })
            break
        case "starAmountEq":
            into.setter({
                ...into.value,
                starAmountEq: Number(value)
            })
            break
        case "starAmountNeq":
            into.setter({
                ...into.value,
                starAmountNeq: Number(value)
            })
            break
        case "starAmountMin":
            into.setter({
                ...into.value,
                starAmountMin: Number(value)
            })
            break
        case "starAmountMax":
            into.setter({
                ...into.value,
                starAmountMax: Number(value)
            })
            break
        case "difficultyEq":
            into.setter({
                ...into.value,
                difficultyEq: value as ContentDifficulty
            })
            break
        case "difficultyNeq":
            into.setter({
                ...into.value,
                difficultyNeq: value as ContentDifficulty
            })
            break
        case "difficultyMin":
            into.setter({
                ...into.value,
                difficultyMin: value as ContentDifficulty
            })
            break
        case "difficultyMax":
            into.setter({
                ...into.value,
                difficultyMax: value as ContentDifficulty
            })
            break
        case "difficultyIn":
            into.setter({
                ...into.value,
                difficultyIn: value
                    .split('|')
                    .map(e => e as ContentDifficulty)
            })
            break
        case "headerImageCountEq":
            into.setter({
                ...into.value,
                headerImageCountEq: Number(value)
            })
            break
        case "headerImageCountNeq":
            into.setter({
                ...into.value,
                headerImageCountNeq: Number(value)
            })
            break
        case "headerImageCountMin":
            into.setter({
                ...into.value,
                headerImageCountMin: Number(value)
            })
            break
        case "headerImageCountMax":
            into.setter({
                ...into.value,
                headerImageCountMax: Number(value)
            })
            break
        case "descriptionImageCountEq":
            into.setter({
                ...into.value,
                descriptionImageCountEq: Number(value)
            })
            break
        case "descriptionImageCountNeq":
            into.setter({
                ...into.value,
                descriptionImageCountNeq: Number(value)
            })
            break
        case "descriptionImageCountMin":
            into.setter({
                ...into.value,
                descriptionImageCountMin: Number(value)
            })
            break
        case "descriptionImageCountMax":
            into.setter({
                ...into.value,
                descriptionImageCountMax: Number(value)
            })
            break
        case "exposedAtEq":
            into.setter({
                ...into.value,
                exposedAtEq: new Date(value)
            })
            break
        case "exposedAtNeq":
            into.setter({
                ...into.value,
                exposedAtNeq: new Date(value)
            })
            break
        case "exposedAtMin":
            into.setter({
                ...into.value,
                exposedAtMin: new Date(value)
            })
            break
        case "exposedAtMax":
            into.setter({
                ...into.value,
                exposedAtMax: new Date(value)
            })
            break
        case "startAtEq":
            into.setter({
                ...into.value,
                startAtEq: new Date(value)
            })
            break
        case "startAtNeq":
            into.setter({
                ...into.value,
                startAtNeq: new Date(value)
            })
            break
        case "startAtMin":
            into.setter({
                ...into.value,
                startAtMin: new Date(value)
            })
            break
        case "startAtMax":
            into.setter({
                ...into.value,
                startAtMax: new Date(value)
            })
            break
        case "endAtEq":
            into.setter({
                ...into.value,
                endAtEq: new Date(value)
            })
            break
        case "endAtNeq":
            into.setter({
                ...into.value,
                endAtNeq: new Date(value)
            })
            break
        case "endAtMin":
            into.setter({
                ...into.value,
                endAtMin: new Date(value)
            })
            break
        case "endAtMax":
            into.setter({
                ...into.value,
                endAtMax: new Date(value)
            })
            break
        case "statusFlagsEq":
            into.setter({
                ...into.value,
                statusFlagsEq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsNeq":
            into.setter({
                ...into.value,
                statusFlagsNeq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsAny":
            into.setter({
                ...into.value,
                statusFlagsAny: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsAll":
            into.setter({
                ...into.value,
                statusFlagsAll: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsNone":
            into.setter({
                ...into.value,
                statusFlagsNone: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "contentTargetCountEq":
            into.setter({
                ...into.value,
                contentTargetCountEq: Number(value)
            })
            break
        case "contentTargetCountNeq":
            into.setter({
                ...into.value,
                contentTargetCountNeq: Number(value)
            })
            break
        case "contentTargetCountMin":
            into.setter({
                ...into.value,
                contentTargetCountMin: Number(value)
            })
            break
        case "contentTargetCountMax":
            into.setter({
                ...into.value,
                contentTargetCountMax: Number(value)
            })
            break
        case "participationCountEq":
            into.setter({
                ...into.value,
                participationCountEq: Number(value)
            })
            break
        case "participationCountNeq":
            into.setter({
                ...into.value,
                participationCountNeq: Number(value)
            })
            break
        case "participationCountMin":
            into.setter({
                ...into.value,
                participationCountMin: Number(value)
            })
            break
        case "participationCountMax":
            into.setter({
                ...into.value,
                participationCountMax: Number(value)
            })
            break
        case "participationPassCountEq":
            into.setter({
                ...into.value,
                participationPassCountEq: Number(value)
            })
            break
        case "participationPassCountNeq":
            into.setter({
                ...into.value,
                participationPassCountNeq: Number(value)
            })
            break
        case "participationPassCountMin":
            into.setter({
                ...into.value,
                participationPassCountMin: Number(value)
            })
            break
        case "participationPassCountMax":
            into.setter({
                ...into.value,
                participationPassCountMax: Number(value)
            })
            break
        case "participationHighAccuracyCountEq":
            into.setter({
                ...into.value,
                participationHighAccuracyCountEq: Number(value)
            })
            break
        case "participationHighAccuracyCountNeq":
            into.setter({
                ...into.value,
                participationHighAccuracyCountNeq: Number(value)
            })
            break
        case "participationHighAccuracyCountMin":
            into.setter({
                ...into.value,
                participationHighAccuracyCountMin: Number(value)
            })
            break
        case "participationHighAccuracyCountMax":
            into.setter({
                ...into.value,
                participationHighAccuracyCountMax: Number(value)
            })
            break
        case "commentCountEq":
            into.setter({
                ...into.value,
                commentCountEq: Number(value)
            })
            break
        case "commentCountNeq":
            into.setter({
                ...into.value,
                commentCountNeq: Number(value)
            })
            break
        case "commentCountMin":
            into.setter({
                ...into.value,
                commentCountMin: Number(value)
            })
            break
        case "commentCountMax":
            into.setter({
                ...into.value,
                commentCountMax: Number(value)
            })
            break
        case "reportCountEq":
            into.setter({
                ...into.value,
                reportCountEq: Number(value)
            })
            break
        case "reportCountNeq":
            into.setter({
                ...into.value,
                reportCountNeq: Number(value)
            })
            break
        case "reportCountMin":
            into.setter({
                ...into.value,
                reportCountMin: Number(value)
            })
            break
        case "reportCountMax":
            into.setter({
                ...into.value,
                reportCountMax: Number(value)
            })
            break
        case "reviewPositiveCountEq":
            into.setter({
                ...into.value,
                reviewPositiveCountEq: Number(value)
            })
            break
        case "reviewPositiveCountNeq":
            into.setter({
                ...into.value,
                reviewPositiveCountNeq: Number(value)
            })
            break
        case "reviewPositiveCountMin":
            into.setter({
                ...into.value,
                reviewPositiveCountMin: Number(value)
            })
            break
        case "reviewPositiveCountMax":
            into.setter({
                ...into.value,
                reviewPositiveCountMax: Number(value)
            })
            break
        case "reviewNeutralCountEq":
            into.setter({
                ...into.value,
                reviewNeutralCountEq: Number(value)
            })
            break
        case "reviewNeutralCountNeq":
            into.setter({
                ...into.value,
                reviewNeutralCountNeq: Number(value)
            })
            break
        case "reviewNeutralCountMin":
            into.setter({
                ...into.value,
                reviewNeutralCountMin: Number(value)
            })
            break
        case "reviewNeutralCountMax":
            into.setter({
                ...into.value,
                reviewNeutralCountMax: Number(value)
            })
            break
        case "reviewNegativeCountEq":
            into.setter({
                ...into.value,
                reviewNegativeCountEq: Number(value)
            })
            break
        case "reviewNegativeCountNeq":
            into.setter({
                ...into.value,
                reviewNegativeCountNeq: Number(value)
            })
            break
        case "reviewNegativeCountMin":
            into.setter({
                ...into.value,
                reviewNegativeCountMin: Number(value)
            })
            break
        case "reviewNegativeCountMax":
            into.setter({
                ...into.value,
                reviewNegativeCountMax: Number(value)
            })
            break
        case "likeCountEq":
            into.setter({
                ...into.value,
                likeCountEq: Number(value)
            })
            break
        case "likeCountNeq":
            into.setter({
                ...into.value,
                likeCountNeq: Number(value)
            })
            break
        case "likeCountMin":
            into.setter({
                ...into.value,
                likeCountMin: Number(value)
            })
            break
        case "likeCountMax":
            into.setter({
                ...into.value,
                likeCountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
