import {ContentReportVerboseDomain} from "../../domain/content/ContentReportVerboseDomain"
import {ContentId} from "../../id/content/ContentId"
import {ContentReportId} from "../../id/content/ContentReportId"
import {ContentReportReasonId} from "../../id/content/ContentReportReasonId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentReportReasonValueObject} from "./ContentReportReasonValueObject"
import {ContentValueObject} from "./ContentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 신고 상세
 */
export class ContentReportVerboseValueObject implements VerboseValueObject, ContentReportVerboseDomain {
    /**
     * 기본키
     */
    id: ContentReportId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 콘텐츠 신고 사유 ID
     */
    contentReportReasonId: ContentReportReasonId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 콘텐츠 ID
     */
    content: ContentValueObject
    /**
     * 콘텐츠 신고 사유 ID
     */
    contentReportReason: ContentReportReasonValueObject
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof ContentReportVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.contentId = BigInt(o.contentId)
        this.contentReportReasonId = BigInt(o.contentReportReasonId)
        this.userId = BigInt(o.userId)
        this.createdAt = new Date(o.createdAt)
        this.content = new ContentValueObject(o.content)
        this.contentReportReason = new ContentReportReasonValueObject(o.contentReportReason)
        this.user = new UserValueObject(o.user)
    }
}
