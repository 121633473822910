import {ContentReviewDomain} from "../../domain/content/ContentReviewDomain"
import {ContentId} from "../../id/content/ContentId"
import {ContentReviewId} from "../../id/content/ContentReviewId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 리뷰(와글와글만 가능)
 */
export class ContentReviewValueObject implements ValueObject, ContentReviewDomain {
    /**
     * 기본키
     */
    id: ContentReviewId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 평가
     */
    review: number
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentReviewDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentId = BigInt(o.contentId)
        this.review = Number(o.review)
        this.createdAt = new Date(o.createdAt)
    }
}
