import {UserGender} from "../../constant/user/UserGender"
import {UserPermission} from "../../constant/user/UserPermission"
import {UserRole} from "../../constant/user/UserRole"
import {UserStatus} from "../../constant/user/UserStatus"
import {UserDomain} from "../../domain/user/UserDomain"

export const UserDescriptor: Record<keyof UserDomain, (o: UserDomain) => string> = {
    id(o: UserDomain): string {
        return o.id.toString()
    },
    authenticationUid(o: UserDomain): string {
        return o.authenticationUid
    },
    role(o: UserDomain): string {
        return UserRole.description(o.role)
    },
    adminPermission(o: UserDomain): string {
        let enabled: string[] = []
        for (let key in UserPermission) {
            const flag = (UserPermission as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.adminPermission & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    email(o: UserDomain): string {
        return o.email
    },
    cloudMessagingToken(o: UserDomain): string {
        return o.cloudMessagingToken ?? '-'
    },
    nickname(o: UserDomain): string {
        return o.nickname
    },
    birthCountryId(o: UserDomain): string {
        return o.birthCountryId.toString()
    },
    residenceCountryId(o: UserDomain): string {
        return o.residenceCountryId.toString()
    },
    birth(o: UserDomain): string {
        return o.birth
    },
    gender(o: UserDomain): string {
        return UserGender.description(o.gender)
    },
    phoneCountryId(o: UserDomain): string {
        return o.phoneCountryId?.toString() ?? '-'
    },
    phone(o: UserDomain): string {
        return o.phone ?? '-'
    },
    recommenderId(o: UserDomain): string {
        return o.recommenderId?.toString() ?? '-'
    },
    recommendationCode(o: UserDomain): string {
        return o.recommendationCode
    },
    statusFlags(o: UserDomain): string {
        let enabled: string[] = []
        for (let key in UserStatus) {
            const flag = (UserStatus as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.statusFlags & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    recommendeeCount(o: UserDomain): string {
        return o.recommendeeCount.toString()
    },
    contentDailyParticipationCount(o: UserDomain): string {
        return o.contentDailyParticipationCount.toString()
    },
    contentDailyParticipationPassCount(o: UserDomain): string {
        return o.contentDailyParticipationPassCount.toString()
    },
    contentRepeatLikeCount(o: UserDomain): string {
        return o.contentRepeatLikeCount.toString()
    },
    contentRepeatParticipationCount(o: UserDomain): string {
        return o.contentRepeatParticipationCount.toString()
    },
    contentRepeatParticipationPassCount(o: UserDomain): string {
        return o.contentRepeatParticipationPassCount.toString()
    },
    contentRepeatParticipationHighAccuracyCount(o: UserDomain): string {
        return o.contentRepeatParticipationHighAccuracyCount.toString()
    },
    contentRepeatCommentCount(o: UserDomain): string {
        return o.contentRepeatCommentCount.toString()
    },
    contentRepeatCommentLikeCount(o: UserDomain): string {
        return o.contentRepeatCommentLikeCount.toString()
    },
    contentRepeatCommentReportCount(o: UserDomain): string {
        return o.contentRepeatCommentReportCount.toString()
    },
    contentRepeatCommentReportedCount(o: UserDomain): string {
        return o.contentRepeatCommentReportedCount.toString()
    },
    contentM10nLikeCount(o: UserDomain): string {
        return o.contentM10nLikeCount.toString()
    },
    contentM10nParticipationCount(o: UserDomain): string {
        return o.contentM10nParticipationCount.toString()
    },
    contentM10nParticipationPassCount(o: UserDomain): string {
        return o.contentM10nParticipationPassCount.toString()
    },
    contentM10nParticipationHighAccuracyCount(o: UserDomain): string {
        return o.contentM10nParticipationHighAccuracyCount.toString()
    },
    contentM10nCommentCount(o: UserDomain): string {
        return o.contentM10nCommentCount.toString()
    },
    contentM10nCommentLikeCount(o: UserDomain): string {
        return o.contentM10nCommentLikeCount.toString()
    },
    contentM10nCommentReportCount(o: UserDomain): string {
        return o.contentM10nCommentReportCount.toString()
    },
    contentM10nCommentReportedCount(o: UserDomain): string {
        return o.contentM10nCommentReportedCount.toString()
    },
    contentUserProductionCount(o: UserDomain): string {
        return o.contentUserProductionCount.toString()
    },
    contentUserProductionLikeCount(o: UserDomain): string {
        return o.contentUserProductionLikeCount.toString()
    },
    contentUserProductionParticipationCount(o: UserDomain): string {
        return o.contentUserProductionParticipationCount.toString()
    },
    contentUserProductionParticipationPassCount(o: UserDomain): string {
        return o.contentUserProductionParticipationPassCount.toString()
    },
    contentUserProductionParticipationHighAccuracyCount(o: UserDomain): string {
        return o.contentUserProductionParticipationHighAccuracyCount.toString()
    },
    contentUserProductionReportCount(o: UserDomain): string {
        return o.contentUserProductionReportCount.toString()
    },
    contentUserProductionReportedCount(o: UserDomain): string {
        return o.contentUserProductionReportedCount.toString()
    },
    contentUserProductionCommentCount(o: UserDomain): string {
        return o.contentUserProductionCommentCount.toString()
    },
    contentUserProductionCommentLikeCount(o: UserDomain): string {
        return o.contentUserProductionCommentLikeCount.toString()
    },
    contentUserProductionCommentReportCount(o: UserDomain): string {
        return o.contentUserProductionCommentReportCount.toString()
    },
    contentUserProductionCommentReportedCount(o: UserDomain): string {
        return o.contentUserProductionCommentReportedCount.toString()
    },
    contentUserProductionReviewPositiveCount(o: UserDomain): string {
        return o.contentUserProductionReviewPositiveCount.toString()
    },
    contentUserProductionReviewNeutralCount(o: UserDomain): string {
        return o.contentUserProductionReviewNeutralCount.toString()
    },
    contentUserProductionReviewNegativeCount(o: UserDomain): string {
        return o.contentUserProductionReviewNegativeCount.toString()
    },
    userBlockCount(o: UserDomain): string {
        return o.userBlockCount.toString()
    },
    createdAt(o: UserDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
