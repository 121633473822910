export enum ContentScriptVisibility {
    /**
     * 전체공개
     */
    Visible = 'Visible',

    /**
     * 빈칸
     */
    Blank = 'Blank',

    /**
     * 비공개
     */
    Invisible = 'Invisible'
}

export namespace ContentScriptVisibility {
    export function description(o: ContentScriptVisibility) {
        switch (o) {
            case ContentScriptVisibility.Visible: return '전체공개'
            case ContentScriptVisibility.Blank: return '빈칸'
            case ContentScriptVisibility.Invisible: return '비공개'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: ContentScriptVisibility.Visible, label: description(ContentScriptVisibility.Visible) },
        { value: ContentScriptVisibility.Blank, label: description(ContentScriptVisibility.Blank) },
        { value: ContentScriptVisibility.Invisible, label: description(ContentScriptVisibility.Invisible) }
    ]
}
