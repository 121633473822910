import {UserWithdrawalReasonDomain} from "../../domain/user/UserWithdrawalReasonDomain"

/**
 * 사용자 탈퇴 사유 속성 설명
 */
export const UserWithdrawalReasonDescription: Record<keyof UserWithdrawalReasonDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    body: '사유',
    displayOrder: '표시 순서',
    userWithdrawalCount: '탈퇴 사유 적용 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
