import {UserBlockModificationVerboseDomain} from "../../domain/user/UserBlockModificationVerboseDomain"
import {UserBlockId} from "../../id/user/UserBlockId"
import {UserBlockModificationId} from "../../id/user/UserBlockModificationId"
import {UserBlockReasonId} from "../../id/user/UserBlockReasonId"
import {UserId} from "../../id/user/UserId"
import {UserBlockReasonValueObject} from "./UserBlockReasonValueObject"
import {UserBlockValueObject} from "./UserBlockValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 정지 수정 이력 상세
 */
export class UserBlockModificationVerboseValueObject implements VerboseValueObject, UserBlockModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UserBlockModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 이용 정지 ID
     */
    userBlockId: UserBlockId
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReasonId: UserBlockReasonId
    /**
     * 이용 정지 시작일
     */
    blockedAt: Date
    /**
     * 이용 정지 종료일
     */
    blockedUntil?: Date
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 이용 정지 ID
     */
    userBlock: UserBlockValueObject
    /**
     * 사용자 이용 정지 사유 ID
     */
    userBlockReason: UserBlockReasonValueObject

    constructor(o: Record<keyof UserBlockModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userBlockId = BigInt(o.userBlockId)
        this.userBlockReasonId = BigInt(o.userBlockReasonId)
        this.blockedAt = new Date(o.blockedAt)
        if (o.blockedUntil !== undefined && o.blockedUntil !== null) {
            this.blockedUntil = new Date(o.blockedUntil)
        }
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.userBlock = new UserBlockValueObject(o.userBlock)
        this.userBlockReason = new UserBlockReasonValueObject(o.userBlockReason)
    }
}
