import {UserRankHistoryDomain} from "../../domain/user/UserRankHistoryDomain"

/**
 * 사용자 랭크 기록 속성 설명
 */
export const UserRankHistoryDescription: Record<keyof UserRankHistoryDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    userRankId: '사용자 랭크 ID',
    assetLunarUsageId: '루나 지급 ID',
    assetSolarUsageId: '솔라 지급 ID',
    assetStarUsageId: '스타 지급 ID',
    createdAt: '생성일'
}
