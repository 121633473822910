import {UiPolicyDomain} from "../../domain/ui/UiPolicyDomain"

export const UiPolicyDescriptor: Record<keyof UiPolicyDomain, (o: UiPolicyDomain) => string> = {
    id(o: UiPolicyDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiPolicyDomain): string {
        return o.creatorId.toString()
    },
    uiHomeContentSuggestionPageSize(o: UiPolicyDomain): string {
        return o.uiHomeContentSuggestionPageSize.toString()
    },
    uiHomeContentUserProductionPageSize(o: UiPolicyDomain): string {
        return o.uiHomeContentUserProductionPageSize.toString()
    },
    uiHomeNoticePageSize(o: UiPolicyDomain): string {
        return o.uiHomeNoticePageSize.toString()
    },
    uiOnboardingPageSize(o: UiPolicyDomain): string {
        return o.uiOnboardingPageSize.toString()
    },
    uiPolicyPageSize(o: UiPolicyDomain): string {
        return o.uiPolicyPageSize.toString()
    },
    createdAt(o: UiPolicyDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UiPolicyDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
