import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {EnvPhoneBlockIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostBigIntField,
    PostDateField,
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField
} from "../../PostField";
import {EnvPhoneBlockDescription} from "../../../data/description/env/EnvPhoneBlockDescription";
import {EnvCountryId} from "../../../data/id/env/EnvCountryId";
import {EnvCountrySearch} from "../country/Search";

enum Phase {
    Waiting,
    Posting,
    Finished,
    EnvCountryList,
    EnvCountryOption,
    EnvCountryOrder
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const envCountryId = useStatePair<EnvCountryId>(0n)
    const phone = useStatePair('')

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvPhoneBlockIO
            .post({
                envCountryId: envCountryId.value,
                phone: phone.value,
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [envCountryId.value, phone.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvPhoneBlockDescription.envCountryId}
                    showSearch
                    onSearchClick={() => phase.setter(Phase.EnvCountryList)}>
                    <PostBigIntField
                        {...props}
                        value={envCountryId.value}
                        setter={envCountryId.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvPhoneBlockDescription.phone}>
                    <PostInputField
                        {...props}
                        value={phone.value}
                        setter={phone.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <EnvCountrySearch
            {...props}
            phase={phase}
            phases={{ waiting: Phase.Waiting, list: Phase.EnvCountryList, option: Phase.EnvCountryOption, order: Phase.EnvCountryOrder }}
            setter={value => envCountryId.setter(value.id)} />
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/phoneBlocks'}/>
    </>
}