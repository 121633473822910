import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {EnvClientIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostDateField,
    PostEnumField,
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField
} from "../../PostField";
import {EnvClientPlatform} from "../../../data/constant/env/EnvClientPlatform";
import {EnvClientDistribution} from "../../../data/constant/env/EnvClientDistribution";
import {unwrapUndefinedDate} from "ts-type-extension";
import {EnvClientDescription} from "../../../data/description/env/EnvClientDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const platform = useStatePair<EnvClientPlatform>(EnvClientPlatform.AdminWeb)
    const distribution = useStatePair<EnvClientDistribution>(EnvClientDistribution.LocalTest)
    const versionName = useStatePair('')
    const expiredAt = useStatePair<string | undefined>(undefined)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvClientIO
            .post({
                platform: platform.value,
                distribution: distribution.value,
                versionName: versionName.value,
                expiredAt: unwrapUndefinedDate(expiredAt.value)
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [platform.value, distribution.value, versionName.value, expiredAt.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvClientDescription.platform}>
                    <PostEnumField
                        {...props}
                        value={platform.value}
                        setter={platform.setter}
                        selections={EnvClientPlatform.selections} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvClientDescription.distribution}>
                    <PostEnumField
                        {...props}
                        value={distribution.value}
                        setter={distribution.setter}
                        selections={EnvClientDistribution.selections} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvClientDescription.versionName}>
                    <PostInputField
                        {...props}
                        value={versionName.value}
                        setter={versionName.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvClientDescription.expiredAt}
                    showUnusedCheckbox
                    isUnusedCheckboxChecked={expiredAt.value === undefined}
                    onUnusedCheckboxChange={checked => expiredAt.setter(checked ? undefined : '')}>
                    <PostDateField
                        {...props}
                        disabled={expiredAt.value === undefined}
                        value={expiredAt.value}
                        setter={expiredAt.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/clients'}/>
    </>
}