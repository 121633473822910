import {UserBlockReasonDomain} from "../../domain/user/UserBlockReasonDomain"

export const UserBlockReasonDescriptor: Record<keyof UserBlockReasonDomain, (o: UserBlockReasonDomain) => string> = {
    id(o: UserBlockReasonDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserBlockReasonDomain): string {
        return o.creatorId.toString()
    },
    body(o: UserBlockReasonDomain): string {
        return o.body
    },
    userBlockCount(o: UserBlockReasonDomain): string {
        return o.userBlockCount.toString()
    },
    createdAt(o: UserBlockReasonDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserBlockReasonDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: UserBlockReasonDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
