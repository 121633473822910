import {DetailDefaultPanel, DetailDefaultTab} from "../../../../DetailViews";
import {RouterProps} from "../../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentQueryPopularPreemptionValueObject
} from "../../../../../data/value-object/content/ContentQueryPopularPreemptionValueObject";
import {useEffect} from "react";
import {ContentQueryPopularPreemptionIO} from "../../../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {
    ContentQueryPopularPreemptionDomain
} from "../../../../../data/domain/content/ContentQueryPopularPreemptionDomain";
import {
    ContentQueryPopularPreemptionDescriptor
} from "../../../../../data/descriptor/content/ContentQueryPopularPreemptionDescriptor";
import {
    ContentQueryPopularPreemptionDescription
} from "../../../../../data/description/content/ContentQueryPopularPreemptionDescription";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentQueryPopularPreemptionId: string }>()
    const item = useStatePair<ContentQueryPopularPreemptionValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentQueryPopularPreemptionId !== undefined) {
            ContentQueryPopularPreemptionIO
                .getById(BigInt(params.contentQueryPopularPreemptionId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentQueryPopularPreemptionValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentQueryPopularPreemptionIO}
                description={ContentQueryPopularPreemptionDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentQueryPopularPreemptionDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "contentType":
        case "query":
        case "exposedAt":
        case "exposedUntil":
        case "displayOrder":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{ContentQueryPopularPreemptionDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentQueryPopularPreemptionDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}