import {ContentLikeDomain} from "../../domain/content/ContentLikeDomain"
import {ContentId} from "../../id/content/ContentId"
import {ContentLikeId} from "../../id/content/ContentLikeId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 좋아요
 */
export class ContentLikeValueObject implements ValueObject, ContentLikeDomain {
    /**
     * 기본키
     */
    id: ContentLikeId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentLikeDomain, any>) {
        this.id = BigInt(o.id)
        this.contentId = BigInt(o.contentId)
        this.userId = BigInt(o.userId)
        this.createdAt = new Date(o.createdAt)
    }
}
