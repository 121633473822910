import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvClientPlatform} from "../../constant/env/EnvClientPlatform"
import {EnvClientVerboseDomain} from "../../domain/env/EnvClientVerboseDomain"
import {EnvClientId} from "../../id/env/EnvClientId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 클라이언트 상세
 */
export class EnvClientVerboseValueObject implements VerboseValueObject, EnvClientVerboseDomain {
    /**
     * 기본키
     */
    id: EnvClientId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 플랫폼 유형
     */
    platform: EnvClientPlatform
    /**
     * 배포 유형
     */
    distribution: EnvClientDistribution
    /**
     * 이름
     */
    versionName: string
    /**
     * 만료일
     */
    expiredAt?: Date
    /**
     * 로그인한 사용자 수
     */
    userSignInCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof EnvClientVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.platform = o.platform as EnvClientPlatform
        this.distribution = o.distribution as EnvClientDistribution
        this.versionName = String(o.versionName)
        if (o.expiredAt !== undefined && o.expiredAt !== null) {
            this.expiredAt = new Date(o.expiredAt)
        }
        this.userSignInCount = Number(o.userSignInCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
