import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserDomain} from "../data/domain/user/UserDomain";
import {useEffect} from "react";
import {UserIO} from "../io/UserIO";
import {makeStyles, Spinner} from "@fluentui/react-components";
import SignInPage from "./SignInPage";
import AssetRouter from "./asset/AssetRouter";
import ContentRouter from "./content/ContentRouter";
import EnvRouter from "./env/EnvRouter";
import UserRouter from "./user/UserRouter";
import HomePage from "./HomePage";
import NotFoundPage from "./NotFoundPage";
import PageWrapper from "./PageWrapper";
import {EnvPolicyDomain} from "../data/domain/env/EnvPolicyDomain";
import {EnvPolicyIO} from "../io/EnvIO";
import {RouterProps} from "./RouterProps";
import UiRouter from "./ui/UiRouter";

const useStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    row4: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '4px'
    },
    row8: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '8px'
    },
    row12: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '12px'
    },
    row16: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '16px'
    },
    rowReverse: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    rowReverse4: {
        display: 'flex',
        flexDirection: 'row-reverse',
        columnGap: '4px'
    },
    rowReverse8: {
        display: 'flex',
        flexDirection: 'row-reverse',
        columnGap: '8px'
    },
    rowReverse12: {
        display: 'flex',
        flexDirection: 'row-reverse',
        columnGap: '12px'
    },
    rowReverse16: {
        display: 'flex',
        flexDirection: 'row-reverse',
        columnGap: '16px'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    column4: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '4px'
    },
    column8: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px'
    },
    column12: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px'
    },
    column16: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px'
    },
    navigationButton: {
        width: '360px',
        justifyContent: 'space-between'
    }
})

export default function RootRouter() {
    const styles = useStyles()
    const user = useStatePair<UserDomain | false | undefined>(undefined)
    const envPolicy = useStatePair<EnvPolicyDomain | undefined>(undefined)
    useEffect(() => {
        UserIO.getCurrent()
            .then(user.setter)
            .catch(reason => {
                user.setter(false)
            })
    }, []);

    useEffect(() => {
        EnvPolicyIO.getLatest()
            .then(envPolicy.setter)
            .catch(reason => {
                console.error(reason)
                alert('환경 정책을 불러올 수 없습니다.')
            })
    }, []);

    if (user.value === undefined || envPolicy.value === undefined) {
        // 로딩중
        return <Spinner />
    } else if (user.value === false) {
        // 로그인 필요
        return <>
            <BrowserRouter>
                <Routes>
                    <Route path={'*'} element={<SignInPage />} />
                </Routes>
            </BrowserRouter>
        </>
    } else {
        const props: RouterProps = {
            user: user.value,
            envPolicy: envPolicy.value,
            styles: styles
        }

        return <>
            <PageWrapper {...props}>
                <BrowserRouter>
                    <Routes>
                        <Route path={'/'} element={<HomePage {...props} />} />
                        <Route path={'/assets/*'} element={<AssetRouter {...props} />} />
                        <Route path={'/contents/*'} element={<ContentRouter {...props} />} />
                        <Route path={'/env/*'} element={<EnvRouter {...props} />} />
                        <Route path={'/ui/*'} element={<UiRouter {...props} />} />
                        <Route path={'/users/*'} element={<UserRouter {...props} />} />
                        <Route path={'/*'} element={<NotFoundPage />} />
                    </Routes>
                </BrowserRouter>
            </PageWrapper>
        </>
    }
}