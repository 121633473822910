import {UiHomeNoticeModificationDomain} from "../../domain/ui/UiHomeNoticeModificationDomain"

/**
 * 홈 공지 및 이벤트 수정 이력 속성 설명
 */
export const UiHomeNoticeModificationDescription: Record<keyof UiHomeNoticeModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    uiHomeNoticeId: '홈 공지 및 이벤트 ID',
    displayOrder: '표시 우선 순위',
    url: 'URL',
    createdAt: '수정일'
}
