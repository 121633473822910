import {UiOnboardingModificationVerboseDomain} from "../../domain/ui/UiOnboardingModificationVerboseDomain"
import {UiOnboardingId} from "../../id/ui/UiOnboardingId"
import {UiOnboardingModificationId} from "../../id/ui/UiOnboardingModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {UiOnboardingValueObject} from "./UiOnboardingValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 온보딩 이미지 수정 이력 상세
 */
export class UiOnboardingModificationVerboseValueObject implements VerboseValueObject, UiOnboardingModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UiOnboardingModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 온보딩 이미지 ID
     */
    uiOnboardingId: UiOnboardingId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 온보딩 이미지 ID
     */
    uiOnboarding: UiOnboardingValueObject

    constructor(o: Record<keyof UiOnboardingModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiOnboardingId = BigInt(o.uiOnboardingId)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.uiOnboarding = new UiOnboardingValueObject(o.uiOnboarding)
    }
}
