import {ContentQuerySearchOption} from "../../search-option/content/ContentQuerySearchOption"

/**
 * 콘텐츠 검색 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentQuerySearchOptionDescription: Record<keyof ContentQuerySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    contentTypeEq: '콘텐츠 유형 일치',
    contentTypeNeq: '콘텐츠 유형 불일치',
    contentTypeMin: '콘텐츠 유형 최소',
    contentTypeMax: '콘텐츠 유형 최대',
    contentTypeIn: '콘텐츠 유형 포함',
    bodyEq: '질의어 일치',
    bodyNeq: '질의어 불일치',
    bodyMin: '질의어 최소',
    bodyMax: '질의어 최대',
    bodyLike: '질의어 포함',
    bodyNotLike: '질의어 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
