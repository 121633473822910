import {ContentReviewDomain} from "../../domain/content/ContentReviewDomain"

export const ContentReviewDescriptor: Record<keyof ContentReviewDomain, (o: ContentReviewDomain) => string> = {
    id(o: ContentReviewDomain): string {
        return o.id.toString()
    },
    userId(o: ContentReviewDomain): string {
        return o.userId.toString()
    },
    contentId(o: ContentReviewDomain): string {
        return o.contentId.toString()
    },
    review(o: ContentReviewDomain): string {
        return o.review.toString()
    },
    createdAt(o: ContentReviewDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
