import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {EnvCountryValueObject} from "../../../data/value-object/env/EnvCountryValueObject";
import {EnvCountrySearchOption} from "../../../data/search-option/env/EnvCountrySearchOption";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvCountryIO} from "../../../io/EnvIO";
import {EnvCountryDescription} from "../../../data/description/env/EnvCountryDescription";
import {EnvCountryDescriptor} from "../../../data/descriptor/env/EnvCountryDescriptor";
import ListOption from "../../ListOption";
import {EnvCountrySearchOptionDescription} from "../../../data/description/env/EnvCountrySearchOptionDescription";
import {
    applyEnvCountrySearchOption,
    EnvCountrySearchOptionField
} from "../../search-option-field/env/EnvCountrySearchOptionField";
import ListOrder from "../../ListOrder";
import {SearchIndex} from "../../../io/HttpClient";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<EnvCountryValueObject[]>([])
    const option = useStatePair<EnvCountrySearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={EnvCountryIO}
                items={items}
                option={option.value}
                index={index}
                description={EnvCountryDescription}
                descriptor={EnvCountryDescriptor}
                showPost
                onPostClick={() => document.location = '/env/countries/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/env/countries/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={EnvCountrySearchOptionDescription}
                fields={EnvCountrySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyEnvCountrySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvCountryDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}