import {AssetLunarExchangeOptionDomain} from "../../domain/asset/AssetLunarExchangeOptionDomain"

/**
 * 루나 교환 옵션 속성 설명
 */
export const AssetLunarExchangeOptionDescription: Record<keyof AssetLunarExchangeOptionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    lunarAmount: '지급 루나',
    solarAmount: '소모 솔라',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
