import {UiPolicySearchOption} from "../../search-option/ui/UiPolicySearchOption"

/**
 * UI 정책 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UiPolicySearchOptionDescription: Record<keyof UiPolicySearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    uiHomeContentSuggestionPageSizeEq: '홈 추천 콘텐츠 페이지 크기 일치',
    uiHomeContentSuggestionPageSizeNeq: '홈 추천 콘텐츠 페이지 크기 불일치',
    uiHomeContentSuggestionPageSizeMin: '홈 추천 콘텐츠 페이지 크기 최소',
    uiHomeContentSuggestionPageSizeMax: '홈 추천 콘텐츠 페이지 크기 최대',
    uiHomeContentUserProductionPageSizeEq: '홈 와글와글 콘텐츠 페이지 크기 일치',
    uiHomeContentUserProductionPageSizeNeq: '홈 와글와글 콘텐츠 페이지 크기 불일치',
    uiHomeContentUserProductionPageSizeMin: '홈 와글와글 콘텐츠 페이지 크기 최소',
    uiHomeContentUserProductionPageSizeMax: '홈 와글와글 콘텐츠 페이지 크기 최대',
    uiHomeNoticePageSizeEq: '홈 공지사항 페이지 크기 일치',
    uiHomeNoticePageSizeNeq: '홈 공지사항 페이지 크기 불일치',
    uiHomeNoticePageSizeMin: '홈 공지사항 페이지 크기 최소',
    uiHomeNoticePageSizeMax: '홈 공지사항 페이지 크기 최대',
    uiOnboardingPageSizeEq: '온보딩 페이지 크기 일치',
    uiOnboardingPageSizeNeq: '온보딩 페이지 크기 불일치',
    uiOnboardingPageSizeMin: '온보딩 페이지 크기 최소',
    uiOnboardingPageSizeMax: '온보딩 페이지 크기 최대',
    uiPolicyPageSizeEq: 'UI 정책 페이지 크기 일치',
    uiPolicyPageSizeNeq: 'UI 정책 페이지 크기 불일치',
    uiPolicyPageSizeMin: 'UI 정책 페이지 크기 최소',
    uiPolicyPageSizeMax: 'UI 정책 페이지 크기 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
