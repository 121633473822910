import {UserRankHistoryVerboseDomain} from "../../domain/user/UserRankHistoryVerboseDomain"
import {AssetLunarUsageId} from "../../id/asset/AssetLunarUsageId"
import {AssetSolarUsageId} from "../../id/asset/AssetSolarUsageId"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {UserId} from "../../id/user/UserId"
import {UserRankHistoryId} from "../../id/user/UserRankHistoryId"
import {UserRankId} from "../../id/user/UserRankId"
import {AssetLunarUsageValueObject} from "../asset/AssetLunarUsageValueObject"
import {AssetSolarUsageValueObject} from "../asset/AssetSolarUsageValueObject"
import {AssetStarUsageValueObject} from "../asset/AssetStarUsageValueObject"
import {UserRankValueObject} from "./UserRankValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 랭크 기록 상세
 */
export class UserRankHistoryVerboseValueObject implements VerboseValueObject, UserRankHistoryVerboseDomain {
    /**
     * 기본키
     */
    id: UserRankHistoryId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 사용자 랭크 ID
     */
    userRankId: UserRankId
    /**
     * 루나 지급 ID
     */
    assetLunarUsageId?: AssetLunarUsageId
    /**
     * 솔라 지급 ID
     */
    assetSolarUsageId?: AssetSolarUsageId
    /**
     * 스타 지급 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 사용자 랭크 ID
     */
    userRank: UserRankValueObject
    /**
     * 루나 지급 ID
     */
    assetLunarUsage?: AssetLunarUsageValueObject
    /**
     * 솔라 지급 ID
     */
    assetSolarUsage?: AssetSolarUsageValueObject
    /**
     * 스타 지급 ID
     */
    assetStarUsage?: AssetStarUsageValueObject

    constructor(o: Record<keyof UserRankHistoryVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.userRankId = BigInt(o.userRankId)
        if (o.assetLunarUsageId !== undefined && o.assetLunarUsageId !== null) {
            this.assetLunarUsageId = BigInt(o.assetLunarUsageId)
        }
        if (o.assetSolarUsageId !== undefined && o.assetSolarUsageId !== null) {
            this.assetSolarUsageId = BigInt(o.assetSolarUsageId)
        }
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.userRank = new UserRankValueObject(o.userRank)
        if (o.assetLunarUsage !== undefined && o.assetLunarUsage !== null) {
            this.assetLunarUsage = new AssetLunarUsageValueObject(o.assetLunarUsage)
        }
        if (o.assetSolarUsage !== undefined && o.assetSolarUsage !== null) {
            this.assetSolarUsage = new AssetSolarUsageValueObject(o.assetSolarUsage)
        }
        if (o.assetStarUsage !== undefined && o.assetStarUsage !== null) {
            this.assetStarUsage = new AssetStarUsageValueObject(o.assetStarUsage)
        }
    }
}
