import {UserSignInDomain} from "../../domain/user/UserSignInDomain"

export const UserSignInDescriptor: Record<keyof UserSignInDomain, (o: UserSignInDomain) => string> = {
    id(o: UserSignInDomain): string {
        return o.id.toString()
    },
    userId(o: UserSignInDomain): string {
        return o.userId.toString()
    },
    envClientId(o: UserSignInDomain): string {
        return o.envClientId.toString()
    },
    remoteAddress(o: UserSignInDomain): string {
        return o.remoteAddress
    },
    deviceName(o: UserSignInDomain): string {
        return o.deviceName
    },
    deviceVersion(o: UserSignInDomain): string {
        return o.deviceVersion
    },
    createdAt(o: UserSignInDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
