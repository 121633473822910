import {ContentResultUrlClickDomain} from "../../domain/content/ContentResultUrlClickDomain"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {ContentResultUrlClickId} from "../../id/content/ContentResultUrlClickId"
import {ContentResultUrlId} from "../../id/content/ContentResultUrlId"
import {UserExpId} from "../../id/user/UserExpId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 참여 결과 URL 클릭
 */
export class ContentResultUrlClickValueObject implements ValueObject, ContentResultUrlClickDomain {
    /**
     * 기본키
     */
    id: ContentResultUrlClickId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 참여 결과 URL ID
     */
    contentResultUrlId: ContentResultUrlId
    /**
     * 스타 지급 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 사용자 경험치 지급 ID
     */
    userExpId?: UserExpId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentResultUrlClickDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentResultUrlId = BigInt(o.contentResultUrlId)
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        if (o.userExpId !== undefined && o.userExpId !== null) {
            this.userExpId = BigInt(o.userExpId)
        }
        this.createdAt = new Date(o.createdAt)
    }
}
