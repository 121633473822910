import {UserNotificationStatus} from "../../../data/constant/user/UserNotificationStatus"
import {UserNotificationSubject} from "../../../data/constant/user/UserNotificationSubject"
import {UserNotificationSearchOptionDescription} from "../../../data/description/user/UserNotificationSearchOptionDescription"
import {UserNotificationSearchOption} from "../../../data/search-option/user/UserNotificationSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"
import {BitMaskFlag} from "ts-protocol-extension"

export function UserNotificationSearchOptionField(props: ListOptionFieldProps<UserNotificationSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.titleEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.titleNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.titleMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.titleMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.titleLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.titleNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.bodyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.bodyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.bodyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.bodyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.bodyLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "bodyNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.bodyNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "subjectEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.subjectEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserNotificationSubject.selections} />
                </ListOptionWrapper>
            </>
        case "subjectNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.subjectNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserNotificationSubject.selections} />
                </ListOptionWrapper>
            </>
        case "subjectMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.subjectMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserNotificationSubject.selections} />
                </ListOptionWrapper>
            </>
        case "subjectMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.subjectMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={UserNotificationSubject.selections} />
                </ListOptionWrapper>
            </>
        case "subjectIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.subjectIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserNotificationSubject.selections} />
                </ListOptionWrapper>
            </>
        case "deepLinkEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deepLinkNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deepLinkMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deepLinkMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deepLinkLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deepLinkNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "deepLinkIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.deepLinkIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "statusFlagsEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.statusFlagsEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserNotificationStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.statusFlagsNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserNotificationStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsAny":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.statusFlagsAny}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserNotificationStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsAll":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.statusFlagsAll}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserNotificationStatus.selections} />
                </ListOptionWrapper>
            </>
        case "statusFlagsNone":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.statusFlagsNone}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={UserNotificationStatus.selections} />
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserNotificationSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserNotificationSearchOption(key: keyof UserNotificationSearchOption, value: string, into: StatePair<UserNotificationSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "titleEq":
            into.setter({
                ...into.value,
                titleEq: String(value)
            })
            break
        case "titleNeq":
            into.setter({
                ...into.value,
                titleNeq: String(value)
            })
            break
        case "titleMin":
            into.setter({
                ...into.value,
                titleMin: String(value)
            })
            break
        case "titleMax":
            into.setter({
                ...into.value,
                titleMax: String(value)
            })
            break
        case "titleLike":
            into.setter({
                ...into.value,
                titleLike: String(value)
            })
            break
        case "titleNotLike":
            into.setter({
                ...into.value,
                titleNotLike: String(value)
            })
            break
        case "bodyEq":
            into.setter({
                ...into.value,
                bodyEq: String(value)
            })
            break
        case "bodyNeq":
            into.setter({
                ...into.value,
                bodyNeq: String(value)
            })
            break
        case "bodyMin":
            into.setter({
                ...into.value,
                bodyMin: String(value)
            })
            break
        case "bodyMax":
            into.setter({
                ...into.value,
                bodyMax: String(value)
            })
            break
        case "bodyLike":
            into.setter({
                ...into.value,
                bodyLike: String(value)
            })
            break
        case "bodyNotLike":
            into.setter({
                ...into.value,
                bodyNotLike: String(value)
            })
            break
        case "subjectEq":
            into.setter({
                ...into.value,
                subjectEq: value as UserNotificationSubject
            })
            break
        case "subjectNeq":
            into.setter({
                ...into.value,
                subjectNeq: value as UserNotificationSubject
            })
            break
        case "subjectMin":
            into.setter({
                ...into.value,
                subjectMin: value as UserNotificationSubject
            })
            break
        case "subjectMax":
            into.setter({
                ...into.value,
                subjectMax: value as UserNotificationSubject
            })
            break
        case "subjectIn":
            into.setter({
                ...into.value,
                subjectIn: value
                    .split('|')
                    .map(e => e as UserNotificationSubject)
            })
            break
        case "deepLinkEq":
            into.setter({
                ...into.value,
                deepLinkEq: String(value)
            })
            break
        case "deepLinkNeq":
            into.setter({
                ...into.value,
                deepLinkNeq: String(value)
            })
            break
        case "deepLinkMin":
            into.setter({
                ...into.value,
                deepLinkMin: String(value)
            })
            break
        case "deepLinkMax":
            into.setter({
                ...into.value,
                deepLinkMax: String(value)
            })
            break
        case "deepLinkLike":
            into.setter({
                ...into.value,
                deepLinkLike: String(value)
            })
            break
        case "deepLinkNotLike":
            into.setter({
                ...into.value,
                deepLinkNotLike: String(value)
            })
            break
        case "deepLinkIsNull":
            into.setter({
                ...into.value,
                deepLinkIsNull: Boolean(value)
            })
            break
        case "statusFlagsEq":
            into.setter({
                ...into.value,
                statusFlagsEq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsNeq":
            into.setter({
                ...into.value,
                statusFlagsNeq: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsAny":
            into.setter({
                ...into.value,
                statusFlagsAny: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsAll":
            into.setter({
                ...into.value,
                statusFlagsAll: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "statusFlagsNone":
            into.setter({
                ...into.value,
                statusFlagsNone: value
                    .split('|')
                    .filter(e => e.length !== 0)
                    .reduce((acc, element) => acc | Number(element), 0)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
