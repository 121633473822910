import {UiOnboardingSearchOption} from "../../search-option/ui/UiOnboardingSearchOption"

/**
 * 온보딩 이미지 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UiOnboardingSearchOptionDescription: Record<keyof UiOnboardingSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    displayOrderEq: '표시 우선 순위 일치',
    displayOrderNeq: '표시 우선 순위 불일치',
    displayOrderMin: '표시 우선 순위 최소',
    displayOrderMax: '표시 우선 순위 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
