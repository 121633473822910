import {AssetLunarUsageType} from "../../constant/asset/AssetLunarUsageType"
import {AssetLunarUsageVerboseDomain} from "../../domain/asset/AssetLunarUsageVerboseDomain"
import {AssetLunarUsageId} from "../../id/asset/AssetLunarUsageId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 루나 사용 및 지급 이력 상세
 */
export class AssetLunarUsageVerboseValueObject implements VerboseValueObject, AssetLunarUsageVerboseDomain {
    /**
     * 기본키
     */
    id: AssetLunarUsageId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 유형
     */
    type: AssetLunarUsageType
    /**
     * 변화
     */
    amount: number
    /**
     * 직전 누계
     */
    lastAccumulation: number
    /**
     * 지급 누계
     */
    positiveAccumulation: number
    /**
     * 사용 누계
     */
    negativeAccumulation: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof AssetLunarUsageVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userId = BigInt(o.userId)
        this.type = o.type as AssetLunarUsageType
        this.amount = Number(o.amount)
        this.lastAccumulation = Number(o.lastAccumulation)
        this.positiveAccumulation = Number(o.positiveAccumulation)
        this.negativeAccumulation = Number(o.negativeAccumulation)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.user = new UserValueObject(o.user)
    }
}
