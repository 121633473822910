import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentResultUrlVerboseDomain} from "../../domain/content/ContentResultUrlVerboseDomain"
import {ContentResultUrlId} from "../../id/content/ContentResultUrlId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 참여 결과 URL 상세
 */
export class ContentResultUrlVerboseValueObject implements VerboseValueObject, ContentResultUrlVerboseDomain {
    /**
     * 기본키
     */
    id: ContentResultUrlId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 브라우저
     */
    browser: EnvBrowser
    /**
     * 참여 결과 URL
     */
    url: string
    /**
     * 클릭 수
     */
    clickCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof ContentResultUrlVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.browser = o.browser as EnvBrowser
        this.url = String(o.url)
        this.clickCount = Number(o.clickCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
