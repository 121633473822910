import {EnvEventModificationDomain} from "../../domain/env/EnvEventModificationDomain"
import {EnvEventId} from "../../id/env/EnvEventId"
import {EnvEventModificationId} from "../../id/env/EnvEventModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 이벤트 수정 이력
 */
export class EnvEventModificationValueObject implements ValueObject, EnvEventModificationDomain {
    /**
     * 기본키
     */
    id: EnvEventModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 이벤트 ID
     */
    envEventId: EnvEventId
    /**
     * 제목
     */
    title: string
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvEventModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envEventId = BigInt(o.envEventId)
        this.title = String(o.title)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
    }
}
