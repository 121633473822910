export enum EnvClientPlatform {
    /**
     * 관리자 웹
     */
    AdminWeb = 'AdminWeb',

    /**
     * 사용자 Android
     */
    UserAndroid = 'UserAndroid',

    /**
     * 사용자 iOS
     */
    UserIphoneos = 'UserIphoneos',

    /**
     * 개발자 cURL
     */
    DevCurl = 'DevCurl',

    /**
     * 개발자 Postman
     */
    DevPostman = 'DevPostman',

    /**
     * 개발자 IntelliJ IDEA
     */
    DevIdea = 'DevIdea'
}

export namespace EnvClientPlatform {
    export function description(o: EnvClientPlatform) {
        switch (o) {
            case EnvClientPlatform.AdminWeb: return '관리자 웹'
            case EnvClientPlatform.UserAndroid: return '사용자 Android'
            case EnvClientPlatform.UserIphoneos: return '사용자 iOS'
            case EnvClientPlatform.DevCurl: return '개발자 cURL'
            case EnvClientPlatform.DevPostman: return '개발자 Postman'
            case EnvClientPlatform.DevIdea: return '개발자 IntelliJ IDEA'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: EnvClientPlatform.AdminWeb, label: description(EnvClientPlatform.AdminWeb) },
        { value: EnvClientPlatform.UserAndroid, label: description(EnvClientPlatform.UserAndroid) },
        { value: EnvClientPlatform.UserIphoneos, label: description(EnvClientPlatform.UserIphoneos) },
        { value: EnvClientPlatform.DevCurl, label: description(EnvClientPlatform.DevCurl) },
        { value: EnvClientPlatform.DevPostman, label: description(EnvClientPlatform.DevPostman) },
        { value: EnvClientPlatform.DevIdea, label: description(EnvClientPlatform.DevIdea) }
    ]
}
