import {EnvFaqItemDomain} from "../../domain/env/EnvFaqItemDomain"
import {EnvFaqId} from "../../id/env/EnvFaqId"
import {EnvFaqItemId} from "../../id/env/EnvFaqItemId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 자주 묻는 질문 항목
 */
export class EnvFaqItemValueObject implements ValueObject, EnvFaqItemDomain {
    /**
     * 기본키
     */
    id: EnvFaqItemId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 자주 묻는 질문 ID
     */
    envFaqId: EnvFaqId
    /**
     * 제목
     */
    title: string
    /**
     * 내용
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof EnvFaqItemDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envFaqId = BigInt(o.envFaqId)
        this.title = String(o.title)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
