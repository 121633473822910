import {AssetLunarUsageType} from "../../../data/constant/asset/AssetLunarUsageType"
import {AssetLunarUsagePostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetLunarUsagePostDataTransferObjectDescription"
import {AssetLunarUsagePostState} from "../../../data/data-transfer-object-state/asset/AssetLunarUsagePostState"
import {AssetLunarUsagePostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetLunarUsagePostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetLunarUsagePostState
}

export const AssetLunarUsagePostField: Record<Capitalize<keyof AssetLunarUsagePostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    UserId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarUsagePostDataTransferObjectDescription.userId}>
                <PostBigIntField
                    {...props}
                    value={props.state.userId.value}
                    setter={props.state.userId.setter} />
            </PostFieldWrapper>
        )
    },
    Type(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarUsagePostDataTransferObjectDescription.type}>
                <PostEnumField
                    {...props}
                    value={props.state.type.value}
                    setter={props.state.type.setter}
                    selections={AssetLunarUsageType.selections} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarUsagePostDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetLunarUsagePostFields(props: PostFieldProps) {
    return <>
        <AssetLunarUsagePostField.UserId
            {...props}
            state={props.state} />
        <AssetLunarUsagePostField.Type
            {...props}
            state={props.state} />
        <AssetLunarUsagePostField.Amount
            {...props}
            state={props.state} />
    </>
}
