import {BitMaskFlag} from "ts-protocol-extension"

export const UserStatus: {
    ReceiveNoticeNotification: BitMaskFlag,
    HideBirthCountry: BitMaskFlag,
    RecommenderRewarded: BitMaskFlag,
    selections: { value: string, label: string }[]
} = new (class _ {
    /**
     * 공지사항 알림 수신
     */
    ReceiveNoticeNotification: BitMaskFlag = {
        value: 1 << 0,
        name: '공지사항 알림 수신'
    }

    /**
     * 출신 국가 숨김
     */
    HideBirthCountry: BitMaskFlag = {
        value: 1 << 1,
        name: '출신 국가 숨김'
    }

    /**
     * 추천인 보상 지급 완료
     */
    RecommenderRewarded: BitMaskFlag = {
        value: 1 << 2,
        name: '추천인 보상 지급 완료'
    }

    selections: { value: string, label: string }[] = [
        { value: this.ReceiveNoticeNotification.value.toString(), label: this.ReceiveNoticeNotification.name },
        { value: this.HideBirthCountry.value.toString(), label: this.HideBirthCountry.name },
        { value: this.RecommenderRewarded.value.toString(), label: this.RecommenderRewarded.name }
    ]
})()
