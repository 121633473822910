import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {EnvAgreementValueObject} from "../../../data/value-object/env/EnvAgreementValueObject";
import {EnvAgreementSearchOption} from "../../../data/search-option/env/EnvAgreementSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {EnvAgreementIO} from "../../../io/EnvIO";
import {EnvAgreementDescription} from "../../../data/description/env/EnvAgreementDescription";
import {EnvAgreementDescriptor} from "../../../data/descriptor/env/EnvAgreementDescriptor";
import ListOption from "../../ListOption";
import {EnvAgreementSearchOptionDescription} from "../../../data/description/env/EnvAgreementSearchOptionDescription";
import {
    applyEnvAgreementSearchOption,
    EnvAgreementSearchOptionField
} from "../../search-option-field/env/EnvAgreementSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<EnvAgreementValueObject[]>([])
    const option = useStatePair<EnvAgreementSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={EnvAgreementIO}
                items={items}
                option={option.value}
                index={index}
                description={EnvAgreementDescription}
                descriptor={EnvAgreementDescriptor}
                showPost
                onPostClick={() => document.location = '/env/agreements/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/env/agreements/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={EnvAgreementSearchOptionDescription}
                fields={EnvAgreementSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyEnvAgreementSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvAgreementDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}