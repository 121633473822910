import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentResultUrlValueObject
} from "../../../data/value-object/content/ContentResultUrlValueObject";
import {useEffect} from "react";
import {ContentResultUrlIO} from "../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {ContentResultUrlDescription} from "../../../data/description/content/ContentResultUrlDescription";
import {ContentResultUrlDomain} from "../../../data/domain/content/ContentResultUrlDomain";
import {ContentResultUrlDescriptor} from "../../../data/descriptor/content/ContentResultUrlDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentResultUrlId: string }>()
    const item = useStatePair<ContentResultUrlValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentResultUrlId !== undefined) {
            ContentResultUrlIO
                .getById(BigInt(params.contentResultUrlId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentResultUrlValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentResultUrlIO}
                description={ContentResultUrlDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentResultUrlDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "browser":
        case "url":
        case "clickCount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{ContentResultUrlDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentResultUrlDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}