import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {Button, Title3} from "@fluentui/react-components";
import React from "react";
import {PostFinishDialog} from "../../PostField";
import {AssetPolicyIO} from "../../../io/AssetIO";
import {useAssetPolicyPostState} from "../../../data/data-transfer-object-state/asset/AssetPolicyPostState";
import {AssetPolicyPostFields} from "../../data-transfer-object-field/asset/AssetPolicyPostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useAssetPolicyPostState()

    const onDoneClick = () => {
        phase.setter(Phase.Posting)
        AssetPolicyIO
            .post(state.toDataTransferObject())
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }

    return <>
        <div className={props.styles.column16}>
            <Title3>새 항목</Title3>
            <AssetPolicyPostFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/assets/policies'} />
    </>
}