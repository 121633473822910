import {AssetCouponVerboseDomain} from "../../domain/asset/AssetCouponVerboseDomain"
import {AssetCouponId} from "../../id/asset/AssetCouponId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 재화 쿠폰 상세
 */
export class AssetCouponVerboseValueObject implements VerboseValueObject, AssetCouponVerboseDomain {
    /**
     * 기본키
     */
    id: AssetCouponId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 코드
     */
    code: string
    /**
     * 수량
     */
    amount: number
    /**
     * 지급 루나
     */
    assetLunarAmount: number
    /**
     * 지급 솔라
     */
    assetSolarAmount: number
    /**
     * 지급 스타
     */
    assetStarAmount: number
    /**
     * 사용량
     */
    usageCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof AssetCouponVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.code = String(o.code)
        this.amount = Number(o.amount)
        this.assetLunarAmount = Number(o.assetLunarAmount)
        this.assetSolarAmount = Number(o.assetSolarAmount)
        this.assetStarAmount = Number(o.assetStarAmount)
        this.usageCount = Number(o.usageCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
