import {UiPolicyIO} from "../../../io/UiIO";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    UiPolicyValueObject
} from "../../../data/value-object/ui/UiPolicyValueObject";
import {
    UiPolicySearchOption
} from "../../../data/search-option/ui/UiPolicySearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {
    UiPolicyDescription
} from "../../../data/description/ui/UiPolicyDescription";
import {UiPolicyDescriptor} from "../../../data/descriptor/ui/UiPolicyDescriptor";
import ListOption from "../../ListOption";
import {
    UiPolicySearchOptionDescription
} from "../../../data/description/ui/UiPolicySearchOptionDescription";
import {
    applyUiPolicySearchOption,
    UiPolicySearchOptionField
} from "../../search-option-field/ui/UiPolicySearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UiPolicyValueObject[]>([])
    const option = useStatePair<UiPolicySearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UiPolicyIO}
                items={items}
                option={option.value}
                index={index}
                description={UiPolicyDescription}
                descriptor={UiPolicyDescriptor}
                showPost
                onPostClick={() => document.location = '/ui/policies/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/ui/policies/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UiPolicySearchOptionDescription}
                fields={UiPolicySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUiPolicySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UiPolicyDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}