import {UiHomeNoticeDomain} from "../../domain/ui/UiHomeNoticeDomain"

/**
 * 홈 공지 및 이벤트 속성 설명
 */
export const UiHomeNoticeDescription: Record<keyof UiHomeNoticeDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    displayOrder: '표시 우선 순위',
    url: 'URL',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
