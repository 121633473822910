import {UserWithdrawalReasonModificationDomain} from "../../domain/user/UserWithdrawalReasonModificationDomain"

export const UserWithdrawalReasonModificationDescriptor: Record<keyof UserWithdrawalReasonModificationDomain, (o: UserWithdrawalReasonModificationDomain) => string> = {
    id(o: UserWithdrawalReasonModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserWithdrawalReasonModificationDomain): string {
        return o.creatorId.toString()
    },
    userWithdrawalReasonId(o: UserWithdrawalReasonModificationDomain): string {
        return o.userWithdrawalReasonId.toString()
    },
    body(o: UserWithdrawalReasonModificationDomain): string {
        return o.body
    },
    displayOrder(o: UserWithdrawalReasonModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: UserWithdrawalReasonModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
