import {ContentParticipationDomain} from "../../domain/content/ContentParticipationDomain"

export const ContentParticipationDescriptor: Record<keyof ContentParticipationDomain, (o: ContentParticipationDomain) => string> = {
    id(o: ContentParticipationDomain): string {
        return o.id.toString()
    },
    userId(o: ContentParticipationDomain): string {
        return o.userId.toString()
    },
    contentId(o: ContentParticipationDomain): string {
        return o.contentId.toString()
    },
    assetSolarUsageId(o: ContentParticipationDomain): string {
        return o.assetSolarUsageId?.toString() ?? '-'
    },
    assetStarUsageId(o: ContentParticipationDomain): string {
        return o.assetStarUsageId?.toString() ?? '-'
    },
    audioId(o: ContentParticipationDomain): string {
        return o.audioId?.toString() ?? '-'
    },
    transcription(o: ContentParticipationDomain): string {
        return o.transcription
    },
    accuracy(o: ContentParticipationDomain): string {
        return o.accuracy.toString()
    },
    isPassed(o: ContentParticipationDomain): string {
        return o.isPassed.toString()
    },
    isHighAccuracy(o: ContentParticipationDomain): string {
        return o.isHighAccuracy.toString()
    },
    createdAt(o: ContentParticipationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
