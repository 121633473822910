import {UserGender} from "../../constant/user/UserGender"
import {UserRole} from "../../constant/user/UserRole"
import {UserVerboseDomain} from "../../domain/user/UserVerboseDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {UserId} from "../../id/user/UserId"
import {EnvCountryValueObject} from "../env/EnvCountryValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 상세
 */
export class UserVerboseValueObject implements VerboseValueObject, UserVerboseDomain {
    /**
     * 기본키
     */
    id: UserId
    /**
     * Firebase Authentication UID
     */
    authenticationUid: string
    /**
     * 역할
     */
    role: UserRole
    /**
     * 관리자 권한
     */
    adminPermission: number
    /**
     * 이메일
     */
    email: string
    /**
     * Firebase Cloud Messaging Token
     */
    cloudMessagingToken?: string
    /**
     * 닉네임
     */
    nickname: string
    /**
     * 출신 국가 ID
     */
    birthCountryId: EnvCountryId
    /**
     * 거주 국가 ID
     */
    residenceCountryId: EnvCountryId
    /**
     * 생년월일
     */
    birth: string
    /**
     * 성별
     */
    gender: UserGender
    /**
     * 전화번호 국가 ID
     */
    phoneCountryId?: EnvCountryId
    /**
     * 전화번호
     */
    phone?: string
    /**
     * 추천인 ID
     */
    recommenderId?: UserId
    /**
     * 추천 코드
     */
    recommendationCode: string
    /**
     * 상태 플래그
     */
    statusFlags: number
    /**
     * 추천한 사용자 수
     */
    recommendeeCount: number
    /**
     * 오늘의 단어 참여 수
     */
    contentDailyParticipationCount: number
    /**
     * 오늘의 단어 합격 수
     */
    contentDailyParticipationPassCount: number
    /**
     * 따라 말하기 좋아요 수
     */
    contentRepeatLikeCount: number
    /**
     * 따라 말하기 참여 수
     */
    contentRepeatParticipationCount: number
    /**
     * 따라 말하기 합격 수
     */
    contentRepeatParticipationPassCount: number
    /**
     * 따라 말하기 높은 일치율 수
     */
    contentRepeatParticipationHighAccuracyCount: number
    /**
     * 따라 말하기 댓글 수
     */
    contentRepeatCommentCount: number
    /**
     * 따라 말하기 댓글 좋아요 수
     */
    contentRepeatCommentLikeCount: number
    /**
     * 따라 말하기 댓글 신고 수
     */
    contentRepeatCommentReportCount: number
    /**
     * 따라 말하기 댓글 피신고 수
     */
    contentRepeatCommentReportedCount: number
    /**
     * 암기하기 좋아요 수
     */
    contentM10nLikeCount: number
    /**
     * 암기하기 참여 수
     */
    contentM10nParticipationCount: number
    /**
     * 암기하기 합격 수
     */
    contentM10nParticipationPassCount: number
    /**
     * 암기하기 높은 일치율 수
     */
    contentM10nParticipationHighAccuracyCount: number
    /**
     * 암기하기 댓글 수
     */
    contentM10nCommentCount: number
    /**
     * 암기하기 댓글 좋아요 수
     */
    contentM10nCommentLikeCount: number
    /**
     * 암기하기 댓글 신고 수
     */
    contentM10nCommentReportCount: number
    /**
     * 암기하기 댓글 피신고 수
     */
    contentM10nCommentReportedCount: number
    /**
     * 와글와글 수
     */
    contentUserProductionCount: number
    /**
     * 와글와글 좋아요 수
     */
    contentUserProductionLikeCount: number
    /**
     * 와글와글 참여 수
     */
    contentUserProductionParticipationCount: number
    /**
     * 와글와글 합격 수
     */
    contentUserProductionParticipationPassCount: number
    /**
     * 와글와글 높은 일치율 수
     */
    contentUserProductionParticipationHighAccuracyCount: number
    /**
     * 와글와글 신고 수
     */
    contentUserProductionReportCount: number
    /**
     * 와글와글 피신고 수
     */
    contentUserProductionReportedCount: number
    /**
     * 와글와글 댓글 수
     */
    contentUserProductionCommentCount: number
    /**
     * 와글와글 댓글 좋아요 수
     */
    contentUserProductionCommentLikeCount: number
    /**
     * 와글와글 댓글 신고 수
     */
    contentUserProductionCommentReportCount: number
    /**
     * 와글와글 댓글 피신고 수
     */
    contentUserProductionCommentReportedCount: number
    /**
     * 와글와글 긍정 평가 수
     */
    contentUserProductionReviewPositiveCount: number
    /**
     * 와글와글 중립 평가 수
     */
    contentUserProductionReviewNeutralCount: number
    /**
     * 와글와글 부정 평가 수
     */
    contentUserProductionReviewNegativeCount: number
    /**
     * 이용 정지 수
     */
    userBlockCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 출신 국가 ID
     */
    birthCountry: EnvCountryValueObject
    /**
     * 거주 국가 ID
     */
    residenceCountry: EnvCountryValueObject
    /**
     * 전화번호 국가 ID
     */
    phoneCountry?: EnvCountryValueObject
    /**
     * 추천인 ID
     */
    recommender?: UserValueObject

    constructor(o: Record<keyof UserVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.authenticationUid = String(o.authenticationUid)
        this.role = o.role as UserRole
        this.adminPermission = Number(o.adminPermission)
        this.email = String(o.email)
        if (o.cloudMessagingToken !== undefined && o.cloudMessagingToken !== null) {
            this.cloudMessagingToken = String(o.cloudMessagingToken)
        }
        this.nickname = String(o.nickname)
        this.birthCountryId = BigInt(o.birthCountryId)
        this.residenceCountryId = BigInt(o.residenceCountryId)
        this.birth = String(o.birth)
        this.gender = o.gender as UserGender
        if (o.phoneCountryId !== undefined && o.phoneCountryId !== null) {
            this.phoneCountryId = BigInt(o.phoneCountryId)
        }
        if (o.phone !== undefined && o.phone !== null) {
            this.phone = String(o.phone)
        }
        if (o.recommenderId !== undefined && o.recommenderId !== null) {
            this.recommenderId = BigInt(o.recommenderId)
        }
        this.recommendationCode = String(o.recommendationCode)
        this.statusFlags = Number(o.statusFlags)
        this.recommendeeCount = Number(o.recommendeeCount)
        this.contentDailyParticipationCount = Number(o.contentDailyParticipationCount)
        this.contentDailyParticipationPassCount = Number(o.contentDailyParticipationPassCount)
        this.contentRepeatLikeCount = Number(o.contentRepeatLikeCount)
        this.contentRepeatParticipationCount = Number(o.contentRepeatParticipationCount)
        this.contentRepeatParticipationPassCount = Number(o.contentRepeatParticipationPassCount)
        this.contentRepeatParticipationHighAccuracyCount = Number(o.contentRepeatParticipationHighAccuracyCount)
        this.contentRepeatCommentCount = Number(o.contentRepeatCommentCount)
        this.contentRepeatCommentLikeCount = Number(o.contentRepeatCommentLikeCount)
        this.contentRepeatCommentReportCount = Number(o.contentRepeatCommentReportCount)
        this.contentRepeatCommentReportedCount = Number(o.contentRepeatCommentReportedCount)
        this.contentM10nLikeCount = Number(o.contentM10nLikeCount)
        this.contentM10nParticipationCount = Number(o.contentM10nParticipationCount)
        this.contentM10nParticipationPassCount = Number(o.contentM10nParticipationPassCount)
        this.contentM10nParticipationHighAccuracyCount = Number(o.contentM10nParticipationHighAccuracyCount)
        this.contentM10nCommentCount = Number(o.contentM10nCommentCount)
        this.contentM10nCommentLikeCount = Number(o.contentM10nCommentLikeCount)
        this.contentM10nCommentReportCount = Number(o.contentM10nCommentReportCount)
        this.contentM10nCommentReportedCount = Number(o.contentM10nCommentReportedCount)
        this.contentUserProductionCount = Number(o.contentUserProductionCount)
        this.contentUserProductionLikeCount = Number(o.contentUserProductionLikeCount)
        this.contentUserProductionParticipationCount = Number(o.contentUserProductionParticipationCount)
        this.contentUserProductionParticipationPassCount = Number(o.contentUserProductionParticipationPassCount)
        this.contentUserProductionParticipationHighAccuracyCount = Number(o.contentUserProductionParticipationHighAccuracyCount)
        this.contentUserProductionReportCount = Number(o.contentUserProductionReportCount)
        this.contentUserProductionReportedCount = Number(o.contentUserProductionReportedCount)
        this.contentUserProductionCommentCount = Number(o.contentUserProductionCommentCount)
        this.contentUserProductionCommentLikeCount = Number(o.contentUserProductionCommentLikeCount)
        this.contentUserProductionCommentReportCount = Number(o.contentUserProductionCommentReportCount)
        this.contentUserProductionCommentReportedCount = Number(o.contentUserProductionCommentReportedCount)
        this.contentUserProductionReviewPositiveCount = Number(o.contentUserProductionReviewPositiveCount)
        this.contentUserProductionReviewNeutralCount = Number(o.contentUserProductionReviewNeutralCount)
        this.contentUserProductionReviewNegativeCount = Number(o.contentUserProductionReviewNegativeCount)
        this.userBlockCount = Number(o.userBlockCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.birthCountry = new EnvCountryValueObject(o.birthCountry)
        this.residenceCountry = new EnvCountryValueObject(o.residenceCountry)
        if (o.phoneCountry !== undefined && o.phoneCountry !== null) {
            this.phoneCountry = new EnvCountryValueObject(o.phoneCountry)
        }
        if (o.recommender !== undefined && o.recommender !== null) {
            this.recommender = new UserValueObject(o.recommender)
        }
    }
}
