import {UiOnboardingModificationDomain} from "../../domain/ui/UiOnboardingModificationDomain"

/**
 * 온보딩 이미지 수정 이력 속성 설명
 */
export const UiOnboardingModificationDescription: Record<keyof UiOnboardingModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    uiOnboardingId: '온보딩 이미지 ID',
    displayOrder: '표시 우선 순위',
    createdAt: '수정일'
}
