import {UiHomeContentUserProductionSearchOption} from "../../search-option/ui/UiHomeContentUserProductionSearchOption"

/**
 * 홈 와글와글 콘텐츠 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UiHomeContentUserProductionSearchOptionDescription: Record<keyof UiHomeContentUserProductionSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    displayOrderEq: '표시 우선 순위 일치',
    displayOrderNeq: '표시 우선 순위 불일치',
    displayOrderMin: '표시 우선 순위 최소',
    displayOrderMax: '표시 우선 순위 최대',
    urlEq: 'URL 일치',
    urlNeq: 'URL 불일치',
    urlMin: 'URL 최소',
    urlMax: 'URL 최대',
    urlLike: 'URL 포함',
    urlNotLike: 'URL 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
