import {ContentResultUrlClickVerboseDomain} from "../../domain/content/ContentResultUrlClickVerboseDomain"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {ContentResultUrlClickId} from "../../id/content/ContentResultUrlClickId"
import {ContentResultUrlId} from "../../id/content/ContentResultUrlId"
import {UserExpId} from "../../id/user/UserExpId"
import {UserId} from "../../id/user/UserId"
import {AssetStarUsageValueObject} from "../asset/AssetStarUsageValueObject"
import {UserExpValueObject} from "../user/UserExpValueObject"
import {UserValueObject} from "../user/UserValueObject"
import {ContentResultUrlValueObject} from "./ContentResultUrlValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 참여 결과 URL 클릭 상세
 */
export class ContentResultUrlClickVerboseValueObject implements VerboseValueObject, ContentResultUrlClickVerboseDomain {
    /**
     * 기본키
     */
    id: ContentResultUrlClickId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 참여 결과 URL ID
     */
    contentResultUrlId: ContentResultUrlId
    /**
     * 스타 지급 ID
     */
    assetStarUsageId?: AssetStarUsageId
    /**
     * 사용자 경험치 지급 ID
     */
    userExpId?: UserExpId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 콘텐츠 참여 결과 URL ID
     */
    contentResultUrl: ContentResultUrlValueObject
    /**
     * 스타 지급 ID
     */
    assetStarUsage?: AssetStarUsageValueObject
    /**
     * 사용자 경험치 지급 ID
     */
    userExp?: UserExpValueObject

    constructor(o: Record<keyof ContentResultUrlClickVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentResultUrlId = BigInt(o.contentResultUrlId)
        if (o.assetStarUsageId !== undefined && o.assetStarUsageId !== null) {
            this.assetStarUsageId = BigInt(o.assetStarUsageId)
        }
        if (o.userExpId !== undefined && o.userExpId !== null) {
            this.userExpId = BigInt(o.userExpId)
        }
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.contentResultUrl = new ContentResultUrlValueObject(o.contentResultUrl)
        if (o.assetStarUsage !== undefined && o.assetStarUsage !== null) {
            this.assetStarUsage = new AssetStarUsageValueObject(o.assetStarUsage)
        }
        if (o.userExp !== undefined && o.userExp !== null) {
            this.userExp = new UserExpValueObject(o.userExp)
        }
    }
}
