import {AssetStarExchangeOptionModificationVerboseDomain} from "../../domain/asset/AssetStarExchangeOptionModificationVerboseDomain"
import {AssetStarExchangeOptionId} from "../../id/asset/AssetStarExchangeOptionId"
import {AssetStarExchangeOptionModificationId} from "../../id/asset/AssetStarExchangeOptionModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {AssetStarExchangeOptionValueObject} from "./AssetStarExchangeOptionValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 스타 교환 옵션 수정 이력 상세
 */
export class AssetStarExchangeOptionModificationVerboseValueObject implements VerboseValueObject, AssetStarExchangeOptionModificationVerboseDomain {
    /**
     * 기본키
     */
    id: AssetStarExchangeOptionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 스타 교환 옵션 ID
     */
    assetStarExchangeOptionId: AssetStarExchangeOptionId
    /**
     * 지급 스타
     */
    starAmount: number
    /**
     * 차감 솔라
     */
    solarAmount: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 스타 교환 옵션 ID
     */
    assetStarExchangeOption: AssetStarExchangeOptionValueObject

    constructor(o: Record<keyof AssetStarExchangeOptionModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetStarExchangeOptionId = BigInt(o.assetStarExchangeOptionId)
        this.starAmount = Number(o.starAmount)
        this.solarAmount = Number(o.solarAmount)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.assetStarExchangeOption = new AssetStarExchangeOptionValueObject(o.assetStarExchangeOption)
    }
}
