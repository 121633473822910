import {AssetSolarUsageType} from "../../../data/constant/asset/AssetSolarUsageType"
import {AssetSolarUsageSearchOptionDescription} from "../../../data/description/asset/AssetSolarUsageSearchOptionDescription"
import {AssetSolarUsageSearchOption} from "../../../data/search-option/asset/AssetSolarUsageSearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function AssetSolarUsageSearchOptionField(props: ListOptionFieldProps<AssetSolarUsageSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "typeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.typeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={AssetSolarUsageType.selections} />
                </ListOptionWrapper>
            </>
        case "typeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.typeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={AssetSolarUsageType.selections} />
                </ListOptionWrapper>
            </>
        case "typeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.typeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={AssetSolarUsageType.selections} />
                </ListOptionWrapper>
            </>
        case "typeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.typeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={AssetSolarUsageType.selections} />
                </ListOptionWrapper>
            </>
        case "typeIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.typeIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={AssetSolarUsageType.selections} />
                </ListOptionWrapper>
            </>
        case "amountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.amountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.amountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.amountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "amountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.amountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastAccumulationEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.lastAccumulationEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastAccumulationNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.lastAccumulationNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastAccumulationMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.lastAccumulationMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "lastAccumulationMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.lastAccumulationMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "positiveAccumulationEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.positiveAccumulationEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "positiveAccumulationNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.positiveAccumulationNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "positiveAccumulationMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.positiveAccumulationMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "positiveAccumulationMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.positiveAccumulationMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "negativeAccumulationEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.negativeAccumulationEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "negativeAccumulationNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.negativeAccumulationNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "negativeAccumulationMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.negativeAccumulationMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "negativeAccumulationMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.negativeAccumulationMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarUsageSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyAssetSolarUsageSearchOption(key: keyof AssetSolarUsageSearchOption, value: string, into: StatePair<AssetSolarUsageSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "typeEq":
            into.setter({
                ...into.value,
                typeEq: value as AssetSolarUsageType
            })
            break
        case "typeNeq":
            into.setter({
                ...into.value,
                typeNeq: value as AssetSolarUsageType
            })
            break
        case "typeMin":
            into.setter({
                ...into.value,
                typeMin: value as AssetSolarUsageType
            })
            break
        case "typeMax":
            into.setter({
                ...into.value,
                typeMax: value as AssetSolarUsageType
            })
            break
        case "typeIn":
            into.setter({
                ...into.value,
                typeIn: value
                    .split('|')
                    .map(e => e as AssetSolarUsageType)
            })
            break
        case "amountEq":
            into.setter({
                ...into.value,
                amountEq: Number(value)
            })
            break
        case "amountNeq":
            into.setter({
                ...into.value,
                amountNeq: Number(value)
            })
            break
        case "amountMin":
            into.setter({
                ...into.value,
                amountMin: Number(value)
            })
            break
        case "amountMax":
            into.setter({
                ...into.value,
                amountMax: Number(value)
            })
            break
        case "lastAccumulationEq":
            into.setter({
                ...into.value,
                lastAccumulationEq: Number(value)
            })
            break
        case "lastAccumulationNeq":
            into.setter({
                ...into.value,
                lastAccumulationNeq: Number(value)
            })
            break
        case "lastAccumulationMin":
            into.setter({
                ...into.value,
                lastAccumulationMin: Number(value)
            })
            break
        case "lastAccumulationMax":
            into.setter({
                ...into.value,
                lastAccumulationMax: Number(value)
            })
            break
        case "positiveAccumulationEq":
            into.setter({
                ...into.value,
                positiveAccumulationEq: Number(value)
            })
            break
        case "positiveAccumulationNeq":
            into.setter({
                ...into.value,
                positiveAccumulationNeq: Number(value)
            })
            break
        case "positiveAccumulationMin":
            into.setter({
                ...into.value,
                positiveAccumulationMin: Number(value)
            })
            break
        case "positiveAccumulationMax":
            into.setter({
                ...into.value,
                positiveAccumulationMax: Number(value)
            })
            break
        case "negativeAccumulationEq":
            into.setter({
                ...into.value,
                negativeAccumulationEq: Number(value)
            })
            break
        case "negativeAccumulationNeq":
            into.setter({
                ...into.value,
                negativeAccumulationNeq: Number(value)
            })
            break
        case "negativeAccumulationMin":
            into.setter({
                ...into.value,
                negativeAccumulationMin: Number(value)
            })
            break
        case "negativeAccumulationMax":
            into.setter({
                ...into.value,
                negativeAccumulationMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
