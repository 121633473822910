import {ContentDifficulty} from "../../constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../constant/content/ContentScriptVisibility"
import {ContentStatus} from "../../constant/content/ContentStatus"
import {ContentModificationDomain} from "../../domain/content/ContentModificationDomain"

export const ContentModificationDescriptor: Record<keyof ContentModificationDomain, (o: ContentModificationDomain) => string> = {
    id(o: ContentModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentModificationDomain): string {
        return o.creatorId.toString()
    },
    contentId(o: ContentModificationDomain): string {
        return o.contentId.toString()
    },
    participationMethod(o: ContentModificationDomain): string {
        return ContentParticipationMethod.description(o.participationMethod)
    },
    title(o: ContentModificationDomain): string {
        return o.title
    },
    titleHighlight(o: ContentModificationDomain): string {
        return o.titleHighlight ?? '-'
    },
    body(o: ContentModificationDomain): string {
        return o.body
    },
    script(o: ContentModificationDomain): string {
        return o.script
    },
    scriptVisibility(o: ContentModificationDomain): string {
        return ContentScriptVisibility.description(o.scriptVisibility)
    },
    contentDetailUrlId(o: ContentModificationDomain): string {
        return o.contentDetailUrlId.toString()
    },
    contentResultUrlId(o: ContentModificationDomain): string {
        return o.contentResultUrlId.toString()
    },
    gradingMessage(o: ContentModificationDomain): string {
        return o.gradingMessage ?? '-'
    },
    amount(o: ContentModificationDomain): string {
        return o.amount.toString()
    },
    passAccuracy(o: ContentModificationDomain): string {
        return o.passAccuracy.toString()
    },
    passAccuracyHigh(o: ContentModificationDomain): string {
        return o.passAccuracyHigh?.toString() ?? '-'
    },
    passSolarAmount(o: ContentModificationDomain): string {
        return o.passSolarAmount.toString()
    },
    passSolarAmountHighAccuracy(o: ContentModificationDomain): string {
        return o.passSolarAmountHighAccuracy.toString()
    },
    starAmount(o: ContentModificationDomain): string {
        return o.starAmount.toString()
    },
    difficulty(o: ContentModificationDomain): string {
        return ContentDifficulty.description(o.difficulty)
    },
    headerImageCount(o: ContentModificationDomain): string {
        return o.headerImageCount.toString()
    },
    descriptionImageCount(o: ContentModificationDomain): string {
        return o.descriptionImageCount.toString()
    },
    exposedAt(o: ContentModificationDomain): string {
        return o.exposedAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    startAt(o: ContentModificationDomain): string {
        return o.startAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    endAt(o: ContentModificationDomain): string {
        return o.endAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    statusFlags(o: ContentModificationDomain): string {
        let enabled: string[] = []
        for (let key in ContentStatus) {
            const flag = (ContentStatus as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.statusFlags & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    createdAt(o: ContentModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
