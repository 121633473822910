import {AssetCouponSearchOption} from "../../search-option/asset/AssetCouponSearchOption"

/**
 * 재화 쿠폰 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const AssetCouponSearchOptionDescription: Record<keyof AssetCouponSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    codeNeq: '코드 불일치',
    codeMin: '코드 최소',
    codeMax: '코드 최대',
    codeLike: '코드 포함',
    codeNotLike: '코드 제외',
    amountEq: '수량 일치',
    amountNeq: '수량 불일치',
    amountMin: '수량 최소',
    amountMax: '수량 최대',
    assetLunarAmountEq: '지급 루나 일치',
    assetLunarAmountNeq: '지급 루나 불일치',
    assetLunarAmountMin: '지급 루나 최소',
    assetLunarAmountMax: '지급 루나 최대',
    assetSolarAmountEq: '지급 솔라 일치',
    assetSolarAmountNeq: '지급 솔라 불일치',
    assetSolarAmountMin: '지급 솔라 최소',
    assetSolarAmountMax: '지급 솔라 최대',
    assetStarAmountEq: '지급 스타 일치',
    assetStarAmountNeq: '지급 스타 불일치',
    assetStarAmountMin: '지급 스타 최소',
    assetStarAmountMax: '지급 스타 최대',
    usageCountEq: '사용량 일치',
    usageCountNeq: '사용량 불일치',
    usageCountMin: '사용량 최소',
    usageCountMax: '사용량 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
