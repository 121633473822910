import {EnvCountryModificationVerboseDomain} from "../../domain/env/EnvCountryModificationVerboseDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {EnvCountryModificationId} from "../../id/env/EnvCountryModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvCountryValueObject} from "./EnvCountryValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 국가 수정 이력 상세
 */
export class EnvCountryModificationVerboseValueObject implements VerboseValueObject, EnvCountryModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvCountryModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 국가 ID
     */
    envCountryId: EnvCountryId
    /**
     * 이름
     */
    name: string
    /**
     * ISO 코드
     */
    isoCode: string
    /**
     * 전화번호 코드
     */
    phoneCode: number
    /**
     * 이모지
     */
    emoji: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 국가 ID
     */
    envCountry: EnvCountryValueObject

    constructor(o: Record<keyof EnvCountryModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envCountryId = BigInt(o.envCountryId)
        this.name = String(o.name)
        this.isoCode = String(o.isoCode)
        this.phoneCode = Number(o.phoneCode)
        this.emoji = String(o.emoji)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envCountry = new EnvCountryValueObject(o.envCountry)
    }
}
