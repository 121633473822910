import {UserAgreementDomain} from "../../domain/user/UserAgreementDomain"
import {EnvAgreementId} from "../../id/env/EnvAgreementId"
import {UserAgreementId} from "../../id/user/UserAgreementId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 약관 동의
 */
export class UserAgreementValueObject implements ValueObject, UserAgreementDomain {
    /**
     * 기본키
     */
    id: UserAgreementId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 이용 약관 ID
     */
    envAgreementId: EnvAgreementId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof UserAgreementDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.envAgreementId = BigInt(o.envAgreementId)
        this.createdAt = new Date(o.createdAt)
    }
}
