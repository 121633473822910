import {ContentUserProductionDurationModificationVerboseDomain} from "../../domain/content/ContentUserProductionDurationModificationVerboseDomain"
import {ContentUserProductionDurationId} from "../../id/content/ContentUserProductionDurationId"
import {ContentUserProductionDurationModificationId} from "../../id/content/ContentUserProductionDurationModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentUserProductionDurationValueObject} from "./ContentUserProductionDurationValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 와글와글 기간 프리셋 수정 이력 상세
 */
export class ContentUserProductionDurationModificationVerboseValueObject implements VerboseValueObject, ContentUserProductionDurationModificationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentUserProductionDurationModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 와글와글 기간 프리셋 ID
     */
    contentUserProductionDurationId: ContentUserProductionDurationId
    /**
     * 기간(주)
     */
    value: number
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 와글와글 기간 프리셋 ID
     */
    contentUserProductionDuration: ContentUserProductionDurationValueObject

    constructor(o: Record<keyof ContentUserProductionDurationModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentUserProductionDurationId = BigInt(o.contentUserProductionDurationId)
        this.value = Number(o.value)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.contentUserProductionDuration = new ContentUserProductionDurationValueObject(o.contentUserProductionDuration)
    }
}
