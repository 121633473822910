import {UiHomeContentSuggestionModificationDomain} from "../../domain/ui/UiHomeContentSuggestionModificationDomain"

/**
 * 홈 추천 콘텐츠 수정 이력 속성 설명
 */
export const UiHomeContentSuggestionModificationDescription: Record<keyof UiHomeContentSuggestionModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    uiHomeContentSuggestionId: '홈 추천 콘텐츠 ID',
    displayOrder: '표시 우선 순위',
    url: 'URL',
    createdAt: '수정일'
}
