import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserNotificationValueObject} from "../../../data/value-object/user/UserNotificationValueObject";
import {useEffect} from "react";
import {UserNotificationIO, UserNotificationModificationIO} from "../../../io/UserIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {UserNotificationDomain} from "../../../data/domain/user/UserNotificationDomain";
import {UserNotificationDescriptor} from "../../../data/descriptor/user/UserNotificationDescriptor";
import {UserNotificationDescription} from "../../../data/description/user/UserNotificationDescription";
import {
    UserNotificationModificationValueObject
} from "../../../data/value-object/user/UserNotificationModificationValueObject";
import {
    UserNotificationModificationSearchOption
} from "../../../data/search-option/user/UserNotificationModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {
    UserNotificationModificationDescription
} from "../../../data/description/user/UserNotificationModificationDescription";
import {
    UserNotificationModificationDescriptor
} from "../../../data/descriptor/user/UserNotificationModificationDescriptor";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";

enum Panel {
    Default,
    UserNotificationModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ envFaqId: string }>()
    const item = useStatePair<UserNotificationValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.envFaqId !== undefined) {
            UserNotificationIO
                .getById(BigInt(params.envFaqId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserNotificationValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserNotificationModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UserNotificationIO}
                description={UserNotificationDescription}
                properties={props => <PropertyBody {...props} />} />
            <UserNotificationModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserNotificationDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "title":
        case "body":
        case "subject":
        case "deepLink":
        case "statusFlags":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{UserNotificationDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserNotificationDescriptor[props.itemKey](props.item)}</Body1></a>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{UserNotificationDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function UserNotificationModificationListPanel(props: PanelProps) {
    const items = useStatePair<UserNotificationModificationValueObject[]>([])
    const option: UserNotificationModificationSearchOption = { userNotificationIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserNotificationModificationList && <>
            <ListPageWrapper
                {...props}
                client={UserNotificationModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={UserNotificationModificationDescription}
                descriptor={UserNotificationModificationDescriptor} />
        </>}
    </>
}