import {UserRankDuration} from "../../constant/user/UserRankDuration"
import {UserPolicyModificationVerboseDomain} from "../../domain/user/UserPolicyModificationVerboseDomain"
import {UserId} from "../../id/user/UserId"
import {UserPolicyId} from "../../id/user/UserPolicyId"
import {UserPolicyModificationId} from "../../id/user/UserPolicyModificationId"
import {UserPolicyValueObject} from "./UserPolicyValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 정책 수정 이력 상세
 */
export class UserPolicyModificationVerboseValueObject implements VerboseValueObject, UserPolicyModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UserPolicyModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 정책 ID
     */
    userPolicyId: UserPolicyId
    /**
     * 회원가입 지급 솔라
     */
    signUpSolarAmount: number
    /**
     * 회원가입 지급 루나
     */
    signUpLunarAmount: number
    /**
     * 회원가입 지급 스타
     */
    signUpStarAmount: number
    /**
     * 추천인 지급 솔라
     */
    recommenderSolarAmount: number
    /**
     * 추천인 지급 루나
     */
    recommenderLunarAmount: number
    /**
     * 추천인 지급 스타
     */
    recommenderStarAmount: number
    /**
     * 추천인 보상 기준
     */
    recommenderRewardThreshold: number
    /**
     * 피추천인 지급 솔라
     */
    recommendeeSolarAmount: number
    /**
     * 피추천인 지급 루나
     */
    recommendeeLunarAmount: number
    /**
     * 피추천인 지급 스타
     */
    recommendeeStarAmount: number
    /**
     * 사용자 페이지 크기
     */
    userPageSize: number
    /**
     * 사용자 이용 약관 동의 페이지 크기
     */
    userAgreementPageSize: number
    /**
     * 사용자 인증 번호 페이지 크기
     */
    userAuthenticationCodePageSize: number
    /**
     * 사용자 인증 번호 만료 시간(초)
     */
    userAuthenticationCodeExpireSeconds: number
    /**
     * 사용자 인증 번호 제한 단위 시간(분)
     */
    userAuthenticationCodeThresholdMinutes: number
    /**
     * 사용자 인증 번호 제한 단위 횟수
     */
    userAuthenticationCodeThresholdCount: number
    /**
     * 사용자 이용 정지 페이지 크기
     */
    userBlockPageSize: number
    /**
     * 사용자 이용 정지 사유 페이지 크기
     */
    userBlockReasonPageSize: number
    /**
     * 사용자 경험치 페이지 크기
     */
    userExpPageSize: number
    /**
     * 사용자 문의 페이지 크기
     */
    userInquiryPageSize: number
    /**
     * 사용자 레벨 페이지 크기
     */
    userLevelPageSize: number
    /**
     * 사용자 레벨 기록 페이지 크기
     */
    userLevelHistoryPageSize: number
    /**
     * 사용자 알림 페이지 크기
     */
    userNotificationPageSize: number
    /**
     * 사용자 정책 페이지 크기
     */
    userPolicyPageSize: number
    /**
     * 사용자 랭크 페이지 크기
     */
    userRankPageSize: number
    /**
     * 사용자 랭크 캐시 스케줄러 주기(분)
     */
    userRankCacheSchedulerDurationMinutes: number
    /**
     * 사용자 랭크 캐시 새로고침 시간(분)
     */
    userRankCacheRefreshDurationMinutes: number
    /**
     * 사용자 랭크 집계 기간
     */
    userRankDuration: UserRankDuration
    /**
     * 사용자 랭크 기록 페이지 크기
     */
    userRankHistoryPageSize: number
    /**
     * 사용자 세션 페이지 크기
     */
    userSessionPageSize: number
    /**
     * 미사용 세션 유지 시간(초)
     */
    userSessionMaxAgeSeconds: number
    /**
     * 사용자 로그인 페이지 크기
     */
    userSignInPageSize: number
    /**
     * 사용자 탈퇴 페이지 크기
     */
    userWithdrawalPageSize: number
    /**
     * 사용자 탈퇴 사유 페이지 크기
     */
    userWithdrawalReasonPageSize: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 사용자 정책 ID
     */
    userPolicy: UserPolicyValueObject

    constructor(o: Record<keyof UserPolicyModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userPolicyId = BigInt(o.userPolicyId)
        this.signUpSolarAmount = Number(o.signUpSolarAmount)
        this.signUpLunarAmount = Number(o.signUpLunarAmount)
        this.signUpStarAmount = Number(o.signUpStarAmount)
        this.recommenderSolarAmount = Number(o.recommenderSolarAmount)
        this.recommenderLunarAmount = Number(o.recommenderLunarAmount)
        this.recommenderStarAmount = Number(o.recommenderStarAmount)
        this.recommenderRewardThreshold = Number(o.recommenderRewardThreshold)
        this.recommendeeSolarAmount = Number(o.recommendeeSolarAmount)
        this.recommendeeLunarAmount = Number(o.recommendeeLunarAmount)
        this.recommendeeStarAmount = Number(o.recommendeeStarAmount)
        this.userPageSize = Number(o.userPageSize)
        this.userAgreementPageSize = Number(o.userAgreementPageSize)
        this.userAuthenticationCodePageSize = Number(o.userAuthenticationCodePageSize)
        this.userAuthenticationCodeExpireSeconds = Number(o.userAuthenticationCodeExpireSeconds)
        this.userAuthenticationCodeThresholdMinutes = Number(o.userAuthenticationCodeThresholdMinutes)
        this.userAuthenticationCodeThresholdCount = Number(o.userAuthenticationCodeThresholdCount)
        this.userBlockPageSize = Number(o.userBlockPageSize)
        this.userBlockReasonPageSize = Number(o.userBlockReasonPageSize)
        this.userExpPageSize = Number(o.userExpPageSize)
        this.userInquiryPageSize = Number(o.userInquiryPageSize)
        this.userLevelPageSize = Number(o.userLevelPageSize)
        this.userLevelHistoryPageSize = Number(o.userLevelHistoryPageSize)
        this.userNotificationPageSize = Number(o.userNotificationPageSize)
        this.userPolicyPageSize = Number(o.userPolicyPageSize)
        this.userRankPageSize = Number(o.userRankPageSize)
        this.userRankCacheSchedulerDurationMinutes = Number(o.userRankCacheSchedulerDurationMinutes)
        this.userRankCacheRefreshDurationMinutes = Number(o.userRankCacheRefreshDurationMinutes)
        this.userRankDuration = o.userRankDuration as UserRankDuration
        this.userRankHistoryPageSize = Number(o.userRankHistoryPageSize)
        this.userSessionPageSize = Number(o.userSessionPageSize)
        this.userSessionMaxAgeSeconds = Number(o.userSessionMaxAgeSeconds)
        this.userSignInPageSize = Number(o.userSignInPageSize)
        this.userWithdrawalPageSize = Number(o.userWithdrawalPageSize)
        this.userWithdrawalReasonPageSize = Number(o.userWithdrawalReasonPageSize)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.userPolicy = new UserPolicyValueObject(o.userPolicy)
    }
}
