import {EnvFaqModificationVerboseDomain} from "../../domain/env/EnvFaqModificationVerboseDomain"
import {EnvFaqId} from "../../id/env/EnvFaqId"
import {EnvFaqModificationId} from "../../id/env/EnvFaqModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvFaqValueObject} from "./EnvFaqValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 자주 묻는 질문 수정 이력 상세
 */
export class EnvFaqModificationVerboseValueObject implements VerboseValueObject, EnvFaqModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvFaqModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 자주 묻는 질문 ID
     */
    envFaqId: EnvFaqId
    /**
     * 제목
     */
    title: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 자주 묻는 질문 ID
     */
    envFaq: EnvFaqValueObject

    constructor(o: Record<keyof EnvFaqModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envFaqId = BigInt(o.envFaqId)
        this.title = String(o.title)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envFaq = new EnvFaqValueObject(o.envFaq)
    }
}
