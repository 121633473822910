import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentUserProductionRewardVerboseValueObject
} from "../../../../data/value-object/content/ContentUserProductionRewardVerboseValueObject";
import {useCallback, useEffect} from "react";
import {ContentUserProductionRewardIO} from "../../../../io/ContentIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostNumberField} from "../../../PostField";
import {
    ContentUserProductionRewardDescription
} from "../../../../data/description/content/ContentUserProductionRewardDescription";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ contentUserProductionRewardId: string }>()
    const item = useStatePair<ContentUserProductionRewardVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const value = useStatePair(0)
    const displayOrder = useStatePair(0)

    useEffect(() => {
        if (item.value === undefined && params.contentUserProductionRewardId !== undefined) {
            ContentUserProductionRewardIO
                .getVerboseById(BigInt(params.contentUserProductionRewardId))
                .then(_value => {
                    item.setter(_value)
                    value.setter(_value.value)
                    displayOrder.setter(_value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        ContentUserProductionRewardIO
            .put(id, {
                value: value.value,
                displayOrder: displayOrder.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/contents/userProduction/durations/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [value.value, displayOrder.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={ContentUserProductionRewardDescription.value}>
                <PostNumberField
                    {...props}
                    value={value.value}
                    setter={value.setter}/>
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={ContentUserProductionRewardDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter}/>
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}