import {UserRankDuration} from "../../../data/constant/user/UserRankDuration"
import {UserPolicyPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/user/UserPolicyPostDataTransferObjectDescription"
import {UserPolicyPostState} from "../../../data/data-transfer-object-state/user/UserPolicyPostState"
import {UserPolicyPostDataTransferObject} from "../../../data/data-transfer-object/user/UserPolicyPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: UserPolicyPostState
}

export const UserPolicyPostField: Record<Capitalize<keyof UserPolicyPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    SignUpSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.signUpSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.signUpSolarAmount.value}
                    setter={props.state.signUpSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    SignUpLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.signUpLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.signUpLunarAmount.value}
                    setter={props.state.signUpLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    SignUpStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.signUpStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.signUpStarAmount.value}
                    setter={props.state.signUpStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommenderSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderSolarAmount.value}
                    setter={props.state.recommenderSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommenderLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderLunarAmount.value}
                    setter={props.state.recommenderLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommenderStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderStarAmount.value}
                    setter={props.state.recommenderStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommenderRewardThreshold(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommenderRewardThreshold}>
                <PostNumberField
                    {...props}
                    value={props.state.recommenderRewardThreshold.value}
                    setter={props.state.recommenderRewardThreshold.setter} />
            </PostFieldWrapper>
        )
    },
    RecommendeeSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommendeeSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommendeeSolarAmount.value}
                    setter={props.state.recommendeeSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommendeeLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommendeeLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommendeeLunarAmount.value}
                    setter={props.state.recommendeeLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    RecommendeeStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.recommendeeStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.recommendeeStarAmount.value}
                    setter={props.state.recommendeeStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    UserPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userPageSize.value}
                    setter={props.state.userPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserAgreementPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userAgreementPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userAgreementPageSize.value}
                    setter={props.state.userAgreementPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userAuthenticationCodePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodePageSize.value}
                    setter={props.state.userAuthenticationCodePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodeExpireSeconds(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userAuthenticationCodeExpireSeconds}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodeExpireSeconds.value}
                    setter={props.state.userAuthenticationCodeExpireSeconds.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodeThresholdMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userAuthenticationCodeThresholdMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodeThresholdMinutes.value}
                    setter={props.state.userAuthenticationCodeThresholdMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    UserAuthenticationCodeThresholdCount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userAuthenticationCodeThresholdCount}>
                <PostNumberField
                    {...props}
                    value={props.state.userAuthenticationCodeThresholdCount.value}
                    setter={props.state.userAuthenticationCodeThresholdCount.setter} />
            </PostFieldWrapper>
        )
    },
    UserBlockPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userBlockPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userBlockPageSize.value}
                    setter={props.state.userBlockPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserBlockReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userBlockReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userBlockReasonPageSize.value}
                    setter={props.state.userBlockReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserExpPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userExpPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userExpPageSize.value}
                    setter={props.state.userExpPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserInquiryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userInquiryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userInquiryPageSize.value}
                    setter={props.state.userInquiryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserLevelPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userLevelPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userLevelPageSize.value}
                    setter={props.state.userLevelPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserLevelHistoryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userLevelHistoryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userLevelHistoryPageSize.value}
                    setter={props.state.userLevelHistoryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserNotificationPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userNotificationPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userNotificationPageSize.value}
                    setter={props.state.userNotificationPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userPolicyPageSize.value}
                    setter={props.state.userPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userRankPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankPageSize.value}
                    setter={props.state.userRankPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankCacheSchedulerDurationMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userRankCacheSchedulerDurationMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankCacheSchedulerDurationMinutes.value}
                    setter={props.state.userRankCacheSchedulerDurationMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankCacheRefreshDurationMinutes(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userRankCacheRefreshDurationMinutes}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankCacheRefreshDurationMinutes.value}
                    setter={props.state.userRankCacheRefreshDurationMinutes.setter} />
            </PostFieldWrapper>
        )
    },
    UserRankDuration(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userRankDuration}>
                <PostEnumField
                    {...props}
                    value={props.state.userRankDuration.value}
                    setter={props.state.userRankDuration.setter}
                    selections={UserRankDuration.selections} />
            </PostFieldWrapper>
        )
    },
    UserRankHistoryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userRankHistoryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userRankHistoryPageSize.value}
                    setter={props.state.userRankHistoryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserSessionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userSessionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userSessionPageSize.value}
                    setter={props.state.userSessionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserSessionMaxAgeSeconds(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userSessionMaxAgeSeconds}>
                <PostNumberField
                    {...props}
                    value={props.state.userSessionMaxAgeSeconds.value}
                    setter={props.state.userSessionMaxAgeSeconds.setter} />
            </PostFieldWrapper>
        )
    },
    UserSignInPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userSignInPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userSignInPageSize.value}
                    setter={props.state.userSignInPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserWithdrawalPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userWithdrawalPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userWithdrawalPageSize.value}
                    setter={props.state.userWithdrawalPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    UserWithdrawalReasonPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPolicyPostDataTransferObjectDescription.userWithdrawalReasonPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.userWithdrawalReasonPageSize.value}
                    setter={props.state.userWithdrawalReasonPageSize.setter} />
            </PostFieldWrapper>
        )
    },
}

export function UserPolicyPostFields(props: PostFieldProps) {
    return <>
        <UserPolicyPostField.SignUpSolarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.SignUpLunarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.SignUpStarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommenderSolarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommenderLunarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommenderStarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommenderRewardThreshold
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommendeeSolarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommendeeLunarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.RecommendeeStarAmount
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserAgreementPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserAuthenticationCodePageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserAuthenticationCodeExpireSeconds
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserAuthenticationCodeThresholdMinutes
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserAuthenticationCodeThresholdCount
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserBlockPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserBlockReasonPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserExpPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserInquiryPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserLevelPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserLevelHistoryPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserNotificationPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserPolicyPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserRankPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserRankCacheSchedulerDurationMinutes
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserRankCacheRefreshDurationMinutes
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserRankDuration
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserRankHistoryPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserSessionPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserSessionMaxAgeSeconds
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserSignInPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserWithdrawalPageSize
            {...props}
            state={props.state} />
        <UserPolicyPostField.UserWithdrawalReasonPageSize
            {...props}
            state={props.state} />
    </>
}
