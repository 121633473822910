import {AssetStarUsageType} from "../../constant/asset/AssetStarUsageType"
import {AssetStarUsageDomain} from "../../domain/asset/AssetStarUsageDomain"
import {AssetStarUsageId} from "../../id/asset/AssetStarUsageId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 스타 사용 및 지급 이력
 */
export class AssetStarUsageValueObject implements ValueObject, AssetStarUsageDomain {
    /**
     * 기본키
     */
    id: AssetStarUsageId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 유형
     */
    type: AssetStarUsageType
    /**
     * 변화
     */
    amount: number
    /**
     * 직전 누계
     */
    lastAccumulation: number
    /**
     * 지급 누계
     */
    positiveAccumulation: number
    /**
     * 사용 누계
     */
    negativeAccumulation: number
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetStarUsageDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userId = BigInt(o.userId)
        this.type = o.type as AssetStarUsageType
        this.amount = Number(o.amount)
        this.lastAccumulation = Number(o.lastAccumulation)
        this.positiveAccumulation = Number(o.positiveAccumulation)
        this.negativeAccumulation = Number(o.negativeAccumulation)
        this.createdAt = new Date(o.createdAt)
    }
}
