import {EnvBrowser} from "../../../data/constant/env/EnvBrowser"
import {ContentPolicySearchOptionDescription} from "../../../data/description/content/ContentPolicySearchOptionDescription"
import {ContentPolicySearchOption} from "../../../data/search-option/content/ContentPolicySearchOption"
import {ListOptionCheckboxes, ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentPolicySearchOptionField(props: ListOptionFieldProps<ContentPolicySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportAutoHiddenCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportAutoHiddenCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportAutoHiddenCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportAutoHiddenCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportAutoHiddenCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportAutoHiddenCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportAutoHiddenCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportAutoHiddenCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentWithAudioSolarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentWithAudioSolarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentWithAudioSolarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentWithAudioSolarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentWithAudioSolarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentWithAudioSolarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentWithAudioSolarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentWithAudioSolarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportAutoHiddenCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportAutoHiddenCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportAutoHiddenCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportAutoHiddenCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportAutoHiddenCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportAutoHiddenCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportAutoHiddenCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportAutoHiddenCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentThresholdCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentThresholdCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentLikePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentLikePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentLikePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentLikePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentLikePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentLikePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentLikePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentLikePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportReasonPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportReasonPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportReasonPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportReasonPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportReasonPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportReasonPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentCommentReportReasonPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentCommentReportReasonPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentParticipationPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentParticipationPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentParticipationPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentParticipationPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentParticipationPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentParticipationPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentParticipationPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentParticipationPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationPassExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationPassExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationPassExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDailyParticipationPassExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDailyParticipationPassExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationThresholdCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationThresholdCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationPassExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationPassExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationPassExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentRepeatParticipationPassExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentRepeatParticipationPassExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationThresholdCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationThresholdCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationPassExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationPassExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationPassExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentM10nParticipationPassExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentM10nParticipationPassExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdCountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdCountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdCountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdCountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdCountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdCountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationThresholdCountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationThresholdCountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationPassExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationPassExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationPassExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionParticipationPassExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionParticipationPassExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPresetPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPresetPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPresetPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPresetPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPresetPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPresetPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPresetPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPresetPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAssetLunarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAssetLunarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAssetLunarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAssetLunarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAssetLunarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAssetLunarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAssetLunarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAssetLunarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumUserLevelIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumUserLevelIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumUserLevelIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumUserLevelIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumUserLevelIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumUserLevelIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumUserLevelIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumUserLevelIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionMinimumAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionMinimumAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassHighAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassHighAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassHighAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassHighAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassHighAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassHighAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassHighAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassHighAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassSolarAmountHighAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassSolarAmountHighAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassSolarAmountHighAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassSolarAmountHighAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassSolarAmountHighAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassSolarAmountHighAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionPassSolarAmountHighAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionPassSolarAmountHighAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionStarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionStarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionStarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionStarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionStarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionStarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionStarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionStarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlBrowserEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlBrowserEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlBrowserNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlBrowserNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlBrowserMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlBrowserMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlBrowserMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlBrowserMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultDetailUrlBrowserIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultDetailUrlBrowserIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlBrowserEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlBrowserEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlBrowserNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlBrowserNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlBrowserMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlBrowserMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlBrowserMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlBrowserMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionDefaultResultUrlBrowserIn":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionDefaultResultUrlBrowserIn}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionCheckboxes
                        {...props}
                        input={input}
                        selections={EnvBrowser.selections} />
                </ListOptionWrapper>
            </>
        case "contentUserProductionScriptPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionScriptPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionScriptPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionScriptPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionScriptPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionScriptPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentUserProductionScriptPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentUserProductionScriptPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentLikePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentLikePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentLikePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentLikePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentLikePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentLikePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentLikePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentLikePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPolicyPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPolicyPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPolicyPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPolicyPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPolicyPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPolicyPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentPolicyPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentPolicyPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularDurationMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularDurationMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularDurationMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularDurationMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularDurationMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularDurationMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularDurationMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularDurationMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentQueryPopularPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentQueryPopularPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportReasonPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportReasonPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportReasonPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportReasonPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportReasonPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportReasonPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportReasonPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportReasonPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportDailyThresholdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportDailyThresholdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportDailyThresholdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportDailyThresholdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportDailyThresholdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportDailyThresholdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReportDailyThresholdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReportDailyThresholdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentDetailUrlClickExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentDetailUrlClickExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlClickExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentResultUrlClickExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReviewPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReviewPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReviewPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReviewPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReviewPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReviewPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentReviewPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentReviewPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentTargetPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentTargetPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentTargetPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentTargetPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.contentTargetPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentPolicySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentPolicySearchOption(key: keyof ContentPolicySearchOption, value: string, into: StatePair<ContentPolicySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "contentPageSizeEq":
            into.setter({
                ...into.value,
                contentPageSizeEq: Number(value)
            })
            break
        case "contentPageSizeNeq":
            into.setter({
                ...into.value,
                contentPageSizeNeq: Number(value)
            })
            break
        case "contentPageSizeMin":
            into.setter({
                ...into.value,
                contentPageSizeMin: Number(value)
            })
            break
        case "contentPageSizeMax":
            into.setter({
                ...into.value,
                contentPageSizeMax: Number(value)
            })
            break
        case "contentReportAutoHiddenCountEq":
            into.setter({
                ...into.value,
                contentReportAutoHiddenCountEq: Number(value)
            })
            break
        case "contentReportAutoHiddenCountNeq":
            into.setter({
                ...into.value,
                contentReportAutoHiddenCountNeq: Number(value)
            })
            break
        case "contentReportAutoHiddenCountMin":
            into.setter({
                ...into.value,
                contentReportAutoHiddenCountMin: Number(value)
            })
            break
        case "contentReportAutoHiddenCountMax":
            into.setter({
                ...into.value,
                contentReportAutoHiddenCountMax: Number(value)
            })
            break
        case "contentCommentPageSizeEq":
            into.setter({
                ...into.value,
                contentCommentPageSizeEq: Number(value)
            })
            break
        case "contentCommentPageSizeNeq":
            into.setter({
                ...into.value,
                contentCommentPageSizeNeq: Number(value)
            })
            break
        case "contentCommentPageSizeMin":
            into.setter({
                ...into.value,
                contentCommentPageSizeMin: Number(value)
            })
            break
        case "contentCommentPageSizeMax":
            into.setter({
                ...into.value,
                contentCommentPageSizeMax: Number(value)
            })
            break
        case "contentCommentWithAudioSolarAmountEq":
            into.setter({
                ...into.value,
                contentCommentWithAudioSolarAmountEq: Number(value)
            })
            break
        case "contentCommentWithAudioSolarAmountNeq":
            into.setter({
                ...into.value,
                contentCommentWithAudioSolarAmountNeq: Number(value)
            })
            break
        case "contentCommentWithAudioSolarAmountMin":
            into.setter({
                ...into.value,
                contentCommentWithAudioSolarAmountMin: Number(value)
            })
            break
        case "contentCommentWithAudioSolarAmountMax":
            into.setter({
                ...into.value,
                contentCommentWithAudioSolarAmountMax: Number(value)
            })
            break
        case "contentCommentReportAutoHiddenCountEq":
            into.setter({
                ...into.value,
                contentCommentReportAutoHiddenCountEq: Number(value)
            })
            break
        case "contentCommentReportAutoHiddenCountNeq":
            into.setter({
                ...into.value,
                contentCommentReportAutoHiddenCountNeq: Number(value)
            })
            break
        case "contentCommentReportAutoHiddenCountMin":
            into.setter({
                ...into.value,
                contentCommentReportAutoHiddenCountMin: Number(value)
            })
            break
        case "contentCommentReportAutoHiddenCountMax":
            into.setter({
                ...into.value,
                contentCommentReportAutoHiddenCountMax: Number(value)
            })
            break
        case "contentCommentThresholdMinutesEq":
            into.setter({
                ...into.value,
                contentCommentThresholdMinutesEq: Number(value)
            })
            break
        case "contentCommentThresholdMinutesNeq":
            into.setter({
                ...into.value,
                contentCommentThresholdMinutesNeq: Number(value)
            })
            break
        case "contentCommentThresholdMinutesMin":
            into.setter({
                ...into.value,
                contentCommentThresholdMinutesMin: Number(value)
            })
            break
        case "contentCommentThresholdMinutesMax":
            into.setter({
                ...into.value,
                contentCommentThresholdMinutesMax: Number(value)
            })
            break
        case "contentCommentThresholdCountEq":
            into.setter({
                ...into.value,
                contentCommentThresholdCountEq: Number(value)
            })
            break
        case "contentCommentThresholdCountNeq":
            into.setter({
                ...into.value,
                contentCommentThresholdCountNeq: Number(value)
            })
            break
        case "contentCommentThresholdCountMin":
            into.setter({
                ...into.value,
                contentCommentThresholdCountMin: Number(value)
            })
            break
        case "contentCommentThresholdCountMax":
            into.setter({
                ...into.value,
                contentCommentThresholdCountMax: Number(value)
            })
            break
        case "contentCommentLikePageSizeEq":
            into.setter({
                ...into.value,
                contentCommentLikePageSizeEq: Number(value)
            })
            break
        case "contentCommentLikePageSizeNeq":
            into.setter({
                ...into.value,
                contentCommentLikePageSizeNeq: Number(value)
            })
            break
        case "contentCommentLikePageSizeMin":
            into.setter({
                ...into.value,
                contentCommentLikePageSizeMin: Number(value)
            })
            break
        case "contentCommentLikePageSizeMax":
            into.setter({
                ...into.value,
                contentCommentLikePageSizeMax: Number(value)
            })
            break
        case "contentCommentReportPageSizeEq":
            into.setter({
                ...into.value,
                contentCommentReportPageSizeEq: Number(value)
            })
            break
        case "contentCommentReportPageSizeNeq":
            into.setter({
                ...into.value,
                contentCommentReportPageSizeNeq: Number(value)
            })
            break
        case "contentCommentReportPageSizeMin":
            into.setter({
                ...into.value,
                contentCommentReportPageSizeMin: Number(value)
            })
            break
        case "contentCommentReportPageSizeMax":
            into.setter({
                ...into.value,
                contentCommentReportPageSizeMax: Number(value)
            })
            break
        case "contentCommentReportReasonPageSizeEq":
            into.setter({
                ...into.value,
                contentCommentReportReasonPageSizeEq: Number(value)
            })
            break
        case "contentCommentReportReasonPageSizeNeq":
            into.setter({
                ...into.value,
                contentCommentReportReasonPageSizeNeq: Number(value)
            })
            break
        case "contentCommentReportReasonPageSizeMin":
            into.setter({
                ...into.value,
                contentCommentReportReasonPageSizeMin: Number(value)
            })
            break
        case "contentCommentReportReasonPageSizeMax":
            into.setter({
                ...into.value,
                contentCommentReportReasonPageSizeMax: Number(value)
            })
            break
        case "contentParticipationPageSizeEq":
            into.setter({
                ...into.value,
                contentParticipationPageSizeEq: Number(value)
            })
            break
        case "contentParticipationPageSizeNeq":
            into.setter({
                ...into.value,
                contentParticipationPageSizeNeq: Number(value)
            })
            break
        case "contentParticipationPageSizeMin":
            into.setter({
                ...into.value,
                contentParticipationPageSizeMin: Number(value)
            })
            break
        case "contentParticipationPageSizeMax":
            into.setter({
                ...into.value,
                contentParticipationPageSizeMax: Number(value)
            })
            break
        case "contentDailyParticipationExpEq":
            into.setter({
                ...into.value,
                contentDailyParticipationExpEq: Number(value)
            })
            break
        case "contentDailyParticipationExpNeq":
            into.setter({
                ...into.value,
                contentDailyParticipationExpNeq: Number(value)
            })
            break
        case "contentDailyParticipationExpMin":
            into.setter({
                ...into.value,
                contentDailyParticipationExpMin: Number(value)
            })
            break
        case "contentDailyParticipationExpMax":
            into.setter({
                ...into.value,
                contentDailyParticipationExpMax: Number(value)
            })
            break
        case "contentDailyParticipationPassExpEq":
            into.setter({
                ...into.value,
                contentDailyParticipationPassExpEq: Number(value)
            })
            break
        case "contentDailyParticipationPassExpNeq":
            into.setter({
                ...into.value,
                contentDailyParticipationPassExpNeq: Number(value)
            })
            break
        case "contentDailyParticipationPassExpMin":
            into.setter({
                ...into.value,
                contentDailyParticipationPassExpMin: Number(value)
            })
            break
        case "contentDailyParticipationPassExpMax":
            into.setter({
                ...into.value,
                contentDailyParticipationPassExpMax: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdMinutesEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdMinutesEq: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdMinutesNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdMinutesNeq: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdMinutesMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdMinutesMin: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdMinutesMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdMinutesMax: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdCountEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdCountEq: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdCountNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdCountNeq: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdCountMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdCountMin: Number(value)
            })
            break
        case "contentRepeatParticipationThresholdCountMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationThresholdCountMax: Number(value)
            })
            break
        case "contentRepeatParticipationExpEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationExpEq: Number(value)
            })
            break
        case "contentRepeatParticipationExpNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationExpNeq: Number(value)
            })
            break
        case "contentRepeatParticipationExpMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationExpMin: Number(value)
            })
            break
        case "contentRepeatParticipationExpMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationExpMax: Number(value)
            })
            break
        case "contentRepeatParticipationPassExpEq":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassExpEq: Number(value)
            })
            break
        case "contentRepeatParticipationPassExpNeq":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassExpNeq: Number(value)
            })
            break
        case "contentRepeatParticipationPassExpMin":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassExpMin: Number(value)
            })
            break
        case "contentRepeatParticipationPassExpMax":
            into.setter({
                ...into.value,
                contentRepeatParticipationPassExpMax: Number(value)
            })
            break
        case "contentM10nParticipationThresholdMinutesEq":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdMinutesEq: Number(value)
            })
            break
        case "contentM10nParticipationThresholdMinutesNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdMinutesNeq: Number(value)
            })
            break
        case "contentM10nParticipationThresholdMinutesMin":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdMinutesMin: Number(value)
            })
            break
        case "contentM10nParticipationThresholdMinutesMax":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdMinutesMax: Number(value)
            })
            break
        case "contentM10nParticipationThresholdCountEq":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdCountEq: Number(value)
            })
            break
        case "contentM10nParticipationThresholdCountNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdCountNeq: Number(value)
            })
            break
        case "contentM10nParticipationThresholdCountMin":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdCountMin: Number(value)
            })
            break
        case "contentM10nParticipationThresholdCountMax":
            into.setter({
                ...into.value,
                contentM10nParticipationThresholdCountMax: Number(value)
            })
            break
        case "contentM10nParticipationExpEq":
            into.setter({
                ...into.value,
                contentM10nParticipationExpEq: Number(value)
            })
            break
        case "contentM10nParticipationExpNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationExpNeq: Number(value)
            })
            break
        case "contentM10nParticipationExpMin":
            into.setter({
                ...into.value,
                contentM10nParticipationExpMin: Number(value)
            })
            break
        case "contentM10nParticipationExpMax":
            into.setter({
                ...into.value,
                contentM10nParticipationExpMax: Number(value)
            })
            break
        case "contentM10nParticipationPassExpEq":
            into.setter({
                ...into.value,
                contentM10nParticipationPassExpEq: Number(value)
            })
            break
        case "contentM10nParticipationPassExpNeq":
            into.setter({
                ...into.value,
                contentM10nParticipationPassExpNeq: Number(value)
            })
            break
        case "contentM10nParticipationPassExpMin":
            into.setter({
                ...into.value,
                contentM10nParticipationPassExpMin: Number(value)
            })
            break
        case "contentM10nParticipationPassExpMax":
            into.setter({
                ...into.value,
                contentM10nParticipationPassExpMax: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdMinutesEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdMinutesEq: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdMinutesNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdMinutesNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdMinutesMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdMinutesMin: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdMinutesMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdMinutesMax: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdCountEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdCountEq: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdCountNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdCountNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdCountMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdCountMin: Number(value)
            })
            break
        case "contentUserProductionParticipationThresholdCountMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationThresholdCountMax: Number(value)
            })
            break
        case "contentUserProductionParticipationExpEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationExpEq: Number(value)
            })
            break
        case "contentUserProductionParticipationExpNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationExpNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationExpMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationExpMin: Number(value)
            })
            break
        case "contentUserProductionParticipationExpMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationExpMax: Number(value)
            })
            break
        case "contentUserProductionParticipationPassExpEq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassExpEq: Number(value)
            })
            break
        case "contentUserProductionParticipationPassExpNeq":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassExpNeq: Number(value)
            })
            break
        case "contentUserProductionParticipationPassExpMin":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassExpMin: Number(value)
            })
            break
        case "contentUserProductionParticipationPassExpMax":
            into.setter({
                ...into.value,
                contentUserProductionParticipationPassExpMax: Number(value)
            })
            break
        case "contentUserProductionPresetPageSizeEq":
            into.setter({
                ...into.value,
                contentUserProductionPresetPageSizeEq: Number(value)
            })
            break
        case "contentUserProductionPresetPageSizeNeq":
            into.setter({
                ...into.value,
                contentUserProductionPresetPageSizeNeq: Number(value)
            })
            break
        case "contentUserProductionPresetPageSizeMin":
            into.setter({
                ...into.value,
                contentUserProductionPresetPageSizeMin: Number(value)
            })
            break
        case "contentUserProductionPresetPageSizeMax":
            into.setter({
                ...into.value,
                contentUserProductionPresetPageSizeMax: Number(value)
            })
            break
        case "contentUserProductionMinimumAssetLunarAmountEq":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAssetLunarAmountEq: Number(value)
            })
            break
        case "contentUserProductionMinimumAssetLunarAmountNeq":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAssetLunarAmountNeq: Number(value)
            })
            break
        case "contentUserProductionMinimumAssetLunarAmountMin":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAssetLunarAmountMin: Number(value)
            })
            break
        case "contentUserProductionMinimumAssetLunarAmountMax":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAssetLunarAmountMax: Number(value)
            })
            break
        case "contentUserProductionMinimumUserLevelIdEq":
            into.setter({
                ...into.value,
                contentUserProductionMinimumUserLevelIdEq: BigInt(value)
            })
            break
        case "contentUserProductionMinimumUserLevelIdNeq":
            into.setter({
                ...into.value,
                contentUserProductionMinimumUserLevelIdNeq: BigInt(value)
            })
            break
        case "contentUserProductionMinimumUserLevelIdMin":
            into.setter({
                ...into.value,
                contentUserProductionMinimumUserLevelIdMin: BigInt(value)
            })
            break
        case "contentUserProductionMinimumUserLevelIdMax":
            into.setter({
                ...into.value,
                contentUserProductionMinimumUserLevelIdMax: BigInt(value)
            })
            break
        case "contentUserProductionMinimumAccuracyEq":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAccuracyEq: Number(value)
            })
            break
        case "contentUserProductionMinimumAccuracyNeq":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAccuracyNeq: Number(value)
            })
            break
        case "contentUserProductionMinimumAccuracyMin":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAccuracyMin: Number(value)
            })
            break
        case "contentUserProductionMinimumAccuracyMax":
            into.setter({
                ...into.value,
                contentUserProductionMinimumAccuracyMax: Number(value)
            })
            break
        case "contentUserProductionPassAccuracyEq":
            into.setter({
                ...into.value,
                contentUserProductionPassAccuracyEq: Number(value)
            })
            break
        case "contentUserProductionPassAccuracyNeq":
            into.setter({
                ...into.value,
                contentUserProductionPassAccuracyNeq: Number(value)
            })
            break
        case "contentUserProductionPassAccuracyMin":
            into.setter({
                ...into.value,
                contentUserProductionPassAccuracyMin: Number(value)
            })
            break
        case "contentUserProductionPassAccuracyMax":
            into.setter({
                ...into.value,
                contentUserProductionPassAccuracyMax: Number(value)
            })
            break
        case "contentUserProductionPassHighAccuracyEq":
            into.setter({
                ...into.value,
                contentUserProductionPassHighAccuracyEq: Number(value)
            })
            break
        case "contentUserProductionPassHighAccuracyNeq":
            into.setter({
                ...into.value,
                contentUserProductionPassHighAccuracyNeq: Number(value)
            })
            break
        case "contentUserProductionPassHighAccuracyMin":
            into.setter({
                ...into.value,
                contentUserProductionPassHighAccuracyMin: Number(value)
            })
            break
        case "contentUserProductionPassHighAccuracyMax":
            into.setter({
                ...into.value,
                contentUserProductionPassHighAccuracyMax: Number(value)
            })
            break
        case "contentUserProductionPassSolarAmountHighAccuracyEq":
            into.setter({
                ...into.value,
                contentUserProductionPassSolarAmountHighAccuracyEq: Number(value)
            })
            break
        case "contentUserProductionPassSolarAmountHighAccuracyNeq":
            into.setter({
                ...into.value,
                contentUserProductionPassSolarAmountHighAccuracyNeq: Number(value)
            })
            break
        case "contentUserProductionPassSolarAmountHighAccuracyMin":
            into.setter({
                ...into.value,
                contentUserProductionPassSolarAmountHighAccuracyMin: Number(value)
            })
            break
        case "contentUserProductionPassSolarAmountHighAccuracyMax":
            into.setter({
                ...into.value,
                contentUserProductionPassSolarAmountHighAccuracyMax: Number(value)
            })
            break
        case "contentUserProductionStarAmountEq":
            into.setter({
                ...into.value,
                contentUserProductionStarAmountEq: Number(value)
            })
            break
        case "contentUserProductionStarAmountNeq":
            into.setter({
                ...into.value,
                contentUserProductionStarAmountNeq: Number(value)
            })
            break
        case "contentUserProductionStarAmountMin":
            into.setter({
                ...into.value,
                contentUserProductionStarAmountMin: Number(value)
            })
            break
        case "contentUserProductionStarAmountMax":
            into.setter({
                ...into.value,
                contentUserProductionStarAmountMax: Number(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlEq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlEq: String(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlNeq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlNeq: String(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlMin":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlMin: String(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlMax":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlMax: String(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlLike":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlLike: String(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlNotLike":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlNotLike: String(value)
            })
            break
        case "contentUserProductionDefaultDetailUrlBrowserEq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlBrowserEq: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultDetailUrlBrowserNeq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlBrowserNeq: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultDetailUrlBrowserMin":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlBrowserMin: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultDetailUrlBrowserMax":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlBrowserMax: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultDetailUrlBrowserIn":
            into.setter({
                ...into.value,
                contentUserProductionDefaultDetailUrlBrowserIn: value
                    .split('|')
                    .map(e => e as EnvBrowser)
            })
            break
        case "contentUserProductionDefaultResultUrlEq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlEq: String(value)
            })
            break
        case "contentUserProductionDefaultResultUrlNeq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlNeq: String(value)
            })
            break
        case "contentUserProductionDefaultResultUrlMin":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlMin: String(value)
            })
            break
        case "contentUserProductionDefaultResultUrlMax":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlMax: String(value)
            })
            break
        case "contentUserProductionDefaultResultUrlLike":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlLike: String(value)
            })
            break
        case "contentUserProductionDefaultResultUrlNotLike":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlNotLike: String(value)
            })
            break
        case "contentUserProductionDefaultResultUrlBrowserEq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlBrowserEq: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultResultUrlBrowserNeq":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlBrowserNeq: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultResultUrlBrowserMin":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlBrowserMin: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultResultUrlBrowserMax":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlBrowserMax: value as EnvBrowser
            })
            break
        case "contentUserProductionDefaultResultUrlBrowserIn":
            into.setter({
                ...into.value,
                contentUserProductionDefaultResultUrlBrowserIn: value
                    .split('|')
                    .map(e => e as EnvBrowser)
            })
            break
        case "contentUserProductionScriptPageSizeEq":
            into.setter({
                ...into.value,
                contentUserProductionScriptPageSizeEq: Number(value)
            })
            break
        case "contentUserProductionScriptPageSizeNeq":
            into.setter({
                ...into.value,
                contentUserProductionScriptPageSizeNeq: Number(value)
            })
            break
        case "contentUserProductionScriptPageSizeMin":
            into.setter({
                ...into.value,
                contentUserProductionScriptPageSizeMin: Number(value)
            })
            break
        case "contentUserProductionScriptPageSizeMax":
            into.setter({
                ...into.value,
                contentUserProductionScriptPageSizeMax: Number(value)
            })
            break
        case "contentLikePageSizeEq":
            into.setter({
                ...into.value,
                contentLikePageSizeEq: Number(value)
            })
            break
        case "contentLikePageSizeNeq":
            into.setter({
                ...into.value,
                contentLikePageSizeNeq: Number(value)
            })
            break
        case "contentLikePageSizeMin":
            into.setter({
                ...into.value,
                contentLikePageSizeMin: Number(value)
            })
            break
        case "contentLikePageSizeMax":
            into.setter({
                ...into.value,
                contentLikePageSizeMax: Number(value)
            })
            break
        case "contentPolicyPageSizeEq":
            into.setter({
                ...into.value,
                contentPolicyPageSizeEq: Number(value)
            })
            break
        case "contentPolicyPageSizeNeq":
            into.setter({
                ...into.value,
                contentPolicyPageSizeNeq: Number(value)
            })
            break
        case "contentPolicyPageSizeMin":
            into.setter({
                ...into.value,
                contentPolicyPageSizeMin: Number(value)
            })
            break
        case "contentPolicyPageSizeMax":
            into.setter({
                ...into.value,
                contentPolicyPageSizeMax: Number(value)
            })
            break
        case "contentQueryPageSizeEq":
            into.setter({
                ...into.value,
                contentQueryPageSizeEq: Number(value)
            })
            break
        case "contentQueryPageSizeNeq":
            into.setter({
                ...into.value,
                contentQueryPageSizeNeq: Number(value)
            })
            break
        case "contentQueryPageSizeMin":
            into.setter({
                ...into.value,
                contentQueryPageSizeMin: Number(value)
            })
            break
        case "contentQueryPageSizeMax":
            into.setter({
                ...into.value,
                contentQueryPageSizeMax: Number(value)
            })
            break
        case "contentQueryPopularDurationMinutesEq":
            into.setter({
                ...into.value,
                contentQueryPopularDurationMinutesEq: Number(value)
            })
            break
        case "contentQueryPopularDurationMinutesNeq":
            into.setter({
                ...into.value,
                contentQueryPopularDurationMinutesNeq: Number(value)
            })
            break
        case "contentQueryPopularDurationMinutesMin":
            into.setter({
                ...into.value,
                contentQueryPopularDurationMinutesMin: Number(value)
            })
            break
        case "contentQueryPopularDurationMinutesMax":
            into.setter({
                ...into.value,
                contentQueryPopularDurationMinutesMax: Number(value)
            })
            break
        case "contentQueryPopularPageSizeEq":
            into.setter({
                ...into.value,
                contentQueryPopularPageSizeEq: Number(value)
            })
            break
        case "contentQueryPopularPageSizeNeq":
            into.setter({
                ...into.value,
                contentQueryPopularPageSizeNeq: Number(value)
            })
            break
        case "contentQueryPopularPageSizeMin":
            into.setter({
                ...into.value,
                contentQueryPopularPageSizeMin: Number(value)
            })
            break
        case "contentQueryPopularPageSizeMax":
            into.setter({
                ...into.value,
                contentQueryPopularPageSizeMax: Number(value)
            })
            break
        case "contentReportReasonPageSizeEq":
            into.setter({
                ...into.value,
                contentReportReasonPageSizeEq: Number(value)
            })
            break
        case "contentReportReasonPageSizeNeq":
            into.setter({
                ...into.value,
                contentReportReasonPageSizeNeq: Number(value)
            })
            break
        case "contentReportReasonPageSizeMin":
            into.setter({
                ...into.value,
                contentReportReasonPageSizeMin: Number(value)
            })
            break
        case "contentReportReasonPageSizeMax":
            into.setter({
                ...into.value,
                contentReportReasonPageSizeMax: Number(value)
            })
            break
        case "contentReportPageSizeEq":
            into.setter({
                ...into.value,
                contentReportPageSizeEq: Number(value)
            })
            break
        case "contentReportPageSizeNeq":
            into.setter({
                ...into.value,
                contentReportPageSizeNeq: Number(value)
            })
            break
        case "contentReportPageSizeMin":
            into.setter({
                ...into.value,
                contentReportPageSizeMin: Number(value)
            })
            break
        case "contentReportPageSizeMax":
            into.setter({
                ...into.value,
                contentReportPageSizeMax: Number(value)
            })
            break
        case "contentReportDailyThresholdEq":
            into.setter({
                ...into.value,
                contentReportDailyThresholdEq: Number(value)
            })
            break
        case "contentReportDailyThresholdNeq":
            into.setter({
                ...into.value,
                contentReportDailyThresholdNeq: Number(value)
            })
            break
        case "contentReportDailyThresholdMin":
            into.setter({
                ...into.value,
                contentReportDailyThresholdMin: Number(value)
            })
            break
        case "contentReportDailyThresholdMax":
            into.setter({
                ...into.value,
                contentReportDailyThresholdMax: Number(value)
            })
            break
        case "contentDetailUrlPageSizeEq":
            into.setter({
                ...into.value,
                contentDetailUrlPageSizeEq: Number(value)
            })
            break
        case "contentDetailUrlPageSizeNeq":
            into.setter({
                ...into.value,
                contentDetailUrlPageSizeNeq: Number(value)
            })
            break
        case "contentDetailUrlPageSizeMin":
            into.setter({
                ...into.value,
                contentDetailUrlPageSizeMin: Number(value)
            })
            break
        case "contentDetailUrlPageSizeMax":
            into.setter({
                ...into.value,
                contentDetailUrlPageSizeMax: Number(value)
            })
            break
        case "contentDetailUrlClickPageSizeEq":
            into.setter({
                ...into.value,
                contentDetailUrlClickPageSizeEq: Number(value)
            })
            break
        case "contentDetailUrlClickPageSizeNeq":
            into.setter({
                ...into.value,
                contentDetailUrlClickPageSizeNeq: Number(value)
            })
            break
        case "contentDetailUrlClickPageSizeMin":
            into.setter({
                ...into.value,
                contentDetailUrlClickPageSizeMin: Number(value)
            })
            break
        case "contentDetailUrlClickPageSizeMax":
            into.setter({
                ...into.value,
                contentDetailUrlClickPageSizeMax: Number(value)
            })
            break
        case "contentDetailUrlClickExpEq":
            into.setter({
                ...into.value,
                contentDetailUrlClickExpEq: Number(value)
            })
            break
        case "contentDetailUrlClickExpNeq":
            into.setter({
                ...into.value,
                contentDetailUrlClickExpNeq: Number(value)
            })
            break
        case "contentDetailUrlClickExpMin":
            into.setter({
                ...into.value,
                contentDetailUrlClickExpMin: Number(value)
            })
            break
        case "contentDetailUrlClickExpMax":
            into.setter({
                ...into.value,
                contentDetailUrlClickExpMax: Number(value)
            })
            break
        case "contentResultUrlPageSizeEq":
            into.setter({
                ...into.value,
                contentResultUrlPageSizeEq: Number(value)
            })
            break
        case "contentResultUrlPageSizeNeq":
            into.setter({
                ...into.value,
                contentResultUrlPageSizeNeq: Number(value)
            })
            break
        case "contentResultUrlPageSizeMin":
            into.setter({
                ...into.value,
                contentResultUrlPageSizeMin: Number(value)
            })
            break
        case "contentResultUrlPageSizeMax":
            into.setter({
                ...into.value,
                contentResultUrlPageSizeMax: Number(value)
            })
            break
        case "contentResultUrlClickPageSizeEq":
            into.setter({
                ...into.value,
                contentResultUrlClickPageSizeEq: Number(value)
            })
            break
        case "contentResultUrlClickPageSizeNeq":
            into.setter({
                ...into.value,
                contentResultUrlClickPageSizeNeq: Number(value)
            })
            break
        case "contentResultUrlClickPageSizeMin":
            into.setter({
                ...into.value,
                contentResultUrlClickPageSizeMin: Number(value)
            })
            break
        case "contentResultUrlClickPageSizeMax":
            into.setter({
                ...into.value,
                contentResultUrlClickPageSizeMax: Number(value)
            })
            break
        case "contentResultUrlClickExpEq":
            into.setter({
                ...into.value,
                contentResultUrlClickExpEq: Number(value)
            })
            break
        case "contentResultUrlClickExpNeq":
            into.setter({
                ...into.value,
                contentResultUrlClickExpNeq: Number(value)
            })
            break
        case "contentResultUrlClickExpMin":
            into.setter({
                ...into.value,
                contentResultUrlClickExpMin: Number(value)
            })
            break
        case "contentResultUrlClickExpMax":
            into.setter({
                ...into.value,
                contentResultUrlClickExpMax: Number(value)
            })
            break
        case "contentReviewPageSizeEq":
            into.setter({
                ...into.value,
                contentReviewPageSizeEq: Number(value)
            })
            break
        case "contentReviewPageSizeNeq":
            into.setter({
                ...into.value,
                contentReviewPageSizeNeq: Number(value)
            })
            break
        case "contentReviewPageSizeMin":
            into.setter({
                ...into.value,
                contentReviewPageSizeMin: Number(value)
            })
            break
        case "contentReviewPageSizeMax":
            into.setter({
                ...into.value,
                contentReviewPageSizeMax: Number(value)
            })
            break
        case "contentTargetPageSizeEq":
            into.setter({
                ...into.value,
                contentTargetPageSizeEq: Number(value)
            })
            break
        case "contentTargetPageSizeNeq":
            into.setter({
                ...into.value,
                contentTargetPageSizeNeq: Number(value)
            })
            break
        case "contentTargetPageSizeMin":
            into.setter({
                ...into.value,
                contentTargetPageSizeMin: Number(value)
            })
            break
        case "contentTargetPageSizeMax":
            into.setter({
                ...into.value,
                contentTargetPageSizeMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
