import {EnvEventModificationDomain} from "../../domain/env/EnvEventModificationDomain"

export const EnvEventModificationDescriptor: Record<keyof EnvEventModificationDomain, (o: EnvEventModificationDomain) => string> = {
    id(o: EnvEventModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvEventModificationDomain): string {
        return o.creatorId.toString()
    },
    envEventId(o: EnvEventModificationDomain): string {
        return o.envEventId.toString()
    },
    title(o: EnvEventModificationDomain): string {
        return o.title
    },
    url(o: EnvEventModificationDomain): string {
        return o.url
    },
    createdAt(o: EnvEventModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
