import {EnvClientPlatform} from "./data/constant/env/EnvClientPlatform";
import {EnvClientDistribution} from "./data/constant/env/EnvClientDistribution";

export namespace Environments {
    export const versionName = '0.2.2'
    export const platform = EnvClientPlatform.AdminWeb
    export const distribution = EnvClientDistribution.InnerTest
    export const baseUrl = (() => {
        switch (distribution) {
            // @ts-ignore
            case EnvClientDistribution.LocalTest:
                return 'http://localhost:8080'
            // @ts-ignore
            case EnvClientDistribution.InnerTest:
                return 'https://inner.backend.twodo.vowingadmin2.com:443'
            // @ts-ignore
            case EnvClientDistribution.OpenTest:
                return 'https://open.backend.twodo.vowingadmin2.com:443'
            // @ts-ignore
            case EnvClientDistribution.Release:
                return 'https://backend.twodo.vowingadmin2.com:443'
        }
    })()
}