import {UserWithdrawalReasonModificationDomain} from "../../domain/user/UserWithdrawalReasonModificationDomain"

/**
 * 사용자 탈퇴 사유 수정 이력 속성 설명
 */
export const UserWithdrawalReasonModificationDescription: Record<keyof UserWithdrawalReasonModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    userWithdrawalReasonId: '사용자 탈퇴 사유 ID',
    body: '사유',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
