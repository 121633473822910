import {UserBlockReasonVerboseDomain} from "../../domain/user/UserBlockReasonVerboseDomain"
import {UserBlockReasonId} from "../../id/user/UserBlockReasonId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 정지 사유 상세
 */
export class UserBlockReasonVerboseValueObject implements VerboseValueObject, UserBlockReasonVerboseDomain {
    /**
     * 기본키
     */
    id: UserBlockReasonId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 사유
     */
    body: string
    /**
     * 사유 적용 수
     */
    userBlockCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof UserBlockReasonVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.body = String(o.body)
        this.userBlockCount = Number(o.userBlockCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
