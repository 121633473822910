import {useStatePair} from "react-type-extension";
import {UserNotificationVerboseValueObject} from "../../../data/value-object/user/UserNotificationVerboseValueObject";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {UserNotificationIO} from "../../../io/UserIO";
import {Button, Title3} from "@fluentui/react-components";
import {UserNotificationSubject} from "../../../data/constant/user/UserNotificationSubject";
import {PostEnumField, PostFieldWrapper, PostInputField} from "../../PostField";
import {UserNotificationDescription} from "../../../data/description/user/UserNotificationDescription";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userNotificationId: string }>()
    const phase = useStatePair<Phase>(Phase.Loading)
    const item = useStatePair<UserNotificationVerboseValueObject | undefined>(undefined)
    const title = useStatePair('')
    const body = useStatePair('')
    const subject = useStatePair<UserNotificationSubject>(UserNotificationSubject.Admin)
    const deepLink = useStatePair<string | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userNotificationId !== undefined) {
            UserNotificationIO
                .getVerboseById(BigInt(params.userNotificationId))
                .then(value => {
                    item.setter(value)
                    title.setter(value.title)
                    body.setter(value.body)
                    subject.setter(value.subject)
                    deepLink.setter(value.deepLink)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UserNotificationIO
            .put(id, {
                title: title.value,
                body: body.value,
                subject: subject.value,
                deepLink: deepLink.value,
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/notifications/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [title.value, body.value, subject.value, deepLink.value,])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={UserNotificationDescription.title}>
                <PostInputField
                    {...props}
                    value={title.value}
                    setter={title.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UserNotificationDescription.body}>
                <PostInputField
                    {...props}
                    value={body.value}
                    setter={body.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UserNotificationDescription.subject}>
                <PostEnumField
                    {...props}
                    value={subject.value}
                    setter={subject.setter}
                    selections={UserNotificationSubject.selections} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UserNotificationDescription.deepLink}
                showUnusedCheckbox
                isUnusedCheckboxChecked={deepLink.value === undefined}
                onUnusedCheckboxChange={checked => deepLink.setter(checked ? undefined : '')}>
                <PostInputField
                    {...props}
                    disabled={deepLink.value === undefined}
                    value={title.value}
                    setter={title.setter} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}