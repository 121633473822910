import {AssetCouponDomain} from "../../domain/asset/AssetCouponDomain"

export const AssetCouponDescriptor: Record<keyof AssetCouponDomain, (o: AssetCouponDomain) => string> = {
    id(o: AssetCouponDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetCouponDomain): string {
        return o.creatorId.toString()
    },
    code(o: AssetCouponDomain): string {
        return o.code
    },
    amount(o: AssetCouponDomain): string {
        return o.amount.toString()
    },
    assetLunarAmount(o: AssetCouponDomain): string {
        return o.assetLunarAmount.toString()
    },
    assetSolarAmount(o: AssetCouponDomain): string {
        return o.assetSolarAmount.toString()
    },
    assetStarAmount(o: AssetCouponDomain): string {
        return o.assetStarAmount.toString()
    },
    usageCount(o: AssetCouponDomain): string {
        return o.usageCount.toString()
    },
    createdAt(o: AssetCouponDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: AssetCouponDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: AssetCouponDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
