import {ContentCommentLikeValueObject} from "../../../../data/value-object/content/ContentCommentLikeValueObject";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentCommentLikeSearchOption} from "../../../../data/search-option/content/ContentCommentLikeSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {ContentCommentLikeIO} from "../../../../io/ContentIO";
import {ContentCommentLikeDescription} from "../../../../data/description/content/ContentCommentLikeDescription";
import {ContentCommentLikeDescriptor} from "../../../../data/descriptor/content/ContentCommentLikeDescriptor";
import ListOption from "../../../ListOption";
import {
    ContentCommentLikeSearchOptionDescription
} from "../../../../data/description/content/ContentCommentLikeSearchOptionDescription";
import {
    applyContentCommentLikeSearchOption,
    ContentCommentLikeSearchOptionField
} from "../../../search-option-field/content/ContentCommentLikeSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentCommentLikeValueObject[]>([])
    const option = useStatePair<ContentCommentLikeSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentLikeIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentCommentLikeDescription}
                descriptor={ContentCommentLikeDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/comments/likes/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentCommentLikeSearchOptionDescription}
                fields={ContentCommentLikeSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentCommentLikeSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentCommentLikeDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}