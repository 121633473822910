import {EnvAgreementIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvAgreementVerboseValueObject} from "../../../data/value-object/env/EnvAgreementVerboseValueObject";
import {EnvAgreementType} from "../../../data/constant/env/EnvAgreementType";
import {useCallback, useEffect} from "react";
import {PostEnumField, PostFieldWrapper, PostTextareaField} from "../../PostField";

enum Phase {
    Loading,
    Waiting,
    Posting,
    Finished,
}

export default function Copy(props: RouterProps) {
    const params = useParams<{ envAgreementId: string }>()
    const item = useStatePair<EnvAgreementVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const type = useStatePair<EnvAgreementType>(EnvAgreementType.Service)
    const body = useStatePair<string>('')

    useEffect(() => {
        if (item.value === undefined && params.envAgreementId !== undefined) {
            EnvAgreementIO
                .getVerboseById(BigInt(params.envAgreementId))
                .then(value => {
                    item.setter(value)
                    type.setter(value.type)
                    body.setter(value.body)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Posting)
        EnvAgreementIO
            .post({
                type: type.value,
                body: body.value
            })
            .then(value => {
                phase.setter(Phase.Finished)
                alert('생성되었습니다.')
                document.location = `/env/agreements/${value.id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [type.value, body.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={'유형'}>
                <PostEnumField
                    {...props}
                    value={type.value}
                    setter={type.setter}
                    selections={EnvAgreementType.selections} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={'내용'}>
                <PostTextareaField
                    {...props}
                    value={body.value}
                    setter={body.setter}/>
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}