import {ContentReportSearchOption} from "../../search-option/content/ContentReportSearchOption"

/**
 * 콘텐츠 신고 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentReportSearchOptionDescription: Record<keyof ContentReportSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    contentIdEq: '콘텐츠 ID 일치',
    contentIdNeq: '콘텐츠 ID 불일치',
    contentIdMin: '콘텐츠 ID 최소',
    contentIdMax: '콘텐츠 ID 최대',
    contentReportReasonIdEq: '콘텐츠 신고 사유 ID 일치',
    contentReportReasonIdNeq: '콘텐츠 신고 사유 ID 불일치',
    contentReportReasonIdMin: '콘텐츠 신고 사유 ID 최소',
    contentReportReasonIdMax: '콘텐츠 신고 사유 ID 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
