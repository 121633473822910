import {ContentCommentReportDomain} from "../../domain/content/ContentCommentReportDomain"

export const ContentCommentReportDescriptor: Record<keyof ContentCommentReportDomain, (o: ContentCommentReportDomain) => string> = {
    id(o: ContentCommentReportDomain): string {
        return o.id.toString()
    },
    contentCommentId(o: ContentCommentReportDomain): string {
        return o.contentCommentId.toString()
    },
    contentCommentReportReasonId(o: ContentCommentReportDomain): string {
        return o.contentCommentReportReasonId.toString()
    },
    userId(o: ContentCommentReportDomain): string {
        return o.userId.toString()
    },
    createdAt(o: ContentCommentReportDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
