import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {useCallback, useEffect} from "react";
import {Button, Spinner, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostInputField, PostNumberField} from "../../PostField";
import {UserLevelIO} from "../../../io/UserIO";
import {UserLevelDescription} from "../../../data/description/user/UserLevelDescription";
import {UserLevelValueObject} from "../../../data/value-object/user/UserLevelValueObject";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userLevelId: string }>()
    const item = useStatePair<UserLevelValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const name = useStatePair('')
    const exp = useStatePair(0)
    const rewardAssetLunarAmount = useStatePair(0)
    const rewardAssetSolarAmount = useStatePair(0)
    const rewardAssetStarAmount = useStatePair(0)

    useEffect(() => {
        if (params.userLevelId !== undefined) {
            UserLevelIO
                .getById(BigInt(params.userLevelId))
                .then(value => {
                    item.setter(value)
                    name.setter(value.name)
                    exp.setter(value.exp)
                    rewardAssetLunarAmount.setter(value.rewardAssetLunarAmount)
                    rewardAssetSolarAmount.setter(value.rewardAssetSolarAmount)
                    rewardAssetStarAmount.setter(value.rewardAssetStarAmount)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UserLevelIO
            .put(id, {
                name: name.value,
                exp: exp.value,
                rewardAssetLunarAmount: rewardAssetLunarAmount.value,
                rewardAssetSolarAmount: rewardAssetSolarAmount.value,
                rewardAssetStarAmount: rewardAssetStarAmount.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/levels/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [name.value, exp.value, rewardAssetLunarAmount.value, rewardAssetSolarAmount.value, rewardAssetStarAmount.value])

    return <>
        {phase.value === Phase.Loading && <Spinner />}
        {(phase.value === Phase.Waiting || phase.value === Phase.Putting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.name}>
                    <PostInputField
                        {...props}
                        value={name.value}
                        setter={name.setter}
                        maxLength={20}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.exp}>
                    <PostNumberField
                        {...props}
                        value={exp.value}
                        setter={exp.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.rewardAssetLunarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetLunarAmount.value}
                        setter={rewardAssetLunarAmount.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.rewardAssetSolarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetSolarAmount.value}
                        setter={rewardAssetSolarAmount.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserLevelDescription.rewardAssetStarAmount}>
                    <PostNumberField
                        {...props}
                        value={rewardAssetStarAmount.value}
                        setter={rewardAssetStarAmount.setter}/>
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
    </>
}