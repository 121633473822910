import {ContentUserProductionScriptSearchOption} from "../../search-option/content/ContentUserProductionScriptSearchOption"

/**
 * 와글와글 스크립트 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentUserProductionScriptSearchOptionDescription: Record<keyof ContentUserProductionScriptSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    audioIdNeq: '오디오 ID 불일치',
    audioIdMin: '오디오 ID 최소',
    audioIdMax: '오디오 ID 최대',
    audioIdIsNull: '오디오 ID 값 존재 여부',
    scriptEq: '본문 일치',
    scriptNeq: '본문 불일치',
    scriptMin: '본문 최소',
    scriptMax: '본문 최대',
    scriptLike: '본문 포함',
    scriptNotLike: '본문 제외',
    transcriptionEq: '인식된 텍스트 일치',
    transcriptionNeq: '인식된 텍스트 불일치',
    transcriptionMin: '인식된 텍스트 최소',
    transcriptionMax: '인식된 텍스트 최대',
    transcriptionLike: '인식된 텍스트 포함',
    transcriptionNotLike: '인식된 텍스트 제외',
    accuracyEq: '일치율(%) 일치',
    accuracyNeq: '일치율(%) 불일치',
    accuracyMin: '일치율(%) 최소',
    accuracyMax: '일치율(%) 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
