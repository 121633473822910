import {UiHomeContentUserProductionModificationDomain} from "../../domain/ui/UiHomeContentUserProductionModificationDomain"

/**
 * 홈 와글와글 콘텐츠 수정 이력 속성 설명
 */
export const UiHomeContentUserProductionModificationDescription: Record<keyof UiHomeContentUserProductionModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    uiHomeContentUserProductionId: '홈 와글와글 콘텐츠 ID',
    displayOrder: '표시 우선 순위',
    url: 'URL',
    createdAt: '수정일'
}
