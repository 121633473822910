import {EnvBankPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvBankPostDataTransferObjectDescription"
import {EnvBankPostState} from "../../../data/data-transfer-object-state/env/EnvBankPostState"
import {EnvBankPostDataTransferObject} from "../../../data/data-transfer-object/env/EnvBankPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvBankPostState
}

export const EnvBankPostField: Record<Capitalize<keyof EnvBankPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Name(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvBankPostDataTransferObjectDescription.name}>
                <PostTextareaField
                    {...props}
                    value={props.state.name.value}
                    setter={props.state.name.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    DisplayOrder(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvBankPostDataTransferObjectDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={props.state.displayOrder.value}
                    setter={props.state.displayOrder.setter} />
            </PostFieldWrapper>
        )
    },
}

export function EnvBankPostFields(props: PostFieldProps) {
    return <>
        <EnvBankPostField.Name
            {...props}
            state={props.state} />
        <EnvBankPostField.DisplayOrder
            {...props}
            state={props.state} />
    </>
}
