import {UserWithdrawalReasonDomain} from "../../domain/user/UserWithdrawalReasonDomain"
import {UserId} from "../../id/user/UserId"
import {UserWithdrawalReasonId} from "../../id/user/UserWithdrawalReasonId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 탈퇴 사유
 */
export class UserWithdrawalReasonValueObject implements ValueObject, UserWithdrawalReasonDomain {
    /**
     * 기본키
     */
    id: UserWithdrawalReasonId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 사유
     */
    body: string
    /**
     * 표시 순서
     */
    displayOrder: number
    /**
     * 탈퇴 사유 적용 수
     */
    userWithdrawalCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof UserWithdrawalReasonDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.body = String(o.body)
        this.displayOrder = Number(o.displayOrder)
        this.userWithdrawalCount = Number(o.userWithdrawalCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
