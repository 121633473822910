import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import React, {useCallback, useEffect} from "react";
import {AssetLunarPurchaseOptionIO} from "../../../../io/AssetIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFinishDialog, PostNumberField} from "../../../PostField";
import {useParams} from "react-router-dom";
import {
    AssetLunarPurchaseOptionVerboseValueObject
} from "../../../../data/value-object/asset/AssetLunarPurchaseOptionVerboseValueObject";
import {
    AssetLunarPurchaseOptionValueObject
} from "../../../../data/value-object/asset/AssetLunarPurchaseOptionValueObject";
import {
    useAssetLunarPurchaseOptionPutState
} from "../../../../data/data-transfer-object-state/asset/AssetLunarPurchaseOptionPutState";
import {
    AssetLunarPurchaseOptionPutFields
} from "../../../data-transfer-object-field/asset/AssetLunarPurchaseOptionPutField";

enum Phase {
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ assetLunarPurchaseOptionId: string }>()
    const item = useStatePair<AssetLunarPurchaseOptionValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useAssetLunarPurchaseOptionPutState()

    useEffect(() => {
        if (params.assetLunarPurchaseOptionId !== undefined) {
            AssetLunarPurchaseOptionIO
                .getVerboseById(BigInt(params.assetLunarPurchaseOptionId))
                .then(value => {
                    item.setter(value)
                    state.fromValueObject(value)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        AssetLunarPurchaseOptionIO
            .put(id, state.toDataTransferObject())
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/assets/lunars/purchaseOptions/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, state.values())

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <AssetLunarPurchaseOptionPutFields
                {...props}
                state={state} />
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}