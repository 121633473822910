export enum EnvAgreementType {
    /**
     * 서비스 이용 약관
     */
    Service = 'Service',

    /**
     * 위치 정보 사용
     */
    Location = 'Location',

    /**
     * 개인정보 처리 방침
     */
    Privacy = 'Privacy',

    /**
     * 홍보성 알림 수신
     */
    Commercial = 'Commercial'
}

export namespace EnvAgreementType {
    export function description(o: EnvAgreementType) {
        switch (o) {
            case EnvAgreementType.Service: return '서비스 이용 약관'
            case EnvAgreementType.Location: return '위치 정보 사용'
            case EnvAgreementType.Privacy: return '개인정보 처리 방침'
            case EnvAgreementType.Commercial: return '홍보성 알림 수신'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: EnvAgreementType.Service, label: description(EnvAgreementType.Service) },
        { value: EnvAgreementType.Location, label: description(EnvAgreementType.Location) },
        { value: EnvAgreementType.Privacy, label: description(EnvAgreementType.Privacy) },
        { value: EnvAgreementType.Commercial, label: description(EnvAgreementType.Commercial) }
    ]
}
