import ListPageWrapper from "../../ListPageWrapper";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentLikeValueObject} from "../../../data/value-object/content/ContentLikeValueObject";
import {ContentLikeSearchOption} from "../../../data/search-option/content/ContentLikeSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import {ContentLikeIO} from "../../../io/ContentIO";
import {ContentLikeDescription} from "../../../data/description/content/ContentLikeDescription";
import {ContentLikeDescriptor} from "../../../data/descriptor/content/ContentLikeDescriptor";
import ListOption from "../../ListOption";
import {
    ContentLikeSearchOptionDescription
} from "../../../data/description/content/ContentLikeSearchOptionDescription";
import {
    applyContentLikeSearchOption,
    ContentLikeSearchOptionField
} from "../../search-option-field/content/ContentLikeSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentLikeValueObject[]>([])
    const option = useStatePair<ContentLikeSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentLikeIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentLikeDescription}
                descriptor={ContentLikeDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/likes/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentLikeSearchOptionDescription}
                fields={ContentLikeSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentLikeSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentLikeDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}