import {UserSessionDomain} from "../../domain/user/UserSessionDomain"

/**
 * 사용자 세션 속성 설명
 */
export const UserSessionDescription: Record<keyof UserSessionDomain, string> = {
    id: '기본키',
    userSignInId: '사용자 로그인 ID',
    outbound: '쿠키에 포함되는 ID',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
