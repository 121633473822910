import {EnvAgreementType} from "../../constant/env/EnvAgreementType"
import {EnvAgreementModificationDomain} from "../../domain/env/EnvAgreementModificationDomain"
import {EnvAgreementId} from "../../id/env/EnvAgreementId"
import {EnvAgreementModificationId} from "../../id/env/EnvAgreementModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 이용 약관 수정 이력
 */
export class EnvAgreementModificationValueObject implements ValueObject, EnvAgreementModificationDomain {
    /**
     * 기본키
     */
    id: EnvAgreementModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 이용 약관 ID
     */
    envAgreementId: EnvAgreementId
    /**
     * 유형
     */
    type: EnvAgreementType
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvAgreementModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envAgreementId = BigInt(o.envAgreementId)
        this.type = o.type as EnvAgreementType
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
    }
}
