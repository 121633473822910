import {EnvCountryDomain} from "../../domain/env/EnvCountryDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 국가
 */
export class EnvCountryValueObject implements ValueObject, EnvCountryDomain {
    /**
     * 기본키
     */
    id: EnvCountryId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 이름
     */
    name: string
    /**
     * ISO 코드
     */
    isoCode: string
    /**
     * 전화번호 코드
     */
    phoneCode: number
    /**
     * 이모지
     */
    emoji: string
    /**
     * 출신 국가로 등록한 사용자 수
     */
    userBirthCount: number
    /**
     * 거주 국가로 등록한 사용자 수
     */
    userResidenceCount: number
    /**
     * 전화번호 코드로 등록한 사용자 수
     */
    userPhoneCodeCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof EnvCountryDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.name = String(o.name)
        this.isoCode = String(o.isoCode)
        this.phoneCode = Number(o.phoneCode)
        this.emoji = String(o.emoji)
        this.userBirthCount = Number(o.userBirthCount)
        this.userResidenceCount = Number(o.userResidenceCount)
        this.userPhoneCodeCount = Number(o.userPhoneCodeCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
