import {ContentResultUrlClickSearchOptionDescription} from "../../../data/description/content/ContentResultUrlClickSearchOptionDescription"
import {ContentResultUrlClickSearchOption} from "../../../data/search-option/content/ContentResultUrlClickSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentResultUrlClickSearchOptionField(props: ListOptionFieldProps<ContentResultUrlClickSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.contentResultUrlIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.contentResultUrlIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.contentResultUrlIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentResultUrlIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.contentResultUrlIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.assetStarUsageIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.assetStarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.assetStarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.assetStarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.assetStarUsageIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userExpIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userExpIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userExpIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userExpIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userExpIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.userExpIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentResultUrlClickSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentResultUrlClickSearchOption(key: keyof ContentResultUrlClickSearchOption, value: string, into: StatePair<ContentResultUrlClickSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "contentResultUrlIdEq":
            into.setter({
                ...into.value,
                contentResultUrlIdEq: BigInt(value)
            })
            break
        case "contentResultUrlIdNeq":
            into.setter({
                ...into.value,
                contentResultUrlIdNeq: BigInt(value)
            })
            break
        case "contentResultUrlIdMin":
            into.setter({
                ...into.value,
                contentResultUrlIdMin: BigInt(value)
            })
            break
        case "contentResultUrlIdMax":
            into.setter({
                ...into.value,
                contentResultUrlIdMax: BigInt(value)
            })
            break
        case "assetStarUsageIdEq":
            into.setter({
                ...into.value,
                assetStarUsageIdEq: BigInt(value)
            })
            break
        case "assetStarUsageIdNeq":
            into.setter({
                ...into.value,
                assetStarUsageIdNeq: BigInt(value)
            })
            break
        case "assetStarUsageIdMin":
            into.setter({
                ...into.value,
                assetStarUsageIdMin: BigInt(value)
            })
            break
        case "assetStarUsageIdMax":
            into.setter({
                ...into.value,
                assetStarUsageIdMax: BigInt(value)
            })
            break
        case "assetStarUsageIdIsNull":
            into.setter({
                ...into.value,
                assetStarUsageIdIsNull: Boolean(value)
            })
            break
        case "userExpIdEq":
            into.setter({
                ...into.value,
                userExpIdEq: BigInt(value)
            })
            break
        case "userExpIdNeq":
            into.setter({
                ...into.value,
                userExpIdNeq: BigInt(value)
            })
            break
        case "userExpIdMin":
            into.setter({
                ...into.value,
                userExpIdMin: BigInt(value)
            })
            break
        case "userExpIdMax":
            into.setter({
                ...into.value,
                userExpIdMax: BigInt(value)
            })
            break
        case "userExpIdIsNull":
            into.setter({
                ...into.value,
                userExpIdIsNull: Boolean(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
