import {EnvPhoneBlockModificationDomain} from "../../domain/env/EnvPhoneBlockModificationDomain"

export const EnvPhoneBlockModificationDescriptor: Record<keyof EnvPhoneBlockModificationDomain, (o: EnvPhoneBlockModificationDomain) => string> = {
    id(o: EnvPhoneBlockModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvPhoneBlockModificationDomain): string {
        return o.creatorId.toString()
    },
    envPhoneBlockId(o: EnvPhoneBlockModificationDomain): string {
        return o.envPhoneBlockId.toString()
    },
    envCountryId(o: EnvPhoneBlockModificationDomain): string {
        return o.envCountryId.toString()
    },
    phone(o: EnvPhoneBlockModificationDomain): string {
        return o.phone
    },
    createdAt(o: EnvPhoneBlockModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
