import {UserBlockModificationDomain} from "../../domain/user/UserBlockModificationDomain"

/**
 * 사용자 이용 정지 수정 이력 속성 설명
 */
export const UserBlockModificationDescription: Record<keyof UserBlockModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    userBlockId: '사용자 이용 정지 ID',
    userBlockReasonId: '사용자 이용 정지 사유 ID',
    blockedAt: '이용 정지 시작일',
    blockedUntil: '이용 정지 종료일',
    createdAt: '수정일'
}
