import {UserAgreementVerboseDomain} from "../../domain/user/UserAgreementVerboseDomain"
import {EnvAgreementId} from "../../id/env/EnvAgreementId"
import {UserAgreementId} from "../../id/user/UserAgreementId"
import {UserId} from "../../id/user/UserId"
import {EnvAgreementValueObject} from "../env/EnvAgreementValueObject"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 이용 약관 동의 상세
 */
export class UserAgreementVerboseValueObject implements VerboseValueObject, UserAgreementVerboseDomain {
    /**
     * 기본키
     */
    id: UserAgreementId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 이용 약관 ID
     */
    envAgreementId: EnvAgreementId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 이용 약관 ID
     */
    envAgreement: EnvAgreementValueObject

    constructor(o: Record<keyof UserAgreementVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.envAgreementId = BigInt(o.envAgreementId)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.envAgreement = new EnvAgreementValueObject(o.envAgreement)
    }
}
