import {UserBlockModificationDomain} from "../../domain/user/UserBlockModificationDomain"

export const UserBlockModificationDescriptor: Record<keyof UserBlockModificationDomain, (o: UserBlockModificationDomain) => string> = {
    id(o: UserBlockModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UserBlockModificationDomain): string {
        return o.creatorId.toString()
    },
    userBlockId(o: UserBlockModificationDomain): string {
        return o.userBlockId.toString()
    },
    userBlockReasonId(o: UserBlockModificationDomain): string {
        return o.userBlockReasonId.toString()
    },
    blockedAt(o: UserBlockModificationDomain): string {
        return o.blockedAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    blockedUntil(o: UserBlockModificationDomain): string {
        return o.blockedUntil?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    createdAt(o: UserBlockModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
