import {EnvCountryModificationDomain} from "../../domain/env/EnvCountryModificationDomain"

/**
 * 국가 수정 이력 속성 설명
 */
export const EnvCountryModificationDescription: Record<keyof EnvCountryModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envCountryId: '국가 ID',
    name: '이름',
    isoCode: 'ISO 코드',
    phoneCode: '전화번호 코드',
    emoji: '이모지',
    createdAt: '수정일'
}
