import {RouterProps} from "../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Post from "./Post";
import Detail from "./Detail";
import TargetRouter from "./target/TargetRouter";
import CommentRouter from "./comment/CommentRouter";
import ParticipationRouter from "./participation/ParticipationRouter";
import PolicyRouter from "./policy/PolicyRouter";
import ReportRouter from "./report/ReportRouter";
import ReviewRouter from "./review/ParticipationRouter";
import QueryRouter from "./query/QueryRouter";
import DetailUrlRouter from "./detailUrl/DetailUrlRouter";
import ResultUrlRouter from "./resultUrl/ResultUrlRouter";
import Edit from "./Edit";
import UserProductionRouter from "./userProduction/UserProductionRouter";
import LikeRouter from "./like/LikeRouter";

export default function ContentRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/post'} element={<Post {...props} />} />
            <Route path={'/:contentId'} element={<Detail {...props} />} />
            <Route path={'/:contentId/edit'} element={<Edit {...props} />} />
            <Route path={'/comments/*'} element={<CommentRouter {...props} />} />
            <Route path={'/detailUrls/*'} element={<DetailUrlRouter {...props} />} />
            <Route path={'/likes/*'} element={<LikeRouter {...props} />} />
            <Route path={'/participations/*'} element={<ParticipationRouter {...props} />} />
            <Route path={'/policies/*'} element={<PolicyRouter {...props} />} />
            <Route path={'/queries/*'} element={<QueryRouter {...props} />} />
            <Route path={'/reports/*'} element={<ReportRouter {...props} />} />
            <Route path={'/resultUrls/*'} element={<ResultUrlRouter {...props} />} />
            <Route path={'/reviews/*'} element={<ReviewRouter {...props} />} />
            <Route path={'/targets/*'} element={<TargetRouter {...props} />} />
            <Route path={'/:contentId/targets/*'} element={<TargetRouter {...props} />} />
            <Route path={'/userProduction/*'} element={<UserProductionRouter {...props} />} />
        </Routes>
    </>
}