import {UserNotificationSubject} from "../../../data/constant/user/UserNotificationSubject"
import {UserNotificationPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/user/UserNotificationPostDataTransferObjectDescription"
import {UserNotificationPostState} from "../../../data/data-transfer-object-state/user/UserNotificationPostState"
import {UserNotificationPostDataTransferObject} from "../../../data/data-transfer-object/user/UserNotificationPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: UserNotificationPostState
}

export const UserNotificationPostField: Record<Capitalize<keyof UserNotificationPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    UserId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserNotificationPostDataTransferObjectDescription.userId}>
                <PostBigIntField
                    {...props}
                    value={props.state.userId.value}
                    setter={props.state.userId.setter} />
            </PostFieldWrapper>
        )
    },
    Title(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserNotificationPostDataTransferObjectDescription.title}>
                <PostTextareaField
                    {...props}
                    value={props.state.title.value}
                    setter={props.state.title.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    Body(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserNotificationPostDataTransferObjectDescription.body}>
                <PostTextareaField
                    {...props}
                    value={props.state.body.value}
                    setter={props.state.body.setter}
                    maxLength={2000} />
            </PostFieldWrapper>
        )
    },
    Subject(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserNotificationPostDataTransferObjectDescription.subject}>
                <PostEnumField
                    {...props}
                    value={props.state.subject.value}
                    setter={props.state.subject.setter}
                    selections={UserNotificationSubject.selections} />
            </PostFieldWrapper>
        )
    },
    DeepLink(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserNotificationPostDataTransferObjectDescription.deepLink}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.deepLink.value === undefined}
                onUnusedCheckboxChange={checked => props.state.deepLink.setter(checked ? undefined : '')}>
                <PostTextareaField
                    {...props}
                    value={props.state.deepLink.value}
                    setter={props.state.deepLink.setter}
                    maxLength={2000}
                    disabled={props.state.deepLink.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Push(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserNotificationPostDataTransferObjectDescription.push}>
                <PostBooleanField
                    {...props}
                    value={props.state.push.value}
                    setter={props.state.push.setter} />
            </PostFieldWrapper>
        )
    },
}

export function UserNotificationPostFields(props: PostFieldProps) {
    return <>
        <UserNotificationPostField.UserId
            {...props}
            state={props.state} />
        <UserNotificationPostField.Title
            {...props}
            state={props.state} />
        <UserNotificationPostField.Body
            {...props}
            state={props.state} />
        <UserNotificationPostField.Subject
            {...props}
            state={props.state} />
        <UserNotificationPostField.DeepLink
            {...props}
            state={props.state} />
        <UserNotificationPostField.Push
            {...props}
            state={props.state} />
    </>
}
