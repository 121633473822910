import {AssetStarChargeDomain} from "../../domain/asset/AssetStarChargeDomain"

/**
 * 스타 충전 속성 설명
 */
export const AssetStarChargeDescription: Record<keyof AssetStarChargeDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    scheduledAt: '충전 예정 시각',
    createdAt: '생성일'
}
