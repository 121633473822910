import ListOption from "../../ListOption";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentReportValueObject} from "../../../data/value-object/content/ContentReportValueObject";
import {ContentReportSearchOption} from "../../../data/search-option/content/ContentReportSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {ContentReportIO} from "../../../io/ContentIO";
import {ContentReportDescription} from "../../../data/description/content/ContentReportDescription";
import {ContentReportDescriptor} from "../../../data/descriptor/content/ContentReportDescriptor";
import {
    ContentReportSearchOptionDescription
} from "../../../data/description/content/ContentReportSearchOptionDescription";
import {
    applyContentReportSearchOption,
    ContentReportSearchOptionField
} from "../../search-option-field/content/ContentReportSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentReportValueObject[]>([])
    const option = useStatePair<ContentReportSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentReportIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentReportDescription}
                descriptor={ContentReportDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/reports/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentReportSearchOptionDescription}
                fields={ContentReportSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentReportSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentReportDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}