import {UserRankDuration} from "../../../data/constant/user/UserRankDuration"
import {EnvPolicyPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvPolicyPutDataTransferObjectDescription"
import {EnvPolicyPutState} from "../../../data/data-transfer-object-state/env/EnvPolicyPutState"
import {EnvPolicyPutDataTransferObject} from "../../../data/data-transfer-object/env/EnvPolicyPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvPolicyPutState
}

export const EnvPolicyPutField: Record<Capitalize<keyof EnvPolicyPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    EnvAdminPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envAdminPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envAdminPageSize.value}
                    setter={props.state.envAdminPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvAgreementPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envAgreementPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envAgreementPageSize.value}
                    setter={props.state.envAgreementPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvBankPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envBankPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envBankPageSize.value}
                    setter={props.state.envBankPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvClientPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envClientPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envClientPageSize.value}
                    setter={props.state.envClientPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvConstructionPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envConstructionPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envConstructionPageSize.value}
                    setter={props.state.envConstructionPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvCountryPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envCountryPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envCountryPageSize.value}
                    setter={props.state.envCountryPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvCountryRankDuration(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envCountryRankDuration}>
                <PostEnumField
                    {...props}
                    value={props.state.envCountryRankDuration.value}
                    setter={props.state.envCountryRankDuration.setter}
                    selections={UserRankDuration.selections} />
            </PostFieldWrapper>
        )
    },
    EnvEventPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envEventPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envEventPageSize.value}
                    setter={props.state.envEventPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvFaqPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envFaqPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envFaqPageSize.value}
                    setter={props.state.envFaqPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvFaqItemPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envFaqItemPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envFaqItemPageSize.value}
                    setter={props.state.envFaqItemPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvNoticePageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envNoticePageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envNoticePageSize.value}
                    setter={props.state.envNoticePageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvPhoneBlockPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envPhoneBlockPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envPhoneBlockPageSize.value}
                    setter={props.state.envPhoneBlockPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvPolicyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envPolicyPageSize.value}
                    setter={props.state.envPolicyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
    EnvStorageKeyPageSize(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvPolicyPutDataTransferObjectDescription.envStorageKeyPageSize}>
                <PostNumberField
                    {...props}
                    value={props.state.envStorageKeyPageSize.value}
                    setter={props.state.envStorageKeyPageSize.setter} />
            </PostFieldWrapper>
        )
    },
}

export function EnvPolicyPutFields(props: PostFieldProps) {
    return <>
        <EnvPolicyPutField.EnvAdminPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvAgreementPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvBankPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvClientPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvConstructionPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvCountryPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvCountryRankDuration
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvEventPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvFaqPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvFaqItemPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvNoticePageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvPhoneBlockPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvPolicyPageSize
            {...props}
            state={props.state} />
        <EnvPolicyPutField.EnvStorageKeyPageSize
            {...props}
            state={props.state} />
    </>
}
