import {Route, Routes} from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import {RouterProps} from "../../../RouterProps";
import PreemptionRouter from "./preemption/PreemptionRouter";

export default function PopularRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/:contentQueryPopularId'} element={<Detail {...props} />} />
            <Route path={'/preemptions/*'} element={<PreemptionRouter {...props} />} />
        </Routes>
    </>
}