import {UserLevelSearchOptionDescription} from "../../../data/description/user/UserLevelSearchOptionDescription"
import {UserLevelSearchOption} from "../../../data/search-option/user/UserLevelSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function UserLevelSearchOptionField(props: ListOptionFieldProps<UserLevelSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.nameEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.nameNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.nameMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.nameMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.nameLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "nameNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.nameNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.expEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.expNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.expMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "expMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.expMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetLunarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetLunarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetLunarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetLunarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetLunarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetLunarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetLunarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetLunarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetSolarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetSolarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetSolarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetSolarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetSolarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetSolarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetSolarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetSolarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetStarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetStarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetStarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetStarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetStarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetStarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "rewardAssetStarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.rewardAssetStarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserLevelSearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserLevelSearchOption(key: keyof UserLevelSearchOption, value: string, into: StatePair<UserLevelSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "nameEq":
            into.setter({
                ...into.value,
                nameEq: String(value)
            })
            break
        case "nameNeq":
            into.setter({
                ...into.value,
                nameNeq: String(value)
            })
            break
        case "nameMin":
            into.setter({
                ...into.value,
                nameMin: String(value)
            })
            break
        case "nameMax":
            into.setter({
                ...into.value,
                nameMax: String(value)
            })
            break
        case "nameLike":
            into.setter({
                ...into.value,
                nameLike: String(value)
            })
            break
        case "nameNotLike":
            into.setter({
                ...into.value,
                nameNotLike: String(value)
            })
            break
        case "expEq":
            into.setter({
                ...into.value,
                expEq: Number(value)
            })
            break
        case "expNeq":
            into.setter({
                ...into.value,
                expNeq: Number(value)
            })
            break
        case "expMin":
            into.setter({
                ...into.value,
                expMin: Number(value)
            })
            break
        case "expMax":
            into.setter({
                ...into.value,
                expMax: Number(value)
            })
            break
        case "rewardAssetLunarAmountEq":
            into.setter({
                ...into.value,
                rewardAssetLunarAmountEq: Number(value)
            })
            break
        case "rewardAssetLunarAmountNeq":
            into.setter({
                ...into.value,
                rewardAssetLunarAmountNeq: Number(value)
            })
            break
        case "rewardAssetLunarAmountMin":
            into.setter({
                ...into.value,
                rewardAssetLunarAmountMin: Number(value)
            })
            break
        case "rewardAssetLunarAmountMax":
            into.setter({
                ...into.value,
                rewardAssetLunarAmountMax: Number(value)
            })
            break
        case "rewardAssetSolarAmountEq":
            into.setter({
                ...into.value,
                rewardAssetSolarAmountEq: Number(value)
            })
            break
        case "rewardAssetSolarAmountNeq":
            into.setter({
                ...into.value,
                rewardAssetSolarAmountNeq: Number(value)
            })
            break
        case "rewardAssetSolarAmountMin":
            into.setter({
                ...into.value,
                rewardAssetSolarAmountMin: Number(value)
            })
            break
        case "rewardAssetSolarAmountMax":
            into.setter({
                ...into.value,
                rewardAssetSolarAmountMax: Number(value)
            })
            break
        case "rewardAssetStarAmountEq":
            into.setter({
                ...into.value,
                rewardAssetStarAmountEq: Number(value)
            })
            break
        case "rewardAssetStarAmountNeq":
            into.setter({
                ...into.value,
                rewardAssetStarAmountNeq: Number(value)
            })
            break
        case "rewardAssetStarAmountMin":
            into.setter({
                ...into.value,
                rewardAssetStarAmountMin: Number(value)
            })
            break
        case "rewardAssetStarAmountMax":
            into.setter({
                ...into.value,
                rewardAssetStarAmountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
