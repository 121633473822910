export enum ContentParticipationMethod {
    /**
     * 타이핑형
     */
    Typing = 'Typing',

    /**
     * 음성형
     */
    Voice = 'Voice'
}

export namespace ContentParticipationMethod {
    export function description(o: ContentParticipationMethod) {
        switch (o) {
            case ContentParticipationMethod.Typing: return '타이핑형'
            case ContentParticipationMethod.Voice: return '음성형'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: ContentParticipationMethod.Typing, label: description(ContentParticipationMethod.Typing) },
        { value: ContentParticipationMethod.Voice, label: description(ContentParticipationMethod.Voice) }
    ]
}
