import {UiHomeContentUserProductionModificationDomain} from "../../domain/ui/UiHomeContentUserProductionModificationDomain"

export const UiHomeContentUserProductionModificationDescriptor: Record<keyof UiHomeContentUserProductionModificationDomain, (o: UiHomeContentUserProductionModificationDomain) => string> = {
    id(o: UiHomeContentUserProductionModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiHomeContentUserProductionModificationDomain): string {
        return o.creatorId.toString()
    },
    uiHomeContentUserProductionId(o: UiHomeContentUserProductionModificationDomain): string {
        return o.uiHomeContentUserProductionId.toString()
    },
    displayOrder(o: UiHomeContentUserProductionModificationDomain): string {
        return o.displayOrder.toString()
    },
    url(o: UiHomeContentUserProductionModificationDomain): string {
        return o.url
    },
    createdAt(o: UiHomeContentUserProductionModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
