import {RouterProps} from "../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {
    UserAgreementIO,
    UserBlockIO,
    UserIO,
    UserModificationIO,
    UserNotificationIO,
    UserSignInIO, UserWithdrawalIO
} from "../../io/UserIO";
import {Body1, Button, Caption1Strong, Spinner, Subtitle2, Tab, TabList} from "@fluentui/react-components";
import {DeleteDialog} from "../DetailViews";
import {UserDomain} from "../../data/domain/user/UserDomain";
import {UserDescriptor} from "../../data/descriptor/user/UserDescriptor";
import {mapByKey} from "../../util/ObjectUtil";
import {UserDescription} from "../../data/description/user/UserDescription";
import {UserModificationValueObject} from "../../data/value-object/user/UserModificationValueObject";
import {UserModificationSearchOption} from "../../data/search-option/user/UserModificationSearchOption";
import {searchIndex, SearchIndex} from "../../io/HttpClient";
import ListPageWrapper from "../ListPageWrapper";
import {UserModificationDescription} from "../../data/description/user/UserModificationDescription";
import {UserModificationDescriptor} from "../../data/descriptor/user/UserModificationDescriptor";
import {AssetLunarUsageValueObject} from "../../data/value-object/asset/AssetLunarUsageValueObject";
import {AssetLunarUsageSearchOption} from "../../data/search-option/asset/AssetLunarUsageSearchOption";
import {AssetLunarUsageIO, AssetSolarUsageIO, AssetStarUsageIO} from "../../io/AssetIO";
import {AssetLunarUsageDescription} from "../../data/description/asset/AssetLunarUsageDescription";
import {AssetLunarUsageDescriptor} from "../../data/descriptor/asset/AssetLunarUsageDescriptor";
import {AssetSolarUsageValueObject} from "../../data/value-object/asset/AssetSolarUsageValueObject";
import {AssetSolarUsageSearchOption} from "../../data/search-option/asset/AssetSolarUsageSearchOption";
import {AssetSolarUsageDescription} from "../../data/description/asset/AssetSolarUsageDescription";
import {AssetSolarUsageDescriptor} from "../../data/descriptor/asset/AssetSolarUsageDescriptor";
import {AssetStarUsageValueObject} from "../../data/value-object/asset/AssetStarUsageValueObject";
import {AssetStarUsageSearchOption} from "../../data/search-option/asset/AssetStarUsageSearchOption";
import {AssetStarUsageDescription} from "../../data/description/asset/AssetStarUsageDescription";
import {AssetStarUsageDescriptor} from "../../data/descriptor/asset/AssetStarUsageDescriptor";
import {ContentCommentValueObject} from "../../data/value-object/content/ContentCommentValueObject";
import {ContentCommentSearchOption} from "../../data/search-option/content/ContentCommentSearchOption";
import {
    ContentCommentIO,
    ContentCommentLikeIO,
    ContentCommentReportIO, ContentDetailUrlClickIO,
    ContentParticipationIO, ContentQueryIO, ContentReportIO, ContentResultUrlClickIO, ContentReviewIO
} from "../../io/ContentIO";
import {ContentCommentDescription} from "../../data/description/content/ContentCommentDescription";
import {ContentCommentDescriptor} from "../../data/descriptor/content/ContentCommentDescriptor";
import {ContentCommentLikeValueObject} from "../../data/value-object/content/ContentCommentLikeValueObject";
import {ContentCommentLikeSearchOption} from "../../data/search-option/content/ContentCommentLikeSearchOption";
import {ContentCommentLikeDescription} from "../../data/description/content/ContentCommentLikeDescription";
import {ContentCommentLikeDescriptor} from "../../data/descriptor/content/ContentCommentLikeDescriptor";
import {ContentCommentReportValueObject} from "../../data/value-object/content/ContentCommentReportValueObject";
import {ContentCommentReportSearchOption} from "../../data/search-option/content/ContentCommentReportSearchOption";
import {ContentCommentReportDescription} from "../../data/description/content/ContentCommentReportDescription";
import {ContentCommentReportDescriptor} from "../../data/descriptor/content/ContentCommentReportDescriptor";
import {ContentParticipationValueObject} from "../../data/value-object/content/ContentParticipationValueObject";
import {ContentParticipationSearchOption} from "../../data/search-option/content/ContentParticipationSearchOption";
import {ContentParticipationDescription} from "../../data/description/content/ContentParticipationDescription";
import {ContentParticipationDescriptor} from "../../data/descriptor/content/ContentParticipationDescriptor";
import {ContentQueryValueObject} from "../../data/value-object/content/ContentQueryValueObject";
import {ContentQuerySearchOption} from "../../data/search-option/content/ContentQuerySearchOption";
import {ContentQueryDescription} from "../../data/description/content/ContentQueryDescription";
import {ContentQueryDescriptor} from "../../data/descriptor/content/ContentQueryDescriptor";
import {ContentReportValueObject} from "../../data/value-object/content/ContentReportValueObject";
import {ContentReportSearchOption} from "../../data/search-option/content/ContentReportSearchOption";
import {ContentReportDescription} from "../../data/description/content/ContentReportDescription";
import {ContentReportDescriptor} from "../../data/descriptor/content/ContentReportDescriptor";
import {ContentReviewValueObject} from "../../data/value-object/content/ContentReviewValueObject";
import {ContentReviewSearchOption} from "../../data/search-option/content/ContentReviewSearchOption";
import {ContentReviewDescription} from "../../data/description/content/ContentReviewDescription";
import {ContentReviewDescriptor} from "../../data/descriptor/content/ContentReviewDescriptor";
import {UserAgreementValueObject} from "../../data/value-object/user/UserAgreementValueObject";
import {UserAgreementSearchOption} from "../../data/search-option/user/UserAgreementSearchOption";
import {UserAgreementDescription} from "../../data/description/user/UserAgreementDescription";
import {UserAgreementDescriptor} from "../../data/descriptor/user/UserAgreementDescriptor";
import {UserBlockValueObject} from "../../data/value-object/user/UserBlockValueObject";
import {UserBlockSearchOption} from "../../data/search-option/user/UserBlockSearchOption";
import {UserBlockDescription} from "../../data/description/user/UserBlockDescription";
import {UserBlockDescriptor} from "../../data/descriptor/user/UserBlockDescriptor";
import {UserNotificationValueObject} from "../../data/value-object/user/UserNotificationValueObject";
import {UserNotificationSearchOption} from "../../data/search-option/user/UserNotificationSearchOption";
import {UserNotificationDescription} from "../../data/description/user/UserNotificationDescription";
import {UserNotificationDescriptor} from "../../data/descriptor/user/UserNotificationDescriptor";
import {UserSignInValueObject} from "../../data/value-object/user/UserSignInValueObject";
import {UserSignInSearchOption} from "../../data/search-option/user/UserSignInSearchOption";
import {UserSignInDescription} from "../../data/description/user/UserSignInDescription";
import {UserSignInDescriptor} from "../../data/descriptor/user/UserSignInDescriptor";
import {UserWithdrawalValueObject} from "../../data/value-object/user/UserWithdrawalValueObject";
import {UserWithdrawalDescription} from "../../data/description/user/UserWithdrawalDescription";
import {UserPermission} from "../../data/constant/user/UserPermission";
import {UserValueObject} from "../../data/value-object/user/UserValueObject";
import {ContentDetailUrlClickValueObject} from "../../data/value-object/content/ContentDetailUrlClickValueObject";
import {ContentDetailUrlClickSearchOption} from "../../data/search-option/content/ContentDetailUrlClickSearchOption";
import {ContentDetailUrlClickDescription} from "../../data/description/content/ContentDetailUrlClickDescription";
import {ContentDetailUrlClickDescriptor} from "../../data/descriptor/content/ContentDetailUrlClickDescriptor";
import {ContentResultUrlClickValueObject} from "../../data/value-object/content/ContentResultUrlClickValueObject";
import {ContentResultUrlClickSearchOption} from "../../data/search-option/content/ContentResultUrlClickSearchOption";
import {ContentResultUrlClickDescription} from "../../data/description/content/ContentResultUrlClickDescription";
import {ContentResultUrlClickDescriptor} from "../../data/descriptor/content/ContentResultUrlClickDescriptor";
import {UserWithdrawalPropertyBody} from "./withdrawal/Detail";

enum Panel {
    Default,
    UserModificationList,
    AssetLunarUsageList,
    AssetSolarUsageList,
    AssetStarUsageList,
    ContentCommentList,
    ContentCommentLikeList,
    ContentCommentReportList,
    ContentDetailUrlClickList,
    ContentParticipationList,
    ContentQueryList,
    ContentReportList,
    ContentResultUrlClickList,
    ContentReviewList,
    UserAgreementList,
    UserBlockList,
    UserNotificationList,
    UserSignInList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userId: string }>()
    const item = useStatePair<UserValueObject | undefined>(undefined)
    const userWithdrawal = useStatePair<UserWithdrawalValueObject | false | undefined>(undefined)

    useEffect(() => {
        if (params.userId !== undefined) {
            UserIO
                .getById(BigInt(params.userId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    useEffect(() => {
        if (params.userId !== undefined) {
            UserWithdrawalIO
                .getByUserId(BigInt(params.userId))
                .then(userWithdrawal.setter)
                .catch(reason => {
                    console.error(reason)
                    userWithdrawal.setter(false)
                })
        }
    }, []);

    const pageProps: PageProps | undefined = (item.value === undefined || userWithdrawal.value === undefined)
        ? undefined
        : { ...props, item: item.value, userWithdrawal: userWithdrawal.value }

    return <>
        {pageProps === undefined && <Spinner />}
        {pageProps !== undefined && <Page {...pageProps} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserValueObject
    userWithdrawal: UserWithdrawalValueObject | false
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    const onDeleteClick = () => {
        isDeleting.setTrue()
        UserIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/users'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserModificationList}>수정 이력</Tab>
                <Tab value={Panel.AssetLunarUsageList}>루나 사용 목록</Tab>
                <Tab value={Panel.AssetSolarUsageList}>솔라 사용 목록</Tab>
                <Tab value={Panel.AssetStarUsageList}>스타 사용 목록</Tab>
                <Tab value={Panel.ContentCommentList}>콘텐츠 댓글 목록</Tab>
                <Tab value={Panel.ContentCommentLikeList}>콘텐츠 댓글 좋아요 목록</Tab>
                <Tab value={Panel.ContentCommentReportList}>콘텐츠 댓글 신고 목록</Tab>
                <Tab value={Panel.ContentDetailUrlClickList}>콘텐츠 더 알아보기 URL 클릭 목록</Tab>
                <Tab value={Panel.ContentParticipationList}>콘텐츠 참여 목록</Tab>
                <Tab value={Panel.ContentQueryList}>콘텐츠 검색 목록</Tab>
                <Tab value={Panel.ContentReportList}>콘텐츠 신고 목록</Tab>
                <Tab value={Panel.ContentResultUrlClickList}>콘텐츠 참여 결과 URL 클릭 목록</Tab>
                <Tab value={Panel.ContentReviewList}>콘텐츠 평가 목록</Tab>
                <Tab value={Panel.UserAgreementList}>사용자 이용 약관 동의 목록</Tab>
                <Tab value={Panel.UserBlockList}>사용자 이용 정지 목록</Tab>
                <Tab value={Panel.UserNotificationList}>사용자 알림 목록</Tab>
                <Tab value={Panel.UserSignInList}>사용자 로그인 목록</Tab>
            </TabList>
            <DefaultPanel
                {...props}
                panel={panel.value}>
                <div className={props.styles.row8}>
                    <Button onClick={() => document.location = `/users/${props.item.id}/edit`}>수정</Button>
                    {props.user.id !== props.item.id && (props.user.adminPermission & UserPermission.WriteUserRole.value) !== 0 && <>
                        <Button onClick={() => document.location = `/users/${props.item.id}/edit/role`}>역할 수정</Button>
                    </>}
                    {props.user.id !== props.item.id && (props.user.adminPermission & UserPermission.WriteUserAdminPermission.value) !== 0 && <>
                        <Button onClick={() => document.location = `/users/${props.item.id}/edit/adminPermission`}>권한 수정</Button>
                    </>}
                </div>
            </DefaultPanel>
            <UserModificationListPanel
                {...props}
                panel={panel.value}/>
            <AssetLunarUsageListPanel
                {...props}
                panel={panel.value}/>
            <AssetSolarUsageListPanel
                {...props}
                panel={panel.value}/>
            <AssetStarUsageListPanel
                {...props}
                panel={panel.value}/>
            <ContentCommentListPanel
                {...props}
                panel={panel.value}/>
            <ContentCommentLikeListPanel
                {...props}
                panel={panel.value}/>
            <ContentCommentReportListPanel
                {...props}
                panel={panel.value}/>
            <ContentDetailUrlClickListPanel
                {...props}
                panel={panel.value}/>
            <ContentParticipationListPanel
                {...props}
                panel={panel.value}/>
            <ContentQueryListPanel
                {...props}
                panel={panel.value}/>
            <ContentReportListPanel
                {...props}
                panel={panel.value}/>
            <ContentResultUrlClickListPanel
                {...props}
                panel={panel.value}/>
            <ContentReviewListPanel
                {...props}
                panel={panel.value}/>
            <UserAgreementListPanel
                {...props}
                panel={panel.value}/>
            <UserBlockListPanel
                {...props}
                panel={panel.value}/>
            <UserNotificationListPanel
                {...props}
                panel={panel.value}/>
            <UserSignInListPanel
                {...props}
                panel={panel.value}/>
        </div>
        <DeleteDialog
            {...props}
            visible={isDeleteDialogVisible.value}
            disabled={isDeleting.value}
            onConfirmClick={onDeleteClick}
            onCancelClick={() => isDeleteDialogVisible.setFalse()}/>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "contentRepeatLikeCount":
        case "contentM10nLikeCount":
        case "contentUserProductionLikeCount":
        case "contentUserProductionReviewPositiveCount":
        case "contentUserProductionReviewNeutralCount":
        case "contentUserProductionReviewNegativeCount":
        case "id":
        case "authenticationUid":
        case "role":
        case "adminPermission":
        case "email":
        case "cloudMessagingToken":
        case "nickname":
        case "birth":
        case "gender":
        case "phone":
        case "recommendationCode":
        case "statusFlags":
        case "recommendeeCount":
        case "contentDailyParticipationCount":
        case "contentDailyParticipationPassCount":
        case "contentRepeatParticipationCount":
        case "contentRepeatParticipationPassCount":
        case "contentRepeatParticipationHighAccuracyCount":
        case "contentRepeatCommentCount":
        case "contentRepeatCommentLikeCount":
        case "contentRepeatCommentReportCount":
        case "contentRepeatCommentReportedCount":
        case "contentM10nParticipationCount":
        case "contentM10nParticipationPassCount":
        case "contentM10nParticipationHighAccuracyCount":
        case "contentM10nCommentCount":
        case "contentM10nCommentLikeCount":
        case "contentM10nCommentReportCount":
        case "contentM10nCommentReportedCount":
        case "contentUserProductionCount":
        case "contentUserProductionParticipationCount":
        case "contentUserProductionParticipationPassCount":
        case "contentUserProductionParticipationHighAccuracyCount":
        case "contentUserProductionReportCount":
        case "contentUserProductionReportedCount":
        case "contentUserProductionCommentCount":
        case "contentUserProductionCommentLikeCount":
        case "contentUserProductionCommentReportCount":
        case "contentUserProductionCommentReportedCount":
        case "userBlockCount":
        case "createdAt":
        case "lastModifiedAt":
            return <Body1>{UserDescriptor[props.itemKey](props.item)}</Body1>
        case "birthCountryId":
            return <a href={`/env/countries/${props.item.birthCountryId}`}><Body1>{UserDescriptor[props.itemKey](props.item)}</Body1></a>
        case "residenceCountryId":
            return <a href={`/env/countries/${props.item.residenceCountryId}`}><Body1>{UserDescriptor[props.itemKey](props.item)}</Body1></a>
        case "phoneCountryId":
            return <a href={`/env/countries/${props.item.phoneCountryId}`}><Body1>{UserDescriptor[props.itemKey](props.item)}</Body1></a>
        case "recommenderId":
            return props.item.recommenderId !== undefined
                ? <a href={`/users/${props.item.recommenderId}`}><Body1>{UserDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>

    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(UserDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{UserDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                <UserWithdrawalProperties {...props} />
                {props.children}
            </div>
        </>}
    </>
}

function UserWithdrawalProperties(props: PanelProps) {
    const userWithdrawal = props.userWithdrawal
    if (userWithdrawal === false) {
        return <></>
    }

    return <>
        <Subtitle2>사용자 탈퇴</Subtitle2>
        {mapByKey(UserWithdrawalDescription, key => <>
            {props.userWithdrawal !== false && <div className={props.styles.column4}>
                <Caption1Strong>{UserWithdrawalDescription[key]}</Caption1Strong>
                <UserWithdrawalPropertyBody
                    {...props}
                    item={props.userWithdrawal}
                    itemKey={key}/>
            </div>}
        </>)}
    </>
}

function UserModificationListPanel(props: PanelProps) {
    const items = useStatePair<UserModificationValueObject[]>([])
    const option: UserModificationSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserModificationList && <>
            <ListPageWrapper
                {...props}
                client={UserModificationIO}
                items={items}
                option={option}
                index={index}
                description={UserModificationDescription}
                descriptor={UserModificationDescriptor}
                showNewTab={false} />
        </>}
    </>
}

function AssetLunarUsageListPanel(props: PanelProps) {
    const items = useStatePair<AssetLunarUsageValueObject[]>([])
    const option: AssetLunarUsageSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.AssetLunarUsageList && <>
            <ListPageWrapper
                {...props}
                client={AssetLunarUsageIO}
                items={items}
                option={option}
                index={index}
                description={AssetLunarUsageDescription}
                descriptor={AssetLunarUsageDescriptor} />
        </>}
    </>
}

function AssetSolarUsageListPanel(props: PanelProps) {
    const items = useStatePair<AssetSolarUsageValueObject[]>([])
    const option: AssetSolarUsageSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.AssetSolarUsageList && <>
            <ListPageWrapper
                {...props}
                client={AssetSolarUsageIO}
                items={items}
                option={option}
                index={index}
                description={AssetSolarUsageDescription}
                descriptor={AssetSolarUsageDescriptor} />
        </>}
    </>
}

function AssetStarUsageListPanel(props: PanelProps) {
    const items = useStatePair<AssetStarUsageValueObject[]>([])
    const option: AssetStarUsageSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.AssetStarUsageList && <>
            <ListPageWrapper
                {...props}
                client={AssetStarUsageIO}
                items={items}
                option={option}
                index={index}
                description={AssetStarUsageDescription}
                descriptor={AssetStarUsageDescriptor} />
        </>}
    </>
}

function ContentCommentListPanel(props: PanelProps) {
    const items = useStatePair<ContentCommentValueObject[]>([])
    const option: ContentCommentSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentCommentList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentIO}
                items={items}
                option={option}
                index={index}
                description={ContentCommentDescription}
                descriptor={ContentCommentDescriptor} />
        </>}
    </>
}

function ContentCommentLikeListPanel(props: PanelProps) {
    const items = useStatePair<ContentCommentLikeValueObject[]>([])
    const option: ContentCommentLikeSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentCommentLikeList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentLikeIO}
                items={items}
                option={option}
                index={index}
                description={ContentCommentLikeDescription}
                descriptor={ContentCommentLikeDescriptor} />
        </>}
    </>
}

function ContentCommentReportListPanel(props: PanelProps) {
    const items = useStatePair<ContentCommentReportValueObject[]>([])
    const option: ContentCommentReportSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentCommentReportList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentReportIO}
                items={items}
                option={option}
                index={index}
                description={ContentCommentReportDescription}
                descriptor={ContentCommentReportDescriptor} />
        </>}
    </>
}

function ContentDetailUrlClickListPanel(props: PanelProps) {
    const items = useStatePair<ContentDetailUrlClickValueObject[]>([])
    const option: ContentDetailUrlClickSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentDetailUrlClickList && <>
            <ListPageWrapper
                {...props}
                client={ContentDetailUrlClickIO}
                items={items}
                option={option}
                index={index}
                description={ContentDetailUrlClickDescription}
                descriptor={ContentDetailUrlClickDescriptor} />
        </>}
    </>
}

function ContentParticipationListPanel(props: PanelProps) {
    const items = useStatePair<ContentParticipationValueObject[]>([])
    const option: ContentParticipationSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentParticipationList && <>
            <ListPageWrapper
                {...props}
                client={ContentParticipationIO}
                items={items}
                option={option}
                index={index}
                description={ContentParticipationDescription}
                descriptor={ContentParticipationDescriptor} />
        </>}
    </>
}

function ContentQueryListPanel(props: PanelProps) {
    const items = useStatePair<ContentQueryValueObject[]>([])
    const option: ContentQuerySearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentQueryList && <>
            <ListPageWrapper
                {...props}
                client={ContentQueryIO}
                items={items}
                option={option}
                index={index}
                description={ContentQueryDescription}
                descriptor={ContentQueryDescriptor} />
        </>}
    </>
}

function ContentReportListPanel(props: PanelProps) {
    const items = useStatePair<ContentReportValueObject[]>([])
    const option: ContentReportSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentReportList && <>
            <ListPageWrapper
                {...props}
                client={ContentReportIO}
                items={items}
                option={option}
                index={index}
                description={ContentReportDescription}
                descriptor={ContentReportDescriptor} />
        </>}
    </>
}

function ContentResultUrlClickListPanel(props: PanelProps) {
    const items = useStatePair<ContentResultUrlClickValueObject[]>([])
    const option: ContentResultUrlClickSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentResultUrlClickList && <>
            <ListPageWrapper
                {...props}
                client={ContentResultUrlClickIO}
                items={items}
                option={option}
                index={index}
                description={ContentResultUrlClickDescription}
                descriptor={ContentResultUrlClickDescriptor} />
        </>}
    </>
}

function ContentReviewListPanel(props: PanelProps) {
    const items = useStatePair<ContentReviewValueObject[]>([])
    const option: ContentReviewSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.ContentReviewList && <>
            <ListPageWrapper
                {...props}
                client={ContentReviewIO}
                items={items}
                option={option}
                index={index}
                description={ContentReviewDescription}
                descriptor={ContentReviewDescriptor} />
        </>}
    </>
}

function UserAgreementListPanel(props: PanelProps) {
    const items = useStatePair<UserAgreementValueObject[]>([])
    const option: UserAgreementSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserAgreementList && <>
            <ListPageWrapper
                {...props}
                client={UserAgreementIO}
                items={items}
                option={option}
                index={index}
                description={UserAgreementDescription}
                descriptor={UserAgreementDescriptor} />
        </>}
    </>
}

function UserBlockListPanel(props: PanelProps) {
    const items = useStatePair<UserBlockValueObject[]>([])
    const option: UserBlockSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserBlockList && <>
            <ListPageWrapper
                {...props}
                client={UserBlockIO}
                items={items}
                option={option}
                index={index}
                description={UserBlockDescription}
                descriptor={UserBlockDescriptor} />
        </>}
    </>
}

function UserNotificationListPanel(props: PanelProps) {
    const items = useStatePair<UserNotificationValueObject[]>([])
    const option: UserNotificationSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserNotificationList && <>
            <ListPageWrapper
                {...props}
                client={UserNotificationIO}
                items={items}
                option={option}
                index={index}
                description={UserNotificationDescription}
                descriptor={UserNotificationDescriptor} />
        </>}
    </>
}

function UserSignInListPanel(props: PanelProps) {
    const items = useStatePair<UserSignInValueObject[]>([])
    const option: UserSignInSearchOption = { userIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserSignInList && <>
            <ListPageWrapper
                {...props}
                client={UserSignInIO}
                items={items}
                option={option}
                index={index}
                description={UserSignInDescription}
                descriptor={UserSignInDescriptor} />
        </>}
    </>
}