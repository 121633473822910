import {UserSessionDomain} from "../../domain/user/UserSessionDomain"
import {UserSessionId} from "../../id/user/UserSessionId"
import {UserSignInId} from "../../id/user/UserSignInId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 세션
 */
export class UserSessionValueObject implements ValueObject, UserSessionDomain {
    /**
     * 기본키
     */
    id: UserSessionId
    /**
     * 사용자 로그인 ID
     */
    userSignInId?: UserSignInId
    /**
     * 쿠키에 포함되는 ID
     */
    outbound: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date

    constructor(o: Record<keyof UserSessionDomain, any>) {
        this.id = BigInt(o.id)
        if (o.userSignInId !== undefined && o.userSignInId !== null) {
            this.userSignInId = BigInt(o.userSignInId)
        }
        this.outbound = String(o.outbound)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
    }
}
