import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Post from "./Post";
import Detail from "./Detail";
import Edit from "./Edit";
import UsageRouter from "./usage/UsageRouter";

export default function CouponRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/post'} element={<Post {...props} />} />
            <Route path={'/:assetCouponId'} element={<Detail {...props} />} />
            <Route path={'/:assetCouponId/edit'} element={<Edit {...props} />} />
            <Route path={'/usages/*'} element={<UsageRouter {...props} />} />
        </Routes>
    </>
}