import {RouterProps} from "../../../RouterProps";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {
    Button,
    Caption1,
    Dialog, DialogActions,
    DialogBody, DialogContent,
    DialogSurface,
    DialogTitle,
    Field,
    Input,
    Title3
} from "@fluentui/react-components";
import {UserBlockReasonIO} from "../../../../io/UserIO";

enum Phase {
    Waiting,
    Posting,
    Finished
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const body = useStatePair('')

    const onDoneClick = () => {
        phase.setter(Phase.Posting)
        UserBlockReasonIO
            .post({ body: body.value })
            .then(() => {
                phase.setter(Phase.Finished)
            })
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }

    return <>
        <div className={props.styles.column16}>
            <Title3>새 항목</Title3>
            <div className={props.styles.row}>
                <div className={props.styles.column8}>
                    <Caption1>사유</Caption1>
                    <Field>
                        <Input
                            value={body.value}
                            onChange={(_, data) => body.setter(data.value)}
                            type={'text'}
                            maxLength={200} />
                    </Field>
                </div>
            </div>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    onClick={onDoneClick}
                    disabled={phase.value !== Phase.Waiting || body.value.length === 0}>
                    완료
                </Button>
            </div>
        </div>
        <Dialog open={phase.value === Phase.Finished}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>완료</DialogTitle>
                    <DialogContent>새 항목이 추가되었습니다. 계속 추가할까요?</DialogContent>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => document.location.reload()}>예</Button>
                    <Button onClick={() => document.location = '/users/blocks/reasons'}>아니오</Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    </>
}