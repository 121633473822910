import {UserGender} from "../../constant/user/UserGender"
import {UserRole} from "../../constant/user/UserRole"
import {UserModificationDomain} from "../../domain/user/UserModificationDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {UserId} from "../../id/user/UserId"
import {UserModificationId} from "../../id/user/UserModificationId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 수정 이력
 */
export class UserModificationValueObject implements ValueObject, UserModificationDomain {
    /**
     * 기본키
     */
    id: UserModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 역할
     */
    role: UserRole
    /**
     * 관리자 권한
     */
    adminPermission: number
    /**
     * 이메일
     */
    email: string
    /**
     * Firebase Cloud Messaging Token
     */
    cloudMessagingToken?: string
    /**
     * 닉네임
     */
    nickname: string
    /**
     * 거주 국가 ID
     */
    residenceCountryId: EnvCountryId
    /**
     * 생년월일
     */
    birth: string
    /**
     * 성별
     */
    gender: UserGender
    /**
     * 전화번호 국가 ID
     */
    phoneCountryId?: EnvCountryId
    /**
     * 전화번호
     */
    phone?: string
    /**
     * 상태 플래그
     */
    statusFlags: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UserModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.userId = BigInt(o.userId)
        this.role = o.role as UserRole
        this.adminPermission = Number(o.adminPermission)
        this.email = String(o.email)
        if (o.cloudMessagingToken !== undefined && o.cloudMessagingToken !== null) {
            this.cloudMessagingToken = String(o.cloudMessagingToken)
        }
        this.nickname = String(o.nickname)
        this.residenceCountryId = BigInt(o.residenceCountryId)
        this.birth = String(o.birth)
        this.gender = o.gender as UserGender
        if (o.phoneCountryId !== undefined && o.phoneCountryId !== null) {
            this.phoneCountryId = BigInt(o.phoneCountryId)
        }
        if (o.phone !== undefined && o.phone !== null) {
            this.phone = String(o.phone)
        }
        this.statusFlags = Number(o.statusFlags)
        this.createdAt = new Date(o.createdAt)
    }
}
