import {UserBlockValueObject} from "../../../data/value-object/user/UserBlockValueObject";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {JSX, useEffect} from "react";
import {UserBlockIO, UserBlockModificationIO} from "../../../io/UserIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";
import {UserBlockDomain} from "../../../data/domain/user/UserBlockDomain";
import {UserBlockDescriptor} from "../../../data/descriptor/user/UserBlockDescriptor";
import {UserBlockDescription} from "../../../data/description/user/UserBlockDescription";
import {UserBlockModificationValueObject} from "../../../data/value-object/user/UserBlockModificationValueObject";
import {UserBlockModificationSearchOption} from "../../../data/search-option/user/UserBlockModificationSearchOption";
import {searchIndex, SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {UserBlockModificationDescription} from "../../../data/description/user/UserBlockModificationDescription";
import {UserBlockModificationDescriptor} from "../../../data/descriptor/user/UserBlockModificationDescriptor";

enum Panel {
    Default,
    UserBlockModificationList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userBlockId: string }>()
    const item = useStatePair<UserBlockValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userBlockId !== undefined) {
            UserBlockIO
                .getById(BigInt(params.userBlockId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserBlockValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserBlockModificationList}>수정 이력</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UserBlockIO}
                description={UserBlockDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
            <UserBlockModificationListPanel
                {...props}
                panel={panel.value}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserBlockDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "blockedAt":
        case "blockedUntil":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{UserBlockDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserBlockDescriptor[props.itemKey](props.item)}</Body1></a>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{UserBlockDescriptor[props.itemKey](props.item)}</Body1></a>
        case "userBlockReasonId":
            return <a href={`/users/blocks/reasons/${props.item.userBlockReasonId}`}><Body1>{UserBlockDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function UserBlockModificationListPanel(props: PanelProps) {
    const items = useStatePair<UserBlockModificationValueObject[]>([])
    const option: UserBlockModificationSearchOption = { userBlockIdEq: props.item.id }
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserBlockModificationList && <>
            <ListPageWrapper
                {...props}
                client={UserBlockModificationIO}
                items={items}
                option={option}
                index={index}
                showNewTab={false}
                description={UserBlockModificationDescription}
                descriptor={UserBlockModificationDescriptor} />
        </>}
    </>
}