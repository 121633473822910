import {EnvAgreementType} from "../../constant/env/EnvAgreementType"
import {EnvAgreementVerboseDomain} from "../../domain/env/EnvAgreementVerboseDomain"
import {EnvAgreementId} from "../../id/env/EnvAgreementId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 이용 약관 상세
 */
export class EnvAgreementVerboseValueObject implements VerboseValueObject, EnvAgreementVerboseDomain {
    /**
     * 기본키
     */
    id: EnvAgreementId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 유형
     */
    type: EnvAgreementType
    /**
     * 내용
     */
    body: string
    /**
     * 약관에 동의한 사용자 수
     */
    userAgreementCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof EnvAgreementVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.type = o.type as EnvAgreementType
        this.body = String(o.body)
        this.userAgreementCount = Number(o.userAgreementCount)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
    }
}
