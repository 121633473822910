import {EnvFaqDomain} from "../../domain/env/EnvFaqDomain"

/**
 * 자주 묻는 질문 속성 설명
 */
export const EnvFaqDescription: Record<keyof EnvFaqDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    title: '제목',
    displayOrder: '표시 순서',
    envFaqItemCount: '하위 항목 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
