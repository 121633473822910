import {AssetLunarPurchaseOptionDomain} from "../../domain/asset/AssetLunarPurchaseOptionDomain"

export const AssetLunarPurchaseOptionDescriptor: Record<keyof AssetLunarPurchaseOptionDomain, (o: AssetLunarPurchaseOptionDomain) => string> = {
    id(o: AssetLunarPurchaseOptionDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetLunarPurchaseOptionDomain): string {
        return o.creatorId.toString()
    },
    lunarAmount(o: AssetLunarPurchaseOptionDomain): string {
        return o.lunarAmount.toString()
    },
    cashAmount(o: AssetLunarPurchaseOptionDomain): string {
        return o.cashAmount.toString()
    },
    createdAt(o: AssetLunarPurchaseOptionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: AssetLunarPurchaseOptionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
