export enum UserNotificationSubject {
    /**
     * 공지
     */
    Notice = 'Notice',

    /**
     * 정보(대부분이 해당됨)
     */
    Info = 'Info',

    /**
     * 홍보
     */
    Commercial = 'Commercial',

    /**
     * 관리자
     */
    Admin = 'Admin'
}

export namespace UserNotificationSubject {
    export function description(o: UserNotificationSubject) {
        switch (o) {
            case UserNotificationSubject.Notice: return '공지'
            case UserNotificationSubject.Info: return '정보(대부분이 해당됨)'
            case UserNotificationSubject.Commercial: return '홍보'
            case UserNotificationSubject.Admin: return '관리자'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: UserNotificationSubject.Notice, label: description(UserNotificationSubject.Notice) },
        { value: UserNotificationSubject.Info, label: description(UserNotificationSubject.Info) },
        { value: UserNotificationSubject.Commercial, label: description(UserNotificationSubject.Commercial) },
        { value: UserNotificationSubject.Admin, label: description(UserNotificationSubject.Admin) }
    ]
}
