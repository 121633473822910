import {UserPutDataTransferObject} from "../../data-transfer-object/user/UserPutDataTransferObject"

/**
 * 사용자 속성 설명
 */
export const UserPutDataTransferObjectDescription: Record<keyof UserPutDataTransferObject, string> = {
    cloudMessagingToken: 'Firebase Cloud Messaging Token',
    nickname: '닉네임',
    residenceCountryId: '거주 국가 ID',
    phoneCountryId: '전화번호 국가 ID',
    phone: '전화번호',
    statusFlags: '상태 플래그'
}
