import {UserExpDomain} from "../../domain/user/UserExpDomain"

/**
 * 사용자 경험치 속성 설명
 */
export const UserExpDescription: Record<keyof UserExpDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    creatorId: '생성자 ID',
    type: '지급 유형',
    amount: '변화량',
    lastAccumulation: '직전 누계',
    inDay: '일간 누적',
    inWeek: '주간 누적',
    inMonth: '월간 누적',
    inQuarter: '분기간 누적',
    inHalf: '반기간 누적',
    inYear: '연간 누적',
    createdAt: '생성일'
}
