import {
    ContentQueryPopularVerboseValueObject
} from "../../../../data/value-object/content/ContentQueryPopularVerboseValueObject";
import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {ContentQueryPopularIO} from "../../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {ContentQueryPopularDescription} from "../../../../data/description/content/ContentQueryPopularDescription";
import {ContentQueryPopularDomain} from "../../../../data/domain/content/ContentQueryPopularDomain";
import {ContentQueryPopularDescriptor} from "../../../../data/descriptor/content/ContentQueryPopularDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentQueryPopularId: string }>()
    const item = useStatePair<ContentQueryPopularVerboseValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentQueryPopularId !== undefined) {
            ContentQueryPopularIO
                .getVerboseById(BigInt(params.contentQueryPopularId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentQueryPopularVerboseValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentQueryPopularIO}
                description={ContentQueryPopularDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentQueryPopularDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "contentType":
        case "displayOrder":
        case "displayOrderDiff":
        case "body":
        case "createdAt":
            return <Body1>{ContentQueryPopularDescriptor[props.itemKey](props.item)}</Body1>
    }
}