import {EnvBankPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvBankPutDataTransferObjectDescription"
import {EnvBankPutState} from "../../../data/data-transfer-object-state/env/EnvBankPutState"
import {EnvBankPutDataTransferObject} from "../../../data/data-transfer-object/env/EnvBankPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvBankPutState
}

export const EnvBankPutField: Record<Capitalize<keyof EnvBankPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Name(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvBankPutDataTransferObjectDescription.name}>
                <PostTextareaField
                    {...props}
                    value={props.state.name.value}
                    setter={props.state.name.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    DisplayOrder(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvBankPutDataTransferObjectDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={props.state.displayOrder.value}
                    setter={props.state.displayOrder.setter} />
            </PostFieldWrapper>
        )
    },
}

export function EnvBankPutFields(props: PostFieldProps) {
    return <>
        <EnvBankPutField.Name
            {...props}
            state={props.state} />
        <EnvBankPutField.DisplayOrder
            {...props}
            state={props.state} />
    </>
}
