import {UserWithdrawalDomain} from "../../domain/user/UserWithdrawalDomain"

/**
 * 사용자 탈퇴 속성 설명
 */
export const UserWithdrawalDescription: Record<keyof UserWithdrawalDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    userWithdrawalReasonId: '사용자 탈퇴 사유 ID',
    createdAt: '생성일'
}
