import {
    DeleteObjectCommand,
    GetObjectCommand,
    GetObjectCommandOutput,
    HeadObjectCommand,
    PutObjectCommand,
    S3Client
} from "@aws-sdk/client-s3";
import {EnvStorageKeyIO} from "./EnvIO";
import {getSignedUrl} from "@aws-sdk/s3-request-presigner";
import {ContentId} from "../data/id/content/ContentId";
import {UiOnboardingId} from "../data/id/ui/UiOnboardingId";
import {UiHomeContentSuggestionId} from "../data/id/ui/UiHomeContentSuggestionId";
import {UiHomeContentUserProductionId} from "../data/id/ui/UiHomeContentUserProductionId";
import {UiHomeNoticeId} from "../data/id/ui/UiHomeNoticeId";
import {MediaFile} from "../ui/Media";
import {UserId} from "../data/id/user/UserId";
import {Lazy} from "../util/Lazy";
import {UserRankId} from "../data/id/user/UserRankId";
import {EnvBankId} from "../data/id/env/EnvBankId";

const storageKey = new Lazy(() => EnvStorageKeyIO.getCurrent())
const storageClient = new Lazy(async () => {
    const key = await storageKey.get()
    return new S3Client({
        region: key.region,
        credentials: {
            accessKeyId: key.accessKeyId,
            secretAccessKey: key.secretAccessKey
        }
    })
})

export type GetObjectByteArrayCommandOutput = Omit<GetObjectCommandOutput, 'Body'> & {
    Body: Uint8Array
}

export const StorageIO = {
    async createUrl(key: string, showVersions: boolean = false) {
        const { bucket, region } = await storageKey.get()
        return `https://s3.console.aws.amazon.com/s3/buckets/${bucket}?region=${region}&prefix=${key}&showversions=${showVersions}`
    },
    async createObjectUrl(key: string) {
        const { bucket } = await storageKey.get()
        return `https://${bucket}.s3.ap-northeast-2.amazonaws.com/${key}`
    },
    async createSignedUrl(key: string) {
        const { bucket } = await storageKey.get()
        const client = await storageClient.get()
        return await getSignedUrl(client, new GetObjectCommand({ Bucket: bucket, Key: key }), { expiresIn: 3600 })
    },
    async createSignedUrls(keys: string[]) {
        const acc: (string | undefined)[] = []
        for (const key of keys) {
            try {
                acc.push(await this.createSignedUrl(key))
            } catch (e) {
                acc.push(undefined)
            }
        }

        return acc
    },
    async headObject(key: string) {
        const { bucket } = await storageKey.get()
        const client = await storageClient.get()
        return await client.send(new HeadObjectCommand({ Bucket: bucket, Key: key }))
    },
    async getObject(key: string) {
        const { bucket } = await storageKey.get()
        const client = await storageClient.get()
        return await client.send(new GetObjectCommand({ Bucket: bucket, Key: key }))
    },
    async getObjectByteArray(key: string): Promise<GetObjectByteArrayCommandOutput> {
        const object: GetObjectCommandOutput = await this.getObject(key)
        const body = object.Body
        if (body === undefined) {
            throw Error('body is undefined.')
        }

        const { Body, ...other } = object
        return {
            ...other,
            Body: await body.transformToByteArray()
        }
    },
    async putObject(key: string, body: ArrayBuffer, contentType?: string) {
        const { bucket } = await storageKey.get()
        const client = await storageClient.get()
        return await client.send(new PutObjectCommand({
            Bucket: bucket,
            Key: key,
            Body: new Uint8Array(body),
            ContentType: contentType
        }))
    },
    async putFile(key: string, mediaFile: MediaFile) {
        return await this.putObject(key, mediaFile.arrayBuffer, mediaFile.file.type)
    },
    async deleteObject(key: string) {
        const { bucket } = await storageKey.get()
        const client = await storageClient.get()
        return await client.send(new DeleteObjectCommand({ Bucket: bucket, Key: key }))
    }
}

export namespace StorageKey {
    export namespace Content {
        export function thumbnail(contentId: ContentId) {
            return `content/${contentId}/thumbnail`
        }

        export function headers(contentId: ContentId, count: number) {
            const acc: string[] = []
            for (let index = 0; index < count; index++) {
                acc.push(header(contentId, index))
            }

            return acc
        }

        export function header(contentId: ContentId, index: number) {
            return `content/${contentId}/header/${index}`
        }

        export function descriptions(contentId: ContentId, count: number) {
            const acc: string[] = []
            for (let index = 0; index < count; index++) {
                acc.push(description(contentId, index))
            }

            return acc
        }

        export function description(contentId: ContentId, index: number) {
            return `content/${contentId}/description/${index}`
        }

        export function participation(contentId: ContentId) {
            return `content/${contentId}/participation`
        }
    }

    export namespace Env {
        export function bank(id: EnvBankId) {
            return `env/bank/${id}`
        }
    }

    export namespace Ui {
        export namespace Home {
            export namespace Content {
                export function suggestion(id: UiHomeContentSuggestionId) {
                    return `ui/home/content/suggestion/${id}`
                }

                export function userProduction(id: UiHomeContentUserProductionId) {
                    return `ui/home/content/userProduction/${id}`
                }
            }

            export function notice(id: UiHomeNoticeId) {
                return `ui/home/notice/${id}`
            }
        }

        export function onboarding(id: UiOnboardingId) {
            return `ui/onboarding/${id}`
        }
    }

    export namespace User {
        export function profile(id: UserId) {
            return `user/${id}/profile`
        }

        export function rank(id: UserRankId) {
            return `user/rank/${id}`
        }
    }
}