import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import Post from "./Post";
import Edit from "./Edit";

export default function PolicyRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />}></Route>
            <Route path={'/post'} element={<Post {...props} />}></Route>
            <Route path={'/:assetPolicyId'} element={<Detail {...props} />}></Route>
            <Route path={'/:assetPolicyId/edit'} element={<Edit {...props} />}></Route>
        </Routes>
    </>
}