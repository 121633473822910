import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {EnvAgreementIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostEnumField,
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField,
    PostNumberField,
    PostTextareaField
} from "../../PostField";
import {EnvAgreementType} from "../../../data/constant/env/EnvAgreementType";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const type = useStatePair<EnvAgreementType>(EnvAgreementType.Service)
    const body = useStatePair<string>('')

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvAgreementIO
            .post({
                type: type.value,
                body: body.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [type.value, body.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={'유형'}>
                    <PostEnumField
                        {...props}
                        value={type.value}
                        setter={type.setter}
                        selections={EnvAgreementType.selections} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'내용'}>
                    <PostTextareaField
                        {...props}
                        value={body.value}
                        setter={body.setter}/>
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/agreements'}/>
    </>
}