export enum ContentType {
    /**
     * 오늘의 단어
     */
    Daily = 'Daily',

    /**
     * 따라 말하기
     */
    Repeat = 'Repeat',

    /**
     * 암기하기
     */
    M10n = 'M10n',

    /**
     * 와글와글
     */
    UserProduction = 'UserProduction'
}

export namespace ContentType {
    export function description(o: ContentType) {
        switch (o) {
            case ContentType.Daily: return '오늘의 단어'
            case ContentType.Repeat: return '따라 말하기'
            case ContentType.M10n: return '암기하기'
            case ContentType.UserProduction: return '와글와글'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: ContentType.Daily, label: description(ContentType.Daily) },
        { value: ContentType.Repeat, label: description(ContentType.Repeat) },
        { value: ContentType.M10n, label: description(ContentType.M10n) },
        { value: ContentType.UserProduction, label: description(ContentType.UserProduction) }
    ]
}
