import {UiPolicyIO} from "../../../io/UiIO";
import {useStatePair} from "react-type-extension";
import {RouterProps} from "../../RouterProps";
import {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFinishDialog, PostNumberField} from "../../PostField";
import {
    UiPolicyDescription
} from "../../../data/description/ui/UiPolicyDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const uiHomeContentSuggestionPageSize = useStatePair(0)
    const uiHomeContentUserProductionPageSize = useStatePair(0)
    const uiHomeNoticePageSize = useStatePair(0)
    const uiOnboardingPageSize = useStatePair(0)
    const uiPolicyPageSize = useStatePair(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        UiPolicyIO
            .post({
                uiHomeContentSuggestionPageSize: uiHomeContentSuggestionPageSize.value,
                uiHomeContentUserProductionPageSize: uiHomeContentUserProductionPageSize.value,
                uiHomeNoticePageSize: uiHomeNoticePageSize.value,
                uiOnboardingPageSize: uiOnboardingPageSize.value,
                uiPolicyPageSize: uiPolicyPageSize.value,
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [uiHomeContentSuggestionPageSize.value, uiHomeContentUserProductionPageSize.value, uiHomeNoticePageSize.value, uiOnboardingPageSize.value, uiPolicyPageSize.value,])

    return <>
        <div className={props.styles.column16}>
            <Title3>새 항목</Title3>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiHomeContentSuggestionPageSize}>
                <PostNumberField
                    {...props}
                    value={uiHomeContentSuggestionPageSize.value}
                    setter={uiHomeContentSuggestionPageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiHomeContentUserProductionPageSize}>
                <PostNumberField
                    {...props}
                    value={uiHomeContentUserProductionPageSize.value}
                    setter={uiHomeContentUserProductionPageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiHomeNoticePageSize}>
                <PostNumberField
                    {...props}
                    value={uiHomeNoticePageSize.value}
                    setter={uiHomeNoticePageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiOnboardingPageSize}>
                <PostNumberField
                    {...props}
                    value={uiOnboardingPageSize.value}
                    setter={uiOnboardingPageSize.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={UiPolicyDescription.uiPolicyPageSize}>
                <PostNumberField
                    {...props}
                    value={uiPolicyPageSize.value}
                    setter={uiPolicyPageSize.setter} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/ui/home/contents/suggestions'} />
    </>
}