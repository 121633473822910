import {UserPutDataTransferObject} from "../../data-transfer-object/user/UserPutDataTransferObject"
import {UserValueObject} from "../../value-object/user/UserValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type UserPutState = {
    cloudMessagingToken: StatePair<string | undefined>
    nickname: StatePair<string>
    residenceCountryId: StatePair<bigint>
    phoneCountryId: StatePair<bigint | undefined>
    phone: StatePair<string | undefined>
    statusFlags: StatePair<number>
    toDataTransferObject(): UserPutDataTransferObject
    fromValueObject(vo: UserValueObject): void
    values(): [string | undefined, string, bigint, bigint | undefined, string | undefined, number]
}

export function useUserPutState(): UserPutState {
    return {
        cloudMessagingToken: useStatePair<string | undefined>(undefined),
        nickname: useStatePair<string>(''),
        residenceCountryId: useStatePair<bigint>(0n),
        phoneCountryId: useStatePair<bigint | undefined>(undefined),
        phone: useStatePair<string | undefined>(undefined),
        statusFlags: useStatePair<number>(0),
        toDataTransferObject(): UserPutDataTransferObject {
            return {
                cloudMessagingToken: this.cloudMessagingToken.value,
                nickname: this.nickname.value,
                residenceCountryId: this.residenceCountryId.value,
                phoneCountryId: this.phoneCountryId.value,
                phone: this.phone.value,
                statusFlags: this.statusFlags.value,
            }
        },
        fromValueObject(vo: UserValueObject) {
            this.cloudMessagingToken.setter(vo.cloudMessagingToken)
            this.nickname.setter(vo.nickname)
            this.residenceCountryId.setter(vo.residenceCountryId)
            this.phoneCountryId.setter(vo.phoneCountryId)
            this.phone.setter(vo.phone)
            this.statusFlags.setter(vo.statusFlags)
        },
        values(): [string | undefined, string, bigint, bigint | undefined, string | undefined, number] {
            return [
                this.cloudMessagingToken.value,
                this.nickname.value,
                this.residenceCountryId.value,
                this.phoneCountryId.value,
                this.phone.value,
                this.statusFlags.value,
            ]
        }
    }
}
