import {EnvConstructionDomain} from "../../domain/env/EnvConstructionDomain"

/**
 * 작업 속성 설명
 */
export const EnvConstructionDescription: Record<keyof EnvConstructionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    title: '제목',
    body: '내용',
    startAt: '시작일',
    endAt: '종료일',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
