import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {SortOrder} from "ts-protocol-extension";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {
    AssetStarExchangeOptionValueObject
} from "../../../../data/value-object/asset/AssetStarExchangeOptionValueObject";
import {
    AssetStarExchangeOptionSearchOption
} from "../../../../data/search-option/asset/AssetStarExchangeOptionSearchOption";
import {AssetStarExchangeOptionIO} from "../../../../io/AssetIO";
import {
    AssetStarExchangeOptionDescription
} from "../../../../data/description/asset/AssetStarExchangeOptionDescription";
import {AssetStarExchangeOptionDescriptor} from "../../../../data/descriptor/asset/AssetStarExchangeOptionDescriptor";
import {
    AssetStarExchangeOptionSearchOptionDescription
} from "../../../../data/description/asset/AssetStarExchangeOptionSearchOptionDescription";
import {
    applyAssetStarExchangeOptionSearchOption,
    AssetStarExchangeOptionSearchOptionField
} from "../../../search-option-field/asset/AssetStarExchangeOptionSearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetStarExchangeOptionValueObject[]>([])
    const option = useStatePair<AssetStarExchangeOptionSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetStarExchangeOptionIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetStarExchangeOptionDescription}
                descriptor={AssetStarExchangeOptionDescriptor}
                showPost
                onPostClick={() => document.location = '/assets/stars/exchangeOptions/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/stars/exchangeOptions/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetStarExchangeOptionSearchOptionDescription}
                fields={AssetStarExchangeOptionSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetStarExchangeOptionSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetStarExchangeOptionDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}