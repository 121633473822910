import {UserLevelHistoryValueObject} from "../../../../data/value-object/user/UserLevelHistoryValueObject";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserLevelHistorySearchOption} from "../../../../data/search-option/user/UserLevelHistorySearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {UserLevelHistoryIO} from "../../../../io/UserIO";
import {UserLevelHistoryDescription} from "../../../../data/description/user/UserLevelHistoryDescription";
import {UserLevelHistoryDescriptor} from "../../../../data/descriptor/user/UserLevelHistoryDescriptor";
import ListOption from "../../../ListOption";
import {
    UserLevelHistorySearchOptionDescription
} from "../../../../data/description/user/UserLevelHistorySearchOptionDescription";
import {
    applyUserLevelHistorySearchOption,
    UserLevelHistorySearchOptionField
} from "../../../search-option-field/user/UserLevelHistorySearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserLevelHistoryValueObject[]>([])
    const option = useStatePair<UserLevelHistorySearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserLevelHistoryIO}
                items={items}
                option={option.value}
                index={index}
                description={UserLevelHistoryDescription}
                descriptor={UserLevelHistoryDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/levels/histories/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserLevelHistorySearchOptionDescription}
                fields={UserLevelHistorySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserLevelHistorySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserLevelHistoryDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}