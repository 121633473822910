import {ContentCommentDomain} from "../../domain/content/ContentCommentDomain"

export const ContentCommentDescriptor: Record<keyof ContentCommentDomain, (o: ContentCommentDomain) => string> = {
    id(o: ContentCommentDomain): string {
        return o.id.toString()
    },
    contentId(o: ContentCommentDomain): string {
        return o.contentId.toString()
    },
    parentId(o: ContentCommentDomain): string {
        return o.parentId?.toString() ?? '-'
    },
    userId(o: ContentCommentDomain): string {
        return o.userId.toString()
    },
    body(o: ContentCommentDomain): string {
        return o.body
    },
    audioId(o: ContentCommentDomain): string {
        return o.audioId?.toString() ?? '-'
    },
    childCount(o: ContentCommentDomain): string {
        return o.childCount.toString()
    },
    likeCount(o: ContentCommentDomain): string {
        return o.likeCount.toString()
    },
    reportCount(o: ContentCommentDomain): string {
        return o.reportCount.toString()
    },
    createdAt(o: ContentCommentDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentCommentDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: ContentCommentDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
