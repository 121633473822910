import {EnvNoticePutDataTransferObject} from "../../data-transfer-object/env/EnvNoticePutDataTransferObject"
import {EnvNoticeValueObject} from "../../value-object/env/EnvNoticeValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type EnvNoticePutState = {
    title: StatePair<string>
    url: StatePair<string>
    toDataTransferObject(): EnvNoticePutDataTransferObject
    fromValueObject(vo: EnvNoticeValueObject): void
    values(): [string, string]
}

export function useEnvNoticePutState(): EnvNoticePutState {
    return {
        title: useStatePair<string>(''),
        url: useStatePair<string>(''),
        toDataTransferObject(): EnvNoticePutDataTransferObject {
            return {
                title: this.title.value,
                url: this.url.value,
            }
        },
        fromValueObject(vo: EnvNoticeValueObject) {
            this.title.setter(vo.title)
            this.url.setter(vo.url)
        },
        values(): [string, string] {
            return [
                this.title.value,
                this.url.value,
            ]
        }
    }
}
