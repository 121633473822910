import {UserAgreementSearchOption} from "../../search-option/user/UserAgreementSearchOption"

/**
 * 사용자 이용 약관 동의 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserAgreementSearchOptionDescription: Record<keyof UserAgreementSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    envAgreementIdEq: '이용 약관 ID 일치',
    envAgreementIdNeq: '이용 약관 ID 불일치',
    envAgreementIdMin: '이용 약관 ID 최소',
    envAgreementIdMax: '이용 약관 ID 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
