import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import LikeRouter from "./like/LikeRouter";
import Detail from "./Detail";
import ReportRouter from "./report/ReportRouter";

export default function CommentRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/:contentCommentId'} element={<Detail {...props} />} />
            <Route path={'/likes/*'} element={<LikeRouter {...props} />} />
            <Route path={'/reports/*'} element={<ReportRouter {...props} />} />
        </Routes>
    </>
}