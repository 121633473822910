import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvConstructionVerboseValueObject} from "../../../data/value-object/env/EnvConstructionVerboseValueObject";
import {useCallback, useEffect} from "react";
import {EnvConstructionIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostDateField, PostFieldWrapper, PostInputField} from "../../PostField";
import {EnvConstructionDescription} from "../../../data/description/env/EnvConstructionDescription";
import { DateFormat } from "ts-format-extension";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envConstructionId: string }>()
    const item = useStatePair<EnvConstructionVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const title = useStatePair('')
    const body = useStatePair('')
    const startAt = useStatePair('')
    const endAt = useStatePair('')

    useEffect(() => {
        if (item.value === undefined && params.envConstructionId !== undefined) {
            EnvConstructionIO
                .getVerboseById(BigInt(params.envConstructionId))
                .then(value => {
                    item.setter(value)
                    title.setter(value.title)
                    body.setter(value.body)
                    startAt.setter(DateFormat.tableDateTime(value.endAt))
                    endAt.setter(DateFormat.tableDateTime(value.endAt))
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        EnvConstructionIO
            .put(id, {
                title: title.value,
                body: body.value,
                startAt: new Date(startAt.value),
                endAt: new Date(endAt.value)
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/constructions/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [title.value, body.value, startAt.value, endAt.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={EnvConstructionDescription.title}>
                <PostInputField
                    {...props}
                    value={title.value}
                    setter={title.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvConstructionDescription.body}>
                <PostInputField
                    {...props}
                    value={body.value}
                    setter={body.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvConstructionDescription.startAt}>
                <PostDateField
                    {...props}
                    value={startAt.value}
                    setter={startAt.setter}
                    maxLength={19} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvConstructionDescription.endAt}>
                <PostDateField
                    {...props}
                    value={endAt.value}
                    setter={endAt.setter}
                    maxLength={19} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}