import {UiPolicyDomain} from "../../domain/ui/UiPolicyDomain"

/**
 * UI 정책 속성 설명
 */
export const UiPolicyDescription: Record<keyof UiPolicyDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    uiHomeContentSuggestionPageSize: '홈 추천 콘텐츠 페이지 크기',
    uiHomeContentUserProductionPageSize: '홈 와글와글 콘텐츠 페이지 크기',
    uiHomeNoticePageSize: '홈 공지사항 페이지 크기',
    uiOnboardingPageSize: '온보딩 페이지 크기',
    uiPolicyPageSize: 'UI 정책 페이지 크기',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
