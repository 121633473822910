import {UserWithdrawalSearchOption} from "../../search-option/user/UserWithdrawalSearchOption"

/**
 * 사용자 탈퇴 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserWithdrawalSearchOptionDescription: Record<keyof UserWithdrawalSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    userWithdrawalReasonIdEq: '사용자 탈퇴 사유 ID 일치',
    userWithdrawalReasonIdNeq: '사용자 탈퇴 사유 ID 불일치',
    userWithdrawalReasonIdMin: '사용자 탈퇴 사유 ID 최소',
    userWithdrawalReasonIdMax: '사용자 탈퇴 사유 ID 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
