import {UserRankDuration} from "../../constant/user/UserRankDuration"
import {EnvPolicyPostDataTransferObject} from "../../data-transfer-object/env/EnvPolicyPostDataTransferObject"
import {EnvPolicyValueObject} from "../../value-object/env/EnvPolicyValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type EnvPolicyPostState = {
    envAdminPageSize: StatePair<number>
    envAgreementPageSize: StatePair<number>
    envBankPageSize: StatePair<number>
    envClientPageSize: StatePair<number>
    envConstructionPageSize: StatePair<number>
    envCountryPageSize: StatePair<number>
    envCountryRankDuration: StatePair<UserRankDuration>
    envEventPageSize: StatePair<number>
    envFaqPageSize: StatePair<number>
    envFaqItemPageSize: StatePair<number>
    envNoticePageSize: StatePair<number>
    envPhoneBlockPageSize: StatePair<number>
    envPolicyPageSize: StatePair<number>
    envStorageKeyPageSize: StatePair<number>
    toDataTransferObject(): EnvPolicyPostDataTransferObject
    fromValueObject(vo: EnvPolicyValueObject): void
    values(): [number, number, number, number, number, number, UserRankDuration, number, number, number, number, number, number, number]
}

export function useEnvPolicyPostState(): EnvPolicyPostState {
    return {
        envAdminPageSize: useStatePair<number>(0),
        envAgreementPageSize: useStatePair<number>(0),
        envBankPageSize: useStatePair<number>(0),
        envClientPageSize: useStatePair<number>(0),
        envConstructionPageSize: useStatePair<number>(0),
        envCountryPageSize: useStatePair<number>(0),
        envCountryRankDuration: useStatePair<UserRankDuration>(UserRankDuration.Day),
        envEventPageSize: useStatePair<number>(0),
        envFaqPageSize: useStatePair<number>(0),
        envFaqItemPageSize: useStatePair<number>(0),
        envNoticePageSize: useStatePair<number>(0),
        envPhoneBlockPageSize: useStatePair<number>(0),
        envPolicyPageSize: useStatePair<number>(0),
        envStorageKeyPageSize: useStatePair<number>(0),
        toDataTransferObject(): EnvPolicyPostDataTransferObject {
            return {
                envAdminPageSize: this.envAdminPageSize.value,
                envAgreementPageSize: this.envAgreementPageSize.value,
                envBankPageSize: this.envBankPageSize.value,
                envClientPageSize: this.envClientPageSize.value,
                envConstructionPageSize: this.envConstructionPageSize.value,
                envCountryPageSize: this.envCountryPageSize.value,
                envCountryRankDuration: this.envCountryRankDuration.value,
                envEventPageSize: this.envEventPageSize.value,
                envFaqPageSize: this.envFaqPageSize.value,
                envFaqItemPageSize: this.envFaqItemPageSize.value,
                envNoticePageSize: this.envNoticePageSize.value,
                envPhoneBlockPageSize: this.envPhoneBlockPageSize.value,
                envPolicyPageSize: this.envPolicyPageSize.value,
                envStorageKeyPageSize: this.envStorageKeyPageSize.value,
            }
        },
        fromValueObject(vo: EnvPolicyValueObject) {
            this.envAdminPageSize.setter(vo.envAdminPageSize)
            this.envAgreementPageSize.setter(vo.envAgreementPageSize)
            this.envBankPageSize.setter(vo.envBankPageSize)
            this.envClientPageSize.setter(vo.envClientPageSize)
            this.envConstructionPageSize.setter(vo.envConstructionPageSize)
            this.envCountryPageSize.setter(vo.envCountryPageSize)
            this.envCountryRankDuration.setter(vo.envCountryRankDuration)
            this.envEventPageSize.setter(vo.envEventPageSize)
            this.envFaqPageSize.setter(vo.envFaqPageSize)
            this.envFaqItemPageSize.setter(vo.envFaqItemPageSize)
            this.envNoticePageSize.setter(vo.envNoticePageSize)
            this.envPhoneBlockPageSize.setter(vo.envPhoneBlockPageSize)
            this.envPolicyPageSize.setter(vo.envPolicyPageSize)
            this.envStorageKeyPageSize.setter(vo.envStorageKeyPageSize)
        },
        values(): [number, number, number, number, number, number, UserRankDuration, number, number, number, number, number, number, number] {
            return [
                this.envAdminPageSize.value,
                this.envAgreementPageSize.value,
                this.envBankPageSize.value,
                this.envClientPageSize.value,
                this.envConstructionPageSize.value,
                this.envCountryPageSize.value,
                this.envCountryRankDuration.value,
                this.envEventPageSize.value,
                this.envFaqPageSize.value,
                this.envFaqItemPageSize.value,
                this.envNoticePageSize.value,
                this.envPhoneBlockPageSize.value,
                this.envPolicyPageSize.value,
                this.envStorageKeyPageSize.value,
            ]
        }
    }
}
