import {UiOnboardingModificationDomain} from "../../domain/ui/UiOnboardingModificationDomain"
import {UiOnboardingId} from "../../id/ui/UiOnboardingId"
import {UiOnboardingModificationId} from "../../id/ui/UiOnboardingModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 온보딩 이미지 수정 이력
 */
export class UiOnboardingModificationValueObject implements ValueObject, UiOnboardingModificationDomain {
    /**
     * 기본키
     */
    id: UiOnboardingModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 온보딩 이미지 ID
     */
    uiOnboardingId: UiOnboardingId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UiOnboardingModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiOnboardingId = BigInt(o.uiOnboardingId)
        this.displayOrder = Number(o.displayOrder)
        this.createdAt = new Date(o.createdAt)
    }
}
