import {UiOnboardingDomain} from "../../domain/ui/UiOnboardingDomain"

export const UiOnboardingDescriptor: Record<keyof UiOnboardingDomain, (o: UiOnboardingDomain) => string> = {
    id(o: UiOnboardingDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiOnboardingDomain): string {
        return o.creatorId.toString()
    },
    displayOrder(o: UiOnboardingDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: UiOnboardingDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UiOnboardingDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: UiOnboardingDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
