import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    AssetSolarWithdrawalValueObject
} from "../../../../data/value-object/asset/AssetSolarWithdrawalValueObject";
import {useEffect} from "react";
import {AssetSolarWithdrawalIO} from "../../../../io/AssetIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {
    AssetSolarWithdrawalDescription
} from "../../../../data/description/asset/AssetSolarWithdrawalDescription";
import {AssetSolarWithdrawalDomain} from "../../../../data/domain/asset/AssetSolarWithdrawalDomain";
import {AssetSolarWithdrawalDescriptor} from "../../../../data/descriptor/asset/AssetSolarWithdrawalDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetSolarWithdrawalId: string }>()
    const item = useStatePair<AssetSolarWithdrawalValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetSolarWithdrawalId !== undefined) {
            AssetSolarWithdrawalIO
                .getById(BigInt(params.assetSolarWithdrawalId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetSolarWithdrawalValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetSolarWithdrawalIO}
                description={AssetSolarWithdrawalDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetSolarWithdrawalDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "account":
        case "cashAmount":
        case "createdAt":
            return <Body1>{AssetSolarWithdrawalDescriptor[props.itemKey](props.item)}</Body1>
        case "assetSolarUsageId":
            return <a href={`/asset/solars/usages/${props.item.assetSolarUsageId}`}><Body1>{AssetSolarWithdrawalDescriptor[props.itemKey](props.item)}</Body1></a>
        case "envBankId":
            return <a href={`/env/banks/${props.item.envBankId}`}><Body1>{AssetSolarWithdrawalDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}