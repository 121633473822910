import {EnvEventModificationDomain} from "../../domain/env/EnvEventModificationDomain"

/**
 * 이벤트 수정 이력 속성 설명
 */
export const EnvEventModificationDescription: Record<keyof EnvEventModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envEventId: '이벤트 ID',
    title: '제목',
    url: 'URL',
    createdAt: '수정일'
}
