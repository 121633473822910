import {AssetSolarUsageType} from "../../../data/constant/asset/AssetSolarUsageType"
import {AssetSolarUsagePostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetSolarUsagePostDataTransferObjectDescription"
import {AssetSolarUsagePostState} from "../../../data/data-transfer-object-state/asset/AssetSolarUsagePostState"
import {AssetSolarUsagePostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetSolarUsagePostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetSolarUsagePostState
}

export const AssetSolarUsagePostField: Record<Capitalize<keyof AssetSolarUsagePostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    UserId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarUsagePostDataTransferObjectDescription.userId}>
                <PostBigIntField
                    {...props}
                    value={props.state.userId.value}
                    setter={props.state.userId.setter} />
            </PostFieldWrapper>
        )
    },
    Type(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarUsagePostDataTransferObjectDescription.type}>
                <PostEnumField
                    {...props}
                    value={props.state.type.value}
                    setter={props.state.type.setter}
                    selections={AssetSolarUsageType.selections} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetSolarUsagePostDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetSolarUsagePostFields(props: PostFieldProps) {
    return <>
        <AssetSolarUsagePostField.UserId
            {...props}
            state={props.state} />
        <AssetSolarUsagePostField.Type
            {...props}
            state={props.state} />
        <AssetSolarUsagePostField.Amount
            {...props}
            state={props.state} />
    </>
}
