import {UiPolicyModificationDomain} from "../../domain/ui/UiPolicyModificationDomain"

export const UiPolicyModificationDescriptor: Record<keyof UiPolicyModificationDomain, (o: UiPolicyModificationDomain) => string> = {
    id(o: UiPolicyModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiPolicyModificationDomain): string {
        return o.creatorId.toString()
    },
    uiPolicyId(o: UiPolicyModificationDomain): string {
        return o.uiPolicyId.toString()
    },
    uiHomeContentSuggestionPageSize(o: UiPolicyModificationDomain): string {
        return o.uiHomeContentSuggestionPageSize.toString()
    },
    uiHomeContentUserProductionPageSize(o: UiPolicyModificationDomain): string {
        return o.uiHomeContentUserProductionPageSize.toString()
    },
    uiHomeNoticePageSize(o: UiPolicyModificationDomain): string {
        return o.uiHomeNoticePageSize.toString()
    },
    uiOnboardingPageSize(o: UiPolicyModificationDomain): string {
        return o.uiOnboardingPageSize.toString()
    },
    uiPolicyPageSize(o: UiPolicyModificationDomain): string {
        return o.uiPolicyPageSize.toString()
    },
    createdAt(o: UiPolicyModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
