import {RouterProps} from "./RouterProps";
import {JSX} from "react";
import {
    Breadcrumb, BreadcrumbButton, BreadcrumbDivider, BreadcrumbItem,
    Button, Caption1Strong,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Image,
    makeStyles, mergeClasses,
    OverlayDrawer, shorthands, Tree, TreeItem, TreeItemLayout
} from "@fluentui/react-components";
import {Dismiss24Filled, TextAlignJustify24Filled} from "@fluentui/react-icons";
import {useBooleanPair} from "react-type-extension";

export type PageWrapperProps = RouterProps & {
    children?: JSX.Element | JSX.Element[]
}

const useStyles = makeStyles({
    header: {
        alignItems: 'center'
    },
    headerDrawerButton: {
        ...shorthands.margin('16px')
    },
    drawerTree: {
        marginTop: '8px',
        marginBottom: '16px'
    },
    children: {
        ...shorthands.margin('16px')
    }
})

export default function PageWrapper(props: PageWrapperProps) {
    const styles = useStyles()
    const isDrawerOpen = useBooleanPair(false)

    flattenNavigationItems()
    return <>
        <div>
            <OverlayDrawer
                open={isDrawerOpen.value}
                onOpenChange={(_, data) => isDrawerOpen.setter(data.open)}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={<>
                            <Button
                                appearance={'subtle'}
                                icon={<Dismiss24Filled/>}
                                onClick={() => isDrawerOpen.setFalse()}/>
                        </>}>
                        <Image src={'/logo.svg'}/>
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <PageWrapperDrawerItems/>
                </DrawerBody>
            </OverlayDrawer>
        </div>
        <div className={mergeClasses(props.styles.row, styles.header)}>
            <Button
                className={styles.headerDrawerButton}
                appearance={'subtle'}
                icon={<TextAlignJustify24Filled/>}
                onClick={() => isDrawerOpen.setTrue()}/>
            <PageWrapperBreadcrumb/>
        </div>
        <div className={mergeClasses(props.styles.column, styles.children)}>
            {props.children}
        </div>
    </>
}

function PageWrapperBreadcrumb() {
    const elements = parsePathname()
    if (elements.length === 0) {
        elements.push({
            text: '홈',
            href: '/'
        })
    }

    return <>
        <Breadcrumb>
            {elements.map((value, index) => <>
                <BreadcrumbItem key={`breadcrumb-${index}`}>
                    <BreadcrumbButton href={value.href} current={index === elements.length - 1}>
                        {value.text}
                    </BreadcrumbButton>
                </BreadcrumbItem>
                {index < elements.length - 1 && <BreadcrumbDivider/>}
            </>)}
        </Breadcrumb>
    </>
}

function PageWrapperDrawerItems() {
    const styles = useStyles()
    const elements = flattenNavigationItems()
    return <>
        {elements.map(element => <>
            <Caption1Strong>{element.text}</Caption1Strong>
            <Tree className={styles.drawerTree}>
                {element.leaves.map(value => <>
                    <TreeItem
                        itemType={'leaf'}
                        onClick={() => document.location = value.href}>
                        <TreeItemLayout>
                            {value.text}
                        </TreeItemLayout>
                    </TreeItem>
                </>)}
            </Tree>
        </>)}
    </>
}

const PathActions = {
    post: { text: '추가' },
    edit: { text: '수정' },
    copy: { text: '복사' }
}

const PathDictionary = {
    assets: {
        text: '재화',
        coupons: {
            text: '쿠폰',
            ...PathActions,
            usages: {
                text: '사용',
                ...PathActions
            }
        },
        lunars: {
            text: '루나',
            exchangeOptions: {
                ...PathActions,
                text: '교환 옵션',
            },
            purchaseOptions: {
                ...PathActions,
                text: '구매 옵션'
            },
            usages: {
                ...PathActions,
                text: '사용'
            }
        },
        policies: {
            ...PathActions,
            text: '정책'
        },
        solars: {
            text: '솔라',
            usages: {
                ...PathActions,
                text: '사용'
            },
            withdrawals: {
                ...PathActions,
                text: '인출'
            },
            withdrawalOptions: {
                ...PathActions,
                text: '인출 옵션'
            }
        },
        stars: {
            text: '스타',
            charges: {
                ...PathActions,
                text: '충전'
            },
            exchangeOptions: {
                ...PathActions,
                text: '교환 옵션'
            },
            usages: {
                ...PathActions,
                text: '사용'
            }
        },
    },
    contents: {
        ...PathActions,
        text: '콘텐츠',
        comments: {
            ...PathActions,
            text: '댓글',
            like: {
                ...PathActions,
                text: '좋아요'
            },
            reports: {
                ...PathActions,
                text: '신고',
                reasons: {
                    ...PathActions,
                    text: '사유'
                }
            }
        },
        detailUrls: {
            ...PathActions,
            text: '더 알아보기 URL',
            click: {
                ...PathActions,
                text: '클릭'
            }
        },
        likes: {
            ...PathActions,
            text: '좋아요',
        },
        participations: {
            ...PathActions,
            text: '참여'
        },
        policies: {
            ...PathActions,
            text: '정책'
        },
        queries: {
            ...PathActions,
            text: '검색어',
            populars: {
                ...PathActions,
                text: '인기',
                preemptions: {
                    ...PathActions,
                    text: '예약'
                }
            }
        },
        reports: {
            ...PathActions,
            text: '신고',
            reasons: {
                ...PathActions,
                text: '사유'
            }
        },
        resultUrls: {
            ...PathActions,
            text: '참여 결과 URL',
            click: {
                ...PathActions,
                text: '클릭'
            }
        },
        reviews: {
            ...PathActions,
            text: '평가',
        },
        targets: {
            ...PathActions,
            text: '대상'
        },
        userProduction: {
            text: '와글와글 프리셋',
            durations: {
                ...PathActions,
                text: '기간'
            },
            participants: {
                ...PathActions,
                text: '인원'
            },
            rewards: {
                ...PathActions,
                text: '보상'
            },
            scripts: {
                ...PathActions,
                text: '스크립트'
            }
        }
    },
    env: {
        text: '환경',
        agreements: {
            ...PathActions,
            text: '이용 약관'
        },
        banks: {
            ...PathActions,
            text: '은행'
        },
        clients: {
            ...PathActions,
            text: '클라이언트'
        },
        constructions: {
            ...PathActions,
            text: '점검'
        },
        countries: {
            ...PathActions,
            text: '국가'
        },
        events: {
            ...PathActions,
            text: '이벤트'
        },
        faqs: {
            ...PathActions,
            text: '자주 묻는 질문',
            items: {
                ...PathActions,
                text: '항목'
            }
        },
        notices: {
            ...PathActions,
            text: '공지사항'
        },
        phoneBlocks: {
            ...PathActions,
            text: '전화번호 차단'
        },
        policies: {
            ...PathActions,
            text: '정책'
        },
        storageKeys: {
            ...PathActions,
            text: 'AWS IAM 사용자'
        },
    },
    scripts: {
        ...PathActions,
        text: '스크립트',
        contextualStrings: {
            ...PathActions,
            text: '특수 문자열'
        },
        customPronunciations: {
            ...PathActions,
            text: '발음 기호',
            phonemes: {
                ...PathActions,
                text: '표현'
            }
        },
        phrases: {
            ...PathActions,
            text: '문장'
        },
        policies: {
            ...PathActions,
            text: '정책'
        }
    },
    ui: {
        text: 'UI',
        home: {
            text: '홈',
            contents: {
                text: '콘텐츠',
                suggestions: {
                    ...PathActions,
                    text: '추천'
                },
                userProductions: {
                    ...PathActions,
                    text: '와글와글'
                }
            },
            notices: {
                ...PathActions,
                text: '공지사항 및 이벤트'
            }
        },
        onboardings: {
            ...PathActions,
            text: '온보딩'
        },
        policies: {
            ...PathActions,
            text: '정책'
        }
    },
    users: {
        ...PathActions,
        text: '사용자',
        edit: {
            text: '수정',
            adminPermission: { text: '권한' },
            role: { text: '역할' }
        },
        agreements: {
            ...PathActions,
            text: '이용 약관 동의'
        },
        blocks: {
            ...PathActions,
            text: '이용 정지',
            reasons: {
                ...PathActions,
                text: '사유'
            },
        },
        exps: {
            ...PathActions,
            text: '경험치'
        },
        inquiries: {
            ...PathActions,
            text: '문의'
        },
        levels: {
            ...PathActions,
            text: '레벨',
            histories: {
                ...PathActions,
                text: '이력'
            }
        },
        notifications: {
            ...PathActions,
            text: '알림'
        },
        policies: {
            ...PathActions,
            text: '정책'
        },
        ranks: {
            ...PathActions,
            text: '랭크',
            histories: {
                ...PathActions,
                text: '이력'
            }
        },
        sessions: {
            ...PathActions,
            text: '세션'
        },
        signIns: {
            ...PathActions,
            text: '로그인'
        },
        withdrawals: {
            ...PathActions,
            text: '탈퇴',
            reasons: {
                ...PathActions,
                text: '사유'
            }
        }
    }
}

function parsePathname() {
    const tokens = document.location.pathname.split('/')
    const entryKeys: string[] = []
    const elements: { text: string, href: string }[] = []
    for (const token of tokens) {
        if (token.length === 0) {
            continue
        }

        const href = (elements.length === 0)
            ? `/${token}`
            : `${elements[elements.length - 1].href}/${token}`
        entryKeys.push(token)
        let entry: any | undefined = PathDictionary
        for (const entryKey of entryKeys) {
            entry = entry[entryKey]
        }

        if (entry !== undefined) {
            const text = entry['text'] as string
            elements.push({ text: text, href: href })
        } else {
            elements.push({ text: token, href: href })
            entryKeys.pop()
        }
    }

    return elements
}

function flattenNavigationItems() {
    const elements: { text: string, leaves: { text: string, href: string }[] }[] = []
    for (const key in PathDictionary) {
        const leaves: { text: string, href: string }[] = []
        const entry = (PathDictionary as any)[key]
        iterateDictionaryText(entry, 0, [key], [], (paths, texts, _, hasActions) => {
            const lastPath = paths[paths.length - 1]
            if (lastPath === 'post' || lastPath === 'edit' || lastPath === 'copy') {
                return
            }
            if (!hasActions) {
                return
            }

            leaves.push({
                text: texts.join(' > '),
                href: '/' + paths.join('/')
            })
        })

        elements.push({
            text: entry['text'],
            leaves: leaves
        })
    }

    return elements
}

function iterateDictionaryText(
    entry: any,
    depth: number,
    paths: string[],
    texts: string[],
    action: (paths: string[], texts: string[], depth: number, hasActions: boolean) => void
) {
    const hasActions = entry['post'] !== undefined
    const text = entry['text'] as string
    texts.push(text)
    action(paths, texts, depth, hasActions)
    for (let key in entry) {
        if (key !== 'text') {
            paths.push(key)
            iterateDictionaryText(entry[key], depth + 1, paths, texts, action)
            paths.pop()
        }
    }

    texts.pop()
}