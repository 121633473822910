import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvFaqVerboseValueObject} from "../../../data/value-object/env/EnvFaqVerboseValueObject";
import {useCallback, useEffect} from "react";
import {EnvFaqIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostInputField, PostNumberField} from "../../PostField";
import {EnvFaqDescription} from "../../../data/description/env/EnvFaqDescription";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envFaqId: string }>()
    const item = useStatePair<EnvFaqVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const title = useStatePair('')
    const displayOrder = useStatePair(0)

    useEffect(() => {
        if (item.value === undefined && params.envFaqId !== undefined) {
            EnvFaqIO
                .getVerboseById(BigInt(params.envFaqId))
                .then(value => {
                    item.setter(value)
                    title.setter(value.title)
                    displayOrder.setter(value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        EnvFaqIO
            .put(id, {
                title: title.value,
                displayOrder: displayOrder.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/faqs/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [title.value, displayOrder.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={EnvFaqDescription.title}>
                <PostInputField
                    {...props}
                    value={title.value}
                    setter={title.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvFaqDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}