import {AssetLunarPurchaseOptionPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetLunarPurchaseOptionPutDataTransferObjectDescription"
import {AssetLunarPurchaseOptionPutState} from "../../../data/data-transfer-object-state/asset/AssetLunarPurchaseOptionPutState"
import {AssetLunarPurchaseOptionPutDataTransferObject} from "../../../data/data-transfer-object/asset/AssetLunarPurchaseOptionPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetLunarPurchaseOptionPutState
}

export const AssetLunarPurchaseOptionPutField: Record<Capitalize<keyof AssetLunarPurchaseOptionPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    LunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarPurchaseOptionPutDataTransferObjectDescription.lunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.lunarAmount.value}
                    setter={props.state.lunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    CashAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarPurchaseOptionPutDataTransferObjectDescription.cashAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.cashAmount.value}
                    setter={props.state.cashAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetLunarPurchaseOptionPutFields(props: PostFieldProps) {
    return <>
        <AssetLunarPurchaseOptionPutField.LunarAmount
            {...props}
            state={props.state} />
        <AssetLunarPurchaseOptionPutField.CashAmount
            {...props}
            state={props.state} />
    </>
}
