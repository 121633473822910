import {EnvPhoneBlockDomain} from "../../domain/env/EnvPhoneBlockDomain"

export const EnvPhoneBlockDescriptor: Record<keyof EnvPhoneBlockDomain, (o: EnvPhoneBlockDomain) => string> = {
    id(o: EnvPhoneBlockDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvPhoneBlockDomain): string {
        return o.creatorId.toString()
    },
    envCountryId(o: EnvPhoneBlockDomain): string {
        return o.envCountryId.toString()
    },
    phone(o: EnvPhoneBlockDomain): string {
        return o.phone
    },
    createdAt(o: EnvPhoneBlockDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvPhoneBlockDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
