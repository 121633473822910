import {EnvFaqItemModificationDomain} from "../../domain/env/EnvFaqItemModificationDomain"

export const EnvFaqItemModificationDescriptor: Record<keyof EnvFaqItemModificationDomain, (o: EnvFaqItemModificationDomain) => string> = {
    id(o: EnvFaqItemModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvFaqItemModificationDomain): string {
        return o.creatorId.toString()
    },
    envFaqItemId(o: EnvFaqItemModificationDomain): string {
        return o.envFaqItemId.toString()
    },
    envFaqId(o: EnvFaqItemModificationDomain): string {
        return o.envFaqId.toString()
    },
    title(o: EnvFaqItemModificationDomain): string {
        return o.title
    },
    body(o: EnvFaqItemModificationDomain): string {
        return o.body
    },
    displayOrder(o: EnvFaqItemModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: EnvFaqItemModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
