import {AssetSolarWithdrawalOptionSearchOption} from "../../search-option/asset/AssetSolarWithdrawalOptionSearchOption"

/**
 * 솔라 인출 옵션 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const AssetSolarWithdrawalOptionSearchOptionDescription: Record<keyof AssetSolarWithdrawalOptionSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    solarAmountNeq: '차감 솔라 불일치',
    solarAmountMin: '차감 솔라 최소',
    solarAmountMax: '차감 솔라 최대',
    cashAmountEq: '지급 금액 일치',
    cashAmountNeq: '지급 금액 불일치',
    cashAmountMin: '지급 금액 최소',
    cashAmountMax: '지급 금액 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
