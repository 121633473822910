import {UserLevelHistoryDescriptor} from "../../../../data/descriptor/user/UserLevelHistoryDescriptor";
import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserLevelHistoryValueObject} from "../../../../data/value-object/user/UserLevelHistoryValueObject";
import {useEffect} from "react";
import {Body1, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {mapByKey} from "../../../../util/ObjectUtil";
import {UserLevelHistoryDescription} from "../../../../data/description/user/UserLevelHistoryDescription";
import {UserLevelHistoryDomain} from "../../../../data/domain/user/UserLevelHistoryDomain";
import {UserLevelHistoryIO} from "../../../../io/UserIO";

enum Panel {
    Default,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userLevelHistoryId: string }>()
    const item = useStatePair<UserLevelHistoryValueObject | undefined>(undefined)

    useEffect(() => {
        if (params.userLevelHistoryId !== undefined) {
            UserLevelHistoryIO
                .getById(BigInt(params.userLevelHistoryId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserLevelHistoryValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
            </TabList>
            <DefaultPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserLevelHistoryDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{UserLevelHistoryDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{UserLevelHistoryDescriptor[props.itemKey](props.item)}</Body1></a>
        case "userLevelId":
            return <a href={`/users/levels/${props.item.userLevelId}`}><Body1>{UserLevelHistoryDescriptor[props.itemKey](props.item)}</Body1></a>
        case "assetLunarUsageId":
            return (props.item.assetLunarUsageId !== undefined)
                ? <a href={`/assets/lunars/usages/${props.item.assetLunarUsageId}`}><Body1>{UserLevelHistoryDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
        case "assetSolarUsageId":
            return (props.item.assetSolarUsageId !== undefined)
                ? <a href={`/assets/solars/usages/${props.item.assetSolarUsageId}`}><Body1>{UserLevelHistoryDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
        case "assetStarUsageId":
            return (props.item.assetStarUsageId !== undefined)
                ? <a href={`/assets/stars/usages/${props.item.assetStarUsageId}`}><Body1>{UserLevelHistoryDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(UserLevelHistoryDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{UserLevelHistoryDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}