import {ContentUserProductionDurationModificationDomain} from "../../domain/content/ContentUserProductionDurationModificationDomain"

/**
 * 와글와글 기간 프리셋 수정 이력 속성 설명
 */
export const ContentUserProductionDurationModificationDescription: Record<keyof ContentUserProductionDurationModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    contentUserProductionDurationId: '와글와글 기간 프리셋 ID',
    value: '기간(주)',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
