import {UiHomeContentUserProductionVerboseDomain} from "../../domain/ui/UiHomeContentUserProductionVerboseDomain"
import {UiHomeContentUserProductionId} from "../../id/ui/UiHomeContentUserProductionId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 홈 와글와글 콘텐츠 상세
 */
export class UiHomeContentUserProductionVerboseValueObject implements VerboseValueObject, UiHomeContentUserProductionVerboseDomain {
    /**
     * 기본키
     */
    id: UiHomeContentUserProductionId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * URL
     */
    url: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof UiHomeContentUserProductionVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.displayOrder = Number(o.displayOrder)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.creator = new UserValueObject(o.creator)
    }
}
