import {Route, Routes} from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import ReasonRouter from "./reason/ReasonRouter";
import {RouterProps} from "../../RouterProps";

export default function ReportRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/:contentReportId'} element={<Detail {...props} />} />
            <Route path={'/reasons/*'} element={<ReasonRouter {...props} />} />
        </Routes>
    </>
}