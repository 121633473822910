import {ContentUserProductionScriptValueObject} from "../../../../data/value-object/content/ContentUserProductionScriptValueObject";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    ContentUserProductionScriptSearchOption
} from "../../../../data/search-option/content/ContentUserProductionScriptSearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {ContentUserProductionScriptIO} from "../../../../io/ContentIO";
import {ContentUserProductionScriptDescription} from "../../../../data/description/content/ContentUserProductionScriptDescription";
import {ContentUserProductionScriptDescriptor} from "../../../../data/descriptor/content/ContentUserProductionScriptDescriptor";
import ListOption from "../../../ListOption";
import {
    ContentUserProductionScriptSearchOptionDescription
} from "../../../../data/description/content/ContentUserProductionScriptSearchOptionDescription";
import {
    applyContentUserProductionScriptSearchOption,
    ContentUserProductionScriptSearchOptionField
} from "../../../search-option-field/content/ContentUserProductionScriptSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentUserProductionScriptValueObject[]>([])
    const option = useStatePair<ContentUserProductionScriptSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentUserProductionScriptIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentUserProductionScriptDescription}
                descriptor={ContentUserProductionScriptDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/userProduction/scripts/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentUserProductionScriptSearchOptionDescription}
                fields={ContentUserProductionScriptSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentUserProductionScriptSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentUserProductionScriptDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}