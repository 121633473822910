import {EnvNoticePutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvNoticePutDataTransferObjectDescription"
import {EnvNoticePutState} from "../../../data/data-transfer-object-state/env/EnvNoticePutState"
import {EnvNoticePutDataTransferObject} from "../../../data/data-transfer-object/env/EnvNoticePutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvNoticePutState
}

export const EnvNoticePutField: Record<Capitalize<keyof EnvNoticePutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Title(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvNoticePutDataTransferObjectDescription.title}>
                <PostTextareaField
                    {...props}
                    value={props.state.title.value}
                    setter={props.state.title.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    Url(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvNoticePutDataTransferObjectDescription.url}>
                <PostTextareaField
                    {...props}
                    value={props.state.url.value}
                    setter={props.state.url.setter}
                    maxLength={4000} />
            </PostFieldWrapper>
        )
    },
}

export function EnvNoticePutFields(props: PostFieldProps) {
    return <>
        <EnvNoticePutField.Title
            {...props}
            state={props.state} />
        <EnvNoticePutField.Url
            {...props}
            state={props.state} />
    </>
}
