import {ContentDetailUrlDomain} from "../../domain/content/ContentDetailUrlDomain"

/**
 * 콘텐츠 더 알아보기 URL 속성 설명
 */
export const ContentDetailUrlDescription: Record<keyof ContentDetailUrlDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    browser: '브라우저',
    url: '더 알아보기 URL',
    clickCount: '클릭 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
