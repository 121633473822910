import {ContentCommentReportVerboseDomain} from "../../domain/content/ContentCommentReportVerboseDomain"
import {ContentCommentId} from "../../id/content/ContentCommentId"
import {ContentCommentReportId} from "../../id/content/ContentCommentReportId"
import {ContentCommentReportReasonId} from "../../id/content/ContentCommentReportReasonId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentCommentReportReasonValueObject} from "./ContentCommentReportReasonValueObject"
import {ContentCommentValueObject} from "./ContentCommentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 댓글 신고 상세
 */
export class ContentCommentReportVerboseValueObject implements VerboseValueObject, ContentCommentReportVerboseDomain {
    /**
     * 기본키
     */
    id: ContentCommentReportId
    /**
     * 댓글 ID
     */
    contentCommentId: ContentCommentId
    /**
     * 댓글 신고 사유 ID
     */
    contentCommentReportReasonId: ContentCommentReportReasonId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 댓글 ID
     */
    contentComment: ContentCommentValueObject
    /**
     * 댓글 신고 사유 ID
     */
    contentCommentReportReason: ContentCommentReportReasonValueObject
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof ContentCommentReportVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.contentCommentId = BigInt(o.contentCommentId)
        this.contentCommentReportReasonId = BigInt(o.contentCommentReportReasonId)
        this.userId = BigInt(o.userId)
        this.createdAt = new Date(o.createdAt)
        this.contentComment = new ContentCommentValueObject(o.contentComment)
        this.contentCommentReportReason = new ContentCommentReportReasonValueObject(o.contentCommentReportReason)
        this.user = new UserValueObject(o.user)
    }
}
