import {StatePair, useStatePair} from "react-type-extension";
import React, {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {ContentId} from "../../../data/id/content/ContentId";
import {EnvCountryId} from "../../../data/id/env/EnvCountryId";
import {UserGender} from "../../../data/constant/user/UserGender";
import {ContentIO, ContentTargetIO} from "../../../io/ContentIO";
import {PostBigIntField, PostEnumField, PostFieldWrapper, PostFinishDialog, PostNumberField} from "../../PostField";
import {ContentDomain} from "../../../data/domain/content/ContentDomain";
import {ContentSearchOption} from "../../../data/search-option/content/ContentSearchOption";
import {searchIndex} from "../../../io/HttpClient";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import ListPageWrapper from "../../ListPageWrapper";
import {ContentDescription} from "../../../data/description/content/ContentDescription";
import {ContentDescriptor} from "../../../data/descriptor/content/ContentDescriptor";
import ListOption from "../../ListOption";
import {ContentSearchOptionDescription} from "../../../data/description/content/ContentSearchOptionDescription";
import {
    applyContentSearchOption,
    ContentSearchOptionField
} from "../../search-option-field/content/ContentSearchOptionField";
import ListOrder from "../../ListOrder";
import {EnvCountryDomain} from "../../../data/domain/env/EnvCountryDomain";
import {EnvCountrySearchOption} from "../../../data/search-option/env/EnvCountrySearchOption";
import {EnvCountryIO} from "../../../io/EnvIO";
import {EnvCountryDescription} from "../../../data/description/env/EnvCountryDescription";
import {EnvCountryDescriptor} from "../../../data/descriptor/env/EnvCountryDescriptor";
import {EnvCountrySearchOptionDescription} from "../../../data/description/env/EnvCountrySearchOptionDescription";
import {
    applyEnvCountrySearchOption,
    EnvCountrySearchOptionField
} from "../../search-option-field/env/EnvCountrySearchOptionField";
import {RouterProps} from "../../RouterProps";

enum Phase {
    Waiting,
    Posting,
    Finished,
    ContentList,
    ContentOption,
    ContentOrder,
    EnvCountryList,
    EnvCountryOption,
    EnvCountryOrder
}

export default function Edit(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const contentId = useStatePair<ContentId>(0n)
    const envCountryId = useStatePair<EnvCountryId | undefined>(undefined)
    const userGender = useStatePair<UserGender | undefined>(undefined)
    const userBirthYearMin = useStatePair<number | undefined>(undefined)
    const userBirthYearMax = useStatePair<number | undefined>(undefined)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        ContentTargetIO
            .post({
                contentId: contentId.value,
                envCountryId: envCountryId.value,
                userGender: userGender.value,
                userBirthYearMin: userBirthYearMin.value,
                userBirthYearMax: userBirthYearMax.value,
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [contentId.value, envCountryId.value, userGender.value, userBirthYearMin.value, userBirthYearMax.value])

    return <>
        <div className={props.styles.column16}>
            {(phase.value === Phase.Waiting || phase.value === Phase.Posting || phase.value === Phase.Finished) && <>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={'콘텐츠 ID'}
                    showSearch
                    onSearchClick={() => phase.setter(Phase.ContentList)}>
                    <PostBigIntField
                        {...props}
                        value={contentId.value}
                        setter={contentId.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'국가 ID'}
                    showSearch
                    onSearchClick={() => phase.setter(Phase.EnvCountryList)}
                    showUnusedCheckbox
                    isUnusedCheckboxChecked={envCountryId.value === undefined}
                    onUnusedCheckboxChange={checked => envCountryId.setter(checked ? undefined : 0n)}>
                    <PostBigIntField
                        {...props}
                        disabled={envCountryId.value === undefined}
                        value={envCountryId.value}
                        setter={envCountryId.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'성별'}
                    showUnusedCheckbox
                    isUnusedCheckboxChecked={userGender.value === undefined}
                    onUnusedCheckboxChange={checked => userGender.setter(checked ? undefined : UserGender.Male)}>
                    <PostEnumField
                        {...props}
                        disabled={userGender.value === undefined}
                        value={userGender.value}
                        setter={userGender.setter}
                        selections={UserGender.selections} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'출생년도 최소'}
                    showUnusedCheckbox
                    isUnusedCheckboxChecked={userBirthYearMin.value === undefined}
                    onUnusedCheckboxChange={checked => userBirthYearMin.setter(checked ? undefined : 0)}>
                    <PostNumberField
                        {...props}
                        disabled={userBirthYearMin.value === undefined}
                        value={userBirthYearMin.value}
                        setter={userBirthYearMin.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'출생년도 최대'}
                    showUnusedCheckbox
                    isUnusedCheckboxChecked={userBirthYearMax.value === undefined}
                    onUnusedCheckboxChange={checked => userBirthYearMax.setter(checked ? undefined : 0)}>
                    <PostNumberField
                        {...props}
                        disabled={userBirthYearMax.value === undefined}
                        value={userBirthYearMax.value}
                        setter={userBirthYearMax.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </>}
            <ContentSearch
                {...props}
                phase={phase}
                contentId={contentId} />
            <EnvCountrySearch
                {...props}
                phase={phase}
                envCountryId={envCountryId} />
            <PostFinishDialog
                open={phase.value === Phase.Finished}
                negativeHref={'/contents/targets'} />
        </div>
    </>
}

type ContentSearchProps = RouterProps & {
    phase: StatePair<Phase>
    contentId: StatePair<ContentId>
}

function ContentSearch(props: ContentSearchProps) {
    const items = useStatePair<ContentDomain[]>([])
    const option = useStatePair<ContentSearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.ContentList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>콘텐츠 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={ContentIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={ContentDescription}
                    descriptor={ContentDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.ContentOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.ContentOrder)}
                    onItemClick={item => {
                        props.contentId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.ContentOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)} />
                    <Title3>콘텐츠 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={ContentSearchOptionDescription}
                    fields={ContentSearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.ContentList)}
                    onApplyClick={(key, value) => {
                        applyContentSearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.ContentOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentDescription}
                onBackClick={() => props.phase.setter(Phase.ContentList)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}

type EnvCountrySearchProps = RouterProps & {
    phase: StatePair<Phase>
    envCountryId: StatePair<EnvCountryId | undefined>
}

function EnvCountrySearch(props: EnvCountrySearchProps) {
    const items = useStatePair<EnvCountryDomain[]>([])
    const option = useStatePair<EnvCountrySearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.EnvCountryList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>국가 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={EnvCountryIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={EnvCountryDescription}
                    descriptor={EnvCountryDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.EnvCountryOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.EnvCountryOrder)}
                    onItemClick={item => {
                        props.envCountryId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.EnvCountryOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)} />
                    <Title3>국가 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={EnvCountrySearchOptionDescription}
                    fields={EnvCountrySearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.EnvCountryList)}
                    onApplyClick={(key, value) => {
                        applyEnvCountrySearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.EnvCountryOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={EnvCountryDescription}
                onBackClick={() => props.phase.setter(Phase.EnvCountryList)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}