import {EnvFaqItemDomain} from "../../domain/env/EnvFaqItemDomain"

/**
 * 자주 묻는 질문 항목 속성 설명
 */
export const EnvFaqItemDescription: Record<keyof EnvFaqItemDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    envFaqId: '자주 묻는 질문 ID',
    title: '제목',
    body: '내용',
    displayOrder: '표시 순서',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
