import {ContentCommentReportReasonIO} from "../../../../../io/ContentIO";
import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField,
    PostNumberField
} from "../../../../PostField";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const body = useStatePair<string>('')
    const displayOrder = useStatePair<number>(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        ContentCommentReportReasonIO
            .post({
                body: body.value,
                displayOrder: displayOrder.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [body.value, displayOrder.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={'사유'}>
                    <PostInputField
                        {...props}
                        value={body.value}
                        setter={body.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={'표시 우선 순위'}>
                    <PostNumberField
                        {...props}
                        value={displayOrder.value}
                        setter={displayOrder.setter}/>
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/contents/comments/reports/reasons'}/>
    </>
}