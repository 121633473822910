import {EnvNoticeSearchOptionDescription} from "../../../data/description/env/EnvNoticeSearchOptionDescription"
import {EnvNoticeSearchOption} from "../../../data/search-option/env/EnvNoticeSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function EnvNoticeSearchOptionField(props: ListOptionFieldProps<EnvNoticeSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.titleEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.titleNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.titleMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.titleMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.titleLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "titleNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.titleNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "urlEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.urlEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "urlNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.urlNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "urlMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.urlMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "urlMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.urlMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "urlLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.urlLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "urlNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.urlNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.concealedAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.concealedAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.concealedAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.concealedAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "concealedAtIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={EnvNoticeSearchOptionDescription.concealedAtIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyEnvNoticeSearchOption(key: keyof EnvNoticeSearchOption, value: string, into: StatePair<EnvNoticeSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "titleEq":
            into.setter({
                ...into.value,
                titleEq: String(value)
            })
            break
        case "titleNeq":
            into.setter({
                ...into.value,
                titleNeq: String(value)
            })
            break
        case "titleMin":
            into.setter({
                ...into.value,
                titleMin: String(value)
            })
            break
        case "titleMax":
            into.setter({
                ...into.value,
                titleMax: String(value)
            })
            break
        case "titleLike":
            into.setter({
                ...into.value,
                titleLike: String(value)
            })
            break
        case "titleNotLike":
            into.setter({
                ...into.value,
                titleNotLike: String(value)
            })
            break
        case "urlEq":
            into.setter({
                ...into.value,
                urlEq: String(value)
            })
            break
        case "urlNeq":
            into.setter({
                ...into.value,
                urlNeq: String(value)
            })
            break
        case "urlMin":
            into.setter({
                ...into.value,
                urlMin: String(value)
            })
            break
        case "urlMax":
            into.setter({
                ...into.value,
                urlMax: String(value)
            })
            break
        case "urlLike":
            into.setter({
                ...into.value,
                urlLike: String(value)
            })
            break
        case "urlNotLike":
            into.setter({
                ...into.value,
                urlNotLike: String(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
        case "concealedAtEq":
            into.setter({
                ...into.value,
                concealedAtEq: new Date(value)
            })
            break
        case "concealedAtNeq":
            into.setter({
                ...into.value,
                concealedAtNeq: new Date(value)
            })
            break
        case "concealedAtMin":
            into.setter({
                ...into.value,
                concealedAtMin: new Date(value)
            })
            break
        case "concealedAtMax":
            into.setter({
                ...into.value,
                concealedAtMax: new Date(value)
            })
            break
        case "concealedAtIsNull":
            into.setter({
                ...into.value,
                concealedAtIsNull: Boolean(value)
            })
            break
    }
}
