import {Route, Routes} from "react-router-dom";
import React from "react";
import ReasonRouter from "./reason/ReasonRouter";
import {RouterProps} from "../../RouterProps";
import List from "./List";
import Post from "./Post";
import Detail from "./Detail";
import Edit from "./Edit";

export default function BlockRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/post'} element={<Post {...props} />} />
            <Route path={'/:userBlockId'} element={<Detail {...props} />} />
            <Route path={'/:userBlockId/edit'} element={<Edit {...props} />} />
            <Route path={'/reasons/*'} element={<ReasonRouter {...props} />} />
        </Routes>
    </>
}