import {BaseHttpClient, BaseHttpGetCurrent} from "./HttpClient";
import {EnvAgreementModificationId} from "../data/id/env/EnvAgreementModificationId";
import {EnvAgreementModificationValueObject} from "../data/value-object/env/EnvAgreementModificationValueObject";
import {
    EnvAgreementModificationVerboseValueObject
} from "../data/value-object/env/EnvAgreementModificationVerboseValueObject";
import {EnvAgreementModificationSearchOption} from "../data/search-option/env/EnvAgreementModificationSearchOption";
import {EnvAgreementId} from "../data/id/env/EnvAgreementId";
import {EnvAgreementPostDataTransferObject} from "../data/data-transfer-object/env/EnvAgreementPostDataTransferObject";
import {EnvAgreementPutDataTransferObject} from "../data/data-transfer-object/env/EnvAgreementPutDataTransferObject";
import {EnvAgreementValueObject} from "../data/value-object/env/EnvAgreementValueObject";
import {EnvAgreementVerboseValueObject} from "../data/value-object/env/EnvAgreementVerboseValueObject";
import {EnvAgreementSearchOption} from "../data/search-option/env/EnvAgreementSearchOption";
import {EnvClientModificationId} from "../data/id/env/EnvClientModificationId";
import {EnvClientModificationValueObject} from "../data/value-object/env/EnvClientModificationValueObject";
import {
    EnvClientModificationVerboseValueObject
} from "../data/value-object/env/EnvClientModificationVerboseValueObject";
import {EnvClientModificationSearchOption} from "../data/search-option/env/EnvClientModificationSearchOption";
import {EnvClientId} from "../data/id/env/EnvClientId";
import {EnvClientPostDataTransferObject} from "../data/data-transfer-object/env/EnvClientPostDataTransferObject";
import {EnvClientPutDataTransferObject} from "../data/data-transfer-object/env/EnvClientPutDataTransferObject";
import {EnvClientValueObject} from "../data/value-object/env/EnvClientValueObject";
import {EnvClientVerboseValueObject} from "../data/value-object/env/EnvClientVerboseValueObject";
import {EnvClientSearchOption} from "../data/search-option/env/EnvClientSearchOption";
import {EnvConstructionModificationId} from "../data/id/env/EnvConstructionModificationId";
import {EnvConstructionModificationValueObject} from "../data/value-object/env/EnvConstructionModificationValueObject";
import {
    EnvConstructionModificationVerboseValueObject
} from "../data/value-object/env/EnvConstructionModificationVerboseValueObject";
import {
    EnvConstructionModificationSearchOption
} from "../data/search-option/env/EnvConstructionModificationSearchOption";
import {EnvConstructionId} from "../data/id/env/EnvConstructionId";
import {
    EnvConstructionPostDataTransferObject
} from "../data/data-transfer-object/env/EnvConstructionPostDataTransferObject";
import {
    EnvConstructionPutDataTransferObject
} from "../data/data-transfer-object/env/EnvConstructionPutDataTransferObject";
import {EnvConstructionValueObject} from "../data/value-object/env/EnvConstructionValueObject";
import {EnvConstructionVerboseValueObject} from "../data/value-object/env/EnvConstructionVerboseValueObject";
import {EnvConstructionSearchOption} from "../data/search-option/env/EnvConstructionSearchOption";
import {EnvCountryModificationId} from "../data/id/env/EnvCountryModificationId";
import {EnvCountryModificationValueObject} from "../data/value-object/env/EnvCountryModificationValueObject";
import {
    EnvCountryModificationVerboseValueObject
} from "../data/value-object/env/EnvCountryModificationVerboseValueObject";
import {EnvCountryModificationSearchOption} from "../data/search-option/env/EnvCountryModificationSearchOption";
import {EnvCountryId} from "../data/id/env/EnvCountryId";
import {EnvCountryPostDataTransferObject} from "../data/data-transfer-object/env/EnvCountryPostDataTransferObject";
import {EnvCountryPutDataTransferObject} from "../data/data-transfer-object/env/EnvCountryPutDataTransferObject";
import {EnvCountryValueObject} from "../data/value-object/env/EnvCountryValueObject";
import {EnvCountryVerboseValueObject} from "../data/value-object/env/EnvCountryVerboseValueObject";
import {EnvCountrySearchOption} from "../data/search-option/env/EnvCountrySearchOption";
import {EnvFaqItemModificationId} from "../data/id/env/EnvFaqItemModificationId";
import {EnvFaqItemModificationValueObject} from "../data/value-object/env/EnvFaqItemModificationValueObject";
import {
    EnvFaqItemModificationVerboseValueObject
} from "../data/value-object/env/EnvFaqItemModificationVerboseValueObject";
import {EnvFaqItemModificationSearchOption} from "../data/search-option/env/EnvFaqItemModificationSearchOption";
import {EnvFaqItemId} from "../data/id/env/EnvFaqItemId";
import {EnvFaqItemPostDataTransferObject} from "../data/data-transfer-object/env/EnvFaqItemPostDataTransferObject";
import {EnvFaqItemPutDataTransferObject} from "../data/data-transfer-object/env/EnvFaqItemPutDataTransferObject";
import {EnvFaqItemValueObject} from "../data/value-object/env/EnvFaqItemValueObject";
import {EnvFaqItemVerboseValueObject} from "../data/value-object/env/EnvFaqItemVerboseValueObject";
import {EnvFaqItemSearchOption} from "../data/search-option/env/EnvFaqItemSearchOption";
import {EnvFaqModificationId} from "../data/id/env/EnvFaqModificationId";
import {EnvFaqModificationValueObject} from "../data/value-object/env/EnvFaqModificationValueObject";
import {EnvFaqModificationVerboseValueObject} from "../data/value-object/env/EnvFaqModificationVerboseValueObject";
import {EnvFaqModificationSearchOption} from "../data/search-option/env/EnvFaqModificationSearchOption";
import {EnvFaqId} from "../data/id/env/EnvFaqId";
import {EnvFaqPostDataTransferObject} from "../data/data-transfer-object/env/EnvFaqPostDataTransferObject";
import {EnvFaqPutDataTransferObject} from "../data/data-transfer-object/env/EnvFaqPutDataTransferObject";
import {EnvFaqValueObject} from "../data/value-object/env/EnvFaqValueObject";
import {EnvFaqVerboseValueObject} from "../data/value-object/env/EnvFaqVerboseValueObject";
import {EnvFaqSearchOption} from "../data/search-option/env/EnvFaqSearchOption";
import {EnvPolicyModificationId} from "../data/id/env/EnvPolicyModificationId";
import {EnvPolicyModificationValueObject} from "../data/value-object/env/EnvPolicyModificationValueObject";
import {
    EnvPolicyModificationVerboseValueObject
} from "../data/value-object/env/EnvPolicyModificationVerboseValueObject";
import {EnvPolicyModificationSearchOption} from "../data/search-option/env/EnvPolicyModificationSearchOption";
import {EnvPolicyId} from "../data/id/env/EnvPolicyId";
import {EnvPolicyPostDataTransferObject} from "../data/data-transfer-object/env/EnvPolicyPostDataTransferObject";
import {EnvPolicyPutDataTransferObject} from "../data/data-transfer-object/env/EnvPolicyPutDataTransferObject";
import {EnvPolicyValueObject} from "../data/value-object/env/EnvPolicyValueObject";
import {EnvPolicyVerboseValueObject} from "../data/value-object/env/EnvPolicyVerboseValueObject";
import {EnvPolicySearchOption} from "../data/search-option/env/EnvPolicySearchOption";
import {EnvStorageKeyId} from "../data/id/env/EnvStorageKeyId";
import {
    EnvStorageKeyPostDataTransferObject
} from "../data/data-transfer-object/env/EnvStorageKeyPostDataTransferObject";
import {EnvStorageKeyValueObject} from "../data/value-object/env/EnvStorageKeyValueObject";
import {EnvStorageKeyVerboseValueObject} from "../data/value-object/env/EnvStorageKeyVerboseValueObject";
import {EnvStorageKeySearchOption} from "../data/search-option/env/EnvStorageKeySearchOption";
import {HttpMethod} from "ts-axios-client-extension";
import {EnvPhoneBlockId} from "../data/id/env/EnvPhoneBlockId";
import {
    EnvPhoneBlockPostDataTransferObject
} from "../data/data-transfer-object/env/EnvPhoneBlockPostDataTransferObject";
import {EnvPhoneBlockPutDataTransferObject} from "../data/data-transfer-object/env/EnvPhoneBlockPutDataTransferObject";
import {EnvPhoneBlockValueObject} from "../data/value-object/env/EnvPhoneBlockValueObject";
import {EnvPhoneBlockVerboseValueObject} from "../data/value-object/env/EnvPhoneBlockVerboseValueObject";
import {EnvPhoneBlockSearchOption} from "../data/search-option/env/EnvPhoneBlockSearchOption";
import {EnvPhoneBlockModificationId} from "../data/id/env/EnvPhoneBlockModificationId";
import {EnvPhoneBlockModificationValueObject} from "../data/value-object/env/EnvPhoneBlockModificationValueObject";
import {
    EnvPhoneBlockModificationVerboseValueObject
} from "../data/value-object/env/EnvPhoneBlockModificationVerboseValueObject";
import {EnvPhoneBlockModificationSearchOption} from "../data/search-option/env/EnvPhoneBlockModificationSearchOption";
import {EnvBankId} from "../data/id/env/EnvBankId";
import {EnvBankPostDataTransferObject} from "../data/data-transfer-object/env/EnvBankPostDataTransferObject";
import {EnvBankPutDataTransferObject} from "../data/data-transfer-object/env/EnvBankPutDataTransferObject";
import {EnvBankValueObject} from "../data/value-object/env/EnvBankValueObject";
import {EnvBankVerboseValueObject} from "../data/value-object/env/EnvBankVerboseValueObject";
import {EnvBankSearchOption} from "../data/search-option/env/EnvBankSearchOption";
import {EnvEventId} from "../data/id/env/EnvEventId";
import {EnvEventPostDataTransferObject} from "../data/data-transfer-object/env/EnvEventPostDataTransferObject";
import {EnvEventPutDataTransferObject} from "../data/data-transfer-object/env/EnvEventPutDataTransferObject";
import {EnvEventValueObject} from "../data/value-object/env/EnvEventValueObject";
import {EnvEventVerboseValueObject} from "../data/value-object/env/EnvEventVerboseValueObject";
import {EnvEventSearchOption} from "../data/search-option/env/EnvEventSearchOption";
import {EnvEventModificationId} from "../data/id/env/EnvEventModificationId";
import {EnvEventModificationValueObject} from "../data/value-object/env/EnvEventModificationValueObject";
import {EnvEventModificationVerboseValueObject} from "../data/value-object/env/EnvEventModificationVerboseValueObject";
import {EnvEventModificationSearchOption} from "../data/search-option/env/EnvEventModificationSearchOption";
import {EnvNoticeId} from "../data/id/env/EnvNoticeId";
import {EnvNoticePostDataTransferObject} from "../data/data-transfer-object/env/EnvNoticePostDataTransferObject";
import {EnvNoticePutDataTransferObject} from "../data/data-transfer-object/env/EnvNoticePutDataTransferObject";
import {EnvNoticeValueObject} from "../data/value-object/env/EnvNoticeValueObject";
import {EnvNoticeVerboseValueObject} from "../data/value-object/env/EnvNoticeVerboseValueObject";
import {EnvNoticeSearchOption} from "../data/search-option/env/EnvNoticeSearchOption";
import {EnvNoticeModificationId} from "../data/id/env/EnvNoticeModificationId";
import {EnvNoticeModificationValueObject} from "../data/value-object/env/EnvNoticeModificationValueObject";
import {
    EnvNoticeModificationVerboseValueObject
} from "../data/value-object/env/EnvNoticeModificationVerboseValueObject";
import {EnvNoticeModificationSearchOption} from "../data/search-option/env/EnvNoticeModificationSearchOption";

export const EnvAgreementIO = new BaseHttpClient<
    EnvAgreementId,
    EnvAgreementPostDataTransferObject,
    EnvAgreementPutDataTransferObject,
    EnvAgreementValueObject,
    EnvAgreementVerboseValueObject,
    EnvAgreementSearchOption
>(
    '/env/agreements',
    o => new EnvAgreementValueObject(o),
    o => new EnvAgreementVerboseValueObject(o)
)

export const EnvAgreementModificationIO = new BaseHttpClient<
    EnvAgreementModificationId,
    never,
    never,
    EnvAgreementModificationValueObject,
    EnvAgreementModificationVerboseValueObject,
    EnvAgreementModificationSearchOption
>(
    '/env/agreements/modifications',
    o => new EnvAgreementModificationValueObject(o),
    o => new EnvAgreementModificationVerboseValueObject(o)
)

export const EnvBankIO = new BaseHttpClient<
    EnvBankId,
    EnvBankPostDataTransferObject,
    EnvBankPutDataTransferObject,
    EnvBankValueObject,
    EnvBankVerboseValueObject,
    EnvBankSearchOption
>(
    '/env/banks',
    o => new EnvBankValueObject(o),
    o => new EnvBankVerboseValueObject(o)
)

export const EnvClientModificationIO = new BaseHttpClient<
    EnvClientModificationId,
    never,
    never,
    EnvClientModificationValueObject,
    EnvClientModificationVerboseValueObject,
    EnvClientModificationSearchOption
>(
    '/env/clients/modifications',
    o => new EnvClientModificationValueObject(o),
    o => new EnvClientModificationVerboseValueObject(o)
)

export const EnvClientIO = new BaseHttpClient<
    EnvClientId,
    EnvClientPostDataTransferObject,
    EnvClientPutDataTransferObject,
    EnvClientValueObject,
    EnvClientVerboseValueObject,
    EnvClientSearchOption
>(
    '/env/clients',
    o => new EnvClientValueObject(o),
    o => new EnvClientVerboseValueObject(o)
)

export const EnvConstructionModificationIO = new BaseHttpClient<
    EnvConstructionModificationId,
    never,
    never,
    EnvConstructionModificationValueObject,
    EnvConstructionModificationVerboseValueObject,
    EnvConstructionModificationSearchOption
>(
    '/env/constructions/modifications',
    o => new EnvConstructionModificationValueObject(o),
    o => new EnvConstructionModificationVerboseValueObject(o)
)

export const EnvConstructionIO = new BaseHttpClient<
    EnvConstructionId,
    EnvConstructionPostDataTransferObject,
    EnvConstructionPutDataTransferObject,
    EnvConstructionValueObject,
    EnvConstructionVerboseValueObject,
    EnvConstructionSearchOption
>(
    '/env/constructions',
    o => new EnvConstructionValueObject(o),
    o => new EnvConstructionVerboseValueObject(o)
)

export const EnvCountryModificationIO = new BaseHttpClient<
    EnvCountryModificationId,
    never,
    never,
    EnvCountryModificationValueObject,
    EnvCountryModificationVerboseValueObject,
    EnvCountryModificationSearchOption
>(
    '/env/countries/modifications',
    o => new EnvCountryModificationValueObject(o),
    o => new EnvCountryModificationVerboseValueObject(o)
)

export const EnvCountryIO = new BaseHttpClient<
    EnvCountryId,
    EnvCountryPostDataTransferObject,
    EnvCountryPutDataTransferObject,
    EnvCountryValueObject,
    EnvCountryVerboseValueObject,
    EnvCountrySearchOption
>(
    '/env/countries',
    o => new EnvCountryValueObject(o),
    o => new EnvCountryVerboseValueObject(o)
)

export const EnvEventIO = new BaseHttpClient<
    EnvEventId,
    EnvEventPostDataTransferObject,
    EnvEventPutDataTransferObject,
    EnvEventValueObject,
    EnvEventVerboseValueObject,
    EnvEventSearchOption
>(
    '/env/events',
    o => new EnvEventValueObject(o),
    o => new EnvEventVerboseValueObject(o)
)

export const EnvEventModificationIO = new BaseHttpClient<
    EnvEventModificationId,
    never,
    never,
    EnvEventModificationValueObject,
    EnvEventModificationVerboseValueObject,
    EnvEventModificationSearchOption
>(
    '/env/events/modifications',
    o => new EnvEventModificationValueObject(o),
    o => new EnvEventModificationVerboseValueObject(o)
)

export const EnvFaqItemModificationIO = new BaseHttpClient<
    EnvFaqItemModificationId,
    never,
    never,
    EnvFaqItemModificationValueObject,
    EnvFaqItemModificationVerboseValueObject,
    EnvFaqItemModificationSearchOption
>(
    '/env/faqs/items/modifications',
    o => new EnvFaqItemModificationValueObject(o),
    o => new EnvFaqItemModificationVerboseValueObject(o)
)

export const EnvFaqItemIO = new BaseHttpClient<
    EnvFaqItemId,
    EnvFaqItemPostDataTransferObject,
    EnvFaqItemPutDataTransferObject,
    EnvFaqItemValueObject,
    EnvFaqItemVerboseValueObject,
    EnvFaqItemSearchOption
>(
    '/env/faqs/items',
    o => new EnvFaqItemValueObject(o),
    o => new EnvFaqItemVerboseValueObject(o)
)

export const EnvFaqModificationIO = new BaseHttpClient<
    EnvFaqModificationId,
    never,
    never,
    EnvFaqModificationValueObject,
    EnvFaqModificationVerboseValueObject,
    EnvFaqModificationSearchOption
>(
    '/env/faqs/modifications',
    o => new EnvFaqModificationValueObject(o),
    o => new EnvFaqModificationVerboseValueObject(o)
)

export const EnvFaqIO = new BaseHttpClient<
    EnvFaqId,
    EnvFaqPostDataTransferObject,
    EnvFaqPutDataTransferObject,
    EnvFaqValueObject,
    EnvFaqVerboseValueObject,
    EnvFaqSearchOption
>(
    '/env/faqs',
    o => new EnvFaqValueObject(o),
    o => new EnvFaqVerboseValueObject(o)
)

export const EnvNoticeIO = new BaseHttpClient<
    EnvNoticeId,
    EnvNoticePostDataTransferObject,
    EnvNoticePutDataTransferObject,
    EnvNoticeValueObject,
    EnvNoticeVerboseValueObject,
    EnvNoticeSearchOption
>(
    '/env/notices',
    o => new EnvNoticeValueObject(o),
    o => new EnvNoticeVerboseValueObject(o)
)

export const EnvNoticeModificationIO = new BaseHttpClient<
    EnvNoticeModificationId,
    never,
    never,
    EnvNoticeModificationValueObject,
    EnvNoticeModificationVerboseValueObject,
    EnvNoticeModificationSearchOption
>(
    '/env/notices/modifications',
    o => new EnvNoticeModificationValueObject(o),
    o => new EnvNoticeModificationVerboseValueObject(o)
)

export const EnvPhoneBlockIO = new BaseHttpClient<
    EnvPhoneBlockId,
    EnvPhoneBlockPostDataTransferObject,
    EnvPhoneBlockPutDataTransferObject,
    EnvPhoneBlockValueObject,
    EnvPhoneBlockVerboseValueObject,
    EnvPhoneBlockSearchOption
>(
    '/env/phoneBlocks',
    o => new EnvPhoneBlockValueObject(o),
    o => new EnvPhoneBlockVerboseValueObject(o)
)

export const EnvPhoneBlockModificationIO = new BaseHttpClient<
    EnvPhoneBlockModificationId,
    never,
    never,
    EnvPhoneBlockModificationValueObject,
    EnvPhoneBlockModificationVerboseValueObject,
    EnvPhoneBlockModificationSearchOption
>(
    '/env/phoneBlocks/modifications',
    o => new EnvPhoneBlockModificationValueObject(o),
    o => new EnvPhoneBlockModificationVerboseValueObject(o)
)

export const EnvPolicyModificationIO = new BaseHttpClient<
    EnvPolicyModificationId,
    never,
    never,
    EnvPolicyModificationValueObject,
    EnvPolicyModificationVerboseValueObject,
    EnvPolicyModificationSearchOption
>(
    '/env/policies/modifications',
    o => new EnvPolicyModificationValueObject(o),
    o => new EnvPolicyModificationVerboseValueObject(o)
)

export const EnvPolicyIO = new (class _ extends BaseHttpClient<
    EnvPolicyId,
    EnvPolicyPostDataTransferObject,
    EnvPolicyPutDataTransferObject,
    EnvPolicyValueObject,
    EnvPolicyVerboseValueObject,
    EnvPolicySearchOption
> {
    getLatest = async () => (await this.httpClient
        .prepare({ path: '/env/policies/latest', method: HttpMethod.GET })
        .bodyAsObject(this.voCtor)
    ).result
    getLatestVerbose = async () => (await this.httpClient
            .prepare({ path: '/env/policies/latest/verbose', method: HttpMethod.GET })
            .bodyAsObject(this.vvoCtor)
    ).result
})(
    '/env/policies',
    o => new EnvPolicyValueObject(o),
    o => new EnvPolicyVerboseValueObject(o)
)

export const EnvStorageKeyIO = new (class _ extends BaseHttpClient<
    EnvStorageKeyId,
    EnvStorageKeyPostDataTransferObject,
    never,
    EnvStorageKeyValueObject,
    EnvStorageKeyVerboseValueObject,
    EnvStorageKeySearchOption
> implements BaseHttpGetCurrent<EnvStorageKeyValueObject, EnvStorageKeyVerboseValueObject> {
    getCurrent = async () => (
        await this.httpClient
            .prepare({ path: this.path + '/current', method: HttpMethod.GET })
            .bodyAsObject(this.voCtor)
    ).result
    getCurrentVerbose = async () => (
        await this.httpClient
            .prepare({ path: this.path + '/current/verbose', method: HttpMethod.GET })
            .bodyAsObject(this.vvoCtor)
    ).result
})(
    '/env/storageKeys',
    o => new EnvStorageKeyValueObject(o),
    o => new EnvStorageKeyVerboseValueObject(o)
)