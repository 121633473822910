import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {ContentPolicyValueObject} from "../../../data/value-object/content/ContentPolicyValueObject";
import {useEffect} from "react";
import {ContentPolicyIO} from "../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";
import {ContentPolicyDomain} from "../../../data/domain/content/ContentPolicyDomain";
import {ContentPolicyDescription} from "../../../data/description/content/ContentPolicyDescription";
import {ContentPolicyDescriptor} from "../../../data/descriptor/content/ContentPolicyDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentPolicyId: string }>()
    const item = useStatePair<ContentPolicyValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentPolicyId !== undefined) {
            ContentPolicyIO
                .getById(BigInt(params.contentPolicyId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentPolicyValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentPolicyIO}
                description={ContentPolicyDescription}
                properties={props => <PropertyBody {...props} />} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentPolicyDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "contentPageSize":
        case "contentReportAutoHiddenCount":
        case "contentCommentPageSize":
        case "contentCommentWithAudioSolarAmount":
        case "contentCommentReportAutoHiddenCount":
        case "contentCommentThresholdMinutes":
        case "contentCommentThresholdCount":
        case "contentCommentLikePageSize":
        case "contentCommentReportPageSize":
        case "contentCommentReportReasonPageSize":
        case "contentDetailUrlPageSize":
        case "contentDetailUrlClickPageSize":
        case "contentDetailUrlClickExp":
        case "contentParticipationPageSize":
        case "contentDailyParticipationExp":
        case "contentDailyParticipationPassExp":
        case "contentRepeatParticipationThresholdMinutes":
        case "contentRepeatParticipationThresholdCount":
        case "contentRepeatParticipationExp":
        case "contentRepeatParticipationPassExp":
        case "contentM10nParticipationThresholdMinutes":
        case "contentM10nParticipationThresholdCount":
        case "contentM10nParticipationExp":
        case "contentM10nParticipationPassExp":
        case "contentUserProductionParticipationThresholdMinutes":
        case "contentUserProductionParticipationThresholdCount":
        case "contentUserProductionParticipationExp":
        case "contentUserProductionParticipationPassExp":
        case "contentUserProductionPresetPageSize":
        case "contentUserProductionMinimumAssetLunarAmount":
        case "contentUserProductionMinimumUserLevelId":
        case "contentUserProductionMinimumAccuracy":
        case "contentUserProductionPassAccuracy":
        case "contentUserProductionPassHighAccuracy":
        case "contentUserProductionPassSolarAmountHighAccuracy":
        case "contentUserProductionStarAmount":
        case "contentUserProductionDefaultDetailUrl":
        case "contentUserProductionDefaultDetailUrlBrowser":
        case "contentUserProductionDefaultResultUrl":
        case "contentUserProductionDefaultResultUrlBrowser":
        case "contentUserProductionScriptPageSize":
        case "contentLikePageSize":
        case "contentReportDailyThreshold":
        case "contentPolicyPageSize":
        case "contentQueryPageSize":
        case "contentQueryPopularDurationMinutes":
        case "contentQueryPopularPageSize":
        case "contentReportReasonPageSize":
        case "contentReportPageSize":
        case "contentResultUrlPageSize":
        case "contentResultUrlClickPageSize":
        case "contentResultUrlClickExp":
        case "contentReviewPageSize":
        case "contentTargetPageSize":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{ContentPolicyDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentPolicyDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}