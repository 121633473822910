import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import UsageRouter from "./usage/UsageRouter";
import {Button} from "@fluentui/react-components";
import {ChevronRight24Filled} from "@fluentui/react-icons";
import ExchangeOptionRouter from "./exchangeOption/ExchangeOptionRouter";
import ChargeRouter from "./charge/ChargeRouter";

export default function StarRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/charges/*'} element={<ChargeRouter {...props} />} />
            <Route path={'/usages/*'} element={<UsageRouter {...props} />} />
            <Route path={'/exchangeOptions/*'} element={<ExchangeOptionRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/stars/exchangeOptions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                스타 교환 옵션
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/assets/stars/usages'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                스타 사용
            </Button>
        </div>
    </>
}