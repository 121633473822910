import {Route, Routes} from "react-router-dom";
import HomeRouter from "./home/HomeRouter";
import {RouterProps} from "../RouterProps";
import PolicyRouter from "./policy/PolicyRouter";
import {RouterNavigationButton} from "../RouterViews";
import OnboardingRouter from "./onboarding/OnboardingRouter";

export default function UiRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/home/*'} element={<HomeRouter {...props} />} />
            <Route path={'/onboardings/*'} element={<OnboardingRouter {...props} />} />
            <Route path={'/policies/*'} element={<PolicyRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <RouterNavigationButton
                {...props}
                href={'/ui/home/contents/suggestions'}>
                홈 추천 콘텐츠
            </RouterNavigationButton>
            <RouterNavigationButton
                {...props}
                href={'/ui/home/contents/userProductions'}>
                홈 와글와글 콘텐츠
            </RouterNavigationButton>
            <RouterNavigationButton
                {...props}
                href={'/ui/home/notices'}>
                홈 공지사항
            </RouterNavigationButton>
            <RouterNavigationButton
                {...props}
                href={'/ui/onboardings'}>
                온보딩
            </RouterNavigationButton>
            <RouterNavigationButton
                {...props}
                href={'/ui/policies'}>
                UI 정책
            </RouterNavigationButton>
        </div>
    </>
}