import {useParams} from "react-router-dom";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentUserProductionScriptValueObject} from "../../../../data/value-object/content/ContentUserProductionScriptValueObject";
import {useEffect} from "react";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {ContentUserProductionScriptDescription} from "../../../../data/description/content/ContentUserProductionScriptDescription";
import {ContentUserProductionScriptIO} from "../../../../io/ContentIO";
import {ContentUserProductionScriptDomain} from "../../../../data/domain/content/ContentUserProductionScriptDomain";
import {ContentUserProductionScriptDescriptor} from "../../../../data/descriptor/content/ContentUserProductionScriptDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentUserProductionScriptId: string }>()
    const item = useStatePair<ContentUserProductionScriptValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentUserProductionScriptId !== undefined) {
            ContentUserProductionScriptIO
                .getById(BigInt(params.contentUserProductionScriptId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentUserProductionScriptValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentUserProductionScriptIO}
                description={ContentUserProductionScriptDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentUserProductionScriptDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "audioId":
        case "script":
        case "transcription":
        case "accuracy":
        case "createdAt":
            return <Body1>{ContentUserProductionScriptDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentUserProductionScriptDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}