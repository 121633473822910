import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserSessionVerboseValueObject} from "../../../data/value-object/user/UserSessionVerboseValueObject";
import {useEffect} from "react";
import {UserSessionIO} from "../../../io/UserIO";
import {Body1, Caption1Strong, Spinner, Tab, TabList} from "@fluentui/react-components";
import {UserSessionDomain} from "../../../data/domain/user/UserSessionDomain";
import {UserSessionDescriptor} from "../../../data/descriptor/user/UserSessionDescriptor";
import {mapByKey} from "../../../util/ObjectUtil";
import {UserSessionDescription} from "../../../data/description/user/UserSessionDescription";

enum Panel {
    Default,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userSessionId: string }>()
    const item = useStatePair<UserSessionVerboseValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userSessionId !== undefined) {
            UserSessionIO
                .getVerboseById(BigInt(params.userSessionId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserSessionVerboseValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
            </TabList>
            <DefaultPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserSessionDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "outbound":
        case "lastModifiedAt":
        case "createdAt":
            return <Body1>{UserSessionDescriptor[props.itemKey](props.item)}</Body1>
        case "userSignInId":
            return props.item.userSignInId !== undefined
                ? <a href={`/users/${props.item.userSignInId}`}><Body1>{UserSessionDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
    }
}

type PanelProps = PageProps & {
    panel: Panel
    children?: false | JSX.Element | JSX.Element[]
}

function DefaultPanel(props: PanelProps) {
    return <>
        {props.panel === Panel.Default && <>
            <div className={props.styles.column16}>
                {mapByKey(UserSessionDescription, key => <>
                    <div className={props.styles.column4}>
                        <Caption1Strong>{UserSessionDescription[key]}</Caption1Strong>
                        <PropertyBody
                            {...props}
                            itemKey={key}/>
                    </div>
                </>)}
                {props.children}
            </div>
        </>}
    </>
}