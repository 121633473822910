import {
    ContentCommentReportReasonValueObject
} from "../../../../../data/value-object/content/ContentCommentReportReasonValueObject";
import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {
    ContentCommentReportReasonSearchOption
} from "../../../../../data/search-option/content/ContentCommentReportReasonSearchOption";
import {SearchIndex} from "../../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../../ListPageWrapper";
import {ContentCommentReportReasonIO} from "../../../../../io/ContentIO";
import {
    ContentCommentReportReasonDescription
} from "../../../../../data/description/content/ContentCommentReportReasonDescription";
import {
    ContentCommentReportReasonDescriptor
} from "../../../../../data/descriptor/content/ContentCommentReportReasonDescriptor";
import ListOption from "../../../../ListOption";
import {
    ContentCommentReportReasonSearchOptionDescription
} from "../../../../../data/description/content/ContentCommentReportReasonSearchOptionDescription";
import {
    applyContentCommentReportReasonSearchOption,
    ContentCommentReportReasonSearchOptionField
} from "../../../../search-option-field/content/ContentCommentReportReasonSearchOptionField";
import ListOrder from "../../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<ContentCommentReportReasonValueObject[]>([])
    const option = useStatePair<ContentCommentReportReasonSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentReportReasonIO}
                items={items}
                option={option.value}
                index={index}
                description={ContentCommentReportReasonDescription}
                descriptor={ContentCommentReportReasonDescriptor}
                showPost
                onPostClick={() => document.location = '/contents/comments/reports/reasons/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/contents/comments/reports/reasons/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={ContentCommentReportReasonSearchOptionDescription}
                fields={ContentCommentReportReasonSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyContentCommentReportReasonSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={ContentCommentReportReasonDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}