import {EnvEventDomain} from "../../domain/env/EnvEventDomain"
import {EnvEventId} from "../../id/env/EnvEventId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 이벤트
 */
export class EnvEventValueObject implements ValueObject, EnvEventDomain {
    /**
     * 기본키
     */
    id: EnvEventId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 제목
     */
    title: string
    /**
     * URL
     */
    url: string
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof EnvEventDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.title = String(o.title)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
