import {UserSearchOption} from "../../search-option/user/UserSearchOption"

/**
 * 사용자 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserSearchOptionDescription: Record<keyof UserSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    authenticationUidNeq: 'Firebase Authentication UID 불일치',
    authenticationUidMin: 'Firebase Authentication UID 최소',
    authenticationUidMax: 'Firebase Authentication UID 최대',
    authenticationUidLike: 'Firebase Authentication UID 포함',
    authenticationUidNotLike: 'Firebase Authentication UID 제외',
    roleEq: '역할 일치',
    roleNeq: '역할 불일치',
    roleMin: '역할 최소',
    roleMax: '역할 최대',
    roleIn: '역할 포함',
    adminPermissionEq: '관리자 권한 일치',
    adminPermissionNeq: '관리자 권한 불일치',
    adminPermissionAny: '관리자 권한 일부 해당',
    adminPermissionAll: '관리자 권한 전체 해당',
    adminPermissionNone: '관리자 권한 제외',
    emailEq: '이메일 일치',
    emailNeq: '이메일 불일치',
    emailMin: '이메일 최소',
    emailMax: '이메일 최대',
    emailLike: '이메일 포함',
    emailNotLike: '이메일 제외',
    cloudMessagingTokenNeq: 'Firebase Cloud Messaging Token 불일치',
    cloudMessagingTokenMin: 'Firebase Cloud Messaging Token 최소',
    cloudMessagingTokenMax: 'Firebase Cloud Messaging Token 최대',
    cloudMessagingTokenLike: 'Firebase Cloud Messaging Token 포함',
    cloudMessagingTokenNotLike: 'Firebase Cloud Messaging Token 제외',
    cloudMessagingTokenIsNull: 'Firebase Cloud Messaging Token 값 존재 여부',
    nicknameNeq: '닉네임 불일치',
    nicknameMin: '닉네임 최소',
    nicknameMax: '닉네임 최대',
    nicknameLike: '닉네임 포함',
    nicknameNotLike: '닉네임 제외',
    birthCountryIdEq: '출신 국가 ID 일치',
    birthCountryIdNeq: '출신 국가 ID 불일치',
    birthCountryIdMin: '출신 국가 ID 최소',
    birthCountryIdMax: '출신 국가 ID 최대',
    residenceCountryIdEq: '거주 국가 ID 일치',
    residenceCountryIdNeq: '거주 국가 ID 불일치',
    residenceCountryIdMin: '거주 국가 ID 최소',
    residenceCountryIdMax: '거주 국가 ID 최대',
    birthEq: '생년월일 일치',
    birthNeq: '생년월일 불일치',
    birthMin: '생년월일 최소',
    birthMax: '생년월일 최대',
    birthLike: '생년월일 포함',
    birthNotLike: '생년월일 제외',
    genderEq: '성별 일치',
    genderNeq: '성별 불일치',
    genderMin: '성별 최소',
    genderMax: '성별 최대',
    genderIn: '성별 포함',
    phoneCountryIdEq: '전화번호 국가 ID 일치',
    phoneCountryIdNeq: '전화번호 국가 ID 불일치',
    phoneCountryIdMin: '전화번호 국가 ID 최소',
    phoneCountryIdMax: '전화번호 국가 ID 최대',
    phoneCountryIdIsNull: '전화번호 국가 ID 값 존재 여부',
    phoneEq: '전화번호 일치',
    phoneNeq: '전화번호 불일치',
    phoneMin: '전화번호 최소',
    phoneMax: '전화번호 최대',
    phoneLike: '전화번호 포함',
    phoneNotLike: '전화번호 제외',
    phoneIsNull: '전화번호 값 존재 여부',
    recommenderIdEq: '추천인 ID 일치',
    recommenderIdNeq: '추천인 ID 불일치',
    recommenderIdMin: '추천인 ID 최소',
    recommenderIdMax: '추천인 ID 최대',
    recommenderIdIsNull: '추천인 ID 값 존재 여부',
    recommendationCodeNeq: '추천 코드 불일치',
    recommendationCodeMin: '추천 코드 최소',
    recommendationCodeMax: '추천 코드 최대',
    recommendationCodeLike: '추천 코드 포함',
    recommendationCodeNotLike: '추천 코드 제외',
    statusFlagsEq: '상태 플래그 일치',
    statusFlagsNeq: '상태 플래그 불일치',
    statusFlagsAny: '상태 플래그 일부 해당',
    statusFlagsAll: '상태 플래그 전체 해당',
    statusFlagsNone: '상태 플래그 제외',
    recommendeeCountEq: '추천한 사용자 수 일치',
    recommendeeCountNeq: '추천한 사용자 수 불일치',
    recommendeeCountMin: '추천한 사용자 수 최소',
    recommendeeCountMax: '추천한 사용자 수 최대',
    contentDailyParticipationCountEq: '오늘의 단어 참여 수 일치',
    contentDailyParticipationCountNeq: '오늘의 단어 참여 수 불일치',
    contentDailyParticipationCountMin: '오늘의 단어 참여 수 최소',
    contentDailyParticipationCountMax: '오늘의 단어 참여 수 최대',
    contentDailyParticipationPassCountEq: '오늘의 단어 합격 수 일치',
    contentDailyParticipationPassCountNeq: '오늘의 단어 합격 수 불일치',
    contentDailyParticipationPassCountMin: '오늘의 단어 합격 수 최소',
    contentDailyParticipationPassCountMax: '오늘의 단어 합격 수 최대',
    contentRepeatLikeCountEq: '따라 말하기 좋아요 수 일치',
    contentRepeatLikeCountNeq: '따라 말하기 좋아요 수 불일치',
    contentRepeatLikeCountMin: '따라 말하기 좋아요 수 최소',
    contentRepeatLikeCountMax: '따라 말하기 좋아요 수 최대',
    contentRepeatParticipationCountEq: '따라 말하기 참여 수 일치',
    contentRepeatParticipationCountNeq: '따라 말하기 참여 수 불일치',
    contentRepeatParticipationCountMin: '따라 말하기 참여 수 최소',
    contentRepeatParticipationCountMax: '따라 말하기 참여 수 최대',
    contentRepeatParticipationPassCountEq: '따라 말하기 합격 수 일치',
    contentRepeatParticipationPassCountNeq: '따라 말하기 합격 수 불일치',
    contentRepeatParticipationPassCountMin: '따라 말하기 합격 수 최소',
    contentRepeatParticipationPassCountMax: '따라 말하기 합격 수 최대',
    contentRepeatParticipationHighAccuracyCountEq: '따라 말하기 높은 일치율 수 일치',
    contentRepeatParticipationHighAccuracyCountNeq: '따라 말하기 높은 일치율 수 불일치',
    contentRepeatParticipationHighAccuracyCountMin: '따라 말하기 높은 일치율 수 최소',
    contentRepeatParticipationHighAccuracyCountMax: '따라 말하기 높은 일치율 수 최대',
    contentRepeatCommentCountEq: '따라 말하기 댓글 수 일치',
    contentRepeatCommentCountNeq: '따라 말하기 댓글 수 불일치',
    contentRepeatCommentCountMin: '따라 말하기 댓글 수 최소',
    contentRepeatCommentCountMax: '따라 말하기 댓글 수 최대',
    contentRepeatCommentLikeCountEq: '따라 말하기 댓글 좋아요 수 일치',
    contentRepeatCommentLikeCountNeq: '따라 말하기 댓글 좋아요 수 불일치',
    contentRepeatCommentLikeCountMin: '따라 말하기 댓글 좋아요 수 최소',
    contentRepeatCommentLikeCountMax: '따라 말하기 댓글 좋아요 수 최대',
    contentRepeatCommentReportCountEq: '따라 말하기 댓글 신고 수 일치',
    contentRepeatCommentReportCountNeq: '따라 말하기 댓글 신고 수 불일치',
    contentRepeatCommentReportCountMin: '따라 말하기 댓글 신고 수 최소',
    contentRepeatCommentReportCountMax: '따라 말하기 댓글 신고 수 최대',
    contentRepeatCommentReportedCountEq: '따라 말하기 댓글 피신고 수 일치',
    contentRepeatCommentReportedCountNeq: '따라 말하기 댓글 피신고 수 불일치',
    contentRepeatCommentReportedCountMin: '따라 말하기 댓글 피신고 수 최소',
    contentRepeatCommentReportedCountMax: '따라 말하기 댓글 피신고 수 최대',
    contentM10nLikeCountEq: '암기하기 좋아요 수 일치',
    contentM10nLikeCountNeq: '암기하기 좋아요 수 불일치',
    contentM10nLikeCountMin: '암기하기 좋아요 수 최소',
    contentM10nLikeCountMax: '암기하기 좋아요 수 최대',
    contentM10nParticipationCountEq: '암기하기 참여 수 일치',
    contentM10nParticipationCountNeq: '암기하기 참여 수 불일치',
    contentM10nParticipationCountMin: '암기하기 참여 수 최소',
    contentM10nParticipationCountMax: '암기하기 참여 수 최대',
    contentM10nParticipationPassCountEq: '암기하기 합격 수 일치',
    contentM10nParticipationPassCountNeq: '암기하기 합격 수 불일치',
    contentM10nParticipationPassCountMin: '암기하기 합격 수 최소',
    contentM10nParticipationPassCountMax: '암기하기 합격 수 최대',
    contentM10nParticipationHighAccuracyCountEq: '암기하기 높은 일치율 수 일치',
    contentM10nParticipationHighAccuracyCountNeq: '암기하기 높은 일치율 수 불일치',
    contentM10nParticipationHighAccuracyCountMin: '암기하기 높은 일치율 수 최소',
    contentM10nParticipationHighAccuracyCountMax: '암기하기 높은 일치율 수 최대',
    contentM10nCommentCountEq: '암기하기 댓글 수 일치',
    contentM10nCommentCountNeq: '암기하기 댓글 수 불일치',
    contentM10nCommentCountMin: '암기하기 댓글 수 최소',
    contentM10nCommentCountMax: '암기하기 댓글 수 최대',
    contentM10nCommentLikeCountEq: '암기하기 댓글 좋아요 수 일치',
    contentM10nCommentLikeCountNeq: '암기하기 댓글 좋아요 수 불일치',
    contentM10nCommentLikeCountMin: '암기하기 댓글 좋아요 수 최소',
    contentM10nCommentLikeCountMax: '암기하기 댓글 좋아요 수 최대',
    contentM10nCommentReportCountEq: '암기하기 댓글 신고 수 일치',
    contentM10nCommentReportCountNeq: '암기하기 댓글 신고 수 불일치',
    contentM10nCommentReportCountMin: '암기하기 댓글 신고 수 최소',
    contentM10nCommentReportCountMax: '암기하기 댓글 신고 수 최대',
    contentM10nCommentReportedCountEq: '암기하기 댓글 피신고 수 일치',
    contentM10nCommentReportedCountNeq: '암기하기 댓글 피신고 수 불일치',
    contentM10nCommentReportedCountMin: '암기하기 댓글 피신고 수 최소',
    contentM10nCommentReportedCountMax: '암기하기 댓글 피신고 수 최대',
    contentUserProductionCountEq: '와글와글 수 일치',
    contentUserProductionCountNeq: '와글와글 수 불일치',
    contentUserProductionCountMin: '와글와글 수 최소',
    contentUserProductionCountMax: '와글와글 수 최대',
    contentUserProductionLikeCountEq: '와글와글 좋아요 수 일치',
    contentUserProductionLikeCountNeq: '와글와글 좋아요 수 불일치',
    contentUserProductionLikeCountMin: '와글와글 좋아요 수 최소',
    contentUserProductionLikeCountMax: '와글와글 좋아요 수 최대',
    contentUserProductionParticipationCountEq: '와글와글 참여 수 일치',
    contentUserProductionParticipationCountNeq: '와글와글 참여 수 불일치',
    contentUserProductionParticipationCountMin: '와글와글 참여 수 최소',
    contentUserProductionParticipationCountMax: '와글와글 참여 수 최대',
    contentUserProductionParticipationPassCountEq: '와글와글 합격 수 일치',
    contentUserProductionParticipationPassCountNeq: '와글와글 합격 수 불일치',
    contentUserProductionParticipationPassCountMin: '와글와글 합격 수 최소',
    contentUserProductionParticipationPassCountMax: '와글와글 합격 수 최대',
    contentUserProductionParticipationHighAccuracyCountEq: '와글와글 높은 일치율 수 일치',
    contentUserProductionParticipationHighAccuracyCountNeq: '와글와글 높은 일치율 수 불일치',
    contentUserProductionParticipationHighAccuracyCountMin: '와글와글 높은 일치율 수 최소',
    contentUserProductionParticipationHighAccuracyCountMax: '와글와글 높은 일치율 수 최대',
    contentUserProductionReportCountEq: '와글와글 신고 수 일치',
    contentUserProductionReportCountNeq: '와글와글 신고 수 불일치',
    contentUserProductionReportCountMin: '와글와글 신고 수 최소',
    contentUserProductionReportCountMax: '와글와글 신고 수 최대',
    contentUserProductionReportedCountEq: '와글와글 피신고 수 일치',
    contentUserProductionReportedCountNeq: '와글와글 피신고 수 불일치',
    contentUserProductionReportedCountMin: '와글와글 피신고 수 최소',
    contentUserProductionReportedCountMax: '와글와글 피신고 수 최대',
    contentUserProductionCommentCountEq: '와글와글 댓글 수 일치',
    contentUserProductionCommentCountNeq: '와글와글 댓글 수 불일치',
    contentUserProductionCommentCountMin: '와글와글 댓글 수 최소',
    contentUserProductionCommentCountMax: '와글와글 댓글 수 최대',
    contentUserProductionCommentLikeCountEq: '와글와글 댓글 좋아요 수 일치',
    contentUserProductionCommentLikeCountNeq: '와글와글 댓글 좋아요 수 불일치',
    contentUserProductionCommentLikeCountMin: '와글와글 댓글 좋아요 수 최소',
    contentUserProductionCommentLikeCountMax: '와글와글 댓글 좋아요 수 최대',
    contentUserProductionCommentReportCountEq: '와글와글 댓글 신고 수 일치',
    contentUserProductionCommentReportCountNeq: '와글와글 댓글 신고 수 불일치',
    contentUserProductionCommentReportCountMin: '와글와글 댓글 신고 수 최소',
    contentUserProductionCommentReportCountMax: '와글와글 댓글 신고 수 최대',
    contentUserProductionCommentReportedCountEq: '와글와글 댓글 피신고 수 일치',
    contentUserProductionCommentReportedCountNeq: '와글와글 댓글 피신고 수 불일치',
    contentUserProductionCommentReportedCountMin: '와글와글 댓글 피신고 수 최소',
    contentUserProductionCommentReportedCountMax: '와글와글 댓글 피신고 수 최대',
    contentUserProductionReviewPositiveCountEq: '와글와글 긍정 평가 수 일치',
    contentUserProductionReviewPositiveCountNeq: '와글와글 긍정 평가 수 불일치',
    contentUserProductionReviewPositiveCountMin: '와글와글 긍정 평가 수 최소',
    contentUserProductionReviewPositiveCountMax: '와글와글 긍정 평가 수 최대',
    contentUserProductionReviewNeutralCountEq: '와글와글 중립 평가 수 일치',
    contentUserProductionReviewNeutralCountNeq: '와글와글 중립 평가 수 불일치',
    contentUserProductionReviewNeutralCountMin: '와글와글 중립 평가 수 최소',
    contentUserProductionReviewNeutralCountMax: '와글와글 중립 평가 수 최대',
    contentUserProductionReviewNegativeCountEq: '와글와글 부정 평가 수 일치',
    contentUserProductionReviewNegativeCountNeq: '와글와글 부정 평가 수 불일치',
    contentUserProductionReviewNegativeCountMin: '와글와글 부정 평가 수 최소',
    contentUserProductionReviewNegativeCountMax: '와글와글 부정 평가 수 최대',
    userBlockCountEq: '이용 정지 수 일치',
    userBlockCountNeq: '이용 정지 수 불일치',
    userBlockCountMin: '이용 정지 수 최소',
    userBlockCountMax: '이용 정지 수 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
