import {AssetStarChargeDomain} from "../../domain/asset/AssetStarChargeDomain"

export const AssetStarChargeDescriptor: Record<keyof AssetStarChargeDomain, (o: AssetStarChargeDomain) => string> = {
    id(o: AssetStarChargeDomain): string {
        return o.id.toString()
    },
    userId(o: AssetStarChargeDomain): string {
        return o.userId.toString()
    },
    scheduledAt(o: AssetStarChargeDomain): string {
        return o.scheduledAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    createdAt(o: AssetStarChargeDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
