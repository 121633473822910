import {UserStatus} from "../../../data/constant/user/UserStatus"
import {UserPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/user/UserPutDataTransferObjectDescription"
import {UserPutState} from "../../../data/data-transfer-object-state/user/UserPutState"
import {UserPutDataTransferObject} from "../../../data/data-transfer-object/user/UserPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: UserPutState
}

export const UserPutField: Record<Capitalize<keyof UserPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    CloudMessagingToken(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPutDataTransferObjectDescription.cloudMessagingToken}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.cloudMessagingToken.value === undefined}
                onUnusedCheckboxChange={checked => props.state.cloudMessagingToken.setter(checked ? undefined : '')}>
                <PostTextareaField
                    {...props}
                    value={props.state.cloudMessagingToken.value}
                    setter={props.state.cloudMessagingToken.setter}
                    maxLength={200}
                    disabled={props.state.cloudMessagingToken.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Nickname(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPutDataTransferObjectDescription.nickname}>
                <PostInputField
                    {...props}
                    value={props.state.nickname.value}
                    setter={props.state.nickname.setter}
                    maxLength={40} />
            </PostFieldWrapper>
        )
    },
    ResidenceCountryId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPutDataTransferObjectDescription.residenceCountryId}>
                <PostBigIntField
                    {...props}
                    value={props.state.residenceCountryId.value}
                    setter={props.state.residenceCountryId.setter} />
            </PostFieldWrapper>
        )
    },
    PhoneCountryId(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPutDataTransferObjectDescription.phoneCountryId}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.phoneCountryId.value === undefined}
                onUnusedCheckboxChange={checked => props.state.phoneCountryId.setter(checked ? undefined : 0n)}>
                <PostBigIntField
                    {...props}
                    value={props.state.phoneCountryId.value}
                    setter={props.state.phoneCountryId.setter}
                    disabled={props.state.phoneCountryId.value === undefined} />
            </PostFieldWrapper>
        )
    },
    Phone(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPutDataTransferObjectDescription.phone}
                showUnusedCheckbox
                isUnusedCheckboxChecked={props.state.phone.value === undefined}
                onUnusedCheckboxChange={checked => props.state.phone.setter(checked ? undefined : '')}>
                <PostInputField
                    {...props}
                    value={props.state.phone.value}
                    setter={props.state.phone.setter}
                    maxLength={20}
                    disabled={props.state.phone.value === undefined} />
            </PostFieldWrapper>
        )
    },
    StatusFlags(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={UserPutDataTransferObjectDescription.statusFlags}>
                <PostBitMaskField
                    {...props}
                    value={props.state.statusFlags.value}
                    setter={props.state.statusFlags.setter}
                    selections={UserStatus.selections} />
            </PostFieldWrapper>
        )
    },
}

export function UserPutFields(props: PostFieldProps) {
    return <>
        <UserPutField.CloudMessagingToken
            {...props}
            state={props.state} />
        <UserPutField.Nickname
            {...props}
            state={props.state} />
        <UserPutField.ResidenceCountryId
            {...props}
            state={props.state} />
        <UserPutField.PhoneCountryId
            {...props}
            state={props.state} />
        <UserPutField.Phone
            {...props}
            state={props.state} />
        <UserPutField.StatusFlags
            {...props}
            state={props.state} />
    </>
}
