import {UiOnboardingModificationDomain} from "../../domain/ui/UiOnboardingModificationDomain"

export const UiOnboardingModificationDescriptor: Record<keyof UiOnboardingModificationDomain, (o: UiOnboardingModificationDomain) => string> = {
    id(o: UiOnboardingModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiOnboardingModificationDomain): string {
        return o.creatorId.toString()
    },
    uiOnboardingId(o: UiOnboardingModificationDomain): string {
        return o.uiOnboardingId.toString()
    },
    displayOrder(o: UiOnboardingModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: UiOnboardingModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
