import {UserBlockReasonDomain} from "../../domain/user/UserBlockReasonDomain"

/**
 * 사용자 이용 정지 사유 속성 설명
 */
export const UserBlockReasonDescription: Record<keyof UserBlockReasonDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    body: '사유',
    userBlockCount: '사유 적용 수',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
