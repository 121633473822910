import {SortOrder} from "ts-protocol-extension";
import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserAgreementValueObject} from "../../../data/value-object/user/UserAgreementValueObject";
import {UserAgreementSearchOption} from "../../../data/search-option/user/UserAgreementSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import ListPageWrapper from "../../ListPageWrapper";
import {UserAgreementIO} from "../../../io/UserIO";
import {UserAgreementDescription} from "../../../data/description/user/UserAgreementDescription";
import {UserAgreementDescriptor} from "../../../data/descriptor/user/UserAgreementDescriptor";
import ListOption from "../../ListOption";
import {
    UserAgreementSearchOptionDescription
} from "../../../data/description/user/UserAgreementSearchOptionDescription";
import {
    applyUserAgreementSearchOption,
    UserAgreementSearchOptionField
} from "../../search-option-field/user/UserAgreementSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserAgreementValueObject[]>([])
    const option = useStatePair<UserAgreementSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserAgreementIO}
                items={items}
                option={option.value}
                index={index}
                description={UserAgreementDescription}
                descriptor={UserAgreementDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/agreements/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserAgreementSearchOptionDescription}
                fields={UserAgreementSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserAgreementSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserAgreementDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}