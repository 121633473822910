import {RouterProps} from "./RouterProps";
import {StatePair, useBooleanPair, useStatePair} from "react-type-extension";
import {
    Button,
    Caption1,
    Checkbox,
    InteractionTag, InteractionTagPrimary, InteractionTagSecondary,
    makeStyles, mergeClasses,
    Radio,
    RadioGroup, TagGroup,
    Title3
} from "@fluentui/react-components";
import {ChevronLeft24Filled, ChevronRight24Filled, Dismiss24Filled} from "@fluentui/react-icons";
import {JSX} from "react";
import Spacer from "./Spacer";

const useStyles = makeStyles({
    navigations: {
        marginTop: '16px',
        width: 'max-content'
    },
    navigation: {
        justifyContent: 'space-between'
    }
})

export type ListOptionFieldProps<SO> = RouterProps & {
    optionKey: keyof SO
    onApplyClick: (key: keyof SO, value: string) => void
}

export type ListOptionProps<SO> = RouterProps & {
    option: Partial<SO>
    description: Record<keyof SO, string>
    fields: (props: ListOptionFieldProps<SO>) => JSX.Element
    onBackClick: () => void
    onApplyClick: (key: keyof SO, value: string) => void
    onDismissClick: (key: keyof SO) => void
}

export default function ListOption<SO>(props: ListOptionProps<SO>) {
    const styles = useStyles()
    const selectedKey = useStatePair<(keyof SO) | undefined>(undefined)

    function mapByKey<R>(transform: (key: keyof SO) => R): R[] {
        const acc: R[] = []
        for (let key in props.description) {
            acc.push(transform(key))
        }

        return acc
    }

    return <>
        <div className={props.styles.row}>
            <div className={props.styles.column}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled />}
                        onClick={props.onBackClick}></Button>
                    <Title3>항목 선택</Title3>
                </div>
                <div className={mergeClasses(styles.navigations, props.styles.column8)}>
                    {mapByKey(key => <>
                        <Button
                            key={`list-option-navigation-${String(key)}`}
                            className={styles.navigation}
                            icon={<ChevronRight24Filled />}
                            iconPosition={'after'}
                            appearance={'subtle'}
                            onClick={() => selectedKey.setter(key)}>
                            {props.description[key]}
                        </Button>
                    </>)}
                </div>
            </div>
            <div className={props.styles.column}>
                {selectedKey.value !== undefined && <>
                    <div className={props.styles.row}>
                        <Spacer width={'16px'} />
                        <props.fields
                            {...props}
                            optionKey={selectedKey.value}
                            onApplyClick={props.onApplyClick} />
                    </div>
                </>}
            </div>
            <Spacer width={'16px'} />
            <div className={props.styles.column}>
                <TagGroup onDismiss={(e, data) => props.onDismissClick(data.value as keyof SO)} >
                    {mapByKey(key => <>
                        {props.option[key] !== undefined && <>
                            <InteractionTag
                                value={key as string}
                                key={key as string}>
                                <InteractionTagPrimary hasSecondaryAction>
                                    {`${props.description[key]}: ${props.option[key]}`}
                                </InteractionTagPrimary>
                                <InteractionTagSecondary aria-label={'remove'} />
                            </InteractionTag>
                        </>}
                    </>)}
                </TagGroup>
            </div>
        </div>
    </>
}

export function ListOptionWrapper<SO>(props: ListOptionFieldProps<SO> & {
    title: string,
    input: StatePair<string>,
    children?: JSX.Element | JSX.Element[]
}) {
    return <>
        <div className={props.styles.column8}>
            <Caption1>{props.title}</Caption1>
            {props.children}
            <div className={props.styles.row}>
                <Button
                    disabled={props.input.value.length === 0}
                    appearance={'primary'}
                    onClick={() => props.onApplyClick(props.optionKey, props.input.value)}>
                    적용
                </Button>
            </div>
        </div>
    </>
}

export function ListOptionRadioGroup(props: RouterProps & {
    input: StatePair<string>,
    selections: { label: string, value: string }[]
}) {
    return <>
        <RadioGroup
            value={props.input.value}
            onChange={(_, data) => props.input.setter(data.value)}>
            {props.selections.map(selection => <>
                <Radio
                    key={`list-option-radio-${selection.value}`}
                    value={selection.value}
                    label={selection.label}/>
            </>)}
        </RadioGroup>
    </>
}

export function ListOptionCheckboxes(props: RouterProps & { input: StatePair<string>, selections: { label: string, value: string }[] }) {
    return <>
        {props.selections.map(selection => <>
            <Checkbox
                key={`list-option-checkbox-${selection.value}`}
                label={selection.label}
                checked={props.input.value.split('|').includes(selection.value)}
                onChange={(ev, data) => {
                    if (data.checked) {
                        props.input.setter(props.input.value + '|' + selection.value)
                    } else {
                        const tokens = props.input.value.split('|')
                        const index = tokens.indexOf(selection.value)
                        props.input.setter([...tokens.slice(0, index), ...tokens.slice(index + 1, tokens.length)].join('|'))
                    }
                }} />
        </>)}
    </>
}