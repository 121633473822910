import {ContentParticipationSearchOptionDescription} from "../../../data/description/content/ContentParticipationSearchOptionDescription"
import {ContentParticipationSearchOption} from "../../../data/search-option/content/ContentParticipationSearchOption"
import {ListOptionFieldProps, ListOptionRadioGroup, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function ContentParticipationSearchOptionField(props: ListOptionFieldProps<ContentParticipationSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.contentIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.contentIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.contentIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "contentIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.contentIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetSolarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetSolarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetSolarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetSolarUsageIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetStarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetStarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetStarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.assetStarUsageIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.audioIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.audioIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.audioIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "audioIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.audioIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.transcriptionEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.transcriptionNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.transcriptionMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.transcriptionMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.transcriptionLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "transcriptionNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.transcriptionNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.accuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.accuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.accuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.accuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "isPassedEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isPassedEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isPassedNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isPassedNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isPassedMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isPassedMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isPassedMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isPassedMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isHighAccuracyEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isHighAccuracyEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isHighAccuracyNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isHighAccuracyNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isHighAccuracyMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isHighAccuracyMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "isHighAccuracyMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.isHighAccuracyMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <ListOptionRadioGroup
                        {...props}
                        input={input}
                        selections={[
                            { value: "true", label: "여" },
                            { value: "false", label: "부" }
                        ]} />
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={ContentParticipationSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyContentParticipationSearchOption(key: keyof ContentParticipationSearchOption, value: string, into: StatePair<ContentParticipationSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "contentIdEq":
            into.setter({
                ...into.value,
                contentIdEq: BigInt(value)
            })
            break
        case "contentIdNeq":
            into.setter({
                ...into.value,
                contentIdNeq: BigInt(value)
            })
            break
        case "contentIdMin":
            into.setter({
                ...into.value,
                contentIdMin: BigInt(value)
            })
            break
        case "contentIdMax":
            into.setter({
                ...into.value,
                contentIdMax: BigInt(value)
            })
            break
        case "assetSolarUsageIdNeq":
            into.setter({
                ...into.value,
                assetSolarUsageIdNeq: BigInt(value)
            })
            break
        case "assetSolarUsageIdMin":
            into.setter({
                ...into.value,
                assetSolarUsageIdMin: BigInt(value)
            })
            break
        case "assetSolarUsageIdMax":
            into.setter({
                ...into.value,
                assetSolarUsageIdMax: BigInt(value)
            })
            break
        case "assetSolarUsageIdIsNull":
            into.setter({
                ...into.value,
                assetSolarUsageIdIsNull: Boolean(value)
            })
            break
        case "assetStarUsageIdNeq":
            into.setter({
                ...into.value,
                assetStarUsageIdNeq: BigInt(value)
            })
            break
        case "assetStarUsageIdMin":
            into.setter({
                ...into.value,
                assetStarUsageIdMin: BigInt(value)
            })
            break
        case "assetStarUsageIdMax":
            into.setter({
                ...into.value,
                assetStarUsageIdMax: BigInt(value)
            })
            break
        case "assetStarUsageIdIsNull":
            into.setter({
                ...into.value,
                assetStarUsageIdIsNull: Boolean(value)
            })
            break
        case "audioIdNeq":
            into.setter({
                ...into.value,
                audioIdNeq: BigInt(value)
            })
            break
        case "audioIdMin":
            into.setter({
                ...into.value,
                audioIdMin: BigInt(value)
            })
            break
        case "audioIdMax":
            into.setter({
                ...into.value,
                audioIdMax: BigInt(value)
            })
            break
        case "audioIdIsNull":
            into.setter({
                ...into.value,
                audioIdIsNull: Boolean(value)
            })
            break
        case "transcriptionEq":
            into.setter({
                ...into.value,
                transcriptionEq: String(value)
            })
            break
        case "transcriptionNeq":
            into.setter({
                ...into.value,
                transcriptionNeq: String(value)
            })
            break
        case "transcriptionMin":
            into.setter({
                ...into.value,
                transcriptionMin: String(value)
            })
            break
        case "transcriptionMax":
            into.setter({
                ...into.value,
                transcriptionMax: String(value)
            })
            break
        case "transcriptionLike":
            into.setter({
                ...into.value,
                transcriptionLike: String(value)
            })
            break
        case "transcriptionNotLike":
            into.setter({
                ...into.value,
                transcriptionNotLike: String(value)
            })
            break
        case "accuracyEq":
            into.setter({
                ...into.value,
                accuracyEq: Number(value)
            })
            break
        case "accuracyNeq":
            into.setter({
                ...into.value,
                accuracyNeq: Number(value)
            })
            break
        case "accuracyMin":
            into.setter({
                ...into.value,
                accuracyMin: Number(value)
            })
            break
        case "accuracyMax":
            into.setter({
                ...into.value,
                accuracyMax: Number(value)
            })
            break
        case "isPassedEq":
            into.setter({
                ...into.value,
                isPassedEq: Boolean(value)
            })
            break
        case "isPassedNeq":
            into.setter({
                ...into.value,
                isPassedNeq: Boolean(value)
            })
            break
        case "isPassedMin":
            into.setter({
                ...into.value,
                isPassedMin: Boolean(value)
            })
            break
        case "isPassedMax":
            into.setter({
                ...into.value,
                isPassedMax: Boolean(value)
            })
            break
        case "isHighAccuracyEq":
            into.setter({
                ...into.value,
                isHighAccuracyEq: Boolean(value)
            })
            break
        case "isHighAccuracyNeq":
            into.setter({
                ...into.value,
                isHighAccuracyNeq: Boolean(value)
            })
            break
        case "isHighAccuracyMin":
            into.setter({
                ...into.value,
                isHighAccuracyMin: Boolean(value)
            })
            break
        case "isHighAccuracyMax":
            into.setter({
                ...into.value,
                isHighAccuracyMax: Boolean(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
