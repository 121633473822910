import {AssetCouponDomain} from "../../domain/asset/AssetCouponDomain"

/**
 * 재화 쿠폰 속성 설명
 */
export const AssetCouponDescription: Record<keyof AssetCouponDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    code: '코드',
    amount: '수량',
    assetLunarAmount: '지급 루나',
    assetSolarAmount: '지급 솔라',
    assetStarAmount: '지급 스타',
    usageCount: '사용량',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
