import {RouterProps} from "../../RouterProps";
import {Route, Routes} from "react-router-dom";
import ClickRouter from "./click/ClickRouter";
import List from "./List";
import Detail from "./Detail";

export default function ResultUrlRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/'} element={<List {...props} />} />
            <Route path={'/:contentResultUrlId'} element={<Detail {...props} />} />
            <Route path={'/clicks/*'} element={<ClickRouter {...props} />} />
        </Routes>
    </>
}