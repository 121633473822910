import {EnvNoticeModificationDomain} from "../../domain/env/EnvNoticeModificationDomain"

/**
 * 공지사항 수정 이력 속성 설명
 */
export const EnvNoticeModificationDescription: Record<keyof EnvNoticeModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envNoticeId: '공지사항 ID',
    title: '제목',
    url: 'URL',
    createdAt: '수정일'
}
