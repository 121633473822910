import {EnvFaqItemDomain} from "../../domain/env/EnvFaqItemDomain"

export const EnvFaqItemDescriptor: Record<keyof EnvFaqItemDomain, (o: EnvFaqItemDomain) => string> = {
    id(o: EnvFaqItemDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvFaqItemDomain): string {
        return o.creatorId.toString()
    },
    envFaqId(o: EnvFaqItemDomain): string {
        return o.envFaqId.toString()
    },
    title(o: EnvFaqItemDomain): string {
        return o.title
    },
    body(o: EnvFaqItemDomain): string {
        return o.body
    },
    displayOrder(o: EnvFaqItemDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: EnvFaqItemDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvFaqItemDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvFaqItemDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
