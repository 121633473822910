import {UserLevelHistoryDomain} from "../../domain/user/UserLevelHistoryDomain"

/**
 * 사용자 레벨 기록 속성 설명
 */
export const UserLevelHistoryDescription: Record<keyof UserLevelHistoryDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    userLevelId: '사용자 레벨 ID',
    assetLunarUsageId: '루나 지급 ID',
    assetSolarUsageId: '솔라 지급 ID',
    assetStarUsageId: '스타 지급 ID',
    createdAt: '생성일'
}
