export function removeAt<T>(array: T[], index: number): T[] {
    return [
        ...array.slice(0, index),
        ...array.slice(index + 1, array.length)
    ]
}

export function switchForward<T>(array: T[], index: number): T[] {
    const src = array[index]
    const dst = array[index - 1]
    return [
        ...array.slice(0, index - 1),
        src,
        dst,
        ...array.slice(index + 1, array.length)
    ]
}

export function switchBackward<T>(array: T[], index: number): T[] {
    const src = array[index]
    const dst = array[index + 1]
    return [
        ...array.slice(0, index),
        dst,
        src,
        ...array.slice(index + 2, array.length)
    ]
}