import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UiHomeNoticeValueObject} from "../../../../data/value-object/ui/UiHomeNoticeValueObject";
import {UiHomeNoticeSearchOption} from "../../../../data/search-option/ui/UiHomeNoticeSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import {UiHomeNoticeIO} from "../../../../io/UiIO";
import {UiHomeNoticeDescription} from "../../../../data/description/ui/UiHomeNoticeDescription";
import {UiHomeNoticeDescriptor} from "../../../../data/descriptor/ui/UiHomeNoticeDescriptor";
import ListOption from "../../../ListOption";
import {UiHomeNoticeSearchOptionDescription} from "../../../../data/description/ui/UiHomeNoticeSearchOptionDescription";
import {
    applyUiHomeNoticeSearchOption,
    UiHomeNoticeSearchOptionField
} from "../../../search-option-field/ui/UiHomeNoticeSearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UiHomeNoticeValueObject[]>([])
    const option = useStatePair<UiHomeNoticeSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UiHomeNoticeIO}
                items={items}
                option={option.value}
                index={index}
                description={UiHomeNoticeDescription}
                descriptor={UiHomeNoticeDescriptor}
                showPost
                onPostClick={() => document.location = '/ui/home/notices/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/ui/home/notices/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UiHomeNoticeSearchOptionDescription}
                fields={UiHomeNoticeSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUiHomeNoticeSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UiHomeNoticeDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}