import {AssetStarUsageType} from "../../constant/asset/AssetStarUsageType"
import {AssetStarUsagePostDataTransferObject} from "../../data-transfer-object/asset/AssetStarUsagePostDataTransferObject"
import {AssetStarUsageValueObject} from "../../value-object/asset/AssetStarUsageValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetStarUsagePostState = {
    userId: StatePair<bigint>
    type: StatePair<AssetStarUsageType>
    amount: StatePair<number>
    toDataTransferObject(): AssetStarUsagePostDataTransferObject
    fromValueObject(vo: AssetStarUsageValueObject): void
    values(): [bigint, AssetStarUsageType, number]
}

export function useAssetStarUsagePostState(): AssetStarUsagePostState {
    return {
        userId: useStatePair<bigint>(0n),
        type: useStatePair<AssetStarUsageType>(AssetStarUsageType.Admin),
        amount: useStatePair<number>(0),
        toDataTransferObject(): AssetStarUsagePostDataTransferObject {
            return {
                userId: this.userId.value,
                type: this.type.value,
                amount: this.amount.value,
            }
        },
        fromValueObject(vo: AssetStarUsageValueObject) {
            this.userId.setter(vo.userId)
            this.type.setter(vo.type)
            this.amount.setter(vo.amount)
        },
        values(): [bigint, AssetStarUsageType, number] {
            return [
                this.userId.value,
                this.type.value,
                this.amount.value,
            ]
        }
    }
}
