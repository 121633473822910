import {AssetLunarExchangeOptionPostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetLunarExchangeOptionPostDataTransferObjectDescription"
import {AssetLunarExchangeOptionPostState} from "../../../data/data-transfer-object-state/asset/AssetLunarExchangeOptionPostState"
import {AssetLunarExchangeOptionPostDataTransferObject} from "../../../data/data-transfer-object/asset/AssetLunarExchangeOptionPostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetLunarExchangeOptionPostState
}

export const AssetLunarExchangeOptionPostField: Record<Capitalize<keyof AssetLunarExchangeOptionPostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    LunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarExchangeOptionPostDataTransferObjectDescription.lunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.lunarAmount.value}
                    setter={props.state.lunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    SolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetLunarExchangeOptionPostDataTransferObjectDescription.solarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.solarAmount.value}
                    setter={props.state.solarAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetLunarExchangeOptionPostFields(props: PostFieldProps) {
    return <>
        <AssetLunarExchangeOptionPostField.LunarAmount
            {...props}
            state={props.state} />
        <AssetLunarExchangeOptionPostField.SolarAmount
            {...props}
            state={props.state} />
    </>
}
