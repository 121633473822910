import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {UserBlockReasonValueObject} from "../../../../data/value-object/user/UserBlockReasonValueObject";
import {useEffect} from "react";
import {UserBlockIO, UserBlockReasonIO, UserBlockReasonModificationIO} from "../../../../io/UserIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailTab} from "../../../DetailViews";
import {UserBlockReasonDescription} from "../../../../data/description/user/UserBlockReasonDescription";
import {UserBlockReasonDomain} from "../../../../data/domain/user/UserBlockReasonDomain";
import {UserBlockReasonDescriptor} from "../../../../data/descriptor/user/UserBlockReasonDescriptor";
import {UserBlockSearchOption} from "../../../../data/search-option/user/UserBlockSearchOption";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListPageWrapper from "../../../ListPageWrapper";
import {UserBlockDescription} from "../../../../data/description/user/UserBlockDescription";
import {UserBlockDescriptor} from "../../../../data/descriptor/user/UserBlockDescriptor";
import {UserBlockValueObject} from "../../../../data/value-object/user/UserBlockValueObject";
import {
    UserBlockReasonModificationValueObject
} from "../../../../data/value-object/user/UserBlockReasonModificationValueObject";
import {
    UserBlockReasonModificationSearchOption
} from "../../../../data/search-option/user/UserBlockReasonModificationSearchOption";
import {
    UserBlockReasonModificationDescription
} from "../../../../data/description/user/UserBlockReasonModificationDescription";
import {
    UserBlockReasonModificationDescriptor
} from "../../../../data/descriptor/user/UserBlockReasonModificationDescriptor";

enum Panel {
    Default,
    UserBlockReasonModificationList,
    UserBlockList,
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ userBlockReasonId: string }>()
    const item = useStatePair<UserBlockReasonValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.userBlockReasonId !== undefined) {
            UserBlockReasonIO
                .getById(BigInt(params.userBlockReasonId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: UserBlockReasonValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)

    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.UserBlockReasonModificationList}>수정 이력</Tab>
                <Tab value={Panel.UserBlockList}>사용자 이용 정지 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={UserBlockReasonIO}
                description={UserBlockReasonDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete />
            <UserBlockReasonModificationListPanel
                {...props}
                panel={panel.value} />
            <UserBlockListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof UserBlockReasonDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "body":
        case "userBlockCount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{UserBlockReasonDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{UserBlockReasonDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function UserBlockReasonModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<UserBlockReasonModificationValueObject[]>([])
    const modificationSearchOption: UserBlockReasonModificationSearchOption = { userBlockReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserBlockReasonModificationList && <>
            <ListPageWrapper
                {...props}
                client={UserBlockReasonModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UserBlockReasonModificationDescription}
                descriptor={UserBlockReasonModificationDescriptor}/>
        </>}
    </>
}

function UserBlockListPanel(props: PanelProps) {
    const modifications = useStatePair<UserBlockValueObject[]>([])
    const modificationSearchOption: UserBlockSearchOption = { userBlockReasonIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>(searchIndex())

    return <>
        {props.panel === Panel.UserBlockList && <>
            <ListPageWrapper
                {...props}
                client={UserBlockIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={UserBlockDescription}
                descriptor={UserBlockDescriptor}
                onItemClick={item => document.location = `/users/blocks/${item.id}`}/>
        </>}
    </>
}