import {ContentTargetModificationDomain} from "../../domain/content/ContentTargetModificationDomain"

/**
 * 콘텐츠 노출 대상 수정 이력 속성 설명
 */
export const ContentTargetModificationDescription: Record<keyof ContentTargetModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    contentTargetId: '콘텐츠 노출 대상 ID',
    envCountryId: '국가 ID',
    userGender: '성별',
    userBirthYearMin: '출생년도 최소',
    userBirthYearMax: '출생년도 최대',
    createdAt: '수정일'
}
