import {EnvStorageKeyDomain} from "../../domain/env/EnvStorageKeyDomain"

/**
 * AWS S3 인증 수단 속성 설명
 */
export const EnvStorageKeyDescription: Record<keyof EnvStorageKeyDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    accessKeyId: '액세스 키 ID',
    secretAccessKey: '비밀키',
    bucket: '버킷',
    region: '리전',
    distribution: '배포 유형',
    createdAt: '생성일'
}
