import {UserNotificationDomain} from "../../domain/user/UserNotificationDomain"

/**
 * 사용자 알림 속성 설명
 */
export const UserNotificationDescription: Record<keyof UserNotificationDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    creatorId: '생성자 ID',
    title: '제목',
    body: '내용',
    subject: '광고 주제',
    deepLink: '딥링크',
    statusFlags: '상태',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
