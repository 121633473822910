import {ContentReviewDomain} from "../../domain/content/ContentReviewDomain"

/**
 * 콘텐츠 리뷰(와글와글만 가능) 속성 설명
 */
export const ContentReviewDescription: Record<keyof ContentReviewDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    contentId: '콘텐츠 ID',
    review: '평가',
    createdAt: '생성일'
}
