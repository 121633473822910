import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentReviewValueObject
} from "../../../data/value-object/content/ContentReviewValueObject";
import {useEffect} from "react";
import {ContentReviewIO} from "../../../io/ContentIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {ContentReviewDescription} from "../../../data/description/content/ContentReviewDescription";
import {ContentReviewDomain} from "../../../data/domain/content/ContentReviewDomain";
import {ContentReviewDescriptor} from "../../../data/descriptor/content/ContentReviewDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentReviewId: string }>()
    const item = useStatePair<ContentReviewValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentReviewId !== undefined) {
            ContentReviewIO
                .getById(BigInt(params.contentReviewId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentReviewValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentReviewIO}
                description={ContentReviewDescription}
                properties={props => <PropertyBody {...props} />}/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentReviewDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{ContentReviewDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentReviewDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentId":
            return <a href={`/contents/${props.item.contentId}`}><Body1>{ContentReviewDescriptor[props.itemKey](props.item)}</Body1></a>
        case "review":
            return (props.item.review < 0)
                ? <Body1>부정</Body1>
                : (props.item.review > 0)
                    ? <Body1>긍정</Body1>
                    : <Body1>중립</Body1>
    }
}