import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserExpValueObject} from "../../../data/value-object/user/UserExpValueObject";
import {UserExpSearchOption} from "../../../data/search-option/user/UserExpSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {UserExpIO} from "../../../io/UserIO";
import {UserExpDescription} from "../../../data/description/user/UserExpDescription";
import {UserExpDescriptor} from "../../../data/descriptor/user/UserExpDescriptor";
import ListOption from "../../ListOption";
import {UserExpSearchOptionDescription} from "../../../data/description/user/UserExpSearchOptionDescription";
import {
    applyUserExpSearchOption,
    UserExpSearchOptionField
} from "../../search-option-field/user/UserExpSearchOptionField";
import ListOrder from "../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserExpValueObject[]>([])
    const option = useStatePair<UserExpSearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserExpIO}
                items={items}
                option={option.value}
                index={index}
                description={UserExpDescription}
                descriptor={UserExpDescriptor}
                showPost
                onPostClick={() => document.location = '/users/exps/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/exps/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserExpSearchOptionDescription}
                fields={UserExpSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserExpSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserExpDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}