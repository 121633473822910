import {AssetSolarWithdrawalOptionModificationDomain} from "../../domain/asset/AssetSolarWithdrawalOptionModificationDomain"
import {AssetSolarWithdrawalOptionId} from "../../id/asset/AssetSolarWithdrawalOptionId"
import {AssetSolarWithdrawalOptionModificationId} from "../../id/asset/AssetSolarWithdrawalOptionModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 솔라 인출 옵션 수정 이력
 */
export class AssetSolarWithdrawalOptionModificationValueObject implements ValueObject, AssetSolarWithdrawalOptionModificationDomain {
    /**
     * 기본키
     */
    id: AssetSolarWithdrawalOptionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 솔라 인출 옵션 ID
     */
    assetSolarWithdrawalOptionId: AssetSolarWithdrawalOptionId
    /**
     * 차감 솔라
     */
    solarAmount: number
    /**
     * 지급 금액
     */
    cashAmount: number
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof AssetSolarWithdrawalOptionModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.assetSolarWithdrawalOptionId = BigInt(o.assetSolarWithdrawalOptionId)
        this.solarAmount = Number(o.solarAmount)
        this.cashAmount = Number(o.cashAmount)
        this.createdAt = new Date(o.createdAt)
    }
}
