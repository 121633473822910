import {EnvCountryDomain} from "../../domain/env/EnvCountryDomain"

export const EnvCountryDescriptor: Record<keyof EnvCountryDomain, (o: EnvCountryDomain) => string> = {
    id(o: EnvCountryDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvCountryDomain): string {
        return o.creatorId.toString()
    },
    name(o: EnvCountryDomain): string {
        return o.name
    },
    isoCode(o: EnvCountryDomain): string {
        return o.isoCode
    },
    phoneCode(o: EnvCountryDomain): string {
        return o.phoneCode.toString()
    },
    emoji(o: EnvCountryDomain): string {
        return o.emoji
    },
    userBirthCount(o: EnvCountryDomain): string {
        return o.userBirthCount.toString()
    },
    userResidenceCount(o: EnvCountryDomain): string {
        return o.userResidenceCount.toString()
    },
    userPhoneCodeCount(o: EnvCountryDomain): string {
        return o.userPhoneCodeCount.toString()
    },
    createdAt(o: EnvCountryDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvCountryDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvCountryDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
