import axios, {RawAxiosRequestHeaders} from "axios";
import {Environments} from "../Environments";
import {AxiosInterceptors, HttpMethod, RequestStatement, RequestStatementConfig} from "ts-axios-client-extension";
import {HttpRequestHeaderNames} from "../data/constant/http/HttpRequestHeaderNames";
import {SortOrder} from "ts-protocol-extension";

const axiosClient = axios.create({
    baseURL: Environments.baseUrl,
    withCredentials: true,
})

axiosClient.interceptors.request.use(request => {
    request.headers.set(HttpRequestHeaderNames.clientVersionName, Environments.versionName)
    request.headers.set(HttpRequestHeaderNames.clientPlatform, Environments.platform)
    request.headers.set(HttpRequestHeaderNames.clientDistribution, Environments.distribution)

    return request
})

axiosClient.interceptors.request.use(AxiosInterceptors.convertBigIntToString)

const noCacheHeaders: RawAxiosRequestHeaders = {
    'Cache-Control': 'no-cache, no-store'
}

const httpClient = {
    ...axiosClient,
    prepare(config: RequestStatementConfig): RequestStatement {
        if (config.method === HttpMethod.GET) {
            if (config.config === undefined) {
                config.config = { headers: noCacheHeaders }
            } else if (config.config.headers === undefined) {
                config.config.headers = noCacheHeaders;
            } else if (config.config.headers['Cache-Control'] === undefined) {
                config.config.headers['Cache-Control'] = 'no-cache, no-store';
            }
        }

        return new RequestStatement(axiosClient, config)
    },
}

export type SearchIndex = {
    pageIndex: bigint
    sortOrder: SortOrder[]
    sortPriority: string[]
}

export function searchIndex(option?: Partial<SearchIndex>): SearchIndex {
    return {
        pageIndex: 0n,
        sortOrder: [SortOrder.ASC],
        sortPriority: ['id'],
        ...option
    }
}

export type BaseHttpPost<DTO, VO> = {
    post(dto: DTO): Promise<VO>
}

export type BaseHttpPut<Id, DTO, VO> = {
    put(id: Id, dto: DTO): Promise<VO>
}

export type BaseHttpPatch<DTO, VO> = {
    patch(dto: DTO): Promise<VO>
}

export type BaseHttpDelete<Id> = {
    delete(id: Id): Promise<void>
}

export type BaseHttpGetById<Id, VO, VVO> = {
    getById(id: Id): Promise<VO>
    getVerboseById(id: Id): Promise<VVO>
}

export type BaseHttpGetByUnique<T, VO, VVO> = {
    get(unique: T): Promise<VO>
    getVerbose(unique: T): Promise<VVO>
}

export type BaseHttpGetCurrent<VO, VVO> = {
    getCurrent(): Promise<VO>
    getCurrentVerbose(): Promise<VVO>
}

export type BaseHttpSearch<SO, VO, VVO> = {
    search(option: SO, index: SearchIndex): Promise<VO[]>
    searchVerbose(option: SO, index: SearchIndex): Promise<VVO[]>
}

export type BaseHttpCount<SO> = {
    count(searchOption: SO): Promise<bigint>
}

export class BaseHttpClient<Id, Post, Put, VO, VVO, SO> implements
    BaseHttpPost<Post, VO>,
    BaseHttpPut<Id, Put, VO>,
    BaseHttpDelete<Id>,
    BaseHttpGetById<Id, VO, VVO>,
    BaseHttpSearch<SO, VO, VVO>,
    BaseHttpCount<SO>
{
    readonly httpClient: { prepare(config: RequestStatementConfig): RequestStatement }
    readonly path: string
    readonly voCtor: (o: Record<keyof VO, any>) => VO
    readonly vvoCtor: (o: Record<keyof VVO, any>) => VVO

    constructor(
        path: string,
        voCtor: (o: Record<keyof VO, any>) => VO,
        vvoCtor: (o: Record<keyof VVO, any>) => VVO
    ) {
        this.httpClient = httpClient
        this.path = path
        this.voCtor = voCtor
        this.vvoCtor = vvoCtor
    }

    post = (dto: Post): Promise<VO> => _post(this.path, dto, this.voCtor);

    put = (id: Id, dto: Put): Promise<VO> => _put(this.path + `/${id}`, dto, this.voCtor);

    delete = (id: Id): Promise<void> => _delete(this.path + `/${id}`);

    getById = (id: Id): Promise<VO> => _get(this.path + `/${id}`, this.voCtor)

    getVerboseById = (id: Id): Promise<VVO> => _get(this.path + `/${id}/verbose`, this.vvoCtor)

    search = (option: SO, index: SearchIndex): Promise<VO[]> => _search(this.path, option, index, this.voCtor)

    searchVerbose = (option: SO, index: SearchIndex): Promise<VVO[]> => _search(this.path + '/verbose', option, index, this.vvoCtor)

    count = (option: SO): Promise<bigint> => _count(this.path + '/count', option)
}

async function _post<DTO, VO>(path: string, dto: DTO, ctor: (o: Record<keyof VO, any>) => VO) {
    const response = await httpClient
        .prepare({ path: path, method: HttpMethod.POST, config: { data: dto } })
        .bodyAsObject(ctor)

    return response.result
}

async function _put<DTO, VO>(path: string, dto: DTO, ctor: (o: Record<keyof VO, any>) => VO) {
    const response = await httpClient
        .prepare({ path: path, method: HttpMethod.PUT, config: { data: dto } })
        .bodyAsObject(ctor)

    return response.result
}

async function _delete(path: string) {
    const response = await httpClient
        .prepare({ path: path, method: HttpMethod.DELETE })
        .bodyAsEmpty()

    return response.result
}

async function _get<VO>(path: string, ctor: (o: Record<keyof VO, any>) => VO) {
    const response = await httpClient
        .prepare({
            path: path,
            method: HttpMethod.GET
        })
        .bodyAsObject(ctor)

    return response.result
}

async function _search<SO, VO>(
    path: string,
    option: SO,
    index: SearchIndex,
    ctor: (o: Record<keyof VO, any>) => VO,
) {
    const response = await httpClient
        .prepare({
            path: path,
            method: HttpMethod.GET,
            config: { params: buildParams(option, index) }
        })
        .bodyAsArray(ctor)

    return response.result
}

async function _count<SO>(
    path: string,
    option: SO
) {
    const response = await httpClient
        .prepare({
            path: path,
            method: HttpMethod.GET,
            config: { params: buildParams(option) }
        })
        .bodyAsObject(BigInt)

    return response.result
}

function buildParams(option?: any, index?: any): URLSearchParams {
    const params = new URLSearchParams()
    const composed = { ...option, ...index }
    for (const key in composed) {
        const value = (composed as any)[key]
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                params.append(key, value[i])
            }
        } else if (typeof value === 'string') {
            params.append(key, value)
        } else if (typeof value === 'number') {
            params.append(key, value.toString())
        } else if (typeof value === 'bigint') {
            params.append(key, value.toString())
        } else if (typeof value === 'boolean') {
            params.append(key, value ? 'true' : 'false')
        } else if (value === undefined) {
            // pass
        } else {
            throw Error(`Unknown option value type for key ${key}: value=${value}`)
        }
    }

    return params
}