import {UserInquiryDomain} from "../../domain/user/UserInquiryDomain"

/**
 * 사용자 문의 속성 설명
 */
export const UserInquiryDescription: Record<keyof UserInquiryDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    envClientId: '클라이언트 ID',
    envCountryId: '국가 ID',
    email: '회신 이메일',
    remoteAddress: '요청자 주소',
    deviceName: '장치 이름',
    deviceVersion: '장치 버전',
    body: '내용',
    createdAt: '생성일'
}
