import {EnvAgreementType} from "../../constant/env/EnvAgreementType"
import {EnvAgreementModificationVerboseDomain} from "../../domain/env/EnvAgreementModificationVerboseDomain"
import {EnvAgreementId} from "../../id/env/EnvAgreementId"
import {EnvAgreementModificationId} from "../../id/env/EnvAgreementModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {EnvAgreementValueObject} from "./EnvAgreementValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 이용 약관 수정 이력 상세
 */
export class EnvAgreementModificationVerboseValueObject implements VerboseValueObject, EnvAgreementModificationVerboseDomain {
    /**
     * 기본키
     */
    id: EnvAgreementModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 이용 약관 ID
     */
    envAgreementId: EnvAgreementId
    /**
     * 유형
     */
    type: EnvAgreementType
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 이용 약관 ID
     */
    envAgreement: EnvAgreementValueObject

    constructor(o: Record<keyof EnvAgreementModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envAgreementId = BigInt(o.envAgreementId)
        this.type = o.type as EnvAgreementType
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.envAgreement = new EnvAgreementValueObject(o.envAgreement)
    }
}
