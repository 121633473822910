import {StatePair, useStatePair} from "react-type-extension";
import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import React, {useCallback, useEffect} from "react";
import {UserBlockIO, UserBlockReasonIO} from "../../../io/UserIO";
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension";
import {Button, Title3} from "@fluentui/react-components";
import {PostBigIntField, PostDateField, PostFieldWrapper} from "../../PostField";
import {UserBlockDescription} from "../../../data/description/user/UserBlockDescription";
import {UserBlockReasonId} from "../../../data/id/user/UserBlockReasonId";
import {UserBlockValueObject} from "../../../data/value-object/user/UserBlockValueObject";
import {DateFormat} from "ts-format-extension";
import {searchIndex} from "../../../io/HttpClient";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import ListPageWrapper from "../../ListPageWrapper";
import ListOption from "../../ListOption";
import ListOrder from "../../ListOrder";
import {UserBlockReasonDomain} from "../../../data/domain/user/UserBlockReasonDomain";
import {UserBlockReasonSearchOption} from "../../../data/search-option/user/UserBlockReasonSearchOption";
import {UserBlockReasonDescription} from "../../../data/description/user/UserBlockReasonDescription";
import {UserBlockReasonDescriptor} from "../../../data/descriptor/user/UserBlockReasonDescriptor";
import {
    UserBlockReasonSearchOptionDescription
} from "../../../data/description/user/UserBlockReasonSearchOptionDescription";
import {
    applyUserBlockReasonSearchOption,
    UserBlockReasonSearchOptionField
} from "../../search-option-field/user/UserBlockReasonSearchOptionField";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
    UserBlockReasonList,
    UserBlockReasonOption,
    UserBlockReasonOrder
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ userBlockId: string }>()
    const phase = useStatePair<Phase>(Phase.Loading)
    const item = useStatePair<UserBlockValueObject | undefined>(undefined)
    const userBlockReasonId = useStatePair<UserBlockReasonId>(0n)
    const blockedAt = useStatePair('')
    const blockedUntil = useStatePair<string | undefined>(undefined)

    useEffect(() => {
        if (params.userBlockId !== undefined) {
            UserBlockIO
                .getVerboseById(BigInt(params.userBlockId))
                .then(value => {
                    item.setter(value)
                    userBlockReasonId.setter(value.userBlockReasonId)
                    blockedAt.setter(DateFormat.tableDateTime(value.blockedAt))
                    blockedUntil.setter(unwrapUndefinedString(value.blockedUntil))
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        UserBlockIO
            .put(id, {
                userBlockReasonId: userBlockReasonId.value,
                blockedAt: new Date(blockedAt.value),
                blockedUntil: unwrapUndefinedDate(blockedUntil.value)
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/users/blocks/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [userBlockReasonId.value, blockedAt.value, blockedUntil.value])

    return <>
        {(phase.value === Phase.Waiting || phase.value === Phase.Putting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>수정</Title3>
                <PostFieldWrapper
                    {...props}
                    title={UserBlockDescription.userBlockReasonId}
                    showSearch
                    onSearchClick={() => phase.setter(Phase.UserBlockReasonList)}>
                    <PostBigIntField
                        {...props}
                        value={userBlockReasonId.value}
                        setter={userBlockReasonId.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserBlockDescription.blockedAt}>
                    <PostDateField
                        {...props}
                        value={blockedAt.value}
                        setter={blockedAt.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={UserBlockDescription.blockedUntil}
                    showUnusedCheckbox
                    isUnusedCheckboxChecked={blockedUntil.value === undefined}
                    onUnusedCheckboxChange={checked => blockedUntil.setter(checked ? undefined : '')}>
                    <PostDateField
                        {...props}
                        disabled={blockedUntil.value === undefined}
                        value={blockedUntil.value}
                        setter={blockedUntil.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <UserBlockReasonSearch
            {...props}
            phase={phase}
            userBlockReasonId={userBlockReasonId} />
    </>
}

type UserBlockReasonSearchProps = RouterProps & {
    phase: StatePair<Phase>
    userBlockReasonId: StatePair<UserBlockReasonId>
}

function UserBlockReasonSearch(props: UserBlockReasonSearchProps) {
    const items = useStatePair<UserBlockReasonDomain[]>([])
    const option = useStatePair<UserBlockReasonSearchOption>({})
    const index = useStatePair(searchIndex())

    return <>
        {props.phase.value === Phase.UserBlockReasonList && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)}>
                    </Button>
                    <Title3>사용자 이용 정지 사유 검색</Title3>
                </div>
                <ListPageWrapper
                    {...props}
                    client={UserBlockReasonIO}
                    items={items}
                    option={option.value}
                    index={index}
                    description={UserBlockReasonDescription}
                    descriptor={UserBlockReasonDescriptor}
                    showFilter
                    onFilterClick={() => props.phase.setter(Phase.UserBlockReasonOption)}
                    showSort
                    onSortClick={() => props.phase.setter(Phase.UserBlockReasonOrder)}
                    onItemClick={item => {
                        props.userBlockReasonId.setter(item.id)
                        props.phase.setter(Phase.Waiting)
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.UserBlockReasonOption && <>
            <div className={props.styles.column16}>
                <div className={props.styles.row8}>
                    <Button
                        appearance={'subtle'}
                        icon={<ChevronLeft24Filled/>}
                        onClick={() => props.phase.setter(Phase.Waiting)} />
                    <Title3>사용자 이용 정지 사유 검색</Title3>
                </div>
                <ListOption
                    {...props}
                    option={option.value}
                    description={UserBlockReasonSearchOptionDescription}
                    fields={UserBlockReasonSearchOptionField}
                    onBackClick={() => props.phase.setter(Phase.UserBlockReasonList)}
                    onApplyClick={(key, value) => {
                        applyUserBlockReasonSearchOption(key, value, option)
                        index.setter({...index.value, pageIndex: 0n})
                        items.setter([])
                    }}
                    onDismissClick={key => {
                        const next = {...option.value}
                        next[key] = undefined
                        option.setter(next)
                        items.setter([])
                    }}/>
            </div>
        </>}
        {props.phase.value === Phase.UserBlockReasonOrder && <>
            <ListOrder
                {...props}
                index={index}
                description={UserBlockReasonDescription}
                onBackClick={() => props.phase.setter(Phase.UserBlockReasonList)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}