import {ContentCommentReportDomain} from "../../domain/content/ContentCommentReportDomain"

/**
 * 댓글 신고 속성 설명
 */
export const ContentCommentReportDescription: Record<keyof ContentCommentReportDomain, string> = {
    id: '기본키',
    contentCommentId: '댓글 ID',
    contentCommentReportReasonId: '댓글 신고 사유 ID',
    userId: '사용자 ID',
    createdAt: '생성일'
}
