import {ContentCommentModificationDomain} from "../../domain/content/ContentCommentModificationDomain"

export const ContentCommentModificationDescriptor: Record<keyof ContentCommentModificationDomain, (o: ContentCommentModificationDomain) => string> = {
    id(o: ContentCommentModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentCommentModificationDomain): string {
        return o.creatorId.toString()
    },
    contentCommentId(o: ContentCommentModificationDomain): string {
        return o.contentCommentId.toString()
    },
    body(o: ContentCommentModificationDomain): string {
        return o.body
    },
    audioId(o: ContentCommentModificationDomain): string {
        return o.audioId?.toString() ?? '-'
    },
    createdAt(o: ContentCommentModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
