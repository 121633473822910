import {ContentType} from "../../constant/content/ContentType"
import {ContentQueryPopularDomain} from "../../domain/content/ContentQueryPopularDomain"

export const ContentQueryPopularDescriptor: Record<keyof ContentQueryPopularDomain, (o: ContentQueryPopularDomain) => string> = {
    id(o: ContentQueryPopularDomain): string {
        return o.id.toString()
    },
    contentType(o: ContentQueryPopularDomain): string {
        return ContentType.description(o.contentType)
    },
    displayOrder(o: ContentQueryPopularDomain): string {
        return o.displayOrder.toString()
    },
    displayOrderDiff(o: ContentQueryPopularDomain): string {
        return o.displayOrderDiff?.toString() ?? '-'
    },
    body(o: ContentQueryPopularDomain): string {
        return o.body
    },
    createdAt(o: ContentQueryPopularDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
