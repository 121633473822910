import {ContentQueryPopularSearchOption} from "../../search-option/content/ContentQueryPopularSearchOption"

/**
 * 콘텐츠 인기 검색어 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentQueryPopularSearchOptionDescription: Record<keyof ContentQueryPopularSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    contentTypeEq: '콘텐츠 유형 일치',
    contentTypeNeq: '콘텐츠 유형 불일치',
    contentTypeMin: '콘텐츠 유형 최소',
    contentTypeMax: '콘텐츠 유형 최대',
    contentTypeIn: '콘텐츠 유형 포함',
    displayOrderEq: '순위 일치',
    displayOrderNeq: '순위 불일치',
    displayOrderMin: '순위 최소',
    displayOrderMax: '순위 최대',
    displayOrderDiffEq: '순위 변동 일치',
    displayOrderDiffNeq: '순위 변동 불일치',
    displayOrderDiffMin: '순위 변동 최소',
    displayOrderDiffMax: '순위 변동 최대',
    displayOrderDiffIsNull: '순위 변동 값 존재 여부',
    bodyEq: '질의어 일치',
    bodyNeq: '질의어 불일치',
    bodyMin: '질의어 최소',
    bodyMax: '질의어 최대',
    bodyLike: '질의어 포함',
    bodyNotLike: '질의어 제외',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
