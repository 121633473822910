import {AssetLunarExchangeOptionPostDataTransferObject} from "../../data-transfer-object/asset/AssetLunarExchangeOptionPostDataTransferObject"
import {AssetLunarExchangeOptionValueObject} from "../../value-object/asset/AssetLunarExchangeOptionValueObject"
import {StatePair, useStatePair} from "react-type-extension"
import {DateFormat} from "ts-format-extension"
import {unwrapUndefinedDate, unwrapUndefinedString} from "ts-type-extension"

export type AssetLunarExchangeOptionPostState = {
    lunarAmount: StatePair<number>
    solarAmount: StatePair<number>
    toDataTransferObject(): AssetLunarExchangeOptionPostDataTransferObject
    fromValueObject(vo: AssetLunarExchangeOptionValueObject): void
    values(): [number, number]
}

export function useAssetLunarExchangeOptionPostState(): AssetLunarExchangeOptionPostState {
    return {
        lunarAmount: useStatePair<number>(0),
        solarAmount: useStatePair<number>(0),
        toDataTransferObject(): AssetLunarExchangeOptionPostDataTransferObject {
            return {
                lunarAmount: this.lunarAmount.value,
                solarAmount: this.solarAmount.value,
            }
        },
        fromValueObject(vo: AssetLunarExchangeOptionValueObject) {
            this.lunarAmount.setter(vo.lunarAmount)
            this.solarAmount.setter(vo.solarAmount)
        },
        values(): [number, number] {
            return [
                this.lunarAmount.value,
                this.solarAmount.value,
            ]
        }
    }
}
