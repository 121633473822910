import {UserNotificationSubject} from "../../constant/user/UserNotificationSubject"
import {UserNotificationVerboseDomain} from "../../domain/user/UserNotificationVerboseDomain"
import {UserId} from "../../id/user/UserId"
import {UserNotificationId} from "../../id/user/UserNotificationId"
import {UserValueObject} from "./UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 사용자 알림 상세
 */
export class UserNotificationVerboseValueObject implements VerboseValueObject, UserNotificationVerboseDomain {
    /**
     * 기본키
     */
    id: UserNotificationId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 제목
     */
    title: string
    /**
     * 내용
     */
    body: string
    /**
     * 광고 주제
     */
    subject: UserNotificationSubject
    /**
     * 딥링크
     */
    deepLink?: string
    /**
     * 상태
     */
    statusFlags: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof UserNotificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.creatorId = BigInt(o.creatorId)
        this.title = String(o.title)
        this.body = String(o.body)
        this.subject = o.subject as UserNotificationSubject
        if (o.deepLink !== undefined && o.deepLink !== null) {
            this.deepLink = String(o.deepLink)
        }
        this.statusFlags = Number(o.statusFlags)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        this.user = new UserValueObject(o.user)
        this.creator = new UserValueObject(o.creator)
    }
}
