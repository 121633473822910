import {UserLevelDomain} from "../../domain/user/UserLevelDomain"

/**
 * 사용자 레벨 속성 설명
 */
export const UserLevelDescription: Record<keyof UserLevelDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    name: '이름',
    exp: '필요 경험치',
    rewardAssetLunarAmount: '보상 루나',
    rewardAssetSolarAmount: '보상 솔라',
    rewardAssetStarAmount: '보상 스타',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일',
    concealedAt: '삭제일'
}
