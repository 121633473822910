import {RouterProps} from "../../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostDateField,
    PostEnumField,
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField,
    PostNumberField
} from "../../../../PostField";
import {ContentType} from "../../../../../data/constant/content/ContentType";
import {ContentQueryPopularPreemptionIO} from "../../../../../io/ContentIO";
import {
    ContentQueryPopularPreemptionDescription
} from "../../../../../data/description/content/ContentQueryPopularPreemptionDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const contentType = useStatePair<ContentType>(ContentType.Repeat)
    const query = useStatePair('')
    const exposedAt = useStatePair('')
    const exposedUntil = useStatePair('')
    const displayOrder = useStatePair(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        ContentQueryPopularPreemptionIO
            .post({
                contentType: contentType.value,
                query: query.value,
                exposedAt: new Date(exposedAt.value),
                exposedUntil: new Date(exposedUntil.value),
                displayOrder: displayOrder.value,
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [contentType.value, query.value, exposedAt.value, exposedUntil.value, displayOrder.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionDescription.contentType}>
                    <PostEnumField
                        {...props}
                        value={contentType.value}
                        setter={contentType.setter}
                        selections={ContentType.selections} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionDescription.query}>
                    <PostInputField
                        {...props}
                        value={query.value}
                        setter={query.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionDescription.exposedAt}>
                    <PostDateField
                        {...props}
                        value={exposedAt.value}
                        setter={exposedAt.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionDescription.exposedUntil}>
                    <PostDateField
                        {...props}
                        value={exposedUntil.value}
                        setter={exposedUntil.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={ContentQueryPopularPreemptionDescription.displayOrder}>
                    <PostNumberField
                        {...props}
                        value={displayOrder.value}
                        setter={displayOrder.setter} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/contents/queries/populars/preemptions'}/>
    </>
}