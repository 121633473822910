import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    ContentReportReasonVerboseValueObject
} from "../../../../data/value-object/content/ContentReportReasonVerboseValueObject";
import {useCallback, useEffect} from "react";
import {ContentReportReasonIO} from "../../../../io/ContentIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostInputField, PostNumberField} from "../../../PostField";
import {ContentReportReasonDescription} from "../../../../data/description/content/ContentReportReasonDescription";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ contentReportReasonId: string }>()
    const item = useStatePair<ContentReportReasonVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const body = useStatePair<string>('')
    const displayOrder = useStatePair<number>(0)

    useEffect(() => {
        if (item.value === undefined && params.contentReportReasonId !== undefined) {
            ContentReportReasonIO
                .getVerboseById(BigInt(params.contentReportReasonId))
                .then(value => {
                    item.setter(value)
                    body.setter(value.body)
                    displayOrder.setter(value.displayOrder)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        ContentReportReasonIO
            .put(id, {
                body: body.value,
                displayOrder: displayOrder.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/contents/reports/reasons/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [body.value, displayOrder.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={ContentReportReasonDescription.body}>
                <PostInputField
                    {...props}
                    value={body.value}
                    setter={body.setter}/>
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={ContentReportReasonDescription.displayOrder}>
                <PostNumberField
                    {...props}
                    value={displayOrder.value}
                    setter={displayOrder.setter}/>
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}