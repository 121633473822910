import {ContentResultUrlClickSearchOption} from "../../search-option/content/ContentResultUrlClickSearchOption"

/**
 * 콘텐츠 참여 결과 URL 클릭 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentResultUrlClickSearchOptionDescription: Record<keyof ContentResultUrlClickSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    contentResultUrlIdEq: '콘텐츠 참여 결과 URL ID 일치',
    contentResultUrlIdNeq: '콘텐츠 참여 결과 URL ID 불일치',
    contentResultUrlIdMin: '콘텐츠 참여 결과 URL ID 최소',
    contentResultUrlIdMax: '콘텐츠 참여 결과 URL ID 최대',
    assetStarUsageIdEq: '스타 지급 ID 일치',
    assetStarUsageIdNeq: '스타 지급 ID 불일치',
    assetStarUsageIdMin: '스타 지급 ID 최소',
    assetStarUsageIdMax: '스타 지급 ID 최대',
    assetStarUsageIdIsNull: '스타 지급 ID 값 존재 여부',
    userExpIdEq: '사용자 경험치 지급 ID 일치',
    userExpIdNeq: '사용자 경험치 지급 ID 불일치',
    userExpIdMin: '사용자 경험치 지급 ID 최소',
    userExpIdMax: '사용자 경험치 지급 ID 최대',
    userExpIdIsNull: '사용자 경험치 지급 ID 값 존재 여부',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
