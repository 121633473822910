import {useParams} from "react-router-dom";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentResultUrlClickValueObject} from "../../../../data/value-object/content/ContentResultUrlClickValueObject";
import {useEffect} from "react";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {ContentResultUrlClickDescription} from "../../../../data/description/content/ContentResultUrlClickDescription";
import {ContentResultUrlClickIO} from "../../../../io/ContentIO";
import {ContentResultUrlClickDomain} from "../../../../data/domain/content/ContentResultUrlClickDomain";
import {ContentResultUrlClickDescriptor} from "../../../../data/descriptor/content/ContentResultUrlClickDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentResultUrlClickId: string }>()
    const item = useStatePair<ContentResultUrlClickValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentResultUrlClickId !== undefined) {
            ContentResultUrlClickIO
                .getById(BigInt(params.contentResultUrlClickId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentResultUrlClickValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={ContentResultUrlClickIO}
                description={ContentResultUrlClickDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete/>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentResultUrlClickDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "createdAt":
            return <Body1>{ContentResultUrlClickDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a
                href={`/users/${props.item.userId}`}><Body1>{ContentResultUrlClickDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentResultUrlId":
            return <a
                href={`/contents/resultUrls/${props.item.contentResultUrlId}`}><Body1>{ContentResultUrlClickDescriptor[props.itemKey](props.item)}</Body1></a>
        case "assetStarUsageId":
            return props.item.assetStarUsageId !== undefined
                ? <a href={`/asset/stars/usages/${props.item.assetStarUsageId}`}><Body1>{ContentResultUrlClickDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
        case "userExpId":
            return props.item.userExpId !== undefined
                ? <a href={`/user/exps/${props.item.userExpId}`}><Body1>{ContentResultUrlClickDescriptor[props.itemKey](props.item)}</Body1></a>
                : <Body1>-</Body1>
    }
}