import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvStorageKeyVerboseDomain} from "../../domain/env/EnvStorageKeyVerboseDomain"
import {EnvStorageKeyId} from "../../id/env/EnvStorageKeyId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * AWS S3 인증 수단 상세
 */
export class EnvStorageKeyVerboseValueObject implements VerboseValueObject, EnvStorageKeyVerboseDomain {
    /**
     * 기본키
     */
    id: EnvStorageKeyId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 액세스 키 ID
     */
    accessKeyId: string
    /**
     * 비밀키
     */
    secretAccessKey: string
    /**
     * 버킷
     */
    bucket: string
    /**
     * 리전
     */
    region: string
    /**
     * 배포 유형
     */
    distribution: EnvClientDistribution
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 생성자 ID
     */
    creator: UserValueObject

    constructor(o: Record<keyof EnvStorageKeyVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.accessKeyId = String(o.accessKeyId)
        this.secretAccessKey = String(o.secretAccessKey)
        this.bucket = String(o.bucket)
        this.region = String(o.region)
        this.distribution = o.distribution as EnvClientDistribution
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
    }
}
