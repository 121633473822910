import {ContentDifficulty} from "../../constant/content/ContentDifficulty"
import {ContentParticipationMethod} from "../../constant/content/ContentParticipationMethod"
import {ContentScriptVisibility} from "../../constant/content/ContentScriptVisibility"
import {ContentStatus} from "../../constant/content/ContentStatus"
import {ContentType} from "../../constant/content/ContentType"
import {ContentDomain} from "../../domain/content/ContentDomain"

export const ContentDescriptor: Record<keyof ContentDomain, (o: ContentDomain) => string> = {
    id(o: ContentDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentDomain): string {
        return o.creatorId.toString()
    },
    advertiserId(o: ContentDomain): string {
        return o.advertiserId?.toString() ?? '-'
    },
    type(o: ContentDomain): string {
        return ContentType.description(o.type)
    },
    participationMethod(o: ContentDomain): string {
        return ContentParticipationMethod.description(o.participationMethod)
    },
    title(o: ContentDomain): string {
        return o.title
    },
    titleHighlight(o: ContentDomain): string {
        return o.titleHighlight ?? '-'
    },
    body(o: ContentDomain): string {
        return o.body
    },
    script(o: ContentDomain): string {
        return o.script
    },
    scriptVisibility(o: ContentDomain): string {
        return ContentScriptVisibility.description(o.scriptVisibility)
    },
    contentDetailUrlId(o: ContentDomain): string {
        return o.contentDetailUrlId.toString()
    },
    contentResultUrlId(o: ContentDomain): string {
        return o.contentResultUrlId.toString()
    },
    gradingMessage(o: ContentDomain): string {
        return o.gradingMessage ?? '-'
    },
    amount(o: ContentDomain): string {
        return o.amount.toString()
    },
    passAccuracy(o: ContentDomain): string {
        return o.passAccuracy.toString()
    },
    passAccuracyHigh(o: ContentDomain): string {
        return o.passAccuracyHigh?.toString() ?? '-'
    },
    passSolarAmount(o: ContentDomain): string {
        return o.passSolarAmount.toString()
    },
    passSolarAmountHighAccuracy(o: ContentDomain): string {
        return o.passSolarAmountHighAccuracy.toString()
    },
    starAmount(o: ContentDomain): string {
        return o.starAmount.toString()
    },
    difficulty(o: ContentDomain): string {
        return ContentDifficulty.description(o.difficulty)
    },
    headerImageCount(o: ContentDomain): string {
        return o.headerImageCount.toString()
    },
    descriptionImageCount(o: ContentDomain): string {
        return o.descriptionImageCount.toString()
    },
    exposedAt(o: ContentDomain): string {
        return o.exposedAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    startAt(o: ContentDomain): string {
        return o.startAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    endAt(o: ContentDomain): string {
        return o.endAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    statusFlags(o: ContentDomain): string {
        let enabled: string[] = []
        for (let key in ContentStatus) {
            const flag = (ContentStatus as any)[key] as { value: number, name: string } | any[]
            if (!Array.isArray(flag) && (o.statusFlags & flag.value) !== 0) {
                enabled.push(flag.name)
            }
        }

        return enabled.join('\n, ')
    },
    contentTargetCount(o: ContentDomain): string {
        return o.contentTargetCount.toString()
    },
    participationCount(o: ContentDomain): string {
        return o.participationCount.toString()
    },
    participationPassCount(o: ContentDomain): string {
        return o.participationPassCount.toString()
    },
    participationHighAccuracyCount(o: ContentDomain): string {
        return o.participationHighAccuracyCount.toString()
    },
    commentCount(o: ContentDomain): string {
        return o.commentCount.toString()
    },
    reportCount(o: ContentDomain): string {
        return o.reportCount.toString()
    },
    reviewPositiveCount(o: ContentDomain): string {
        return o.reviewPositiveCount.toString()
    },
    reviewNeutralCount(o: ContentDomain): string {
        return o.reviewNeutralCount.toString()
    },
    reviewNegativeCount(o: ContentDomain): string {
        return o.reviewNegativeCount.toString()
    },
    likeCount(o: ContentDomain): string {
        return o.likeCount.toString()
    },
    createdAt(o: ContentDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: ContentDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
