import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentDetailUrlDomain} from "../../domain/content/ContentDetailUrlDomain"
import {ContentDetailUrlId} from "../../id/content/ContentDetailUrlId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 더 알아보기 URL
 */
export class ContentDetailUrlValueObject implements ValueObject, ContentDetailUrlDomain {
    /**
     * 기본키
     */
    id: ContentDetailUrlId
    /**
     * 생성자 ID
     */
    creatorId: UserId
    /**
     * 브라우저
     */
    browser: EnvBrowser
    /**
     * 더 알아보기 URL
     */
    url: string
    /**
     * 클릭 수
     */
    clickCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date

    constructor(o: Record<keyof ContentDetailUrlDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.browser = o.browser as EnvBrowser
        this.url = String(o.url)
        this.clickCount = Number(o.clickCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
    }
}
