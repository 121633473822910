export enum UserRole {
    /**
     * 비회원
     */
    Guest = 'Guest',

    /**
     * 일반 사용자
     */
    User = 'User',

    /**
     * 관리자
     */
    Manager = 'Manager',

    /**
     * 전체 관리자
     */
    Admin = 'Admin',

    /**
     * 시스템
     */
    System = 'System'
}

export namespace UserRole {
    export function description(o: UserRole) {
        switch (o) {
            case UserRole.Guest: return '비회원'
            case UserRole.User: return '일반 사용자'
            case UserRole.Manager: return '관리자'
            case UserRole.Admin: return '전체 관리자'
            case UserRole.System: return '시스템'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: UserRole.Guest, label: description(UserRole.Guest) },
        { value: UserRole.User, label: description(UserRole.User) },
        { value: UserRole.Manager, label: description(UserRole.Manager) },
        { value: UserRole.Admin, label: description(UserRole.Admin) },
        { value: UserRole.System, label: description(UserRole.System) }
    ]
}
