import {UserAgreementDomain} from "../../domain/user/UserAgreementDomain"

export const UserAgreementDescriptor: Record<keyof UserAgreementDomain, (o: UserAgreementDomain) => string> = {
    id(o: UserAgreementDomain): string {
        return o.id.toString()
    },
    userId(o: UserAgreementDomain): string {
        return o.userId.toString()
    },
    envAgreementId(o: UserAgreementDomain): string {
        return o.envAgreementId.toString()
    },
    createdAt(o: UserAgreementDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
