import {ContentCommentModificationDomain} from "../../domain/content/ContentCommentModificationDomain"

/**
 * 댓글 수정 이력 속성 설명
 */
export const ContentCommentModificationDescription: Record<keyof ContentCommentModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    contentCommentId: '댓글 ID',
    body: '내용',
    audioId: '오디오 ID',
    createdAt: '수정일'
}
