import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {SortOrder} from "ts-protocol-extension";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {
    AssetLunarExchangeOptionValueObject
} from "../../../../data/value-object/asset/AssetLunarExchangeOptionValueObject";
import {
    AssetLunarExchangeOptionSearchOption
} from "../../../../data/search-option/asset/AssetLunarExchangeOptionSearchOption";
import {AssetLunarExchangeOptionIO} from "../../../../io/AssetIO";
import {
    AssetLunarExchangeOptionDescription
} from "../../../../data/description/asset/AssetLunarExchangeOptionDescription";
import {AssetLunarExchangeOptionDescriptor} from "../../../../data/descriptor/asset/AssetLunarExchangeOptionDescriptor";
import {
    AssetLunarExchangeOptionSearchOptionDescription
} from "../../../../data/description/asset/AssetLunarExchangeOptionSearchOptionDescription";
import {
    applyAssetLunarExchangeOptionSearchOption,
    AssetLunarExchangeOptionSearchOptionField
} from "../../../search-option-field/asset/AssetLunarExchangeOptionSearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetLunarExchangeOptionValueObject[]>([])
    const option = useStatePair<AssetLunarExchangeOptionSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetLunarExchangeOptionIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetLunarExchangeOptionDescription}
                descriptor={AssetLunarExchangeOptionDescriptor}
                showPost
                onPostClick={() => document.location = '/assets/lunars/exchangeOptions/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/lunars/exchangeOptions/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetLunarExchangeOptionSearchOptionDescription}
                fields={AssetLunarExchangeOptionSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetLunarExchangeOptionSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetLunarExchangeOptionDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}