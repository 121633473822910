import {EnvFaqModificationDomain} from "../../domain/env/EnvFaqModificationDomain"

export const EnvFaqModificationDescriptor: Record<keyof EnvFaqModificationDomain, (o: EnvFaqModificationDomain) => string> = {
    id(o: EnvFaqModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvFaqModificationDomain): string {
        return o.creatorId.toString()
    },
    envFaqId(o: EnvFaqModificationDomain): string {
        return o.envFaqId.toString()
    },
    title(o: EnvFaqModificationDomain): string {
        return o.title
    },
    displayOrder(o: EnvFaqModificationDomain): string {
        return o.displayOrder.toString()
    },
    createdAt(o: EnvFaqModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
