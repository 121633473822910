import {ContentUserProductionRewardModificationDomain} from "../../domain/content/ContentUserProductionRewardModificationDomain"

/**
 * 와글와글 보상 프리셋 수정 이력 속성 설명
 */
export const ContentUserProductionRewardModificationDescription: Record<keyof ContentUserProductionRewardModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    contentUserProductionRewardId: '와글와글 보상 프리셋 ID',
    value: '지급 솔라',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
