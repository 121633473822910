import {AssetCouponUsageDomain} from "../../domain/asset/AssetCouponUsageDomain"

export const AssetCouponUsageDescriptor: Record<keyof AssetCouponUsageDomain, (o: AssetCouponUsageDomain) => string> = {
    id(o: AssetCouponUsageDomain): string {
        return o.id.toString()
    },
    assetCouponId(o: AssetCouponUsageDomain): string {
        return o.assetCouponId.toString()
    },
    userId(o: AssetCouponUsageDomain): string {
        return o.userId.toString()
    },
    assetSolarUsageId(o: AssetCouponUsageDomain): string {
        return o.assetSolarUsageId?.toString() ?? '-'
    },
    assetLunarUsageId(o: AssetCouponUsageDomain): string {
        return o.assetLunarUsageId?.toString() ?? '-'
    },
    assetStarUsageId(o: AssetCouponUsageDomain): string {
        return o.assetStarUsageId?.toString() ?? '-'
    },
    createdAt(o: AssetCouponUsageDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
