import {ContentCommentModificationVerboseDomain} from "../../domain/content/ContentCommentModificationVerboseDomain"
import {ContentCommentId} from "../../id/content/ContentCommentId"
import {ContentCommentModificationId} from "../../id/content/ContentCommentModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentCommentValueObject} from "./ContentCommentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 댓글 수정 이력 상세
 */
export class ContentCommentModificationVerboseValueObject implements VerboseValueObject, ContentCommentModificationVerboseDomain {
    /**
     * 기본키
     */
    id: ContentCommentModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 댓글 ID
     */
    contentCommentId: ContentCommentId
    /**
     * 내용
     */
    body: string
    /**
     * 오디오 ID
     */
    audioId?: bigint
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 댓글 ID
     */
    contentComment: ContentCommentValueObject

    constructor(o: Record<keyof ContentCommentModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.contentCommentId = BigInt(o.contentCommentId)
        this.body = String(o.body)
        if (o.audioId !== undefined && o.audioId !== null) {
            this.audioId = BigInt(o.audioId)
        }
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.contentComment = new ContentCommentValueObject(o.contentComment)
    }
}
