import {RouterProps} from "../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {EnvConstructionIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {
    PostDateField,
    PostFieldWrapper,
    PostFinishDialog,
    PostInputField
} from "../../PostField";
import {EnvConstructionDescription} from "../../../data/description/env/EnvConstructionDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const title = useStatePair('')
    const body = useStatePair('')
    const startAt = useStatePair('')
    const endAt = useStatePair('')

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        EnvConstructionIO
            .post({
                title: title.value,
                body: body.value,
                startAt: new Date(startAt.value),
                endAt: new Date(endAt.value)
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [title.value, body.value, startAt.value, endAt.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={EnvConstructionDescription.title}>
                    <PostInputField
                        {...props}
                        value={title.value}
                        setter={title.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvConstructionDescription.body}>
                    <PostInputField
                        {...props}
                        value={body.value}
                        setter={body.setter} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvConstructionDescription.startAt}>
                    <PostDateField
                        {...props}
                        value={startAt.value}
                        setter={startAt.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={EnvConstructionDescription.endAt}>
                    <PostDateField
                        {...props}
                        value={endAt.value}
                        setter={endAt.setter}
                        maxLength={19} />
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/env/constructions'}/>
    </>
}