import {RouterProps} from "../RouterProps";
import {Route, Routes} from "react-router-dom";
import AgreementRouter from "./agreement/AgreementRouter";
import ClientRouter from "./client/ClientRouter";
import ConstructionRouter from "./construction/ConstructionRouter";
import CountryRouter from "./country/CountryRouter";
import FaqRouter from "./faq/FaqRouter";
import PolicyRouter from "./policy/PolicyRouter";
import StorageKeyRouter from "./storageKey/StorageKeyRouter";
import {Button} from "@fluentui/react-components";
import {ChevronRight24Filled} from "@fluentui/react-icons";
import PhoneBlockRouter from "./phoneBlocks/PhoneBlockRouter";
import BankRouter from "./bank/BankRouter";
import EventRouter from "./event/EventRouter";
import NoticeRouter from "./notice/NoticeRouter";

export default function EnvRouter(props: RouterProps) {
    return <>
        <Routes>
            <Route path={'/agreements/*'} element={<AgreementRouter {...props} />} />
            <Route path={'/banks/*'} element={<BankRouter {...props} />} />
            <Route path={'/clients/*'} element={<ClientRouter {...props} />} />
            <Route path={'/constructions/*'} element={<ConstructionRouter {...props} />} />
            <Route path={'/countries/*'} element={<CountryRouter {...props} />} />
            <Route path={'/events/*'} element={<EventRouter {...props} />} />
            <Route path={'/faqs/*'} element={<FaqRouter {...props} />} />
            <Route path={'/notices/*'} element={<NoticeRouter {...props} />} />
            <Route path={'/phoneBlocks/*'} element={<PhoneBlockRouter {...props} />} />
            <Route path={'/policies/*'} element={<PolicyRouter {...props} />} />
            <Route path={'/storageKeys/*'} element={<StorageKeyRouter {...props} />} />
            <Route path={'/*'} element={<Navigation {...props} />} />
        </Routes>
    </>
}

function Navigation(props: RouterProps) {
    return <>
        <div className={props.styles.column8}>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/agreements'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                이용 약관
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/banks'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                은행
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/clients'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                클라이언트
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/constructions'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                점검
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/countries'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                국가
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/events'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                이벤트
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/faqs'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                자주 묻는 질문
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/faqs/items'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                자주 묻는 질문 항목
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/notices'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                공지사항
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/policies'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                환경 정책
            </Button>
            <Button
                className={props.styles.navigationButton}
                as={'a'}
                href={'/env/storageKeys'}
                appearance={'subtle'}
                icon={<ChevronRight24Filled/>}
                iconPosition={'after'}>
                AWS IAM 사용자
            </Button>
        </div>
    </>
}