import {UiHomeNoticeModificationDomain} from "../../domain/ui/UiHomeNoticeModificationDomain"

export const UiHomeNoticeModificationDescriptor: Record<keyof UiHomeNoticeModificationDomain, (o: UiHomeNoticeModificationDomain) => string> = {
    id(o: UiHomeNoticeModificationDomain): string {
        return o.id.toString()
    },
    creatorId(o: UiHomeNoticeModificationDomain): string {
        return o.creatorId.toString()
    },
    uiHomeNoticeId(o: UiHomeNoticeModificationDomain): string {
        return o.uiHomeNoticeId.toString()
    },
    displayOrder(o: UiHomeNoticeModificationDomain): string {
        return o.displayOrder.toString()
    },
    url(o: UiHomeNoticeModificationDomain): string {
        return o.url
    },
    createdAt(o: UiHomeNoticeModificationDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
