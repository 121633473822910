import {ContentUserProductionDurationDomain} from "../../domain/content/ContentUserProductionDurationDomain"

/**
 * 와글와글 기간 프리셋 속성 설명
 */
export const ContentUserProductionDurationDescription: Record<keyof ContentUserProductionDurationDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    value: '기간(주)',
    displayOrder: '표시 순서',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
