export enum AssetLunarUsageType {
    /**
     * 관리자 지급
     */
    Admin = 'Admin',

    /**
     * 콘텐츠 제작
     */
    ContentCreation = 'ContentCreation',

    /**
     * 구매
     */
    Purchase = 'Purchase',

    /**
     * 솔라를 사용하여 루나 교환
     */
    Exchange = 'Exchange',

    /**
     * 회원가입
     */
    SignUp = 'SignUp',

    /**
     * 추천인
     */
    UserRecommender = 'UserRecommender',

    /**
     * 피추천인
     */
    UserRecommendee = 'UserRecommendee',

    /**
     * 레벨업 보상
     */
    UserLevelReward = 'UserLevelReward',

    /**
     * 랭크 보상
     */
    UserRankReward = 'UserRankReward',

    /**
     * 쿠폰
     */
    Coupon = 'Coupon'
}

export namespace AssetLunarUsageType {
    export function description(o: AssetLunarUsageType) {
        switch (o) {
            case AssetLunarUsageType.Admin: return '관리자 지급'
            case AssetLunarUsageType.ContentCreation: return '콘텐츠 제작'
            case AssetLunarUsageType.Purchase: return '구매'
            case AssetLunarUsageType.Exchange: return '솔라를 사용하여 루나 교환'
            case AssetLunarUsageType.SignUp: return '회원가입'
            case AssetLunarUsageType.UserRecommender: return '추천인'
            case AssetLunarUsageType.UserRecommendee: return '피추천인'
            case AssetLunarUsageType.UserLevelReward: return '레벨업 보상'
            case AssetLunarUsageType.UserRankReward: return '랭크 보상'
            case AssetLunarUsageType.Coupon: return '쿠폰'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: AssetLunarUsageType.Admin, label: description(AssetLunarUsageType.Admin) },
        { value: AssetLunarUsageType.ContentCreation, label: description(AssetLunarUsageType.ContentCreation) },
        { value: AssetLunarUsageType.Purchase, label: description(AssetLunarUsageType.Purchase) },
        { value: AssetLunarUsageType.Exchange, label: description(AssetLunarUsageType.Exchange) },
        { value: AssetLunarUsageType.SignUp, label: description(AssetLunarUsageType.SignUp) },
        { value: AssetLunarUsageType.UserRecommender, label: description(AssetLunarUsageType.UserRecommender) },
        { value: AssetLunarUsageType.UserRecommendee, label: description(AssetLunarUsageType.UserRecommendee) },
        { value: AssetLunarUsageType.UserLevelReward, label: description(AssetLunarUsageType.UserLevelReward) },
        { value: AssetLunarUsageType.UserRankReward, label: description(AssetLunarUsageType.UserRankReward) },
        { value: AssetLunarUsageType.Coupon, label: description(AssetLunarUsageType.Coupon) }
    ]
}
