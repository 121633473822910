import {UiHomeNoticeModificationDomain} from "../../domain/ui/UiHomeNoticeModificationDomain"
import {UiHomeNoticeId} from "../../id/ui/UiHomeNoticeId"
import {UiHomeNoticeModificationId} from "../../id/ui/UiHomeNoticeModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 홈 공지 및 이벤트 수정 이력
 */
export class UiHomeNoticeModificationValueObject implements ValueObject, UiHomeNoticeModificationDomain {
    /**
     * 기본키
     */
    id: UiHomeNoticeModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 홈 공지 및 이벤트 ID
     */
    uiHomeNoticeId: UiHomeNoticeId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof UiHomeNoticeModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiHomeNoticeId = BigInt(o.uiHomeNoticeId)
        this.displayOrder = Number(o.displayOrder)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
    }
}
