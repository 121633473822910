import {ContentCommentReportReasonModificationDomain} from "../../domain/content/ContentCommentReportReasonModificationDomain"

/**
 * 댓글 신고 사유 수정 이력 속성 설명
 */
export const ContentCommentReportReasonModificationDescription: Record<keyof ContentCommentReportReasonModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    contentCommentReportReasonId: '댓글 신고 사유 ID',
    body: '내용',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
