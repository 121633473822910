import {UserRankHistorySearchOptionDescription} from "../../../data/description/user/UserRankHistorySearchOptionDescription"
import {UserRankHistorySearchOption} from "../../../data/search-option/user/UserRankHistorySearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function UserRankHistorySearchOptionField(props: ListOptionFieldProps<UserRankHistorySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userRankIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userRankIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userRankIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "userRankIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.userRankIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsageIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetLunarUsageIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetLunarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetLunarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetLunarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsageIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetLunarUsageIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetSolarUsageIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetSolarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetSolarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetSolarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetSolarUsageIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetStarUsageIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetStarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetStarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetStarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageIdIsNull":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.assetStarUsageIdIsNull}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={UserRankHistorySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyUserRankHistorySearchOption(key: keyof UserRankHistorySearchOption, value: string, into: StatePair<UserRankHistorySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "userIdEq":
            into.setter({
                ...into.value,
                userIdEq: BigInt(value)
            })
            break
        case "userIdNeq":
            into.setter({
                ...into.value,
                userIdNeq: BigInt(value)
            })
            break
        case "userIdMin":
            into.setter({
                ...into.value,
                userIdMin: BigInt(value)
            })
            break
        case "userIdMax":
            into.setter({
                ...into.value,
                userIdMax: BigInt(value)
            })
            break
        case "userRankIdEq":
            into.setter({
                ...into.value,
                userRankIdEq: BigInt(value)
            })
            break
        case "userRankIdNeq":
            into.setter({
                ...into.value,
                userRankIdNeq: BigInt(value)
            })
            break
        case "userRankIdMin":
            into.setter({
                ...into.value,
                userRankIdMin: BigInt(value)
            })
            break
        case "userRankIdMax":
            into.setter({
                ...into.value,
                userRankIdMax: BigInt(value)
            })
            break
        case "assetLunarUsageIdEq":
            into.setter({
                ...into.value,
                assetLunarUsageIdEq: BigInt(value)
            })
            break
        case "assetLunarUsageIdNeq":
            into.setter({
                ...into.value,
                assetLunarUsageIdNeq: BigInt(value)
            })
            break
        case "assetLunarUsageIdMin":
            into.setter({
                ...into.value,
                assetLunarUsageIdMin: BigInt(value)
            })
            break
        case "assetLunarUsageIdMax":
            into.setter({
                ...into.value,
                assetLunarUsageIdMax: BigInt(value)
            })
            break
        case "assetLunarUsageIdIsNull":
            into.setter({
                ...into.value,
                assetLunarUsageIdIsNull: Boolean(value)
            })
            break
        case "assetSolarUsageIdEq":
            into.setter({
                ...into.value,
                assetSolarUsageIdEq: BigInt(value)
            })
            break
        case "assetSolarUsageIdNeq":
            into.setter({
                ...into.value,
                assetSolarUsageIdNeq: BigInt(value)
            })
            break
        case "assetSolarUsageIdMin":
            into.setter({
                ...into.value,
                assetSolarUsageIdMin: BigInt(value)
            })
            break
        case "assetSolarUsageIdMax":
            into.setter({
                ...into.value,
                assetSolarUsageIdMax: BigInt(value)
            })
            break
        case "assetSolarUsageIdIsNull":
            into.setter({
                ...into.value,
                assetSolarUsageIdIsNull: Boolean(value)
            })
            break
        case "assetStarUsageIdEq":
            into.setter({
                ...into.value,
                assetStarUsageIdEq: BigInt(value)
            })
            break
        case "assetStarUsageIdNeq":
            into.setter({
                ...into.value,
                assetStarUsageIdNeq: BigInt(value)
            })
            break
        case "assetStarUsageIdMin":
            into.setter({
                ...into.value,
                assetStarUsageIdMin: BigInt(value)
            })
            break
        case "assetStarUsageIdMax":
            into.setter({
                ...into.value,
                assetStarUsageIdMax: BigInt(value)
            })
            break
        case "assetStarUsageIdIsNull":
            into.setter({
                ...into.value,
                assetStarUsageIdIsNull: Boolean(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
