import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {useEffect} from "react";
import {
    ContentCommentIO,
    ContentCommentModificationIO,
    ContentCommentLikeIO,
    ContentCommentReportIO
} from "../../../io/ContentIO";
import {Body1, Spinner, Tab} from "@fluentui/react-components";
import {ContentCommentDescription} from "../../../data/description/content/ContentCommentDescription";
import {ContentCommentDomain} from "../../../data/domain/content/ContentCommentDomain";
import {ContentCommentDescriptor} from "../../../data/descriptor/content/ContentCommentDescriptor";
import {ContentCommentModificationValueObject} from "../../../data/value-object/content/ContentCommentModificationValueObject";
import {ContentCommentModificationSearchOption} from "../../../data/search-option/content/ContentCommentModificationSearchOption";
import {SearchIndex} from "../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../ListPageWrapper";
import {ContentCommentModificationDescription} from "../../../data/description/content/ContentCommentModificationDescription";
import {ContentCommentModificationDescriptor} from "../../../data/descriptor/content/ContentCommentModificationDescriptor";
import {ContentCommentValueObject} from "../../../data/value-object/content/ContentCommentValueObject";
import {ContentCommentSearchOption} from "../../../data/search-option/content/ContentCommentSearchOption";
import {ContentCommentLikeValueObject} from "../../../data/value-object/content/ContentCommentLikeValueObject";
import {ContentCommentLikeSearchOption} from "../../../data/search-option/content/ContentCommentLikeSearchOption";
import {ContentCommentLikeDescription} from "../../../data/description/content/ContentCommentLikeDescription";
import {ContentCommentLikeDescriptor} from "../../../data/descriptor/content/ContentCommentLikeDescriptor";
import {ContentCommentReportValueObject} from "../../../data/value-object/content/ContentCommentReportValueObject";
import {ContentCommentReportSearchOption} from "../../../data/search-option/content/ContentCommentReportSearchOption";
import {ContentCommentReportDescription} from "../../../data/description/content/ContentCommentReportDescription";
import {ContentCommentReportDescriptor} from "../../../data/descriptor/content/ContentCommentReportDescriptor";
import {DetailDefaultPanel, DetailTab} from "../../DetailViews";

enum Panel {
    Default,
    ContentCommentModificationList,
    ContentCommentChildList,
    ContentCommentLikeList,
    ContentCommentReportList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentCommentId: string }>()
    const item = useStatePair<ContentCommentValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentCommentId !== undefined) {
            ContentCommentIO
                .getById(BigInt(params.contentCommentId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentCommentValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    return <>
        <div className={props.styles.row16}>
            <DetailTab
                {...props}
                panel={panel}>
                <Tab value={Panel.ContentCommentModificationList}>수정 이력</Tab>
                <Tab value={Panel.ContentCommentChildList}>대댓글 목록</Tab>
                <Tab value={Panel.ContentCommentLikeList}>좋아요 목록</Tab>
                <Tab value={Panel.ContentCommentReportList}>신고 목록</Tab>
            </DetailTab>
            <DetailDefaultPanel
                {...props}
                visible={panel.value === Panel.Default}
                client={ContentCommentIO}
                description={ContentCommentDescription}
                properties={props => <PropertyBody {...props} />}/>
            <ContentCommentModificationListPanel
                {...props}
                panel={panel.value} />
            <ContentCommentChildListPanel
                {...props}
                panel={panel.value} />
            <ContentCommentLikeListPanel
                {...props}
                panel={panel.value} />
            <ContentCommentReportListPanel
                {...props}
                panel={panel.value} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentCommentDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "audioId":
        case "body":
        case "childCount":
        case "likeCount":
        case "reportCount":
        case "lastModifiedAt":
        case "concealedAt":
        case "createdAt":
            return <Body1>{ContentCommentDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{ContentCommentDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentId":
            return <a href={`/contents/${props.item.contentId}`}><Body1>{ContentCommentDescriptor[props.itemKey](props.item)}</Body1></a>
        case "parentId":
            return <a href={`/contents/comments/${props.item.parentId}`}><Body1>{ContentCommentDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function ContentCommentModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentCommentModificationValueObject[]>([])
    const modificationSearchOption: ContentCommentModificationSearchOption = { contentCommentIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentCommentModificationList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentCommentModificationDescription}
                descriptor={ContentCommentModificationDescriptor} />
        </>}
    </>
}

function ContentCommentChildListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentCommentValueObject[]>([])
    const modificationSearchOption: ContentCommentSearchOption = { parentIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentCommentChildList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentCommentDescription}
                descriptor={ContentCommentDescriptor}
                onItemClick={item => `/contents/comments/${item.id}`}/>
        </>}
    </>
}

function ContentCommentLikeListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentCommentLikeValueObject[]>([])
    const modificationSearchOption: ContentCommentLikeSearchOption = { contentCommentIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentCommentLikeList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentLikeIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentCommentLikeDescription}
                descriptor={ContentCommentLikeDescriptor}
                onItemClick={item => `/contents/comments/likes/${item.id}`} />
        </>}
    </>
}

function ContentCommentReportListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentCommentReportValueObject[]>([])
    const modificationSearchOption: ContentCommentReportSearchOption = { contentCommentIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentCommentReportList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentReportIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentCommentReportDescription}
                descriptor={ContentCommentReportDescriptor}
                onItemClick={item => `/contents/comments/reports/${item.id}`}/>
        </>}
    </>
}