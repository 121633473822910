import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    AssetCouponValueObject
} from "../../../data/value-object/asset/AssetCouponValueObject";
import {useEffect} from "react";
import {AssetCouponIO} from "../../../io/AssetIO";
import {Body1, Spinner} from "@fluentui/react-components";
import {DetailDefaultPanel, DetailDefaultTab} from "../../DetailViews";
import {AssetCouponDescription} from "../../../data/description/asset/AssetCouponDescription";
import {AssetCouponDomain} from "../../../data/domain/asset/AssetCouponDomain";
import {AssetCouponDescriptor} from "../../../data/descriptor/asset/AssetCouponDescriptor";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetCouponId: string }>()
    const item = useStatePair<AssetCouponValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetCouponId !== undefined) {
            AssetCouponIO
                .getById(BigInt(params.assetCouponId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetCouponValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetCouponIO}
                description={AssetCouponDescription}
                properties={props => <PropertyBody {...props} />} />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetCouponDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "code":
        case "amount":
        case "assetLunarAmount":
        case "assetSolarAmount":
        case "assetStarAmount":
        case "usageCount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{AssetCouponDescriptor[props.itemKey](props.item)}</Body1>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetCouponDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}