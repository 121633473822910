import {RouterProps} from "../RouterProps";
import {useParams} from "react-router-dom";
import {useBooleanPair, useStatePair} from "react-type-extension";
import {ContentValueObject} from "../../data/value-object/content/ContentValueObject";
import {useEffect} from "react";
import {
    ContentCommentIO,
    ContentIO,
    ContentModificationIO,
    ContentParticipationIO,
    ContentReportIO,
    ContentReviewIO,
    ContentTargetIO
} from "../../io/ContentIO";
import {
    Body1,
    Button,
    Caption1Strong,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Spinner,
    Tab,
    TabList
} from "@fluentui/react-components";
import {ContentModificationValueObject} from "../../data/value-object/content/ContentModificationValueObject";
import {ContentModificationSearchOption} from "../../data/search-option/content/ContentModificationSearchOption";
import {SearchIndex} from "../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import {ContentParticipationValueObject} from "../../data/value-object/content/ContentParticipationValueObject";
import {ContentParticipationSearchOption} from "../../data/search-option/content/ContentParticipationSearchOption";
import {mapByKey} from "../../util/ObjectUtil";
import {ContentDescription} from "../../data/description/content/ContentDescription";
import ListPageWrapper from "../ListPageWrapper";
import {ContentModificationDescription} from "../../data/description/content/ContentModificationDescription";
import {ContentModificationDescriptor} from "../../data/descriptor/content/ContentModificationDescriptor";
import {ContentParticipationDescription} from "../../data/description/content/ContentParticipationDescription";
import {ContentParticipationDescriptor} from "../../data/descriptor/content/ContentParticipationDescriptor";
import {ContentDomain} from "../../data/domain/content/ContentDomain";
import {ContentDescriptor} from "../../data/descriptor/content/ContentDescriptor";
import {ContentCommentValueObject} from "../../data/value-object/content/ContentCommentValueObject";
import {ContentCommentSearchOption} from "../../data/search-option/content/ContentCommentSearchOption";
import {ContentCommentDescription} from "../../data/description/content/ContentCommentDescription";
import {ContentCommentDescriptor} from "../../data/descriptor/content/ContentCommentDescriptor";
import {ContentReportValueObject} from "../../data/value-object/content/ContentReportValueObject";
import {ContentReportSearchOption} from "../../data/search-option/content/ContentReportSearchOption";
import {ContentReportDescription} from "../../data/description/content/ContentReportDescription";
import {ContentReportDescriptor} from "../../data/descriptor/content/ContentReportDescriptor";
import {ContentReviewValueObject} from "../../data/value-object/content/ContentReviewValueObject";
import {ContentReviewSearchOption} from "../../data/search-option/content/ContentReviewSearchOption";
import {ContentReviewDescription} from "../../data/description/content/ContentReviewDescription";
import {ContentReviewDescriptor} from "../../data/descriptor/content/ContentReviewDescriptor";
import {ContentTargetValueObject} from "../../data/value-object/content/ContentTargetValueObject";
import {ContentTargetSearchOption} from "../../data/search-option/content/ContentTargetSearchOption";
import {ContentTargetDescription} from "../../data/description/content/ContentTargetDescription";
import {ContentTargetDescriptor} from "../../data/descriptor/content/ContentTargetDescriptor";
import {ContentType} from "../../data/constant/content/ContentType";
import {StorageKey} from "../../io/StorageIO";
import {Media, MediaFile, MediaList} from "../Media";

enum Panel {
    Default,
    ModificationList,
    ContentParticipationList,
    ContentCommentList,
    ContentReportList,
    ContentReviewList,
    ContentTargetList
}

export default function Detail(props: RouterProps) {
    const params = useParams<{ contentId: string }>()
    const item = useStatePair<ContentValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.contentId !== undefined) {
            ContentIO
                .getById(BigInt(params.contentId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: ContentValueObject
}

function Page(props: PageProps) {
    const panel = useStatePair<Panel>(Panel.Default)
    const thumbnailImageUrl = useStatePair<MediaFile | undefined>(undefined)
    const headerImageUrls = useStatePair<MediaFile[] | undefined>(undefined)
    const descriptionImageUrls = useStatePair<MediaFile[] | undefined>(undefined)
    const participationMediaUrl = useStatePair<MediaFile | undefined>(undefined)
    const isDeleteDialogVisible = useBooleanPair(false)
    const isDeleting = useBooleanPair(false)

    useEffect(() => {
        if (props.item.type === ContentType.Daily) {
            return
        }

        MediaFile
            .createFromStorageKey(StorageKey.Content.thumbnail(props.item.id))
            .then(thumbnailImageUrl.setter)
            .catch(reason => {
                console.error(reason)
                alert('썸네일 이미지를 불러올 수 없습니다. 이미지를 먼저 등록해주세요.')
                document.location = `/contents/${props.item.id}/edit`
            })
        MediaFile
            .createFromStorageKeys(StorageKey.Content.headers(props.item.id, props.item.headerImageCount))
            .then(headerImageUrls.setter)
            .catch(reason => {
                console.error(reason)
                alert('상단 이미지를 불러올 수 없습니다. 이미지를 먼저 등록해주세요.')
                document.location = `/contents/${props.item.id}/edit`
            })
        MediaFile
            .createFromStorageKeys(StorageKey.Content.descriptions(props.item.id, props.item.descriptionImageCount))
            .then(descriptionImageUrls.setter)
            .catch(reason => {
                console.error(reason)
                alert('설명 이미지를 불러올 수 없습니다. 이미지를 먼저 등록해주세요.')
                document.location = `/contents/${props.item.id}/edit`
            })
        MediaFile
            .createFromStorageKey(StorageKey.Content.participation(props.item.id))
            .then(participationMediaUrl.setter)
            .catch(reason => {
                console.error(reason)
                alert('참여 이미지 또는 비디오를 불러올 수 없습니다. 미디어를 먼저 등록해주세요.')
                document.location = `/contents/${props.item.id}/edit`
            })
    }, []);

    const onDeleteClick = () => {
        isDeleting.setTrue()
        ContentIO
            .delete(props.item.id)
            .then(() => {
                alert('삭제되었습니다.')
                document.location = '/contents'
            })
            .catch(reason => {
                console.error(reason)
                alert('삭제를 실패했습니다.')
                isDeleting.setFalse()
            })
    }

    return <>
        <div className={props.styles.row16}>
            <TabList
                selectedValue={panel.value}
                onTabSelect={(_, data) => panel.setter(data.value as Panel)}
                vertical>
                <Tab value={Panel.Default}>상세</Tab>
                <Tab value={Panel.ModificationList}>수정 이력</Tab>
                <Tab value={Panel.ContentParticipationList}>참여 목록</Tab>
                <Tab value={Panel.ContentCommentList}>댓글 목록</Tab>
                <Tab value={Panel.ContentReportList}>신고 목록</Tab>
                {props.item.type === ContentType.UserProduction && <Tab value={Panel.ContentReviewList}>평가 목록</Tab>}
                <Tab value={Panel.ContentTargetList}>대상 목록</Tab>
            </TabList>
            {panel.value === Panel.Default && <>
                <div className={props.styles.column16}>
                    {mapByKey(ContentDescription, key => <>
                        <div className={props.styles.column4}>
                            <Caption1Strong>{ContentDescription[key]}</Caption1Strong>
                            <PropertyBody {...props} itemKey={key}></PropertyBody>
                        </div>
                    </>)}
                    <div className={props.styles.column4}>
                        <Caption1Strong>썸네일 이미지</Caption1Strong>
                        {thumbnailImageUrl.value !== undefined && <>
                            <Media
                                {...props}
                                source={thumbnailImageUrl.value}/>
                        </>}
                    </div>
                    <div className={props.styles.column4}>
                        <Caption1Strong>상단 이미지</Caption1Strong>
                        {headerImageUrls.value !== undefined && <>
                            <MediaList
                                {...props}
                                sources={headerImageUrls.value}/>
                        </>}
                    </div>
                    <div className={props.styles.column4}>
                        <Caption1Strong>설명 이미지</Caption1Strong>
                        {descriptionImageUrls.value !== undefined && <>
                            <MediaList
                                {...props}
                                sources={descriptionImageUrls.value}/>
                        </>}
                    </div>
                    <div className={props.styles.column4}>
                        <Caption1Strong>참여 이미지 또는 비디오</Caption1Strong>
                        {participationMediaUrl.value !== undefined && <>
                            <Media
                                {...props}
                                source={participationMediaUrl.value}/>
                        </>}
                    </div>
                    <div className={props.styles.row8}>
                        {props.item.concealedAt === undefined &&
                            <Button onClick={() => document.location = `/contents/${props.item.id}/edit`}>수정</Button>}
                        {props.item.concealedAt === undefined &&
                            <Button onClick={isDeleteDialogVisible.setTrue}>삭제</Button>}
                    </div>
                </div>
            </>}
            <ContentModificationListPanel
                {...props}
                panel={panel.value}/>
            <ContentParticipationListPanel
                {...props}
                panel={panel.value}/>
            <ContentCommentListPanel
                {...props}
                panel={panel.value}/>
            <ContentReportListPanel
                {...props}
                panel={panel.value} />
            <ContentReviewListPanel
                {...props}
                panel={panel.value} />
            <ContentTargetListPanel
                {...props}
                panel={panel.value} />
            <Dialog open={isDeleteDialogVisible.value}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>삭제</DialogTitle>
                        <DialogContent>이 항목을 삭제하시겠습니까?</DialogContent>
                    </DialogBody>
                    <DialogActions>
                        <Button
                            disabled={isDeleting.value}
                            onClick={onDeleteClick}>예</Button>
                        <Button
                            disabled={isDeleting.value}
                            onClick={() => isDeleteDialogVisible.setFalse()}>아니오</Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof ContentDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "advertiserId":
        case "type":
        case "participationMethod":
        case "title":
        case "titleHighlight":
        case "body":
        case "script":
        case "scriptVisibility":
        case "gradingMessage":
        case "amount":
        case "passAccuracy":
        case "passAccuracyHigh":
        case "passSolarAmount":
        case "passSolarAmountHighAccuracy":
        case "starAmount":
        case "difficulty":
        case "headerImageCount":
        case "descriptionImageCount":
        case "exposedAt":
        case "startAt":
        case "endAt":
        case "statusFlags":
        case "contentTargetCount":
        case "participationCount":
        case "participationPassCount":
        case "participationHighAccuracyCount":
        case "commentCount":
        case "reviewPositiveCount":
        case "reviewNeutralCount":
        case "reviewNegativeCount":
        case "reportCount":
        case "likeCount":
        case "createdAt":
        case "lastModifiedAt":
        case "concealedAt":
            return <Body1>{ContentDescriptor[props.itemKey](props.item)}</Body1>
        case "contentDetailUrlId":
            return <a href={`/contents/detailUrls/${props.item.contentDetailUrlId}`}><Body1>{ContentDescriptor[props.itemKey](props.item)}</Body1></a>
        case "contentResultUrlId":
            return <a href={`/contents/resultUrls/${props.item.contentResultUrlId}`}><Body1>{ContentDescriptor[props.itemKey](props.item)}</Body1></a>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{ContentDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}

type PanelProps = PageProps & {
    panel: Panel
}

function ContentModificationListPanel(props: PanelProps) {
    const modifications = useStatePair<ContentModificationValueObject[]>([])
    const modificationSearchOption: ContentModificationSearchOption = { contentIdEq: props.item.id }
    const modificationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ModificationList && <>
            <ListPageWrapper
                {...props}
                client={ContentModificationIO}
                items={modifications}
                option={modificationSearchOption}
                index={modificationSearchIndex}
                description={ContentModificationDescription}
                descriptor={ContentModificationDescriptor} />
        </>}
    </>
}

function ContentParticipationListPanel(props: PanelProps) {
    const contentParticipations = useStatePair<ContentParticipationValueObject[]>([])
    const contentParticipationSearchOption: ContentParticipationSearchOption = { contentIdEq: props.item.id }
    const contentParticipationSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentParticipationList && <>
            <ListPageWrapper
                {...props}
                client={ContentParticipationIO}
                items={contentParticipations}
                option={contentParticipationSearchOption}
                index={contentParticipationSearchIndex}
                description={ContentParticipationDescription}
                descriptor={ContentParticipationDescriptor} />
        </>}
    </>
}

function ContentCommentListPanel(props: PanelProps) {
    const contentComments = useStatePair<ContentCommentValueObject[]>([])
    const contentCommentSearchOption: ContentCommentSearchOption = { contentIdEq: props.item.id }
    const contentCommentSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentCommentList && <>
            <ListPageWrapper
                {...props}
                client={ContentCommentIO}
                items={contentComments}
                option={contentCommentSearchOption}
                index={contentCommentSearchIndex}
                description={ContentCommentDescription}
                descriptor={ContentCommentDescriptor} />
        </>}
    </>
}

function ContentReportListPanel(props: PanelProps) {
    const contentReports = useStatePair<ContentReportValueObject[]>([])
    const contentReportSearchOption: ContentReportSearchOption = { contentIdEq: props.item.id }
    const contentReportSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentReportList && <>
            <ListPageWrapper
                {...props}
                client={ContentReportIO}
                items={contentReports}
                option={contentReportSearchOption}
                index={contentReportSearchIndex}
                description={ContentReportDescription}
                descriptor={ContentReportDescriptor} />
        </>}
    </>
}

function ContentReviewListPanel(props: PanelProps) {
    const contentReviews = useStatePair<ContentReviewValueObject[]>([])
    const contentReviewSearchOption: ContentReviewSearchOption = { contentIdEq: props.item.id }
    const contentReviewSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentReviewList && <>
            <ListPageWrapper
                {...props}
                client={ContentReviewIO}
                items={contentReviews}
                option={contentReviewSearchOption}
                index={contentReviewSearchIndex}
                description={ContentReviewDescription}
                descriptor={ContentReviewDescriptor} />
        </>}
    </>
}

function ContentTargetListPanel(props: PanelProps) {
    const contentTargets = useStatePair<ContentTargetValueObject[]>([])
    const contentTargetSearchOption: ContentTargetSearchOption = { contentIdEq: props.item.id }
    const contentTargetSearchIndex = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {props.panel === Panel.ContentTargetList && <>
            <ListPageWrapper
                {...props}
                client={ContentTargetIO}
                items={contentTargets}
                option={contentTargetSearchOption}
                index={contentTargetSearchIndex}
                description={ContentTargetDescription}
                descriptor={ContentTargetDescriptor}
                showPost
                onPostClick={() => window.open(`/contents/${props.item.id}/targets/post`, '_blank')} />
        </>}
    </>
}