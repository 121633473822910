import {EnvNoticeDomain} from "../../domain/env/EnvNoticeDomain"

export const EnvNoticeDescriptor: Record<keyof EnvNoticeDomain, (o: EnvNoticeDomain) => string> = {
    id(o: EnvNoticeDomain): string {
        return o.id.toString()
    },
    creatorId(o: EnvNoticeDomain): string {
        return o.creatorId.toString()
    },
    title(o: EnvNoticeDomain): string {
        return o.title
    },
    url(o: EnvNoticeDomain): string {
        return o.url
    },
    createdAt(o: EnvNoticeDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: EnvNoticeDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: EnvNoticeDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
