import {UserSignInDomain} from "../../domain/user/UserSignInDomain"

/**
 * 사용자 로그인 속성 설명
 */
export const UserSignInDescription: Record<keyof UserSignInDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    envClientId: '클라이언트 ID',
    remoteAddress: '요청자 주소',
    deviceName: '장치 이름',
    deviceVersion: '장치 버전',
    createdAt: '생성일'
}
