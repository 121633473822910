import {ContentReportDomain} from "../../domain/content/ContentReportDomain"
import {ContentId} from "../../id/content/ContentId"
import {ContentReportId} from "../../id/content/ContentReportId"
import {ContentReportReasonId} from "../../id/content/ContentReportReasonId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 신고
 */
export class ContentReportValueObject implements ValueObject, ContentReportDomain {
    /**
     * 기본키
     */
    id: ContentReportId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 콘텐츠 신고 사유 ID
     */
    contentReportReasonId: ContentReportReasonId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof ContentReportDomain, any>) {
        this.id = BigInt(o.id)
        this.contentId = BigInt(o.contentId)
        this.contentReportReasonId = BigInt(o.contentReportReasonId)
        this.userId = BigInt(o.userId)
        this.createdAt = new Date(o.createdAt)
    }
}
