import {EnvConstructionSearchOption} from "../../search-option/env/EnvConstructionSearchOption"

/**
 * 작업 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const EnvConstructionSearchOptionDescription: Record<keyof EnvConstructionSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    titleEq: '제목 일치',
    titleNeq: '제목 불일치',
    titleMin: '제목 최소',
    titleMax: '제목 최대',
    titleLike: '제목 포함',
    titleNotLike: '제목 제외',
    bodyEq: '내용 일치',
    bodyNeq: '내용 불일치',
    bodyMin: '내용 최소',
    bodyMax: '내용 최대',
    bodyLike: '내용 포함',
    bodyNotLike: '내용 제외',
    startAtEq: '시작일 일치',
    startAtNeq: '시작일 불일치',
    startAtMin: '시작일 최소',
    startAtMax: '시작일 최대',
    endAtEq: '종료일 일치',
    endAtNeq: '종료일 불일치',
    endAtMin: '종료일 최소',
    endAtMax: '종료일 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
    concealedAtEq: '삭제일 일치',
    concealedAtNeq: '삭제일 불일치',
    concealedAtMin: '삭제일 최소',
    concealedAtMax: '삭제일 최대',
    concealedAtIsNull: '삭제일 값 존재 여부',
}
