import {RouterProps} from "../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {EnvCountryVerboseValueObject} from "../../../data/value-object/env/EnvCountryVerboseValueObject";
import {useCallback, useEffect} from "react";
import {EnvCountryIO} from "../../../io/EnvIO";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostInputField, PostNumberField} from "../../PostField";
import {EnvCountryDescription} from "../../../data/description/env/EnvCountryDescription";

enum Phase {
    Loading,
    Waiting,
    Putting,
    Finished,
}

export default function Edit(props: RouterProps) {
    const params = useParams<{ envCountryId: string }>()
    const item = useStatePair<EnvCountryVerboseValueObject | undefined>(undefined)
    const phase = useStatePair<Phase>(Phase.Loading)
    const name = useStatePair('')
    const isoCode = useStatePair('')
    const phoneCode = useStatePair(0)
    const emoji = useStatePair('')

    useEffect(() => {
        if (item.value === undefined && params.envCountryId !== undefined) {
            EnvCountryIO
                .getVerboseById(BigInt(params.envCountryId))
                .then(value => {
                    item.setter(value)
                    name.setter(value.name)
                    isoCode.setter(value.isoCode)
                    phoneCode.setter(value.phoneCode)
                    emoji.setter(value.emoji)
                    phase.setter(Phase.Waiting)
                })
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    const onDoneClick = useCallback(() => {
        const id = item.value?.id
        if (id === undefined) {
            return
        }

        phase.setter(Phase.Putting)
        EnvCountryIO
            .put(id, {
                name: name.value,
                isoCode: isoCode.value,
                phoneCode: phoneCode.value,
                emoji: emoji.value
            })
            .then(() => {
                phase.setter(Phase.Finished)
                alert('수정되었습니다.')
                document.location = `/env/countries/${id}`
            })
            .catch(reason => {
                console.error(reason)
                alert('수정을 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [name.value, phoneCode.value])

    return <>
        <div className={props.styles.column16}>
            <Title3>수정</Title3>
            <PostFieldWrapper
                {...props}
                title={EnvCountryDescription.name}>
                <PostInputField
                    {...props}
                    value={name.value}
                    setter={name.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvCountryDescription.isoCode}>
                <PostInputField
                    {...props}
                    value={isoCode.value}
                    setter={isoCode.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvCountryDescription.phoneCode}>
                <PostNumberField
                    {...props}
                    value={phoneCode.value}
                    setter={phoneCode.setter} />
            </PostFieldWrapper>
            <PostFieldWrapper
                {...props}
                title={EnvCountryDescription.emoji}>
                <PostInputField
                    {...props}
                    value={emoji.value}
                    setter={emoji.setter} />
            </PostFieldWrapper>
            <div className={props.styles.row}>
                <Button
                    appearance={'primary'}
                    disabled={phase.value !== Phase.Waiting}
                    onClick={onDoneClick}>
                    완료
                </Button>
            </div>
        </div>
    </>
}