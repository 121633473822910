export enum EnvBrowser {
    /**
     * 기본(브라우저)
     */
    DefaultBrowser = 'DefaultBrowser',

    /**
     * 웹뷰(인앱)
     */
    WebViewInApp = 'WebViewInApp',

    /**
     * Safari(인앱)
     */
    SafariInApp = 'SafariInApp',

    /**
     * Chrome(인앱)
     */
    ChromeInApp = 'ChromeInApp',

    /**
     * Chrome(브라우저)
     */
    ChromeBrowser = 'ChromeBrowser',

    /**
     * Firefox(브라우저)
     */
    FirefoxBrowser = 'FirefoxBrowser',

    /**
     * 네이버(브라우저)
     */
    NaverBrowser = 'NaverBrowser'
}

export namespace EnvBrowser {
    export function description(o: EnvBrowser) {
        switch (o) {
            case EnvBrowser.DefaultBrowser: return '기본(브라우저)'
            case EnvBrowser.WebViewInApp: return '웹뷰(인앱)'
            case EnvBrowser.SafariInApp: return 'Safari(인앱)'
            case EnvBrowser.ChromeInApp: return 'Chrome(인앱)'
            case EnvBrowser.ChromeBrowser: return 'Chrome(브라우저)'
            case EnvBrowser.FirefoxBrowser: return 'Firefox(브라우저)'
            case EnvBrowser.NaverBrowser: return '네이버(브라우저)'
        }
    }

    export const selections: { value: string, label: string }[] = [
        { value: EnvBrowser.DefaultBrowser, label: description(EnvBrowser.DefaultBrowser) },
        { value: EnvBrowser.WebViewInApp, label: description(EnvBrowser.WebViewInApp) },
        { value: EnvBrowser.SafariInApp, label: description(EnvBrowser.SafariInApp) },
        { value: EnvBrowser.ChromeInApp, label: description(EnvBrowser.ChromeInApp) },
        { value: EnvBrowser.ChromeBrowser, label: description(EnvBrowser.ChromeBrowser) },
        { value: EnvBrowser.FirefoxBrowser, label: description(EnvBrowser.FirefoxBrowser) },
        { value: EnvBrowser.NaverBrowser, label: description(EnvBrowser.NaverBrowser) }
    ]
}
