import {UserBlockSearchOption} from "../../search-option/user/UserBlockSearchOption"

/**
 * 사용자 이용 정지 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const UserBlockSearchOptionDescription: Record<keyof UserBlockSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    creatorIdEq: '생성자 ID 일치',
    creatorIdNeq: '생성자 ID 불일치',
    creatorIdMin: '생성자 ID 최소',
    creatorIdMax: '생성자 ID 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    userBlockReasonIdEq: '사용자 이용 정지 사유 ID 일치',
    userBlockReasonIdNeq: '사용자 이용 정지 사유 ID 불일치',
    userBlockReasonIdMin: '사용자 이용 정지 사유 ID 최소',
    userBlockReasonIdMax: '사용자 이용 정지 사유 ID 최대',
    blockedAtEq: '이용 정지 시작일 일치',
    blockedAtNeq: '이용 정지 시작일 불일치',
    blockedAtMin: '이용 정지 시작일 최소',
    blockedAtMax: '이용 정지 시작일 최대',
    blockedUntilEq: '이용 정지 종료일 일치',
    blockedUntilNeq: '이용 정지 종료일 불일치',
    blockedUntilMin: '이용 정지 종료일 최소',
    blockedUntilMax: '이용 정지 종료일 최대',
    blockedUntilIsNull: '이용 정지 종료일 값 존재 여부',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
