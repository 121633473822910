import {EnvBrowser} from "../../constant/env/EnvBrowser"
import {ContentResultUrlDomain} from "../../domain/content/ContentResultUrlDomain"

export const ContentResultUrlDescriptor: Record<keyof ContentResultUrlDomain, (o: ContentResultUrlDomain) => string> = {
    id(o: ContentResultUrlDomain): string {
        return o.id.toString()
    },
    creatorId(o: ContentResultUrlDomain): string {
        return o.creatorId.toString()
    },
    browser(o: ContentResultUrlDomain): string {
        return EnvBrowser.description(o.browser)
    },
    url(o: ContentResultUrlDomain): string {
        return o.url
    },
    clickCount(o: ContentResultUrlDomain): string {
        return o.clickCount.toString()
    },
    createdAt(o: ContentResultUrlDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: ContentResultUrlDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    },
    concealedAt(o: ContentResultUrlDomain): string {
        return o.concealedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
