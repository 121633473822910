import {EnvPhoneBlockModificationDomain} from "../../domain/env/EnvPhoneBlockModificationDomain"
import {EnvCountryId} from "../../id/env/EnvCountryId"
import {EnvPhoneBlockId} from "../../id/env/EnvPhoneBlockId"
import {EnvPhoneBlockModificationId} from "../../id/env/EnvPhoneBlockModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 전화번호 차단 수정 이력
 */
export class EnvPhoneBlockModificationValueObject implements ValueObject, EnvPhoneBlockModificationDomain {
    /**
     * 기본키
     */
    id: EnvPhoneBlockModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 전화번호 차단 ID
     */
    envPhoneBlockId: EnvPhoneBlockId
    /**
     * 국가 ID
     */
    envCountryId: EnvCountryId
    /**
     * 전화번호
     */
    phone: string
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvPhoneBlockModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envPhoneBlockId = BigInt(o.envPhoneBlockId)
        this.envCountryId = BigInt(o.envCountryId)
        this.phone = String(o.phone)
        this.createdAt = new Date(o.createdAt)
    }
}
