import {ContentDetailUrlClickDomain} from "../../domain/content/ContentDetailUrlClickDomain"

/**
 * 콘텐츠 더 알아보기 URL 클릭 속성 설명
 */
export const ContentDetailUrlClickDescription: Record<keyof ContentDetailUrlClickDomain, string> = {
    id: '기본키',
    userId: '사용자 ID',
    contentDetailUrlId: '콘텐츠 더 알아보기 URL ID',
    createdAt: '생성일'
}
