import {ContentParticipationSearchOption} from "../../search-option/content/ContentParticipationSearchOption"

/**
 * 콘텐츠 참여 검색, 계수 요청 URL 인자 구성 요소 설명
 */
export const ContentParticipationSearchOptionDescription: Record<keyof ContentParticipationSearchOption, string> = {
    idNeq: '기본키 불일치',
    idMin: '기본키 최소',
    idMax: '기본키 최대',
    userIdEq: '사용자 ID 일치',
    userIdNeq: '사용자 ID 불일치',
    userIdMin: '사용자 ID 최소',
    userIdMax: '사용자 ID 최대',
    contentIdEq: '콘텐츠 ID 일치',
    contentIdNeq: '콘텐츠 ID 불일치',
    contentIdMin: '콘텐츠 ID 최소',
    contentIdMax: '콘텐츠 ID 최대',
    assetSolarUsageIdNeq: '솔라 지급 ID 불일치',
    assetSolarUsageIdMin: '솔라 지급 ID 최소',
    assetSolarUsageIdMax: '솔라 지급 ID 최대',
    assetSolarUsageIdIsNull: '솔라 지급 ID 값 존재 여부',
    assetStarUsageIdNeq: '스타 사용 ID 불일치',
    assetStarUsageIdMin: '스타 사용 ID 최소',
    assetStarUsageIdMax: '스타 사용 ID 최대',
    assetStarUsageIdIsNull: '스타 사용 ID 값 존재 여부',
    audioIdNeq: '오디오 ID 불일치',
    audioIdMin: '오디오 ID 최소',
    audioIdMax: '오디오 ID 최대',
    audioIdIsNull: '오디오 ID 값 존재 여부',
    transcriptionEq: '인식된 텍스트 일치',
    transcriptionNeq: '인식된 텍스트 불일치',
    transcriptionMin: '인식된 텍스트 최소',
    transcriptionMax: '인식된 텍스트 최대',
    transcriptionLike: '인식된 텍스트 포함',
    transcriptionNotLike: '인식된 텍스트 제외',
    accuracyEq: '일치율(%) 일치',
    accuracyNeq: '일치율(%) 불일치',
    accuracyMin: '일치율(%) 최소',
    accuracyMax: '일치율(%) 최대',
    isPassedEq: '합격 여부 일치',
    isPassedNeq: '합격 여부 불일치',
    isPassedMin: '합격 여부 최소',
    isPassedMax: '합격 여부 최대',
    isHighAccuracyEq: '높은 일치율 여부 일치',
    isHighAccuracyNeq: '높은 일치율 여부 불일치',
    isHighAccuracyMin: '높은 일치율 여부 최소',
    isHighAccuracyMax: '높은 일치율 여부 최대',
    createdAtEq: '생성일 일치',
    createdAtNeq: '생성일 불일치',
    createdAtMin: '생성일 최소',
    createdAtMax: '생성일 최대',
}
