import {EnvNoticePostDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvNoticePostDataTransferObjectDescription"
import {EnvNoticePostState} from "../../../data/data-transfer-object-state/env/EnvNoticePostState"
import {EnvNoticePostDataTransferObject} from "../../../data/data-transfer-object/env/EnvNoticePostDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvNoticePostState
}

export const EnvNoticePostField: Record<Capitalize<keyof EnvNoticePostDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Title(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvNoticePostDataTransferObjectDescription.title}>
                <PostTextareaField
                    {...props}
                    value={props.state.title.value}
                    setter={props.state.title.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    Url(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvNoticePostDataTransferObjectDescription.url}>
                <PostTextareaField
                    {...props}
                    value={props.state.url.value}
                    setter={props.state.url.setter}
                    maxLength={4000} />
            </PostFieldWrapper>
        )
    },
}

export function EnvNoticePostFields(props: PostFieldProps) {
    return <>
        <EnvNoticePostField.Title
            {...props}
            state={props.state} />
        <EnvNoticePostField.Url
            {...props}
            state={props.state} />
    </>
}
