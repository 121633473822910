import {ContentDetailUrlClickVerboseDomain} from "../../domain/content/ContentDetailUrlClickVerboseDomain"
import {ContentDetailUrlClickId} from "../../id/content/ContentDetailUrlClickId"
import {ContentDetailUrlId} from "../../id/content/ContentDetailUrlId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentDetailUrlValueObject} from "./ContentDetailUrlValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 콘텐츠 더 알아보기 URL 클릭 상세
 */
export class ContentDetailUrlClickVerboseValueObject implements VerboseValueObject, ContentDetailUrlClickVerboseDomain {
    /**
     * 기본키
     */
    id: ContentDetailUrlClickId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 콘텐츠 더 알아보기 URL ID
     */
    contentDetailUrlId: ContentDetailUrlId
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 사용자 ID
     */
    user: UserValueObject
    /**
     * 콘텐츠 더 알아보기 URL ID
     */
    contentDetailUrl: ContentDetailUrlValueObject

    constructor(o: Record<keyof ContentDetailUrlClickVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.contentDetailUrlId = BigInt(o.contentDetailUrlId)
        this.createdAt = new Date(o.createdAt)
        this.user = new UserValueObject(o.user)
        this.contentDetailUrl = new ContentDetailUrlValueObject(o.contentDetailUrl)
    }
}
