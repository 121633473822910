import {UiHomeContentUserProductionDomain} from "../../domain/ui/UiHomeContentUserProductionDomain"

/**
 * 홈 와글와글 콘텐츠 속성 설명
 */
export const UiHomeContentUserProductionDescription: Record<keyof UiHomeContentUserProductionDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    displayOrder: '표시 우선 순위',
    url: 'URL',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
