import {RouterProps} from "../RouterProps";
import {useStatePair} from "react-type-extension";
import {ContentType} from "../../data/constant/content/ContentType";
import React, {useCallback, useEffect} from "react";
import {Button, Title3} from "@fluentui/react-components";
import Spacer from "../Spacer";
import {ContentIO} from "../../io/ContentIO";
import {StorageIO, StorageKey} from "../../io/StorageIO";
import {MediaFile} from "../Media";
import {PostFieldWrapper, PostFileField, PostFilesField, PostFinishDialog} from "../PostField";
import {Promises} from "../../util/PromiseUtils";
import {ContentPostState, useContentPostState} from "../../data/data-transfer-object-state/content/ContentPostState";
import {ContentPostField} from "../data-transfer-object-field/content/ContentPostField";
import {EnvBrowser} from "../../data/constant/env/EnvBrowser";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const state = useContentPostState()
    const thumbnailImage = useStatePair<MediaFile | undefined>(undefined)
    const headerImages = useStatePair<MediaFile[] | undefined>(undefined)
    const descriptionImages = useStatePair<MediaFile[] | undefined>(undefined)
    const participationMedia = useStatePair<MediaFile | undefined>(undefined)

    useEffect(() => {
        if (state.type.value === ContentType.Daily) {
            thumbnailImage.setter(undefined)
            headerImages.setter([])
            descriptionImages.setter([])
            participationMedia.setter(undefined)
        }
    }, [state.type.value]);

    const onDoneClick = useCallback(() => {
        if (state.type.value !== ContentType.Daily && thumbnailImage.value === undefined) {
            alert('썸네일 이미지를 선택해주세요.')
            return
        }
        if (state.type.value !== ContentType.Daily && (headerImages.value === undefined || headerImages.value.length === 0)) {
            alert('상단 이미지를 선택해주세요.')
            return
        }
        if (state.type.value !== ContentType.Daily && (descriptionImages.value === undefined || descriptionImages.value.length === 0)) {
            alert('설명 이미지를 선택해주세요.')
            return
        }

        post(thumbnailImage.value, headerImages.value, descriptionImages.value, participationMedia.value)
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [...state.values(), thumbnailImage.value, headerImages.value, descriptionImages.value, participationMedia.value])

    const post = async (
        thumbnailImage: MediaFile | undefined,
        headerImages: MediaFile[] | undefined,
        descriptionImages: MediaFile[] | undefined,
        participationMedia: MediaFile | undefined
    ) => {
        phase.setter(Phase.Posting)
        const content = await ContentIO.post({
            ...state.toDataTransferObject(),
            contentDetailUrl: (state.type.value === ContentType.Daily) ? 'https://stdict.korean.go.kr' : state.contentDetailUrl.value,
            contentDetailUrlBrowser: (state.type.value === ContentType.Daily) ? EnvBrowser.DefaultBrowser : state.contentDetailUrlBrowser.value,
            contentResultUrl: (state.type.value === ContentType.Daily) ? 'https://stdict.korean.go.kr' : state.contentResultUrl.value,
            contentResultUrlBrowser: (state.type.value === ContentType.Daily) ? EnvBrowser.DefaultBrowser : state.contentResultUrlBrowser.value,
        })
        await new Promises()
            .pendIf(thumbnailImage, value => StorageIO.putFile(StorageKey.Content.thumbnail(content.id), value))
            .pendEachIf(headerImages, (value, index) => StorageIO.putFile(StorageKey.Content.header(content.id, index), value))
            .pendEachIf(descriptionImages, (value, index) => StorageIO.putFile(StorageKey.Content.description(content.id, index), value))
            .pendIf(participationMedia, value => StorageIO.putFile(StorageKey.Content.participation(content.id), value))
            .await()
    }

    return <>
        {(phase.value === Phase.Waiting || phase.value === Phase.Posting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFields
                    {...props}
                    state={state} />
                {/* 오늘의 단어가 아닐 때에만 이미지 등록 */}
                {state.type.value !== ContentType.Daily && <>
                    <PostFieldWrapper
                        {...props}
                        title={'썸네일 이미지'}>
                        <PostFileField
                            {...props}
                            value={thumbnailImage.value}
                            setter={thumbnailImage.setter}
                            accept={'image/*'} />
                    </PostFieldWrapper>
                    <PostFieldWrapper
                        {...props}
                        title={'상단 이미지'}>
                        <PostFilesField
                            {...props}
                            value={headerImages.value}
                            setter={headerImages.setter}
                            accept={'image/*'}
                            multiple />
                    </PostFieldWrapper>
                    <PostFieldWrapper
                        {...props}
                        title={'설명 이미지'}>
                        <PostFilesField
                            {...props}
                            value={descriptionImages.value}
                            setter={descriptionImages.setter}
                            accept={'image/*'}
                            multiple />
                    </PostFieldWrapper>
                    <PostFieldWrapper
                        {...props}
                        title={'참여 이미지 또는 비디오'}>
                        <PostFileField
                            {...props}
                            value={participationMedia.value}
                            setter={participationMedia.setter}
                            accept={'image/*,video/*'} />
                    </PostFieldWrapper>
                </>}
                <div className={props.styles.row}>
                    <Button
                        disabled={phase.value === Phase.Posting}
                        appearance={'primary'}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
                <Spacer height={'120px'}/>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/contents'}/>
    </>
}

type PostFieldProps = RouterProps & {
    state: ContentPostState
}

function PostFields(props: PostFieldProps) {
    return <>
        <ContentPostField.AdvertiserId
            {...props}
            state={props.state} />
        <ContentPostField.Type
            {...props}
            state={props.state} />
        <ContentPostField.ParticipationMethod
            {...props}
            state={props.state} />
        <ContentPostField.Title
            {...props}
            state={props.state} />
        <ContentPostField.TitleHighlight
            {...props}
            state={props.state} />
        <ContentPostField.Body
            {...props}
            state={props.state} />
        <ContentPostField.Script
            {...props}
            state={props.state} />
        <ContentPostField.ScriptVisibility
            {...props}
            state={props.state} />
        <ContentPostField.GradingMessage
            {...props}
            state={props.state} />
        <ContentPostField.Amount
            {...props}
            state={props.state} />
        <ContentPostField.PassAccuracy
            {...props}
            state={props.state} />
        <ContentPostField.PassAccuracyHigh
            {...props}
            state={props.state} />
        <ContentPostField.PassSolarAmount
            {...props}
            state={props.state} />
        <ContentPostField.PassSolarAmountHighAccuracy
            {...props}
            state={props.state} />
        <ContentPostField.StarAmount
            {...props}
            state={props.state} />
        <ContentPostField.Difficulty
            {...props}
            state={props.state} />
        <ContentPostField.ExposedAt
            {...props}
            state={props.state} />
        <ContentPostField.StartAt
            {...props}
            state={props.state} />
        <ContentPostField.EndAt
            {...props}
            state={props.state} />
        <ContentPostField.StatusFlags
            {...props}
            state={props.state} />
        {props.state.type.value !== ContentType.Daily && <>
            <ContentPostField.ContentDetailUrl
                {...props}
                state={props.state} />
            <ContentPostField.ContentDetailUrlBrowser
                {...props}
                state={props.state} />
            <ContentPostField.ContentResultUrl
                {...props}
                state={props.state} />
            <ContentPostField.ContentResultUrlBrowser
                {...props}
                state={props.state} />
        </>}
        {props.state.type.value === ContentType.UserProduction && <>
            <ContentPostField.AudioId
                {...props}
                state={props.state} />
            <ContentPostField.Transcription
                {...props}
                state={props.state} />
        </>}
    </>
}
