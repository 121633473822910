import {AssetLunarUsageDomain} from "../../domain/asset/AssetLunarUsageDomain"

/**
 * 루나 사용 및 지급 이력 속성 설명
 */
export const AssetLunarUsageDescription: Record<keyof AssetLunarUsageDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    userId: '사용자 ID',
    type: '유형',
    amount: '변화',
    lastAccumulation: '직전 누계',
    positiveAccumulation: '지급 누계',
    negativeAccumulation: '사용 누계',
    createdAt: '생성일'
}
