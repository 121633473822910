import {UserRankHistoryValueObject} from "../../../../data/value-object/user/UserRankHistoryValueObject";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {UserRankHistorySearchOption} from "../../../../data/search-option/user/UserRankHistorySearchOption";
import {SearchIndex} from "../../../../io/HttpClient";
import {SortOrder} from "ts-protocol-extension";
import ListPageWrapper from "../../../ListPageWrapper";
import {UserRankHistoryIO} from "../../../../io/UserIO";
import {UserRankHistoryDescription} from "../../../../data/description/user/UserRankHistoryDescription";
import {UserRankHistoryDescriptor} from "../../../../data/descriptor/user/UserRankHistoryDescriptor";
import ListOption from "../../../ListOption";
import {
    UserRankHistorySearchOptionDescription
} from "../../../../data/description/user/UserRankHistorySearchOptionDescription";
import {
    applyUserRankHistorySearchOption,
    UserRankHistorySearchOptionField
} from "../../../search-option-field/user/UserRankHistorySearchOptionField";
import ListOrder from "../../../ListOrder";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<UserRankHistoryValueObject[]>([])
    const option = useStatePair<UserRankHistorySearchOption>({})
    const index = useStatePair<SearchIndex>({ pageIndex: 0n, sortOrder: [SortOrder.ASC], sortPriority: ['id'] })

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={UserRankHistoryIO}
                items={items}
                option={option.value}
                index={index}
                description={UserRankHistoryDescription}
                descriptor={UserRankHistoryDescriptor}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/users/ranks/histories/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={UserRankHistorySearchOptionDescription}
                fields={UserRankHistorySearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyUserRankHistorySearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={UserRankHistoryDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}