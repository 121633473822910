import {AssetStarUsageType} from "../../constant/asset/AssetStarUsageType"
import {AssetStarUsageDomain} from "../../domain/asset/AssetStarUsageDomain"

export const AssetStarUsageDescriptor: Record<keyof AssetStarUsageDomain, (o: AssetStarUsageDomain) => string> = {
    id(o: AssetStarUsageDomain): string {
        return o.id.toString()
    },
    creatorId(o: AssetStarUsageDomain): string {
        return o.creatorId.toString()
    },
    userId(o: AssetStarUsageDomain): string {
        return o.userId.toString()
    },
    type(o: AssetStarUsageDomain): string {
        return AssetStarUsageType.description(o.type)
    },
    amount(o: AssetStarUsageDomain): string {
        return o.amount.toString()
    },
    lastAccumulation(o: AssetStarUsageDomain): string {
        return o.lastAccumulation.toString()
    },
    positiveAccumulation(o: AssetStarUsageDomain): string {
        return o.positiveAccumulation.toString()
    },
    negativeAccumulation(o: AssetStarUsageDomain): string {
        return o.negativeAccumulation.toString()
    },
    createdAt(o: AssetStarUsageDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    }
}
