import {UiPolicyModificationDomain} from "../../domain/ui/UiPolicyModificationDomain"

/**
 * UI 정책 수정 이력 속성 설명
 */
export const UiPolicyModificationDescription: Record<keyof UiPolicyModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    uiPolicyId: 'UI 정책 ID',
    uiHomeContentSuggestionPageSize: '홈 추천 콘텐츠 페이지 크기',
    uiHomeContentUserProductionPageSize: '홈 와글와글 콘텐츠 페이지 크기',
    uiHomeNoticePageSize: '홈 공지사항 페이지 크기',
    uiOnboardingPageSize: '온보딩 페이지 크기',
    uiPolicyPageSize: 'UI 정책 페이지 크기',
    createdAt: '수정일'
}
