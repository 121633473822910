import {ContentCommentVerboseDomain} from "../../domain/content/ContentCommentVerboseDomain"
import {ContentCommentId} from "../../id/content/ContentCommentId"
import {ContentId} from "../../id/content/ContentId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {ContentCommentValueObject} from "./ContentCommentValueObject"
import {ContentValueObject} from "./ContentValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 댓글 상세
 */
export class ContentCommentVerboseValueObject implements VerboseValueObject, ContentCommentVerboseDomain {
    /**
     * 기본키
     */
    id: ContentCommentId
    /**
     * 콘텐츠 ID
     */
    contentId: ContentId
    /**
     * 부모 댓글 ID
     */
    parentId?: ContentCommentId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 내용
     */
    body: string
    /**
     * 오디오 ID
     */
    audioId?: bigint
    /**
     * 대댓글 수
     */
    childCount: number
    /**
     * 좋아요 수
     */
    likeCount: number
    /**
     * 신고 수
     */
    reportCount: number
    /**
     * 생성일
     */
    createdAt: Date
    /**
     * 마지막 수정일
     */
    lastModifiedAt?: Date
    /**
     * 삭제일
     */
    concealedAt?: Date
    /**
     * 콘텐츠 ID
     */
    content: ContentValueObject
    /**
     * 부모 댓글 ID
     */
    parent?: ContentCommentValueObject
    /**
     * 사용자 ID
     */
    user: UserValueObject

    constructor(o: Record<keyof ContentCommentVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.contentId = BigInt(o.contentId)
        if (o.parentId !== undefined && o.parentId !== null) {
            this.parentId = BigInt(o.parentId)
        }
        this.userId = BigInt(o.userId)
        this.body = String(o.body)
        if (o.audioId !== undefined && o.audioId !== null) {
            this.audioId = BigInt(o.audioId)
        }
        this.childCount = Number(o.childCount)
        this.likeCount = Number(o.likeCount)
        this.reportCount = Number(o.reportCount)
        this.createdAt = new Date(o.createdAt)
        if (o.lastModifiedAt !== undefined && o.lastModifiedAt !== null) {
            this.lastModifiedAt = new Date(o.lastModifiedAt)
        }
        if (o.concealedAt !== undefined && o.concealedAt !== null) {
            this.concealedAt = new Date(o.concealedAt)
        }
        this.content = new ContentValueObject(o.content)
        if (o.parent !== undefined && o.parent !== null) {
            this.parent = new ContentCommentValueObject(o.parent)
        }
        this.user = new UserValueObject(o.user)
    }
}
