import {UserBlockReasonModificationDomain} from "../../domain/user/UserBlockReasonModificationDomain"

/**
 * 사용자 이용 정지 사유 수정 이력 속성 설명
 */
export const UserBlockReasonModificationDescription: Record<keyof UserBlockReasonModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    userBlockReasonId: '사용자 이용 정지 사유 ID',
    body: '사유',
    createdAt: '수정일'
}
