import {AssetLunarUsagePostDataTransferObject} from "../../data-transfer-object/asset/AssetLunarUsagePostDataTransferObject"

/**
 * 루나 사용 및 지급 이력 속성 설명
 */
export const AssetLunarUsagePostDataTransferObjectDescription: Record<keyof AssetLunarUsagePostDataTransferObject, string> = {
    userId: '사용자 ID',
    type: '유형',
    amount: '변화'
}
