import {RouterProps} from "./RouterProps";
import {StatePair, useStatePair} from "react-type-extension";
import {SearchIndex} from "../io/HttpClient";
import {mapByKey} from "../util/ObjectUtil";
import {
    Body1, Body1Strong,
    Button,
    Caption1,
    Card, CardHeader,
    Field,
    makeStyles,
    mergeClasses,
    Radio,
    RadioGroup,
    Title3
} from "@fluentui/react-components";
import {
    ChevronDown20Filled,
    ChevronLeft24Filled,
    ChevronRight24Filled,
    ChevronUp20Filled,
    Dismiss20Filled
} from "@fluentui/react-icons";
import {SortOrder} from "ts-protocol-extension";

const useStyles = makeStyles({
    navigations: {
        marginTop: '16px',
        width: 'max-content'
    },
    navigation: {
        justifyContent: 'space-between'
    },
    card: {
        minWidth: '480px'
    }
})

export type ListOrderProps<VO> = RouterProps & {
    index: StatePair<SearchIndex>
    description: Record<keyof VO, string>
    onBackClick?: () => void
    onOrderUpdated?: () => void
}

export default function ListOrder<VO>(props: ListOrderProps<VO>) {
    const styles = useStyles()
    const selectedKey = useStatePair<(keyof VO) | undefined>(undefined)
    const selectedOrder = useStatePair<SortOrder | undefined>(undefined)

    return <>
        <div className={props.styles.row8}>
            <Button
                appearance={'subtle'}
                icon={<ChevronLeft24Filled/>}
                onClick={props.onBackClick}></Button>
            <Title3>항목 선택</Title3>
        </div>
        <div className={props.styles.row16}>
            <div className={mergeClasses(styles.navigations, props.styles.column8)}>
                {mapByKey(props.description, key => <>
                    {!props.index.value.sortPriority.includes(key as string) && <>
                        <Button
                            key={`list-order-selectable-${String(key)}`}
                            className={styles.navigation}
                            icon={<ChevronRight24Filled/>}
                            iconPosition={'after'}
                            appearance={'subtle'}
                            onClick={() => {
                                selectedKey.setter(key)
                                selectedOrder.setter(undefined)
                            }}>
                            {props.description[key]}
                        </Button>
                    </>}
                </>)}
            </div>
            <div className={props.styles.column}>
                {selectedKey.value !== undefined && <>
                    <div className={props.styles.row}>
                        <div className={props.styles.column8}>
                            <Caption1>{props.description[selectedKey.value]}</Caption1>
                            <Field>
                                <RadioGroup
                                    value={selectedOrder.value}
                                    onChange={(ev, data) => selectedOrder.setter(data.value as SortOrder)}>
                                    <Radio value={SortOrder.ASC} label={SortOrder.names[SortOrder.ASC]}/>
                                    <Radio value={SortOrder.DESC} label={SortOrder.names[SortOrder.DESC]}/>
                                    <Radio value={SortOrder.ASC_NULLS_FIRST}
                                           label={SortOrder.names[SortOrder.ASC_NULLS_FIRST]}/>
                                    <Radio value={SortOrder.DESC_NULLS_FIRST}
                                           label={SortOrder.names[SortOrder.DESC_NULLS_FIRST]}/>
                                    <Radio value={SortOrder.ASC_NULLS_LAST}
                                           label={SortOrder.names[SortOrder.ASC_NULLS_LAST]}/>
                                    <Radio value={SortOrder.DESC_NULLS_LAST}
                                           label={SortOrder.names[SortOrder.DESC_NULLS_LAST]}/>
                                </RadioGroup>
                            </Field>
                            <div className={props.styles.row8}>
                                <Button
                                    disabled={selectedOrder.value === undefined}
                                    appearance={'primary'}
                                    onClick={() => {
                                        props.index.setter({
                                            pageIndex: 0n,
                                            sortOrder: [...props.index.value.sortOrder, selectedOrder.value as SortOrder],
                                            sortPriority: [...props.index.value.sortPriority, selectedKey.value as string]
                                        });
                                        selectedKey.setter(undefined)
                                        selectedOrder.setter(undefined)
                                        props.onOrderUpdated?.()
                                    }}>
                                    적용
                                </Button>
                                <Button
                                    appearance={'secondary'}
                                    onClick={() => {
                                        selectedKey.setter(undefined)
                                        selectedOrder.setter(undefined)
                                    }}>
                                    취소
                                </Button>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
            <div className={props.styles.column8}>
                {props.index.value.sortPriority.map(key => {
                    const itemIndex = props.index.value.sortPriority.indexOf(key as string)
                    return itemIndex !== -1 && <>
                        <ListOrderCard
                            {...props}
                            key={`list-order-selected-${key}`}
                            itemKey={key}
                            itemIndex={itemIndex}/>
                    </>
                })}
            </div>
        </div>
    </>
}

type ListOrderCardProps<VO> = ListOrderProps<VO> & {
    itemKey: string
    itemIndex: number
}

function ListOrderCard<VO>(props: ListOrderCardProps<VO>) {
    const styles = useStyles()

    return <>
        <Card
            className={styles.card}
            orientation={'horizontal'}>
            <CardHeader
                action={<>
                    <div className={props.styles.row4}>
                        <ListOrderCardUp {...props} />
                        <ListOrderCardDown {...props} />
                        <ListOrderCardDismiss {...props} />
                    </div>
                </>}
                header={<Body1Strong>{props.description[props.itemKey as keyof VO]}</Body1Strong>}
                description={<Body1>{SortOrder.names[props.index.value.sortOrder[props.itemIndex]]}</Body1>}/>
        </Card>
    </>
}

function ListOrderCardUp<VO>(props: ListOrderCardProps<VO>) {
    return <>
        <Button
            disabled={props.itemIndex === 0}
            appearance={'subtle'}
            icon={<ChevronUp20Filled/>}
            onClick={() => {
                const currentOrder = props.index.value.sortOrder[props.itemIndex]
                const currentPriority = props.index.value.sortPriority[props.itemIndex]
                const dstOrder = props.index.value.sortOrder[props.itemIndex - 1]
                const dstPriority = props.index.value.sortPriority[props.itemIndex - 1]
                props.index.setter({
                    pageIndex: 0n,
                    sortOrder: [
                        ...props.index.value.sortOrder.slice(0, props.itemIndex - 1),
                        currentOrder,
                        dstOrder,
                        ...props.index.value.sortOrder.slice(props.itemIndex + 1, props.index.value.sortOrder.length)
                    ],
                    sortPriority: [
                        ...props.index.value.sortPriority.slice(0, props.itemIndex - 1),
                        currentPriority,
                        dstPriority,
                        ...props.index.value.sortPriority.slice(props.itemIndex + 1, props.index.value.sortOrder.length)
                    ]
                })
                props.onOrderUpdated?.()
            }} />
    </>
}

function ListOrderCardDown<VO>(props: ListOrderCardProps<VO>) {
    return <>
        <Button
            disabled={props.itemIndex === props.index.value.sortPriority.length - 1}
            appearance={'subtle'}
            icon={<ChevronDown20Filled />}
            onClick={() => {
                const currentOrder = props.index.value.sortOrder[props.itemIndex]
                const currentPriority = props.index.value.sortPriority[props.itemIndex]
                const dstOrder = props.index.value.sortOrder[props.itemIndex + 1]
                const dstPriority = props.index.value.sortPriority[props.itemIndex + 1]
                props.index.setter({
                    pageIndex: 0n,
                    sortOrder: [
                        ...props.index.value.sortOrder.slice(0, props.itemIndex),
                        dstOrder,
                        currentOrder,
                        ...props.index.value.sortOrder.slice(props.itemIndex + 2, props.index.value.sortOrder.length)
                    ],
                    sortPriority: [
                        ...props.index.value.sortPriority.slice(0, props.itemIndex),
                        dstPriority,
                        currentPriority,
                        ...props.index.value.sortPriority.slice(props.itemIndex + 2, props.index.value.sortOrder.length)
                    ]
                })
                props.onOrderUpdated?.()
            }} />
    </>
}

function ListOrderCardDismiss<VO>(props: ListOrderCardProps<VO>) {
    return <>
        <Button
            appearance={'subtle'}
            icon={<Dismiss20Filled />}
            onClick={() => {
                props.index.setter({
                    pageIndex: 0n,
                    sortOrder: [
                        ...props.index.value.sortOrder.slice(0, props.itemIndex),
                        ...props.index.value.sortOrder.slice(props.itemIndex + 1, props.index.value.sortOrder.length)
                    ],
                    sortPriority: [
                        ...props.index.value.sortPriority.slice(0, props.itemIndex),
                        ...props.index.value.sortPriority.slice(props.itemIndex + 1, props.index.value.sortOrder.length)
                    ]
                })
                props.onOrderUpdated?.()
            }} />
    </>
}