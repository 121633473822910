import {EnvEventPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/env/EnvEventPutDataTransferObjectDescription"
import {EnvEventPutState} from "../../../data/data-transfer-object-state/env/EnvEventPutState"
import {EnvEventPutDataTransferObject} from "../../../data/data-transfer-object/env/EnvEventPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: EnvEventPutState
}

export const EnvEventPutField: Record<Capitalize<keyof EnvEventPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Title(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvEventPutDataTransferObjectDescription.title}>
                <PostTextareaField
                    {...props}
                    value={props.state.title.value}
                    setter={props.state.title.setter}
                    maxLength={400} />
            </PostFieldWrapper>
        )
    },
    Url(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={EnvEventPutDataTransferObjectDescription.url}>
                <PostTextareaField
                    {...props}
                    value={props.state.url.value}
                    setter={props.state.url.setter}
                    maxLength={4000} />
            </PostFieldWrapper>
        )
    },
}

export function EnvEventPutFields(props: PostFieldProps) {
    return <>
        <EnvEventPutField.Title
            {...props}
            state={props.state} />
        <EnvEventPutField.Url
            {...props}
            state={props.state} />
    </>
}
