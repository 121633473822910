import {AssetSolarWithdrawalSearchOptionDescription} from "../../../data/description/asset/AssetSolarWithdrawalSearchOptionDescription"
import {AssetSolarWithdrawalSearchOption} from "../../../data/search-option/asset/AssetSolarWithdrawalSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function AssetSolarWithdrawalSearchOptionField(props: ListOptionFieldProps<AssetSolarWithdrawalSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.assetSolarUsageIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.assetSolarUsageIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.assetSolarUsageIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsageIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.assetSolarUsageIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.envBankIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.envBankIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.envBankIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "envBankIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.envBankIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.accountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.accountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.accountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.accountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accountLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.accountLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "accountNotLike":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.accountNotLike}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cashAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.cashAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cashAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.cashAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cashAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.cashAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "cashAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.cashAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetSolarWithdrawalSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyAssetSolarWithdrawalSearchOption(key: keyof AssetSolarWithdrawalSearchOption, value: string, into: StatePair<AssetSolarWithdrawalSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "assetSolarUsageIdEq":
            into.setter({
                ...into.value,
                assetSolarUsageIdEq: BigInt(value)
            })
            break
        case "assetSolarUsageIdNeq":
            into.setter({
                ...into.value,
                assetSolarUsageIdNeq: BigInt(value)
            })
            break
        case "assetSolarUsageIdMin":
            into.setter({
                ...into.value,
                assetSolarUsageIdMin: BigInt(value)
            })
            break
        case "assetSolarUsageIdMax":
            into.setter({
                ...into.value,
                assetSolarUsageIdMax: BigInt(value)
            })
            break
        case "envBankIdEq":
            into.setter({
                ...into.value,
                envBankIdEq: BigInt(value)
            })
            break
        case "envBankIdNeq":
            into.setter({
                ...into.value,
                envBankIdNeq: BigInt(value)
            })
            break
        case "envBankIdMin":
            into.setter({
                ...into.value,
                envBankIdMin: BigInt(value)
            })
            break
        case "envBankIdMax":
            into.setter({
                ...into.value,
                envBankIdMax: BigInt(value)
            })
            break
        case "accountEq":
            into.setter({
                ...into.value,
                accountEq: String(value)
            })
            break
        case "accountNeq":
            into.setter({
                ...into.value,
                accountNeq: String(value)
            })
            break
        case "accountMin":
            into.setter({
                ...into.value,
                accountMin: String(value)
            })
            break
        case "accountMax":
            into.setter({
                ...into.value,
                accountMax: String(value)
            })
            break
        case "accountLike":
            into.setter({
                ...into.value,
                accountLike: String(value)
            })
            break
        case "accountNotLike":
            into.setter({
                ...into.value,
                accountNotLike: String(value)
            })
            break
        case "cashAmountEq":
            into.setter({
                ...into.value,
                cashAmountEq: Number(value)
            })
            break
        case "cashAmountNeq":
            into.setter({
                ...into.value,
                cashAmountNeq: Number(value)
            })
            break
        case "cashAmountMin":
            into.setter({
                ...into.value,
                cashAmountMin: Number(value)
            })
            break
        case "cashAmountMax":
            into.setter({
                ...into.value,
                cashAmountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
