import {AssetLunarExchangeOptionModificationId} from "../data/id/asset/AssetLunarExchangeOptionModificationId";
import {
    AssetLunarExchangeOptionModificationValueObject
} from "../data/value-object/asset/AssetLunarExchangeOptionModificationValueObject";
import {BaseHttpClient} from "./HttpClient";
import {
    AssetLunarExchangeOptionModificationVerboseValueObject
} from "../data/value-object/asset/AssetLunarExchangeOptionModificationVerboseValueObject";
import {AssetLunarExchangeOptionId} from "../data/id/asset/AssetLunarExchangeOptionId";
import {AssetLunarExchangeOptionValueObject} from "../data/value-object/asset/AssetLunarExchangeOptionValueObject";
import {
    AssetLunarExchangeOptionVerboseValueObject
} from "../data/value-object/asset/AssetLunarExchangeOptionVerboseValueObject";
import {
    AssetLunarExchangeOptionPostDataTransferObject
} from "../data/data-transfer-object/asset/AssetLunarExchangeOptionPostDataTransferObject";
import {
    AssetLunarExchangeOptionPutDataTransferObject
} from "../data/data-transfer-object/asset/AssetLunarExchangeOptionPutDataTransferObject";
import {AssetLunarPurchaseOptionModificationId} from "../data/id/asset/AssetLunarPurchaseOptionModificationId";
import {
    AssetLunarPurchaseOptionModificationValueObject
} from "../data/value-object/asset/AssetLunarPurchaseOptionModificationValueObject";
import {
    AssetLunarPurchaseOptionModificationVerboseValueObject
} from "../data/value-object/asset/AssetLunarPurchaseOptionModificationVerboseValueObject";
import {
    AssetLunarPurchaseOptionModificationSearchOption
} from "../data/search-option/asset/AssetLunarPurchaseOptionModificationSearchOption";
import {AssetLunarPurchaseOptionId} from "../data/id/asset/AssetLunarPurchaseOptionId";
import {
    AssetLunarPurchaseOptionPostDataTransferObject
} from "../data/data-transfer-object/asset/AssetLunarPurchaseOptionPostDataTransferObject";
import {
    AssetLunarPurchaseOptionPutDataTransferObject
} from "../data/data-transfer-object/asset/AssetLunarPurchaseOptionPutDataTransferObject";
import {AssetLunarPurchaseOptionValueObject} from "../data/value-object/asset/AssetLunarPurchaseOptionValueObject";
import {
    AssetLunarPurchaseOptionVerboseValueObject
} from "../data/value-object/asset/AssetLunarPurchaseOptionVerboseValueObject";
import {
    AssetLunarPurchaseOptionSearchOption
} from "../data/search-option/asset/AssetLunarPurchaseOptionSearchOption";
import {
    AssetLunarUsagePostDataTransferObject
} from "../data/data-transfer-object/asset/AssetLunarUsagePostDataTransferObject";
import {AssetLunarUsageId} from "../data/id/asset/AssetLunarUsageId";
import {AssetLunarUsageValueObject} from "../data/value-object/asset/AssetLunarUsageValueObject";
import {AssetLunarUsageVerboseValueObject} from "../data/value-object/asset/AssetLunarUsageVerboseValueObject";
import {AssetPolicyModificationId} from "../data/id/asset/AssetPolicyModificationId";
import {AssetPolicyModificationValueObject} from "../data/value-object/asset/AssetPolicyModificationValueObject";
import {
    AssetPolicyModificationVerboseValueObject
} from "../data/value-object/asset/AssetPolicyModificationVerboseValueObject";
import {
    AssetLunarExchangeOptionModificationSearchOption
} from "../data/search-option/asset/AssetLunarExchangeOptionModificationSearchOption";
import {
    AssetLunarExchangeOptionSearchOption
} from "../data/search-option/asset/AssetLunarExchangeOptionSearchOption";
import {AssetLunarUsageSearchOption} from "../data/search-option/asset/AssetLunarUsageSearchOption";
import {AssetPolicyModificationSearchOption} from "../data/search-option/asset/AssetPolicyModificationSearchOption";
import {AssetPolicyId} from "../data/id/asset/AssetPolicyId";
import {AssetPolicyValueObject} from "../data/value-object/asset/AssetPolicyValueObject";
import {AssetPolicyVerboseValueObject} from "../data/value-object/asset/AssetPolicyVerboseValueObject";
import {AssetPolicySearchOption} from "../data/search-option/asset/AssetPolicySearchOption";
import {
    AssetPolicyPostDataTransferObject
} from "../data/data-transfer-object/asset/AssetPolicyPostDataTransferObject";
import {AssetPolicyPutDataTransferObject} from "../data/data-transfer-object/asset/AssetPolicyPutDataTransferObject";
import {AssetSolarWithdrawalOptionModificationId} from "../data/id/asset/AssetSolarWithdrawalOptionModificationId";
import {
    AssetSolarWithdrawalOptionModificationValueObject
} from "../data/value-object/asset/AssetSolarWithdrawalOptionModificationValueObject";
import {
    AssetSolarWithdrawalOptionModificationVerboseValueObject
} from "../data/value-object/asset/AssetSolarWithdrawalOptionModificationVerboseValueObject";
import {
    AssetSolarWithdrawalOptionModificationSearchOption
} from "../data/search-option/asset/AssetSolarWithdrawalOptionModificationSearchOption";
import {AssetSolarWithdrawalOptionId} from "../data/id/asset/AssetSolarWithdrawalOptionId";
import {
    AssetSolarWithdrawalOptionPostDataTransferObject
} from "../data/data-transfer-object/asset/AssetSolarWithdrawalOptionPostDataTransferObject";
import {
    AssetSolarWithdrawalOptionPutDataTransferObject
} from "../data/data-transfer-object/asset/AssetSolarWithdrawalOptionPutDataTransferObject";
import {
    AssetSolarWithdrawalOptionValueObject
} from "../data/value-object/asset/AssetSolarWithdrawalOptionValueObject";
import {
    AssetSolarWithdrawalOptionVerboseValueObject
} from "../data/value-object/asset/AssetSolarWithdrawalOptionVerboseValueObject";
import {
    AssetSolarWithdrawalOptionSearchOption
} from "../data/search-option/asset/AssetSolarWithdrawalOptionSearchOption";
import {AssetSolarUsageId} from "../data/id/asset/AssetSolarUsageId";
import {
    AssetSolarUsagePostDataTransferObject
} from "../data/data-transfer-object/asset/AssetSolarUsagePostDataTransferObject";
import {AssetSolarUsageValueObject} from "../data/value-object/asset/AssetSolarUsageValueObject";
import {AssetSolarUsageVerboseValueObject} from "../data/value-object/asset/AssetSolarUsageVerboseValueObject";
import {AssetSolarUsageSearchOption} from "../data/search-option/asset/AssetSolarUsageSearchOption";
import {AssetStarExchangeOptionModificationId} from "../data/id/asset/AssetStarExchangeOptionModificationId";
import {
    AssetStarExchangeOptionModificationValueObject
} from "../data/value-object/asset/AssetStarExchangeOptionModificationValueObject";
import {
    AssetStarExchangeOptionModificationVerboseValueObject
} from "../data/value-object/asset/AssetStarExchangeOptionModificationVerboseValueObject";
import {
    AssetStarExchangeOptionModificationSearchOption
} from "../data/search-option/asset/AssetStarExchangeOptionModificationSearchOption";
import {AssetStarExchangeOptionId} from "../data/id/asset/AssetStarExchangeOptionId";
import {
    AssetStarExchangeOptionPostDataTransferObject
} from "../data/data-transfer-object/asset/AssetStarExchangeOptionPostDataTransferObject";
import {
    AssetStarExchangeOptionPutDataTransferObject
} from "../data/data-transfer-object/asset/AssetStarExchangeOptionPutDataTransferObject";
import {AssetStarExchangeOptionValueObject} from "../data/value-object/asset/AssetStarExchangeOptionValueObject";
import {
    AssetStarExchangeOptionVerboseValueObject
} from "../data/value-object/asset/AssetStarExchangeOptionVerboseValueObject";
import {AssetStarExchangeOptionSearchOption} from "../data/search-option/asset/AssetStarExchangeOptionSearchOption";
import {AssetStarUsageId} from "../data/id/asset/AssetStarUsageId";
import {
    AssetStarUsagePostDataTransferObject
} from "../data/data-transfer-object/asset/AssetStarUsagePostDataTransferObject";
import {AssetStarUsageValueObject} from "../data/value-object/asset/AssetStarUsageValueObject";
import {AssetStarUsageVerboseValueObject} from "../data/value-object/asset/AssetStarUsageVerboseValueObject";
import {AssetStarUsageSearchOption} from "../data/search-option/asset/AssetStarUsageSearchOption";
import {AssetSolarWithdrawalId} from "../data/id/asset/AssetSolarWithdrawalId";
import {
    AssetSolarWithdrawalPostDataTransferObject
} from "../data/data-transfer-object/asset/AssetSolarWithdrawalPostDataTransferObject";
import {AssetSolarWithdrawalValueObject} from "../data/value-object/asset/AssetSolarWithdrawalValueObject";
import {
    AssetSolarWithdrawalVerboseValueObject
} from "../data/value-object/asset/AssetSolarWithdrawalVerboseValueObject";
import {AssetSolarWithdrawalSearchOption} from "../data/search-option/asset/AssetSolarWithdrawalSearchOption";
import {AssetCouponId} from "../data/id/asset/AssetCouponId";
import {AssetCouponPostDataTransferObject} from "../data/data-transfer-object/asset/AssetCouponPostDataTransferObject";
import {AssetCouponPutDataTransferObject} from "../data/data-transfer-object/asset/AssetCouponPutDataTransferObject";
import {AssetCouponValueObject} from "../data/value-object/asset/AssetCouponValueObject";
import {AssetCouponVerboseValueObject} from "../data/value-object/asset/AssetCouponVerboseValueObject";
import {AssetCouponSearchOption} from "../data/search-option/asset/AssetCouponSearchOption";
import {AssetCouponModificationId} from "../data/id/asset/AssetCouponModificationId";
import {AssetCouponModificationValueObject} from "../data/value-object/asset/AssetCouponModificationValueObject";
import {
    AssetCouponModificationVerboseValueObject
} from "../data/value-object/asset/AssetCouponModificationVerboseValueObject";
import {AssetCouponModificationSearchOption} from "../data/search-option/asset/AssetCouponModificationSearchOption";
import {AssetCouponUsageId} from "../data/id/asset/AssetCouponUsageId";
import {
    AssetCouponUsagePostDataTransferObject
} from "../data/data-transfer-object/asset/AssetCouponUsagePostDataTransferObject";
import {AssetCouponUsageValueObject} from "../data/value-object/asset/AssetCouponUsageValueObject";
import {AssetCouponUsageVerboseValueObject} from "../data/value-object/asset/AssetCouponUsageVerboseValueObject";
import {AssetCouponUsageSearchOption} from "../data/search-option/asset/AssetCouponUsageSearchOption";
import {AssetStarChargeId} from "../data/id/asset/AssetStarChargeId";
import {AssetStarChargeValueObject} from "../data/value-object/asset/AssetStarChargeValueObject";
import {AssetStarChargeVerboseValueObject} from "../data/value-object/asset/AssetStarChargeVerboseValueObject";
import {AssetStarChargeSearchOption} from "../data/search-option/asset/AssetStarChargeSearchOption";

export const AssetCouponIO = new BaseHttpClient<
    AssetCouponId,
    AssetCouponPostDataTransferObject,
    AssetCouponPutDataTransferObject,
    AssetCouponValueObject,
    AssetCouponVerboseValueObject,
    AssetCouponSearchOption
>(
    '/assets/coupons',
    o => new AssetCouponValueObject(o),
    o => new AssetCouponVerboseValueObject(o)
)

export const AssetCouponModificationIO = new BaseHttpClient<
    AssetCouponModificationId,
    never,
    never,
    AssetCouponModificationValueObject,
    AssetCouponModificationVerboseValueObject,
    AssetCouponModificationSearchOption
>(
    '/assets/coupons/modifications',
    o => new AssetCouponModificationValueObject(o),
    o => new AssetCouponModificationVerboseValueObject(o)
)

export const AssetCouponUsageIO = new BaseHttpClient<
    AssetCouponUsageId,
    AssetCouponUsagePostDataTransferObject,
    never,
    AssetCouponUsageValueObject,
    AssetCouponUsageVerboseValueObject,
    AssetCouponUsageSearchOption
>(
    '/assets/coupons/usages',
    o => new AssetCouponUsageValueObject(o),
    o => new AssetCouponUsageVerboseValueObject(o)
)

export const AssetLunarExchangeOptionModificationIO = new BaseHttpClient<
    AssetLunarExchangeOptionModificationId,
    never,
    never,
    AssetLunarExchangeOptionModificationValueObject,
    AssetLunarExchangeOptionModificationVerboseValueObject,
    AssetLunarExchangeOptionModificationSearchOption
>(
    '/assets/lunars/exchangeOptions/modifications',
    o => new AssetLunarExchangeOptionModificationValueObject(o),
    o => new AssetLunarExchangeOptionModificationVerboseValueObject(o)
)

export const AssetLunarExchangeOptionIO = new BaseHttpClient<
    AssetLunarExchangeOptionId,
    AssetLunarExchangeOptionPostDataTransferObject,
    AssetLunarExchangeOptionPutDataTransferObject,
    AssetLunarExchangeOptionValueObject,
    AssetLunarExchangeOptionVerboseValueObject,
    AssetLunarExchangeOptionSearchOption
>(
    '/assets/lunars/exchangeOptions',
    o => new AssetLunarExchangeOptionValueObject(o),
    o => new AssetLunarExchangeOptionVerboseValueObject(o)
)

export const AssetLunarPurchaseOptionModificationIO = new BaseHttpClient<
    AssetLunarPurchaseOptionModificationId,
    never,
    never,
    AssetLunarPurchaseOptionModificationValueObject,
    AssetLunarPurchaseOptionModificationVerboseValueObject,
    AssetLunarPurchaseOptionModificationSearchOption
>(
    '/assets/lunars/purchaseOptions/modifications',
    o => new AssetLunarPurchaseOptionModificationValueObject(o),
    o => new AssetLunarPurchaseOptionModificationVerboseValueObject(o)
)

export const AssetLunarPurchaseOptionIO = new BaseHttpClient<
    AssetLunarPurchaseOptionId,
    AssetLunarPurchaseOptionPostDataTransferObject,
    AssetLunarPurchaseOptionPutDataTransferObject,
    AssetLunarPurchaseOptionValueObject,
    AssetLunarPurchaseOptionVerboseValueObject,
    AssetLunarPurchaseOptionSearchOption
>(
    '/assets/lunars/purchaseOptions',
    o => new AssetLunarPurchaseOptionValueObject(o),
    o => new AssetLunarPurchaseOptionVerboseValueObject(o)
)

export const AssetLunarUsageIO = new BaseHttpClient<
    AssetLunarUsageId,
    AssetLunarUsagePostDataTransferObject,
    never,
    AssetLunarUsageValueObject,
    AssetLunarUsageVerboseValueObject,
    AssetLunarUsageSearchOption
>(
    '/assets/lunars/usages',
    o => new AssetLunarUsageValueObject(o),
    o => new AssetLunarUsageVerboseValueObject(o)
)

export const AssetPolicyModificationIO = new BaseHttpClient<
    AssetPolicyModificationId,
    never,
    never,
    AssetPolicyModificationValueObject,
    AssetPolicyModificationVerboseValueObject,
    AssetPolicyModificationSearchOption
>(
    '/assets/policies/modifications',
    o => new AssetPolicyModificationValueObject(o),
    o => new AssetPolicyModificationVerboseValueObject(o)
)

export const AssetPolicyIO = new BaseHttpClient<
    AssetPolicyId,
    AssetPolicyPostDataTransferObject,
    AssetPolicyPutDataTransferObject,
    AssetPolicyValueObject,
    AssetPolicyVerboseValueObject,
    AssetPolicySearchOption
>(
    '/assets/policies',
    o => new AssetPolicyValueObject(o),
    o => new AssetPolicyVerboseValueObject(o)
)

export const AssetSolarUsageIO = new BaseHttpClient<
    AssetSolarUsageId,
    AssetSolarUsagePostDataTransferObject,
    never,
    AssetSolarUsageValueObject,
    AssetSolarUsageVerboseValueObject,
    AssetSolarUsageSearchOption
>(
    '/assets/solars/usages',
    o => new AssetSolarUsageValueObject(o),
    o => new AssetSolarUsageVerboseValueObject(o)
)

export const AssetSolarWithdrawalIO = new BaseHttpClient<
    AssetSolarWithdrawalId,
    AssetSolarWithdrawalPostDataTransferObject,
    never,
    AssetSolarWithdrawalValueObject,
    AssetSolarWithdrawalVerboseValueObject,
    AssetSolarWithdrawalSearchOption
>(
    '/assets/solars/withdrawals',
    o => new AssetSolarWithdrawalValueObject(o),
    o => new AssetSolarWithdrawalVerboseValueObject(o)
)

export const AssetSolarWithdrawalOptionIO = new BaseHttpClient<
    AssetSolarWithdrawalOptionId,
    AssetSolarWithdrawalOptionPostDataTransferObject,
    AssetSolarWithdrawalOptionPutDataTransferObject,
    AssetSolarWithdrawalOptionValueObject,
    AssetSolarWithdrawalOptionVerboseValueObject,
    AssetSolarWithdrawalOptionSearchOption
>(
    '/assets/solars/withdrawals/options',
    o => new AssetSolarWithdrawalOptionValueObject(o),
    o => new AssetSolarWithdrawalOptionVerboseValueObject(o)
)

export const AssetSolarWithdrawalOptionModificationIO = new BaseHttpClient<
    AssetSolarWithdrawalOptionModificationId,
    never,
    never,
    AssetSolarWithdrawalOptionModificationValueObject,
    AssetSolarWithdrawalOptionModificationVerboseValueObject,
    AssetSolarWithdrawalOptionModificationSearchOption
>(
    '/assets/solars/withdrawals/options/modifications',
    o => new AssetSolarWithdrawalOptionModificationValueObject(o),
    o => new AssetSolarWithdrawalOptionModificationVerboseValueObject(o)
)

export const AssetStarChargeIO = new BaseHttpClient<
    AssetStarChargeId,
    never,
    never,
    AssetStarChargeValueObject,
    AssetStarChargeVerboseValueObject,
    AssetStarChargeSearchOption
>(
    '/assets/stars/charges',
    o => new AssetStarChargeValueObject(o),
    o => new AssetStarChargeVerboseValueObject(o)
)

export const AssetStarExchangeOptionModificationIO = new BaseHttpClient<
    AssetStarExchangeOptionModificationId,
    never,
    never,
    AssetStarExchangeOptionModificationValueObject,
    AssetStarExchangeOptionModificationVerboseValueObject,
    AssetStarExchangeOptionModificationSearchOption
>(
    '/assets/stars/exchangeOptions/modifications',
    o => new AssetStarExchangeOptionModificationValueObject(o),
    o => new AssetStarExchangeOptionModificationVerboseValueObject(o)
)

export const AssetStarExchangeOptionIO = new BaseHttpClient<
    AssetStarExchangeOptionId,
    AssetStarExchangeOptionPostDataTransferObject,
    AssetStarExchangeOptionPutDataTransferObject,
    AssetStarExchangeOptionValueObject,
    AssetStarExchangeOptionVerboseValueObject,
    AssetStarExchangeOptionSearchOption
>(
    '/assets/stars/exchangeOptions',
    o => new AssetStarExchangeOptionValueObject(o),
    o => new AssetStarExchangeOptionVerboseValueObject(o)
)

export const AssetStarUsageIO = new BaseHttpClient<
    AssetStarUsageId,
    AssetStarUsagePostDataTransferObject,
    never,
    AssetStarUsageValueObject,
    AssetStarUsageVerboseValueObject,
    AssetStarUsageSearchOption
>(
    '/assets/stars/usages',
    o => new AssetStarUsageValueObject(o),
    o => new AssetStarUsageVerboseValueObject(o)
)