import {AssetPolicySearchOptionDescription} from "../../../data/description/asset/AssetPolicySearchOptionDescription"
import {AssetPolicySearchOption} from "../../../data/search-option/asset/AssetPolicySearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function AssetPolicySearchOptionField(props: ListOptionFieldProps<AssetPolicySearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponUsagePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponUsagePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponUsagePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponUsagePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponUsagePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponUsagePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetCouponUsagePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetCouponUsagePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsagePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarUsagePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsagePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarUsagePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsagePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarUsagePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarUsagePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarUsagePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeOptionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeOptionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeOptionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeOptionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeOptionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeOptionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeOptionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeOptionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarExchangeExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarExchangeExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseOptionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseOptionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseOptionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseOptionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseOptionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseOptionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseOptionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseOptionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetLunarPurchaseExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetLunarPurchaseExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetPolicyPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetPolicyPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetPolicyPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetPolicyPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetPolicyPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetPolicyPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetPolicyPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetPolicyPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsagePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarUsagePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsagePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarUsagePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsagePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarUsagePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarUsagePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarUsagePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalOptionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalOptionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalOptionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalOptionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalOptionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalOptionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalOptionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalOptionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetSolarWithdrawalExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetSolarWithdrawalExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsagePageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsagePageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsagePageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsagePageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsagePageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsagePageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsagePageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsagePageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsageExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsageExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsageExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarUsageExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarUsageExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeOptionPageSizeEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeOptionPageSizeEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeOptionPageSizeNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeOptionPageSizeNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeOptionPageSizeMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeOptionPageSizeMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeOptionPageSizeMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeOptionPageSizeMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeExpEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeExpEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeExpNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeExpNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeExpMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeExpMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarExchangeExpMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarExchangeExpMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMinutesEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMinutesEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMinutesNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMinutesNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMinutesMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMinutesMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMinutesMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMinutesMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMaxEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMaxEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMaxNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMaxNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMaxMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMaxMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "assetStarChargeMaxMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.assetStarChargeMaxMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetPolicySearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyAssetPolicySearchOption(key: keyof AssetPolicySearchOption, value: string, into: StatePair<AssetPolicySearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "assetCouponPageSizeEq":
            into.setter({
                ...into.value,
                assetCouponPageSizeEq: Number(value)
            })
            break
        case "assetCouponPageSizeNeq":
            into.setter({
                ...into.value,
                assetCouponPageSizeNeq: Number(value)
            })
            break
        case "assetCouponPageSizeMin":
            into.setter({
                ...into.value,
                assetCouponPageSizeMin: Number(value)
            })
            break
        case "assetCouponPageSizeMax":
            into.setter({
                ...into.value,
                assetCouponPageSizeMax: Number(value)
            })
            break
        case "assetCouponUsagePageSizeEq":
            into.setter({
                ...into.value,
                assetCouponUsagePageSizeEq: Number(value)
            })
            break
        case "assetCouponUsagePageSizeNeq":
            into.setter({
                ...into.value,
                assetCouponUsagePageSizeNeq: Number(value)
            })
            break
        case "assetCouponUsagePageSizeMin":
            into.setter({
                ...into.value,
                assetCouponUsagePageSizeMin: Number(value)
            })
            break
        case "assetCouponUsagePageSizeMax":
            into.setter({
                ...into.value,
                assetCouponUsagePageSizeMax: Number(value)
            })
            break
        case "assetLunarUsagePageSizeEq":
            into.setter({
                ...into.value,
                assetLunarUsagePageSizeEq: Number(value)
            })
            break
        case "assetLunarUsagePageSizeNeq":
            into.setter({
                ...into.value,
                assetLunarUsagePageSizeNeq: Number(value)
            })
            break
        case "assetLunarUsagePageSizeMin":
            into.setter({
                ...into.value,
                assetLunarUsagePageSizeMin: Number(value)
            })
            break
        case "assetLunarUsagePageSizeMax":
            into.setter({
                ...into.value,
                assetLunarUsagePageSizeMax: Number(value)
            })
            break
        case "assetLunarExchangeOptionPageSizeEq":
            into.setter({
                ...into.value,
                assetLunarExchangeOptionPageSizeEq: Number(value)
            })
            break
        case "assetLunarExchangeOptionPageSizeNeq":
            into.setter({
                ...into.value,
                assetLunarExchangeOptionPageSizeNeq: Number(value)
            })
            break
        case "assetLunarExchangeOptionPageSizeMin":
            into.setter({
                ...into.value,
                assetLunarExchangeOptionPageSizeMin: Number(value)
            })
            break
        case "assetLunarExchangeOptionPageSizeMax":
            into.setter({
                ...into.value,
                assetLunarExchangeOptionPageSizeMax: Number(value)
            })
            break
        case "assetLunarExchangeExpEq":
            into.setter({
                ...into.value,
                assetLunarExchangeExpEq: Number(value)
            })
            break
        case "assetLunarExchangeExpNeq":
            into.setter({
                ...into.value,
                assetLunarExchangeExpNeq: Number(value)
            })
            break
        case "assetLunarExchangeExpMin":
            into.setter({
                ...into.value,
                assetLunarExchangeExpMin: Number(value)
            })
            break
        case "assetLunarExchangeExpMax":
            into.setter({
                ...into.value,
                assetLunarExchangeExpMax: Number(value)
            })
            break
        case "assetLunarPurchaseOptionPageSizeEq":
            into.setter({
                ...into.value,
                assetLunarPurchaseOptionPageSizeEq: Number(value)
            })
            break
        case "assetLunarPurchaseOptionPageSizeNeq":
            into.setter({
                ...into.value,
                assetLunarPurchaseOptionPageSizeNeq: Number(value)
            })
            break
        case "assetLunarPurchaseOptionPageSizeMin":
            into.setter({
                ...into.value,
                assetLunarPurchaseOptionPageSizeMin: Number(value)
            })
            break
        case "assetLunarPurchaseOptionPageSizeMax":
            into.setter({
                ...into.value,
                assetLunarPurchaseOptionPageSizeMax: Number(value)
            })
            break
        case "assetLunarPurchaseExpEq":
            into.setter({
                ...into.value,
                assetLunarPurchaseExpEq: Number(value)
            })
            break
        case "assetLunarPurchaseExpNeq":
            into.setter({
                ...into.value,
                assetLunarPurchaseExpNeq: Number(value)
            })
            break
        case "assetLunarPurchaseExpMin":
            into.setter({
                ...into.value,
                assetLunarPurchaseExpMin: Number(value)
            })
            break
        case "assetLunarPurchaseExpMax":
            into.setter({
                ...into.value,
                assetLunarPurchaseExpMax: Number(value)
            })
            break
        case "assetPolicyPageSizeEq":
            into.setter({
                ...into.value,
                assetPolicyPageSizeEq: Number(value)
            })
            break
        case "assetPolicyPageSizeNeq":
            into.setter({
                ...into.value,
                assetPolicyPageSizeNeq: Number(value)
            })
            break
        case "assetPolicyPageSizeMin":
            into.setter({
                ...into.value,
                assetPolicyPageSizeMin: Number(value)
            })
            break
        case "assetPolicyPageSizeMax":
            into.setter({
                ...into.value,
                assetPolicyPageSizeMax: Number(value)
            })
            break
        case "assetSolarUsagePageSizeEq":
            into.setter({
                ...into.value,
                assetSolarUsagePageSizeEq: Number(value)
            })
            break
        case "assetSolarUsagePageSizeNeq":
            into.setter({
                ...into.value,
                assetSolarUsagePageSizeNeq: Number(value)
            })
            break
        case "assetSolarUsagePageSizeMin":
            into.setter({
                ...into.value,
                assetSolarUsagePageSizeMin: Number(value)
            })
            break
        case "assetSolarUsagePageSizeMax":
            into.setter({
                ...into.value,
                assetSolarUsagePageSizeMax: Number(value)
            })
            break
        case "assetSolarWithdrawalPageSizeEq":
            into.setter({
                ...into.value,
                assetSolarWithdrawalPageSizeEq: Number(value)
            })
            break
        case "assetSolarWithdrawalPageSizeNeq":
            into.setter({
                ...into.value,
                assetSolarWithdrawalPageSizeNeq: Number(value)
            })
            break
        case "assetSolarWithdrawalPageSizeMin":
            into.setter({
                ...into.value,
                assetSolarWithdrawalPageSizeMin: Number(value)
            })
            break
        case "assetSolarWithdrawalPageSizeMax":
            into.setter({
                ...into.value,
                assetSolarWithdrawalPageSizeMax: Number(value)
            })
            break
        case "assetSolarWithdrawalOptionPageSizeEq":
            into.setter({
                ...into.value,
                assetSolarWithdrawalOptionPageSizeEq: Number(value)
            })
            break
        case "assetSolarWithdrawalOptionPageSizeNeq":
            into.setter({
                ...into.value,
                assetSolarWithdrawalOptionPageSizeNeq: Number(value)
            })
            break
        case "assetSolarWithdrawalOptionPageSizeMin":
            into.setter({
                ...into.value,
                assetSolarWithdrawalOptionPageSizeMin: Number(value)
            })
            break
        case "assetSolarWithdrawalOptionPageSizeMax":
            into.setter({
                ...into.value,
                assetSolarWithdrawalOptionPageSizeMax: Number(value)
            })
            break
        case "assetSolarWithdrawalExpEq":
            into.setter({
                ...into.value,
                assetSolarWithdrawalExpEq: Number(value)
            })
            break
        case "assetSolarWithdrawalExpNeq":
            into.setter({
                ...into.value,
                assetSolarWithdrawalExpNeq: Number(value)
            })
            break
        case "assetSolarWithdrawalExpMin":
            into.setter({
                ...into.value,
                assetSolarWithdrawalExpMin: Number(value)
            })
            break
        case "assetSolarWithdrawalExpMax":
            into.setter({
                ...into.value,
                assetSolarWithdrawalExpMax: Number(value)
            })
            break
        case "assetStarChargePageSizeEq":
            into.setter({
                ...into.value,
                assetStarChargePageSizeEq: Number(value)
            })
            break
        case "assetStarChargePageSizeNeq":
            into.setter({
                ...into.value,
                assetStarChargePageSizeNeq: Number(value)
            })
            break
        case "assetStarChargePageSizeMin":
            into.setter({
                ...into.value,
                assetStarChargePageSizeMin: Number(value)
            })
            break
        case "assetStarChargePageSizeMax":
            into.setter({
                ...into.value,
                assetStarChargePageSizeMax: Number(value)
            })
            break
        case "assetStarUsagePageSizeEq":
            into.setter({
                ...into.value,
                assetStarUsagePageSizeEq: Number(value)
            })
            break
        case "assetStarUsagePageSizeNeq":
            into.setter({
                ...into.value,
                assetStarUsagePageSizeNeq: Number(value)
            })
            break
        case "assetStarUsagePageSizeMin":
            into.setter({
                ...into.value,
                assetStarUsagePageSizeMin: Number(value)
            })
            break
        case "assetStarUsagePageSizeMax":
            into.setter({
                ...into.value,
                assetStarUsagePageSizeMax: Number(value)
            })
            break
        case "assetStarUsageExpEq":
            into.setter({
                ...into.value,
                assetStarUsageExpEq: Number(value)
            })
            break
        case "assetStarUsageExpNeq":
            into.setter({
                ...into.value,
                assetStarUsageExpNeq: Number(value)
            })
            break
        case "assetStarUsageExpMin":
            into.setter({
                ...into.value,
                assetStarUsageExpMin: Number(value)
            })
            break
        case "assetStarUsageExpMax":
            into.setter({
                ...into.value,
                assetStarUsageExpMax: Number(value)
            })
            break
        case "assetStarExchangeOptionPageSizeEq":
            into.setter({
                ...into.value,
                assetStarExchangeOptionPageSizeEq: Number(value)
            })
            break
        case "assetStarExchangeOptionPageSizeNeq":
            into.setter({
                ...into.value,
                assetStarExchangeOptionPageSizeNeq: Number(value)
            })
            break
        case "assetStarExchangeOptionPageSizeMin":
            into.setter({
                ...into.value,
                assetStarExchangeOptionPageSizeMin: Number(value)
            })
            break
        case "assetStarExchangeOptionPageSizeMax":
            into.setter({
                ...into.value,
                assetStarExchangeOptionPageSizeMax: Number(value)
            })
            break
        case "assetStarExchangeExpEq":
            into.setter({
                ...into.value,
                assetStarExchangeExpEq: Number(value)
            })
            break
        case "assetStarExchangeExpNeq":
            into.setter({
                ...into.value,
                assetStarExchangeExpNeq: Number(value)
            })
            break
        case "assetStarExchangeExpMin":
            into.setter({
                ...into.value,
                assetStarExchangeExpMin: Number(value)
            })
            break
        case "assetStarExchangeExpMax":
            into.setter({
                ...into.value,
                assetStarExchangeExpMax: Number(value)
            })
            break
        case "assetStarChargeMinutesEq":
            into.setter({
                ...into.value,
                assetStarChargeMinutesEq: Number(value)
            })
            break
        case "assetStarChargeMinutesNeq":
            into.setter({
                ...into.value,
                assetStarChargeMinutesNeq: Number(value)
            })
            break
        case "assetStarChargeMinutesMin":
            into.setter({
                ...into.value,
                assetStarChargeMinutesMin: Number(value)
            })
            break
        case "assetStarChargeMinutesMax":
            into.setter({
                ...into.value,
                assetStarChargeMinutesMax: Number(value)
            })
            break
        case "assetStarChargeMaxEq":
            into.setter({
                ...into.value,
                assetStarChargeMaxEq: Number(value)
            })
            break
        case "assetStarChargeMaxNeq":
            into.setter({
                ...into.value,
                assetStarChargeMaxNeq: Number(value)
            })
            break
        case "assetStarChargeMaxMin":
            into.setter({
                ...into.value,
                assetStarChargeMaxMin: Number(value)
            })
            break
        case "assetStarChargeMaxMax":
            into.setter({
                ...into.value,
                assetStarChargeMaxMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
