import {UserSessionDomain} from "../../domain/user/UserSessionDomain"

export const UserSessionDescriptor: Record<keyof UserSessionDomain, (o: UserSessionDomain) => string> = {
    id(o: UserSessionDomain): string {
        return o.id.toString()
    },
    userSignInId(o: UserSessionDomain): string {
        return o.userSignInId?.toString() ?? '-'
    },
    outbound(o: UserSessionDomain): string {
        return o.outbound
    },
    createdAt(o: UserSessionDomain): string {
        return o.createdAt.toISOString().substring(0, 19).replace('T', ' ')
    },
    lastModifiedAt(o: UserSessionDomain): string {
        return o.lastModifiedAt?.toISOString().substring(0, 19).replace('T', ' ') ?? '-'
    }
}
