import {AssetCouponPutDataTransferObjectDescription} from "../../../data/data-transfer-object-description/asset/AssetCouponPutDataTransferObjectDescription"
import {AssetCouponPutState} from "../../../data/data-transfer-object-state/asset/AssetCouponPutState"
import {AssetCouponPutDataTransferObject} from "../../../data/data-transfer-object/asset/AssetCouponPutDataTransferObject"
import {PostBigIntField, PostBitMaskField, PostBooleanField, PostDateField, PostEnumField, PostFieldWrapper, PostInputField, PostNumberField, PostTextareaField} from "../../PostField"
import {RouterProps} from "../../RouterProps"
import {JSX} from "react"

type PostFieldProps = RouterProps & {
    state: AssetCouponPutState
}

export const AssetCouponPutField: Record<Capitalize<keyof AssetCouponPutDataTransferObject>, (props: PostFieldProps) => JSX.Element> = {
    Code(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPutDataTransferObjectDescription.code}>
                <PostTextareaField
                    {...props}
                    value={props.state.code.value}
                    setter={props.state.code.setter}
                    maxLength={200} />
            </PostFieldWrapper>
        )
    },
    Amount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPutDataTransferObjectDescription.amount}>
                <PostNumberField
                    {...props}
                    value={props.state.amount.value}
                    setter={props.state.amount.setter} />
            </PostFieldWrapper>
        )
    },
    AssetLunarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPutDataTransferObjectDescription.assetLunarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.assetLunarAmount.value}
                    setter={props.state.assetLunarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    AssetSolarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPutDataTransferObjectDescription.assetSolarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.assetSolarAmount.value}
                    setter={props.state.assetSolarAmount.setter} />
            </PostFieldWrapper>
        )
    },
    AssetStarAmount(props: PostFieldProps): JSX.Element {
        return (
            <PostFieldWrapper
                {...props}
                title={AssetCouponPutDataTransferObjectDescription.assetStarAmount}>
                <PostNumberField
                    {...props}
                    value={props.state.assetStarAmount.value}
                    setter={props.state.assetStarAmount.setter} />
            </PostFieldWrapper>
        )
    },
}

export function AssetCouponPutFields(props: PostFieldProps) {
    return <>
        <AssetCouponPutField.Code
            {...props}
            state={props.state} />
        <AssetCouponPutField.Amount
            {...props}
            state={props.state} />
        <AssetCouponPutField.AssetLunarAmount
            {...props}
            state={props.state} />
        <AssetCouponPutField.AssetSolarAmount
            {...props}
            state={props.state} />
        <AssetCouponPutField.AssetStarAmount
            {...props}
            state={props.state} />
    </>
}
