import {RouterProps} from "./RouterProps";
import {BaseHttpCount, BaseHttpSearch, SearchIndex} from "../io/HttpClient";
import {StatePair, useStatePair} from "react-type-extension";
import {JSX, useCallback, useEffect} from "react";
import {onError} from "./ErrorHandler";
import {
    Button, Caption1, Caption1Stronger,
    makeStyles, mergeClasses,
    Table,
    TableBody,
    TableCell, TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow, Text
} from "@fluentui/react-components";
import {mapByKey} from "../util/ObjectUtil";
import {MoreHorizontal24Regular, WindowNew24Regular} from "@fluentui/react-icons";

const paginationPadding = 10n
const paginationLimitSize = paginationPadding * 2n

const useStyles = makeStyles({
    actions: {
        alignItems: 'center'
    },
    table: {
        width: 'max-content',
    },
    paginationItem: {
        width: '36px',
        minWidth: '36px',
        height: '36px'
    }
})

export type ListPageWrapperProps<VO, SO> = RouterProps & {
    client: BaseHttpSearch<SO, VO, any> & BaseHttpCount<SO>
    search?: (option: SO, index: SearchIndex) => Promise<VO[]>
    preSearch?: (present: VO[]) => Promise<void>
    postSearch?: (present: VO[], response: VO[]) => Promise<void>
    items: StatePair<VO[]>
    option: SO
    index: StatePair<SearchIndex>
    description: Record<keyof VO, string>
    descriptor: Record<keyof VO, (o: VO) => string>
    showPost?: boolean
    onPostClick?: () => void
    showFilter?: boolean
    onFilterClick?: () => void
    showSort?: boolean
    onSortClick?: () => void
    showRefresh?: boolean
    onRefreshClick?: () => void
    showNewTab?: boolean
    onItemClick?: (item: VO) => string | void
    additionalColumns?: ListPageWrapperAdditionalColumnProps<VO>[]
}

export type ListPageWrapperAdditionalColumnProps<VO> = {
    header: string
    row: (item: VO) => string
}

export default function ListPageWrapper<VO extends { id: bigint }, SO>(props: ListPageWrapperProps<VO, SO>) {
    const styles = useStyles()
    const count = useStatePair<bigint | undefined>(undefined)

    useEffect(() => {
        if (props.items.value.length === 0) {
            fetchItems().catch(onError)
        }
    }, [props.index.value]);

    useEffect(() => {
        if (count.value === undefined) {
            fetchCount().catch(onError)
        }
    }, []);

    async function fetchItems() {
        await props.preSearch?.(props.items.value)
        const search = props.search ?? props.client.search
        const fetched = await search(props.option, props.index.value)
        await props.postSearch?.(props.items.value, fetched)
        props.items.setter(fetched)
    }

    async function fetchCount() {
        count.setter(await props.client.count(props.option))
    }

    function onRowClick(item: VO) {
        const onItemClick = props.onItemClick
        if (onItemClick !== undefined) {
            const location = onItemClick(item)
            if (typeof location === 'string') {
                document.location = location
            }
        }
    }

    function onNewTabClick(item: VO) {
        const onItemClick = props.onItemClick
        if (onItemClick !== undefined) {
            const location = onItemClick(item)
            if (typeof location === 'string') {
                window.open(location, '_blank')
            }
        }
    }

    return <>
        <div className={props.styles.column16}>
            <div className={mergeClasses(styles.actions, props.styles.row8)}>
                {props.showPost === true && <Button onClick={props.onPostClick}>새 항목</Button>}
                {props.showSort === true && <Button onClick={props.onFilterClick}>필터</Button>}
                {props.showFilter === true && <Button onClick={props.onSortClick}>정렬</Button>}
                {props.showRefresh === true && <Button onClick={props.onRefreshClick}>새로고침</Button>}
                <Text>{`전체 항목: ${(count.value === undefined) ? '' : count.value}`}</Text>
            </div>
            <Table className={styles.table}>
                <TableHeader>
                    <TableRow>
                        {mapByKey(props.description, key => <>
                            <TableHeaderCell key={key as string}>
                                {props.description[key]}
                            </TableHeaderCell>
                        </>)}
                        {props.additionalColumns?.map(additionalColumn => (
                            <TableHeaderCell key={additionalColumn.header}>
                                {additionalColumn.header}
                            </TableHeaderCell>
                        ))}
                        {props.showNewTab !== false && <TableHeaderCell>동작</TableHeaderCell>}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {props.items.value.map(item => <>
                        <TableRow key={`table-row-${item.id}`}>
                            {mapByKey(props.description, key => <>
                                <TableCell
                                    key={`table-row-${item.id}-cell-${key as string}`}
                                    onClick={() => onRowClick(item)}>
                                    <TableCellLayout>
                                        {props.descriptor[key](item).split('\n, ').map((line, index) => <>
                                            <Text key={`table-row-${item.id}-cell-${key as string}-line-${index}`}>{line}</Text>
                                            <br/>
                                        </>)}
                                    </TableCellLayout>
                                </TableCell>
                            </>)}
                            {props.additionalColumns?.map(additionalColumn => (
                                <TableCell onClick={() => onRowClick(item)}>
                                    <TableCellLayout>
                                        <Text>{additionalColumn.row(item)}</Text>
                                    </TableCellLayout>
                                </TableCell>
                            ))}
                            {props.showNewTab !== false && <>
                                <TableCell>
                                    <TableCellLayout>
                                        <Button
                                            appearance={'subtle'}
                                            icon={<WindowNew24Regular /> }
                                            onClick={() => onNewTabClick(item)} />
                                    </TableCellLayout>
                                </TableCell>
                            </>}
                        </TableRow>
                    </>)}
                </TableBody>
            </Table>
            <Pagination
                {...props}
                count={count.value}
                index={{
                    value: props.index.value.pageIndex,
                    setter: (index: bigint) => {
                        props.index.setter({...props.index.value, pageIndex: index})
                        props.items.setter([])
                    }
                }} />
        </div>
    </>
}

type PaginationProps = RouterProps & {
    count: bigint | undefined
    index: StatePair<bigint>
}

function Pagination(props: PaginationProps) {
    const adminPageSize = BigInt(props.envPolicy.envAdminPageSize)
    const onItemClick = useCallback((index: bigint) => {
        props.index.setter(index * adminPageSize)
    }, [])

    if (props.count === undefined) {
        return <></>
    }

    const children: JSX.Element[] = []
    const max = maxPageIndex(props.count, adminPageSize)
    const currentIndex = props.index.value / adminPageSize
    if (currentIndex < paginationPadding) {
        for (let index = 0n; index < minOf(max, paginationLimitSize); index++) {
            children.push(<>
                <PaginationItem
                    key={`pagination-${index}`}
                    {...props}
                    index={index}
                    current={index === currentIndex}
                    onClick={onItemClick} />
            </>)
        }
        if (max > 10n) {
            if (max > 11n) {
                children.push(<PaginationOverflow />)
            }
            children.push(<>
                <PaginationItem
                    key={`pagination-end`}
                    {...props}
                    index={max - 1n}
                    current={false}
                    onClick={onItemClick} />
            </>)
        }
    } else if (max - currentIndex < paginationPadding + 1n) {
        const startIndex = maxOf(max - paginationLimitSize, 0n)
        if (startIndex !== 0n) {
            children.push(<>
                <PaginationItem
                    key={`pagination-start`}
                    {...props}
                    index={0n}
                    current={false}
                    onClick={onItemClick} />
            </>)
            if (startIndex !== 1n) {
                children.push(<PaginationOverflow />)
            }
        }
        for (let index = startIndex; index < max; index++) {
            children.push(<>
                <PaginationItem
                    key={`pagination-${index}`}
                    {...props}
                    index={index}
                    current={index === currentIndex}
                    onClick={onItemClick} />
            </>)
        }
    } else {
        const startIndex = currentIndex - paginationPadding
        if (startIndex !== 0n) {
            children.push(<>
                <PaginationItem
                    key={`pagination-start`}
                    {...props}
                    index={0n}
                    current={false}
                    onClick={onItemClick} />
            </>)
            if (startIndex !== 1n) {
                children.push(<PaginationOverflow />)
            }
        }
        const endIndex = currentIndex + paginationPadding + 1n
        for (let index = startIndex; index < endIndex; index++) {
            children.push(<>
                <PaginationItem
                    key={`pagination-${index}`}
                    {...props}
                    index={index}
                    current={index === currentIndex}
                    onClick={onItemClick} />
            </>)
        }
        if (endIndex !== max) {
            if (endIndex !== max - 1n) {
                children.push(<PaginationOverflow />)
            }
            children.push(<>
                <PaginationItem
                    key={`pagination-end`}
                    {...props}
                    index={max - 1n}
                    current={false}
                    onClick={onItemClick} />
            </>)
        }
    }

    return <>
        <div className={props.styles.row}>
            {children}
        </div>
    </>
}

type PaginationItemProps = RouterProps & {
    index: bigint
    current: boolean
    onClick: (index: bigint) => void
}

function PaginationItem(props: PaginationItemProps) {
    const style = useStyles()

    return <>
        <Button
            className={style.paginationItem}
            appearance={'subtle'}
            onClick={() => props.onClick(props.index)}>
            {props.current
                ? <Caption1Stronger>{`${props.index + 1n}`}</Caption1Stronger>
                : <Caption1>{`${props.index + 1n}`}</Caption1>}
        </Button>
    </>
}

function PaginationOverflow() {
    return <>
        <Button
            appearance={'transparent'}
            icon={<MoreHorizontal24Regular />} />
    </>
}

function maxPageIndex(count: bigint, adminPageSize: bigint) {
    if (count % adminPageSize === 0n) {
        return count / adminPageSize
    } else {
        return count / adminPageSize + 1n
    }
}

function maxOf(a: bigint, b: bigint) {
    return a > b ? a : b;
}

function minOf(a: bigint, b: bigint) {
    return a < b ? a : b;
}