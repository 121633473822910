import {RouterProps} from "../../../RouterProps";
import {useParams} from "react-router-dom";
import {useStatePair} from "react-type-extension";
import {
    AssetStarUsageValueObject
} from "../../../../data/value-object/asset/AssetStarUsageValueObject";
import {useEffect} from "react";
import {AssetLunarExchangeOptionIO, AssetStarUsageIO} from "../../../../io/AssetIO";
import {Body1, Caption1Strong, Spinner} from "@fluentui/react-components";
import {mapByKey} from "../../../../util/ObjectUtil";
import {
    AssetStarUsageDescription
} from "../../../../data/description/asset/AssetStarUsageDescription";
import {AssetStarUsageDomain} from "../../../../data/domain/asset/AssetStarUsageDomain";
import {AssetStarUsageDescriptor} from "../../../../data/descriptor/asset/AssetStarUsageDescriptor";
import {DetailDefaultPanel, DetailDefaultTab} from "../../../DetailViews";
import {
    AssetLunarExchangeOptionDescription
} from "../../../../data/description/asset/AssetLunarExchangeOptionDescription";

export default function Detail(props: RouterProps) {
    const params = useParams<{ assetStarUsageId: string }>()
    const item = useStatePair<AssetStarUsageValueObject | undefined>(undefined)

    useEffect(() => {
        if (item.value === undefined && params.assetStarUsageId !== undefined) {
            AssetStarUsageIO
                .getById(BigInt(params.assetStarUsageId))
                .then(item.setter)
                .catch(reason => {
                    console.error(reason)
                    alert('오류가 발생했습니다.')
                })
        }
    }, []);

    return <>
        {item.value === undefined && <Spinner />}
        {item.value !== undefined && <Page {...props} item={item.value} /> }
    </>
}

type PageProps = RouterProps & {
    item: AssetStarUsageValueObject
}

function Page(props: PageProps) {
    return <>
        <div className={props.styles.row16}>
            <DetailDefaultTab {...props} />
            <DetailDefaultPanel
                {...props}
                client={AssetStarUsageIO}
                description={AssetStarUsageDescription}
                properties={props => <PropertyBody {...props} />}
                hideEdit
                hideDelete />
        </div>
    </>
}

type PropertyBodyProps = PageProps & {
    itemKey: keyof AssetStarUsageDomain
}

function PropertyBody(props: PropertyBodyProps) {
    switch (props.itemKey) {
        case "id":
        case "type":
        case "amount":
        case "lastAccumulation":
        case "positiveAccumulation":
        case "negativeAccumulation":
        case "createdAt":
            return <Body1>{AssetStarUsageDescriptor[props.itemKey](props.item)}</Body1>
        case "userId":
            return <a href={`/users/${props.item.userId}`}><Body1>{AssetStarUsageDescriptor[props.itemKey](props.item)}</Body1></a>
        case "creatorId":
            return <a href={`/users/${props.item.creatorId}`}><Body1>{AssetStarUsageDescriptor[props.itemKey](props.item)}</Body1></a>
    }
}