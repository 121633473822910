import ListPageWrapper from "../../../ListPageWrapper";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {SortOrder} from "ts-protocol-extension";
import {searchIndex, SearchIndex} from "../../../../io/HttpClient";
import ListOption from "../../../ListOption";
import ListOrder from "../../../ListOrder";
import {
    AssetLunarUsageValueObject
} from "../../../../data/value-object/asset/AssetLunarUsageValueObject";
import {
    AssetLunarUsageSearchOption
} from "../../../../data/search-option/asset/AssetLunarUsageSearchOption";
import {AssetLunarUsageIO} from "../../../../io/AssetIO";
import {
    AssetLunarUsageDescription
} from "../../../../data/description/asset/AssetLunarUsageDescription";
import {AssetLunarUsageDescriptor} from "../../../../data/descriptor/asset/AssetLunarUsageDescriptor";
import {
    AssetLunarUsageSearchOptionDescription
} from "../../../../data/description/asset/AssetLunarUsageSearchOptionDescription";
import {
    applyAssetLunarUsageSearchOption,
    AssetLunarUsageSearchOptionField
} from "../../../search-option-field/asset/AssetLunarUsageSearchOptionField";

enum Phase {
    List,
    Option,
    Order
}

export default function List(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.List)
    const items = useStatePair<AssetLunarUsageValueObject[]>([])
    const option = useStatePair<AssetLunarUsageSearchOption>({})
    const index = useStatePair<SearchIndex>(searchIndex())

    return <>
        {phase.value === Phase.List && <>
            <ListPageWrapper
                {...props}
                client={AssetLunarUsageIO}
                items={items}
                option={option.value}
                index={index}
                description={AssetLunarUsageDescription}
                descriptor={AssetLunarUsageDescriptor}
                showPost
                onPostClick={() => document.location = '/assets/lunars/usages/post'}
                showFilter
                onFilterClick={() => phase.setter(Phase.Option)}
                showSort
                onSortClick={() => phase.setter(Phase.Order)}
                onItemClick={item => `/assets/lunars/usages/${item.id}`}/>
        </>}
        {phase.value === Phase.Option && <>
            <ListOption
                {...props}
                option={option.value}
                description={AssetLunarUsageSearchOptionDescription}
                fields={AssetLunarUsageSearchOptionField}
                onBackClick={() => phase.setter(Phase.List)}
                onApplyClick={(key, value) => {
                    applyAssetLunarUsageSearchOption(key, value, option)
                    index.setter({ ...index.value, pageIndex: 0n })
                    items.setter([])
                }}
                onDismissClick={key => {
                    const next = { ...option.value }
                    next[key] = undefined
                    option.setter(next)
                    items.setter([])
                }} />
        </>}
        {phase.value === Phase.Order && <>
            <ListOrder
                {...props}
                index={index}
                description={AssetLunarUsageDescription}
                onBackClick={() => phase.setter(Phase.List)}
                onOrderUpdated={() => items.setter([])} />
        </>}
    </>
}