import {UserBlockDomain} from "../../domain/user/UserBlockDomain"

/**
 * 사용자 이용 정지 속성 설명
 */
export const UserBlockDescription: Record<keyof UserBlockDomain, string> = {
    id: '기본키',
    creatorId: '생성자 ID',
    userId: '사용자 ID',
    userBlockReasonId: '사용자 이용 정지 사유 ID',
    blockedAt: '이용 정지 시작일',
    blockedUntil: '이용 정지 종료일',
    createdAt: '생성일',
    lastModifiedAt: '마지막 수정일'
}
