import {AssetStarExchangeOptionSearchOptionDescription} from "../../../data/description/asset/AssetStarExchangeOptionSearchOptionDescription"
import {AssetStarExchangeOptionSearchOption} from "../../../data/search-option/asset/AssetStarExchangeOptionSearchOption"
import {ListOptionFieldProps, ListOptionWrapper} from "../../ListOption"
import {Field, Input} from "@fluentui/react-components"
import {StatePair, useStatePair} from "react-type-extension"

export function AssetStarExchangeOptionSearchOptionField(props: ListOptionFieldProps<AssetStarExchangeOptionSearchOption>) {
    const input = useStatePair('')
    switch (props.optionKey) {
        case "idNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.idNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.idMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "idMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.idMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.creatorIdEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.creatorIdNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.creatorIdMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "creatorIdMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.creatorIdMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.starAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.starAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "starAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.starAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "solarAmountEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.solarAmountEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "solarAmountNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.solarAmountNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "solarAmountMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.solarAmountMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "solarAmountMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.solarAmountMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'number'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtEq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.createdAtEq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtNeq":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.createdAtNeq}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMin":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.createdAtMin}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
        case "createdAtMax":
            return <>
                <ListOptionWrapper
                    {...props}
                    title={AssetStarExchangeOptionSearchOptionDescription.createdAtMax}
                    input={input}
                    onApplyClick={(key, value) => {
                        props.onApplyClick(key, value)
                        input.setter('')
                    }}>
                    <Field>
                        <Input
                            value={input.value}
                            onChange={(_, data) => input.setter(data.value)}
                            type={'text'} />
                    </Field>
                </ListOptionWrapper>
            </>
    }
}

export function applyAssetStarExchangeOptionSearchOption(key: keyof AssetStarExchangeOptionSearchOption, value: string, into: StatePair<AssetStarExchangeOptionSearchOption>) {
    switch (key) {
        case "idNeq":
            into.setter({
                ...into.value,
                idNeq: BigInt(value)
            })
            break
        case "idMin":
            into.setter({
                ...into.value,
                idMin: BigInt(value)
            })
            break
        case "idMax":
            into.setter({
                ...into.value,
                idMax: BigInt(value)
            })
            break
        case "creatorIdEq":
            into.setter({
                ...into.value,
                creatorIdEq: BigInt(value)
            })
            break
        case "creatorIdNeq":
            into.setter({
                ...into.value,
                creatorIdNeq: BigInt(value)
            })
            break
        case "creatorIdMin":
            into.setter({
                ...into.value,
                creatorIdMin: BigInt(value)
            })
            break
        case "creatorIdMax":
            into.setter({
                ...into.value,
                creatorIdMax: BigInt(value)
            })
            break
        case "starAmountNeq":
            into.setter({
                ...into.value,
                starAmountNeq: Number(value)
            })
            break
        case "starAmountMin":
            into.setter({
                ...into.value,
                starAmountMin: Number(value)
            })
            break
        case "starAmountMax":
            into.setter({
                ...into.value,
                starAmountMax: Number(value)
            })
            break
        case "solarAmountEq":
            into.setter({
                ...into.value,
                solarAmountEq: Number(value)
            })
            break
        case "solarAmountNeq":
            into.setter({
                ...into.value,
                solarAmountNeq: Number(value)
            })
            break
        case "solarAmountMin":
            into.setter({
                ...into.value,
                solarAmountMin: Number(value)
            })
            break
        case "solarAmountMax":
            into.setter({
                ...into.value,
                solarAmountMax: Number(value)
            })
            break
        case "createdAtEq":
            into.setter({
                ...into.value,
                createdAtEq: new Date(value)
            })
            break
        case "createdAtNeq":
            into.setter({
                ...into.value,
                createdAtNeq: new Date(value)
            })
            break
        case "createdAtMin":
            into.setter({
                ...into.value,
                createdAtMin: new Date(value)
            })
            break
        case "createdAtMax":
            into.setter({
                ...into.value,
                createdAtMax: new Date(value)
            })
            break
    }
}
