import {EnvFaqModificationDomain} from "../../domain/env/EnvFaqModificationDomain"

/**
 * 자주 묻는 질문 수정 이력 속성 설명
 */
export const EnvFaqModificationDescription: Record<keyof EnvFaqModificationDomain, string> = {
    id: '기본키',
    creatorId: '수정자 ID',
    envFaqId: '자주 묻는 질문 ID',
    title: '제목',
    displayOrder: '표시 순서',
    createdAt: '수정일'
}
