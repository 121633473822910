import {EnvClientDistribution} from "../../constant/env/EnvClientDistribution"
import {EnvClientPlatform} from "../../constant/env/EnvClientPlatform"
import {EnvClientModificationDomain} from "../../domain/env/EnvClientModificationDomain"
import {EnvClientId} from "../../id/env/EnvClientId"
import {EnvClientModificationId} from "../../id/env/EnvClientModificationId"
import {UserId} from "../../id/user/UserId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 클라이언트 수정 이력
 */
export class EnvClientModificationValueObject implements ValueObject, EnvClientModificationDomain {
    /**
     * 기본키
     */
    id: EnvClientModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 클라이언트 ID
     */
    envClientId: EnvClientId
    /**
     * 플랫폼 유형
     */
    platform: EnvClientPlatform
    /**
     * 배포 유형
     */
    distribution: EnvClientDistribution
    /**
     * 이름
     */
    versionName: string
    /**
     * 만료일
     */
    expiredAt?: Date
    /**
     * 수정일
     */
    createdAt: Date

    constructor(o: Record<keyof EnvClientModificationDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.envClientId = BigInt(o.envClientId)
        this.platform = o.platform as EnvClientPlatform
        this.distribution = o.distribution as EnvClientDistribution
        this.versionName = String(o.versionName)
        if (o.expiredAt !== undefined && o.expiredAt !== null) {
            this.expiredAt = new Date(o.expiredAt)
        }
        this.createdAt = new Date(o.createdAt)
    }
}
