export function randomString(options: {
    length: number,
    radix?: number
}) {
    let s = ''
    while (s.length < options.length) {
        s += Math.abs(Math.random()).toString(options.radix ?? 36)
    }

    return s.substring(0, options.length)
}