import {UserSignInDomain} from "../../domain/user/UserSignInDomain"
import {EnvClientId} from "../../id/env/EnvClientId"
import {UserId} from "../../id/user/UserId"
import {UserSignInId} from "../../id/user/UserSignInId"
import {ValueObject} from "ts-protocol-extension"

/**
 * 사용자 로그인
 */
export class UserSignInValueObject implements ValueObject, UserSignInDomain {
    /**
     * 기본키
     */
    id: UserSignInId
    /**
     * 사용자 ID
     */
    userId: UserId
    /**
     * 클라이언트 ID
     */
    envClientId: EnvClientId
    /**
     * 요청자 주소
     */
    remoteAddress: string
    /**
     * 장치 이름
     */
    deviceName: string
    /**
     * 장치 버전
     */
    deviceVersion: string
    /**
     * 생성일
     */
    createdAt: Date

    constructor(o: Record<keyof UserSignInDomain, any>) {
        this.id = BigInt(o.id)
        this.userId = BigInt(o.userId)
        this.envClientId = BigInt(o.envClientId)
        this.remoteAddress = String(o.remoteAddress)
        this.deviceName = String(o.deviceName)
        this.deviceVersion = String(o.deviceVersion)
        this.createdAt = new Date(o.createdAt)
    }
}
