import {UiHomeContentSuggestionModificationVerboseDomain} from "../../domain/ui/UiHomeContentSuggestionModificationVerboseDomain"
import {UiHomeContentSuggestionId} from "../../id/ui/UiHomeContentSuggestionId"
import {UiHomeContentSuggestionModificationId} from "../../id/ui/UiHomeContentSuggestionModificationId"
import {UserId} from "../../id/user/UserId"
import {UserValueObject} from "../user/UserValueObject"
import {UiHomeContentSuggestionValueObject} from "./UiHomeContentSuggestionValueObject"
import {VerboseValueObject} from "ts-protocol-extension"

/**
 * 홈 추천 콘텐츠 수정 이력 상세
 */
export class UiHomeContentSuggestionModificationVerboseValueObject implements VerboseValueObject, UiHomeContentSuggestionModificationVerboseDomain {
    /**
     * 기본키
     */
    id: UiHomeContentSuggestionModificationId
    /**
     * 수정자 ID
     */
    creatorId: UserId
    /**
     * 홈 추천 콘텐츠 ID
     */
    uiHomeContentSuggestionId: UiHomeContentSuggestionId
    /**
     * 표시 우선 순위
     */
    displayOrder: number
    /**
     * URL
     */
    url: string
    /**
     * 수정일
     */
    createdAt: Date
    /**
     * 수정자 ID
     */
    creator: UserValueObject
    /**
     * 홈 추천 콘텐츠 ID
     */
    uiHomeContentSuggestion: UiHomeContentSuggestionValueObject

    constructor(o: Record<keyof UiHomeContentSuggestionModificationVerboseDomain, any>) {
        this.id = BigInt(o.id)
        this.creatorId = BigInt(o.creatorId)
        this.uiHomeContentSuggestionId = BigInt(o.uiHomeContentSuggestionId)
        this.displayOrder = Number(o.displayOrder)
        this.url = String(o.url)
        this.createdAt = new Date(o.createdAt)
        this.creator = new UserValueObject(o.creator)
        this.uiHomeContentSuggestion = new UiHomeContentSuggestionValueObject(o.uiHomeContentSuggestion)
    }
}
