import {ContentUserProductionRewardIO} from "../../../../io/ContentIO";
import {RouterProps} from "../../../RouterProps";
import {useStatePair} from "react-type-extension";
import {useCallback} from "react";
import {Button, Title3} from "@fluentui/react-components";
import {PostFieldWrapper, PostFinishDialog, PostNumberField} from "../../../PostField";
import {
    ContentUserProductionRewardDescription
} from "../../../../data/description/content/ContentUserProductionRewardDescription";

enum Phase {
    Waiting,
    Posting,
    Finished,
}

export default function Post(props: RouterProps) {
    const phase = useStatePair<Phase>(Phase.Waiting)
    const value = useStatePair(0)
    const displayOrder = useStatePair<number>(0)

    const onDoneClick = useCallback(() => {
        phase.setter(Phase.Posting)
        ContentUserProductionRewardIO
            .post({
                value: value.value,
                displayOrder: displayOrder.value
            })
            .then(() => phase.setter(Phase.Finished))
            .catch(reason => {
                console.error(reason)
                alert('추가를 실패했습니다.')
                phase.setter(Phase.Waiting)
            })
    }, [value.value, displayOrder.value])

    return <>
        {(phase.value === Phase.Posting || phase.value === Phase.Waiting || phase.value === Phase.Finished) && <>
            <div className={props.styles.column16}>
                <Title3>새 항목</Title3>
                <PostFieldWrapper
                    {...props}
                    title={ContentUserProductionRewardDescription.value}>
                    <PostNumberField
                        {...props}
                        value={value.value}
                        setter={value.setter}/>
                </PostFieldWrapper>
                <PostFieldWrapper
                    {...props}
                    title={ContentUserProductionRewardDescription.displayOrder}>
                    <PostNumberField
                        {...props}
                        value={displayOrder.value}
                        setter={displayOrder.setter}/>
                </PostFieldWrapper>
                <div className={props.styles.row}>
                    <Button
                        appearance={'primary'}
                        disabled={phase.value !== Phase.Waiting}
                        onClick={onDoneClick}>
                        완료
                    </Button>
                </div>
            </div>
        </>}
        <PostFinishDialog
            open={phase.value === Phase.Finished}
            negativeHref={'/contents/reports/reasons'} />
    </>
}